import React from 'react'
import { useState } from 'react';

import { Typography,Modal,Box,TableCell,TextField,Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { selectPOProducts } from '../../actions/POActions';

export const LocalStorageModal = ({open,setLocalStoragePrompt}) => {
  let initial;
  
  initial = localStorage?.getItem("selectedProducts")
    ? JSON.parse(localStorage.getItem("selectedProducts"))
    : [];

  const [openUp, setOpenUp] = React.useState(open);

  const dispatch = useDispatch()

//   const handleOpen = () => setOpenUp(true);
  const handleClose = () => {
    setOpenUp(false)
    setLocalStoragePrompt(false)
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleSubmit = () => {
    localStorage.setItem("selectedProducts", []);
    dispatch(selectPOProducts([]))
    handleClose()
  }

  return (
    <div>
        <Modal
            open={openUp}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight="Bold">
            Confirm
            </Typography>
            <br/>
            <Typography>
                There Are Already {initial.length} Items of Another Product Type Selected In PO Cart.
                <br/>
                Please Clear Those Products Before Changing Product Type
            </Typography>
            <br/>
            <Button variant="contained" color='error' onClick={handleSubmit}>Clear PO Cart</Button>
            <Button variant="contained" style={{left:"10px"}} onClick={handleClose}>Cancel</Button>
        </Box>
        </Modal>
    </div>
  )
}
