import React, { useEffect, useState } from "react";
import { Stack, Box, Divider, Modal } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import ImageViewer from "./ImageViewer";

const ReadChat = ({ messages, chover, ticket }) => {
  const [openImage, setOpenImage] = useState(false);
  const [imageId, setImageId] = useState("");
  const [prevChats, setPrevChats] = useState([]);
  const loading = useSelector((state) => state.loader.loading);

  useEffect(() => {
    const timeFormatted = messages.map((m) => {
      const date = new Date(m.time);
      const localDateTime = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      ).toLocaleString();
      return {
        ...m,
        time: localDateTime,
      };
    });
    setPrevChats(timeFormatted);
  }, [messages]);
  const handleImageClose = () => {
    setOpenImage(false);
  };

  return (
    <>
      {loading && (
        <div className="load-overlay">
          <h1>Please Wait</h1>
        </div>
      )}
      <div>
        {/* <Container> */}
        <Stack direction="column" spacing={1}>
          <Box
            //   className="chatSection"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",

              padding: "10px",
              borderRadius: "5px",
              paddingTop: "30px",
              backgroundColor: "#f7f7f7",
              boxShadow: "2px -4px #D3D3D3",
              // border: "1px groove grey",
              overflow: "scroll",
            }}
          >
            <h2>Correspondence</h2>

            <Stack spacing={2} direction="column">
              {"files" in ticket && ticket.files.length > 0 ? (
                <Box>
                  <Divider />
                  <h3>Images Submitted :</h3>
                  <Stack direction={"row"} gap={2}>
                    {ticket.files.map((f, i) => (
                      <>
                        <Box
                          onClick={() => {
                            setImageId(f.id);
                            setOpenImage(true);
                          }}
                          sx={{
                            "& :hover": {
                              cursor: "pointer",
                              color: "lightblue",
                              textDecoration: "underline",
                            },
                          }}
                        >
                          <img
                            style={{
                              width: "100px",
                              height: "100px",
                            }}
                            src={`https://drive.google.com/uc?export=view&id=${f.id}`}
                            alt=""
                          />
                        </Box>
                      </>
                    ))}
                  </Stack>
                </Box>
              ) : (
                ""
              )}
              {prevChats.map((m) => (
                <>
                  <Stack direction={"row"} gap={2}>
                    <Box
                      p={"20px"}
                      sx={{
                        display: chover ? "none" : "block",
                        bgcolor: "rgb(255,255,255)",
                        border: "1px solid #cfcdcd",
                      }}
                    >
                      <h4>
                        By : {m.sendedBy} <br />{" "}
                        {new Date(m.time).toLocaleString()}
                      </h4>
                    </Box>
                    <Stack
                      direction={"column"}
                      gap={2}
                      className="message"
                      sx={{
                        width: chover ? "400px" : "100vh",
                        "& h3": {
                          padding: 0,
                          margin: 0,
                        },
                      }}
                    >
                      {chover ? (
                        <h3>
                          By: {m.sendedBy}, {m.time}
                        </h3>
                      ) : (
                        ""
                      )}
                      {chover ? <Divider flexItem /> : ""}
                      {/* <div>
                        <p>{m.message}</p>
                      </div> */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: String(m.message).replaceAll("\n", "<br>"),
                        }}
                      />
                      {"files" in m && m.files.length > 0 ? (
                        <Box>
                          <Divider />
                          <h3>Attachments :</h3>
                          <Stack direction={"row"} gap={2}>
                            {m.files.map((f, i) => (
                              <>
                                <Box
                                  onClick={() => {
                                    setImageId(f.id);
                                    setOpenImage(true);
                                  }}
                                  sx={{
                                    "& :hover": {
                                      cursor: "pointer",
                                      color: "lightblue",
                                      textDecoration: "underline",
                                    },
                                  }}
                                >
                                  <img
                                    style={{ width: "100px", height: "100px" }}
                                    src={`https://drive.google.com/uc?export=view&id=${f.id}`}
                                    alt=""
                                  />
                                </Box>
                              </>
                            ))}
                          </Stack>
                        </Box>
                      ) : (
                        ""
                      )}
                    </Stack>
                  </Stack>
                </>
              ))}
            </Stack>
          </Box>
        </Stack>
        <Modal
          open={openImage}
          onClose={handleImageClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ImageViewer handleClose={handleImageClose} id={imageId} />
        </Modal>
      </div>
    </>
  );
};

export default ReadChat;
