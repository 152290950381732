import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Avatar,
  Modal,
} from "@mui/material";
import { Link } from "react-router-dom";

function Row({ row, index }) {
  return (
    <>
      <TableRow
        sx={{
          bgcolor: "rgb(245,245,245)",

          "& > *": { borderBottom: "unset" },
        }}
      >
        <TableCell component="th" scope="row">
          <Avatar
            key={index}
            src={row.url}
            style={{ height: "80px", width: "80px" }}
            variant="square"
          />
        </TableCell>
        <TableCell>
          {row.sku}
          {row.replacement ? "(replacement)" : ""}
        </TableCell>
        <TableCell>{row.originalSize}</TableCell>
        <TableCell>{row.size}</TableCell>
        <TableCell>{row.quantity}</TableCell>
        <TableCell>{row.mrp}</TableCell>
      </TableRow>
    </>
  );
}

const ProductViewModal = ({ productModal, tableData, setProductModal }) => {
  const [openUp, setOpenUp] = useState(productModal);
  const [data, setData] = useState([]);

  const { stock } = useSelector((state) => state.inventoryDetails);

  const handleClose = () => {
    setOpenUp(false);
    setProductModal(false);
  };

  useEffect(() => {
    if (stock.length > 0) {
      const mappedOrders = tableData.map((o) => {
        console.log("table data", o);
        const productsWithImages = o.products.map((p) => {
          const replacement =
            o.exchangeAction === "Replacement&Giftcard" &&
            "exchange_products" in o &&
            o.new_exchangeOrder_created
              ? o.exchange_products.some((ep) => ep.sku === p.sku)
              : false;
          const found = stock.find((i) => i.SKU === p.sku);
          const url =
            found?.imageUrl ||
            "https://media.istockphoto.com/vectors/error-page-or-file-not-found-icon-vector-id924949200";
          return { ...p, replacement, url };
        });

        return {
          ...o,
          products: productsWithImages,
        };
      });
      console.log("mapped orders", mappedOrders);
      setData(mappedOrders);
    }
  }, [stock, tableData]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    background: "whitesmoke",
    textAlign: "center",
    maxHeight: "80vh",
    overflowY: "scroll",
    maxWidth: "50%",
  };

  return (
    <Modal
      open={openUp}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box style={style}>
        {data.length > 0 ? (
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>Ordered Size</TableCell>
                  <TableCell>Exchange Size</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>MRP</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data[0].products.map((row, index) => (
                  <Row key={index} row={row} index={index} />
                ))}
              </TableBody>
            </Table>
            <Box style={{ padding: "10px" }}>
              <Box style={{ display: "flex" }}>
                {data[0].files?.length > 0 &&
                  data[0].files.map((x) => (
                    <a key={x.id} href={x.url} target="_blank">
                      <Avatar
                        key={x.id}
                        // src={`https://drive.google.com/uc?export=view&id=${x.id}`}
                        src={`https://lh3.google.com/u/0/d/${x.id}`}
                        alt={x.name}
                        style={{
                          height: "150px",
                          width: "150px",
                          cursor: "pointer",
                          paddingRight: "5px",
                        }}
                        variant="square"
                      />
                    </a>
                  ))}
              </Box>
              <br />
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  fontSize: "small",
                }}
              >
                Reason : {data[0].reason ? data[0].reason : "Not Mentioned"}
              </Typography>
              {data[0].rejection_reason ? (
                <Typography
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    fontSize: "small",
                  }}
                >
                  Rejection Reason :{" "}
                  {data[0].rejection_reason
                    ? data[0].rejection_reason
                    : "Not Mentioned"}
                </Typography>
              ) : (
                ""
              )}
            </Box>
            <br />
          </TableContainer>
        ) : (
          "Loading"
        )}
      </Box>
    </Modal>
  );
};

export default ProductViewModal;
