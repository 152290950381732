import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import {
  getWeeklySalesData,
  getTridiurnalSalesData,
  getThreeMonthsSalesData,
  getReturningProductsData,
} from "../../actions/ordersActions";
import { SkeletonComponent } from "../dashboard/helperComponents";
import ProgressBarWidget from "../generalWidgets/ProgressBarWidget";
import AdjustPendingPOProducts from "../inventoryWidgets/AdjustPendingPOProducts";

import { Paper, Button } from "@mui/material";
import { BACKEND_BASE_URL, SOCKET_IO_URL } from "../../utils/helpers";

const AnalyticsSync = () => {
  const dispatch = useDispatch();
  const {
    weeklySalesData,
    tridiurnalSalesData,
    threeMonthsSalesData,
    returningProductsData,
  } = useSelector((state) => state.orderDetails);
  const [sync4WeeksMessage, setSync4WeeksMessage] = useState("");
  const [sync15daysMessage, setSync15daysMessage] = useState("");
  const [sync3MonthsMessage, setSync3MonthsMessage] = useState("");
  const [syncRPMessage, setSyncRPMessage] = useState("");
  const [sync4WeeksProgress, setSync4WeeksProgress] = useState(0);
  const [sync15daysProgress, setSync15daysProgress] = useState(0);
  const [sync3MonthsProgress, setSync3MonthsProgress] = useState(0);
  const [syncRPProgress, setSyncRPProgress] = useState(0);

  useEffect(() => {
    //Revalidate data on completion of sync
    if (sync4WeeksProgress === 100) dispatch(getWeeklySalesData());
    if (sync15daysProgress === 100) dispatch(getTridiurnalSalesData());
    if (sync3MonthsProgress === 100) dispatch(getThreeMonthsSalesData());
    if (syncRPProgress === 100) dispatch(getReturningProductsData());
  }, [
    sync4WeeksProgress,
    sync15daysProgress,
    sync3MonthsProgress,
    syncRPProgress,
  ]);

  useEffect(() => {
    function on4WeeksEvent(props) {
      setSync4WeeksMessage(props);
    }

    function on15daysEvent(props) {
      setSync15daysMessage(props);
    }

    function on3MonthsEvent(props) {
      setSync3MonthsMessage(props);
    }

    function onRPEvent(props) {
      setSyncRPMessage(props);
    }

    function on3MonthsPCEvent(props) {
      setSync3MonthsProgress(props);
    }
    function on4WeeksPCEvent(props) {
      setSync4WeeksProgress(props);
    }
    function on15daysPCEvent(props) {
      setSync15daysProgress(props);
    }
    function onRPPCEvent(props) {
      setSyncRPProgress(props);
    }

    const socket = io(SOCKET_IO_URL);
    socket.on("FETCHING_4_WEEKS", on4WeeksEvent);
    socket.on("FETCHING_4_WEEKS_PC", on4WeeksPCEvent);
    socket.on("ERROR_FETCHING_4_WEEKS", on4WeeksEvent);
    socket.on("DB_SAVED_4_WEEKS", on4WeeksEvent);

    socket.on("FETCHING_15_DAYS", on15daysEvent);
    socket.on("FETCHING_15_DAYS_PC", on15daysPCEvent);
    socket.on("ERROR_FETCHING_15_DAYS", on15daysEvent);
    socket.on("DB_SAVED_15_DAYS", on15daysEvent);

    socket.on("FETCHING_3_MONTHS", on3MonthsEvent);
    socket.on("FETCHING_3_MONTHS_PC", on3MonthsPCEvent);
    socket.on("ERROR_FETCHING_3_MONTHS", on3MonthsEvent);
    socket.on("DB_SAVED_3_MONTHS", on3MonthsEvent);

    socket.on("FETCHING_RP", onRPEvent);
    socket.on("FETCHING_RP_PC", onRPPCEvent);
    socket.on("ERROR_FETCHING_RP", onRPEvent);
    socket.on("DB_SAVED_RP", onRPEvent);

    return () => {
      socket.off("FETCHING_4_WEEKS", on4WeeksEvent);
      socket.off("FETCHING_4_WEEKS_PC", on4WeeksPCEvent);
      socket.off("ERROR_FETCHING_4_WEEKS", on4WeeksEvent);
      socket.off("DB_SAVED_4_WEEKS", on4WeeksEvent);

      socket.off("FETCHING_15_DAYS", on15daysEvent);
      socket.off("FETCHING_15_DAYS_PC", on15daysPCEvent);
      socket.off("ERROR_FETCHING_15_DAYS", on15daysEvent);
      socket.off("DB_SAVED_15_DAYS", on15daysEvent);

      socket.off("FETCHING_3_MONTHS", on3MonthsEvent);
      socket.off("FETCHING_3_MONTHS_PC", on3MonthsPCEvent);
      socket.off("ERROR_FETCHING_3_MONTHS", on3MonthsEvent);
      socket.off("DB_SAVED_3_MONTHS", on3MonthsEvent);

      socket.off("FETCHING_RP", onRPEvent);
      socket.off("FETCHING_RP_PC", onRPPCEvent);
      socket.off("ERROR_FETCHING_RP", onRPEvent);
      socket.off("DB_SAVED_RP", onRPEvent);
    };
  }, []);

  useEffect(() => {
    if (!weeklySalesData) {
      dispatch(getWeeklySalesData());
    }
    if (!tridiurnalSalesData) {
      dispatch(getTridiurnalSalesData());
    }
    if (!threeMonthsSalesData) {
      dispatch(getThreeMonthsSalesData());
    }
    if (!returningProductsData) {
      dispatch(getReturningProductsData());
    }
  }, [
    weeklySalesData,
    tridiurnalSalesData,
    threeMonthsSalesData,
    returningProductsData,
  ]);

  const sync4Weeks = async () => {
    setSync4WeeksProgress(0.01);
    const syncUrl = `${BACKEND_BASE_URL}/api/ordersAnalytics/set4weeks`;

    const response = await fetch(syncUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const data = await response.json();
    console.log("4 weeks", data);
  };

  const sync15Days = async () => {
    setSync15daysProgress(0.01);
    const syncUrl = `${BACKEND_BASE_URL}/api/ordersAnalytics/set15days`;

    const response = await fetch(syncUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const data = await response.json();
    console.log("15 days", data);
  };

  const sync3Months = async () => {
    setSync3MonthsProgress(0.01);
    const syncUrl = `${BACKEND_BASE_URL}/api/ordersAnalytics/set90days`;

    const response = await fetch(syncUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const data = await response.json();
    console.log("3 months", data);
  };

  const syncReturned = async () => {
    setSyncRPProgress(0.01);
    const syncUrl = `${BACKEND_BASE_URL}/api/ordersAnalytics/setreturned`;

    const response = await fetch(syncUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const data = await response.json();
    console.log("RETURNED PRODUCTS", data);
  };

  const pincodeSync = async () => {
    setSyncRPProgress(0.01);
    const syncUrl = `${BACKEND_BASE_URL}/api/ordersAnalytics/setdelhiverypincodes`;
    const response = await fetch(syncUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const data = await response.json();
    console.log("Pincodes", data);
  };

  return (
    <>
      <div className="container">
        {weeklySalesData ? (
          <Paper
            elevation={2}
            style={{
              borderRadius: "5px",
              width: "300px",
              height: "300px",
              padding: "20px",
              textAlign: "center",
            }}
          >
            <h3 sx={{ fontSize: "medium", marginLeft: "10px" }}>
              4 weeks sales data. Last synced on: {weeklySalesData?.updated_at}
            </h3>
            <br />
            <p>{sync4WeeksMessage}</p>
            <br />
            {sync4WeeksProgress !== 0 && (
              <ProgressBarWidget percent={sync4WeeksProgress} />
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={sync4Weeks}
              disabled={sync4WeeksProgress !== 0}
            >
              Sales Data 4 weeks(28 days)
            </Button>
          </Paper>
        ) : (
          <SkeletonComponent />
        )}
        {tridiurnalSalesData ? (
          <Paper
            elevation={2}
            style={{
              borderRadius: "5px",
              width: "300px",
              height: "300px",
              padding: "20px",
              textAlign: "center",
            }}
          >
            <h3 sx={{ fontSize: "medium", marginLeft: "10px" }}>
              15 days Tridiurnal sales data. Last synced on:{" "}
              {tridiurnalSalesData?.updated_at}
            </h3>
            <br />
            <p>{sync15daysMessage}</p>
            <br />
            {sync15daysProgress !== 0 && (
              <ProgressBarWidget percent={sync15daysProgress} />
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={sync15Days}
              disabled={sync15daysProgress !== 0}
            >
              Sales Data 15 days
            </Button>
          </Paper>
        ) : (
          <SkeletonComponent />
        )}
        {threeMonthsSalesData ? (
          <Paper
            elevation={2}
            style={{
              borderRadius: "5px",
              width: "300px",
              height: "300px",
              padding: "20px",
              textAlign: "center",
            }}
          >
            <h3 sx={{ fontSize: "medium", marginLeft: "10px" }}>
              1.5 months sales data. Last synced on:{" "}
              {threeMonthsSalesData?.updated_at}
            </h3>
            <br />
            <p>{sync3MonthsMessage}</p>
            <br />
            {sync3MonthsProgress !== 0 && (
              <ProgressBarWidget percent={sync3MonthsProgress} />
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={sync3Months}
              disabled={sync3MonthsProgress !== 0}
            >
              Sales Data 1.5 months(45 days)
            </Button>
          </Paper>
        ) : (
          <SkeletonComponent />
        )}
        {returningProductsData ? (
          <Paper
            elevation={2}
            style={{
              borderRadius: "5px",
              width: "300px",
              height: "300px",
              padding: "20px",
              textAlign: "center",
            }}
          >
            <h3 sx={{ fontSize: "medium", marginLeft: "10px" }}>
              Returning products data. Last synced on:{" "}
              {returningProductsData?.updated_at}
            </h3>
            <br />
            <p>{syncRPMessage}</p>
            <br />
            {syncRPProgress !== 0 && (
              <ProgressBarWidget percent={syncRPProgress} />
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={syncReturned}
              disabled={syncRPProgress !== 0}
            >
              Returning products data
            </Button>
          </Paper>
        ) : (
          <SkeletonComponent />
        )}
        {/* pincode sync */}
        <Paper
          elevation={2}
          style={{
            borderRadius: "5px",
            width: "300px",
            height: "300px",
            padding: "20px",
            textAlign: "center",
          }}
        >
          <h3 sx={{ fontSize: "medium", marginLeft: "10px" }}>
            Pincode. Last synced on: {/* {returningProductsData?.updated_at} */}
          </h3>
          <br />
          <p>{syncRPMessage}</p>
          <br />
          {syncRPProgress !== 0 && (
            <ProgressBarWidget percent={syncRPProgress} />
          )}
          <Button
            variant="contained"
            color="secondary"
            onClick={pincodeSync}
            disabled={syncRPProgress !== 0}
          >
            Returning products data
          </Button>
        </Paper>
        <SkeletonComponent />
        <SkeletonComponent />
        <SkeletonComponent />
      </div>
      <div
        className="container"
        style={{ marginTop: "20px", flexDirection: "column" }}
      >
        <h1>Adjust Pending PO product data</h1>
        <div>
          <AdjustPendingPOProducts />
        </div>
      </div>
    </>
  );
};

export default AnalyticsSync;
