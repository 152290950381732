import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { productTypes, categoryTypes } from "../../../utils/helperArrays";
import { TextField, MenuItem, InputAdornment, Paper } from "@mui/material";
import MaterialTable from "./MaterialTable";

const salesViewList = [
  { value: 0, label: "weeks" },
  { value: 1, label: "3 days interval" },
  { value: 2, label: "per day" },
];

const SalesDataWithPO = () => {
  const {
    weeklySalesData,
    tridiurnalSalesData,
    shopifyInventory,
    perDaySalesData,
  } = useSelector((state) => state.orderDetails);
  const productVariants = useSelector(
    (state) => state.productDetails.productVariants
  );
  const inventoryStock = useSelector((state) => state.inventoryDetails.stock);

  const [tableData, setTableData] = useState([]);
  const [productType, setProductType] = useState("");
  const [filteredProducts, setFilteredProducts] = useState("");

  const [categoryTypesArray, setCategoryTypesArray] = useState([]);
  const [productVariantList, setProductVariantList] = useState([]);
  const [salesView, setSalesView] = useState(0);

  const changeDateFormat = (date) => {
    const reqDate = (date.getDate() <= 9 ? "0" : "") + date.getDate();
    const reqMonth =
      (date.getMonth() + 1 <= 9 ? "0" : "") + (date.getMonth() + 1);
    const reqFormat = `${[date.getFullYear(), reqMonth, reqDate].join(
      "-"
    )}T00:00:00+05:30`;
    return reqFormat;
  };

  useEffect(() => {
    if (shopifyInventory.length > 0 && productVariants.length > 0) {
      const temp = productVariants.map((x) => {
        const variant = shopifyInventory.find(
          (p) => p.inventory_item_id === x.inventory_item_id
        );
        let stock = 0;
        if (variant) {
          stock = variant.available;
        } else {
          stock = 0;
        }
        return {
          ...x,
          stock,
        };
      });
      // console.log(temp)
      setProductVariantList(temp);
    }
  }, [shopifyInventory, productVariants]);

  useEffect(() => {
    //Create the Tabledata
    if (productVariantList.length > 0 && inventoryStock.length > 0) {
      const sales =
        salesView === 0
          ? weeklySalesData
          : salesView === 1
          ? tridiurnalSalesData
          : perDaySalesData;
      const data = sales?.sales_data.map((p) => {
        const imageObj = inventoryStock.find((item) => item.SKU === p.sku);
        let src = null;
        let material = null;
        let materialWeight = 0;
        let materialFormula = "NA";
        if (imageObj) {
          src = imageObj.imageUrl;
          material = imageObj.material || [];
          materialWeight = imageObj.materialWeight
            ? imageObj.materialWeight
            : 0;
          materialFormula = imageObj.materialFormula
            ? imageObj.materialFormula
            : "NA";
        } else {
          src = "https://sss-retail-images.s3.amazonaws.com/404.jpg?w=256&q=75";
          material = [];
        }

        let variantArr = [];
        const variantObj = productVariantList.filter(
          (item) => item.sku === p.sku
        );
        variantArr.push(
          variantObj.map((x) => ({
            size: x.title,
            stock: x.stock ? x.stock : 0,
          }))
        );

        let inventoryArr = [];
        const inventoryObj = inventoryStock.filter(
          (item) => item.SKU === p.sku
        );
        inventoryArr.push(
          inventoryObj.map((x) => ({
            size: x.size,
            stock: x.count ? x.count : 0,
          }))
        );
        const inventoryObjHavingDate = inventoryObj.find((i) => "date" in i);
        return {
          ...p,
          tags: "",
          src,
          publishedAt: inventoryObjHavingDate
            ? changeDateFormat(new Date(inventoryObjHavingDate.date))
            : "Invalid Date",
          material,
          materialWeight,
          materialFormula,
          type: imageObj?.type,
          stock: variantArr,
          inventoryStock: inventoryArr,
        };
      });
      // console.log(data)
      setTableData(data);
    }
  }, [inventoryStock, productVariantList, salesView]);

  useEffect(() => {
    if (tableData.length > 0 && productType) {
      const getCategories = categoryTypes(productType);
      const filtered = tableData.filter((item) => item.type === productType);
      setCategoryTypesArray(getCategories);
      setFilteredProducts(filtered);
    }
  }, [productType, tableData]);
  return (
    <div>
      <br />
      <h2>Last Updated at: {weeklySalesData.updated_at}</h2>
      <br />
      <div style={{ display: "flex" }}>
        <Paper sx={{ width: "500px" }}>
          <TextField
            select
            value={productType}
            onChange={(e) => setProductType(e.target.value)}
            variant="outlined"
            className="select"
            fullWidth
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <p
                    style={{
                      fontWeight: 800,
                      borderRight: "1px solid black",
                      paddingRight: "4px",
                    }}
                  >
                    Product Type
                  </p>
                </InputAdornment>
              ),
            }}
          >
            {productTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Paper>
      </div>
      <br />
      {productType && filteredProducts.length > 0 && (
        <>
          {/* sales */}
          <Paper sx={{ width: "250px", my: "5px", marginBottom: "10px" }}>
            <TextField
              select
              fullWidth
              value={salesView}
              onChange={(e) => setSalesView(e.target.value)}
              variant="outlined"
              className="select"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <p
                      style={{
                        fontWeight: 800,
                        borderRight: "1px solid black",
                        paddingRight: "4px",
                      }}
                    >
                      Sales
                    </p>
                  </InputAdornment>
                ),
              }}
            >
              {salesViewList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Paper>
          <MaterialTable
            tableData={filteredProducts}
            salesView={salesView}
            categoryTypes={categoryTypesArray}
            productType={productType}
          />
        </>
      )}
    </div>
  );
};

export default SalesDataWithPO;
