import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./DatePicker.css";
import DatePicker from "./DatePicker";

const SearchBar = ({
  handleCourierChange,
  orderStatus,
  handleSearch,
  courier,
  courierSelected,
  loading,
  setStatus,
  status,
  ordersLength,
  setDate,
}) => {
  return (
    <div>
      <Stack
        direction={"row"}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          sx={{
            width: "100%",
            height: ordersLength > 0 ? null : "70vh",
            px: "2px",
            py: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 3,
            mb: 2,
          }}
        >
          {ordersLength <= 0 && (
            <h1 style={{ color: "gray" }}>Search For Orders</h1>
          )}
          <Stack direction={"row"} gap={2}>
            <DatePicker setDate={setDate} />
            <TextField
              style={{ width: "200px" }}
              select
              placeholder="Courier"
              value={courierSelected}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <p
                      style={{
                        fontWeight: 800,
                        borderRight: "1px solid black",
                        paddingRight: "4px",
                      }}
                    >
                      Courier
                    </p>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleCourierChange(e.target.value)}
              variant="outlined"
              className="select"
              size="small"
            >
              {courier.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              style={{ width: "400px" }}
              select
              placeholder="select status"
              value={status}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <p
                      style={{
                        fontWeight: 800,
                        borderRight: "1px solid black",
                        paddingRight: "4px",
                      }}
                    >
                      Status
                    </p>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setStatus(e.target.value)}
              variant="outlined"
              className="select"
              size="small"
            >
              {orderStatus.map((option) => (
                <MenuItem key={option.label} value={option.label}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                size="small"
                disabled={loading}
                variant="contained"
                onClick={handleSearch}
              >
                Search Orders
              </Button>
            )}
          </Stack>
        </Paper>
      </Stack>
    </div>
  );
};

export default SearchBar;
