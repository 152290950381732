import { formatCurrency } from "./orderTakeoutHelpers";
import { getGSTbyLineItem } from "./gstHelpers";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { getSizesByType, skuTypes, skuWiseTypes } from "./helperArrays";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const createPDFforManifest = (data) => {
  const allImages = data.reduce(
    (acc, item) => {
      const products = item.order_items
        .map((p) => ({ sku: p.sku, url: p.url }))
        .reduce(
          (a, i) => ({
            ...a,
            [i.sku]: i.url,
          }),
          {}
        );
      return {
        ...acc,
        ...products,
      };
    },
    {
      DEFAULT:
        "https://cdn.shopify.com/s/files/1/0604/6957/5837/files/Littlebox_white_border_2_aae20d45-94e3-4b3f-adb1-674d40d94e9e_195x@2x.png?v=1649095358",
    }
  );

  const orderItems = data.reduce((acc, d) => {
    acc.push(...d.order_items);
    return acc;
  }, []);

  const skuWise = orderItems.reduce((acc, oi) => {
    const amt = parseInt(oi.units);
    const sku = oi.sku.split(".")[0];
    const alphabetPart = sku.match(/[A-Za-z]+/)[0];
    const size = oi.variant_title;
    const findType = skuWiseTypes.find((st) => st.sku === alphabetPart);
    if (!findType) {
      console.log("not found", sku);
      return acc;
    }
    const type = findType.type;
    const category = findType.category;
    const sizes = getSizesByType(type).reduce((acc, sizeRow) => {
      acc[sizeRow] = 0;
      return acc;
    }, {});
    const found = acc.find((ac) => ac.sku === sku);
    if (found) {
      found.quantity[size] = found.quantity[size] + amt;
      found.total = found.total + amt;
    } else {
      const newObj = {
        sku,
        url: oi.url,
        quantity: sizes,
        total: amt,
        type,
        category,
      };
      newObj.quantity[size] = newObj.quantity[size] + amt;
      acc.push(newObj);
    }
    return acc;
  }, []);
  const skuOnlyImages = skuWise.reduce(
    (acc, item) => {
      return {
        ...acc,
        [`${item.sku}`]: item.url,
      };
    },
    {
      DEFAULT:
        "https://cdn.shopify.com/s/files/1/0604/6957/5837/files/Littlebox_white_border_2_aae20d45-94e3-4b3f-adb1-674d40d94e9e_195x@2x.png?v=1649095358",
    }
  );
  const totalQuantity = skuWise.reduce((acc, s) => acc + s.total, 0);

  // category wise sorted
  const categoryWise = skuWise.reduce((acc, sk) => {
    const found = acc.find((ac) => ac.category === sk.category);
    if (found) {
      found.data = [...found.data, sk];
    } else {
      const newObj = {
        category: sk.category,
        data: [sk],
      };
      acc.push(newObj);
    }
    return acc;
  }, []);

  const createQuantityList = (quantity) => {
    const sizes = Object.keys(quantity);
    const temp = sizes.map((x) => {
      return [quantity[x]];
    });
    return [sizes, temp];
  };
  const categoryWiseProductTable = (data) => {
    const row = data.map((o) => [
      {
        image: o.sku || "DEFAULT",
        width: 40,
        height: 40,
      },
      `${o.sku}`,
      {
        style: "tableExample",
        table: {
          // headerRows: 1,
          body: createQuantityList(o.quantity),
        },
        layout: "noBorders",
      },
      o.total,
    ]);
    row.unshift([
      { text: "Image", style: "tableHeader" },
      { text: "SKU", style: "tableHeader" },
      { text: "Quantity", style: "tableHeader" },
      { text: "Total", style: "tableHeader" },
    ]);
    return row;
  };

  const docTable = categoryWise.reduce((acc, cw) => {
    const totalCategoryQnty = cw.data.reduce(
      (acc, item) => acc + item.total,
      0
    );
    const categoryName = { text: `${cw.category}`, style: "header" };
    const categoryInfo = `Total SKU's :${cw.data.length} Total Quantity : ${totalCategoryQnty}`;
    const data = cw.data;
    acc.push(categoryName, categoryInfo, {
      style: "tableExample",
      table: {
        headerRows: 1,
        body: categoryWiseProductTable(data),
      },
      // layout: "noBorders",
    });
    return acc;
  }, []);

  const skuInfoTable = categoryWise.reduce((acc, cw) => {
    const category = cw.category;
    const totalSKU = cw.data.length;
    const totalQnty = cw.data.reduce((accQnty, d) => accQnty + d.total, 0);
    const arr = [category, `${totalSKU}`, `${totalQnty}`];
    acc.push(arr);
    return acc;
  }, []);
  skuInfoTable.unshift([
    { text: "Category", style: "tableHeader" },
    { text: "Total SKU's", style: "tableHeader" },
    { text: "Total Quantity", style: "tableHeader" },
  ]);
  const skuInfoTableDef = {
    style: "tableExample",
    table: {
      headerRows: 1,
      body: skuInfoTable,
    },
    // layout: "noBorders",
  };

  const createImageList = (products) => {
    const temp = products.map((x) => {
      return [
        {
          image: x.sku || "DEFAULT",
          width: 80,
          height: 80,
        },
      ];
    });
    return temp;
  };
  const createItemList = (products) => {
    const temp = products.map((x) => {
      return [
        {
          text: [
            x.sku.split(".")[0] + " -- ",
            { text: x.variant_title, bold: true },
          ],
          margin: [0, 0, 0, 67],
        },
      ];
    });
    return temp;
  };
  const createUnitList = (products) => {
    const temp = products.map((x) => {
      return [
        {
          text: x.units,
          margin: [0, 0, 0, 67],
        },
      ];
    });
    return temp;
  };
  const productTable = data.map((o) => [
    {
      stack: [{ text: o.original_order_id }, { text: `(${o.fulfillment_id})` }],
      margin: [0, 5, 0, 5],
    },
    {
      style: "tableExample",
      table: {
        // headerRows: 1,
        body: createItemList(o.order_items),
      },
      layout: "noBorders",
    },
    {
      style: "tableExample",
      table: {
        // headerRows: 1,
        body: createUnitList(o.order_items),
      },
      layout: "noBorders",
    },
    {
      style: "tableExample",
      table: {
        // headerRows: 1,
        body: createImageList(o.order_items),
      },
      layout: "noBorders",
    },
    { text: o.total, margin: [5, 5, 7, 5] },
  ]);

  productTable.unshift([
    { text: "Order No.", style: "tableHeader" },
    { text: "Item", style: "tableHeader" },
    { text: "Unit", style: "tableHeader" },
    { text: "Image", style: "tableHeader" },
    { text: "Price", style: "tableHeader" },
  ]);

  const docDefinition = {
    content: [
      { text: "Products Manifest", style: "header" },
      `Manifest Sheet For : Little Box India`,
      `Date : ${new Date().toLocaleDateString()}`,
      `Pickup Address : Santana Folks LLP`,
      `Total Quantity : ${totalQuantity}`,
      skuInfoTableDef,
      ...docTable,
      { text: "Order List", style: "header" },
      {
        style: "tableExample",
        table: {
          headerRows: 1,
          body: productTable,
        },
        // layout: "noBorders",
      },
    ],
    images: { ...allImages, ...skuOnlyImages },
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 16,
        bold: false,
        margin: [0, 10, 0, 5],
      },
      tableExample: {
        margin: [0, 5, 0, 5],
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
      },
    },
  };

  // const options = {};
  console.log("here");

  const newFileName = new Date().toLocaleDateString(); //cryptoRandomString({ length: 20, type: "url-safe" });

  pdfMake.createPdf(docDefinition).download(newFileName);

  console.log(newFileName);

  return newFileName;
};

export const createPDFforAllOrders = (data, imageData) => {
  const allImages = imageData.reduce(
    (acc, item) => {
      return {
        ...acc,
        ...item,
      };
    },
    {
      DEFAULT:
        "https://cdn.shopify.com/s/files/1/0604/6957/5837/files/Littlebox_white_border_2_aae20d45-94e3-4b3f-adb1-674d40d94e9e_195x@2x.png?v=1649095358",
    }
  );

  const createCustomerDetails = (data) => {
    const newAddress = [data.name, data.phone, data.address1, data.zip];
    return newAddress;
  };

  const itemTable = (products) => {
    return products.map((x) => {
      return [
        {
          image: x.sku || "DEFAULT",
          width: 80,
          height: 80,
        },

        x.sku + " - " + x.variant_title + " - " + x.title + " - " + x.quantity,
      ];
    });
  };

  const productTable = data.map((p) => [
    p.order,
    p.date,
    p.status || "pending",
    {
      style: "tableExample",
      table: {
        // headerRows: 1,
        body: itemTable(p.products),
      },
      layout: "noBorders",
    },
    p.products.length,
    createCustomerDetails(p.address),
    p.total,
  ]);

  productTable.unshift([
    { text: "Order No.", style: "tableHeader" },
    { text: "Order Date", style: "tableHeader" },
    { text: "Order Status", style: "tableHeader" },
    { text: "Items", style: "tableHeader" },

    { text: "Item Count", style: "tableHeader" },
    { text: "Customer Details", style: "tableHeader" },
    { text: "Price", style: "tableHeader" },
  ]);

  const docDefinition = {
    content: [
      { text: "Products Manifest", style: "header" },
      `Manifest Sheet For : Little Box India`,
      `Date : ${new Date().toLocaleDateString()}`,
      `Courier : Delhivery`,
      `Pickup Address : DELHI`,
      {
        style: "tableExample",
        table: {
          headerRows: 1,
          body: productTable,
        },
      },
    ],
    images: allImages,
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 16,
        bold: false,
        margin: [0, 10, 0, 5],
      },
      tableExample: {
        margin: [0, 5, 0, 15],
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
      },
    },
  };

  // const options = {};

  const newFileName = new Date().toLocaleDateString(); //cryptoRandomString({ length: 20, type: "url-safe" });

  pdfMake.createPdf(docDefinition).download(newFileName);

  return newFileName;
};

export const createPDFForUnfulfilledOrders = (data) => {
  //Image Definitions
  const allImages = data.reduce(
    (acc, item) => {
      const products = { [item.sku]: item.src };
      return {
        ...acc,
        ...products,
      };
    },
    {
      default:
        "https://cdn.shopify.com/s/files/1/0604/6957/5837/files/Littlebox_white_border_2_aae20d45-94e3-4b3f-adb1-674d40d94e9e_195x@2x.png?v=1649095358",
    }
  );

  console.log(allImages);

  //Nested Table Definitions

  const nestedTable = (item) => {
    const sizes = getSizesByType(item.type); //sizes array
    const Numbers = ["Numbers", ...sizes, "Total"];
    const getShopifyStock = (size) => {
      const found = item.stock[0].filter((i) => i.size === size);
      if (found.length > 0) {
        return found[0].stock;
      } else {
        return 0;
      }
    };

    const sizeTotal = Object.values(item.sizes).reduce((acc, item) => {
      acc = acc + item;
      return acc;
    }, 0);

    const inventoryStockTotal = Object.values(item.inventoryStock).reduce(
      (acc, item) => {
        acc = acc + item;
        return acc;
      },
      0
    );

    const shopifyInventoryTotal = item.stock[0].reduce((acc, item) => {
      acc = acc + item.stock;
      return acc;
    }, 0);

    const OrderSizeCount = [
      "Order size count",
      ...sizes.map((s) => item.sizes[s] || 0),
      sizeTotal,
    ];
    const inventoryStock = [
      "Inventory Stock",
      ...sizes.map((s) => item.inventoryStock[s] || 0),
      inventoryStockTotal,
    ];
    const shopifyInventory = [
      "Shopify Inventory",
      ...sizes.map((s) => getShopifyStock(s)),
      shopifyInventoryTotal,
    ];

    const newItem = [Numbers, OrderSizeCount, inventoryStock, shopifyInventory];
    return newItem;
  };

  //Main Table Body

  const mainTable = data.map((item) => {
    const newBody = [
      {
        image: item.sku,
        fit: [100, 100],
      },
      item.sku,
      item.name,
      {
        style: "nestedTable",
        table: {
          //----------Nested Table-------//
          headerRows: 1,
          body: nestedTable(item),
        },
        layout: "noBorders",
      },
    ];
    return newBody;
  });

  const pdfDefinition = {
    content: [
      { text: "Unfulfilled Orders", style: "header" },

      {
        style: "mainTable",
        table: {
          headerRows: 1,

          widths: ["auto", "auto", "auto", "auto"],
          body: [["Image", "SKU", "Name", "Numbers"], ...mainTable],
          layout: "auto",
        },
      },
    ],

    images: allImages,

    styles: {
      header: {
        fontSize: 22,
        bold: true,
      },
      anotherStyle: {
        fontSize: 15,
        italics: true,
      },
      nestedTable: {
        fontSize: 9,
      },
      mainTable: {
        fontSize: 10,
      },
    },
  };

  // Image definitions
  // const allImages = data.reduce(
  //   (acc, item) => {
  //     const products = { [item.sku]: item.src };
  //     return {
  //       ...acc,
  //       ...products,
  //     };
  //   },
  //   {
  //     DEFAULT:
  //       "https://cdn.shopify.com/s/files/1/0604/6957/5837/files/Littlebox_white_border_2_aae20d45-94e3-4b3f-adb1-674d40d94e9e_195x@2x.png?v=1649095358",
  //   }
  // );
  // console.log("all imahes", allImages);

  // const docDefinition = {
  //   content: [
  //     { text: "Products Manifest", style: "header" },
  //     `Manifest Sheet For : Little Box India`,
  //     `Date : ${new Date().toLocaleDateString()}`,
  //     `Pickup Address : Santana Folks LLP`,
  //     {
  //       style: "tableExample",
  //       table: {
  //         headerRows: 1,
  //         body: productTable,
  //       },
  //       // layout: "noBorders",
  //     },
  //   ],
  //   images: allImages,
  //   styles: {
  //     header: {
  //       fontSize: 18,
  //       bold: true,
  //       margin: [0, 0, 0, 10],
  //     },
  //     subheader: {
  //       fontSize: 16,
  //       bold: false,
  //       margin: [0, 10, 0, 5],
  //     },
  //     tableExample: {
  //       margin: [0, 5, 0, 5],
  //     },
  //     tableHeader: {
  //       bold: true,
  //       fontSize: 13,
  //       color: "black",
  //     },
  //   },
  // };

  // const options = {};
  // console.log("here");

  const newFileName = new Date().toLocaleDateString();

  pdfMake.createPdf(pdfDefinition).download(newFileName);

  console.log(newFileName);

  return newFileName;
};

export const createPDFForSkuWiseOrderOut = (data) => {
  const orderItems = data.reduce((acc, d) => {
    acc.push(...d.order_items);
    return acc;
  }, []);
  // create sku wise array
  const skuWise = orderItems.reduce((acc, oi) => {
    const amt = parseInt(oi.units);
    const sku = oi.sku.split(".")[0];
    const alphabetPart = sku.match(/[A-Za-z]+/)[0];
    const size = oi.variant_title;
    const findType = skuWiseTypes.find((st) => st.sku === alphabetPart);
    if (!findType) {
      console.log("not found", sku);
      return acc;
    }
    const type = findType.type;
    const category = findType.category;
    const sizes = getSizesByType(type).reduce((acc, sizeRow) => {
      acc[sizeRow] = 0;
      return acc;
    }, {});
    const found = acc.find((ac) => ac.sku === sku);
    if (found) {
      found.quantity[size] = found.quantity[size] + amt;
      found.total = found.total + amt;
    } else {
      const newObj = {
        sku,
        url: oi.url,
        quantity: sizes,
        total: amt,
        type,
        category,
      };
      newObj.quantity[size] = newObj.quantity[size] + amt;
      acc.push(newObj);
    }
    return acc;
  }, []);
  const totalQuantity = skuWise.reduce((acc, s) => acc + s.total, 0);
  // create images array for pdf
  const allImages = skuWise.reduce(
    (acc, item) => {
      return {
        ...acc,
        [`${item.sku}`]: item.url,
      };
    },
    {
      DEFAULT:
        "https://cdn.shopify.com/s/files/1/0604/6957/5837/files/Littlebox_white_border_2_aae20d45-94e3-4b3f-adb1-674d40d94e9e_195x@2x.png?v=1649095358",
    }
  );

  // category wise sorted
  const categoryWise = skuWise.reduce((acc, sk) => {
    const found = acc.find((ac) => ac.category === sk.category);
    if (found) {
      found.data = [...found.data, sk];
    } else {
      const newObj = {
        category: sk.category,
        data: [sk],
      };
      acc.push(newObj);
    }
    return acc;
  }, []);

  const createQuantityList = (quantity) => {
    const sizes = Object.keys(quantity);
    const temp = sizes.map((x) => {
      return [quantity[x]];
    });
    return [sizes, temp];
  };
  const productTable = (data) => {
    const row = data.map((o) => [
      {
        image: o.sku || "DEFAULT",
        width: 40,
        height: 40,
      },
      `${o.sku}`,
      {
        style: "tableExample",
        table: {
          // headerRows: 1,
          body: createQuantityList(o.quantity),
        },
        layout: "noBorders",
      },
      o.total,
    ]);
    row.unshift([
      { text: "Image", style: "tableHeader" },
      { text: "SKU", style: "tableHeader" },
      { text: "Quantity", style: "tableHeader" },
      { text: "Total", style: "tableHeader" },
    ]);
    return row;
  };

  const docTable = categoryWise.reduce((acc, cw) => {
    const totalCategoryQnty = cw.data.reduce(
      (acc, item) => acc + item.total,
      0
    );
    const categoryName = { text: `${cw.category}`, style: "header" };
    const categoryInfo = `Total SKU's :${cw.data.length} Total Quantity : ${totalCategoryQnty}`;
    const data = cw.data;
    acc.push(categoryName, categoryInfo, {
      style: "tableExample",
      table: {
        headerRows: 1,
        body: productTable(data),
      },
      // layout: "noBorders",
    });
    return acc;
  }, []);

  const skuInfoTable = categoryWise.reduce((acc, cw) => {
    const category = cw.category;
    const totalSKU = cw.data.length;
    const totalQnty = cw.data.reduce((accQnty, d) => accQnty + d.total, 0);
    const arr = [category, `${totalSKU}`, `${totalQnty}`];
    acc.push(arr);
    return acc;
  }, []);
  skuInfoTable.unshift([
    { text: "Category", style: "tableHeader" },
    { text: "Total SKU's", style: "tableHeader" },
    { text: "Total Quantity", style: "tableHeader" },
  ]);
  const skuInfoTableDef = {
    style: "tableExample",
    table: {
      headerRows: 1,
      body: skuInfoTable,
    },
    // layout: "noBorders",
  };

  const docDefinition = {
    content: [
      { text: "SKU Wise List", style: "header" },
      `Manifest Sheet For : Little Box India`,
      `Date : ${new Date().toLocaleDateString()}`,
      `Pickup Address : Santana Folks LLP`,
      `Total Quantity : ${totalQuantity}`,
      skuInfoTableDef,
      ...docTable,
    ],
    images: allImages,
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 16,
        bold: false,
        margin: [0, 10, 0, 5],
      },
      tableExample: {
        margin: [0, 5, 0, 5],
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
      },
    },
  };

  // const options = {};

  const newFileName = `sku_quantity_${new Date().toLocaleDateString()}`; //cryptoRandomString({ length: 20, type: "url-safe" });

  pdfMake.createPdf(docDefinition).download(newFileName);

  console.log(newFileName);

  return newFileName;
};

export const createInvoicePDF = (order) => {
  const totalQuantity = order.order_items.reduce((sum, i) => i.units + sum, 0);

  const productsTable = order.order_items.reduce((acc, item) => {
    const calculatedGST = getGSTbyLineItem(item).toFixed(2);
    const name = item.name.substring(0, 80);
    const quantity = item.units;
    const price = formatCurrency(
      item.selling_price * item.units - calculatedGST
    );
    const gst = formatCurrency(calculatedGST);
    const total = formatCurrency(item.selling_price * item.units);
    const arr = [name, quantity, price, gst, total];
    acc.push(arr);
    return acc;
  }, []);
  productsTable.unshift([
    { text: "Product Name", style: "tableHeader" },
    { text: "Quantity", style: "tableHeader" },
    { text: "Price", style: "tableHeader" },
    { text: "GST", style: "tableHeader" },
    { text: "Total", style: "tableHeader" },
  ]);
  // Main PDF
  const docDefinition = {
    content: [
      { text: "Good Tribe Pvt. Lmt", fontSize: 30, bold: true },
      `Invoice# ${order?.awb_code}`,
      `Shopify Order Id: ${order.fulfillment_id?.split(".")[0]}`,
      `Invoice Date : ${new Date(order?.processed_at).toLocaleDateString()}`,
      `Total Quantity : ${totalQuantity}`,
      {
        style: "columnStyle",
        columns: [
          {
            width: "50%",
            stack: [
              {
                text: "Sold By: ",
                bold: true,
              },
              "Goodtribe Pvt. Lmt.",
              "First Floor, KH. No. 382",
              "Front Side 100 Phoota Road",
              "Ghitorni, South Delhi",
              "New Delhi, PIN: 110030",
            ],
          },
          {
            width: "50%",
            stack: [
              {
                text: `Billing Address`,
                bold: true,
              },
              `${order.billing_customer_name} ${order.billing_last_name}`,
              `${order.billing_address}`,
              `${order.billing_address_2}`,
              `${order.billing_city}`,
              `${order.billing_state} PIN: ${order.billing_pincode}`,
            ],
          },
        ],
      },
      {
        style: "columnStyle",
        columns: [
          {
            width: "50%",
            stack: [
              {
                text: "PAN No: AAICG9642F",
                bold: true,
              },
              {
                text: "GST Registration No: 07AAICG9642F1ZE",
                bold: true,
              },
              {
                text: `${order.payment_method}: by Delhivery`,
                bold: true,
              },
              `AWB: ${order?.awb_code}`,
              `INR: ${order.total}`,
            ],
          },
          {
            width: "50%",
            stack: [
              {
                text: `Shipping Address`,
                bold: true,
              },
              `${order.shipping_customer_name} ${order.shipping_last_name}`,
              `${order.shipping_address}`,
              `${order.shipping_address_2}`,
              `${order.shipping_city}`,
              `${order.shipping_state} PIN: ${order.shipping_pincode}`,
            ],
          },
        ],
      },
      {
        style: "tableExample",
        table: {
          headerRows: 1,
          body: productsTable,
        },
      },
      {
        text: `Shipping charges: ${formatCurrency(order.shipping_charges)}`,
        alignment: "right",
      },
      { text: `Total: ${order.total}`, bold: true, alignment: "right" },
    ],
    images: [],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 16,
        bold: false,
        margin: [0, 10, 0, 5],
      },
      tableExample: {
        width: "100%",
        margin: [0, 50, 0, 5],
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
      },
      columnStyle: {
        margin: [0, 40, 0, 0],
      },
    },
  };

  // const options = {};

  const newFileName = `invoice_${order.awb_code}`;

  pdfMake.createPdf(docDefinition).download(newFileName);

  console.log(newFileName);

  return newFileName;
};

export const createMaterialChallan = (
  context,
  materials,
  vendorName,
  challanNumber
) => {
  const materialTable = materials.reduce((acc, item, currentIndex) => {
    const serialNo = String(currentIndex + 1);
    const material = `${item.fabricName} ${item.colorName}`;
    const amt = String(item.amt);
    const arr = [serialNo, material, amt];
    acc.push(arr);
    return acc;
  }, []);
  materialTable.unshift([
    { text: "No.", style: "tableHeader" },
    { text: "Material", style: "tableHeader" },
    { text: "Amount (In Roll)", style: "tableHeader" },
  ]);
  // Main PDF
  const docDefinition = {
    content: [
      { text: "Good Tribe Pvt. Lmt", fontSize: 30, bold: true },
      `Challan Number# ${challanNumber}`,
      `${context} : ${vendorName}`,
      `Challan Date : ${new Date().toLocaleDateString("en-in")}`,
      {
        style: "tableExample",
        table: {
          headerRows: 1,
          body: materialTable,
        },
      },
    ],
    images: [],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 16,
        bold: false,
        margin: [0, 10, 0, 5],
      },
      tableExample: {
        width: "100%",
        margin: [0, 50, 0, 5],
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
      },
      columnStyle: {
        margin: [0, 40, 0, 0],
      },
    },
  };

  // const options = {};

  const newFileName = `challan_${vendorName}_${new Date().toLocaleDateString(
    "en-in"
  )}`;

  pdfMake.createPdf(docDefinition).download(newFileName);

  console.log(newFileName);

  return newFileName;
};
