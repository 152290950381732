import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import UnfulfilledOrdersTable from "./UnfulfilledOrdersTable";
import { productTypes, categoryTypes } from "../../utils/helperArrays";
import { TextField, MenuItem } from "@mui/material";

const UnfulfilledProductsWithImage = ({ orderedProducts }) => {
    const inventoryStock = useSelector((state) => state.inventoryDetails.stock);
    const shopifyInventory = useSelector(
        (state) => state.orderDetails.shopifyInventory
    );
    const productVariants = useSelector(
        (state) => state.productDetails.productVariants
    );
    const products = useSelector((state) => state.productDetails.products);

    const [tableData, setTableData] = useState([]);
    const [productType, setProductType] = useState("");
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [categoryTypesArray, setCategoryTypesArray] = useState([]);
    const [productVariantList, setProductVariantList] = useState([]);

    useEffect(() => {
        //Create the Tabledata
        if (
            orderedProducts.length > 0 &&
            inventoryStock.length > 0 &&
            productVariantList.length > 0
        ) {
            const data = orderedProducts.map((p) => {
                let imageObj = null;
                const inventoryFound = inventoryStock.filter(
                    (item) => item.SKU === p.sku
                );
                let productStock = {};
                if (inventoryFound.length > 0) {
                    const mapped = inventoryFound.map((item) => ({
                        [item.size]: item.count,
                    }));
                    productStock = Object.assign({}, ...mapped);

                    imageObj = inventoryFound[0];
                }
                //add material
                const material =
                    imageObj && "material" in imageObj ? imageObj.material : [];
                let src = null;
                if (imageObj) {
                    src = imageObj.imageUrl;
                } else {
                    src =
                        "https://sss-retail-images.s3.amazonaws.com/404.jpg?w=256&q=75";
                }
                let variantArr = [];
                const variantObj = productVariantList.filter(
                    (item) => item.sku === p.sku
                );
                const productId = variantObj[0]?.product_id;
                const productCreatedAt = variantObj[0]?.created_at;
                // Calculate the time difference in milliseconds
                const timeDiff = new Date() - new Date(productCreatedAt);

                // Calculate the number of days
                const productAge = timeDiff / (1000 * 60 * 60 * 24);

                variantArr.push(
                    variantObj.map((x) => ({
                        size: x.title,
                        stock: x.stock ? x.stock : 0,
                    }))
                );
                const itemIdList = variantObj.reduce((acc, x) => {
                    acc[x.title] = x.inventory_item_id;
                    return acc;
                }, {});
                const status = products.find((p) => p.id === productId)?.status;
                return {
                    ...p,
                    src,
                    type: imageObj?.type,
                    inventoryStock: productStock,
                    itemIds: itemIdList,
                    stock: variantArr,
                    material,
                    productId,
                    status,
                    productAge,
                };
            });
            setTableData(data);
        }
    }, [orderedProducts, productVariantList, products, inventoryStock]);

    useEffect(() => {
        if (tableData.length > 0 && productType) {
            const getCategories = categoryTypes(productType);
            const filtered = tableData.filter(
                (item) => item.type === productType
            );
            setCategoryTypesArray(getCategories);
            setFilteredProducts(filtered);
        }
    }, [productType, tableData]);

    useEffect(() => {
        if (shopifyInventory.length > 0 && productVariants.length > 0) {
            const temp = productVariants.map((x) => {
                const variant = shopifyInventory.find(
                    (p) => p.inventory_item_id === x.inventory_item_id
                );
                let stock = 0;
                if (variant) {
                    stock = variant.available;
                } else {
                    stock = 0;
                }
                return {
                    ...x,
                    stock,
                };
            });
            setProductVariantList(temp);
        }
    }, [shopifyInventory, productVariants]);

    return (
        <div>
            <br />
            <div style={{ display: "flex" }}>
                <TextField
                    style={{ width: "500px" }}
                    select
                    label="Select Product Type"
                    value={productType}
                    onChange={(e) => setProductType(e.target.value)}
                    variant="outlined"
                    className="select"
                >
                    {productTypes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <br />
            {productType && tableData.length > 0 ? (
                <UnfulfilledOrdersTable
                    tableData={filteredProducts}
                    categoryTypes={categoryTypesArray}
                    productType={productType}
                />
            ) : (
                ""
            )}
        </div>
    );
};

export default UnfulfilledProductsWithImage;
