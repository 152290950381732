import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { Box, Button, TextField, Typography, Stack } from "@mui/material";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

import { io } from "socket.io-client";

import { shiprocketSyncData } from "../../actions/backendSync";
const ShipRocketSync = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [syncConnect, setSyncConnect] = useState(false);
  const [syncStatus, setSyncStatus] = useState({});
  const [errorStack, setErrorStack] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const socket = io("http://localhost:8000");
    socket.on("connect", () => {
      setSyncConnect(true);
    });
    socket.on("sync-status", (status, date, message) => {
      console.log(status, message);
      setSyncStatus((prev) => ({ ...prev, [status]: { message, date } }));
      socket.emit("dummy_run", "ok");
      if (status === "completed") socket.off("connect");
    });
    socket.on("error-status", (message) => {
      setErrorStack((prev) => [...prev, message]);
      socket.emit("dummy_run", "ok");
    });
  }, []);

  const handleSyncData = () => {
    const requiredData = {
      from: startDate,
      to: endDate,
    };
    dispatch(shiprocketSyncData(requiredData));
  };

  console.log("start", startDate);
  console.log("end", endDate);
  return (
    <Box sx={{ marginBottom: "50px", overflowX: "scroll" }}>
      <div>
        <h3>Shiprocket Sync</h3>
        <Stack direction={"row"} gap={2}>
          <TextField
            label="FROM"
            InputProps={{ inputProps: { max: endDate } }}
            InputLabelProps={{ shrink: true }}
            type="date"
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
          />
          <TextField
            label="TO"
            InputLabelProps={{ shrink: true }}
            type="date"
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
            defaultValue={endDate}
          />
          {startDate && endDate ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSyncData}
            >
              Sync Data
            </Button>
          ) : (
            ""
          )}
        </Stack>

        <br />
      </div>
      <br />
      <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
        <Stack gap={0.1}>
          <h2 style={{ margin: 0 }}>Status Logs ...</h2>
          {Object.keys(syncStatus).map((s, index) => (
            <Box>
              <Stack>
                <Stack direction={"row"} alignItems="center" gap={0.5}>
                  <AccessTimeFilledIcon
                    sx={{
                      color:
                        Object.keys(syncStatus).length - 1 > index
                          ? "green"
                          : "black",
                    }}
                  />
                  <h5 style={{ margin: 0, color: "#808080" }}>
                    {syncStatus[s]["date"]}
                  </h5>
                </Stack>
                <Typography
                  variant="h6"
                  component="p"
                  sx={{
                    fontStyle: "italic",
                    borderLeft: "3px solid",
                    borderColor:
                      Object.keys(syncStatus).length - 1 > index
                        ? "green"
                        : "black",
                    marginLeft: "8.5px",
                    paddingLeft: "10px",
                    color:
                      Object.keys(syncStatus).length - 1 > index
                        ? "green"
                        : "black",
                  }}
                >
                  {syncStatus[s]["message"]}
                </Typography>
              </Stack>
            </Box>
          ))}
        </Stack>
        <Stack>
          <h2 style={{ margin: 0 }}>Error Logs ...</h2>
          {errorStack.map((e) => (
            <>
              <Typography variant="h6" component="p">
                {e}
              </Typography>
            </>
          ))}
        </Stack>
      </Stack>
      <Box
        position={"fixed"}
        bottom={0}
        left={0}
        paddingLeft={1}
        sx={{ marginTop: "20px" }}
      >
        {syncConnect ? (
          <h3 style={{ color: "green" }}>Connected To LBSync</h3>
        ) : (
          <h3 style={{ color: "red" }}>Not Connected</h3>
        )}
      </Box>
    </Box>
  );
};

export default ShipRocketSync;
