import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getcustomercareusernames,
  getDynamoNdrOrdersData,
} from "../../actions/ordersActions";
import { showLoading, hideLoading, showFullScreenLoader } from "../../actions/loaderActions";
import NdrAdminFilter1 from "../ordersWidgets/ndr/NdrAdminFilter1";
import RedirectLogin from "../RedirectLogin";
import { Backdrop, CircularProgress } from "@mui/material";

const AssignNDR = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { xbNdrOrders } = useSelector((state) => state.orderDetails);
  // console.log(xbNdrOrders)
  const { customerCareUsers } = useSelector((state) => state.inventoryDetails);
  const { loading } = useSelector(state => state.loader)

  const [ orders, setOrders] = useState([])
  // console.log(orders)

  const todayDate = new Date();
  const changeDateFormat = (date) => {
    const reqDate = (date.getDate() <= 9 ? "0" : "") + date.getDate();
    const reqMonth =
        (date.getMonth() + 1 <= 9 ? "0" : "") + (date.getMonth() + 1);
    const reqFormat = `${[date.getFullYear(), reqMonth, reqDate].join(
        "-"
    )}T00:00:00+05:30`;
    return reqFormat;
  };

  const firstDayLastMonth = changeDateFormat(
      new Date(todayDate.getFullYear(), todayDate.getMonth() - 2, 1)
  );

  useEffect(() => {
    if(!auth) return
    dispatch(showFullScreenLoader())
    dispatch(getDynamoNdrOrdersData({
      p_status: ["sync#UD"],
      from_date: firstDayLastMonth,
    }))
    dispatch(getcustomercareusernames())
  }, [auth, dispatch]);

  const reqDateFormat = (date) => {
    const tempDate = date.split("-")
    const reqDate = [tempDate[1],tempDate[0],tempDate[2]].join("/")
    return reqDate
  }

  useEffect(() => {
    if(xbNdrOrders.length>0){
      const formattedOrders = xbNdrOrders
      .filter(x => !!x.last_ndr_details)
      .filter(x => !!x.total_ndr_attempts)
      .map(x => {
        const newObj = {
          ...x.last_ndr_details,
          POID : String(x.fulfillment_id),
          PendingDays : (new Date(reqDateFormat(x.last_ndr_details.StatusDate)).getTime() - new Date(x.sync_date).getTime())/(1000 * 60 * 60 * 24),
          TotalAttempt : x.total_ndr_attempts,
          customer_name : x.shipping_customer_name + " " + x.shipping_last_name,
          customer_email : x.billing_email,
          customer_address : x.shipping_address + " " + x.shipping_address_2,
          customer_city : x.shipping_city,
          customer_state : x.shipping_state,
          customer_pincode : x.shipping_pincode,
          customer_phone : x.shipping_phone,
          order_items : x.order_items,
          order_original_id : x.id,
          executive_id : x.executive_id === "1" ? "Not Assigned" : x.executive_id,
          total_order_price : x.total?.toString(),
          ordered_date : new Date(x.order_date).toLocaleDateString("en-in"),
          awb : x.awb_code,
          PaymentMode : x.payment_method,
          ShipmentDate : x.sync_date
        }
        return newObj
      })
      setOrders(formattedOrders)
      dispatch(hideLoading())
    }else{
      setOrders([])
      dispatch(hideLoading())
    }
  }, [xbNdrOrders])

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {(loading || orders.length === 0) 
          ? (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading || orders.length===0}
              >
              <CircularProgress size={100} color="inherit" />
            </Backdrop>
          ) : (
            <NdrAdminFilter1 tableData={orders} usernames = {customerCareUsers}/>
          )}
        </>
      )}
    </>
  );
};

export default AssignNDR;
