import React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TablePagination,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useDispatch, useSelector } from "react-redux";
import RtoProductViewModal from "./RtoProductViewModal";
import { updateRtoDeliveredOrders } from "../../actions/ordersActions";
import {
  hideFullScreenLoader,
  showFullScreenLoader,
} from "../../actions/loaderActions";
import { RtoDamagedModal } from "./RtoDamagedModal";
import { toast } from "react-toastify";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function Row({ row, index, setSearchValue, setSearchAwbValue }) {
  const [productModal, setProductModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [selectDamagedModal, setSelectDamagedModal] = useState(false);

  const { shopify_product_variants } = useSelector(
    (state) => state.shopifyDetails
  );

  const dispatch = useDispatch();

  const handleData = () => {
    let tempArr = [];
    tempArr.push(row);
    setModalData(tempArr);
  };

  const handleSubmit = (orderId) => {
    dispatch(showFullScreenLoader());
    let temp = [];
    console.log("row", row);
    row.order_items.forEach((x) => {
      const filteredVariant = shopify_product_variants.filter(
        (item) =>
          item.sku === x.sku.split(".")[0] && item.title === x.variant_title
      );
      if (filteredVariant.length > 0)
        temp.push({
          location_id: 65807843485,
          available_adjustment: x.units,
          inventory_item_id: filteredVariant[0].inventory_item_id,
        });
      else return;
    });
    if (temp.length > 0) {
      dispatch(updateRtoDeliveredOrders(temp, { id: orderId }));
      setSearchValue("");
      setSearchAwbValue("");
    } else {
      dispatch(hideFullScreenLoader());
      toast.error(
        "One or More Products Of This Order Is Not Available In Shopify"
      );
    }
  };

  return (
    <>
      <TableRow
        component={Paper}
        sx={{
          "&:hover": {
            backgroundColor: "rgb(220,220,220)",
          },
        }}
      >
        <TableCell
          component="th"
          scope="row"
          style={{ color: "blue" }}
          align="left"
        >
          <Typography>{row.fulfillment_id}</Typography>
        </TableCell>
        <TableCell align="left">
          <Box>
            <Typography>{row.awb_code ? row.awb_code : "N/A"}</Typography>
            <Typography
              onClick={() => {
                setProductModal(true);
                handleData();
              }}
              style={{
                fontSize: "x-small",
                color: "blue",
                cursor: "pointer",
                borderBottom: "2px dotted",
                width: "max-content",
              }}
            >
              View Products
            </Typography>
            {productModal ? (
              <RtoProductViewModal
                productModal={productModal}
                tableData={modalData}
                setProductModal={setProductModal}
              />
            ) : (
              ""
            )}
          </Box>
        </TableCell>
        <TableCell align="left">
          {new Date(row.rto_declared_on).toLocaleDateString("en-in")}
        </TableCell>
        <TableCell align="left">
          {new Date(row.rto_delivered_on).toLocaleDateString("en-in")}
        </TableCell>
        <TableCell align="left">
          {!row.rto_inventory_updated ? (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => handleSubmit(row.fulfillment_id)}
              >
                Approve
              </Button>
              <br />
              <Button
                variant="contained"
                color="error"
                onClick={() => setSelectDamagedModal(true)}
              >
                Damaged
              </Button>
            </Box>
          ) : (
            "....."
          )}
          {selectDamagedModal ? (
            <RtoDamagedModal
              orderId={row.fulfillment_id}
              open={selectDamagedModal}
              setSelectDamagedModal={setSelectDamagedModal}
              orderProducts={row.order_items}
              productVariants={shopify_product_variants}
              setSearchValue={setSearchValue}
              setSearchAwbValue={setSearchAwbValue}
            />
          ) : (
            ""
          )}
        </TableCell>
      </TableRow>
    </>
  );
}

const RtoDeliveredTable = ({ data, setSearchValue, setSearchAwbValue }) => {
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    if (data.length > 0)
      setTableData(
        data
          .sort((a, b) => new Date(a.sync_date) - new Date(b.sync_date))
          .reverse()
      );
  }, [data]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead component={Paper}>
            <TableRow>
              <TableCell align="left">Order Number</TableCell>
              <TableCell align="left">AWB Code</TableCell>
              <TableCell align="left">RTO Date</TableCell>
              <TableCell align="left">Delivery Date</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <br />
          <TableBody>
            {(rowsPerPage > 0
              ? tableData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : tableData
            ).map((row, index) => (
              <Row
                key={index}
                row={row}
                index={index}
                setSearchValue={setSearchValue}
                setSearchAwbValue={setSearchAwbValue}
              />
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <TablePagination
        style={{
          overflow: "inherit",
          display: "flex",
          justifyContent: "center",
          background: "white",
          borderRadius: "10px",
        }}
        rowsPerPageOptions={[5, 10, 25, 100]}
        colSpan={3}
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </>
  );
};

export default RtoDeliveredTable;
