import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRejectedReturnExchangeOrders } from "../../actions/ordersActions";
import RedirectLogin from "../RedirectLogin";
import RejectedREOrders from "../ordersWidgets/RejectedREOrders";

const RejectedRE = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { rejectedREOrders } = useSelector((state) => state.orderDetails);
  const loading = useSelector((state) => state.loader.loading);

  useEffect(() => {
    if (rejectedREOrders.length === 0 && auth)
      dispatch(getRejectedReturnExchangeOrders());
  }, [auth]);

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {loading && (
            <div className="load-overlay">
              <h1>Please Wait...</h1>
            </div>
          )}
          <h1>Rejected Returns/Exchange Requests</h1>

          <div>
            <p>Rejected Returns/Exchanges: {rejectedREOrders.length}</p>
            <RejectedREOrders orders={rejectedREOrders} />
          </div>
        </>
      )}
    </>
  );
};

export default RejectedRE;
