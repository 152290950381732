import { useState, useEffect } from "react";
import { createPDFforManifest } from "../../utils/pdfUtils";
import { skuTypes } from "../../utils/helperArrays";
import { formatCurrency } from "../../utils/orderTakeoutHelpers";
import { CSVLink, CSVDownload } from "react-csv";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
  Button
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

const ExportReactCSV = ({csvData, fileName}) => {
  return (
      <Button
        variant="contained"
        color="secondary"
      >
          <CSVLink data={csvData} filename={fileName}>Export Excel</CSVLink>
      </Button>
  )
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function Row({ row }) {

  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          {row.channel_order_id}
        </TableCell>
        <TableCell align="right">
          {row.updated_at}
        </TableCell>
        <TableCell align="right">{row.customer_name}</TableCell>
        <TableCell align="right">
          {row.shipments[0].awb}
        </TableCell>
        <TableCell align="right">
          {row.shipments[0].courier}
        </TableCell>
        <TableCell align="right">
          <Button 
            variant="contained"
          >
            Confirm Delivered
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function RtoOrdersTable({ tableData }) {
  const [data, setData] = useState(
    tableData
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setData(
      [...tableData].sort((a, b) => Number(a.order_number) - Number(b.order_number)).reverse()
    );
  }, [tableData]);

  const handleSubmit = async (data) => {
    <CSVLink data={data} filename={new Date()}>Export</CSVLink>
  };

  return (
    <>
      <br />
      <>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Order Number</TableCell>
                <TableCell align="right">Delivered Date</TableCell>
                <TableCell align="right">Customer</TableCell>
                <TableCell align="right">AWB</TableCell>
                <TableCell align="right">Courier</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? data.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : data
              ).map((row, index) => (
                <Row key={index} row={row} index={index} />
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  style={{ overflow: "inherit" }}
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <br />
        <br />
        <ExportReactCSV csvData={data} fileName={new Date().toDateString()} />
      </>
    </>
  );
}
