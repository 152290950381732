const initialState = {
    loading: false,
    success: false,
    failure : false
  };
  const loaderReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SHOW_LOADER":
        return {
          ...state,
          loading: true,
        };
      case "HIDE_LOADER":
        return {
          ...state,
          loading: false,
        };
      case "SHOW_SUCCESS":
        return {
          ...state,
          success: true,
        };
      case "HIDE_SUCCESS":
        return {
          ...state,
          success: false,
        };
      case "SHOW_FAILURE":
        return {
          ...state,
          failure: true,
        };  
      case "HIDE_FAILURE":
        return {
          ...state,
          failure: false,
        };

      default:
        return state;
    }
  };
  export default loaderReducer;