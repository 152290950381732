import React from 'react'
import { useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import { NdrEditOrderModal } from './ndr/NdrEditOrderModal';
import { NdrEditAddressModal } from './ndr/NdrEditAddressModal';
import NdrRtoModal from './ndr/NdrRtoModal';
import NdrOnHoldModal from './ndr/NdrOnHoldModal';
import NdrCloseModal from './ndr/NdrCloseModal';

const NonDeliveryReportButton = ({rowData}) => {
        const options = ['Change Delivery Date', 'Change Customer Address', 'RTO', 'Close']
        // const options = ['Change Delivery Date', 'Change Customer Address', 'RTO'];
        const [open, setOpen] = React.useState(false);
        const anchorRef = React.useRef(null);
        const [selectedIndex, setSelectedIndex] = React.useState("");
        const [editDetailModal, setEditDetailModal]  = useState(false)
        const [ editAddressModal, setEditAddressModal ] = useState(false)
        const [ rtoModal, setRtoModal ] = useState(false)
        const [ onHoldModal,  setOnHoldModal] = useState(false)
        const [closeModal, setCloseModal] = useState(false)

        const handleClick = () => {
          if(selectedIndex === 0){
            setEditDetailModal(true)
          }else if(selectedIndex === 1){
            setEditAddressModal(true)
          }else if(selectedIndex === 2){
            setRtoModal(true)
          }else if(selectedIndex === 3){
            setCloseModal(true)
          }
            setSelectedIndex("")
        };

        const handleMenuItemClick = (event, index) => {
            setSelectedIndex(index);
            setOpen(false);
        };

        const handleToggle = () => {
            setOpen((prevOpen) => !prevOpen);
        };

        const handleClose = (event) => {
            if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
            }
            setOpen(false);
        };
  return (
    <div>
        <React.Fragment>
          <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button" >
            <Button style={{width:"150px"}} onClick={handleClick} >{options[selectedIndex]} </Button>
            <Button
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          
          <Popper
            sx={{
              zIndex: 1,
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {options.map((option, index) => (
                        <MenuItem
                          key={option}
                          selected={index === selectedIndex}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        
        </React.Fragment>
        
        {editDetailModal ? (<NdrEditOrderModal open={editDetailModal} setEditDetailModal={setEditDetailModal} rowData={rowData}/>) : ""}
        {editAddressModal ? (<NdrEditAddressModal open={editAddressModal} setEditAddressModal={setEditAddressModal} rowData={rowData}/>) : ""}
        {rtoModal ? (<NdrRtoModal open={rtoModal} setRtoModal={setRtoModal} rowData={rowData}/>) : ""}
        {/* { onHoldModal ? (<NdrOnHoldModal open={onHoldModal} setOnHoldModal={setOnHoldModal} rowData={rowData}/>) : ""} */}
        { closeModal ? (<NdrCloseModal open={closeModal} setCloseModal={setCloseModal} rowData={rowData}/>) : ""}
    </div>
  )
}
export default NonDeliveryReportButton
