import { TextField, MenuItem, Box } from '@mui/material'
import React from 'react'
import { useState, useEffect } from 'react'
import { OrderTypeOptions,
         TotalAttemptOptions,
         PendingDayOptions,
         LastDeliveryStatusOptions } from '../../../utils/helperArrays';  
import AssignNDROrders from './AssignNDROrders';
import CancelIcon from '@mui/icons-material/Cancel';
import { toast } from 'react-toastify';
import SearchIcon from "@mui/icons-material/Search";
          
        
const NdrAdminFilter1 = ({tableData, usernames, crm1, crm2, crm3}) => {

    const [data, setData] = useState(tableData)

    const [orderType, setOrderType] = useState("")
    const [orderTypeData, setOrderTypeData] = useState([])

    const [ totalAttemptsSelected, setTotalAttemptsSelected ] = useState([])
    const [ totalAttemptData, setTotalAttemptData] = useState([])


    const [pendingDaysSelected, setPendingDaysSelected] = useState([])
    const [pendingDaysData, setPendingDaysData] = useState([])
    
    const [lastDeliveryStatusSelected, setLastDeliveryStatusSelected] = useState([])
    const [lastStatusData, setLastStatusData] = useState([])

    const [ assigned, setAssigned] = useState("")
    const [reassignButton, setReassignButton] = useState(false)

    const [searchFilter, setSearchFilter] = useState("")

    useEffect(() => {
        const masterArray = [orderTypeData,totalAttemptData,pendingDaysData,lastStatusData]
        const filterEmptyArray = masterArray.filter(x => x.length !== 0)
        if(filterEmptyArray.length>0){
            const finalFilterResult = filterEmptyArray.reduce((a, b) => a.filter(c => b.includes(c)));
            setData(finalFilterResult)
        }else{
            setData(tableData)
        }
    },[orderTypeData,totalAttemptData,pendingDaysData,lastStatusData])

    useEffect(() => {
      setData(tableData);
    }, [tableData])

   
    useEffect(() => {
        if ( orderType !== ""){
            let tempData = []
            if (orderType === "All") {
            tempData = tableData
            }else{
            const filteredData = tableData.filter((option) => option.PaymentMode === orderType )
            tempData = filteredData
            }
            if(tempData.length>0) setOrderTypeData(tempData)
            else{
                setOrderType("")
                setOrderTypeData([])
                toast.error("No Data Found For This Filter.Please Select Another Option")
            }
        }
      },[orderType, tableData])

    useEffect(() => {
        if(totalAttemptsSelected.length>0){
            let tempData = []
            totalAttemptsSelected.map(x => {
                if(x === ">6"){
                    const filteredData = tableData.filter((option) => parseInt(option.TotalAttempt) > 6 )
                    tempData.push(filteredData)
                }else{
                    const filteredData = tableData.filter((option) => parseInt(option.TotalAttempt) === parseInt(x))
                    tempData.push(filteredData)
                }
            })
            if(!!tempData.find(p => p.length === 0)) toast.error("Selected Filter Data Doesn't Exist.Please Try Another Filter")
            if(tempData.length>0){
                const finalFilterResult = tempData.flat();
                setTotalAttemptData(finalFilterResult)
            }else{
                setTotalAttemptsSelected([])
                setTotalAttemptData([])
                toast.error("No Data Found For This Filter.Please Select Another Option")
            }
        }
    },[totalAttemptsSelected, tableData])

    useEffect(() => {
        if ( pendingDaysSelected.length>0) {
            let tempData = []
            pendingDaysSelected.map(x => {
                if(x  === "1-5"){
                    const filteredData = tableData.filter((option) => parseInt(option.PendingDays) >= 1 && parseInt(option.PendingDays) <= 5)
                    tempData.push(filteredData)
                }else if(x === "5-10"){
                    const filteredData = tableData.filter((option) => parseInt(option.PendingDays) >= 5 && parseInt(option.PendingDays) < 10)
                    tempData.push(filteredData)
                }else if (x === "10-15"){
                    const filteredData = tableData.filter((option) => parseInt(option.PendingDays) >= 10 && parseInt(option.PendingDays) < 15)
                    tempData.push(filteredData)
                }else if (x  === "15-20"){
                    const filteredData = tableData.filter((option) => parseInt(option.PendingDays) >= 15 && parseInt(option.PendingDays) < 20)
                    tempData.push(filteredData)
                }else if (x  === "20-25"){
                    const filteredData = tableData.filter((option) => parseInt(option.PendingDays) >= 20 && parseInt(option.PendingDays) < 25)
                    tempData.push(filteredData)
                }else if (x  === ">25"){
                    const filteredData = tableData.filter((option) => parseInt(option.PendingDays) >= 25)
                    tempData.push(filteredData)
                }
            })
            if(!!tempData.find(p => p.length === 0)) toast.error("Selected Filter Data Doesn't Exist.Please Try Another Filter")
            if(tempData.length>0){
                const finalFilterResult = tempData.flat();
                setPendingDaysData(finalFilterResult)
            }else{
                setPendingDaysSelected([])
                setPendingDaysData([])
                toast.error("No Data Found For This Filter.Please Select Another Option")
            }
        }
    },[pendingDaysSelected, tableData])  

    useEffect(() => {
        if ( lastDeliveryStatusSelected.length>0){
            let tempData = []
            lastDeliveryStatusSelected.map(x => {
                const filteredData = tableData.filter(option => (option.Status?.replaceAll(" ","").toLowerCase()) === x.replaceAll(" ","").toLowerCase())
                tempData.push(filteredData)
            })
            if(!!tempData.find(p => p.length === 0)) toast.error("Selected Filter Data Doesn't Exist.Please Try Another Filter")
            if(tempData.length>0){
                const finalFilterResult = tempData.flat();
                setLastStatusData(finalFilterResult)
            }
            else {
                setLastDeliveryStatusSelected([])
                setLastStatusData([])
                toast.error("No Data Found For This Filter.Please Select Another Option")
            }
        }
      },[lastDeliveryStatusSelected, tableData])

    useEffect(() => {
    if ( assigned !== ""){
        setData(tableData)
        setReassignButton(false)
        if (assigned === "Not Assigned"){
        const filterData = tableData.filter(option => option.executive_id === assigned)
        setData(filterData);
        setReassignButton(false)
        }else if(assigned === "All"){
        setData(tableData)
        setReassignButton(false)
        }else{
        const filteredData = tableData.filter(option => option.executive_id !== "Not Assigned")
        setData(filteredData)
        setReassignButton(true)
        }
    }
    },[assigned, tableData])

    useEffect(() => {
        if (crm1) {
            setTotalAttemptsSelected(TotalAttemptOptions);
        }
        }, [crm1, TotalAttemptOptions]);

    useEffect(() => {
    if (crm2) {
        setPendingDaysSelected(PendingDayOptions);
    }
    }, [crm2, PendingDayOptions]);

    useEffect(() => {
        if (crm2) {
            setLastDeliveryStatusSelected(LastDeliveryStatusOptions);
        }
        }, [crm3, LastDeliveryStatusOptions]);

    useEffect(() => {
        if(searchFilter !== ""){
            const searchedOrder = tableData.filter(x => x.POID.startsWith(searchFilter))
            if(searchedOrder) setData(searchedOrder)
            else return
        }else{
            setData(tableData)
        }
    },[searchFilter])
        
    return (
        <div>
            <div style = {{display:"flex", justifyContent:"center"}}>
                <>
                    <Box sx={{ display: "flex", paddingRight:"30px", alignItems: "flex-end", gap: 1 }}>
                        <SearchIcon />
                        <TextField
                        id="input-with-sx"
                        label="Search Order"
                        variant="standard"
                        onChange={(e) => setSearchFilter(e.target.value)}
                        />
                    </Box>
                    <TextField
                        select
                        label="Order Type"
                        value={orderType}
                        onChange={(e) => {
                            setOrderType(e.target.value)
                        }}
                        variant="outlined"
                        className="select"
                        style={{width:"230px"}}
                        >
                        {OrderTypeOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                            {option}
                            </MenuItem>
                        ))}
                    </TextField>
                    {orderTypeData.length>0 ? (
                        <CancelIcon style={{color:"red", margin:"10px"}} onClick={() => {
                            setOrderType("")
                            setOrderTypeData([])
                        }}/>
                    ) : ""}
                </>
                <>
                    <TextField
                        SelectProps={{
                        multiple: true,
                        value: totalAttemptsSelected,
                        onChange: (e) => {
                            if (crm1) return;
                            setTotalAttemptsSelected(e.target.value);
                        },
                        }}
                        sx={{ width: "230px" }}
                        variant="outlined"
                        select
                        label="Total Attempts"
                    >
                        {TotalAttemptOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                        ))}
                    </TextField>
                    {totalAttemptData.length>0 ? (
                        <CancelIcon style={{color:"red", margin:"10px"}} onClick={() => {
                            setTotalAttemptsSelected([])
                            setTotalAttemptData([])
                        }}/>
                    ) : ""}
                </>
                <>
                    <TextField
                        SelectProps={{
                        multiple: true,
                        value: pendingDaysSelected,
                        onChange: (e) => {
                            if (crm2) return;
                            setPendingDaysSelected(e.target.value);
                        },
                        }}
                        sx={{ width: "230px" }}
                        variant="outlined"
                        select
                        label="Pending Days"
                    >
                        {PendingDayOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                        ))}
                    </TextField>
                    {pendingDaysData.length>0 ? (
                        <CancelIcon style={{color:"red", margin:"10px"}} onClick={() => {
                            setPendingDaysSelected([])
                            setPendingDaysData([])
                        }}/>
                    ) : ""}
                </>
                <>
                    <TextField
                        SelectProps={{
                        multiple: true,
                        value: lastDeliveryStatusSelected,
                        onChange: (e) => {
                            if (crm3) return;
                            setLastDeliveryStatusSelected(e.target.value);
                        },
                        }}
                        sx={{ width: "230px" }}
                        variant="outlined"
                        select
                        label="Last Delivery Status"
                    >
                        {LastDeliveryStatusOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                        ))}
                    </TextField>
                    {lastStatusData.length>0 ? (
                        <CancelIcon style={{color:"red", margin:"10px"}} onClick={() => {
                            setLastDeliveryStatusSelected([])
                            setLastStatusData([])
                        }}/>
                    ) : ""}
                </>
                <>
                    <TextField
                        select
                        label="Assigned To"
                        value={assigned}
                        onChange={(e) => {
                            setAssigned(e.target.value)
                        }}
                        variant="outlined"
                        className="select"
                        style={{width:"230px"}}
                        >
                        {["All","Assigned","Not Assigned"].map((option) => (
                            <MenuItem key={option} value={option}>
                            {option}
                            </MenuItem>
                        ))}
                    </TextField>
                    {assigned !== "" ? (
                        <CancelIcon style={{color:"red", margin:"10px"}} onClick={() => {
                            setAssigned("")
                            setData(tableData)
                            setReassignButton(false)
                        }}/>
                    ) : ""}
                </>
            </div>
            <br/>
            <AssignNDROrders
              tableData={data}
              usernames={usernames}
              reassignButton = {reassignButton}
              setReassignButton = {setReassignButton}
            />
                                           
        </div>  
  )
}

export default NdrAdminFilter1