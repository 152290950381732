import { toast } from "react-toastify";
import { lambdaFetch } from "../utils/awsUtils";
import { hideFullScreenLoader } from "./loaderActions";

export const hideLoader = () => {
  return {
    type: "HIDE_LOADER",
  };
};

export const sendREapprovedMessage = (email) => {
  async function sendMail() {
    const Url =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/sendreturnexchangemessage";

    const response = await lambdaFetch(Url, {
      method: "POST",
      body: JSON.stringify({ status: "Approved", email }),
    });
    const data = await response.json();
    return data;
  }
  return (dispatch) => {
    sendMail()
      .then((data) => {
        if (data.statusCode === 200) {
          toast.success("Mail sended successfully");
        } else {
          toast.error("Error in sending mail");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const sendRErejectedMessage = (email) => {
  async function sendMail() {
    const Url =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/sendreturnexchangemessage";

    const response = await lambdaFetch(Url, {
      method: "POST",

      body: JSON.stringify({ status: "Rejected", email }),
    });
    if (response.status === 403) {
      return 403;
    }
    const data = await response.json();
    return data;
  }
  return (dispatch) => {
    sendMail()
      .then((data) => {
        if (data.statusCode === 200) {
          toast.success("Mail sended successfully");
        } else {
          alert("Error in sending mail");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getMonthlyReversePendingData = (dataBody) => {
  async function getData() {
    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/dashboard/reverse_monthly_analytics";

    let fetching = true;
    let orders = [];
    let key = null;
    while (fetching) {
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify({
          ...dataBody,
          package_status: "bsync#RPPickupPending",
          key,
        }),
      });
      if (response.status === 403) {
        fetching = false;
        return 403;
      }
      const data = await response.json();
      if (data.statusCode === 200) {
        const processingData = data.body;
        orders.push(...processingData);
        key = data.key;
        if (!key) {
          fetching = false;
        }
      } else {
        orders = [];
        fetching = false;
      }
    }
    return orders;
  }
  return (dispatch) => {
    // dispatch(showLoading());
    getData()
      .then((data) => {
        if (data === 403) {
          // console.log("rto delivered orders", data);
          // dispatch(showUnauthorizedModal());
          return;
        }
        if (data) {
          dispatch(getMonthlyReverseOFPData(dataBody));
          dispatch({
            type: "MONTHLY_REVERSE_NOT_PICKED_ORDERS",
            data,
          });
        } else {
          console.log("Error In Getting Reverse Pending Orders");
          dispatch(hideLoader());
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideLoader());
      });
  };
};

export const getMonthlyReverseOFPData = (dataBody) => {
  async function getData() {
    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/dashboard/reverse_monthly_analytics";

    let fetching = true;
    let orders = [];
    let key = null;
    while (fetching) {
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify({
          ...dataBody,
          package_status: "bsync#RPOutForPickup",
          key,
        }),
      });
      if (response.status === 403) {
        fetching = false;
        return 403;
      }
      const data = await response.json();
      if (data.statusCode === 200) {
        const processingData = data.body;
        orders.push(...processingData);
        key = data.key;
        if (!key) {
          fetching = false;
        }
      } else {
        orders = [];
        fetching = false;
      }
    }
    return orders;
  }
  return (dispatch) => {
    // dispatch(showLoading());
    getData()
      .then((data) => {
        if (data === 403) {
          // console.log("rto delivered orders", data);
          // dispatch(showUnauthorizedModal());
          return;
        }
        if (data) {
          dispatch(getMonthlyReverseAttemptData(dataBody));
          dispatch({
            type: "MONTHLY_REVERSE_NOT_PICKED_ORDERS",
            data,
          });
        } else {
          console.log("Error In Getting Reverse OFP Orders");
          dispatch(hideLoader());
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideLoader());
      });
  };
};

export const getMonthlyReverseAttemptData = (dataBody) => {
  async function getData() {
    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/dashboard/reverse_monthly_analytics";

    let fetching = true;
    let orders = [];
    let key = null;
    while (fetching) {
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify({
          ...dataBody,
          package_status: "bsync#RPAttempt",
          key,
        }),
      });
      if (response.status === 403) {
        fetching = false;
        return 403;
      }
      const data = await response.json();
      if (data.statusCode === 200) {
        const processingData = data.body;
        orders.push(...processingData);
        key = data.key;
        if (!key) {
          fetching = false;
        }
      } else {
        orders = [];
        fetching = false;
      }
    }
    return orders;
  }
  return (dispatch) => {
    // dispatch(showLoading());
    getData()
      .then((data) => {
        if (data === 403) {
          // console.log("rto delivered orders", data);
          // dispatch(showUnauthorizedModal());
          return;
        }
        if (data) {
          dispatch(getMonthlyReverseCancelledData(dataBody));
          dispatch({
            type: "MONTHLY_REVERSE_NOT_PICKED_ORDERS",
            data,
          });
        } else {
          console.log("Error In Getting Reverse Attempted Orders");
          dispatch(hideLoader());
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideLoader());
      });
  };
};

export const getMonthlyReverseCancelledData = (dataBody) => {
  async function getData() {
    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/dashboard/reverse_monthly_analytics";

    let fetching = true;
    let orders = [];
    let key = null;
    while (fetching) {
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify({
          ...dataBody,
          package_status: "bsync#RPCancel",
          key,
        }),
      });
      if (response.status === 403) {
        fetching = false;
        return 403;
      }
      const data = await response.json();
      if (data.statusCode === 200) {
        const processingData = data.body;
        orders.push(...processingData);
        key = data.key;
        if (!key) {
          fetching = false;
        }
      } else {
        orders = [];
        fetching = false;
      }
    }
    return orders;
  }
  return (dispatch) => {
    // dispatch(showLoading());
    getData()
      .then((data) => {
        if (data === 403) {
          // console.log("rto delivered orders", data);
          // dispatch(showUnauthorizedModal());
          return;
        }
        if (data) {
          dispatch(getMonthlyReversePickedupData(dataBody));
          dispatch({
            type: "MONTHLY_REVERSE_NOT_PICKED_ORDERS",
            data,
          });
        } else {
          console.log("Error In Getting Reverse Cancelled Orders");
          dispatch(hideLoader());
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideLoader());
      });
  };
};

export const getMonthlyReversePickedupData = (dataBody) => {
  async function getData() {
    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/dashboard/reverse_monthly_analytics";

    let fetching = true;
    let orders = [];
    let key = null;
    while (fetching) {
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify({
          ...dataBody,
          package_status: "bsync#RPPickDone",
          key,
        }),
      });
      if (response.status === 403) {
        fetching = false;
        return 403;
      }
      const data = await response.json();
      if (data.statusCode === 200) {
        const processingData = data.body;
        orders.push(...processingData);
        key = data.key;
        if (!key) {
          fetching = false;
        }
      } else {
        orders = [];
        fetching = false;
      }
    }
    return orders;
  }
  return (dispatch) => {
    // dispatch(showLoading());
    getData()
      .then((data) => {
        if (data === 403) {
          // console.log("rto delivered orders", data);
          // dispatch(showUnauthorizedModal());
          return;
        }
        if (data) {
          dispatch(getMonthlyReverseIntransitData(dataBody));
          dispatch({
            type: "MONTHLY_REVERSE_PICKED_ORDERS",
            data,
          });
        } else {
          console.log("Error In Getting Reverse PU Orders");
          dispatch(hideLoader());
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideLoader());
      });
  };
};

export const getMonthlyReverseIntransitData = (dataBody) => {
  async function getData() {
    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/dashboard/reverse_monthly_analytics";

    let fetching = true;
    let orders = [];
    let key = null;
    while (fetching) {
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify({
          ...dataBody,
          package_status: "bsync#IT",
          key,
        }),
      });
      if (response.status === 403) {
        fetching = false;
        return 403;
      }
      const data = await response.json();
      if (data.statusCode === 200) {
        const processingData = data.body;
        orders.push(...processingData);
        key = data.key;
        if (!key) {
          fetching = false;
        }
      } else {
        orders = [];
        fetching = false;
      }
    }
    return orders;
  }
  return (dispatch) => {
    // dispatch(showLoading());
    getData()
      .then((data) => {
        if (data === 403) {
          // console.log("rto delivered orders", data);
          // dispatch(showUnauthorizedModal());
          return;
        }
        if (data) {
          dispatch(getMonthlyReverseRadData(dataBody));
          dispatch({
            type: "MONTHLY_REVERSE_PICKED_ORDERS",
            data,
          });
        } else {
          console.log("Error In Getting Reverse Intransit Orders");
          dispatch(hideLoader());
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideLoader());
      });
  };
};

export const getMonthlyReverseRadData = (dataBody) => {
  async function getData() {
    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/dashboard/reverse_monthly_analytics";

    let fetching = true;
    let orders = [];
    let key = null;
    while (fetching) {
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify({
          ...dataBody,
          package_status: "bsync#RAD",
          key,
        }),
      });
      if (response.status === 403) {
        fetching = false;
        return 403;
      }
      const data = await response.json();
      if (data.statusCode === 200) {
        const processingData = data.body;
        orders.push(...processingData);
        key = data.key;
        if (!key) {
          fetching = false;
        }
      } else {
        orders = [];
        fetching = false;
      }
    }
    return orders;
  }
  return (dispatch) => {
    // dispatch(showLoading());
    getData()
      .then((data) => {
        if (data === 403) {
          // console.log("rto delivered orders", data);
          // dispatch(showUnauthorizedModal());
          return;
        }
        if (data) {
          dispatch(getMonthlyReverseOfdData(dataBody));
          dispatch({
            type: "MONTHLY_REVERSE_PICKED_ORDERS",
            data,
          });
        } else {
          console.log("Error In Getting Reverse RAD Orders");
          dispatch(hideLoader());
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideLoader());
      });
  };
};

export const getMonthlyReverseOfdData = (dataBody) => {
  async function getData() {
    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/dashboard/reverse_monthly_analytics";

    let fetching = true;
    let orders = [];
    let key = null;
    while (fetching) {
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify({
          ...dataBody,
          package_status: "bsync#OFD",
          key,
        }),
      });
      if (response.status === 403) {
        fetching = false;
        return 403;
      }
      const data = await response.json();
      if (data.statusCode === 200) {
        const processingData = data.body;
        orders.push(...processingData);
        key = data.key;
        if (!key) {
          fetching = false;
        }
      } else {
        orders = [];
        fetching = false;
      }
    }
    return orders;
  }
  return (dispatch) => {
    // dispatch(showLoading());
    getData()
      .then((data) => {
        if (data === 403) {
          // console.log("rto delivered orders", data);
          // dispatch(showUnauthorizedModal());
          return;
        }
        if (data) {
          dispatch({
            type: "MONTHLY_REVERSE_PICKED_ORDERS",
            data,
          });
        } else {
          console.log("Error In Getting Reverse OFD Orders");
          dispatch(hideLoader());
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideLoader());
      });
  };
};

export const getMonthlyReverseDeliveredData = (dataBody) => {
  async function getData() {
    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/dashboard/reverse_monthly_analytics";

    let fetching = true;
    let orders = [];
    let key = null;
    while (fetching) {
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify({
          ...dataBody,
          package_status: "R_DLVD",
          key,
        }),
      });
      if (response.status === 403) {
        fetching = false;
        return 403;
      }
      const data = await response.json();
      if (data.statusCode === 200) {
        const processingData = data.body;
        orders.push(...processingData);
        key = data.key;
        if (!key) {
          fetching = false;
        }
      } else {
        orders = [];
        fetching = false;
      }
    }
    return orders;
  }
  return (dispatch) => {
    // dispatch(showLoading());
    getData()
      .then((data) => {
        if (data === 403) {
          // console.log("rto delivered orders", data);
          // dispatch(showUnauthorizedModal());
          return;
        }
        if (data) {
          dispatch({
            type: "MONTHLY_REVERSE_DELIVERED_ORDERS",
            data,
          });
        } else {
          console.log("Error In Getting Reverse Delivered Orders");
          dispatch(hideLoader());
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideLoader());
      });
  };
};

export const clearReverseDataByStatus = () => {
  return {
    type: "CLEAR_REVERSE",
  };
};

export const changeFilteredOrdersArray = (payload) => {
  return {
    type: "CHANGE_FILTERED_ORDERS",
    payload: payload,
  };
};

export const getReverseDataByStatus = (orderBody) => {
  async function getData(reqBody) {
    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/reverseorders/get_orders";

    let fetching = true;
    let orders = [];
    let key = null;
    while (fetching) {
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify({ ...reqBody, key }),
      });

      const data = await response.json();
      if (data.statusCode === 200) {
        const processingData = data.body;
        orders.push(...processingData);
        key = data.key;
        if (!key) {
          fetching = false;
        }
      } else {
        orders = [];
        fetching = false;
      }
    }
    return orders;
  }
  return (dispatch) => {
    // dispatch(showLoading());
    for (let i = 0; i < orderBody.p_status.length; i++) {
      const payload = {
        package_status: orderBody.p_status[i],
        from_date: orderBody.from_date,
      };
      getData(payload)
        .then((data) => {
          // dispatch(hideLoading());
          if (data) {
            dispatch({
              type: "REVERSE_BY_STATUS",
              data,
            });
          } else {
            console.log("Error in Fetching Data");
            toast.error("Error in fetching data");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};

export const clearReverseReportData = () => {
  return {
    type: "CLEAR_REVERSE_REPORT",
  };
};

export const getReverseDataByStatusReport = (orderBody) => {
  async function getData(reqBody) {
    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/dashboard/reverse_monthly_analytics";

    let fetching = true;
    let orders = [];
    let key = null;
    while (fetching) {
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify({ ...reqBody, key }),
      });

      const data = await response.json();
      if (data.statusCode === 200) {
        const processingData = data.body;
        orders.push(...processingData);
        key = data.key;
        if (!key) {
          fetching = false;
        }
      } else {
        orders = [];
        fetching = false;
      }
    }
    return orders;
  }
  return (dispatch) => {
    // dispatch(showLoading());
    for (let i = 0; i < orderBody.p_status.length; i++) {
      const payload = {
        package_status: orderBody.p_status[i],
        from_date: orderBody.from_date,
        to_date: orderBody.to_date,
      };

      getData(payload)
        .then((data) => {
          // dispatch(hideLoading());
          if (data) {
            dispatch({
              type: "REVERSE_BY_STATUS_REPORT",
              data,
            });
          } else {
            console.log("Error in Fetching Data");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};

export const clearForwardReportData = () => {
  return {
    type: "CLEAR_FORWARD_REPORT",
  };
};

export const getForwardDataByStatusReport = (orderBody) => {
  async function getData(reqBody) {
    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/dashboard/reverse_monthly_analytics";

    let fetching = true;
    let orders = [];
    let key = null;
    while (fetching) {
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify({ ...reqBody, key }),
      });

      const data = await response.json();
      if (data.statusCode === 200) {
        const processingData = data.body;
        orders.push(...processingData);
        key = data.key;
        if (!key) {
          fetching = false;
        }
      } else {
        orders = [];
        fetching = false;
      }
    }
    return orders;
  }
  return (dispatch) => {
    // dispatch(showLoading());
    for (let i = 0; i < orderBody.p_status.length; i++) {
      const payload = {
        package_status: orderBody.p_status[i],
        from_date: orderBody.from_date,
        to_date: orderBody.to_date,
      };

      getData(payload)
        .then((data) => {
          // dispatch(hideLoading());
          if (data) {
            dispatch({
              type: "FORWARD_BY_STATUS_REPORT",
              data,
            });
          } else {
            console.log("Error in Fetching Data");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};

export const getReverseDeliveredDataByStatus = (orderBody) => {
  console.log(orderBody);
  async function getData(reqBody) {
    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/reverseorders/get_orders";

    let fetching = true;
    let orders = [];
    let key = null;
    while (fetching) {
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify({ ...reqBody, key }),
      });

      const data = await response.json();
      if (data.statusCode === 200) {
        const processingData = data.body;
        orders.push(...processingData);
        key = data.key;
        if (!key) {
          fetching = false;
        }
      } else {
        orders = [];
        fetching = false;
      }
    }
    return orders;
  }
  return (dispatch) => {
    // dispatch(showLoading());
    for (let i = 0; i < orderBody.p_status.length; i++) {
      const payload = {
        package_status: orderBody.p_status[i],
        from_date: orderBody.from_date,
      };

      getData(payload)
        .then((data) => {
          // dispatch(hideLoading());
          if (data) {
            dispatch({
              type: "REVERSE_DELIVERED_BY_STATUS",
              data,
            });
          } else {
            console.log("Error in Fetching Data");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};

export const getLastXBReverseAWB = async () => {
  let awb;
  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/littleboxgetawbnumber";
  //TODO GET LAST AWB and INCREMENT
  try {
    const response = await lambdaFetch(URL, {
      method: "POST",
      body: JSON.stringify({}),
    });
    const data = await response.json();
    awb = data.awb;
    return awb;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const postXBReverseOrder = (productBody, email, orderId, comment) => {
  const reverseOrder = async () => {
    const Url =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/xbpostreverseorder";
    const response = await lambdaFetch(Url, {
      method: "POST",
      body: JSON.stringify({ productBody, comment }),
    });
    const data = await response.json();
    console.log("xpress bees response", data);
    return data;
  };
  return (dispatch) => {
    reverseOrder()
      .then((data) => {
        if (data.statusCode === 200) {
          console.log("success reverse", data.body);
          dispatch({
            type: "UPDATE_REVERSE_BY_STATUS",
            payload: orderId,
          });
          if (email) {
            dispatch(sendREapprovedMessage(email));
          } else {
            alert("Email not provided");
          }
          console.log("approved successfully");
          dispatch(hideFullScreenLoader());
        } else if (data.statusCode === 400) {
          alert(data.body);
          dispatch(hideFullScreenLoader());
        } else {
          alert("Failed", data.body);
          dispatch(hideFullScreenLoader());
        }
      })
      .catch((error) => {
        alert("reverse", error);
        dispatch(hideFullScreenLoader());
      });
  };
};

export const denyRErequest = (payload, email, orderId) => async (dispatch) => {
  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/littleboxupdatereverseorderstatus";
  try {
    const response = await lambdaFetch(URL, {
      method: "POST",
      body: JSON.stringify({
        fulfillmentId: payload.id,
        status: "rejected",
        rejection_reason: payload.reason,
      }),
    });
    const data = await response.json();

    if (data.statusCode === 200) {
      toast.success("Rejected R/E Order Successfully.");
      if (email) {
        dispatch(sendRErejectedMessage(email));
      } else {
        alert("Email not provided");
      }
      dispatch({
        type: "UPDATE_REVERSE_BY_STATUS",
        payload: orderId,
      });
      dispatch(hideFullScreenLoader());
    } else {
      alert("Error In Rejecting Order");
      dispatch(hideFullScreenLoader());
    }
  } catch (err) {
    console.error(err);
    dispatch(hideFullScreenLoader());
  }
};

export const reinstateRErequest = (id) => async (dispatch) => {
  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/littleboxupdatereverseorderstatus";
  try {
    const response = await lambdaFetch(URL, {
      method: "POST",
      body: JSON.stringify({
        fulfillmentId: id,
        status: "new",
      }),
    });
    const data = await response.json();

    if (data.statusCode === 200) {
      console.log("Reinstated R/E Order Successfully.");
      dispatch({
        type: "UPDATE_REVERSE_BY_STATUS",
        payload: id,
      });
      dispatch(hideFullScreenLoader());
    } else {
      alert(`Server Error ${data.statusCode}`);
      dispatch(hideFullScreenLoader());
    }
  } catch (err) {
    console.error(err);
    dispatch(hideFullScreenLoader());
  }
};

const sendVoucherMessageToCustomer = async (payload) => {
  try {
    const response = await lambdaFetch(
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/delhivery/sendvouchermessage",
      {
        method: "POST",
        Headers: {
          "content/type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );
    const data = await response.json();
    if (data.statusCode === 200) {
      toast.success("Voucher Message Sent Successfully");
    } else {
      toast.error("Error in Sending Voucher Message");
    }
  } catch (err) {
    toast.error("Error in Sending Voucher Message");
  }
};

export const updateReverseOrder = (orderBody) => async (dispatch) => {
  const todayDate = new Date();
  const changeDateFormat = (date) => {
    const reqDate = (date.getDate() <= 9 ? "0" : "") + date.getDate();
    const reqMonth =
      (date.getMonth() + 1 <= 9 ? "0" : "") + (date.getMonth() + 1);
    const reqFormat = `${[date.getFullYear(), reqMonth, reqDate].join(
      "-"
    )}T00:00:00+05:30`;
    return reqFormat;
  };
  const firstDayLastMonth = changeDateFormat(
    new Date(todayDate.getFullYear(), todayDate.getMonth() - 1, 1)
  );

  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/updateNewReverseOrder";
  try {
    const response = await lambdaFetch(URL, {
      method: "POST",
      body: JSON.stringify(orderBody),
    });
    const data = await response.json();
    if (data.statusCode === 200) {
      if (orderBody.voucher) {
        const payload = {
          phone: orderBody.phone,
          voucher: orderBody.voucher_code,
        };
        await sendVoucherMessageToCustomer(payload);
      }
      if (orderBody.draft) {
        dispatch(clearReverseDataByStatus());
        dispatch(
          getReverseDataByStatus({
            p_status: [
              "R_DLVD",
              "bsync#IT",
              "bsync#RPPickDone",
              "bsync#RAD",
              "bsync#OFD",
              "bsync#PU_Dispatched",
              "bsync#PU_In Transit",
              "bsync#PU_In_Transit",
              "bsync#PU_Pending",
            ],
            from_date: firstDayLastMonth,
          })
        );
      } else {
        dispatch({
          type: "UPDATE_REVERSE_BY_STATUS",
          payload: orderBody.id,
        });
      }
      console.log("Reverse Order Updated Successfully");
      dispatch(hideFullScreenLoader());
    } else {
      alert("Error in Updating Reverse Order");
      console.log(data);
      dispatch(hideFullScreenLoader());
    }
  } catch (err) {
    console.log(err);
    dispatch(hideFullScreenLoader());
  }
};

export const approveReverseDeliveredOrders = (orderBody, id) => {
  const updateRTO = async () => {
    const Url =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/updateShopifyInventory";

    const response = await lambdaFetch(Url, {
      method: "POST",
      body: JSON.stringify(orderBody),
    });
    return await response.json();
  };
  return (dispatch) => {
    updateRTO()
      .then((data) => {
        if (data.statusCode === 200) {
          dispatch(replenishReverseOrderStockInInventory(id));
        } else {
          console.log(data.body);
          alert("Error In Updating Inventory In Shopify");
          dispatch(hideFullScreenLoader());
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideFullScreenLoader());
      });
  };
};

export const replenishReverseOrderStockInInventory =
  (id) => async (dispatch) => {
    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/replenishReverseOrder";
    try {
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify({ id }),
      });
      const data = await response.json();
      if (data.statusCode === 200) {
        dispatch({
          type: "UPDATE_REVERSE_DELIVERED_INVENTORY_STATUS",
          payload: id,
        });
        console.log("Stock Added In Inventory Successfully");
        dispatch(hideFullScreenLoader());
      } else {
        alert("Error in Adding Stock In Inventory");
        console.log(data);
        dispatch(hideFullScreenLoader());
      }
    } catch (err) {
      console.log(err);
      dispatch(hideFullScreenLoader());
    }
  };

export const updateDamagedReverseOrders = (orderBody, toBeUpdatedBody) => {
  const updateRTO = async () => {
    const Url =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/updateShopifyInventory";

    const response = await lambdaFetch(Url, {
      method: "POST",
      body: JSON.stringify(orderBody),
    });
    return await response.json();
  };
  return (dispatch) => {
    updateRTO()
      .then((data) => {
        if (data.statusCode === 200) {
          dispatch(replenishDamagedReverseOrder(toBeUpdatedBody));
          console.log("Added to shopify Inventory");
        } else {
          alert("Error In Updating Inventory In Shopify");
          dispatch(hideFullScreenLoader());
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideFullScreenLoader());
      });
  };
};

export const replenishDamagedReverseOrder = (orderBody) => async (dispatch) => {
  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/replenishDamagedReverse";
  try {
    const response = await lambdaFetch(URL, {
      method: "POST",
      body: JSON.stringify(orderBody),
    });
    const data = await response.json();
    if (data.statusCode === 200) {
      dispatch({
        type: "UPDATE_REVERSE_DELIVERED_INVENTORY_STATUS",
        payload: parseFloat(orderBody.id),
      });
      console.log("Updated Successfully");
      dispatch(hideFullScreenLoader());
    } else {
      alert("Error in Adding Stock In Inventory");
      console.log(data);
      dispatch(hideFullScreenLoader());
    }
  } catch (err) {
    console.log(err);
    dispatch(hideFullScreenLoader());
  }
};

export const getSingleReverseOrderDetails = (orderBody) => async (dispatch) => {
  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/reverseorders/single_reverse";
  try {
    const response = await lambdaFetch(URL, {
      method: "POST",
      body: JSON.stringify(orderBody),
    });
    const data = await response.json();
    if (data.statusCode === 200) {
      if (data.body.length > 0) {
        dispatch({
          type: "GET_SINGLE_REVERSE",
          payload: data.body,
        });
      }
      console.log("Order Found");
      dispatch(hideFullScreenLoader());
    } else {
      alert("Order Details Not Found");
      dispatch(hideFullScreenLoader());
    }
  } catch (err) {
    console.log(err);
    dispatch(hideFullScreenLoader());
  }
};

export const getSingleReverseOrder = async (id) => {
  let res;
  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/reverseorders/single_reverse";
  try {
    const response = await lambdaFetch(URL, {
      method: "POST",
      body: JSON.stringify({ id }),
    });
    console.log("payload", { id });
    const data = await response.json();
    if (data.statusCode === 200) {
      if (data.body.length > 0) {
        res = { statusCode: 200, data: data.body[0] };
      } else {
        alert("Order Details Not Found");
        res = { statusCode: 400 };
      }
    }
  } catch (err) {
    res = { statusCode: 500 };
    console.log(err);
  }
  return res;
};

export const updateMoveToVoucher = (id) => (dispatch) => {
  dispatch({
    type: "UPDATE_REVERSE_BY_STATUS_TO_VOUCHER",
    payload: id,
  });
  dispatch(hideFullScreenLoader());
};

export const updateMoveToReplacement = (id) => (dispatch) => {
  dispatch({
    type: "UPDATE_REVERSE_BY_STATUS_TO_REPLACEMENT",
    payload: id,
  });
  dispatch(hideFullScreenLoader());
};

export const updateMoveToGiftcard = (id) => (dispatch) => {
  dispatch({
    type: "UPDATE_REVERSE_BY_STATUS_TO_GIFTCARD",
    payload: id,
  });
  dispatch(hideFullScreenLoader());
};

export const reinstateAndDeleteAwbRE = (id, awb) => async (dispatch) => {
  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/littleboxupdatereverseorderstatus";
  console.log("payload", {
    fulfillmentId: id,
    status: "reinstate",
    awb,
  });
  try {
    const response = await lambdaFetch(URL, {
      method: "POST",
      body: JSON.stringify({
        fulfillmentId: id,
        status: "reinstate",
        awb,
      }),
    });
    const data = await response.json();

    if (data.statusCode === 200) {
      console.log("Reinstated Order Successfully.");
      dispatch({
        type: "UPDATE_REVERSE_BY_STATUS",
        payload: id,
      });
      dispatch(hideFullScreenLoader());
    } else {
      alert(`Server Error ${data.statusCode}`);
      dispatch(hideFullScreenLoader());
    }
  } catch (err) {
    console.error(err);
    dispatch(hideFullScreenLoader());
  }
};

export const moveToDeleted = (id, awb, reason) => async (dispatch) => {
  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/littleboxupdatereverseorderstatus";
  try {
    const response = await lambdaFetch(URL, {
      method: "POST",
      body: JSON.stringify({
        fulfillmentId: id,
        status: "deleted",
        awb,
        reason,
      }),
    });
    const data = await response.json();

    if (data.statusCode === 200) {
      console.log("Deleted R/E Order Successfully.");
      dispatch({
        type: "UPDATE_REVERSE_BY_STATUS",
        payload: id,
      });
      dispatch(hideFullScreenLoader());
    } else {
      alert(`Server Error ${data.statusCode}`);
      dispatch(hideFullScreenLoader());
    }
  } catch (err) {
    console.error(err);
    dispatch(hideFullScreenLoader());
  }
};

export const getReverseDataByStatusModified =
  (orderBody) => async (dispatch) => {
    async function getData(reqBody) {
      const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/reverseorders/get_orders";

      let fetching = true;
      let orders = [];
      let key = null;
      while (fetching) {
        const response = await lambdaFetch(URL, {
          method: "POST",
          body: JSON.stringify({ ...reqBody, key }),
        });

        const data = await response.json();
        if (data.statusCode === 200) {
          const processingData = data.body;
          orders.push(...processingData);
          key = data.key;
          if (!key) {
            fetching = false;
          }
        } else {
          orders = [];
          fetching = false;
        }
      }
      return orders;
    }
    let allReverseOrders = [];
    for (let i = 0; i < orderBody.p_status.length; i++) {
      const payload = {
        package_status: orderBody.p_status[i],
        from_date: orderBody.from_date,
      };
      const data = await getData(payload);
      if (data) {
        allReverseOrders.push(...data);
      } else {
        console.log("Error in Fetching Data");
        toast.error("Error in fetching data");
      }
    }
    dispatch({
      type: "REVERSE_BY_STATUS",
      data: allReverseOrders,
    });
  };
