import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Typography,
  Modal,
  Box,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { removeDelhiveryShipmentDetails } from "../../../actions/ordersActions";
import XBShipmentDetailTable from "./XBShipmentDetailTable";

function formatDate(date) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

export const DelhiveryOrderHistory = ({ open, setOrderHistoryModal, awb }) => {
  const dispatch = useDispatch();
  const orderHistory = useSelector(
    (state) => state.orderDetails.delhiveryShipmentHistory
  );
  const [viewTableData, setViewTableData] = useState([]);
  // console.log(viewTableData)
  const [openUp, setOpenUp] = useState(open);
  useEffect(() => {
    setOpenUp(open);
  }, [open]);
  const handleClose = () => {
    setOpenUp(false);
    setOrderHistoryModal(false);
    dispatch(removeDelhiveryShipmentDetails());
  };
  useEffect(() => {
    if (orderHistory.length > 0) {
      const newOrderArray = orderHistory.map((item) => {
        const newOrder = {
          AWB: item.AWB,
          OrderNo: item.ReferenceNo,
          Quantity: item.Quantity,
          XB_Pickup_Date: formatDate(new Date(item.PickedupDate)),
          Expected_DeliveryDate: formatDate(
            new Date(item.PromisedDeliveryDate)
          ),
          OrderDetails: item.Scans.map((s) => s.ScanDetail).reverse(),
        };
        return newOrder;
      });
      setViewTableData(newOrderArray);
    } else {
      setViewTableData("");
    }
  }, [orderHistory]);
  console.log("order history", orderHistory, viewTableData);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    background: "whitesmoke",
    textAlign: "center",
    maxHeight: "80vh",
    overflowY: "scroll",
    maxWidth: "90%",
  };

  return (
    <div>
      <Modal
        open={openUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {orderHistory && viewTableData.length > 0 ? (
          <Box sx={style}>
            <h2>Order Tracking Details</h2>
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  AWB Number : {viewTableData[0].AWB}
                </Typography>
                <Typography variant="h5" component="div"></Typography>
                <Typography variant="body2">
                  Order Id : {viewTableData[0].OrderNo}
                </Typography>
                <Typography variant="body2">
                  Quantity: {viewTableData[0].Quantity}
                </Typography>
                <Typography variant="h5" component="div"></Typography>
                <Typography variant="body2">
                  Pick up Date : {viewTableData[0].XB_Pickup_Date}
                </Typography>
                <Typography variant="h5" component="div"></Typography>
                <Typography variant="body2">
                  Expected Delivery Date :{" "}
                  {viewTableData[0].Expected_DeliveryDate}
                </Typography>
              </CardContent>
            </Card>
            <br />
            <XBShipmentDetailTable tableData={viewTableData[0].OrderDetails} />
          </Box>
        ) : !orderHistory && viewTableData === "" ? (
          <Box sx={style}>
            <h3>XpressBees Shipment History Not Found.</h3>
          </Box>
        ) : (
          <Box sx={style}>
            <CircularProgress />
          </Box>
        )}
      </Modal>
    </div>
  );
};
