import { lambdaFetch } from "../utils/awsUtils";
import { showModal, showUnauthorizedModal } from "./errorModalActions";
import { toast } from "react-toastify";
import { hideLoading, showLoading } from "./loaderActions";
import { getOrderAmountAnalytics } from "../utils/helpers";

export const getNumberOfExchangeDelivered = () => async (dispatch) => {
  try {
    dispatch(showLoading());
    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/analytics";

    const response = await lambdaFetch(URL, {
      method: "POST",
      body: JSON.stringify({}),
    });
    if (response.status === 403) {
      dispatch(hideLoading());
      dispatch(showUnauthorizedModal());
    }
    const data = await response.json();
    // console.log(data)
    if (data.statusCode === 200) {
      dispatch({
        type: "EXCHANGE_DELIVERED",
        data: data.data,
      });
      toast.success("Fetch analytics");
    } else {
      toast.error("error fetching analytics");
    }
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    dispatch(showModal());
    console.log("error", error);
    toast.error("Something Went Wrong With Fetching Analytics", {
      theme: "colored",
    });
  }
};

export const getAutomationStatus = async () => {
  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/automation";
  try {
    const response = await fetch(URL);
    const data = await response.json();
    if (data.statusCode === 200) {
      return {
        statusCode: 200,
        body: data.body,
      };
    } else {
      return {
        statusCode: 500,
      };
    }
  } catch (error) {
    console.log("error", error);
    return {
      statusCode: 500,
    };
  }
};

export const getDailyOrderAnalytics = async () => {
  const date = new Date().toISOString().split("T")[0] + "T00:00:00+05:30";
  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/analytics/todayorder/getamountanalytics";
  try {
    const response = await lambdaFetch(URL, {
      method: "POST",

      body: JSON.stringify({
        date,
      }),
    });
    const data = await response.json();
    if (data.statusCode === 200) {
      return {
        statusCode: 200,
        body: data.body,
      };
    } else if (data.statusCode === 404) {
      return {
        statusCode: 404,
      };
    } else {
      return {
        statusCode: 500,
      };
    }
  } catch (error) {
    console.log("error", error);
    return {
      statusCode: 500,
    };
  }
};

export const updateDailyOrderAnalytics = async (orders) => {
  try {
    const payload = getOrderAmountAnalytics(orders);
    console.log("payload", payload);
    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/analytics/todayorder/updateamountanalytics";
    await lambdaFetch(URL, {
      method: "POST",

      body: JSON.stringify(payload),
    });
    // const data = await response.json();
  } catch (error) {
    console.log("error", error);
  }
};

export const get45DaysOrderAmtAnalytics = async () => {
  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/analytics/sales/32";
  try {
    const response = await fetch(URL, {
      method: "GET",
    });

    const data = await response.json();
    if (data.statusCode === 200) {
      return {
        statusCode: 200,
        body: data.body,
      };
    } else if (data.statusCode === 404) {
      return {
        statusCode: 404,
      };
    } else {
      return {
        statusCode: 500,
      };
    }
  } catch (error) {
    console.log("error", error);
    return {
      statusCode: 500,
    };
  }
};
