import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ProductsList from "../productsWidgets/ProductsList";
import RedirectLogin from "../RedirectLogin";
import { allProducts } from "../../actions/productsActions";
import ProgressBar from "../../ProgressBar";

const Products = () => {
  const auth = useSelector((state) => state.auth);
  const products = useSelector((state) => state.productDetails.products);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!products.length > 0 && auth) dispatch(allProducts());
  }, [dispatch, auth]);

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {!products.length > 0 ? (
            <ProgressBar />
          ) : (
            <ProductsList products={products} />
          )}
        </>
      )}
    </>
  );
};

export default Products;
