import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  revertFulfillment,
  getShiprocketToken,
  generateShipRocketOrder,
  updateOrderForShopify,
} from "../../actions/ordersActions";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TableFooter,
  TablePagination,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePaginationActions from "../../utils/TablePaginationActions";
import { createPDFforManifest } from "../../utils/pdfUtils";
// import { mapXBToShipRocketFormat } from "../../utils/orderTakeoutHelpers";
import ConfirmPopup from "./ConfirmPopup";
import { toast } from "react-toastify";
import { Stack } from "@mui/system";

function OrderDetailBox({ row, save }) {
  const [openEdit, setOpenEdit] = useState(false);
  const [orderDetails, setOrderDetails] = useState(row);
  useEffect(() => {
    setOrderDetails(row);
  }, [row, openEdit]);

  const handleSave = () => {
    // Check if weight is a number
    const weight = parseFloat(orderDetails.weight);
    console.log("WEIGHT", weight);
    if (isNaN(weight)) {
      toast.error("Weight must be a number");
      return;
    } else {
      orderDetails.weight = weight;
      save(orderDetails);
      setOrderDetails((prev) => ({
        ...prev,
        weight,
      }));
    }
  };

  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div">
        Product Details
      </Typography>
      <Table size="small" aria-label="purchases">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>SKU</TableCell>
            <TableCell align="right">Size</TableCell>
            <TableCell align="right">Quantity</TableCell>
            <TableCell align="right">M.R.P</TableCell>
            <TableCell align="right">Discount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {row.order_items?.map((productRow) => (
            <TableRow key={productRow.sku}>
              <TableCell component="th" scope="row">
                {productRow.name}
              </TableCell>
              <TableCell>{productRow.sku}</TableCell>
              <TableCell align="right">{productRow.variant_title}</TableCell>
              <TableCell align="right">{productRow.units}</TableCell>
              <TableCell align="right">{productRow.selling_price}</TableCell>
              <TableCell align="right">{productRow.discount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="flexRow">
        <h4>{openEdit ? "Edit Details" : "Shipping Address:"}</h4>
        <Button
          variant={openEdit ? "outlined" : "contained"}
          color={openEdit ? "error" : "success"}
          onClick={() => {
            setOpenEdit((prev) => !prev);
          }}
        >
          {openEdit ? "Cancel" : "Edit details"}
        </Button>
        {/* <Button
          variant="contained"
          color="success"
          onClick={() => {
            handleEdit();
          }}
        >
          Edit Address for Shiprocket
        </Button> */}
      </div>
      {openEdit ? (
        <Stack direction={"column"} gap={2}>
          <Stack direction={"row"} gap={3}>
            <TextField
              value={orderDetails.shipping_customer_name}
              onChange={(e) => {
                setOrderDetails((prev) => ({
                  ...prev,
                  shipping_customer_name: e.target.value,
                  billing_customer_name: e.target.value,
                }));
              }}
              label="First Name"
              variant="standard"
            />
            <TextField
              value={orderDetails.shipping_last_name}
              onChange={(e) => {
                setOrderDetails((prev) => ({
                  ...prev,
                  shipping_last_name: e.target.value,
                  billing_last_name: e.target.value,
                }));
              }}
              label="Last Name"
              variant="standard"
            />
          </Stack>
          <TextField
            value={orderDetails.shipping_address}
            onChange={(e) => {
              setOrderDetails((prev) => ({
                ...prev,
                shipping_address: e.target.value,
                billing_address: e.target.value,
              }));
            }}
            label="Address"
            variant="standard"
          />
          <TextField
            value={orderDetails.shipping_address_2}
            onChange={(e) => {
              setOrderDetails((prev) => ({
                ...prev,
                shipping_address_2: e.target.value,
                billing_address_2: e.target.value,
              }));
            }}
            label="Address 2"
            variant="standard"
          />
          <Stack direction={"row"} gap={3}>
            <TextField
              value={orderDetails.shipping_phone}
              onChange={(e) => {
                setOrderDetails((prev) => ({
                  ...prev,
                  shipping_phone: e.target.value,
                  billing_phone: e.target.value,
                }));
              }}
              label="Phone"
              variant="standard"
            />
            <TextField
              value={orderDetails.shipping_city}
              onChange={(e) => {
                setOrderDetails((prev) => ({
                  ...prev,
                  shipping_city: e.target.value,
                  billing_city: e.target.value,
                }));
              }}
              label="City"
              variant="standard"
            />
            <TextField
              value={orderDetails.shipping_state}
              onChange={(e) => {
                setOrderDetails((prev) => ({
                  ...prev,
                  shipping_state: e.target.value,
                  billing_state: e.target.value,
                }));
              }}
              label="State"
              variant="standard"
            />
            <TextField
              value={orderDetails.shipping_pincode}
              onChange={(e) => {
                setOrderDetails((prev) => ({
                  ...prev,
                  shipping_pincode: e.target.value,
                  billing_pincode: e.target.value,
                }));
              }}
              label="Pincode"
              variant="standard"
            />
          </Stack>
          <Stack direction={"row"} gap={3}>
            <TextField
              value={orderDetails.length}
              onChange={(e) => {
                setOrderDetails((prev) => ({
                  ...prev,
                  length: e.target.value,
                }));
              }}
              label="Length"
              variant="standard"
            />
            <TextField
              value={orderDetails.breadth}
              onChange={(e) => {
                setOrderDetails((prev) => ({
                  ...prev,
                  breadth: e.target.value,
                }));
              }}
              label="Breadth"
              variant="standard"
            />
            <TextField
              value={orderDetails.height}
              onChange={(e) => {
                setOrderDetails((prev) => ({
                  ...prev,
                  height: e.target.value,
                }));
              }}
              label="Height"
              variant="standard"
            />
            <TextField
              value={orderDetails.weight}
              onChange={(e) => {
                setOrderDetails((prev) => ({
                  ...prev,
                  weight: e.target.value,
                }));
              }}
              label="Weight"
              variant="standard"
            />
          </Stack>
          <Button
            sx={{ margin: "15px 0" }}
            variant="contained"
            onClick={handleSave}
          >
            Save
          </Button>
        </Stack>
      ) : (
        <p>
          Name: {row.billing_customer_name + " " + row.billing_last_name}
          <br />
          Mobile: {row.shipping_phone}
          <br />
          Address: {row.shipping_address}
          <br />
          {row.shipping_address_2}
          <br />
          {row.shipping_city}
          <br />
          {row.shipping_country}
          <br />
          {row.shipping_pincode}
        </p>
      )}
    </Box>
  );
}

function OrderActionsSelector({ orders, reset, setSearchFilter }) {
  const dispatch = useDispatch();
  const [popupVisible, setPopupVisible] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("ShiprocketToken"))
      dispatch(getShiprocketToken());
  }, []);

  const deleteProcessing = () => {
    dispatch(revertFulfillment(orders[0], false));
    setPopupVisible(false);
    reset([]);
    setSearchFilter("");
  };

  const handleChange = (e) => {
    if (e.target.value === 1) {
      const payload = {
        ...orders[0],
        order_id: String(orders[0].fulfillment_id),
      };
      console.log("PAYLOAD", payload);
      // PLACE ORDER ON SHIPROCKET
      dispatch(generateShipRocketOrder(payload));
      reset([]);
      setSearchFilter("");
      // Refresh the page
    } else {
      setPopupVisible(true);
    }
  };

  return (
    <FormControl variant="outlined" size="small" sx={{ m: 2, minWidth: 120 }}>
      <InputLabel id="demo-simple-select-label">Actions</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={""}
        label="Age"
        onChange={handleChange}
      >
        <MenuItem value={1}>Dispatch To Shiprocket</MenuItem>
        <MenuItem value={2}>Cancel Order</MenuItem>
      </Select>
      <ConfirmPopup
        visible={popupVisible}
        cancel={() => setPopupVisible(false)}
        proceed={deleteProcessing}
      />
    </FormControl>
  );
}

const PDFDownloader = ({ data }) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => createPDFforManifest(data)}
    >
      Download Manifest
    </Button>
  );
};

export default function ShiprocketDispatchTable({
  tableData,
  setSearchFilter,
}) {
  const loading = useSelector((state) => state.loader.loading);
  const [data, setData] = useState(
    tableData?.sort(
      (a, b) => Number(a.original_order_id) - Number(b.original_order_id)
    )
  );
  const [open, setOpen] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const dispatch = useDispatch();

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (tableData.length > 0) {
      setOpen(Array(tableData.length).fill(false));
    }
    setData(
      [...tableData]
        ?.sort(
          (a, b) => Number(a.original_order_id) - Number(b.original_order_id)
        )
        .reverse()
    );
  }, [tableData]);

  const handleEdits = (order) => {
    dispatch(updateOrderForShopify(order));
    setData(
      data.map((o) => (o.fulfillment_id === order.fulfillment_id ? order : o))
    );
  };

  return (
    <>
      {loading && (
        <div className="load-overlay">
          <h1>Please Wait...</h1>
        </div>
      )}
      {selectedOrders.length === 1 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <h4>Selected Action : </h4>
          <OrderActionsSelector
            orders={selectedOrders}
            reset={setSelectedOrders}
            setSearchFilter={setSearchFilter}
          />
        </div>
      ) : (
        <h4>Select only one order to perform actions.</h4>
      )}
      {tableData.length > 0 ? (
        <>
          {/* <PDFDownloader data={data} /> */}
          <br />
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {tableData.length} Items
                    <br /> Select
                  </TableCell>
                  <TableCell align="left">Order Number</TableCell>
                  <TableCell align="left">AWB Code</TableCell>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Package Status</TableCell>
                  <TableCell align="left">Shipping Cost</TableCell>
                  <TableCell align="left">Sub Total</TableCell>
                  <TableCell align="left">Total</TableCell>
                  <TableCell align="left">Product</TableCell>
                  <TableCell align="left">List</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? data.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : data
                ).map((row, index) => (
                  <React.Fragment key={index}>
                    <TableRow
                      key={index}
                      sx={{
                        bgcolor:
                          row.status === "fulfilled"
                            ? "rgba(145,145,145, 0.2)"
                            : index % 2 === 0
                            ? "#afd275"
                            : "#edf5e1",

                        "& > *": {
                          borderBottom: "unset",
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Checkbox
                          color="primary"
                          checked={
                            selectedOrders.filter(
                              (i) => i.fulfillment_id === row.fulfillment_id
                            ).length > 0
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedOrders([...selectedOrders, row]);
                            } else {
                              setSelectedOrders(
                                selectedOrders.filter(
                                  (item) =>
                                    item.fulfillment_id !== row.fulfillment_id
                                )
                              );
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        {row.original_order_id} (#
                        {row.fulfillment_id})
                      </TableCell>
                      <TableCell>{row.awb_code}</TableCell>
                      <TableCell align="left">
                        {new Date(row.order_date).toLocaleDateString()}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          color: "blue",
                        }}
                        align="left"
                      >
                        {row.package_status}
                      </TableCell>
                      <TableCell align="left">{row.shipping_charges}</TableCell>
                      <TableCell align="left">{row.sub_total}</TableCell>
                      <TableCell align="left">{row.total}</TableCell>
                      <TableCell align="left">
                        {row.order_items?.length || 0} Items
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            const temp = open.map((i, openIndex) => {
                              if (index !== openIndex) {
                                return i;
                              } else {
                                return !i;
                              }
                            });
                            setOpen(temp);
                          }}
                        >
                          {open[index] ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                        }}
                        colSpan={6}
                      >
                        <Collapse in={open[index]} timeout="auto" unmountOnExit>
                          <OrderDetailBox row={row} save={handleEdits} />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    style={{ overflow: "inherit" }}
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </>
      ) : (
        "No Under Process Orders At Present"
      )}
    </>
  );
}
