import * as React from "react";
import { useState, useEffect } from "react";
import { getSizesByType } from "../../utils/helperArrays";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  Paper,
  TextField,
  MenuItem,
  Avatar,
  Chip,
  Button,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useDispatch, useSelector } from "react-redux";
import { postPOObject, selectPOProducts } from "../../actions/POActions";
import DeleteIcon from "@mui/icons-material/Delete";
import { CSVLink, CSVDownload } from "react-csv";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function Row({ row, sizes }) {
  const selectedProducts = useSelector((state) => state.PO.products);

  const dispatch = useDispatch();
  const handleDeleteClick = (product) => {
    const filtered = selectedProducts.filter((i) => i.SKU !== product.SKU);
    // console.log("filtered", filtered);
    dispatch(selectPOProducts(filtered));
  };
  const handleSizeChange = (product, stock, size) => {
    // console.log("product", product);
    // console.log("stock", stock);
    // console.log("size", size);
    let count = parseInt(stock, 10);
    console.log("count ", count);
    if (Number.isNaN(count)) {
      count = 0;
    }
    // console.log("seleted products", selectedProducts);
    const newData = selectedProducts.map((row) => {
      if (product.SKU === row.SKU) {
        console.log("HERE");
        row.postock = { ...product.postock, [size]: count };
        const newTotal = Object.values(row.postock).reduce(
          (acc, i) => (acc = acc + i),
          0
        );
        row.totalpo = newTotal;
        return row;
      } else {
        return row;
      }
    });
    dispatch(selectPOProducts(newData));
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <Avatar
            src={row.imageUrl}
            sx={{ width: 100, height: 100 }}
            variant="square"
          />
        </TableCell>
        <TableCell>{row.SKU}</TableCell>
        {sizes.map((i, k) => (
          <TableCell key={k}>
            <TextField
              id="outlined-basic"
              value={row.postock[i]}
              variant="outlined"
              onChange={(e) => {
                handleSizeChange(row, e.target.value, i);
              }}
            />
          </TableCell>
        ))}
        <TableCell>{row.totalpo}</TableCell>
        <TableCell>
          <DeleteIcon
            onClick={() => handleDeleteClick(row)}
            sx={{
              fontSize: 25,
              "&:hover": {
                color: "red",
                cursor: "pointer",
              },
            }}
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function POTable({ productType, setOpen, poId }) {
  const selectedProducts = useSelector((state) => state.PO.products);

  const [data, setData] = useState(
    selectedProducts.sort((x, y) => x.SKU.toLowerCase() > y.SKU.toLowerCase())
  );
  console.log(data);
  const [excelData, setExcelData] = useState([]);
  const [desc, setDesc] = useState(true);
  const [sizes, setSizes] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [poObject, setPOObject] = useState({});
  // console.log(poObject)

  const dispatch = useDispatch();

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setSizes(getSizesByType(productType));
    setData(
      [...selectedProducts]
        .sort((a, b) => Number(a.total) - Number(b.total))
        .reverse()
    );
  }, [productType, selectedProducts]);

  useEffect(() => {
    const newData = [...data].reverse();
    setData(newData);
  }, [desc]);

  //   useEffect(() => {
  //     if (categoryType && productType) {
  //       const filterTableData = tableData.filter((p) => {
  //         if(p.sku?.includes(categoryType)) return p
  //       });
  //       if (filterTableData.length > 0) {
  //         const newData = [...filterTableData].reverse();
  //         setData(newData);
  //       } else {
  //         setData([]);
  //       }
  //     } else {
  //       setData(
  //         [...tableData]
  //           .sort((a, b) => Number(a.inventoryTotal) - Number(b.inventoryTotal))
  //       );
  //     }
  //   }, [categoryType, categoryTypes, productType]);
  // useEffect for excel download

  useEffect(() => {
    const excelformatted = data.map((row) => {
      console.log("data po", data);
      return {
        SKU: row.SKU,
        ...row.postock,
        total: row.totalpo,
      };
    });

    const poformatted = data.map((row) => {
      return {
        SKU: row.SKU,
        Sizes: row.postock,
        total: row.totalpo,
        vendor: row.vendor,
      };
    });

    const finalObj = {
      POID: "LB" + poId,
      SKU_ID: poformatted,
      status: "new",
      vendor: data[0].vendor,
    };

    setPOObject(finalObj);
    setExcelData(excelformatted);
  }, [data]);

  const handlePOObjSubmit = () => {
    console.log(poObject);
    dispatch(postPOObject({ poObject, poId }));
    setOpen(false);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>SKU</TableCell>
              {sizes.map((i, k) => (
                <TableCell key={k}>Size : {i.toString()}</TableCell>
              ))}
              <TableCell onClick={() => setDesc(!desc)}>
                <div style={{ display: "flex" }}>
                  Total
                  <div>{desc ? <ArrowDownward /> : <ArrowUpward />}</div>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row, index) => (
              <Row key={index} row={row} sizes={sizes} />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{ overflow: "inherit" }}
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
            <TableRow></TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          color="secondary"
          style={{ maxWidth: "max-content" }}
          onClick={handlePOObjSubmit}
        >
          <CSVLink
            data={excelData}
            filename={`PO_${data[0].type}_${new Date().toLocaleDateString()}`}
          >
            Export Excel
          </CSVLink>
        </Button>
      </Box>
    </>
  );
}
