import React from 'react'
import { LinearProgress } from '@mui/material'
import "./ProgressBar.css"

const ProgressBar = () => {

  return (
    <div className='progressBarContainer'>
        <LinearProgress className="progressBar" />
        <br/>
        <LinearProgress className="progressBar" />
        <br/>
        <LinearProgress className="progressBar" />
    </div>
  )
}

export default ProgressBar
