import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllTickets,
  getTicketsByExecutiveId,
} from "../../actions/ticketActions";
import RedirectLogin from "../RedirectLogin";
import AllQueryFilter from "../ordersWidgets/AllQueryFilter";

import {
  getInvalidEmailList,
  getUnsuccessfulEmailList,
} from "../../actions/SESActions";

import Loader from "../Loader";
import { getcustomercareusernames } from "../../actions/ordersActions";

const sortingOrder = {
  None: 1,
  Low: 2,
  Medium: 3,
  High: 4,
};
const AllQuery = () => {
  const [emailList, setEmailList] = useState([]);
  const [fetchedTickets, setFetchedTickets] = useState([]);
  const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { invalidEmail, unsuccessfulEmail } = useSelector(
    (state) => state.orderDetails
  );
  const { tickets, executiveTickets } = useSelector((state) => state.tickets);
  const { customerCareUsers } = useSelector((state) => state.inventoryDetails);
  const loading = useSelector((state) => state.loader.loading);
  useEffect(() => {
    if (auth) {
      if (auth.name === "customercare") {
        dispatch(getTicketsByExecutiveId());
      } else {
        dispatch(getAllTickets("All"));
      }
    }
    if (customerCareUsers.length === 0 && auth)
      dispatch(getcustomercareusernames());
    if (invalidEmail.length === 0 && auth) dispatch(getInvalidEmailList());
    if (unsuccessfulEmail.length === 0 && auth)
      dispatch(getUnsuccessfulEmailList());
  }, [auth]);

  useEffect(() => {
    const totalMail = invalidEmail.concat(unsuccessfulEmail);
    setEmailList(totalMail);
  }, [invalidEmail, unsuccessfulEmail]);

  useEffect(() => {
    if (!auth) return;
    if (executiveTickets.length > 0 && auth.name === "customercare") {
      setFetchedTickets(executiveTickets);
    } else {
      setFetchedTickets(tickets);
    }
  }, [tickets, executiveTickets, auth]);

  useEffect(() => {
    if (fetchedTickets.length > 0) {
      const sorted = fetchedTickets
        // .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
        .sort((a, b) => {
          // Compare fieldStatus based on custom sorting order
          const priorityStatusA = sortingOrder[a.priority || "None"];
          const priorityStatusB = sortingOrder[b.priority || "None"];
          if (priorityStatusA !== priorityStatusB) {
            return priorityStatusB - priorityStatusA;
          } else {
            // If fieldStatus is same, compare date
            return new Date(b.updated_at) - new Date(a.updated_at);
          }
        })
        .map((t) => {
          const differenceTime =
            new Date().getTime() - new Date(t.created_at).getTime();
          const differentDays = Math.round(differenceTime / (1000 * 3600 * 24));
          const createdDate = new Date(t.created_at);
          const updatedDate = new Date(t.updated_at);
          const createdLocal = new Date(
            createdDate.getTime() - createdDate.getTimezoneOffset() * 60000
          ).toLocaleString();
          const updatedLocal = new Date(
            updatedDate.getTime() - updatedDate.getTimezoneOffset() * 60000
          ).toLocaleString();
          const messages = t.messages;
          let messageCount = 0;
          let lastSender = "";
          const chat = messages.slice(2);
          chat.forEach((m) => {
            if (lastSender === m.sendedBy) {
              return;
            }
            messageCount++;
            lastSender = m.sendedBy;
          });
          if (messages.length > 2 && !(t.status === "new")) {
            const sendedBy = messages[messages.length - 1].sendedBy;
            return {
              ...t,
              messageCount: messageCount,
              package_status: t.package_status.split("#")[1],
              executive_id: t.executive_id
                ? t.executive_id.split("#")[0]
                : t.executive_id,
              lastSendedBy: sendedBy,
              created_at: createdLocal,
              updated_at: updatedLocal,
              ticketAge: differentDays,
            };
          } else {
            return {
              ...t,
              messageCount: messageCount,
              package_status: t.package_status.split("#")[1],
              executive_id: t.executive_id
                ? t.executive_id.split("#")[0]
                : t.executive_id,
              created_at: createdLocal,
              updated_at: updatedLocal,
              ticketAge: differentDays,
            };
          }
        });
      setTableData(sorted);
    }
  }, [fetchedTickets]);

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {loading && (
            <div className="load-overlay">
              <Loader />
            </div>
          )}
          <div>
            <AllQueryFilter
              tickets={tableData}
              emailList={emailList}
              users={customerCareUsers}
            />
          </div>
        </>
      )}
    </>
  );
};
export default AllQuery;
