import { Box, Button, Divider } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { useState } from "react";
import DownloadPOList from "./DownloadPOList";

const DownloadPO = () => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <Box sx={{ position: "relative" }}>
        <Button
          variant={open ? "contained" : "outlined"}
          color={open ? "success" : "primary"}
          onClick={() => setOpen(!open)}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <p>Download PO</p>
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {open ? <CloseIcon /> : <KeyboardArrowDownIcon />}
          </span>
        </Button>
        {open && (
          <Box
            sx={{
              position: "absolute",
              zIndex: "99",
              right: "50%",
              top: "135%",
            }}
          >
            <Box
              sx={{
                transform: "rotate(-90deg)",
                position: "absolute",
                top: -25,
                right: "20px",
                fontSize: "40px",
                color: "white",
              }}
            >
              {"\u25b6"}
            </Box>
            <Box
              className="scrollbar"
              sx={{
                bgcolor: "white",
                width: "50vw",
                height: "70vh",
                overflow: "scroll",
                px: "5px",
              }}
            >
              <DownloadPOList />
            </Box>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default DownloadPO;
