import { useEffect } from "react";
import EmailIcon from "@mui/icons-material/Email";
import { useSelector, useDispatch } from "react-redux";
import { getAllReturnExchangeOrders } from "../../actions/ordersActions";
import RedirectLogin from "../RedirectLogin";
import {
  getComplaintsEmailList,
  getInvalidEmailList,
  getUnsuccessfulEmailList,
} from "../../actions/SESActions";
import { Box } from "@mui/system";
import { LinearProgress } from "@mui/material";
import ShowEmailReport from "../ordersWidgets/ShowEmailReport";

const EmailReport = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { allREOrders, invalidEmail, complaintEmail, unsuccessfulEmail } =
    useSelector((state) => state.orderDetails);
  const loading = useSelector((state) => state.loader.loading);

  useEffect(() => {
    if (!auth) return;
    if (allREOrders.length === 0 && auth)
      dispatch(getAllReturnExchangeOrders());
    if (complaintEmail.length === 0 && auth) dispatch(getComplaintsEmailList());
    if (unsuccessfulEmail.length === 0 && auth)
      dispatch(getUnsuccessfulEmailList());
    if (invalidEmail.length === 0 && auth) dispatch(getInvalidEmailList());
  }, [auth]);
  console.log("invalid", invalidEmail);
  console.log("unsuccessful", unsuccessfulEmail);
  console.log("complaints", complaintEmail);
  console.log("all re orders", allREOrders);
  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {loading && (
            <div className="load-overlay">
              <Box>
                <EmailIcon
                  sx={{
                    fontSize: "15rem",
                    padding: "20px 0",
                    color: "#000099",
                  }}
                />
                <LinearProgress sx={{ color: "#000099" }} />
              </Box>
            </div>
          )}
          <ShowEmailReport
            allOrders={allREOrders}
            invalid={invalidEmail}
            complaints={complaintEmail}
            unsuccessful={unsuccessfulEmail}
          />
          <div></div>
        </>
      )}
    </>
  );
};

export default EmailReport;
