import React from "react";
import { useState, useEffect } from "react";

import {
  Typography,
  Modal,
  Box,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import StockHistoryTable from "./StockHistoryTable";
import { getProductStockHistory } from "../../actions/productsActions";

export const StockHistoryModal = ({
  open,
  setStockHistoryModal,
  setSelectedRowData,
  product,
}) => {
  const [tableData, setTableData] = useState([]);
  const [isNoHistory, setIsNoHistory] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openUp, setOpenUp] = useState(open);
  const handleClose = () => {
    setOpenUp(false);
    setSelectedRowData({});
    setStockHistoryModal(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    background: "whitesmoke",
    textAlign: "center",
    maxHeight: "80vh",
    overflowY: "scroll",
    maxWidth: "90%",
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      console.log("product", product);
      const data = await getProductStockHistory(product.SKU);
      if (data.status === 200) {
        if (data.body.length > 0) {
          const logs = data.body.map((d) => ({
            timestamp: d.barcode,
            type: d.log.type,
            message: d.log.message,
          }));
          setTableData(logs);
        } else {
          setIsNoHistory(true);
        }
      } else {
        setError(true);
      }
      setLoading(false);
    })();
  }, []);
  console.log("stock history", tableData);
  return (
    <div>
      <Modal
        open={openUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {tableData.length > 0 && (
            <Box>
              <h2>Stock History(Last 30 logs)</h2>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    SKU : {product.SKU}
                  </Typography>
                  <Typography variant="h5" component="div"></Typography>
                  {/* <Typography variant="body2">
                  Order Id : {tableData[0].OrderNo}
                </Typography> */}
                </CardContent>
              </Card>
              <br />
              <StockHistoryTable tableData={tableData} />
            </Box>
          )}
          {isNoHistory && (
            <Box>
              <h3>Stock History Not Found.</h3>
            </Box>
          )}
          {error && (
            <Box>
              <h3>Something went wrong. Please try again</h3>
            </Box>
          )}
          {loading && (
            <Box>
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};
