import React, { useEffect, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Stack, TextField } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const daysArray = Array.from({ length: 30 }, (_, i) => i + 1); // Create an array from 1 to 30
function divideDays(limit) {
  const result = [];

  for (let i = 0; i < daysArray.length; i += limit) {
    result.push(daysArray.slice(i, i + limit));
  }

  return result;
}
const StateWiseSalesTable = ({ sales }) => {
  const [tableData, setTableData] = useState([]);
  const [daysRange, setDaysRange] = useState(7);
  const tableRef = useRef(null);
  const createRows = (n) => {
    if (!sales) return;
    const salesData = sales.sales_data.reduce((acc, state) => {
      const sales = divideDays(n).map((range) => {
        // [1,2,3]
        const dayRangeData = range.reduce(
          (acc, day) => {
            acc.cancelled = acc.cancelled + state.data[day].cancelled;
            acc.orders = acc.cancelled + state.data[day].orders;
            acc.products = acc.cancelled + state.data[day].products;
            return acc;
          },
          {
            cancelled: 0,
            orders: 0,
            products: 0,
          }
        );
        const name =
          range[0] === range[range.length - 1]
            ? `${range[0]}`
            : `${range[0]}-${range[range.length - 1]}`;
        return {
          range: name,
          sales: dayRangeData,
        };
      });
      acc = [
        ...acc,
        {
          state: state.state,
          totalOrders: sales.reduce((acc, d) => acc + d.sales.orders, 0),
          data: sales,
        },
      ];
      return acc;
    }, []);
    const totalOrdersSorted = salesData.sort(
      (a, b) => b.totalOrders - a.totalOrders
    );
    return totalOrdersSorted;
  };
  const handleCreateRange = () => {
    if (daysRange > 0) {
      const stateWiseRows = createRows(daysRange);
      console.log("stateWise", stateWiseRows);
      setTableData(stateWiseRows);
    } else {
      const stateWiseRows = createRows(7);
      setDaysRange(7);
      setTableData(stateWiseRows);
    }
  };
  const handleRangeInput = (e) => {
    const value = Number(e.target.value);
    console.log(value);
    if (isNaN(value)) {
      setDaysRange(0);
    } else if (value < 0 || value > 30) {
      console.log("here");
      return;
    } else {
      setDaysRange(value);
    }
  };
  useEffect(() => {
    if (sales) {
      handleCreateRange();
    }
  }, [sales]);
  const handleForward = () => {
    console.log("table ref", tableRef);
    tableRef.current.scrollLeft += 50;
    console.log("forward");
  };
  const handleBackward = () => {
    tableRef.current.scrollLeft -= 50;
    console.log("backward");
  };
  console.log("table data zero", tableData);
  return (
    <Box>
      <Stack
        direction={"row"}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          px: 10,
          my: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
          }}
        >
          <TextField
            size="small"
            label="Range"
            value={daysRange}
            onChange={handleRangeInput}
            type="text"
            variant="filled"
          />
          <Button variant="outlined" onClick={handleCreateRange}>
            Set Range
          </Button>
        </Box>
        <Stack direction={"row"} gap={2}>
          <Box
            onClick={handleBackward}
            sx={{
              display: "flex",
              alignItems: "center",
              border: "1px solid gray",
              borderRadius: "50px",
              px: 3,
              cursor: "pointer",
            }}
          >
            <ArrowBackIcon />
          </Box>
          <Box
            onClick={handleForward}
            sx={{
              display: "flex",
              alignItems: "center",
              border: "1px solid gray",
              borderRadius: "50px",
              px: 3,
              cursor: "pointer",
            }}
          >
            <ArrowForwardIcon />
          </Box>
        </Stack>
      </Stack>
      {tableData.length > 0 && (
        <div
          style={{
            display: "flex",
            direction: "row",
            justifyContent: "center",
          }}
        >
          <TableContainer
            component={Paper}
            ref={tableRef}
            sx={{
              width: "90vw",
            }}
          >
            <Table sx={{ minWidth: "650px" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      position: "sticky",
                      left: 0,
                      background: "white",
                    }}
                  >
                    State
                  </TableCell>
                  <TableCell>Total</TableCell>
                  <React.Fragment>
                    {tableData[0].data.map((sales, i) => (
                      <TableCell key={i} align="right">
                        {sales.range}
                      </TableCell>
                    ))}
                  </React.Fragment>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row, i) => (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        position: "sticky",
                        left: 0,
                        background: "white",
                      }}
                    >
                      {row.state}
                    </TableCell>
                    <TableCell>{String(row.totalOrders)}</TableCell>
                    <React.Fragment>
                      {row.data.map((salesData, i) => (
                        <TableCell key={i} align="right">
                          {salesData.sales.orders}
                        </TableCell>
                      ))}
                    </React.Fragment>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </Box>
  );
};

export default StateWiseSalesTable;
