const initialState = {
    products : [],
    imageList : [],
    productVariants : [],
    responseBody : {},
    loading : false,
    SkuIds : [],
    skuImages : [],
    shopifyResponseBody : {}
}

const productsReducer = (state = initialState, action) => {
    switch(action.type){
        case "ALL_PRODUCTS":
            return {
                ...state,
                products : action.data
            }
        case "UPLOADED_PRODUCT":
            return {
                ...state,
                responseBody : action.data
            }
        case "SHOPIFY_PRODUCT":
            return {
                ...state,
                shopifyResponseBody : action.data
            }
        case "REMOVE_RESPONSE_BODY":
            return {
                ...state,
                responseBody : []
            }
        case "IMAGE_LIST":
            return {
                ...state,
                imageList : action.data
            }
        case "PRODUCT_VARIANTS":
            return {
                ...state,
                productVariants : action.data
            }
        case "SHOW_LOADER":
            return {
                ...state,
                loading : true
            }
        case "HIDE_LOADER":
            return {
                ...state,
                loading : false
            }
        case "ALL_SKU":
            return {
                ...state,
                SkuIds : action.data.body
            }
        case "SKU_IMAGES":
            return {
                ...state,
                skuImages : action.data.body
            }
        case "REMOVE_S3_IMAGES":
            return {
                ...state,
                skuImages : []
            }
        case "NEW_SKU_ID":
            return {
                ...state,
                SkuIds : state.SkuIds.map(o => o.id === action.data.body.id ? action.data.body : o)
            }
        default:
            return state
    }
}

export default productsReducer