import React from "react";
import { useState, useEffect } from "react";
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    TableFooter,
    TablePagination,
    Button,
    Chip,
    Card,
    CardContent,
    TextField,
    MenuItem,
    Avatar,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useDispatch } from "react-redux";
import {
    replenishRTOorder,
    updateRtoDeliveredOrders,
} from "../../../actions/ordersActions";
import { hideLoader, showLoading } from "../../../actions/loaderActions";
import { OrderHistoryDetailModal } from "../ndr/OrderHistoryDetailModal";
import { getXBShipmentDetails } from "../../../actions/ordersActions";
import { SelectItemModal } from "./SelectItemModal";
import { toast } from "react-toastify";
function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };
    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };
    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };
    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </Box>
    );
}
function Row({ row, index, productVariants, setSearchFilter }) {
    const [open, setOpen] = useState(false);
    const [orderHistoryModal, setOrderHistoryModal] = useState(false);
    const [selectItemModal, setSelectItemModal] = useState(false);
    const dispatch = useDispatch();
    const handleSubmit = (orderId) => {
        dispatch(showLoading());
        let temp = [];
        const orderItems = row.order_items.map((x) => {
            const filteredVariant = productVariants.filter(
                (item) =>
                    item.sku === x.sku.split(".")[0] &&
                    item.title === x.variant_title
            );
            if (filteredVariant.length > 0)
                temp.push({
                    location_id: 65807843485,
                    available_adjustment: x.units,
                    inventory_item_id: filteredVariant[0].inventory_item_id,
                });
            else return;
        });
        if (temp.length > 0) {
            dispatch(updateRtoDeliveredOrders(temp, {id : orderId.toString()}))
            // dispatch(replenishRTOorder({ id : orderId.toString()}));
            setSearchFilter("");
        } else {
            dispatch(hideLoader());
            toast.error(
                "One or More Products Of This Order Is Not Available In Shopify"
            );
        }
    };
    return (
        <>
            <TableRow
                sx={{
                    bgcolor: "rgb(245,245,245)",
                    "& > *": { borderBottom: "unset" },
                }}
            >
                <TableCell component="th" scope="row">
                    {row.fulfillment_id}
                </TableCell>
                <TableCell
                    align="left"
                    sx={{ color: "red", cursor: "pointer" }}
                >
                    <div
                        onClick={() => {
                            setOrderHistoryModal(true);
                            dispatch(
                                getXBShipmentDetails({
                                    AWB: parseInt(row.awb_code),
                                })
                            );
                        }}
                    >
                        {row.awb_code}
                    </div>
                </TableCell>
                {orderHistoryModal ? (
                    <OrderHistoryDetailModal
                        open={orderHistoryModal}
                        setOrderHistoryModal={setOrderHistoryModal}
                    />
                ) : (
                    ""
                )}
                <TableCell
                    align="right"
                    sx={{
                        color:
                            row.package_status === "deliveredRT"
                                ? "green"
                                : "red",
                    }}
                >
                    {row.package_status === "deliveredRT"
                        ? "Delivered To WH"
                        : "RTO In Transit"}
                </TableCell>
                <TableCell align="right">
                    {row.rto_declared_on
                        ? new Date(row.rto_declared_on).toLocaleDateString(
                              "en-in"
                          )
                        : "NA"}
                </TableCell>
                <TableCell align="right">
                    {new Date(row.rto_delivery_date).toLocaleDateString(
                        "en-in"
                    )}
                </TableCell>
                <TableCell align="right">
                    {row.order_items.length} Items
                </TableCell>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Button
                        variant="contained"
                        onClick={() => handleSubmit(row.fulfillment_id)}
                    >
                        Acknowledge
                    </Button>
                    <Button
                        style={{ left: "20px" }}
                        variant="contained"
                        color="error"
                        onClick={() => setSelectItemModal(true)}
                    >
                        Mark As Damaged
                    </Button>
                    {selectItemModal ? (
                        <SelectItemModal
                            orderId={row.fulfillment_id}
                            open={selectItemModal}
                            setSelectItemModal={setSelectItemModal}
                            orderProducts={row.order_items}
                            productVariants={productVariants}
                            setSearchFilter={setSearchFilter}
                        />
                    ) : (
                        ""
                    )}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                component="div"
                            >
                                Product Details
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Image</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>SKU</TableCell>
                                        <TableCell align="right">
                                            Size
                                        </TableCell>
                                        <TableCell align="right">
                                            Quantity
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.order_items.map((productRow) => (
                                        <TableRow key={productRow.sku}>
                                            <TableCell>
                                                <Avatar
                                                    src={productRow.url}
                                                    sx={{
                                                        width: 100,
                                                        height: 100,
                                                        objectFit: "ro",
                                                    }}
                                                    variant="square"
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {productRow.name}
                                            </TableCell>
                                            <TableCell>
                                                {productRow.sku.split(".")[0]}
                                            </TableCell>
                                            <TableCell align="right">
                                                {productRow.variant_title}
                                            </TableCell>
                                            <TableCell align="right">
                                                {productRow.units}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}
const PendingRtoCloseTable = ({
    viewTableData,
    productVariants,
    setSearchFilter,
}) => {
    const [data, setData] = useState(
        viewTableData
            .sort((a, b) => Number(a.fulfillment_id) - Number(b.fulfillment_id))
            .reverse()
    );
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    useEffect(
        () =>
            setData(
                viewTableData
                    .sort(
                        (a, b) =>
                            Number(a.fulfillment_id) - Number(b.fulfillment_id)
                    )
                    .reverse()
            ),
        [viewTableData]
    );
    return (
        <>
            <br />
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Order Number</TableCell>
                            <TableCell align="left">AWB Number</TableCell>
                            <TableCell align="right">Current Status</TableCell>
                            <TableCell align="right">RTO Declared On</TableCell>
                            <TableCell align="right">Delivered At</TableCell>
                            <TableCell align="right">Product</TableCell>
                            <TableCell align="left">List</TableCell>
                            <TableCell align="left">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? data.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                              )
                            : data
                        ).map((row, index) => (
                            <Row
                                key={index}
                                row={row}
                                index={index}
                                productVariants={productVariants}
                                setSearchFilter={setSearchFilter}
                            />
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                style={{ overflow: "inherit" }}
                                rowsPerPageOptions={[
                                    5,
                                    10,
                                    25,
                                    { label: "All", value: -1 },
                                ]}
                                colSpan={3}
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "rows per page",
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
};
export default PendingRtoCloseTable;
