import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { allOrders } from "../../actions/ordersActions";
import { allInventory } from "../../actions/inventoryActions";
import ProgressBar from "../../ProgressBar";
import RedirectLogin from "../RedirectLogin";
import SingleOrder from "../ordersWidgets/SingleOrder";
import "./index.css";

const SingleOrderDispatch = () => {
    const auth = useSelector((state) => state.auth);
    const orders = useSelector((state) => state.orderDetails.orders);
    const inventory = useSelector((state) => state.inventoryDetails.stock);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!orders.length > 0) dispatch(allOrders());
        if (!inventory.length > 0) dispatch(allInventory());
    }, [dispatch]);

    return (
        <>
            {!auth ? (
                <RedirectLogin />
            ) : (
                <>
                    {!orders.length > 0 || !inventory.length > 0 ? (
                        <ProgressBar />
                    ) : (
                        <SingleOrder
                            orderData={orders}
                            inventoryData={inventory}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default SingleOrderDispatch;
