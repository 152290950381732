import React, { useState } from "react";
import { CSVLink } from "react-csv";
import {
  Button,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TextField,
  Stack,
} from "@mui/material";

import { read, utils } from "xlsx";
import {
  createRefund,
  getOrdersByOriginalOrderId,
  getReverseOrdersByOriginalOrderId,
} from "../../actions/ordersActions";
import { ms } from "date-fns/locale";
const CreateRefund = () => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState("");
  const [dataArray, setDataArray] = useState([]);
  const [dataWithStatus, setDataWithStatus] = useState([]);
  const [errStack, setErrStack] = useState([]);
  const [excelFile, setExcelFile] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  let selectedFile;

  const handler = (e) => {
    selectedFile = e.target.files[0];
  };
  const handlerSubmit = () => {
    if (selectedFile) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = (e) => {
        let data = e.target.result;
        let workbook = read(data, { type: "binary" });
        workbook.SheetNames.forEach((sheet) => {
          let rowObject = utils.sheet_to_json(workbook.Sheets[sheet], {
            raw: false,
            // dateNF: "dd/mm/yyyy",
          });
          setDataArray((e) => [...e, rowObject]);
        });
      };
    }
  };

  const handleClear = () => {
    setDataArray([]);
  };
  const waitFor500ms = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 500);
    });
  };
  const handleClick = async () => {
    const refund_orders = dataArray.flat().map((da) => da.OrderId);
    console.log("refund orders", refund_orders);
    setLoading(true);
    let success = [];
    let error = [];
    for (let i = 0; i < refund_orders.length; i++) {
      const data = await getReverseOrdersByOriginalOrderId(refund_orders[i]);
      await waitFor500ms();
      const returnDetails = data.body;
      if (returnDetails.length === 0) {
        setExcelFile((prev) => [
          ...prev,
          {
            orderId: refund_orders[i],
            id: "",
          },
        ]);
      } else {
        const orderId = returnDetails[0].orderId;
        console.log("data", data);
        setExcelFile((prev) => [
          ...prev,
          {
            orderId: refund_orders[i],
            id: orderId,
          },
        ]);
      }
      // if (data.status === 200) {
      //   let status = "";
      //   let refund = "";
      //   let amount = "";
      //   const returnDetails = data.body;
      //   console.log("return details", data.body);
      //   for (let ri = 0; ri < returnDetails.length; ri++) {
      //     if (
      //       returnDetails[ri].package_status === "R_DLVD" ||
      //       returnDetails[ri].package_status === "DTO"
      //     ) {
      //       //create refund
      //       const payload = {
      //         fulfillment_id: String(returnDetails[ri].fulfillment_id),
      //         orderId: returnDetails[ri].orderId,
      //         products: returnDetails[ri].products,
      //       };
      //       const data = await createRefund(payload);
      //       if (data.status === 200) {
      //         status =
      //           ri === 0
      //             ? `${returnDetails[ri].fulfillment_id}(Delivered)`
      //             : `${status},${returnDetails[ri].fulfillment_id}(Delivered)`;
      //         refund =
      //           ri === 0
      //             ? `${returnDetails[ri].fulfillment_id}(Done)`
      //             : `${refund},${returnDetails[ri].fulfillment_id}(Done)`;
      //         amount =
      //           ri === 0
      //             ? `${returnDetails[ri].fulfillment_id}(${data.body.amount})`
      //             : `${amount},${returnDetails[ri].fulfillment_id}(${data.body.amount})`;
      //       } else if (data.status === 400) {
      //         status =
      //           ri === 0
      //             ? `${returnDetails[ri].fulfillment_id}(Delivered)`
      //             : `${status},${returnDetails[ri].fulfillment_id}(Delivered)`;
      //         refund =
      //           ri === 0
      //             ? `${returnDetails[ri].fulfillment_id}(${data.body.message})`
      //             : `${refund},${returnDetails[ri].fulfillment_id}(${data.body.message})`;
      //         amount =
      //           ri === 0
      //             ? `${returnDetails[ri].fulfillment_id}(${data.body.amount})`
      //             : `${amount},${returnDetails[ri].fulfillment_id}(${data.body.amount})`;
      //       } else {
      //         error.push(refund_orders[i]);
      //       }
      //     } else if (
      //       returnDetails[ri].package_status === "bsync#RPCancel" ||
      //       returnDetails[ri].package_status === "bsync#CN_Canceled"
      //     ) {
      //       status =
      //         ri === 0
      //           ? `${returnDetails[ri].fulfillment_id}(Cancelled)`
      //           : `${status},${returnDetails[ri].fulfillment_id}(Cancelled)`;
      //       refund =
      //         ri === 0
      //           ? `${returnDetails[ri].fulfillment_id}(Not Refunded)`
      //           : `${refund},${returnDetails[ri].fulfillment_id}(Not Refunded)`;
      //       amount =
      //         ri === 0
      //           ? `${returnDetails[ri].fulfillment_id}(0)`
      //           : `${amount},${returnDetails[ri].fulfillment_id}(0)`;
      //     } else {
      //       //not yet delivered
      //       status =
      //         ri === 0
      //           ? `${returnDetails[ri].fulfillment_id}(Not Delivered)`
      //           : `${status},${returnDetails[ri].fulfillment_id}(Not Delivered)`;
      //       refund =
      //         ri === 0
      //           ? `${returnDetails[ri].fulfillment_id}(Not Refunded)`
      //           : `${refund},${returnDetails[ri].fulfillment_id}(Not Refunded)`;
      //       amount =
      //         ri === 0
      //           ? `${returnDetails[ri].fulfillment_id}(0)`
      //           : `${amount},${returnDetails[ri].fulfillment_id}(0)`;
      //     }
      //   }
      //   setExcelFile((prev) => [
      //     ...prev,
      //     {
      //       orderId: refund_orders[i],
      //       status,
      //       refund,
      //       amount,
      //     },
      //   ]);
      //   console.log("data", returnDetails);
      // } else if (data.status === 404) {
      //   setExcelFile((prev) => [
      //     ...prev,
      //     {
      //       orderId: refund_orders[i],
      //       status: "Return Order not found",
      //       refund: "",
      //       amount: "",
      //     },
      //   ]);
      // } else {
      //   setExcelFile((prev) => [
      //     ...prev,
      //     {
      //       orderId: refund_orders[i],
      //       status: "",
      //       refund: "",
      //       amount: "",
      //     },
      //   ]);
      //   error.push(refund_orders[i]);
      // }
      setProgress(`${i + 1}/${refund_orders.length}`);
    }
    setDataWithStatus(success);
    setErrStack(error);

    // console.log("excel file", excelFile);
    // setExcelFile(excelFormat);
    setLoading(false);
  };
  console.log("excel data", excelFile, errStack);
  return (
    <div>
      <h1>File Converter</h1>
      <div style={{ display: "flex", alignItems: "center" }}>
        <TextField
          type={"file"}
          onChange={handler}
          inputProps={{ accept: ".xls,.xlsx,.csv,.xlsb" }}
        />
        <Button
          style={{ left: "10px" }}
          variant="contained"
          color="error"
          onClick={handleClear}
        >
          Clear File
        </Button>
      </div>
      <br />
      <Stack direction={"row"} gap={2}>
        <Button variant="contained" color="error" onClick={handlerSubmit}>
          Upload File
        </Button>
      </Stack>
      {dataArray.length > 0 && (
        <Box>
          <h4 style={{ color: "green" }}>Order Ids uploaded</h4>
          {loading ? (
            <h4 style={{ color: "blue" }}>{progress}</h4>
          ) : (
            <Button variant="contained" onClick={handleClick}>
              Create Refund
            </Button>
          )}
        </Box>
      )}
      {/* download order table*/}
      {excelFile.length > 0 && (
        <CSVLink filename="orders" data={excelFile}>
          <Button variant="outlined">Download CSV</Button>
        </CSVLink>
      )}
      {/* order table */}
      {dataWithStatus.length > 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
              <TableRow></TableRow>
            </TableHead>
            <TableBody>
              {dataWithStatus
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((d, i) => (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  ></TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={dataWithStatus.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
      {/* error table */}
      {errStack.length > 0 && (
        <TableContainer component={Paper}>
          <h3 style={{ color: "red" }}>Order not found</h3>
          <Table sx={{ minWidth: 650 }}>
            <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
              <TableRow>
                <TableCell>Order Ids</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {errStack.map((d, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{d}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default CreateRefund;
