import React, { useState, useEffect } from "react";
import { getOrderDetails, getShopifyCustomerOrdersAsync, getDBOrders } from "../../../actions/customerActions";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import HistoryTable from './HistoryTable'
import CircularProgress from '@mui/material/CircularProgress';


const OrderHistory = () => {
  const [custId, setCustId] = useState(null)
  const [custDetails, setCustDetails] = useState(null)
  const [type, setType] = useState("fulfillment_id");
  const [id, setId] = useState("");

  const [orderDetailsShop, setOrderDetailsShop] = useState([])  // STATE with shopify + DynamoDb data
 
  const [loading, setLoading] = useState(false);

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleIdChange = (event) => {
    setId(event.target.value);
  };
 
  const showToastMessage = (msg,isError) => {
    if (isError) {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchOrderDetails = async () => {
    try {
      setLoading(true);
      const result = await getOrderDetails(type,id);
      if(result.length > 0 && result[0].customer_id){
      console.log("Customer ID",result[0].customer_id)
      setCustId(result[0].customer_id)
      setCustDetails({
        name: result[0].billing_customer_name + " " + result[0].billing_last_name,
        phone: result[0].billing_phone,
        Email: result[0].billing_email,
        address: result[0].billing_address + " " + result[0].billing_address_2
      })
      } else {
        showToastMessage("Enter Correct Data !", true);
        setType("");
        setId("");
        setOrderDetailsShop([]);
        setCustDetails(null);
        setCustId(null);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetcing Data", error);
      setLoading(false);
    }
  };


  useEffect(() => {
    const fetchShopifyOrders = async () => {
          try {
            setCustId(null);       // once we get the data, custId is filled, again searching with the same id needs to change the custId to run the useFetch.....else no invoking of fetchShopifyOrders, hence not reaching bottom of the code where setLoading is false(loading always)
            const result = await getShopifyCustomerOrdersAsync(custId);
            const shopDataWithAWSData = await fetchDbData(result)
            setOrderDetailsShop(shopDataWithAWSData);
            console.log("Shopify Data with AWS data", shopDataWithAWSData);
          } catch (error) {
            console.error("Error fetching Shopify orders:", error);
            setLoading(false);
          }
    }
      
    if(custId){
        fetchShopifyOrders();
        console.log("inside the function of fetchShopifyOrders",custId)
    }
    else{
      console.log("No customer ID")
      setLoading(false);
    }
  }, [custId]);

  const fetchDbData = async (newDetailsShop) => {
    for (let i = 0; i < newDetailsShop.length; i++) {
      console.log("FETCHING from customer orders", newDetailsShop[i].order_number)
      try {
        const response = await getDBOrders(newDetailsShop[i].order_number);
        console.log("Response from dynamo", response);
        
        newDetailsShop[i].dbData = response.data;

        console.log("dbData Added")
      } catch (error) {
        console.error("Error fetching DB Data", error)
      }
    }
    setLoading(false);
    return newDetailsShop
  }


  return(
    <div style={{ padding: "20px" }}>
    <FormControl
      variant="outlined"
      style={{ marginBottom: "20px", minWidth: 200 }}
    >
      <InputLabel id="search-type-label">Select Type</InputLabel>
      <Select
        labelId="search-type-label"
        value={type}
        onChange={handleTypeChange}
        label="Select Type"
      >
        <MenuItem value="fulfillment_id">Fulfillment ID</MenuItem>
        <MenuItem value="awb_code">AWB Code</MenuItem>
        <MenuItem value="original_order_id">Original Order ID</MenuItem>
      </Select>
    </FormControl>
    <TextField
      label={`Enter ${id}`}
      variant="outlined"
      value={id}
      onChange={handleIdChange}
      style={{ marginBottom: "20px", minWidth: 200 }}
    />
    {loading? <CircularProgress/> : <Button variant="contained" color="primary" onClick={fetchOrderDetails}>
      Search
    </Button>}
    

    {orderDetailsShop.length >0 && <div>
        <h3>Customer: {custDetails && custDetails.name ? custDetails.name : " "}</h3>
        <p>Phone: {custDetails && custDetails.phone ? custDetails.phone : " "}</p>
        <p>Email: {custDetails && custDetails.Email ? custDetails.Email : " "}</p>
        <HistoryTable data={orderDetailsShop}/>
      </div>}
  </div>
  )
  
};

export default OrderHistory;
