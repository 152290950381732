import React, { useEffect } from 'react'
import Typography from '@mui/material/Typography';

const AverageReplyTime = ({tickets}) => {
  
    const averageTime = () =>{
        // console.log(tickets);

        const allTimetoreply = tickets.reduce((acc, curr)=>{
            let customercaremessage = curr.messages.filter(msg => msg.sendedBy === "customercare")
            if (customercaremessage && customercaremessage.length > 1){
                let timemili = new Date(customercaremessage[1].time) - new Date(curr.created_at)
                const newTime = parseFloat((timemili / (1000 * 60 * 60 )).toFixed(2))
                acc.totalTime += newTime
                acc.count += 1
                
            }
            return acc
        }, {
            totalTime: 0,
            count: 0
        })

        console.log("allTimetoreply",allTimetoreply)

    const avgTime = (allTimetoreply.totalTime / allTimetoreply.count ).toFixed(2)
    console.log(avgTime)
    return avgTime

        
    }
  return (
    <div>
        <Typography variant="button" display="block" gutterBottom>
        Average Reply Time
      </Typography>
        {averageTime()} Hour
    </div>
  )

}

export default AverageReplyTime