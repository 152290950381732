import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Backdrop,
  Box,
  CircularProgress,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { getReverseDeliveredDataByStatus } from "../../actions/reverseDeliveryActions";
import { useDispatch, useSelector } from "react-redux";
import { TableSkeletonComponent } from "../dashboard/helperComponents";
import ReverseDeliveredTable from "./ReverseDeliveredTable";
import { allProducts } from "../../actions/shopifyActions";
import { allInventory } from "../../actions/inventoryActions";
import { toast } from "react-toastify";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ReverseDelivered = () => {
  const [value, setValue] = React.useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filteredOrder, setFilteredOrder] = useState([]);
  const [awbSearch, setAwbSearch] = useState(false);
  const [searchAwbValue, setSearchAwbValue] = useState("");

  const { reverse_dlvd_by_status } = useSelector(
    (state) => state.reverseDetails
  );
  const { shopify_product_variants } = useSelector(
    (state) => state.shopifyDetails
  );
  const { stock } = useSelector((state) => state.inventoryDetails);
  const { loading } = useSelector((state) => state.loader);

  const dispatch = useDispatch();

  const todayDate = new Date();
  const changeDateFormat = (date) => {
    const reqDate = (date.getDate() <= 9 ? "0" : "") + date.getDate();
    const reqMonth =
      (date.getMonth() + 1 <= 9 ? "0" : "") + (date.getMonth() + 1);
    const reqFormat = `${[date.getFullYear(), reqMonth, reqDate].join(
      "-"
    )}T00:00:00+05:30`;
    return reqFormat;
  };

  const firstDayLastMonth = changeDateFormat(
    new Date(todayDate.getFullYear(), todayDate.getMonth() - 2, 1)
  );

  useEffect(() => {
    if (reverse_dlvd_by_status.length === 0)
      dispatch(
        getReverseDeliveredDataByStatus({
          p_status: ["R_DLVD", "DTO"],
          from_date: firstDayLastMonth,
        })
      );
    if (shopify_product_variants.length === 0) dispatch(allProducts());
    if (stock.length === 0) dispatch(allInventory());
  }, []);

  useEffect(() => {
    if (searchValue !== "" && !!searchValue) {
      setSearchAwbValue("");
      const findOrder = reverse_dlvd_by_status.filter(
        (x) => x.fulfillment_id === parseFloat(searchValue.label)
      );
      if (findOrder.length > 0) setFilteredOrder(findOrder);
      else return;
    } else {
      setFilteredOrder(reverse_dlvd_by_status);
    }
  }, [reverse_dlvd_by_status, searchValue]);

  useEffect(() => {
    if (searchAwbValue !== "" && !!searchAwbValue) {
      setSearchValue("");
      const findOrder = reverse_dlvd_by_status.filter(
        (x) => x.awb_code === searchAwbValue.label
      );
      if (findOrder.length > 0) setFilteredOrder(findOrder);
      else return;
    } else {
      setFilteredOrder(reverse_dlvd_by_status);
    }
  }, [reverse_dlvd_by_status, searchAwbValue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearchChange = (event) => {
    setAwbSearch(event.target.checked);
  };

  const handleScanAWB = (e) => {
    e.preventDefault();
    const reqOrder = reverse_dlvd_by_status.filter(
      (x) => x.awb_code === String(searchAwbValue)
    );
    if (reqOrder.length > 0) setFilteredOrder(reqOrder);
    else {
      toast("No Order found for this AWB");
    }
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <>
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress size={100} color="inherit" />
        </Backdrop>
      )}
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box style={{ display: "flex", justifyContent: "center" }}>
          {!awbSearch ? (
            <form onSubmit={handleScanAWB}>
              <input
                style={{
                  width: 300,
                  bgcolor: "white",
                  height: "23px",
                  padding: "8px",
                }}
                type="text"
                placeholder="Scan AWB Here"
                value={searchAwbValue}
                onChange={(e) => {
                  setSearchAwbValue(e.target.value);
                }}
              />
            </form>
          ) : (
            <Autocomplete
              getOptionLabel={(option) => option.label || ""}
              freeSolo
              disablePortal
              id="combo-box-demo"
              options={reverse_dlvd_by_status.map((x) => ({
                label: String(x.fulfillment_id),
              }))}
              sx={{ width: 300, bgcolor: "white" }}
              renderInput={(params) => (
                <TextField {...params} label="Search OrderID" />
              )}
              size="small"
              value={searchValue}
              onChange={(event, newValue) => {
                setSearchValue(newValue);
              }}
              selectOnFocus
            />
          )}
        </Box>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Typography>AWB</Typography>
          <Switch
            checked={awbSearch}
            onChange={handleSearchChange}
            inputProps={{ "aria-label": "controlled" }}
          />
          <Typography>OrderID</Typography>
        </Box>
      </Box>
      <br />
      <br />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Pending"
            {...a11yProps(0)}
            onClick={() => {
              setSearchValue("");
              setSearchAwbValue("");
            }}
          />
          <Tab
            label="Approved"
            {...a11yProps(1)}
            onClick={() => {
              setSearchValue("");
              setSearchAwbValue("");
            }}
          />
          {/* <Tab label="Damaged" {...a11yProps(2)}/> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {filteredOrder.length > 0 ? (
          <ReverseDeliveredTable
            data={filteredOrder
              .filter((x) => !x.reverse_inventory_updated)
              .sort((a, b) => new Date(a.sync_date) - new Date(b.sync_date))
              .reverse()}
            setSearchValue={setSearchValue}
            setSearchAwbValue={setSearchAwbValue}
          />
        ) : (
          <TableSkeletonComponent />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {filteredOrder.length > 0 ? (
          <ReverseDeliveredTable
            data={filteredOrder
              .filter((x) => !!x.reverse_inventory_updated)
              .sort((a, b) => new Date(a.sync_date) - new Date(b.sync_date))
              .reverse()}
            setSearchValue={setSearchValue}
            setSearchAwbValue={setSearchAwbValue}
          />
        ) : (
          <TableSkeletonComponent />
        )}
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
          {filteredOrder.length>0 
            ? (<ReverseDeliveredTable data={filteredOrder}/>) 
            : (<TableSkeletonComponent/>)}
        </TabPanel> */}
    </>
  );
};

export default ReverseDelivered;
