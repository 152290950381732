import React, { useState } from "react";
import { CSVLink } from "react-csv";
import {
  Button,
  TextField,
  Box,
  Backdrop,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from "@mui/material";

import * as XLSX from "xlsx";
import { getOrdersByOriginalOrderId } from "../../actions/ordersActions";
const GetOrderStatus = () => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState("");
  const [dataArray, setDataArray] = useState([]);
  const [dataWithStatus, setDataWithStatus] = useState([]);
  const [errStack, setErrStack] = useState([]);
  const [fulfillmentNames, setFulfillmentNames] = useState([]);
  const [excelFile, setExcelFile] = useState([]);
  const [fileType, setFileType] = useState("csv");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const fileData = e.target.result;
        const workbook = XLSX.read(fileData, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const excelData = XLSX.utils.sheet_to_csv(worksheet);
        const rows = excelData.split("\n");
        const values = rows.reduce((result, row) => {
          const rowValues = row.split(",");
          return result.concat(rowValues);
        }, []);

        console.log("values", values);
        setDataArray(values.map((v) => String(v).replace("#", "")));
        setFileType("excel");
      };

      reader.readAsBinaryString(file);
    }
  };

  const handleClear = () => {
    setDataArray([]);
    setFileType("csv");
  };
  function getOrdinal(number) {
    if (number === 1) {
      return "1st fulfillment";
    } else if (number === 2) {
      return "2nd fulfillment";
    } else if (number === 3) {
      return "3rd fulfillment";
    } else {
      return `${number}th fulfillment`;
    }
  }

  const waitFor500ms = async () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, 100);
    });
  };
  const handleClick = async () => {
    setLoading(true);
    let highestFulfillment = 0;
    let success = [];
    let error = [];
    for (let i = 0; i < dataArray.length; i++) {
      console.log("data array", dataArray[i]);
      const data = await getOrdersByOriginalOrderId(dataArray[i]);
      await waitFor500ms();
      if (data.status === 200) {
        success.push({
          order: dataArray[i],
          fulfillments: data.body.sort(
            (a, b) => a.fulfillment_id - b.fulfillment_id
          ),
          total: data?.body[0]?.total,
        });
        if (data.body.length > highestFulfillment) {
          highestFulfillment = data.body.length;
        }
      } else {
        error.push(dataArray[i]);
        success.push({
          order: dataArray[i],
          fulfillments: [],
          total: 0,
        });
      }
      setProgress(`${i + 1}/${dataArray.length}`);
    }
    setDataWithStatus(success);
    setErrStack(error);
    const fulfillments = Array.from(
      { length: highestFulfillment },
      (_, index) => index + 1
    ).map((i) => getOrdinal(i));
    console.log("main fulfillment", fulfillmentNames);

    const excelFormat = success.reduce((arr, row) => {
      const rowData = {
        order: row.order,
      };

      row.fulfillments.forEach((fRow, fIndex) => {
        const fulfillmentName = fulfillments[fIndex];
        rowData[fulfillmentName] = `${fRow.package_status}`;
        rowData[`Price ${fulfillmentName}`] = `Rs.${fRow.total}`;
      });

      row.totalDelivered = row.fulfillments
        .filter(
          (f) => f.package_status === "DLVD" || f.package_status === "Delivered"
        )
        .reduce((acc, f) => acc + f.total, 0);

      arr.push(rowData);
      rowData["Total Delivered"] = `${row.totalDelivered}`;
      return arr;
    }, []);

    console.log("excel file", excelFile);
    setExcelFile(excelFormat);
    setFulfillmentNames(fulfillments);
    setLoading(false);
  };

  return (
    <div>
      <h1>File Converter</h1>
      <input type="file" accept=".csv, .xlsx" onChange={handleFileUpload} />
      <button onClick={handleClear}>Clear</button>
      {dataArray.length > 0 && (
        <Box>
          <h4 style={{ color: "green" }}>Order Ids upload, get status</h4>
          {loading ? (
            <h4 style={{ color: "blue" }}>{progress}</h4>
          ) : (
            <Button variant="contained" onClick={handleClick}>
              Get status
            </Button>
          )}
        </Box>
      )}
      {/* download order table*/}
      {excelFile.length > 0 && (
        <CSVLink filename="reconcilation_delivered" data={excelFile}>
          <Button variant="outlined">Download CSV</Button>
        </CSVLink>
      )}
      {/* order table */}
      {dataWithStatus.length > 0 && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
              <TableRow>
                <TableCell>Order</TableCell>
                {fulfillmentNames.map((fn, i) => (
                  <React.Fragment key={i}>
                    <TableCell>{fn}</TableCell>
                    <TableCell>Partial Total</TableCell>
                  </React.Fragment>
                ))}
                <TableCell>Total Delivered</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataWithStatus
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((d, i) => (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{d.order}</TableCell>
                    {d?.fulfillments.map((f, i) => (
                      <React.Fragment key={i}>
                        <TableCell>
                          {`${f.fulfillment_id}\n(${f.package_status})`}
                        </TableCell>
                        <TableCell>Rs.{f.total}</TableCell>
                      </React.Fragment>
                    ))}
                    <TableCell>
                      {d.fulfillments
                        .filter((f) => f.package_status === "DLVD")
                        .reduce((acc, f) => acc + f.total, 0)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={dataWithStatus.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
      {/* error table */}
      {errStack.length > 0 && (
        <TableContainer component={Paper}>
          <h3 style={{ color: "red" }}>Order not found</h3>
          <Table sx={{ minWidth: 650 }}>
            <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
              <TableRow>
                <TableCell>Order Ids</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {errStack.map((d, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{d}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default GetOrderStatus;
