import React, { Fragment, useState } from "react";
import { AgChartsReact } from "ag-charts-react";

const Questions = ({questionsCount}) => {
    
    const getData = questionsCount.sort((a, b) => b.count - a.count);
    // console.log("getData",getData)
  const [options, setOptions] = useState({
    data: getData,
    series: [{ type: "bar", xKey: "question", yKey: "count"}],
    axes: [
        {
          type: 'category',
          position: 'bottom',
          label: {
            enabled: false, 
          },
        },
        {
          type: 'number',
          position: 'left',
        },
      ],
  });

  return <AgChartsReact options={options} />;
};

export default Questions;