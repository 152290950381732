import React from "react";
import { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Button,
  Stack,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { bulkOrderMailing } from "../../actions/customerActions";

const OrderList = ({ data }) => {
  const [tagSearch, setTagSearch] = useState("");
  const [orders, setOrders] = useState([]);
  const [openMessageBox, setOpenMessageBox] = useState(false);
  const [message, setMessage] = useState("Hey there!\n\nJust wanted to let you know that unfortunately we had to cancel your order. We ran out of stock and couldn't fulfill your purchase. But don't worry, your payment has been voided so you won't be charged.\n\nIf you have any questions or concerns, feel free to reach out to us by raising a ticket at https://littleboxindia.com/pages/contact-us.\n\nThanks for your understanding!\n\nBest regards,\nLittleBoxIndia");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const {
    shopify_orders_bulk_mail_loading: loading,
    shopify_orders_bulk_mail_progress: progress,
  } = useSelector((state) => state.shopifyDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    const filter = data.filter((row) => row.tags.includes(tagSearch) && !row.tags.includes("mailed"));
    setOrders(filter);
  }, [data, tagSearch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSubmit = () => {
    const newdata = orders
      .filter((o) => !o.tags.includes("mailed") && o.email)
      .map((row) => ({
        ...row,
        tags: row.tags ? row.tags + ", mailed" : row.tags + "mailed",
      }));

    const payload = {
      orders: newdata,
      message: String(message).replaceAll("\n", "<br />"),
    };

    console.log("Payload", payload);
    dispatch(bulkOrderMailing(payload));
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, orders.length - page * rowsPerPage);

  return (
    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
    <div style={{display:"inline-grid"}}>
     <h1>Bulk Order Mail</h1>
     
      <TextField
        variant="outlined"
        placeholder="Filter Tag"
        value={tagSearch}
        onChange={(e) => setTagSearch(e.target.value)}
      />
      
      {openMessageBox ? (
        <>
          <TextField
            sx={{ marginTop: "5px", marginBottom:"5px", width: 700 }}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            autoFocus
            InputProps={{
              disableUnderline: true,
            }}
            id="filled-textarea"
            aria-expanded={true}
            minRows={15}
            multiline
            variant="outlined"
          />
          
          {loading ? (
            <Stack direction={"row"} gap={2}>
              <CircularProgress size={5} />
              <p>{progress}</p>
            </Stack>
          ) : (
            <Button sx={{width:"25%"}} variant="contained" color="primary" onClick={handleSubmit}>
              Send Mail
            </Button>
          )}
        </>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={setOpenMessageBox(true)}
        >
          Send bulk mail
        </Button>
      )}
    </div>
      <br></br>
      <div style={{display:"inline-grid"}}>
      <h4>Orders: Last 60 days</h4>
      <TableContainer>
        <Table sx={{ width: 700, border:"1px solid" }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: "cadetblue" }}>
            <TableRow>
              <TableCell>Order Id</TableCell>
              <TableCell align="right">E-mail</TableCell>
              <TableCell align="right">Tags</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? orders.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : orders
            ).map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.order_number}
                </TableCell>
                <TableCell align="right">{row.email}</TableCell>
                <TableCell align="right">{row.tags}</TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={3} />
              </TableRow>
            )}
          </TableBody>
          <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={orders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
        </Table>
      </TableContainer>
      </div>
    </div>
  );
};

export default OrderList;
