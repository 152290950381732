import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  syncDelhiveryForward,
  syncDelhiveryReverse,
} from "../../actions/reportActions";
import { orders } from "../../utils/orderArray";

import { Box, Button, Paper } from "@mui/material";

import { io } from "socket.io-client";
import ProgressBarWidget from "../generalWidgets/ProgressBarWidget";
import { SOCKET_IO_URL } from "../../utils/helpers";

const DelhiveryShipmentSync = () => {
  const [syncConnect, setSyncConnect] = useState(false);
  const [syncStatus, setSyncStatus] = useState("");
  const [syncProgress, setSyncProgress] = useState(0);
  console.log(orders.length);
  const dispatch = useDispatch();

  useEffect(() => {
    const socket = io(SOCKET_IO_URL);
    socket.on("connect", () => {
      setSyncConnect(true);
    });
    socket.on("forwardsync-status", (status, progressType, progressValue) => {
      setSyncStatus(status);
      if (progressType === "showProgress") {
        setSyncProgress(progressValue);
      }
      if (progressType === "resetProgress") {
        setSyncProgress(0);
      }
      socket.emit("dummy_run", "ok");
      // if (status === "completed") socket.off("connect");
    });
    return () => {
      socket.off("forwardsync-status", () => {
        console.log("forward sync socket io off");
      });
    };
  }, []);

  const syncNew = () => {
    dispatch(syncDelhiveryForward(orders));
  };
  const syncReverse = () => {
    dispatch(syncDelhiveryReverse(orders));
  };

  return (
    <>
      <Button variant="contained" color="secondary" onClick={syncNew}>
        Sync Forward Orders
      </Button>
      <Button variant="contained" color="secondary" onClick={syncReverse}>
        Sync Reverse Orders
      </Button>
      <br /> <br />
      {/* sync progress box */}
      {syncStatus && (
        <Paper
          elevation={2}
          style={{
            borderRadius: "5px",
            width: "50%",
            height: "300px",
            padding: "20px",
            textAlign: "center",
          }}
        >
          <h3 sx={{ fontSize: "medium", marginLeft: "10px" }}>Sync Forward</h3>
          <br />
          <p>{syncStatus}</p>
          <br />
          {syncProgress !== 0 && <ProgressBarWidget percent={syncProgress} />}

          <br />
        </Paper>
      )}
      <Box sx={{ marginBottom: "50px", overflowX: "scroll" }}>
        <Box
          position={"fixed"}
          bottom={10}
          left={50}
          paddingLeft={1}
          sx={{ marginTop: "20px" }}
        >
          {syncConnect ? (
            <h3 style={{ color: "green" }}>Connected To LBSync</h3>
          ) : (
            <h3 style={{ color: "red" }}>Not Connected</h3>
          )}
        </Box>
      </Box>
    </>
  );
};

export default DelhiveryShipmentSync;
