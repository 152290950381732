import React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TablePagination,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import ProductViewModal from "./ReverseProductViewModal";
import { approveReverseDeliveredOrders } from "../../actions/reverseDeliveryActions";
import { useDispatch, useSelector } from "react-redux";
import { ReverseDamagedModal } from "./ReverseDamagedModal";
import {
  hideFullScreenLoader,
  showFullScreenLoader,
} from "../../actions/loaderActions";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function Row({ row, index, setSearchValue, setSearchAwbValue }) {
  const [productModal, setProductModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [selectDamagedModal, setSelectDamagedModal] = useState(false);

  const { shopify_product_variants } = useSelector(
    (state) => state.shopifyDetails
  );

  const dispatch = useDispatch();

  const handleData = () => {
    let tempArr = [];
    tempArr.push(row);
    setModalData(tempArr);
  };

  const handleSubmit = () => {
    dispatch(showFullScreenLoader());
    let temp = [];
    row.products.map((x) => {
      const filteredVariant = shopify_product_variants.filter(
        (item) => item.sku === x.sku && item.title === x.originalSize
      );
      if (filteredVariant.length > 0)
        temp.push({
          location_id: 65807843485,
          available_adjustment: 1,
          inventory_item_id: filteredVariant[0].inventory_item_id,
        });
      else return;
    });
    if (temp.length > 0) {
      console.log(temp, row.fulfillment_id);
      dispatch(approveReverseDeliveredOrders(temp, row.fulfillment_id));
      // dispatch(replenishRTOorder(row.fulfillment_id.toString()));
    } else {
      alert("One or More Products Of This Order Is Not Available In Shopify");
      dispatch(hideFullScreenLoader());
    }
    setSearchValue("");
    setSearchAwbValue("");
  };

  return (
    <>
      <TableRow
        component={Paper}
        sx={{
          "&:hover": {
            backgroundColor: "rgb(220,220,220)",
          },
        }}
      >
        <TableCell
          component="th"
          scope="row"
          style={{ color: row.tags?.includes("replacement") ? "red" : "blue" }}
        >
          <Typography>{row.fulfillment_id}</Typography>
          {row.tags?.includes("replacement") ? (
            <Typography
              style={{
                fontSize: "x-small",
                borderBottom: "2px dotted",
                width: "max-content",
              }}
            >
              2nd Exchange
            </Typography>
          ) : (
            ""
          )}
        </TableCell>
        <TableCell>
          {new Date(row.sync_date).toLocaleDateString("en-in")}
        </TableCell>
        <TableCell>
          {row.modified_at
            ? new Date(row.modified_at).toLocaleDateString("en-in")
            : "....."}
        </TableCell>
        <TableCell>
          {row.delivered_date
            ? new Date(row.delivered_date).toLocaleDateString("en-in")
            : "....."}
        </TableCell>
        <TableCell style={{ maxWidth: "200px" }}>
          <Typography style={{ color: "blue" }}>
            {row.address ? row.address.name : ""}
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ fontSize: "small" }}
          >
            {row.address ? row.address.address1 : ""},
            {row.address ? `${row.address.city},${row.address.province}` : ""},
            {row.address ? row.address.zip : ""}
            <br />
            Ph : {row.address ? row.address.phone : ""}
            <br />
            Alt : {row.alternatePhone ? row.alternatePhone : ""}
            <br />
            Email : {row.email ? row.email : ""}
          </Typography>
        </TableCell>
        <TableCell>
          <Box>
            <Typography>{row.awb_code ? row.awb_code : "N/A"}</Typography>
            <Typography
              onClick={() => {
                setProductModal(true);
                handleData();
              }}
              style={{
                fontSize: "x-small",
                color: "blue",
                cursor: "pointer",
                borderBottom: "2px dotted",
                width: "max-content",
              }}
            >
              View Products
            </Typography>
            {productModal ? (
              <ProductViewModal
                productModal={productModal}
                tableData={modalData}
                setProductModal={setProductModal}
              />
            ) : (
              ""
            )}
          </Box>
        </TableCell>
        <TableCell>{row.comment ? row.comment : "no comment"}</TableCell>
        <TableCell style={{ maxWidth: "200px" }}>
          {row.exchangeAction === "Giftcard" && !!row.new_voucher_created ? (
            <Typography
              variant="body1"
              gutterBottom
              style={{ fontSize: "small" }}
            >
              Voucher Code :{" "}
              <Typography
                style={{
                  borderRadius: "5px",
                  background: "rgb(255, 204, 204)",
                  color: "rgb(250, 25, 5)",
                  width: "max-content",
                }}
              >
                {row.voucher_code}
              </Typography>
            </Typography>
          ) : row.exchangeAction === "Replacement" &&
            !!row.new_exchangeOrder_created ? (
            <Typography
              variant="body1"
              gutterBottom
              style={{ fontSize: "small" }}
            >
              New Order :{" "}
              <Typography
                style={{
                  borderRadius: "5px",
                  background: "rgb(181, 247, 198)",
                  color: "rgb(0, 102, 0)",
                  width: "max-content",
                }}
              >
                {row.new_exchange_order}
              </Typography>
            </Typography>
          ) : (
            "Action Pending"
          )}
        </TableCell>
        <TableCell>
          {!!row.reverse_inventory_updated ? (
            "....."
          ) : (
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Button variant="contained" onClick={handleSubmit}>
                Approve
              </Button>
              <br />
              <Button
                variant="contained"
                color="error"
                onClick={() => setSelectDamagedModal(true)}
              >
                Damaged
              </Button>
            </Box>
          )}
          {selectDamagedModal ? (
            <ReverseDamagedModal
              orderId={row.fulfillment_id}
              package_status={row.package_status}
              open={selectDamagedModal}
              setSelectDamagedModal={setSelectDamagedModal}
              orderProducts={row.products}
              setSearchValue={setSearchValue}
              setSearchAwbValue={setSearchAwbValue}
            />
          ) : (
            ""
          )}
        </TableCell>
      </TableRow>
    </>
  );
}

const ReverseDeliveredTable = ({ data, setSearchValue, setSearchAwbValue }) => {
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    if (data.length > 0)
      setTableData(
        data
          .sort((a, b) => new Date(a.sync_date) - new Date(b.sync_date))
          .reverse()
      );
  }, [data]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead component={Paper}>
            <TableRow>
              <TableCell align="left">Order Number</TableCell>
              <TableCell align="left">Created On </TableCell>
              <TableCell align="left">Last Action On</TableCell>
              <TableCell align="left">Delivery Date</TableCell>
              <TableCell align="left">Customer Details</TableCell>
              <TableCell align="left">AWB Code</TableCell>
              <TableCell align="left">Comment</TableCell>
              <TableCell align="left">CC Action</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <br />
          <TableBody>
            {(rowsPerPage > 0
              ? tableData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : tableData
            ).map((row, index) => (
              <Row
                key={index}
                row={row}
                index={index}
                setSearchValue={setSearchValue}
                setSearchAwbValue={setSearchAwbValue}
              />
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <TablePagination
        style={{
          overflow: "inherit",
          display: "flex",
          justifyContent: "center",
          background: "white",
          borderRadius: "10px",
        }}
        rowsPerPageOptions={[5, 10, 25, 100]}
        colSpan={3}
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </>
  );
};

export default ReverseDeliveredTable;
