import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import TablePaginationActions from "../../utils/TablePaginationActions";

const GroupProductsTable = ({ customer, orders }) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (orders.length > 0) {
            // Incoming data is like [[{}, {}]] or [[{},{}], [{},{}]] depending on how many common products repeated
            // If outer array has 1 child, remove extra dimension
            // If outer array has multiple child put all orders in first array
            // [[{},{}],[{},{}],[{},{}]] becomes [{},{},{},{},{},{}]
            const removed = orders.reduce((acc, i) => {
                acc.push(...i);
                return acc;
            }, []);

            const rowData = removed.reduce((acc, order) => {
                if (!acc.customer) {
                    acc.customer = customer;
                    acc.order_numbers = [];
                    acc.created_dates = [];
                    acc.financial_statuses = [];
                    acc.fulfillment_statuses = [];
                    acc.product_lists = [];
                    acc.tags_list = [];
                }
                // Make the row data
                const created_at = new Date(order.created_at).toLocaleString(
                    "en-in"
                );
                const flstatus = order.fulfillment_status
                    ? order.fulfillment_status
                    : "unfulfilled";
                const pList = order.line_items?.reduce(
                    (str, li) => `${str} ${li.sku}-${li.variant_title}`,
                    ""
                );
                acc.order_numbers.push(order.order_number);
                acc.created_dates.push(created_at);

                acc.financial_statuses.push(order.financial_status);
                acc.fulfillment_statuses.push(flstatus);
                acc.product_lists.push(pList);
                acc.tags_list.push(order.tags || "...");
                return acc;
            }, {});

            setData(rowData);
        }
    }, [orders]);

    return (
        <>
            {data && (
                <TableRow
                    sx={{
                        "&:last-child td, &:last-child th": {
                            border: 0,
                        },
                    }}
                >
                    <TableCell component="th" scope="row">
                        {customer}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {data.order_numbers.map((o, i) => (
                            <p key={i} className="tb-row-gap">
                                {o}
                            </p>
                        ))}
                    </TableCell>
                    <TableCell align="center">
                        {data.created_dates.map((o, i) => (
                            <p key={i} className="tb-row-gap">
                                {o}
                            </p>
                        ))}
                    </TableCell>
                    <TableCell align="center">
                        {data.financial_statuses.map((o, i) => (
                            <p key={i} className="tb-row-gap">
                                {o}
                            </p>
                        ))}
                    </TableCell>
                    <TableCell align="center">
                        {data.fulfillment_statuses.map((o, i) => (
                            <p key={i} className="tb-row-gap">
                                {o}
                            </p>
                        ))}
                    </TableCell>
                    <TableCell align="center">
                        {data.product_lists.map((o, i) => (
                            <p key={i} className="tb-row-gap">
                                {o}
                            </p>
                        ))}
                    </TableCell>
                    <TableCell align="center">
                        {data.tags_list.map((o, i) => (
                            <p key={i} className="tb-row-gap">
                                {o}
                            </p>
                        ))}
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

const RepeatingProductsTable = ({ data }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    console.log("repeating products data", data);
    return (
        <div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Customer</TableCell>
                            <TableCell>Order Number</TableCell>
                            <TableCell align="center">Created At</TableCell>
                            <TableCell align="center">Payment Status</TableCell>
                            <TableCell align="center">
                                Fulfillment Status
                            </TableCell>
                            <TableCell align="center">Products</TableCell>
                            <TableCell align="center">Tags</TableCell>
                            {/* <TableCell align="center">Orders</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? data.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                              )
                            : data
                        )
                            .filter((r) => {
                                // Remove Wishlink's customer from list
                                return r.customerId !== 6758706741535;
                            })
                            .map((row, i) => (
                                <GroupProductsTable
                                    key={i}
                                    customer={row.customerId}
                                    orders={row.groupedOrders}
                                />
                            ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                style={{ overflow: "inherit" }}
                                rowsPerPageOptions={[
                                    5,
                                    10,
                                    25,
                                    { label: "All", value: -1 },
                                ]}
                                colSpan={3}
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "rows per page",
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
};

export default RepeatingProductsTable;
