import { Box,
  IconButton,
  Collapse,
  Typography,
  TableContainer,
  Table,
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell, 
  Paper,
  Avatar,
  Button, } from "@mui/material"; 
import React from 'react';
import NonDeliveryReportButton  from './NonDeliveryReportButton';
import { TableFooter, TablePagination } from "@mui/material"
import TablePaginationActions from "../../utils/TablePaginationActions";   
import { useState, useEffect } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CSVLink } from "react-csv";
import { useDispatch } from "react-redux";
import { showLoading, hideLoading, changeNdronHoldStatusBulk } from "../../actions/ordersActions";

const NdrOnHoldTable = ({tableData}) => {

  const dispatch = useDispatch()

  const [data, setData] = useState(
  tableData?.sort(
  (a, b) => new Date(a.ShipmentDate) - new Date(b.ShipmentDate)
  )
  );
  const [open, setOpen] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [ excelData, setExcelData] = useState([])

  const emptyRows =
  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
  setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
  };


  useEffect(() => {
    if (tableData.length > 0) {
    setOpen(Array(tableData.length).fill(false));
    }
    setData(
    [...tableData]
    ?.sort(
      (a, b) => new Date(a.ShipmentDate) - new Date(b.ShipmentDate)
    )
    .reverse()
    );
  }, [tableData]);

  const handleSubmit = (data) => {
    // dispatch(showLoading())
    const dynamoObj = data.map(x => ({
      fulfillment_id : x.POID,
      status : "dispatched",
    }))
    const newObj = data.map(item => ({
      AWB : item.awb,
      AltInstruction : item.ndr_history[item.ndr_history.length - 1]?.ActionType,
      ReAttemptDate : item.ndr_history[item.ndr_history.length - 1]?.ReAttemptDate,
      AlternateAddress : item.ndr_history[item.ndr_history.length - 1]?.AlternateAddress,
      AlternatePincode : item.ndr_history[item.ndr_history.length - 1]?.AlternatePincode,
      AlternateContactNo : "",
      RTOReason : item.ndr_history[item.ndr_history.length - 1]?.RTOReason
    }))
    // console.log(dynamoObj)
    setExcelData(newObj)
    // dispatch(changeNdronHoldStatusBulk(dynamoObj))
  }

  return (
    <div>
      <h3>On Hold NDR Orders</h3>
      {tableData.length > 0 ? (
      <>
        <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
        <TableHead>
            
            <TableRow style={{textalign: "Center"}}>
                <TableCell>
                  {tableData.length} Items
                </TableCell>
                <TableCell>Order Details</TableCell>
                <TableCell>Order ID</TableCell>
                <TableCell>AWB</TableCell>
                <TableCell>Current Location</TableCell>
                <TableCell>Destination</TableCell>
                <TableCell>Total Attempts</TableCell>
                <TableCell>Shipment Date</TableCell>           
                <TableCell>Pending Days</TableCell>
                <TableCell>Payment Type</TableCell>
                <TableCell>Last Delivery Status XB</TableCell>
                <TableCell>Customer Care Action</TableCell>
                <TableCell>LBI Remarks</TableCell>
            </TableRow>
        </TableHead>
        
        <TableBody>
        {(rowsPerPage > 0
                ? tableData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : tableData
              ).map((row, index) => (
            <React.Fragment key={index}>
              
                <TableRow
                  key={index}
                  sx={{
                    bgcolor: index % 2 === 0 ? "#ededed" : "#f2f5ed",

                    "& > *": { borderBottom: "unset" },
                  }}
                >
                  <TableCell component="th" scope="row">
                        {index + 1}
                  </TableCell>
                  <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            const temp = open.map((i, openIndex) => {
                              if (index !== openIndex) {
                                return i;
                              } else {
                                return !i;
                              }
                            });
                            setOpen(temp);
                          }}
                        >
                          {open[index] ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                  </TableCell>
                  <TableCell>{row.POID}</TableCell>
                  <TableCell>{row.awb}</TableCell>
                  <TableCell>{row.Location}</TableCell>
                  <TableCell>{row.DestinationLocation}</TableCell>
                  <TableCell>{row.TotalAttempt}</TableCell>
                  <TableCell>{new Date(row.ShipmentDate).toLocaleDateString("en-in")}</TableCell>
                  <TableCell>{row.PendingDays}</TableCell>
                  <TableCell>{row.PaymentMode}</TableCell>
                  <TableCell
                    sx={{ color: "blue" }}
                  >{row.Status}
                  </TableCell>
                  <TableCell style={{color:"red"}}>
                    {row.ndr_history[row.ndr_history.length - 1]?.ActionType }
                  </TableCell>
                  <TableCell style={{color:"red"}}>
                    {row.ndr_history[row.ndr_history.length - 1]?.Action}
                  </TableCell>
                </TableRow>
                <TableRow style={{verticalAlign : "baseline"}}>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={7}
                  >
                    <Collapse in={open[index]} timeout="auto" unmountOnExit>
                      <Box sx={{ margin: 1 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            Product Details
                          </Typography>
                            <Table size="small" aria-label="purchases">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Image</TableCell>
                                  <TableCell>SKU</TableCell>
                                  <TableCell>Name</TableCell>
                                  <TableCell>Size</TableCell>
                                  <TableCell>Price</TableCell>
                                  <TableCell>Discount</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {row.order_items?.map((x,index) => (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <Avatar
                                        src={x.url}
                                        alt="https://www.elegantthemes.com/blog/wp-content/uploads/2018/02/502-error.png"
                                        sx={{
                                          width: 100,
                                          height: 100,
                                          objectFit: "contain",
                                        }}
                                        variant="square"
                                      />
                                    </TableCell>
                                    <TableCell>{x.sku.split(".")[0]}</TableCell>
                                    <TableCell>{x.name}</TableCell>
                                    <TableCell>{x.variant_title}</TableCell>
                                    <TableCell>{x.selling_price}</TableCell>
                                    <TableCell>{x.discount ? x.discount : 0}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <h4>Customer Details :</h4>
                            <p>
                              Name: {row.customer_name}
                              <br/>
                              Phone No.: {row.customer_phone}
                              <br/>
                              Email: {row.customer_email}
                              <br/>
                              Address : {row.customer_address}
                              <br/>
                              City : {row.customer_city}
                              <br/>
                              State : {row.customer_state}
                              <br/>
                              Pincode : {row.customer_pincode}
                            </p>
                      </Box>   
                    </Collapse>
                  </TableCell>
                  {row.ndr_history.length > 0 ? (
                    <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={7}
                  >
                    <Collapse in={open[index]} timeout="auto" unmountOnExit>
                      <Box sx={{ margin: 1 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            NDR Customer Care History : 
                          </Typography>
                          {row.ndr_history.map((item,index) => (
                            <div key={index} style={{display : "flex", alignItems : "baseline"}}>
                              <h4>{index + 1}.</h4>
                              <div style={{paddingLeft:"10px"}}>
                                {Object.keys(item).map((x,index) => (
                                  <div key={index} style={{display:"flex"}}>
                                    <h4 style={{color:"blue", margin:"0"}}>{x} :</h4>
                                    <h4 style={{paddingLeft:"10px", margin:"0"}}>{Object.values(item[x])}</h4>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                      </Box>   
                    </Collapse>
                  </TableCell>
                  ) : (
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={7}
                    >
                      <Collapse in={open[index]} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                          <Typography variant="h6" gutterBottom component="div" style={{fontSize:"large"}}>
                            NDR Customer Care History : 
                          </Typography>
                          <h4 style={{color:"red"}}>No Customer Care History Yet</h4>
                        </Box>
                      </Collapse>
                    </TableCell>
                  )}
                </TableRow>
          
            </React.Fragment>
            ))}
            {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
        </TableBody>
        <TableFooter>
              <TableRow>
                <TablePagination
                  style={{ overflow: "inherit" }}
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
        </Table>
        </TableContainer>
        <br/>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleSubmit(data)}
        >
          <CSVLink data={excelData} filename={new Date().toLocaleDateString("en-in")}>Export XB NDR Orders</CSVLink>
        </Button>
        {/* {excelData.length>0 ? (
        ) : ""} */}
      </>
      ):(
      
      "No NDR Orders At Present"
      )}
      <br/>
      {/* <ExportReactCSV csvData={data} fileName={new Date().toLocaleDateString("en-in")} /> */}
    </div>
  )
}
export default NdrOnHoldTable
