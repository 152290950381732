import React from "react";
import { useState } from "react";
import SkeletonProgress from "./SkeletonProgress";
import OrderDisplay from "./OrderDisplay";
import "./manualExchange.css";
const ManualExchange = ({
  loading,
  order,
  email,
  previousExchangeDetails,
  exchangeHistory,
  setEmail,
  setOrder,
  setPreviousExchangeDetails,
  setExchangeHistoryDetails,
}) => {
  return (
    <div>
      {loading ? <SkeletonProgress /> : ""}
      {order && (
        <div className="page">
          <b>
            {order.tags.includes("replacement") ? (
              <p style={{ color: "red" }}>
                Please note: You'll be charged an Rs. 250 for your second
                exchange on the same order
              </p>
            ) : (
              <p style={{ color: "red" }}>
                A shipping charge of Rs. 99 applies for replacement
              </p>
            )}
          </b>
          <h1 className="main-title">Exchange Portal</h1>
          {/* <h2>Hello {customerName}</h2> */}
          <h3 className="orderid-txt">Order ID: {order.name}</h3>
          <OrderDisplay
            order={order}
            loading={loading}
            email={email}
            previousExchangeDetails={previousExchangeDetails}
            exchangeHistory={exchangeHistory}
            setEmail={setEmail}
            setOrder={setOrder}
            setPreviousExchangeDetails={setPreviousExchangeDetails}
            setExchangeHistoryDetails={setPreviousExchangeDetails}
          />
        </div>
      )}
    </div>
  );
};

export default ManualExchange;
