import { filter } from 'd3'
import React from 'react'
import Typography from '@mui/material/Typography';


const AverageHandleTime = ({tickets}) => {
    const averageTime = () =>{
        // console.log("handle",tickets)

        const closedTickets = tickets.filter((ticket)=> ticket.package_status == "closed")
        // console.log("closed",closedTickets)

        let timeToReply = closedTickets.reduce((acc,ticket)=>{
            if(String(ticket.closed_by).replace(" ","").toLowerCase() === "littlebox"){
                const time = parseFloat((new Date(ticket.messages[ticket.messages.length -2].time) -  new Date(ticket.created_at))/(1000 *60 *60))
                acc += time;
            }
            else{
                const time = parseFloat((new Date(ticket.messages[ticket.messages.length -1].time) - new Date(ticket.created_at))/ (1000 *60 *60))
                acc += time;
            }
            return acc;
    },0)
        // console.log(timeToReply)

        const avg = (timeToReply/closedTickets.length).toFixed(2);
        // console.log(avg)
        return avg;
    }
  return (
    <div>
        <Typography variant="button" display="block" gutterBottom>
        Average Handle Time
      </Typography>
        {averageTime()} Hour
    </div>
  )
}

export default AverageHandleTime