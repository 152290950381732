const initialState = {
    reverse_pickedup_monthly: [],
    reverse_notpicked_monthly: [],
    reverse_delivered_monthly: [],
    reverse_by_status: [],
    reverse_by_status_report: [],
    forward_by_status_report: [],
    reverse_dlvd_by_status: [],
    reverse_single: [],
    filteredOrdersReverse: [],
};

const reverseDeliveryReducer = (state = initialState, action) => {
    switch (action.type) {
        case "MONTHLY_REVERSE_PICKED_ORDERS":
            return {
                ...state,
                reverse_pickedup_monthly: [
                    ...state.reverse_pickedup_monthly,
                    ...action.data,
                ],
            };
        case "MONTHLY_REVERSE_NOT_PICKED_ORDERS":
            return {
                ...state,
                reverse_notpicked_monthly: [
                    ...state.reverse_notpicked_monthly,
                    ...action.data,
                ],
            };
        case "MONTHLY_REVERSE_DELIVERED_ORDERS":
            return {
                ...state,
                reverse_delivered_monthly: [
                    ...state.reverse_delivered_monthly,
                    ...action.data,
                ],
            };
        case "CLEAR_REVERSE":
            return {
                ...state,
                reverse_by_status: [],
            };
        case "REVERSE_BY_STATUS":
            return {
                ...state,
                reverse_by_status: [...state.reverse_by_status, ...action.data],
            };
        case "REVERSE_BY_STATUS_REPORT":
            return {
                ...state,
                reverse_by_status_report: [
                    ...state.reverse_by_status_report,
                    ...action.data,
                ],
            };
        case "CLEAR_REVERSE_REPORT":
            return {
                ...state,
                reverse_by_status_report: [],
            };
        case "FORWARD_BY_STATUS_REPORT":
            return {
                ...state,
                forward_by_status_report: [
                    ...state.forward_by_status_report,
                    ...action.data,
                ],
            };
        case "CLEAR_FORWARD_REPORT":
            return {
                ...state,
                forward_by_status_report: [],
            };
        case "UPDATE_REVERSE_BY_STATUS":
            return {
                ...state,
                reverse_by_status: [
                    ...state.reverse_by_status.filter(
                        (x) => x.fulfillment_id !== action.payload
                    ),
                ],
            };
        case "UPDATE_REVERSE_BY_STATUS_TO_VOUCHER":
            return {
                ...state,
                reverse_by_status: [
                    ...state.reverse_by_status.map((x) => {
                        if (x.fulfillment_id === action.payload) {
                            return {
                                ...x,
                                exchangeAction: "Replacement&Giftcard",
                            };
                        } else {
                            return x;
                        }
                    }),
                ],
            };
        case "UPDATE_REVERSE_BY_STATUS_TO_REPLACEMENT":
            return {
                ...state,
                reverse_by_status: [
                    ...state.reverse_by_status.map((x) => {
                        if (x.fulfillment_id === action.payload) {
                            return { ...x, exchangeAction: "Replacement" };
                        } else {
                            return x;
                        }
                    }),
                ],
            };
        case "UPDATE_REVERSE_BY_STATUS_TO_GIFTCARD":
            return {
                ...state,
                reverse_by_status: [
                    ...state.reverse_by_status.map((x) => {
                        if (x.fulfillment_id === action.payload) {
                            return { ...x, exchangeAction: "Giftcard" };
                        } else {
                            return x;
                        }
                    }),
                ],
            };
        case "REVERSE_DELIVERED_BY_STATUS":
            return {
                ...state,
                reverse_dlvd_by_status: [
                    ...state.reverse_dlvd_by_status,
                    ...action.data,
                ],
            };
        case "GET_SINGLE_REVERSE":
            return {
                ...state,
                reverse_single: action.payload,
            };
        case "UPDATE_REVERSE_DELIVERED_INVENTORY_STATUS":
            return {
                ...state,
                reverse_dlvd_by_status: state.reverse_dlvd_by_status.map(
                    (x) => {
                        if (x.fulfillment_id === action.payload) {
                            console.log("found", action.payload);
                            return {
                                ...x,
                                reverse_inventory_updated: true,
                            };
                        } else {
                            return x;
                        }
                    }
                ),
            };
        case "HIDE_LOADER":
            return {
                ...state,
                loading: false,
            };
        case "CHANGE_FILTERED_ORDERS":
            return {
                ...state,
                filteredOrdersReverse: action.payload,
            };
        default:
            return state;
    }
};

export default reverseDeliveryReducer;
