import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    allShopifyInventory,
    allUnfulfilledOrders,
    getShopifyOrdersForBulkMail,
    getThreeUnfulfilledProductsFilteredData,
} from "../../actions/ordersActions";
import { allProducts } from "../../actions/productsActions";
import { allInventory } from "../../actions/inventoryActions";
import ProgressBar from "../../ProgressBar";
import UnfulfilledOrders from "../ordersWidgets/UnfulfilledOrders";
import RedirectLogin from "../RedirectLogin";
import "./index.css";
import OrderList from "../bulkOrderMailWidgets/OrderList";

const BulkOrderCancelMail = () => {
    const auth = useSelector((state) => state.auth);
    const {shopify_orders_bulk_mail : orders} = useSelector(
        (state) => state.shopifyDetails
    );
   

    const dispatch = useDispatch();

    useEffect(() => {
        if (!auth) return;
        if (!orders.length > 0) dispatch(getShopifyOrdersForBulkMail());
    }, [dispatch, auth]);

    return (
        <>
            {!auth ? (
                <RedirectLogin />
            ) : (
                <>
                    {!orders.length > 0 ? (
                        <ProgressBar />
                    ) : (
                        <>
                        <OrderList data={orders}/>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default BulkOrderCancelMail;
