import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Button,
  Container,
  Avatar,
  TextField,
  Box,
  Stack,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Login.css";
import { signIn } from "../actions/authActions";
import { showFullScreenLoader } from "../actions/loaderActions";

const theme = createTheme();

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(showFullScreenLoader());
    const user = `+91${username}`;
    dispatch(signIn(user, password));
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" sx={{ marginTop: "50px" }}>
        <Stack
          direction={"column"}
          gap={5}
          sx={{
            alignItems: "center",
            px: "10px",
          }}
          className="container"
        >
          <div class="logo">
            <Avatar
              variant="square"
              sx={{ width: "160px", marginTop: "1rem" }}
              src="provicali_new_logo_2_1.png"
            />
          </div>
          <Box
            className="form"
            sx={{
              py: "10px",
            }}
          >
            <div class="form__group">
              <div class="form__icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M5 5a5 5 0 0 1 10 0v2A5 5 0 0 1 5 7V5zM0 16.68A19.9 19.9 0 0 1 10 14c3.64 0 7.06.97 10 2.68V20H0v-3.32z" />
                </svg>
              </div>
              <TextField
                class="form__control"
                variant="standard"
                placeholder="Username"
                onChange={(e) => setUsername(e.target.value)}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
            <div class="form__group">
              <div class="form__icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z" />
                </svg>
              </div>
              <TextField
                class="form__control"
                variant="standard"
                type="password"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </div>
            <div>
              <Button class="form__button" onClick={handleSubmit}>
                Login
              </Button>
            </div>
          </Box>
        </Stack>
      </Container>
    </ThemeProvider>
  );
}
