export const deliveredSyncData = (orderBody) => {
  console.log(orderBody);
  const syncData = async () => {
    const syncUrl = "http://localhost:8001/api/syncDelivered";

    const response = await fetch(syncUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderBody),
    });
    const data = await response.json();
    console.log(data);
  };

  return (dispatch) => {
    syncData();
  };
};

export const shiprocketSyncData = (orderBody) => {
  console.log(orderBody);
  const syncData = async () => {
    const syncUrl = "http://localhost:8001/api/syncSR";
    console.log("here running");
    const response = await fetch(syncUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderBody),
    });
    const data = await response.json();
    console.log(data);
  };

  return (dispatch) => {
    syncData();
  };
};

export const ndrSyncData = (orderBody) => {
  console.log(orderBody);
  const syncData = async () => {
    const syncUrl = "http://localhost:8001/api/syncNdr";

    const response = await fetch(syncUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderBody),
    });
    const data = await response.json();
    console.log(data);
  };

  return (dispatch) => {
    syncData();
  };
};

export const AWBSyncData = (orderBody) => {
  console.log(orderBody);
  const syncData = async () => {
    const syncUrl = "http://localhost:8001/api/syncNdr/updatebyawb";

    const response = await fetch(syncUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderBody),
    });
    const data = await response.json();
    console.log(data);
  };

  return (dispatch) => {
    syncData();
  };
};

export const rtoSyncData = (orderBody) => {
  console.log(orderBody);
  const syncData = async () => {
    const syncUrl = "http://localhost:8001/api/syncRto";

    const response = await fetch(syncUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderBody),
    });
    const data = await response.json();
    console.log(data);
  };

  return (dispatch) => {
    syncData();
  };
};

export const reverseSyncData = (orderBody) => {
  console.log(orderBody);
  const syncData = async () => {
    const syncUrl = "http://localhost:8001/api/syncReverse";

    const response = await fetch(syncUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderBody),
    });
    const data = await response.json();
    console.log(data);
  };

  return (dispatch) => {
    syncData();
  };
};
