const initialState = null;

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_LOADED":
    case "SIGN_IN":
      return {
        ...initialState,
        action,
        phone: action.data.idToken.payload.phone_number,
        name: action.data.idToken.payload.name,
        user : action.data.idToken.payload.preferred_username
      };
    case "SIGN_IN_INVALID":
      return state;
    default:
      return state;
  }
};

export default authReducer;
