import React from 'react'
import { useState } from 'react';

import { Typography,Modal,Box,TableCell,TextField,Button } from '@mui/material';
import { updateNdrOrder, showLoading, hideLoading, XbRtoPostRequest } from '../../../actions/ordersActions';
import { useDispatch } from 'react-redux';
import { showFullScreenLoader } from '../../../actions/loaderActions';

const NdrRtoModal = ({open, setRtoModal ,rowData}) => {
  const [openUp, setOpenUp] = React.useState(open);
  const [ rtoText, setRtoText] = useState("")

  const dispatch = useDispatch()

  const handleClose = () => {
    setRtoText("")
    setOpenUp(false)
    setRtoModal(false)
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleSubmit = () => {
    dispatch(showFullScreenLoader())
    const formatDate = new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear()

    const XBObj = {
      XBkey : "CBDGjh20453NVHyhu",
      AWBNumber : rowData.awb.toString(),
      RTOReason : rtoText
    }

    const dynamoObj = [{
      fulfillment_id : rowData.POID,
      package_status : "sync#NDR",
      executive_id : "1",
      ndr_history : [
        {
          ActionType : "RTO",
          Action : "Marked As RTO By Customer Care",
          Customer_Care : rowData.executive_id,
          RTOReason : rtoText,
          RTODate : formatDate
        }
      ]
    }]
    console.log(XBObj)
    dispatch(XbRtoPostRequest(XBObj, dynamoObj))
    handleClose()
  }

  return (
    <div>
        <Modal
            open={openUp}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight="Bold">
            Edit Rto Details
            </Typography>
            <TableCell sx={{ fontWeight: "bold" }}
                        aligntext="left">Shipping ID:
            </TableCell>
            <TextField
                    variant="outlined"
                    className="select"
                    value = {rowData.awb}
                    style={{width:"400px"}}>                     
            </TextField>
            <br/>
            <TableCell sx={{ fontWeight: "bold" }}
                        aligntext="left">Rto Reason:
            </TableCell>
            <TextField
                    variant="outlined"
                    className="select"
                    value = {rtoText}                     
                    onChange = {e => setRtoText(e.target.value)}
                    style={{width:"400px"}}>
            </TextField>
            <br/>
            <br/>
            <Button variant="contained" onClick={handleSubmit}>Submit</Button>
        </Box>
        </Modal>
    </div>
  )
}

export default NdrRtoModal