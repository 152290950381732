import React, { useState, Fragment } from "react";
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Avatar,
  Divider,
  Checkbox,
  Menu,
  MenuItem,
} from "@mui/material";
import styled from "@emotion/styled";
import MuiTextField from "@mui/material/TextField";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Stack } from "@mui/system";

const TextField = styled(MuiTextField)(({ theme }) => ({
  // "& .MuiInputBase-root": {
  //   textAlign: "center",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  // },
  "& .MuiInputBase-input": {
    textAlign: "center", // Center the value
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CCCCCC",
      },
    },
    "&:hover:not(.Mui-disabled)": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CCCCCC",
      },
    },
  },
}));

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const POAnalyticsSalesTableRow = ({
  row,
  sizes,
  changePOQuantity,
  changeProductsSelectedForPO,
  productsSelected,
}) => {
  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setAnchorEl(event.currentTarget);
    } else {
      handleChecked(event.target.checked, row);
    }
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleQuantityChange = (e, size) => {
    const newRow = row;
    if (isNaN(parseInt(e.target.value))) {
      return;
    }
    newRow.poQuantity[size] = parseInt(e.target.value);
    changePOQuantity(newRow);
  };
  const handleChecked = (checked, row, vendor) => {
    changeProductsSelectedForPO(row, checked, vendor);
    handleMenuClose();
  };

  return (
    <Fragment>
      <TableRow>
        <TableCell colSpan={5}>
          <Stack direction={"row"} gap={3}>
            <div>
              <h4>1st week</h4>
              <p>{row[1] ? row[1]?.total : 0}</p>
            </div>
            <div>
              <h4>2nd week</h4>
              <p>{row[2] ? row[2]?.total : 0}</p>
            </div>
            <div>
              <h4>3rd week</h4>
              <p>{row[3] ? row[3]?.total : 0}</p>
            </div>
            <div>
              <h4>4th week</h4>
              <p>{row[4] ? row[4]?.total : 0}</p>
            </div>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell style={{ maxWidth: "120px" }}>
          <Avatar
            src={row.src}
            sx={{ width: 100, height: 100 }}
            variant="square"
          />
          <br />
          {row.sku}
          <br />
          {row.name}
        </TableCell>
        {/* headings */}
        <TableCell>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                background: "yellowgreen",
                padding: "5px",
                minHeight: "40px",
              }}
            >
              Category%
            </p>
            <p
              style={{
                background: "beige",
                padding: "5px",
                minHeight: "40px",
              }}
            >
              Prediction (r.m.s: {row.predictedRMS})
            </p>
            <p
              style={{
                background: "beige",
                padding: "5px",
                minHeight: "40px",
              }}
            >
              Inventory Stock
            </p>
            <p
              style={{
                background: "indianred",
                padding: "5px",
                minHeight: "40px",
              }}
            >
              Unfulfilled
            </p>
            <p
              style={{
                background: "yellowgreen",
                padding: "5px",
                minHeight: "40px",
              }}
            >
              RTO/RE in-transit
            </p>
            <p
              style={{
                background: "beige",
                padding: "5px",
                minHeight: "40px",
              }}
            >
              Pending in POs
            </p>
          </div>
        </TableCell>
        {sizes.map((i, k) => (
          <TableCell key={k}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  background: "yellowgreen",
                  padding: "10px 5px",
                }}
              >
                {row.percent[i] ? row.percent[i] : 0}%
              </p>
              <p
                style={{
                  background: "beige",
                  padding: "10px 5px",
                }}
              >
                {row?.predictedSales[i] || 0}
              </p>
              <p
                style={{
                  background: "beige",
                  padding: "10px 5px",
                }}
              >
                {row.sizes[i] ? row.sizes[i] : 0}
              </p>
              <p
                style={{
                  background: "indianred",
                  padding: "10px 5px",
                }}
              >
                {row.unfulfilled[i] ? row.unfulfilled[i] : 0}
              </p>
              <p
                style={{
                  background: "yellowgreen",
                  padding: "10px 5px",
                }}
              >
                {row.returned[i] ? row.returned[i] : 0}
              </p>
              <p
                style={{
                  background: "beige",
                  padding: "10px 5px",
                }}
              >
                {row.pending[i] ? row.pending[i] : 0}
              </p>
            </div>
          </TableCell>
        ))}
        <TableCell>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                background: "yellowgreen",
                padding: "10px 5px",
              }}
            >
              100%
            </p>
            <p
              style={{
                background: "beige",
                padding: "10px 5px",
              }}
            >
              {Object.values(row.predictedSales)
                .reduce((sum, item) => parseFloat(item) + sum, 0.0)
                .toFixed(2)}
            </p>
            <p
              style={{
                background: "beige",
                padding: "10px 5px",
              }}
            >
              {Object.keys(row.sizes).length !== 0
                ? Object.values(row.sizes).reduce((sum, item) => item + sum, 0)
                : 0}
            </p>
            <p
              style={{
                background: "indianred",
                padding: "10px 5px",
              }}
            >
              {row.totalUnfulfilled ? row.totalUnfulfilled : 0}
            </p>
            <p
              style={{
                background: "yellowgreen",
                padding: "10px 5px",
              }}
            >
              {Object.keys(row.returned).length !== 0
                ? Object.values(row.returned).reduce(
                    (sum, item) => item + sum,
                    0
                  )
                : 0}
            </p>
            <p
              style={{
                background: "beige",
                padding: "10px 5px",
              }}
            >
              {Object.keys(row.pending).length !== 0
                ? Object.values(row.pending).reduce(
                    (sum, item) => item + sum,
                    0
                  )
                : 0}
            </p>
          </div>
        </TableCell>
        <TableCell>{row.total4weeks}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={17}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <h3>Calculated PO numbers</h3>
              <Stack direction={"row"} sx={{ justifyContent: "space-around" }}>
                {/* checkbox */}
                <div>
                  {/* <Checkbox
                    {...label}
                    checked={productsSelected.some((p) => p.sku === row.sku)}
                    onClick={(e) => handleChecked(e, row)}
                  /> */}
                  <Checkbox
                    {...label}
                    checked={productsSelected.some((p) => p.sku === row.sku)}
                    onClick={handleMenuOpen}
                    // onClick={(e) => handleChecked(e, row)}
                  />
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                  >
                    {[
                      "JK FASHION",
                      "BINITA CREATION",
                      "T2B APPAREL",
                      "MISS CLOG",
                    ].map((o) => (
                      <MenuItem
                        onClick={(e) => {
                          handleChecked(isChecked, row, o);
                        }}
                      >
                        {o}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
                {sizes.map((s, i) => (
                  <Fragment key={i}>
                    <Stack
                      direction={"column"}
                      sx={{
                        alignItems: "center",
                        width: "100%",
                        margin: "0 8px",
                      }}
                    >
                      <Box
                        sx={{
                          background: "rgba(153,14,199,0.3)",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        <h3
                          style={{
                            fontSize: "25px",
                            margin: "2px 0",
                          }}
                        >
                          {s}
                        </h3>
                      </Box>
                      <Divider flexItem />
                      <TextField
                        disabled={productsSelected.some(
                          (p) => p.sku === row.sku
                        )}
                        value={row.poQuantity[s] ? row.poQuantity[s] : 0}
                        onChange={(e) => handleQuantityChange(e, s)}
                        InputProps={{
                          style: {
                            textAlign: "center",
                          },
                        }}
                        sx={{
                          flexWrap: "wrap",
                          mx: "auto",
                          marginTop: "10px",
                        }}
                      />
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                  </Fragment>
                ))}
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default POAnalyticsSalesTableRow;
