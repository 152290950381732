import { Card, CardContent, CardMedia, Typography, Box } from "@mui/material";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import TaskAltSharpIcon from "@mui/icons-material/TaskAltSharp";
import React from "react";

const POCard = ({ data, selected }) => {
    const datelimit = new Date(new Date().setDate(new Date().getDate() - 10));
    // console.log(data.SKU);
    // console.log("date limit", datelimit.toLocaleString());
    // console.log("date", new Date(data.date).toLocaleString());

    const handleImageFail = (e) => {
        e.target.onerror = null;
        // e.target.style.display = 'none'
        e.target.src =
            "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png";
    };

    return (
        <Box sx={{ position: "relative" }}>
            {selected ? (
                <Box
                    sx={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        zIndex: 1,
                        backdropFilter: "blur(0.8px)",
                        background: "rgba(24, 24, 24, 0.8)",
                        borderRadius: 1,
                    }}
                ></Box>
            ) : (
                <></>
            )}

            {selected ? (
                <TaskAltSharpIcon
                    sx={{
                        position: "absolute",
                        width: "55%",
                        height: "60%",
                        zIndex: 3,
                        color: "#6BD0FF",
                        transform: "translateY(30%)",
                    }}
                />
            ) : (
                <></>
            )}

            <Card
                sx={{
                    maxWidth: 300,
                    maxHeight: 188,
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                }}
            >
                {new Date(data.date) > new Date(datelimit) ? (
                    <FiberNewIcon
                        sx={{
                            position: "absolute",
                            right: 0,
                            top: 0,
                            fontSize: 35,
                            color: "#d1001f",
                        }}
                    />
                ) : (
                    ""
                )}

                <CardMedia
                    component="img"
                    alt="green iguana"
                    sx={{ width: 140, objectFit: "contain", height: 188 }}
                    image={data.imageUrl}
                    onError={handleImageFail}
                />
                <CardContent>
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{
                            color: selected ? "white" : "",
                            zIndex: 10,
                            position: "relative",
                            fontWeight: 800,
                            fontSize: selected ? 20 : "",
                        }}
                    >
                        {data.SKU}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        total sales : {data.totalsales}
                    </Typography>
                    {new Date(data.date) > new Date(datelimit) ? (
                        <Typography variant="body2" color="text.secondary">
                            predicted : {data.expectedsale}
                        </Typography>
                    ) : (
                        ""
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};

export default POCard;
