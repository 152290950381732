import React from "react";
import Category from "./Chart/Pie";
import { Typography } from "@mui/material";

const CategoryPercentage = ({ tickets }) => {
  console.log("tickets", tickets);
  let data = tickets.reduce((acc, ticket) => {
    const found = acc.find((ac) => ac.category === ticket.category);
    if (found) {
      found.count += 1;
    } else {
      const newObj = {
        category: ticket.category,
        count: 1,
      };
      acc.push(newObj);
    }
    return acc;
  }, []);
  console.log("data", data);

  const percentage = data.map((item) => {
    return {
      category: item.category,
      count: parseFloat(((item.count / tickets.length) * 100).toFixed(2)),
    };
  });
  console.log("percentage", percentage);
  return (
    <>
      <div>Category Percentage(%)</div>
      <Category data={percentage} />
    </>
  );
};

export default CategoryPercentage;
