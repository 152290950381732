import React from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SizeSelector from "./SizeSelector";

const ProductDisplay = ({
  product,
  isExchange,
  isSelected,
  onChange,
  exchangeChoice,
  isAlreadyExchange,
}) => {
  // const handleSizeChange = () => {};

  return (
    <li
      className={
        isSelected ? "product-details" : "product-details item-disabled"
      }
      key={product.id}
    >
      <img
        style={{ maxWidth: "80px", padding: "20px" }}
        src={product.src}
        alt="LittleBox"
      />
      <div>
        {" "}
        <p>{product.title}</p>
        <p>Size: {product.variant_title} </p>
      </div>
      <div>
        {isExchange && (
          <div>
            {isAlreadyExchange ? (
              <CheckCircleIcon sx={{ color: "green" }} />
            ) : (
              <SizeSelector
                exchangeChoice={exchangeChoice}
                product={product}
                onChange={onChange}
              />
            )}
          </div>
        )}
      </div>
    </li>
  );
};

export default ProductDisplay;
