import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import OrdersWithImages from "./OrdersWithImages.js";
import FilterUnfulfilledOrdersData from "../ordersWidgets/FilterUnfulfilledOrdersData"
import { createUnfulfilledOrdersTableData } from "../../utils/helpers";

const ProductStockCount = () => {
  const orderData = useSelector(state => state.orderDetails.orders)
  const [orderedProducts, setOrderedProducts] = useState([]);
  const [filteredDateData, setFilteredDateData] = useState([])

  useEffect(() => {
    if (orderData.length > 0) {
      const filteredOrders = orderData.filter(x => x.financial_status !== 'voided')
      const formatted = createUnfulfilledOrdersTableData(filteredOrders);
      setOrderedProducts(formatted);
    }
  }, [orderData]);

  useEffect(() => {
    if(filteredDateData?.length>0){
      const filteredOrders = filteredDateData.filter(x => x.financial_status !== 'voided')
      const formatted = createUnfulfilledOrdersTableData(filteredOrders)
      setOrderedProducts(formatted);
    }else{
      const filteredOrders = orderData.filter(x => x.financial_status !== 'voided')
      const formatted = createUnfulfilledOrdersTableData(filteredOrders);
      setOrderedProducts(formatted);
    }
  },[filteredDateData,orderData])

  return (
    <div>
      <FilterUnfulfilledOrdersData data={orderData} filteredDateData={setFilteredDateData}/>
      <OrdersWithImages orderedProducts={orderedProducts}/>
    </div>
  );
};

export default ProductStockCount;
