import React, { useEffect, useState } from "react";
import DesignPicker from "../DesignPicker/DesignPicker";
import { getDesignList } from "../../actions/inventoryActions";

const UpdateDesignPicks = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const designData = await getDesignList();
      if (designData.statusCode === 200) {
        setData(designData.data.data);
      }
      setLoading(false);
    })();
  }, []);
  return (
    <div>
      <DesignPicker loading={loading} designs={data} changeDesign={setData} />
    </div>
  );
};

export default UpdateDesignPicks;
