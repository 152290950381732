import { Box, Paper, Skeleton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getAutomationStatus } from "../actions/analyticsActions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import SyncIcon from "@mui/icons-material/Sync";
/*
4weeksSale: 28,
3monthsSale: 90,
3monthsUnfulfilled: 9090
3monthsAll: 9091
3monthsNone: 9092
3monthsZaamo: 9093
3monthsWishLink: 9094
3monthsGoaffpro: 9095
3monthsShopify: 9096
returnedProducts: 100
pendingPO: 101
*/
const namesWithId = [
  [
    {
      name: "4weeksSale",
      id: 28,
    },
    {
      name: "3monthsSale",
      id: 90,
    },
    {
      name: "3monthsUnfulfilled",
      id: 9090,
    },
  ],
  [
    {
      name: "3monthsAll",
      id: 9091,
    },
    {
      name: "3monthsShopify",
      id: 9096,
    },
    {
      name: "returnedProducts",
      id: 100,
    },
  ],
  [
    {
      name: "delhivery forward orders",
      id: 1000,
    },
    {
      name: "delhivery reverse orders",
      id: 1001,
    },
  ],
];

const months = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const SkeletonComponent = () => {
  return (
    <Stack spacing={1}>
      {/* <Skeleton variant="text" sx={{ fontSize: '1rem', width:"300px" }} /> */}
      <Skeleton
        animation="wave"
        variant="rounded"
        width={"100%"}
        height={100}
      />
      {/* {[1,2,3].map(x => (
                <Skeleton key={x} variant="text" sx={{ fontSize: '0.75rem', width:"200px",paddingLeft:"10px" }} />
            ))} */}
    </Stack>
  );
};
const AutomationStatus = () => {
  const [statuses, setStatuses] = useState([]);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    getAutomationStatus()
      .then((data) => {
        if (data.statusCode === 200) {
          setStatuses(data.body);
        } else {
          setIsError(true);
        }
      })
      .catch(() => {
        setIsError(true);
      });
  }, []);

  const createAutomationStatusRows = (name, id) => {
    const found = statuses.find((s) => s.fulfillment_id === id);

    const date = found.last_updated_at;
    const splitDate = found.last_updated_at.split("/");
    const month = months[parseInt(splitDate[1]) - 1];
    const day = splitDate[0];
    const year = splitDate[2];
    const dateInName = `${day} ${month} ${year}`;
    const isToday = date === new Date().toLocaleDateString("en-in");

    return (
      <Stack
        direction={"row"}
        sx={{
          alignItems: "top",
        }}
      >
        <span>
          {isToday ? (
            <CheckCircleIcon sx={{ color: "green", width: 20, height: 20 }} />
          ) : (
            <CloseIcon sx={{ color: "red" }} />
          )}
        </span>
        <span style={{ fontWeight: 500 }}>{name}</span> : {dateInName}{" "}
      </Stack>
    );
  };
  return (
    <Box my={2}>
      <Paper
        sx={{
          px: 4,
          py: 2,
        }}
      >
        {!statuses.length > 0 && !isError && <SkeletonComponent />}
        {statuses.length > 0 && (
          <div>
            <div
              style={{
                fontSize: 20,
                color: "grey",
                fontWeight: 500,
                marginBottom: 10,
                display: "flex",
                alignItems: "top",
              }}
            >
              <span>
                <SyncIcon sx={{ color: "green" }} />
              </span>
              <span
                style={{
                  paddingRight: 10,
                  borderBottom: "2px solid grey",
                }}
              >
                Daily Sync Status
              </span>
            </div>
            <Stack direction={"row"} gap={2}>
              {namesWithId.map((item, i) => (
                <div key={i}>
                  {item.map((row, i) => (
                    <div key={i}>
                      {createAutomationStatusRows(row.name, row.id)}
                    </div>
                  ))}
                </div>
              ))}
            </Stack>
          </div>
        )}
      </Paper>
    </Box>
  );
};

export default AutomationStatus;
