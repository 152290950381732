import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllTickets } from "../../actions/ticketActions";
import RedirectLogin from "../RedirectLogin";
import AllQueryFilter from "../ordersWidgets/AllQueryFilter";

import Loader from "../Loader";
import AssignTicketFilter from "./AssignTicketFilter";
import { getcustomercareusernames } from "../../actions/ordersActions";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
} from "@mui/material";
import AssignTicketBox from "./AssignTicketBox";
import AutomaticAssign from "./AutomaticAssign";
import TicketsAssignReport from "./TicketsAssignReport";

const AssignTicket = () => {
  const [tableData, setTableData] = useState([]);
  const [sortValue, setSortValue] = useState("New");
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { tickets } = useSelector((state) => state.tickets);
  const loading = useSelector((state) => state.loader.loading);
  const { customerCareUsers } = useSelector((state) => state.inventoryDetails);
  useEffect(() => {
    if (auth) dispatch(getAllTickets("All"));
    if (customerCareUsers.length === 0 && auth)
      dispatch(getcustomercareusernames());
  }, [auth]);

  useEffect(() => {
    if (tickets.length > 0) {
      const sorted = tickets
        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
        .map((t) => {
          const differenceTime =
            new Date().getTime() - new Date(t.created_at).getTime();
          const differentDays = Math.round(differenceTime / (1000 * 3600 * 24));
          return {
            ...t,
            created_at: new Date(t.created_at).toLocaleString(),
            updated_at: new Date(t.updated_at).toLocaleString(),
            ticketAge: differentDays,
          };
        });
      const ticketSort = sorted.filter(
        (t) =>
          (t.package_status === "active#new" && sortValue === "New") ||
          (t.package_status === "active#open" && sortValue === "Open") ||
          (!("executive_id" in t) &&
            t.package_status === "active#open" &&
            sortValue === "New")
      );
      setTableData(ticketSort);
    }
  }, [tickets, sortValue]);
  console.log("table data length", tableData.length);
  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        // <>
        //   <Paper sx={{ py: "10px", my: "5px" }}>
        //     <TicketsAssignReport tickets={tickets} users={customerCareUsers} />
        //   </Paper>
        //   <Stack direction={"row"} gap={2} sx={{ alignItems: "start" }}>
        //     <AutomaticAssign />
        //     <div>
        //       {loading ? (
        //         <Skeleton variant="rectangular" width={700} height={100} />
        //       ) : (
        //         <Paper sx={{ padding: "20px" }}>
        //           <FormControl>
        //             <FormLabel id="demo-row-radio-buttons-group-label">
        //               Sort By
        //             </FormLabel>
        //             <RadioGroup
        //               row
        //               aria-labelledby="demo-row-radio-buttons-group-label"
        //               name="row-radio-buttons-group"
        //               value={sortValue}
        //               onChange={(e) => {
        //                 setSortValue(e.target.value);
        //               }}
        //             >
        //               <FormControlLabel
        //                 value="New"
        //                 control={<Radio />}
        //                 label="New"
        //               />
        //               <FormControlLabel
        //                 value="Open"
        //                 control={<Radio />}
        //                 label="Open"
        //               />
        //             </RadioGroup>
        //           </FormControl>
        //           <AssignTicketBox
        //             tickets={tableData}
        //             users={customerCareUsers}
        //           />

        //         </Paper>
        //       )}
        //     </div>
        //   </Stack>
        // </>
        <AssignTicketFilter tickets={tableData} users={customerCareUsers} />
      )}
    </>
  );
};
export default AssignTicket;
