const initialState = {
  customers: [],
  customerDetails: {},
  count: 0,
  customerCount: 0,
  orderCount: 0,
  shopifyOrdersCount: 0,
  shopifyCustomerOrders: [],
  shopifyOrders: [],
  dbOrders: [],
  customerLoading: false,
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOPIFY_ORDERS":
      return {
        ...state,
        shopifyOrders: action.data,
      };
    case "SHOPIFY_CUSTOMER":
      return {
        ...state,
        customers: action.data,
      };
    case "SHOPIFY_CUSTOMER_COUNT":
      return {
        ...state,
        customerCount: action.data,
      };
    case "SHOPIFY_CUSTOMER_LOADER":
      return {
        ...state,
        customerLoading: action.data,
      };
    case "CUSTOMER_COUNT":
      return {
        ...state,
        count: action.data,
      };
    case "TOTAL_ORDERS_COUNT":
      return {
        ...state,
        shopifyOrdersCount: action.data,
      };
    case "ORDERS_COUNT":
      return {
        ...state,
        orderCount: action.data,
      };
    case "CUSTOMER_ORDERS":
      return {
        ...state,
        shopifyOrders: action.data,
      };
    case "CUSTOMER_ORDER_LOADER":
      return {
        ...state,
        shopifyOrderLoading: action.data,
      };
    case "CUSTOMER_DB_ORDERS":
      return {
        ...state,
        dbOrders: action.data,
      };
    case "CUSTOMER_DETAILS":
      return {
        ...state,
        customerDetails: action.data,
      };
    default:
      return state;
  }
};

export default customerReducer;
