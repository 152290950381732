import React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TableFooter,
  TablePagination,
  Button,
  Avatar,
  CircularProgress,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useDispatch, useSelector } from "react-redux";
import { allInventory } from "../../../actions/inventoryActions";
import {
  createSingleDraftOrderShopify,
  createSingleOrderShopify,
  getXBReverseShipmentDetails,
  reApproveReverseOrder,
} from "../../../actions/ordersActions";
import { XbReverseShipmentHistoryModal } from "./XbReverseShipmentHistoryModal";
import { showLoading } from "../../../actions/loaderActions";
import DraftOrderModal from "./DraftOrderModal";
import CreateGiftCardModal from "./CreateGiftCardModal";
import NprActionsButton from "./NprActionsButton";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function Row({ row, index, productIdArray, setSearchFilter }) {
  const [open, setOpen] = useState(false);
  const [orderHistoryModal, setOrderHistoryModal] = useState(false);
  const dispatch = useDispatch();

  const handleCancelReverse = () => {
    dispatch(showLoading());
    const objBody = {
      id: row.fulfillment_id,
      cancelled_awb: [row.awb_code],
    };
    console.log(objBody);
    //   dispatch(reApproveReverseOrder(objBody))
  };

  return (
    <>
      <TableRow
        sx={{
          bgcolor: row.tags?.includes("replacement")
            ? "rgb(232, 165, 158)"
            : "rgb(245,245,245)",

          "& > *": { borderBottom: "unset" },
        }}
      >
        <TableCell>{index + 1}</TableCell>
        <TableCell>{row.numberOfDays}</TableCell>
        <TableCell component="th" scope="row">
          {row.orderNumber || row.fulfillment_id}
        </TableCell>
        <TableCell>{row.type}</TableCell>
        <TableCell align="left" sx={{ color: "red", cursor: "pointer" }}>
          <div
            onClick={() => {
              setOrderHistoryModal(true);
              dispatch(
                getXBReverseShipmentDetails({
                  AWB: parseInt(
                    row.reverse_pickup_awb
                      ? row.reverse_pickup_awb
                      : row.awb_code
                  ),
                })
              );
            }}
          >
            {row.reverse_pickup_awb ? row.reverse_pickup_awb : row.awb_code}
          </div>
        </TableCell>
        {orderHistoryModal ? (
          <XbReverseShipmentHistoryModal
            open={orderHistoryModal}
            setOrderHistoryModal={setOrderHistoryModal}
          />
        ) : (
          ""
        )}
        <TableCell>
          {row.xb_reverse_last_sync_status
            ? row.xb_reverse_last_sync_status
            : "NA"}
        </TableCell>
        <TableCell>
          {row.reverse_pickup_date ? row.reverse_pickup_date : "NA"}
        </TableCell>
        <TableCell>
          {row.xb_reverse_last_sync_status === "WHHandover"
            ? row.reverse_delivery_date
            : "Not Delivered Yet"}
        </TableCell>
        <TableCell>
          {new Date(row.modified_at).toLocaleDateString("en-in")}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <NprActionsButton rowData={row} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Product Details :
              </Typography>
              <Typography>Name : {row.address.name}</Typography>
              <Typography>
                Contact : {row.address?.phone} | {row.alternatePhone}
              </Typography>
              <div>
                {row.type === "Exchange" ? (
                  <>
                    <h4>Exchange Type: {row.exchangeReason}</h4>
                    <h4>Reason:</h4>
                  </>
                ) : (
                  <>
                    <h4>Return reason</h4>
                  </>
                )}
                <p>{row.reason}</p>
              </div>
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <div>
                  <h4>Shopify Image : </h4>
                  {row.products.map((p) => (
                    <div
                      key={p.id}
                      className="exchangePSpecs"
                      style={{
                        maxWidth: "240px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Avatar
                        src={p.url}
                        alt="LBI"
                        sx={{
                          width: 200,
                          height: 200,
                          objectFit: "ro",
                        }}
                        variant="square"
                      />
                      <h4>SKU: {p.sku}</h4>
                      {row.type === "Exchange" ? (
                        <div>
                          <h4>Original Size: {p.originalSize}</h4>
                          <h4>Requested Size: {p.size}</h4>
                        </div>
                      ) : (
                        <h4>Size: {p.originalSize}</h4>
                      )}
                    </div>
                  ))}
                </div>
                <div className="flexRow" style={{ paddingLeft: "50px" }}>
                  {row.files?.length > 0 ? (
                    <div>
                      <h4>Customer Pictures:</h4>
                      <div className="flexRow">
                        {row.files?.map((f) => (
                          <div key={f.id}>
                            <a href={f.url} target="_blanck">
                              <img
                                src={`https://drive.google.com/uc?export=view&id=${f.id}`}
                                alt={f.name}
                                width="200"
                              />
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <CircularProgress />
                  )}
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const NprDataTable = ({ orders, setSearchFilter }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(
    orders
      .sort((a, b) => Number(a.orderNumber) - Number(b.orderNumber))
      .reverse()
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [productIdArray, setProductIdArray] = useState([]);

  const { stock } = useSelector((state) => state.inventoryDetails);
  const { products } = useSelector((state) => state.productDetails);

  useEffect(() => {
    if (products.length > 0) {
      const tempArray = [];
      products.map((x) =>
        x.variants.map((item) =>
          tempArray.push({
            product_id: item.product_id,
            sku: item.sku,
            size: item.title,
          })
        )
      );
      setProductIdArray(tempArray);
    }
  }, [products]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(
    () =>
      setData(
        orders
          .sort((a, b) => Number(a.orderNumber) - Number(b.orderNumber))
          .reverse()
      ),
    [orders]
  );

  useEffect(() => {
    if (stock.length > 0) {
      //   console.log("order", orders.filter((o) => !Object.keys(o).includes('products')))
      const mappedOrders = orders.map((o) => {
        const productsWithImages = o.products.map((p) => {
          const found = stock.find((i) => i.SKU === p.sku);
          const url =
            found?.imageUrl ||
            "https://media.istockphoto.com/vectors/error-page-or-file-not-found-icon-vector-id924949200";
          return { ...p, url };
        });

        return {
          ...o,
          products: productsWithImages,
        };
      });
      setData(mappedOrders);
    } else {
      dispatch(allInventory());
    }
  }, [stock, orders]);

  return (
    <>
      <br />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Index</TableCell>
              <TableCell>Number of days</TableCell>
              <TableCell>Order Number</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">AWB Code</TableCell>
              <TableCell align="left">XB Sync Status</TableCell>
              <TableCell align="left">Order Pickup Date</TableCell>
              <TableCell align="left">Order Delivered Date</TableCell>
              <TableCell align="left">Approved On </TableCell>
              <TableCell align="left">View Details</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row, index) => (
              <Row
                key={index}
                row={row}
                index={index}
                productIdArray={productIdArray}
                setSearchFilter={setSearchFilter}
              />
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{ overflow: "inherit" }}
                rowsPerPageOptions={[5, 10, 25, 100]}
                colSpan={3}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default NprDataTable;
