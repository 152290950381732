import React, { useEffect, useState } from "react";
import { get7daysticket, getAll7daysticket } from "../../actions/ticketActions";
import { CircularProgress, Stack } from "@mui/material";
import AverageReplyTime from "../ticketWidgets/report/AverageReplyTime";
import AverageHandleTime from "../ticketWidgets/report/AverageHandleTime";
import CategoryPercentage from "../ticketWidgets/report/CategoryPercentage";
import QuestionPercentage from "../ticketWidgets/report/QuestionPercentage";
import CustomercareReplied from "../ticketWidgets/report/CustomercareReplied";
import Last7daysCustomerCare from "../ticketWidgets/report/Last7daysCustomerCare";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const DemoPaper = styled(Paper)(({ theme }) => ({
  width: 225,
  height: 120,
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: "center",
}));
const DemoPaper1 = styled(Paper)(({ theme }) => ({
  width: 450,
  height: 300,
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: "center",
}));

const TicketsReport = () => {
  const [isloading, setLoading] = useState(false);
  const [allTickets, setAllTickets] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAll7daysticket().then((data) => {
      if (data.statusCode === 200) {
        if (data.body.length > 0) {
          setAllTickets(data.body);
        } else {
          setIsEmpty(true);
        }
      } else {
        setIsError(true);
      }
      setLoading(false);
    });
  }, []);

  return (
    <>
      {(isloading || (allTickets.length === 0 && !isEmpty && !isError)) && (
        <CircularProgress />
      )}
      {allTickets.length > 0 && (
        <div>
          <Stack
            direction={"row"}
            gap={2}
            justifyContent="center"
            alignItems="center"
          >
            <DemoPaper>
              <AverageReplyTime tickets={allTickets} />
            </DemoPaper>
            <DemoPaper>
              <AverageHandleTime tickets={allTickets} />
            </DemoPaper>
          </Stack>

          <Stack
            direction={"row"}
            gap={2}
            justifyContent="center"
            alignItems="center"
            sx={{ m: 2 }}
          >
            <DemoPaper1>
              <CategoryPercentage tickets={allTickets} />
            </DemoPaper1>
            <DemoPaper1>
              <QuestionPercentage tickets={allTickets} />
            </DemoPaper1>
          </Stack>

          <Stack
            direction={"row"}
            gap={2}
            justifyContent="center"
            alignItems="center"
            sx={{ m: 2 }}
          >
            <DemoPaper1>
              <CustomercareReplied tickets={allTickets} />
            </DemoPaper1>
          </Stack>

          <Stack
            direction={"row"}
            gap={2}
            justifyContent="center"
            alignItems="center"
            sx={{ m: 2 }}
          >
            <Last7daysCustomerCare tickets={allTickets} />
          </Stack>
        </div>
      )}
    </>
  );
};

export default TicketsReport;
