import React from "react";
import { useState } from "react";
import "react-date-range/dist/styles.css"; // Import the styles for react-date-range
import "react-date-range/dist/theme/default.css"; // Import the default theme for react-date-range
import TextField from "@mui/material/TextField";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { InputAdornment, Popover } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useEffect } from "react";
const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

function convertDateRangeToIndiaTimeZoneISOString(startDate, endDate) {
  // Convert start date and end date to India time zone (+05:30)
  const indiaStartDate = dayjs(startDate).tz("Asia/Kolkata");
  const indiaEndDate = dayjs(endDate).tz("Asia/Kolkata");

  // Format the dates as ISO strings
  const indiaStartDateISOString = indiaStartDate.format();
  const indiaEndDateISOString = indiaEndDate.format();

  return {
    startDate: indiaStartDateISOString,
    endDate: indiaEndDateISOString,
  };
}

const DatePicker = ({ setDate }) => {
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    console.log("date range", dateRange[0].startDate, dateRange[0].endDate);
    console.log("date range");
    const isoDate = convertDateRangeToIndiaTimeZoneISOString(
      dateRange[0].startDate,
      dateRange[0].endDate
    );
    setDate({ startDate: isoDate.startDate, endDate: isoDate.endDate });
  }, [dateRange]);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const options = {
    day: "numeric",
    month: "short",
    year: "2-digit",
  };
  return (
    <div>
      <div>
        <TextField
          style={{ width: "230px" }}
          size="small"
          variant="outlined"
          onClick={handleClick}
          aria-describedby={id}
          value={`${dateRange[0].startDate.toLocaleDateString(
            undefined,
            options
          )} - ${dateRange[0].endDate.toLocaleDateString(undefined, options)}`}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">
                <p
                  style={{
                    fontWeight: 800,
                    borderRight: "1px solid black",
                    paddingRight: "4px",
                  }}
                >
                  <CalendarMonthIcon />
                </p>
              </InputAdornment>
            ),
          }}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div className="custom-date-range-picker">
            <DateRangePicker
              onChange={(item) => setDateRange([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={dateRange}
              direction="horizontal"
            />
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default DatePicker;
