import { useState } from "react";
import { TextareaAutosize, Modal, Box, Button } from "@mui/material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
};

const RejectREModal = ({ open, close, saveReason, deny }) => {
    const [reason, setReason] = useState("");

    return (
        <div>
            <Modal
                open={open}
                onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div
                        className="wrapper"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div
                            className="info"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                width: "100%",
                            }}
                        >
                            <h1>REJECT RETURN/EXCHANGE</h1>
                            <TextareaAutosize
                                aria-label="minimum height"
                                minRows={5}
                                placeholder="Rejection reason"
                                style={{
                                    width: "100%",
                                    fontSize: "2em",
                                    padding: 10,
                                }}
                                onChange={(e) => saveReason(e.target.value)}
                            />
                            <br />
                        </div>
                    </div>
                    <div className="flexRow">
                        <Button
                            variant="contained"
                            color="success"
                            onClick={close}
                        >
                            Go Back
                        </Button>
                        <Button variant="contained" onClick={deny}>
                            Deny Request
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default RejectREModal;
