import { Container, MenuItem, Stack, TextField } from "@mui/material";
import React from "react";
import {
  categoryTypes,
  productTypes,
  vendorTypes,
} from "../../../utils/helperArrays";

const Filter = ({
  type,
  setType,
  category,
  setCategory,
  vendor,
  setVendor,
}) => {
  return (
    <div>
      <Stack direction={"row"} gap={2} sx={{ my: "15px" }}>
        {/* type filter */}
        <TextField
          select
          label="Select Product Type"
          value={type}
          onChange={(e) => {
            setType(e.target.value);
          }}
          variant="outlined"
          className="select"
          fullWidth
          style={{ background: "white" }}
          size="small"
        >
          {productTypes
            .filter((p) => p.value)
            .map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </TextField>
        {/* category filter */}
        <TextField
          select
          label="Select Category"
          value={category}
          onChange={(e) => {
            setCategory(e.target.value);
          }}
          variant="outlined"
          className="select"
          fullWidth
          style={{ background: "white" }}
          size="small"
        >
          {categoryTypes(type).map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        {/* vendor */}
        <TextField
          select
          label="Select Vendor"
          value={vendor}
          onChange={(e) => {
            setVendor(e.target.value);
          }}
          variant="outlined"
          className="select"
          fullWidth
          style={{ background: "white" }}
          size="small"
        >
          {/* {vendorTypes(type).map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))} */}

          {[
            "All",
            "JK FASHION",
            "BINITA CREATION",
            "T2B APPAREL",
            "MISS CLOG",
          ].map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    </div>
  );
};

export default Filter;
