import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  InputAdornment,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import MuiTextField from "@mui/material/TextField";
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { getSizesByType } from "../../../utils/helperArrays";
import { useDispatch } from "react-redux";
import {
  changePOList,
  changeProcessedQuantity,
  changeProcessedQuantityAsync,
} from "../../../actions/POActions";

//custom textfield
const ProcessingTextField = styled(MuiTextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    paddingLeft: 0,
  },
  "& .MuiInputAdornment-root": {
    backgroundColor: "lightblue",
    padding: "28px 14px",
    borderTopLeftRadius: "3px",
    borderBottomLeftRadius: "3px",
  },
}));
// switch label
const label = { inputProps: { "aria-label": "Switch demo" } };

//show image switch
const ShowImageSwitch = ({ showImage, setShowImage }) => (
  <div>
    <FormControlLabel
      labelPlacement="start"
      label="Show image"
      control={
        <Switch
          {...label}
          checked={showImage}
          onChange={(e) => setShowImage(e.target.checked)}
        />
      }
    />
    {/* <Switch  /> */}
  </div>
);
//show POID switch
const ShowPOIDSwitch = ({ showPOID, setShowPOID }) => (
  <div>
    <FormControlLabel
      labelPlacement="start"
      label="Show POID"
      control={
        <Switch
          {...label}
          checked={showPOID}
          onChange={(e) => setShowPOID(e.target.checked)}
        />
      }
    />
  </div>
);
// table body row
const Row = ({ product, type, showImage, showPOID }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [processingQnty, setProcessingQnty] = useState(
    getSizesByType(type).reduce((acc, s) => {
      acc[s] = 0;
      return acc;
    }, {})
  );
  const dispatch = useDispatch();
  const handleClose = () => setOpen(false);
  const handleCompletePartial = () => {
    console.log("completing");
    setOpen(true);
  };
  const handleCompleteAll = async () => {
    console.log("completing all");
    setLoading(true);
    const processedQuantity = product.processedQuantity;
    const newProcessedQuantity = Object.keys(processedQuantity).reduce(
      (acc, s) => {
        const totalProcessed = processedQuantity[s].processed;
        acc[s] = {
          processed: 0,
          pending: processedQuantity[s].pending,
          completed: processedQuantity[s].completed + totalProcessed,
        };
        return acc;
      },
      {}
    );
    const data = await changeProcessedQuantity(
      product.pk,
      product.sk,
      newProcessedQuantity
    );
    console.log("data", data);
    if (data.status === 200) {
      setOpen(false);
      setLoading(false);
      dispatch(changePOList(product.pk, product.sk, newProcessedQuantity));
    } else {
      setLoading(false);
    }
  };
  const handleEnter = async () => {
    console.log("entering", processingQnty, product.processedQuantity);
    setLoading(true);
    const processedQuantity = product.processedQuantity;
    const newProcessedQuantity = Object.keys(processedQuantity).reduce(
      (acc, s) => {
        acc[s] = {
          processed: processedQuantity[s].processed - processingQnty[s],
          pending: processedQuantity[s].pending,
          completed: processedQuantity[s].completed + processingQnty[s],
        };
        return acc;
      },
      {}
    );
    console.log("newProcessedQnty", newProcessedQuantity);
    const data = await changeProcessedQuantity(
      product.pk,
      product.sk,
      newProcessedQuantity
    );
    console.log("data", data);
    if (data.status === 200) {
      setOpen(false);
      setLoading(false);
      dispatch(changePOList(product.pk, product.sk, newProcessedQuantity));
    } else {
      setLoading(false);
    }
    setLoading(false);
  };
  return (
    <React.Fragment>
      <TableRow>
        {showImage && (
          <TableCell>
            <img
              src={product.src}
              alt=""
              style={{
                width: 100,
                height: 100,
                objectFit: "contain",
              }}
            />
          </TableCell>
        )}
        <TableCell>{product.sk}</TableCell>
        <TableCell>
          {product.material_list.map((m) => (
            <div>{`${m.type} ${m.color}`}</div>
          ))}
        </TableCell>
        {getSizesByType(type).map((s) => (
          <TableCell>{product.processedQuantity[s]?.processed}</TableCell>
        ))}
        <TableCell>{product.total_po_quantity}</TableCell>
        <TableCell>
          {product.material_list.map((m) => (
            <div>{m.amount}</div>
          ))}
        </TableCell>
        <TableCell>
          {product.material_list.map((m) => (
            <div>{m.totalMaterialAmt}</div>
          ))}
        </TableCell>
        <TableCell>
          <Stack gap={1}>
            <Button variant="outlined" onClick={handleCompletePartial}>
              Complete Partial
            </Button>
            <Button variant="contained" onClick={handleCompleteAll}>
              Complete All
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
      {/*dialog for processing po  */}
      <Dialog onClose={handleClose} open={open} maxWidth="70%">
        <DialogTitle>Enter amount</DialogTitle>
        <DialogContent sx={{ margin: "auto", maxWidth: "fit-content" }}>
          <Stack direction={"row"} gap={3}>
            {Object.keys(product.processedQuantity).map((s) => (
              <ProcessingTextField
                sx={{ width: "150px" }}
                variant="outlined"
                value={processingQnty[s]}
                onChange={(e) => {
                  if (isNaN(parseInt(e.target.value))) {
                    setProcessingQnty((prev) => ({ ...prev, [s]: 0 }));
                    return;
                  }
                  if (
                    parseInt(e.target.value) >
                    product.processedQuantity[s].processed
                  ) {
                    return;
                  } else {
                    setProcessingQnty((prev) => ({
                      ...prev,
                      [s]: parseInt(e.target.value),
                    }));
                    return;
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <h3
                        style={{
                          textAlign: "center",
                          color: "black",
                        }}
                      >
                        {s}
                      </h3>
                    </InputAdornment>
                  ),
                }}
              />
            ))}
          </Stack>
          {loading ? (
            <Box sx={{ marginTop: "10px", float: "right" }}>
              <CircularProgress />
            </Box>
          ) : (
            <Button
              variant="contained"
              sx={{ marginTop: "10px", float: "right" }}
              onClick={handleEnter}
            >
              Enter
            </Button>
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

const HeaderRow = ({ data: td, type, showImage, showPOID }) => {
  return (
    <React.Fragment>
      <TableRow sx={{ backgroundColor: "#ffa7a7" }}>
        <TableCell colSpan={7 + getSizesByType(type).length} align="center">
          <h3>
            Date:{" "}
            <span style={{ color: "red", marginRight: "10px" }}>{td.date}</span>{" "}
            Vendor:<span style={{ color: "red" }}>{td.vendor}</span>
          </h3>
        </TableCell>
      </TableRow>
      <TableRow>
        {showImage && <TableCell>Img</TableCell>}
        <TableCell>SKU</TableCell>
        <TableCell>Material list</TableCell>
        {getSizesByType(type).map((s) => (
          <TableCell>{s}</TableCell>
        ))}
        <TableCell>Total Quantity</TableCell>
        <TableCell>Amount</TableCell>
        <TableCell>Total Material Amt</TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const DatewiseTable = ({ data, type }) => {
  const [tableData, setTableData] = useState([]);
  const [showImage, setShowImage] = useState(true);
  const [showPOID, setShowPOID] = useState(true);

  useEffect(() => {
    //filter non processed products
    const filteredNonProcessed = data.filter(
      (d) =>
        Object.values(d.processedQuantity).reduce(
          (acc, v) => acc + v.processed,
          0
        ) > 0
    );
    const dateVendorwise = filteredNonProcessed.reduce((acc, row) => {
      const found = acc.find(
        (ac) =>
          ac.date === new Date(row.created_at).toLocaleDateString("en-us") &&
          ac.vendor === row.vendor
      );
      if (found) {
        found.data = [...found.data, row];
      } else {
        const newObj = {
          date: new Date(row.created_at).toLocaleDateString("en-us"),
          vendor: row.vendor,
          data: [row],
        };
        acc.push(newObj);
      }
      return acc;
    }, []);
    console.log("date wise", dateVendorwise);
    const POIdWise = dateVendorwise.map((dw) => ({
      date: dw.date,
      vendor: dw.vendor,
      data: dw.data.reduce((acc, row) => {
        const found = acc.find(
          (ac) => ac.id === String(row.pk).replace("PO#", "")
        );
        if (found) {
          found.products = [...found.products, row];
        } else {
          const newObj = {
            id: String(row.pk).replace("PO#", ""),
            products: [row],
          };
          acc.push(newObj);
        }
        return acc;
      }, []),
    }));
    console.log("po wise", POIdWise);
    setTableData(POIdWise);
  }, [data]);
  console.log("table data", tableData, getSizesByType(type));
  return (
    <div>
      {/* switches */}
      <Stack direction={"row"}>
        <ShowPOIDSwitch showPOID={showPOID} setShowPOID={setShowPOID} />
        <ShowImageSwitch showImage={showImage} setShowImage={setShowImage} />
      </Stack>

      {tableData.map((td, i) => (
        <TableContainer component={Paper} sx={{ mb: "5px" }} key={i}>
          <Table>
            <TableHead>
              <HeaderRow
                data={td}
                type={type}
                showImage={showImage}
                showPOID={showPOID}
              />
            </TableHead>
            <TableBody>
              {td.data.map((data, i) => (
                <React.Fragment>
                  {showPOID && (
                    <TableCell
                      colSpan={7 + getSizesByType(type).length}
                      align="center"
                    >
                      <h3>POID : {data.id}</h3>
                    </TableCell>
                  )}
                  {data.products.map((product) => (
                    <Row
                      product={product}
                      type={type}
                      key={product.sku}
                      showImage={showImage}
                      showPOID={showPOID}
                    />
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ))}
    </div>
  );
};

export default DatewiseTable;
