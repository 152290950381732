import React, { useState } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";

import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
  Modal,
  Box,
  Chip,
  TableFooter,
  TablePagination,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import TicketBox from "../ticketWidgets/TicketBox";
import { useDispatch, useSelector } from "react-redux";
import {
  changePriority,
  getSingleTicket,
  openTicket,
} from "../../actions/ticketActions";
import { useEffect } from "react";
import { removeCustomerDetails } from "../../actions/customerActions";
import TicketSections from "../ticketWidgets/TicketSections";
const style = {
  position: "absolute",
  width: "100%",
  height: "100%",
  overflow: "scroll",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "99vw",
  // heigt: "99vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const priorityOptions = ["None", "Low", "Medium", "High"];

function Row(props) {
  const { row, setOpen, priorityLoading } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const openPriorityMenu = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handlePriorityMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handlePriorityMenuClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleOpen = (row) => {
    // setTicket(row);
    dispatch(getSingleTicket(row));
    if (row.package_status === "new") {
      dispatch(
        openTicket(
          row.original_order_id,
          row.fulfillment_id,
          row.customer_id,
          row.executive_id
        )
      );
    }
    setOpen(true);
  };

  const handleChangePriority = (ticketId, value, id) => {
    dispatch(changePriority(ticketId, priorityOptions[value]));
  };

  return (
    <React.Fragment>
      <TableRow
        key={row.id}
        onClick={(e) => {
          if (!e.target.className.includes("prioritydiv")) {
            handleOpen(row);
          } else return;
        }}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "lightblue",
          },
        }}
      >
        <TableCell>{row.original_order_id || row.fulfillment_id}</TableCell>

        <TableCell>{row.updated_at}</TableCell>
        <TableCell>{String(row.ticketAge)}</TableCell>
        <TableCell>{row.category}</TableCell>
        <TableCell>{row.question}</TableCell>
        <TableCell>
          {row.order_id}
          {row.payment ? `(${row.payment})` : ""}
        </TableCell>
        <TableCell>
          {priorityLoading ? (
            <CircularProgress size={30} />
          ) : (
            <div>
              <Chip
                className="prioritydiv"
                onClick={(e) => handlePriorityMenuClick(e)}
                label={
                  "priority" in row && row.priority ? row.priority : "None"
                }
                sx={{
                  bgcolor:
                    row.priority === "Low"
                      ? "#008000"
                      : row.priority === "Medium"
                      ? "#FFB266"
                      : row.priority === "High"
                      ? "#FF0000"
                      : "#AAA9AD",
                  color: "white",
                }}
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openPriorityMenu}
                onClose={(e) => {
                  handlePriorityMenuClose(e);
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {priorityOptions.map((p, index) => (
                  <MenuItem
                    onClick={(e) => {
                      handleChangePriority(
                        row.fulfillment_id,
                        index,
                        row.original_order_id
                      );
                      handlePriorityMenuClose(e);
                    }}
                  >
                    {p}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          )}
        </TableCell>
        <TableCell>
          {"executive_id" in row ? row.executive_id : "Not Assigned"}
        </TableCell>
        <TableCell>
          {"lastSendedBy" in row ? (
            <Chip
              label={row.lastSendedBy}
              sx={{ bgcolor: "#6495ED", color: "white" }}
            />
          ) : (
            ""
          )}
        </TableCell>
        <TableCell>{String(row.messageCount)}</TableCell>
        <TableCell>
          <Chip
            label={row.package_status}
            color={
              row.package_status === "new"
                ? "primary"
                : row.package_status === "open"
                ? "success"
                : "error"
            }
          />
        </TableCell>
        <TableCell>{row.email || "not provided"}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const AllQueryFilterTable = ({
  data,
  emailList,
  isFilteredEmpty,
  resetPage,
  setResetPage,
  setTicketSection,
}) => {
  const [open, setOpen] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [ticket, setTicket] = useState({});
  const { singleTicket, priorityLoading } = useSelector(
    (state) => state.tickets
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const dispatch = useDispatch();
  useEffect(() => {
    setPage(0);
  }, [resetPage]);

  useEffect(() => {
    if (!Object.keys(singleTicket).length > 0) return;
    // const differenceTime =
    //   new Date().getTime(singleTicket.created_at) -
    //   new Date(singleTicket.created_at).getTime();
    // const differentDays = Math.round(differenceTime / (1000 * 3600 * 24));
    // const timeFormatted = {
    //   ...singleTicket,
    //   created_at: new Date(singleTicket.created_at).toLocaleString(),
    //   updated_at: new Date(singleTicket.updated_at).toLocaleString(),
    //   ticketAge: differentDays,
    // };
    console.log("single ticket", singleTicket);
    setTicket(singleTicket);
  }, [singleTicket]);

  const handleClose = () => {
    // setTicket({});

    dispatch(getSingleTicket({}));
    setTicket({});
    dispatch(removeCustomerDetails());
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const excelHeaders = [
      { label: "Case Number", key: "fulfillment_id" },
      { label: "Customer Id", key: "customer_id" },
      { label: "Created At", key: "created_at" },
      { label: "Last Updated At", key: "updated_at" },
      { label: "Question", key: "question" },
      { label: "Category", key: "category" },
      { label: "Description", key: "description" },
      { label: "Order Id", key: "order_id" },
      { label: "Email", key: "email" },
      { label: "Case Status", key: "package_status" },
      { label: "Ticket Age", key: "ticketAge" },
      { label: "Number Of Messages", key: "messageCount" },
      { label: "Handled By", key: "executive_id" },
      { label: "Closed By", key: "closed_by" },
    ];
    const excelFormatted = data.map((t) => ({
      fulfillment_id: t.original_order_id,
      customer_id: t.customer_id,
      created_at: t.created_at,
      updated_at: t.updated_at,
      question: t.question,
      category: t.category,
      description: t.description,
      order_id: t.order_id,
      email: t.email,
      package_status: t.package_status,
      ticketAge: t.ticketAge,
      messageCount: t.messageCount,
      executive_id: t.executive_id,
      closed_by: t.closed_by,
    }));
    setHeaders(excelHeaders);
    setExcelData(excelFormatted);
  }, [data]);
  return (
    <div>
      <TicketSections
        setTicketSection={setTicketSection}
        setResetPage={setResetPage}
      />
      <TableContainer component={Paper}>
        <Table aria-label="support table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Case No.</TableCell>

              <TableCell>Updated At</TableCell>
              <TableCell>Ticket Age (days)</TableCell>
              <TableCell align="left">Category</TableCell>
              <TableCell>Question</TableCell>
              <TableCell>Order</TableCell>
              <TableCell>Priority</TableCell>
              <TableCell>Assigned To</TableCell>

              <TableCell>Last Sended By</TableCell>
              <TableCell>Message Count</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Email Address</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFilteredEmpty && (
              <h2 style={{ textAlign: "center", mx: 2, my: 2 }}>
                No Tickets Found
              </h2>
            )}
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row) => (
              <Row
                row={row}
                setOpen={setOpen}
                priorityLoading={priorityLoading}
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{ overflow: "inherit" }}
                rowsPerPageOptions={[5, 10, 25, 100]}
                colSpan={3}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>

        <CSVLink data={excelData} headers={headers} filename="Tickets">
          <Button variant="contained" sx={{ float: "right", margin: "5px" }}>
            Download Table
          </Button>
        </CSVLink>
      </TableContainer>
      {Object.keys(ticket).length > 0 ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <TicketBox
              handleClose={handleClose}
              ticket={ticket}
              emailList={emailList}
            />
          </Box>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
};
export default AllQueryFilterTable;
