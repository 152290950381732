import React from "react";
import TopicIcon from "@mui/icons-material/Topic";
import { Stack } from "@mui/material";
const Folder = ({ data, setChoseFolder }) => {
  return (
    <div>
      <Stack direction={"row"} gap={2}>
        {data.slice(-8).map((d, i) => (
          <Stack
            onClick={(e) => setChoseFolder(d)}
            key={i}
            direction={"column"}
            sx={{
              alignItems: "center",
              "& :hover": {
                cursor: "pointer",
              },
            }}
          >
            <TopicIcon />
            <h4>{d.split("_")[0]}</h4>
            <p>{d.split("_")[1]}</p>
          </Stack>
        ))}
      </Stack>
    </div>
  );
};

export default Folder;
