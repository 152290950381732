import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  productTypes,
  getSizesByType,
  categoryTypes,
  vendorTypes,
  materialFabricArray,
  materialColorArray,
  findMaterialId,
} from "../../utils/helperArrays";
import { postNewProducts } from "../../actions/productsActions";
import ProductModal from "./ProductModal";
import ProgressBar from "../../ProgressBar";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  TextField,
  MenuItem,
  Chip,
  Button,
  Container,
  Paper,
  Box,
  Collapse,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Typography,
  FormControlLabel,
  Stack,
} from "@mui/material";

const AddInventoryProductForm = ({ setSelected }) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.productDetails.loading);
  const responseBody = useSelector(
    (state) => state.productDetails.responseBody
  );
  const { stock } = useSelector((state) => state.inventoryDetails);
  // console.log(stock)

  const [productType, setProductType] = useState("");
  const [categoriesArray, setCategoriesArray] = useState([]);
  const [categoryType, setCategoryType] = useState("");
  const [vendorsArray, setVendorsArray] = useState([]);
  const [vendorType, setVendorType] = useState("");
  const [sku, setSku] = useState("");
  const [costPrice, setCostPrice] = useState("");
  const [barcodes, setBarcodes] = useState([]);
  const [newProductData, setNewProductData] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [url, setUrl] = useState("");
  const [errorResponse, setErrorResponse] = useState(false);

  const [materialType, setMaterialType] = useState("");
  const [materialColor, setMaterialColor] = useState("");
  const [materialAmount, setMaterialAmount] = useState("");
  const [materialObj, setMaterialObj] = useState([]);
  const [openAddMaterial, setOpenAddMaterial] = useState(false);
  const [isCombo, setIsCombo] = useState(false);
  const [comboAmount, setComboAmount] = useState(2);

  useEffect(() => {
    if (productType) {
      const categories = categoryTypes(productType);
      const vendors = vendorTypes(productType);
      setCategoriesArray(categories);
      setVendorsArray(vendors);
    }
  }, [productType]);

  useEffect(() => {
    if (sku && productType) {
      const sizes = getSizesByType(productType);
      const generatedBarcodes = sizes.map((i) => {
        if (i === "Free Size (Fits XS to L)") {
          const barcodeGenerated = "LB" + sku + "FS";
          return barcodeGenerated;
        } else {
          const barcodeGenerated = "LB" + sku + i;
          return barcodeGenerated;
        }
      });
      setBarcodes(generatedBarcodes);
    }
  }, [productType, sku]);

  useEffect(() => {
    if (responseBody.statusCode === 200) setModalData(newProductData);
  }, [responseBody]);

  useEffect(() => {
    if (sku !== "") {
      const filterSku = stock.filter((x) => x.SKU === sku);
      if (filterSku.length > 0) {
        setErrorResponse(true);
      } else {
        setErrorResponse(false);
      }
    }
  }, [sku]);

  const handleModalClose = () => {
    setProductType("");
    setCategoryType("");
    setSku("");
    setCostPrice("");
    setBarcodes([]);
    setVendorsArray([]);
    setVendorType("");
    setUrl("");
    setMaterialType("");
    setMaterialColor("");
    setMaterialAmount("");
    setMaterialObj([]);
    setOpenAddMaterial(false);
    setIsCombo(false);
    setComboAmount(2);
  };

  const handleOnlyInventorySubmit = async () => {
    const sizes = getSizesByType(productType);

    const newProduct = sizes.reduce((acc, i) => {
      const product = {
        company: "LB",
        SKU: sku,
        date: new Date(),
        count: 0,
        category: categoryType,
        type: productType,
        barcode:
          i === "Free Size (Fits XS to L)" ? "LB" + sku + "FS" : "LB" + sku + i,
        vendorType,
        imageUrl: url,
        costPrice: parseInt(costPrice),
        size: i,
        material: materialObj,
        combo: isCombo,
        comboAmount: isCombo ? comboAmount : undefined,
      };
      acc.push(product);
      return acc;
    }, []);

    setNewProductData(newProduct);
    console.log(newProduct);
    dispatch(postNewProducts(newProduct));
  };

  const handleMaterialObj = () => {
    const newObj = {
      type: materialType,
      color: materialColor,
      amount: materialAmount,
      name: String(materialType) + " " + String(materialColor),
      id: findMaterialId(materialType, materialColor),
    };
    setMaterialObj((prev) => [...prev, newObj]);
    setMaterialType("");
    setMaterialColor("");
    setMaterialAmount("");
    setOpenAddMaterial(false);
  };

  return (
    <div>
      {!loading ? (
        <>
          <Button
            variant="contained"
            color="error"
            onClick={() => setSelected("")}
          >
            Back
          </Button>
          <Container>
            <br />
            <br />
            <Paper sx={{ padding: "25px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  style={{ width: "500px" }}
                  select
                  label="Select Product Type"
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                  variant="outlined"
                  className="select"
                  // fullWidth
                >
                  {productTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              {productType && (
                <>
                  <br />
                  <div>
                    <TextField
                      style={{ width: "500px" }}
                      select
                      label="Select Product Category"
                      value={categoryType}
                      onChange={(e) => {
                        setCategoryType(e.target.value);
                      }}
                      variant="outlined"
                      className="select"
                    >
                      {categoriesArray.map((option) => (
                        <MenuItem key={option.value} value={option.label}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <br />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <TextField
                      label="SKU"
                      value={sku}
                      onChange={(e) => setSku(e.target.value)}
                      variant="outlined"
                      className="select"
                      sx={{ width: "500px" }}
                    />
                    <div>
                      {errorResponse ? (
                        <h4 style={{ color: "red" }}>
                          SKU "<b style={{ color: "black" }}>{sku}</b>" Already
                          Exists. Please Enter Another SKU
                        </h4>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <br />
                  <div style={{ display: "flex" }}>
                    <TextField
                      style={{ width: "300px" }}
                      select
                      label="Select Vendor"
                      value={vendorType}
                      onChange={(e) => {
                        setVendorType(e.target.value);
                      }}
                      variant="outlined"
                      className="select"
                    >
                      {vendorsArray.map((option) => (
                        <MenuItem key={option.value} value={option.label}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      style={{ left: "100px" }}
                      label="Cost Price"
                      value={costPrice}
                      onChange={(e) => setCostPrice(e.target.value)}
                      variant="outlined"
                      className="select"
                    />
                  </div>
                  <br />
                  <div>
                    <TextField
                      style={{ width: "500px" }}
                      label="Image URL"
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                      variant="outlined"
                      className="select"
                    />
                  </div>
                  <br />
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <h4>Add Material Details</h4>
                    <Box style={{ paddingLeft: "10px" }}>
                      <AddIcon
                        onClick={() => setOpenAddMaterial((prev) => !prev)}
                        fontSize="large"
                        style={{
                          color: "white",
                          background: "black",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  </Box>
                  <Collapse in={true}>
                    <Paper sx={{ bgcolor: "whitesmoke" }}>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          padding: "20px",
                        }}
                      >
                        <TextField
                          style={{ width: "250px" }}
                          select
                          label="Select Product Material"
                          value={materialType}
                          onChange={(e) => setMaterialType(e.target.value)}
                          variant="outlined"
                          className="select"
                        >
                          {materialFabricArray.map((option) => (
                            <MenuItem key={option.code} value={option.name}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          style={{ width: "250px" }}
                          select
                          label="Select Material Color"
                          value={materialColor}
                          onChange={(e) => setMaterialColor(e.target.value)}
                          variant="outlined"
                          className="select"
                        >
                          {materialColorArray.map((option) => (
                            <MenuItem key={option.code} value={option.name}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          style={{ width: "150px" }}
                          label="Material Amount"
                          value={materialAmount}
                          onChange={(e) => setMaterialAmount(e.target.value)}
                          variant="outlined"
                          className="select"
                        />
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleMaterialObj}
                        >
                          Add
                        </Button>
                      </Box>
                    </Paper>
                  </Collapse>
                  {materialObj.length > 0 ? (
                    <>
                      <br />
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">Type</TableCell>
                              <TableCell align="left">Color</TableCell>
                              <TableCell align="left">Amount</TableCell>
                              <TableCell align="left">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {materialObj.map((row, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">{row.type}</TableCell>
                                <TableCell align="left">{row.color}</TableCell>
                                <TableCell align="left">{row.amount}</TableCell>
                                <TableCell align="left">
                                  <DeleteIcon
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => {
                                      setMaterialObj(() => {
                                        const toBeDeleted = materialObj.find(
                                          (x) => x.color === row.color
                                        );
                                        if (toBeDeleted)
                                          return materialObj.filter(
                                            (x) => x !== toBeDeleted
                                          );
                                        else return materialObj;
                                      });
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ) : (
                    ""
                  )}
                  <h3>Barcodes Generated : </h3>
                  <div style={{ display: "flex" }}>
                    {barcodes.map((i) => (
                      <Chip
                        key={i}
                        variant="filled"
                        label={i}
                        style={{ margin: "10px", color: "blue" }}
                      />
                    ))}
                  </div>
                  <br />
                  <Stack direction={"row"} gap={2}>
                    <FormControlLabel
                      sx={{
                        border: "1px solid black",
                        borderRadius: 2,
                        py: 0.8,
                        px: 2,
                        mx: 0,
                      }}
                      label={
                        <Typography variant="h6" color="textSecondary">
                          Combo ?
                        </Typography>
                      }
                      control={
                        <Checkbox
                          value={isCombo}
                          onChange={(e) => setIsCombo(e.target.checked)}
                        />
                      }
                    />
                    {isCombo && (
                      <TextField
                        type="number"
                        style={{ width: "200px" }}
                        value={comboAmount}
                        onChange={(e) => setComboAmount(e.target.value)}
                        variant="outlined"
                        label="Combo Amount"
                      />
                    )}
                  </Stack>
                  <br />
                  <Button
                    disabled={errorResponse}
                    variant="contained"
                    color="secondary"
                    onClick={handleOnlyInventorySubmit}
                  >
                    ADD TO INVENTORY
                  </Button>
                </>
              )}
            </Paper>
          </Container>
        </>
      ) : (
        <ProgressBar />
      )}
      <>
        {modalData.length > 0 && (
          <ProductModal data={modalData} handleModalClose={handleModalClose} />
        )}
      </>
    </div>
  );
};

export default AddInventoryProductForm;
