import React from "react";
import PropTypes from "prop-types";
import { TableSkeletonComponent } from "../dashboard/helperComponents";
import ReverseActionTaken from "./ReverseActionTaken";
import { Box, Tab, Tabs } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ReverseInTransitFilter = ({ tableData }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  console.log("table", tableData);
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Exchange" {...a11yProps(0)} />
          <Tab label="Voucher" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {tableData.length > 0 ? (
          <ReverseActionTaken
            tableData={tableData
              .filter(
                (x) =>
                  (x.exchangeAction === "Replacement" ||
                    x.exchangeAction === "Replacement&Giftcard") &&
                  !!x.new_exchangeOrder_created
              )
              .sort((a, b) => new Date(a.sync_date) - new Date(b.sync_date))
              .reverse()}
          />
        ) : (
          <TableSkeletonComponent />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {tableData.length > 0 ? (
          <ReverseActionTaken
            tableData={tableData
              .filter(
                (x) =>
                  (x.exchangeAction === "Giftcard" ||
                    x.exchangeAction === "Replacement&Giftcard") &&
                  !!x.new_voucher_created
              )
              .sort((a, b) => new Date(a.sync_date) - new Date(b.sync_date))
              .reverse()}
          />
        ) : (
          <TableSkeletonComponent />
        )}
      </TabPanel>
    </>
  );
};

export default ReverseInTransitFilter;
