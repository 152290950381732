import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { TableSkeletonComponent } from "../dashboard/helperComponents";
import { Box, Tab, Tabs } from "@mui/material";
import ReverseInTransitOrdersTable from "./ReverseInTransitOrdersTable";
import { useDispatch, useSelector } from "react-redux";
import {
    allShopifyInventory,
    getReturningProductsData,
} from "../../actions/ordersActions";

import { allProducts } from "../../actions/productsActions";

import { allInventory } from "../../actions/inventoryActions";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const ReverseInTransitFilter1 = ({ setSearchValue }) => {
    const [value, setValue] = React.useState(0);

    const { filteredOrdersReverse } = useSelector(
        (state) => state.reverseDetails
    );
    const auth = useSelector((state) => state.auth);
    const { returningProductsData } = useSelector(
        (state) => state.orderDetails
    );
    const products = useSelector((state) => state.productDetails.products);
    const inventoryStock = useSelector((state) => state.inventoryDetails.stock);
    const shopifyInventory = useSelector(
        (state) => state.orderDetails.shopifyInventory
    );
    const dispatch = useDispatch();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    useEffect(() => {
        if (!returningProductsData) dispatch(getReturningProductsData());
        if (!products.length > 0) dispatch(allProducts());
        if (!inventoryStock.length > 0) dispatch(allInventory());
        if (!shopifyInventory.length > 0) dispatch(allShopifyInventory());
    }, []);

    if (filteredOrdersReverse.length === 0) {
        return <TableSkeletonComponent />;
    } else {
        return (
            <>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab
                            label="Exchange"
                            {...a11yProps(0)}
                            onClick={() => setSearchValue("")}
                        />
                        <Tab
                            label="Voucher"
                            {...a11yProps(1)}
                            onClick={() => setSearchValue("")}
                        />
                        <Tab
                            label="Drafts"
                            {...a11yProps(2)}
                            onClick={() => setSearchValue("")}
                        />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    {filteredOrdersReverse.length > 0 ? (
                        <ReverseInTransitOrdersTable
                            tableData={filteredOrdersReverse
                                .filter(
                                    (x) =>
                                        x.exchangeAction === "Replacement" &&
                                        !x.tags.includes("replacement") &&
                                        (!x.new_exchangeOrder_created ||
                                            ("exchange_products" in x &&
                                                x.products.length !==
                                                    x.exchange_products.length))
                                )
                                .sort(
                                    (a, b) =>
                                        new Date(a.sync_date) -
                                        new Date(b.sync_date)
                                )
                                .reverse()}
                            setSearchValue={setSearchValue}
                        />
                    ) : (
                        <TableSkeletonComponent />
                    )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {filteredOrdersReverse.length > 0 ? (
                        <ReverseInTransitOrdersTable
                            tableData={filteredOrdersReverse
                                .filter(
                                    (x) =>
                                        (x.exchangeAction === "Giftcard" ||
                                            x.exchangeAction ===
                                                "Replacement&Giftcard") &&
                                        !x.new_voucher_created
                                )
                                .sort(
                                    (a, b) =>
                                        new Date(a.sync_date) -
                                        new Date(b.sync_date)
                                )
                                .reverse()}
                            setSearchValue={setSearchValue}
                        />
                    ) : (
                        <TableSkeletonComponent />
                    )}
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {filteredOrdersReverse.length > 0 ? (
                        <ReverseInTransitOrdersTable
                            tableData={filteredOrdersReverse
                                .filter(
                                    (x) =>
                                        x.exchangeAction === "Replacement" &&
                                        x.tags.includes("replacement") &&
                                        !x.new_exchangeOrder_created
                                )
                                .sort(
                                    (a, b) =>
                                        new Date(a.sync_date) -
                                        new Date(b.sync_date)
                                )
                                .reverse()}
                            setSearchValue={setSearchValue}
                        />
                    ) : (
                        <TableSkeletonComponent />
                    )}
                </TabPanel>
            </>
        );
    }
};

export default ReverseInTransitFilter1;
