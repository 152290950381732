import { useState } from "react";
import { Box, Grid, TextField } from "@mui/material";

const DateSelector = ({ handleMinChange, handleMaxChange }) => {
  const [startDateFocus, setStartDateFocus] = useState(false);
  const [endDateFocus, setEndDateFocus] = useState(false);
  const [hasStartDate, setHasStartDate] = useState(false);
  const [hasEndDate, setHasEndDate] = useState(false);

  return (
    <Grid item md={4}>
      <Box sx={{ display: "flex", gap: 2 }}>
        <TextField
          label="Start Date"
          onFocus={() => setStartDateFocus(true)}
          onBlur={() => setStartDateFocus(false)}
          InputProps={{
            classes: {
              input: "CustomTextField",
            },
          }}
          type={hasStartDate || startDateFocus ? "date" : "text"}
          size="small"
          onChange={(e) => {
            if (e.target.value) {
              handleMinChange(e.target.value);
              setHasStartDate(true);
            } else {
              setHasStartDate(false);
            }
          }}
          sx={{bgcolor:"white"}}
        />
        <TextField
          label="End Date"
          onFocus={() => setEndDateFocus(true)}
          onBlur={() => setEndDateFocus(false)}
          type={hasEndDate || endDateFocus ? "date" : "text"}
          size="small"
          InputProps={{ max: Date.now() }}
          onChange={(e) => {
            if (e.target.value) {
              handleMaxChange(e.target.value);
              setHasEndDate(true);
            } else {
              setHasEndDate(false);
            }
          }}
          sx={{bgcolor:"white"}}
        />
      </Box>
    </Grid>
  );
};

export default DateSelector;
