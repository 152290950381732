import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ViewAllOrdersTable from "./ViewAllOrdersTable";
import {
  Box,
  Collapse,
  IconButton,
  Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Typography,Paper,TableFooter,TablePagination,
  Button,
  Chip,
  Card,
  CardContent,
} from "@mui/material";

export default function ViewAllOrdersFilter({tableData}) {
  const [viewTableData, setViewTableData] = useState(
    tableData.sort((a, b) => Number(a.order) - Number(b.order))
  );
  const [paidOrders, setPaidOrders] = useState(0)
  const [pendingOrders, setPendingOrders] = useState(0)
  const [hypd, setHypd] = useState(0)
  const [zaamo, setZaamo] = useState(0)
  const [shopify, setShopify] = useState(0)
  const [wishlink, setWishlink] = useState(0)

  useEffect(() => {
    // setViewTableData(tableData)

    const prepaidOrders = tableData.filter(o => o.financial_status === 'paid')
    const totalPrepaid = prepaidOrders.length > 0 ? prepaidOrders.length : 0

    const codOrders = tableData.filter(o => o.financial_status === 'pending')
    const totalCod = codOrders.length > 0 ? codOrders.length : 0

    const hypdOrders = tableData.filter(o => o.tags.toLowerCase().includes("hypd"))
    const totalHypd = hypdOrders.length > 0 ? hypdOrders.length : 0

    const zaamoOrders = tableData.filter(o => o.tags.toLowerCase().includes("zaamo"))
    const totalZaamo = zaamoOrders.length > 0 ? zaamoOrders.length : 0

    const shopifyOrders = tableData.filter(o => o.tags === "" || o.tags === "adp_order")
    const totalShopify = shopifyOrders.length > 0 ? shopifyOrders.length : 0

    const wishLinkOrders = tableData.filter(o => o.tags.toLowerCase().includes("wishlink"))
    const totalWishLink = wishLinkOrders.length > 0 ? wishLinkOrders.length : 0

    setPaidOrders(totalPrepaid)
    setPendingOrders(totalCod)
    setHypd(totalHypd)
    setZaamo(totalZaamo)
    setShopify(totalShopify)
    setWishlink(totalWishLink)
  },[tableData])

  useEffect(() => {
    if (tableData.length > 0) {
        const newOrderArray = tableData
            .map((item) => {
                const newOrder = {
                    order: item.order_number,
                    date: new Date(item.created_at).toLocaleDateString(),
                    name: item.billing_address?.name, //HYPD sent orders without billing address
                    total: item.current_total_price,
                    products: item.line_items,
                    payment: item.financial_status,
                    address: item.shipping_address,
                    status: item.fulfillment_status, //can be null too
                    tag : item.tags
                };
                return newOrder;
            })
            setViewTableData(newOrderArray);
    }
}, [tableData]);

  return (
    <>
      <br />
      <br />
      <Card style={{width:"500px"}}>
        <CardContent>
          <Typography variant="h4" component="div">
            Total Order Count : {tableData.length}
          </Typography>
          <Typography>
            Prepaid : {paidOrders}
          </Typography>
          <Typography>
            COD : {pendingOrders}
          </Typography>
          <br/>
          <br/>
          <Typography variant="h6" component="div">
            Tagged Orders : 
          </Typography>
          <Typography>
            Shopify : {shopify}
          </Typography>
          <Typography>
            HYPD : {hypd}
          </Typography>
          <Typography>
            Zaamo : {zaamo}
          </Typography>
          <Typography>
            Wishlink : {wishlink}
          </Typography>
        </CardContent>
      </Card>
      {viewTableData.length>0 && (
        <ViewAllOrdersTable viewTableData={viewTableData} />
      )}
    </>
  );
}
