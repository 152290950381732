import { Button, CircularProgress, Stack, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { getSingleReverseOrder } from "../../actions/reverseDeliveryActions";
import ShiprocketReturnForm from "../reverse/ShiprocketReturnForm";
import { useDispatch, useSelector } from "react-redux";
import { allInventory } from "../../actions/inventoryActions";
import { allShopifyInventory } from "../../actions/ordersActions";
import { allProducts } from "../../actions/productsActions";

const ShiprocketReverse = () => {
  const [loading, setLoading] = useState(false);
  const [orderNumber, setOrderNumber] = useState("");
  const [orderDetail, setOrderDetail] = useState({});
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const products = useSelector((state) => state.productDetails.products);

  useEffect(() => {
    if (!products.length > 0 && auth) dispatch(allProducts());
  }, [dispatch, auth]);

  const handleClick = async (e) => {
    setLoading(true);
    const data = await getSingleReverseOrder(orderNumber);
    if (data.statusCode === 200) {
      setOrderDetail(data.data);
    }
    setLoading(false);
  };

  return (
    <div>
      {!products.length > 0 ? (
        <h1>Loading...</h1>
      ) : (
        <div>
          <Stack direction={"row"} gap={1}>
            <TextField
              placeholder="Enter Order Id"
              variant="outlined"
              onChange={(e) => setOrderNumber(e.target.value)}
            />
            <Button
              onClick={handleClick}
              variant="contained"
              disabled={loading}
            >
              {loading ? <CircularProgress /> : "Search"}
            </Button>
          </Stack>
          {Object.keys(orderDetail).length > 0 && (
            <ShiprocketReturnForm inventory={products} order={orderDetail} />
          )}
        </div>
      )}
    </div>
  );
};

export default ShiprocketReverse;
