import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import { getSizesByType } from "../../../utils/helperArrays";
import { getCustomerInformation } from "../../../actions/customerActions";
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function Row({ row, sizes, replacementUnfulfilledOrders }) {
  const [open, setOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [orderId, setOrderId] = useState("");
  const handleModalOpen = (id) => {
    setOrderId(String(id));
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setOrderId("");
    setModalOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const totalStock = Object.values(row.inventoryStock).reduce(
    (x, sum) => x + sum,
    0
  );

  let shopifyStock = {};
  if (row.stock[0].length > 0) {
    const mapped = row.stock[0].map((item) => ({
      [item.size]: item.stock,
    }));
    shopifyStock = Object.assign({}, ...mapped);
  }
  const totalShopifyInventory = row.stock[0].reduce(
    (sum, item) => item.stock + sum,
    0
  );

  const customerDetails = (orderId) => {
    if (orderId) {
      const customer = replacementUnfulfilledOrders.find(
        (o) => String(o.order_number) === String(orderId)
      ).customer;
      return (
        <div>
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "center",
            }}
          >
            <h2>#{orderId}</h2>
          </Stack>
          <h3>{`${customer.first_name} ${customer.last_name}`}</h3>
          <p>{customer.email ? customer.email : "No email provided"}</p>
          <p>
            {customer.phone
              ? customer.phone
              : customer.default_address.phone
              ? customer.default_address.phone
              : "No phone provided"}
          </p>
          <div>
            <h3>Default Address:</h3>
            <p>Phone :{customer.default_address.phone}</p>
            <p>{customer.default_address.address1}</p>
            <p>{customer.default_address.city}</p>
            <p>{customer.default_address.province}</p>
            <p>{customer.default_address.zip}</p>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <Avatar
            src={row.src}
            sx={{ width: 100, height: 100 }}
            variant="square"
          />
        </TableCell>
        <TableCell>{row.sku}</TableCell>
        <TableCell
          sx={{
            maxWidth: "300px",
          }}
        >
          {row.name}
        </TableCell>
        <TableCell>
          <div>
            {row.material.map((m) => (
              <p>{`${m.type}${m.place ? `(${m.place})` : ""} ${m.color} `}</p>
            ))}
          </div>
        </TableCell>
        <TableCell>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p style={{ background: "indianred", padding: "5px" }}>
              Unfulfilled
            </p>
            <p
              style={{
                background: "yellowgreen",
                padding: "5px",
              }}
            >
              Inventory Stock
            </p>
            <p style={{ background: "beige", padding: "5px" }}>
              Shopify Inventory
            </p>
          </div>
        </TableCell>
        {sizes.map((i, k) => (
          <TableCell key={k}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p
                style={{
                  background: "indianred",
                  padding: "10px 5px",
                }}
              >
                {row.sizes[i] ? row.sizes[i] : 0}
              </p>
              <p
                style={{
                  background: "yellowgreen",
                  padding: "10px 5px",
                }}
              >
                {row.inventoryStock[i] ? row.inventoryStock[i] : 0}
              </p>
              <p
                style={{
                  background: "beige",
                  padding: "10px 5px",
                }}
              >
                {shopifyStock[i] ? shopifyStock[i] : 0}
              </p>
            </div>
          </TableCell>
        ))}
        <TableCell>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p
              style={{
                background: "indianred",
                padding: "10px 5px",
              }}
            >
              {row.total}
            </p>

            <p
              style={{
                background: "yellowgreen",
                padding: "10px 5px",
              }}
            >
              {totalStock}
            </p>

            <p
              style={{
                background: "beige",
                padding: "10px 5px",
              }}
            >
              {totalShopifyInventory}
            </p>
          </div>
        </TableCell>
        <TableCell>{row.orderId.length} Order</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Stack
            direction={"column"}
            sx={{
              gap: 1,
              alignItems: "center",
            }}
          ></Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={17}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <h3>All Orders</h3>
              <Stack direction={"row"} sx={{ justifyContent: "space-around" }}>
                {sizes.map((s, i) => (
                  <React.Fragment key={i}>
                    <Stack
                      direction={"column"}
                      sx={{
                        alignItems: "center",
                        width: "100%",
                        margin: "0 8px",
                      }}
                    >
                      <Box
                        sx={{
                          background: "rgba(153,14,199,0.3)",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        <h3
                          style={{
                            fontSize: "25px",
                            margin: "2px 0",
                          }}
                        >
                          {s}
                        </h3>
                      </Box>
                      <Divider flexItem />
                      <Stack
                        direction={"row"}
                        sx={{
                          flexWrap: "wrap",
                          justifyContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        {(row.orderWithSizes[s] || []).map((o, i) => (
                          <Chip
                            key={i}
                            variant="filled"
                            onClick={() => handleModalOpen(o)}
                            sx={{
                              "& :hover": {
                                cursor: "pointer",
                              },
                            }}
                            label={o}
                            style={{
                              // width: "40%",
                              margin: "5px 1px",
                              marginLeft: "0",
                              fontSize: "0.8rem",
                              color: "limegreen",
                            }}
                          />
                        ))}
                      </Stack>
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                  </React.Fragment>
                ))}
              </Stack>
            </Box>
            <Modal
              open={modalOpen}
              onClose={handleModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>{customerDetails(orderId)}</Box>
            </Modal>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const ReportTable = ({
  productsRE,
  productType,
  productAge,
  stockSize,
  replacementUnfulfilledOrders,
}) => {
  const productImageList = useSelector(
    (state) => state.productDetails.imageList
  );
  const inventoryStock = useSelector((state) => state.inventoryDetails.stock);
  const shopifyInventory = useSelector(
    (state) => state.orderDetails.shopifyInventory
  );
  const products = useSelector((state) => state.productDetails.products);
  const productVariants = useSelector(
    (state) => state.productDetails.productVariants
  );
  const [tableData, setTableData] = useState([]);
  const [productVariantList, setProductVariantList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [desc, setDesc] = useState(true);
  const [sizes, setSizes] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    //Create the Tabledata
    if (
      productsRE.length > 0 &&
      productImageList.length > 0 &&
      inventoryStock.length > 0 &&
      productVariantList.length > 0
    ) {
      const data = productsRE.map((p) => {
        const variantObj = productVariantList.filter(
          (item) => item.sku === p.sku
        );
        const productId = variantObj[0]?.product_id;
        const imageObj = productImageList.find((item) => item.id === productId);
        const inventoryObj = inventoryStock.filter(
          (item) => item.SKU === p.sku
        );
        let productStock = {};
        if (inventoryObj.length > 0) {
          const mapped = inventoryObj.map((item) => ({
            [item.size]: item.count,
          }));
          productStock = Object.assign({}, ...mapped);
        }
        const material =
          inventoryObj.length > 0 && "material" in inventoryObj[0]
            ? inventoryObj[0].material
            : [];
        let src = null;
        if (imageObj) {
          src = imageObj.src;
        } else {
          src = "https://sss-retail-images.s3.amazonaws.com/404.jpg?w=256&q=75";
        }
        let variantArr = [];

        const productCreatedAt = variantObj[0]?.created_at;
        // Calculate the time difference in milliseconds
        const timeDiff = new Date() - new Date(productCreatedAt);

        // Calculate the number of days
        const productAge = timeDiff / (1000 * 60 * 60 * 24);
        variantArr.push(
          variantObj.map((x) => ({
            size: x.title,
            stock: x.stock ? x.stock : 0,
          }))
        );
        const itemIdList = variantObj.reduce((acc, x) => {
          acc[x.title] = x.inventory_item_id;
          return acc;
        }, {});
        const product = products.find((p) => p.id === productId);
        const status = product?.status;
        const name = product?.title;
        return {
          ...p,
          src,
          name,
          type: imageObj?.type,
          inventoryStock: productStock,
          itemIds: itemIdList,
          stock: variantArr,
          material,
          productId,
          status,
          productAge,
        };
      });

      setTableData(data.filter((d) => d.productAge >= productAge));
    }
  }, [
    productsRE,
    productImageList,
    productVariantList,
    products,
    inventoryStock,
    productAge,
  ]);

  useEffect(() => {
    if (shopifyInventory.length > 0 && productVariants.length > 0) {
      const temp = productVariants.map((x) => {
        const variant = shopifyInventory.find(
          (p) => p.inventory_item_id === x.inventory_item_id
        );
        let stock = 0;
        if (variant) {
          stock = variant.available;
        } else {
          stock = 0;
        }
        return {
          ...x,
          stock,
        };
      });
      // console.log(temp)
      setProductVariantList(temp);
    }
  }, [shopifyInventory, productVariants]);

  useEffect(() => {
    const type = productType === "Clothing" ? "Clothing" : "Shoes";
    const sizesArray = getSizesByType(type);
    setSizes(sizesArray);
    setPage(0);
  }, [productType, tableData]);

  useEffect(() => {
    const ordersWithTotalShopifyStock = tableData.map((d) => ({
      ...d,
      totalShopifyStock: d.stock[0].reduce((acc, d) => acc + d.stock, 0),
    }));
    const data = ordersWithTotalShopifyStock.filter(
      (d) =>
        d.productAge >= productAge &&
        (stockSize === "All" ||
          (stockSize === "Finite" && d.totalShopifyStock < 600) ||
          (stockSize === "Infinite" && d.totalShopifyStock >= 600))
    );
    setFilteredData(data);
  }, [tableData, productAge, stockSize]);
  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>SKU</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Material</TableCell>
              <TableCell></TableCell>
              {sizes.map((i, k) => (
                <TableCell key={k}>Size : {i.toString()}</TableCell>
              ))}
              <TableCell onClick={() => setDesc(!desc)}>
                <div style={{ display: "flex" }}>
                  Total
                  <div>{desc ? <ArrowDownward /> : <ArrowUpward />}</div>
                </div>
              </TableCell>
              {/* <TableCell>Inventory Stock</TableCell> */}
              <TableCell>Order IDs</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredData
            ).map((row, index) => (
              <Row
                key={index}
                row={row}
                sizes={sizes}
                replacementUnfulfilledOrders={replacementUnfulfilledOrders}
              />
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{ overflow: "inherit" }}
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ReportTable;
