import {
  Box,
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import TablePaginationActions from "../../utils/TablePaginationActions";

const MobileUnvailabilityReport = ({ orders }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    const formatted = orders
      .map((o) => {
        if (checkNumber(String(o.billing_address.phone))) {
          o.valid = "Valid";
        } else {
          o.valid = "Invalid";
        }
        return o;
      })
      .sort((a, b) => {
        const validA = a.valid.toUpperCase(); // ignore upper and lowercase
        const validB = b.valid.toUpperCase(); // ignore upper and lowercase
        if (validA < validB) {
          return -1;
        }
        if (validA > validB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    setData(formatted);
  }, [orders]);

  const checkNumber = (number) => {
    const formatted = number
      .split("")
      .filter((i) => i !== " ")
      .filter((i) => i !== "  ")
      .filter((i) => i !== "-")
      .join("");
    console.log(formatted);

    if (formatted.startsWith("+91") && formatted.length !== 13) {
      return false;
    }

    if (/^[6-9]\d{9}$/gi.test(formatted.slice(-10))) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Order Id</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Created At</TableCell>
              <TableCell align="left">Order Number</TableCell>
              <TableCell align="left">Phone Number</TableCell>
              <TableCell align="left">Valid</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="left">{row.billing_address.name}</TableCell>
                <TableCell align="left">
                  {new Date(row.created_at).toLocaleString()}
                </TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.billing_address.phone}</TableCell>
                <TableCell align="left">
                  {row.valid === "Valid" ? (
                    <Chip label={"Valid"} color={"success"} />
                  ) : (
                    <Chip label="Invalid" size={"medium"} color={"error"} />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{ overflow: "inherit" }}
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MobileUnvailabilityReport;
