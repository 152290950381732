import { TextField, MenuItem, Box } from '@mui/material'
import React from 'react'
import { useState, useEffect } from 'react'
import { LastDeliveryStatusOptions,
         lastReverseStatusOptions } from '../../../utils/helperArrays';
import CancelIcon from '@mui/icons-material/Cancel';
import { toast } from 'react-toastify';
import SearchIcon from "@mui/icons-material/Search";
import NprDataTable from './NprDataTable';
          
        
const NprDataFilter = ({tableData, crm1, crm2, crm3}) => {

    const [data, setData] = useState(tableData)
    console.log(data)

    const [orderType, setOrderType] = useState("")
    const [orderTypeData, setOrderTypeData] = useState([])
    
    const [lastDeliveryStatusSelected, setLastDeliveryStatusSelected] = useState([])
    const [lastStatusData, setLastStatusData] = useState([])

    const [searchFilter, setSearchFilter] = useState("")

    useEffect(() => {
        const masterArray = [orderTypeData,lastStatusData]
        const filterEmptyArray = masterArray.filter(x => x.length !== 0)
        if(filterEmptyArray.length>0){
            const finalFilterResult = filterEmptyArray.reduce((a, b) => a.filter(c => b.includes(c)));
            setData(finalFilterResult)
        }else{
            setData(tableData)
        }
    },[orderTypeData,lastStatusData])

    useEffect(() => {
      setData(tableData);
    }, [tableData])

   
    useEffect(() => {
        if ( orderType !== ""){
            let tempData = []
            if (orderType === "All") {
            tempData = tableData
            }else{
            const filteredData = tableData.filter((option) => option.type === orderType )
            tempData = filteredData
            }
            if(tempData.length>0) setOrderTypeData(tempData)
            else{
                setOrderType("")
                setOrderTypeData([])
                toast.error("No Data Found For This Filter.Please Select Another Option")
            }
        }
      },[orderType, tableData])

    useEffect(() => {
        if ( lastDeliveryStatusSelected.length>0){
            let tempData = []
            lastDeliveryStatusSelected.map(x => {
                const filteredData = tableData.filter(option => (option.xb_reverse_last_sync_status?.replaceAll(" ","").toLowerCase()) === x.replaceAll(" ","").toLowerCase())
                tempData.push(filteredData)
            })
            if(!!tempData.find(p => p.length === 0)) toast.error("Selected Filter Data Doesn't Exist.Please Try Another Filter")
            if(tempData.length>0){
                const finalFilterResult = tempData.flat();
                setLastStatusData(finalFilterResult)
            }
            else {
                setLastDeliveryStatusSelected([])
                setLastStatusData([])
                toast.error("No Data Found For This Filter.Please Select Another Option")
            }
        }
      },[lastDeliveryStatusSelected, tableData])

    useEffect(() => {
        if (crm3) {
            setLastDeliveryStatusSelected(LastDeliveryStatusOptions);
        }
        }, [crm3, LastDeliveryStatusOptions]);

    useEffect(() => {
        if(searchFilter !== ""){
            const searchedOrder = tableData.filter(x => String(x.orderNumber).startsWith(searchFilter))
            if(searchedOrder) setData(searchedOrder)
            else return
        }else{
            setData(tableData)
        }
    },[searchFilter])
        
    return (
        <div>
            <div style = {{display:"flex", justifyContent:"center"}}>
                <>
                    <Box sx={{ display: "flex", paddingRight:"30px", alignItems: "flex-end", gap: 1 }}>
                        <SearchIcon />
                        <TextField
                        id="input-with-sx"
                        label="Search Order"
                        variant="standard"
                        onChange={(e) => setSearchFilter(e.target.value)}
                        />
                    </Box>
                    <TextField
                        select
                        label="Order Type"
                        value={orderType}
                        onChange={(e) => {
                            setOrderType(e.target.value)
                        }}
                        variant="outlined"
                        className="select"
                        style={{width:"230px"}}
                        >
                        {['Exchange', 'Return'].map((option) => (
                            <MenuItem key={option} value={option}>
                            {option}
                            </MenuItem>
                        ))}
                    </TextField>
                    {orderTypeData.length>0 ? (
                        <CancelIcon style={{color:"red", margin:"10px"}} onClick={() => {
                            setOrderType("")
                            setOrderTypeData([])
                        }}/>
                    ) : ""}
                </>
                <>
                    <TextField
                        SelectProps={{
                        multiple: true,
                        value: lastDeliveryStatusSelected,
                        onChange: (e) => {
                            if (crm3) return;
                            setLastDeliveryStatusSelected(e.target.value);
                        },
                        }}
                        sx={{ width: "230px" }}
                        variant="outlined"
                        select
                        label="Last Delivery Status"
                    >
                        {lastReverseStatusOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                        ))}
                    </TextField>
                    {lastStatusData.length>0 ? (
                        <CancelIcon style={{color:"red", margin:"10px"}} onClick={() => {
                            setLastDeliveryStatusSelected([])
                            setLastStatusData([])
                        }}/>
                    ) : ""}
                </>
            </div>
            <br/>
            {data.length>0 ? (
                <NprDataTable
                  orders={data}
                />
            ) : "No Orders Found"}
                                           
        </div>  
  )
}

export default NprDataFilter