import React from 'react'
import { useState } from 'react';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { Typography,Modal,Box,TableCell,TextField,Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { XbNprPostRequest } from '../../../actions/ordersActions';

export const NprEditAddressModal = ({open, setEditAddressModal ,rowData}) => {
  const [value, setValue] = useState(dayjs(new Date().setDate(new Date().getDate() + 1)));
  const [ alternateAddress, setAlternateAddress] = useState("")
  const [ customerPincode, setCustomerPincode] = useState(rowData.address.zip)
  
  const [openUp, setOpenUp] = React.useState(open);
  const dispatch = useDispatch()

//   const handleOpen = () => setOpenUp(true);
  const handleClose = () => {
    setValue(dayjs(new Date()))
    setAlternateAddress("")
    setCustomerPincode("")
    setOpenUp(false)
    setEditAddressModal(false)
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleSubmit = () => {
    // dispatch(showLoading())
    const formatDate = new Date(value.$d).getDate() + "-" + (new Date(value.$d).getMonth() + 1) + "-" + new Date(value.$d).getFullYear()
    const formatTime = new Date(value.$d).getHours() + ":" + new Date(value.$d).getMinutes() + ":" + new Date(value.$d).getSeconds()
    const XBObj = {
        ShippingID: rowData.awb_code.toString() ,
        ReAttemptDate : formatDate + " " + formatTime,
        CustomerAddress : alternateAddress,
        CustomerPincode : parseInt(customerPincode)
    }
    const dynamoObj = {
      fulfillment_id : rowData.fulfillment_id,
      package_status : "dispatched",
      npr_history : [
        {
          lbi_Remarks : "Customer Delivery Address Changed",
          ReAttemptDate : formatDate,
          AlternateAddress : alternateAddress,
          AlternatePincode : customerPincode
        }
      ]
    }
    console.log(XBObj)
    // console.log(dynamoObj)
    dispatch(XbNprPostRequest(XBObj, dynamoObj))
    // dispatch(hideLoading())
    handleClose()
  }

  return (
    <div>
        <Modal
            open={openUp}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            // style = {{width:"500px"}}
        >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight="Bold">
            Edit Address Details
            </Typography>
            <TableCell sx={{ fontWeight: "bold" }}
                        aligntext="left">Shipping ID:
            </TableCell>
            <TextField
                    variant="outlined"
                    className="select"
                    value = {rowData.awb_code}
            >                     
            </TextField>
            <br/>
            <br/>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                renderInput={(props) => <TextField width="fit-content"{...props} />}
                label="New Delivery Date"
                value={value}
                onChange={(newValue) => {
                    setValue(newValue);
                }}
                />
            </LocalizationProvider>
            <TableCell sx={{ fontWeight: "bold" }}
                        aligntext="left">Customer Provided Address:
            </TableCell>
            <TextField
              variant="outlined"
              className="select"
              value={rowData.address.address1 + " " + rowData.address.address2}
              fullWidth
            >                     
            </TextField>
            <TableCell sx={{ fontWeight: "bold" }}
                        aligntext="left">Enter Alternate Address:
            </TableCell>
            <TextField
                    variant="outlined"
                    className="select"
                    value={alternateAddress}
                    onChange = {e => setAlternateAddress(e.target.value)}
                    fullWidth
            >                     
            </TextField>
            <br/>
            <TableCell sx={{ fontWeight: "bold" }}
                        aligntext="left">Customer Pincode :
            </TableCell>
            <TextField
                    variant="outlined"
                    className="select"
                    value={customerPincode}
                    onChange = {e => setCustomerPincode(e.target.value)}
            >                     
            </TextField>
            <br/>
            <br/>
            <Button variant="contained" onClick={handleSubmit}>Submit</Button>
        </Box>
        </Modal>
    </div>
  )
}
