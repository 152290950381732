export const showModal = () => {
  return (dispatch) => {
    dispatch({
      type: "SHOW_MODAL",
    });
  };
};

export const hideModal = () => {
  return (dispatch) => {
    dispatch({
      type: "HIDE_MODAL",
    });
  };
};

export const showUnauthorizedModal = () => {
  return (dispatch) => {
    dispatch({
      type: "SHOW_UNAUTHORIZED_MODAL",
    });
  };
};

export const hideUnauthorizedModal = () => {
  return (dispatch) => {
    dispatch({
      type: "HIDE_UNAUTHORIZED_MODAL",
    });
  };
};
