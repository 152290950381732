import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDynamoNonDeliveredOrdersData } from "../../actions/ordersActions";
import PickupReadyTable from "../ordersWidgets/PickupReadyTable";
import RedirectLogin from "../RedirectLogin";
import ProgressBar from "../../ProgressBar";

const ReadyToShipOrders = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const orders = useSelector((state) => state.orderDetails);

  useEffect(() => {
    if (orders.nonDeliveredOrders.length === 0 && auth) {
      dispatch(getDynamoNonDeliveredOrdersData());
    }
  }, [dispatch, auth]);

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {orders.nonDeliveredOrders.length === 0 ? (
            <ProgressBar />
          ) : (
            <>
              {/* <h1>Work here</h1>
                            {console.log(
                                orders.processingOrders
                                    .filter((o) => {
                                        const found = phones.find(
                                            (p) => p === o.billing_phone
                                        );
                                        return !!found;
                                    })
                                    .map((o) => ({
                                        phone: o.billing_phone,
                                        awbcode: o.awb_code,
                                        courier: o.courier_name,
                                        status: o.package_status,
                                    }))
                            )}

                            <h1>End</h1> */}
              <PickupReadyTable
                tableData={orders.nonDeliveredOrders.filter(
                  (o) => o.package_status === "dispatched"
                )}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default ReadyToShipOrders;
