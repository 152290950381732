import * as React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import NavBar from "./NavBar";
import Header from "./Header";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const drawerWidth = "250px";

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: "#ecf0f3",
  transition: theme.transitions.create("width", {
    // easing: theme.transitions.easing.easeOut,
    // duration: "500ms",
  }),
  overflowX: "hidden",
  // overflow:"auto",
  scrollbarWidth: "thin",
  "&::-webkit-scrollbar": {
    width: "0.2em",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    // easing: theme.transitions.easing.easeInOut,
    // duration: theme.transitions.duration.standard,
  }),
  backgroundColor: "#ecf0f3",
  overflowX: "hidden",
  // overflow:"auto",
  scrollbarWidth: "thin",
  "&::-webkit-scrollbar": {
    width: "0.2em",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
  width: `calc(${theme.spacing(4.5)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(5.5)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    // easing: theme.transitions.easing.easeInOut,
    // duration: theme.transitions.duration.standard,
    // delay : theme.transitions.delay
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100%)`,
    transition: theme.transitions.create(["width", "margin"], {
      // easing: theme.transitions.easing.easeInOut,
      // duration: theme.transitions.duration.standard,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Layout() {
  const auth = useSelector((state) => state.auth);
  const { redirectToLogin } = useSelector((state) => state.errorModal);
  const [open, setOpen] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth && redirectToLogin) {
      navigate("/login");
    }
  }, [auth, navigate, redirectToLogin]);
  return (
    auth && (
      <Box sx={{ display: "flex" }}>
        {/* <CssBaseline /> */}
        <AppBar
          position="fixed"
          open={open}
          style={{
            backgroundColor: "#ecf0f3",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            height: "40px",
          }}
        >
          <Header open={open} />
        </AppBar>

        <Drawer
          variant="permanent"
          onMouseOver={() => setOpen(true)}
          onMouseLeave={() => {
            setOpen(false);
            setExpanded(false);
          }}
          open={open}
        >
          <DrawerHeader style={{ minHeight: "40px" }} />
          <Divider />
          <NavBar open={open} expanded={expanded} setExpanded={setExpanded} />
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "40px" }}>
          {/* <DrawerHeader style={{justifyContent:"unset"}}></DrawerHeader> */}
          <Outlet />
        </Box>
      </Box>
    )
  );
  // <Navigate to="/login" />
}
