import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Stack } from "@mui/material";
import { toast } from "react-toastify";

import {
  showLoading,
  hideLoading,
  getLastXBReverseAWB,
  updateREstatusPostXBReverse,
  denyRErequest,
  postXBReverseOrder,
} from "../../actions/ordersActions";
import { createXBReverseOrderPayload } from "../../utils/helpers";
import RejectREModal from "./RejectREModal";
import { showUnauthorizedModal } from "../../actions/errorModalActions";
import { showFailure } from "../../actions/loaderActions";
import {
  sendREapprovedMessage,
  sendRErejectedMessage,
} from "../../actions/SESActions";

const RejectedREDetails = ({ order, clear, emailList }) => {
  const [reason, setReason] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleCancel = () => {
    // Open a modal
    setIsModalOpen(true);
  };

  const handleApprove = async () => {
    dispatch(showLoading());
    const awb = await getLastXBReverseAWB();
    if (awb === 403) {
      dispatch(showUnauthorizedModal());
      return;
    }
    console.log(awb);
    if (!awb) {
      // EXIT and show error
      dispatch(showFailure());
      dispatch(hideLoading());
      toast.error("Cound not get AWB. Please try later");
      return;
    }
    const email =
      "email" in order && !emailList.includes(order.email) ? order.email : "";
    const payload = createXBReverseOrderPayload(order, awb);
    console.log("PAYLOAD", payload);
    dispatch(postXBReverseOrder(payload, email));
    // dispatch(sendREapprovedMessage(email));
    clear();
  };

  const handleDeny = () => {
    if (!reason) {
      toast.error("You must specify a reason");
      return;
    }
    setIsModalOpen(false);

    const payload = {
      id: order.fulfillment_id,
      reason,
    };
    const email =
      "email" in order && !emailList.includes(order.email) ? order.email : "";
    dispatch(denyRErequest(payload, email));
    // dispatch(sendRErejectedMessage(email));
    clear();
    setReason(null);
  };

  return (
    <div>
      <RejectREModal
        open={isModalOpen}
        close={() => setIsModalOpen(false)}
        saveReason={setReason}
        deny={handleDeny}
      />
      <div className="flexRow" style={{ alignItems: "none" }}>
        <h4>Order No: {order.orderNumber}</h4>
        <h5>Type: {order.type}</h5>
        <h5>Name: {order.address.name}</h5>
        <h5>
          Contact:{" "}
          {order.address?.phone
            ?.split("")
            .filter((i) => i !== " ")
            .filter((i) => i !== "  ")
            .filter((i) => i !== "-")
            .join("")
            .slice(-10)}{" "}
          |{" "}
          {order.alternatePhone
            ?.split("")
            .filter((i) => i !== " ")
            .filter((i) => i !== "  ")
            .filter((i) => i !== "-")
            .join("")
            .slice(-10)}
        </h5>
      </div>
      <div className="flexRow">
        {order.products.map((p) => (
          <div key={p.id} className="exchangePSpecs">
            <img src={p.url} alt="LBI" width="100" />
            <h5>SKU: {p.sku}</h5>
            {order.type === "Exchange" ? (
              <div>
                <h5>Original Size: {p.originalSize}</h5>
                <h5>Requested Size: {p.size}</h5>
              </div>
            ) : (
              <h5>Size: {p.originalSize}</h5>
            )}
          </div>
        ))}
      </div>
      <div>
        {"created_at" in order ? (
          <h4>
            Created At : {new Date(order.created_at).toLocaleDateString()}
          </h4>
        ) : (
          ""
        )}
        <h4>
          Email :{" "}
          {"email" in order && !emailList.includes(order.email) ? (
            order.email
          ) : emailList.includes(order.email) ? (
            <span style={{ color: "red" }}>
              Customer Gave Invalid Email or Cannot be Sended
            </span>
          ) : (
            <span style={{ color: "red" }}>Not Provided</span>
          )}
        </h4>
        {<h4>Customer Wants : {order.exchangeAction}</h4>}
        {order.type === "Exchange" ? (
          <>
            <h4>
              Exchange Type: {order.exchangeReason}{" "}
              {order.exchangeReason === "Both"
                ? "(size exchange & damaged product)"
                : ""}
            </h4>
            <h4>Reason:</h4>
          </>
        ) : (
          <>
            <h4>Return reason</h4>
          </>
        )}
        <p>{order.reason}</p>
      </div>
      <div className="flexRow">
        {order.files?.length > 0 && (
          <div>
            <h4>Customer Pictures:</h4>
            <div className="flexRow">
              {order.files?.map((f) => (
                <div key={f.id}>
                  <a href={f.url} target="_blanck">
                    <img
                      src={`https://drive.google.com/uc?export=view&id=${f.id}`}
                      alt={f.name}
                      width="200"
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="flexRow">
        <Button variant="contained" color="success" onClick={handleCancel}>
          Deny Request
        </Button>
        <Button variant="contained" onClick={handleApprove}>
          Approve Request
        </Button>
      </div>
    </div>
  );
};

export default RejectedREDetails;
