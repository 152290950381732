import { toast } from "react-toastify";
import { ClientId } from "../utils/UserPool";
import { showUnauthorizedModal } from "./errorModalActions";
import { lambdaFetch } from "../utils/awsUtils";
import { BACKEND_BASE_URL } from "../utils/helpers";

const lastAuthUser = localStorage.getItem(
  "CognitoIdentityServiceProvider." + ClientId + ".LastAuthUser"
);
const tokenName =
  "CognitoIdentityServiceProvider." +
  ClientId +
  "." +
  lastAuthUser +
  ".idToken";
const IDtoken = localStorage.getItem(tokenName);
const ShiprocketToken = localStorage.getItem("ShiprocketToken");

export const showLoading = () => {
  return {
    type: "SHOW_LOADER",
  };
};

export const hideLoading = () => {
  return {
    type: "HIDE_LOADER",
  };
};

export const getAllShipRocketOrders = (date) => {
  const allOrders = async (IDtoken, token, date) => {
    console.log(date);

    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/reports/getshiprocketorders";

    let fetching = true;
    let shiprocket_orders = [];
    let next = null;
    while (fetching) {
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify({ token, date, next }),
      });
      if (response.status === 403) {
        return 403;
      }
      const data = await response.json();
      console.log(data);
      if (data.statusCode === 200) {
        const processingData = data.body.data;
        shiprocket_orders.push(...processingData);
        next = data.next;
        if (!next) {
          fetching = false;
        }
      } else {
        shiprocket_orders = [];
        fetching = false;
      }
    }
    return shiprocket_orders;
  };
  return (dispatch) => {
    dispatch(showLoading());
    allOrders(IDtoken, ShiprocketToken, date)
      .then((data) => {
        if (data === 403) {
          dispatch(hideLoading());
          dispatch(dispatch(showUnauthorizedModal()));
          return;
        }
        if (data.length > 0) {
          dispatch(hideLoading());
          dispatch({
            type: "SHIPROCKET_ORDERS",
            data,
          });
          toast.success("All ShipRocket Orders Loaded Successfully");
        } else {
          dispatch(hideLoading());
          toast.error("Error in Fetching ShipRocket Orders");
        }
      })
      .catch((error) => {
        dispatch(hideLoading());
        toast.error("Something Went Wrong With Connecting To Server", {
          theme: "colored",
        });
      });
  };
};

export const gstData = () => {
  const syncData = async () => {
    const syncUrl = "http://localhost:8001/api/syncGst";

    const response = await fetch(syncUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  };

  return (dispatch) => {
    syncData().then((data) => {
      dispatch({
        type: "GST_DATA",
        payload: data.allOrders,
      });
    });
  };
};

export const delhiveryGstData = () => {
  const syncData = async () => {
    const syncUrl = "http://localhost:8001/api/syncGst/delhivery";

    const response = await fetch(syncUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return await response.json();
  };

  return (dispatch) => {
    syncData().then((data) => {
      dispatch({
        type: "GST_DATA",
        payload: data.allOrders,
      });
    });
  };
};

export const getBackupData = (orderBody) => {
  const syncData = async () => {
    const syncUrl = "http://localhost:8001/api/backup";

    const response = await fetch(syncUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderBody),
    });
    return await response.json();
  };

  return (dispatch) => {
    syncData().then((data) => {
      dispatch({
        type: "BACKUP_DATA",
        payload: data.allOrders,
      });
    });
  };
};

export const putData = (orderBody) => {
  const syncData = async () => {
    const syncUrl = `${BACKEND_BASE_URL}/api/putOrders/new`;

    const response = await fetch(syncUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderBody),
    });
    const data = await response.json();
    console.log("NEW XB ACCOUNT", data);
  };

  return (dispatch) => {
    syncData();
  };
};

export const syncDelhiveryForward = (orderBody) => {
  const syncData = async () => {
    const syncUrl = `${BACKEND_BASE_URL}/api/delhivery/forward`;

    const response = await fetch(syncUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderBody),
    });
    const data = await response.json();
    console.log("NEW XB ACCOUNT", data);
  };

  return (dispatch) => {
    syncData();
  };
};

export const syncDelhiveryReverse = (orderBody) => {
  const syncData = async () => {
    const syncUrl = `${BACKEND_BASE_URL}/api/delhivery/reverse`;

    const response = await fetch(syncUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderBody),
    });
    const data = await response.json();
    console.log("NEW XB ACCOUNT", data);
  };

  return (dispatch) => {
    syncData();
  };
};

export const putDataOld = (orderBody) => {
  const syncData = async () => {
    const syncUrl = `${BACKEND_BASE_URL}/api/putOrders/old`;

    const response = await fetch(syncUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderBody),
    });
    const data = await response.json();
    console.log(data);
  };

  return (dispatch) => {
    syncData();
  };
};

export const putReverseData = (orderBody) => {
  const syncData = async () => {
    const syncUrl = `${BACKEND_BASE_URL}/api/putReverseOrders/new`;

    const response = await fetch(syncUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderBody),
    });
    const data = await response.json();
    console.log(data);
  };

  return (dispatch) => {
    syncData();
  };
};

export const putReverseDataOld = (orderBody) => {
  const syncData = async () => {
    const syncUrl = `${BACKEND_BASE_URL}/api/putReverseOrders/old`;

    const response = await fetch(syncUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderBody),
    });
    const data = await response.json();
    console.log(data);
  };

  return (dispatch) => {
    syncData();
  };
};

export const putNdrData = () => {
  const syncData = async () => {
    const syncUrl = `${BACKEND_BASE_URL}/api/syncNdr/new`;

    const response = await fetch(syncUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const data = await response.json();
    console.log(data);
  };

  return (dispatch) => {
    syncData();
  };
};

export const putNdrDataOld = () => {
  const syncData = async () => {
    const syncUrl = `${BACKEND_BASE_URL}/api/syncNdr/old`;

    const response = await fetch(syncUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const data = await response.json();
    console.log(data);
  };

  return (dispatch) => {
    syncData();
  };
};
