import React, { useState } from "react";
import { Button, TextField } from "@mui/material";

const EditShipping = ({ order, close }) => {
  const [state, setState] = useState(order.shipping_address.province);
  const [city, setCity] = useState(order.shipping_address.city);
  const [pin, setPin] = useState(order.shipping_address.zip);
  const [address, setAddress] = useState(
    (order.shipping_address.address1 || "") +
      (order.shipping_address.address2 || "")
  );

  const handleSave = () => {
    console.log("Clicked");
    order.shipping_address.address1 = address;
    order.shipping_address.address2 = null;
    order.shipping_address.city = city;
    order.shipping_address.province = state;
    order.shipping_address.zip = pin;
    close(false);
  };

  return (
    <div className="order-container padding-all margin-50">
      <h4>EditShipping (Delivery only in India)</h4>
      <div className="container">
        <div className="flex-row">
          <p>State: </p>
          <TextField
            variant="outlined"
            type="text"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
        </div>
        <div className="flex-row">
          <p>City: </p>
          <TextField
            variant="outlined"
            type="text"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </div>
        <div className="flex-row">
          <p>Pincode: </p>
          <TextField
            variant="outlined"
            type="text"
            value={pin}
            onChange={(e) => setPin(e.target.value)}
          />
        </div>
        <div className="flex-row">
          <p>Address: </p>
          <TextField
            variant="outlined"
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            fullWidth
          />
        </div>
        <div style={{ width: "100%" }}>
          <Button
            variant="contained"
            className="button-right-floating"
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditShipping;
