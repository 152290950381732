import { Box,
         IconButton,
         Collapse,
         Typography,
         TableContainer,
         Table,
         TableHead, 
         TableBody, 
         TableRow, 
         TableCell, 
         Paper,
         Avatar, 
         Checkbox, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { toast } from "react-toastify";
import React from 'react';
import NonDeliveryReportButton  from './NonDeliveryReportButton';
import { TableFooter, TablePagination, Button } from "@mui/material"
import TablePaginationActions from "../../utils/TablePaginationActions";   
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CustomerDetailsModal } from "./ndr/CustomerDetailsModal";
import { OrderHistoryDetailModal } from "./ndr/OrderHistoryDetailModal";
import { getCustomerDetails, getXBShipmentDetails, updateNdrOrder, showLoading, hideLoading } from "../../actions/ordersActions";
import ProgressBar from "../../ProgressBar";
import { showFullScreenLoader } from "../../actions/loaderActions";

function OrderActionsSelector({ orders, reset }) {
  const dispatch = useDispatch();

  const handleChange = (e) => {
      dispatch(showFullScreenLoader())
      const todayDate = new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear()
      const tommorowDate = new Date().setDate(new Date().getDate() + 1)
      const formatDate = new Date(tommorowDate).getDate() + "-" + (new Date(tommorowDate).getMonth() + 1) + "-" + new Date(tommorowDate).getFullYear()
      if (e.target.value === 1) {
          const giveAutoReattempt = orders.map(x => ({
            fulfillment_id : x.POID,
            package_status : "sync#NDR-HOLD",
            executive_id : "1",
            ndr_history : [
              {
                ActionType : "Re-Attempt",
                Action : "Marked for Future Delivery Date By Customer Care",
                ReAttemptDate : formatDate,
                Customer_Care : x.executive_id
              }
            ]
          }))
          //save to dynamo
          // console.log(giveAutoReattempt)
          dispatch(updateNdrOrder(giveAutoReattempt))
          reset([]);
      } else {
        const giveRTO = orders.map(x => ({
          fulfillment_id : x.POID,
            package_status : "sync#NDR-HOLD",
            executive_id : "1",
            ndr_history : [
              {
                ActionType : "RTO",
                Action : "Marked As RTO By Customer Care",
                Customer_Care : x.executive_id,
                RTOReason : "Cancelled By Client",
                RTODate : todayDate
              }
            ]
        }))
        // console.log(giveRTO)
        dispatch(updateNdrOrder(giveRTO))
        reset([])
      }
  };

  return (
      <FormControl variant="standard" sx={{ m: 2, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-label">Actions</InputLabel>
          <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={""}
              label="Age"
              onChange={handleChange}
          >
              <MenuItem value={1}>Auto Reattempt</MenuItem>
              {/* <MenuItem value={2}>RTO</MenuItem> */}
          </Select>
      </FormControl>
  );
}

const NonDeliveryReportTable = ({tableData}) => {
 
  const [data, setData] = useState(
    tableData?.sort(
      (a, b) => new Date(a.ShipmentDate) - new Date(b.ShipmentDate)
    )
  );
  const [open, setOpen] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [ customerDetailsModal, setCustomerDetailsModal ] = useState(false)
  const [ orderHistoryModal, setOrderHistoryModal ] = useState(false)
  const [selectedOrders, setSelectedOrders] = useState([]);
  // console.log(selectedOrders)

  const dispatch = useDispatch()
  
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  useEffect(() => {
    if (tableData.length > 0) {
      setOpen(Array(tableData.length).fill(false));
    }
    setData(
      [...tableData]
        ?.sort(
          (a, b) => new Date(a.ShipmentDate) - new Date(b.ShipmentDate)
        )
    );
  }, [tableData]);

  return (
    <>
    {/* {!loading ? ( */}
      <div>
        {tableData.length > 0 ? (
          <>
          {selectedOrders.length > 0 ? (
            <div
                style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "20px",
                }}
            >
                <h4>Select Action: </h4>
                <OrderActionsSelector
                    orders={selectedOrders}
                    reset={setSelectedOrders}
                />
            </div>
          ) : (
              ""
          )}

          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow style={{textalign: "Center"}}>
                    <TableCell>
                      <>
                        <Checkbox
                            color="primary"
                            checked={
                                selectedOrders.length > 0
                                    ? true
                                    : false
                            }
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setSelectedOrders(tableData);
                                } else {
                                    setSelectedOrders([]);
                                }
                            }}
                        />
                        {`${selectedOrders.length} Selected`}
                      </>
                    </TableCell>
                    <TableCell>
                      {tableData.length} Items
                    </TableCell>
                    <TableCell>Order Details</TableCell>
                    <TableCell>Order ID</TableCell>
                    <TableCell>AWB</TableCell>
                    <TableCell>Order Cost</TableCell>
                    <TableCell>Current Location</TableCell>
                    <TableCell>Destination</TableCell>
                    <TableCell>Total Attempts</TableCell>
                    <TableCell>Order Date</TableCell>
                    <TableCell>Shipment Date</TableCell>           
                    <TableCell>Pending Days</TableCell>
                    <TableCell>Payment Type</TableCell>
                    <TableCell>Last Delivery Status</TableCell>
                    {/* <TableCell>Current Process</TableCell> */}
                    <TableCell>Phone</TableCell>
                    <TableCell style={{textAlign: "center"}}>Actions</TableCell>
                </TableRow>
              </TableHead>
                
                <TableBody>
                {(rowsPerPage > 0
                        ? tableData.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : tableData
                      ).map((row, index) => (
                    <React.Fragment key={index}>
                        <TableRow
                            key={index}
                            sx={{
                              bgcolor: index % 2 === 0 ? "#ededed" : "#f2f5ed",

                              "& > *": { borderBottom: "unset" },
                            }}
                          >
                            <TableCell
                                component="th"
                                scope="row"
                            >
                                <Checkbox
                                    color="primary"
                                    checked={
                                        selectedOrders.filter(
                                            (i) =>
                                                i.POID ===
                                                row.POID
                                        ).length > 0
                                            ? true
                                            : false
                                    }
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedOrders([
                                                ...selectedOrders,
                                                row,
                                            ]);
                                        } else {
                                            setSelectedOrders(
                                                selectedOrders.filter(
                                                    (item) =>
                                                        item.POID !==
                                                        row.POID
                                                )
                                            );
                                        }
                                    }}
                                />
                            </TableCell>
                            <TableCell component="th" scope="row">
                                  {index + 1}
                            </TableCell>
                            <TableCell>
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => {
                                      const temp = open.map((i, openIndex) => {
                                        if (index !== openIndex) {
                                          return i;
                                        } else {
                                          return !i;
                                        }
                                      });
                                      setOpen(temp);
                                    }}
                                  >
                                    {open[index] ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <KeyboardArrowDownIcon />
                                    )}
                                  </IconButton>
                            </TableCell>
                            <TableCell>{row.POID}</TableCell>
                            {/* <Button variant="text" sx={{objectFit: "contain"}}>{row.POID}</Button> */}
                          
                            {/* <TableCell>{row.AWB}</TableCell> */}
                            <TableCell sx={{ fontWeight: "bold", color: "rgb(64, 230, 18)", cursor:"pointer"}}>
                              <div onClick={() =>{
                                setOrderHistoryModal(true)
                                dispatch(getXBShipmentDetails({AWB : parseInt(row.awb)}))
                                }}>{row.awb}
                              </div>
                              {orderHistoryModal ? (
                                <OrderHistoryDetailModal open={orderHistoryModal} setOrderHistoryModal={setOrderHistoryModal}/>
                              ) : ""}
                            </TableCell>
                            <TableCell>{row.total_order_price}</TableCell>
                            <TableCell>{row.Location}</TableCell>
                            <TableCell>{row.DestinationLocation}</TableCell>
                            <TableCell>{row.TotalAttempt}</TableCell>
                            <TableCell>{row.ordered_date}</TableCell>
                            <TableCell>{new Date(row.ShipmentDate).toLocaleDateString("en-in")}</TableCell>
                            <TableCell>{row.PendingDays}</TableCell>
                            <TableCell>{row.PaymentMode}</TableCell>
                            <TableCell
                              sx={{ fontWeight: "bold", color: "red" }}
                            >{row.Status}
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold", color: "rgb(64, 230, 18)", cursor:"pointer"}}>
                              <div onClick={() =>{ 
                                setCustomerDetailsModal(true)
                                dispatch(getCustomerDetails({phone : parseInt(row.customer_phone)}))
                                }}>{row.customer_phone}</div>
                              {customerDetailsModal ? (
                                <CustomerDetailsModal open={customerDetailsModal} setCustomerDetailsModal={setCustomerDetailsModal}/>
                              ) : ""}
                            </TableCell>
                            <TableCell>
                                <NonDeliveryReportButton rowData={row}/>
                            </TableCell>
                        </TableRow>
                        <TableRow style={{verticalAlign : "baseline"}}>
                          <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={7}
                              >
                                <Collapse in={open[index]} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>
                                    <Typography variant="h6" gutterBottom component="div">
                                      Product Details
                                    </Typography>
                                      <Table size="small" aria-label="purchases">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>Image</TableCell>
                                            <TableCell>SKU</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Size</TableCell>
                                            <TableCell>Price</TableCell>
                                            <TableCell>Discount</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {row.order_items?.map((x,index) => (
                                            <TableRow key={index}>
                                              <TableCell>
                                                <Avatar
                                                  src={x.url}
                                                  alt="https://www.elegantthemes.com/blog/wp-content/uploads/2018/02/502-error.png"
                                                  sx={{
                                                    width: 100,
                                                    height: 100,
                                                    objectFit: "contain",
                                                  }}
                                                  variant="square"
                                                />
                                              </TableCell>
                                              <TableCell>{x.sku.split(".")[0]}</TableCell>
                                              <TableCell>{x.name}</TableCell>
                                              <TableCell>{x.variant_title}</TableCell>
                                              <TableCell>{x.selling_price}</TableCell>
                                              <TableCell>{x.discount ? x.discount : 0}</TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                      <h3>Customer Details :</h3>
                                      <p>
                                        Name: {row.customer_name}
                                        <br/>
                                        Phone No.: {row.customer_phone}
                                        <br/>
                                        Email: {row.customer_email}
                                        <br/>
                                        Address : {row.customer_address}
                                        <br/>
                                        City : {row.customer_city}
                                        <br/>
                                        State : {row.customer_state}
                                        <br/>
                                        Pincode : {row.customer_pincode}
                                      </p>
                                </Box>   
                                </Collapse>
                          </TableCell>
                          {/* {row.ndr_history.length > 0 ? (
                            <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={7}
                          >
                            <Collapse in={open[index]} timeout="auto" unmountOnExit>
                              <Box sx={{ margin: 1 }}>
                                  <Typography variant="h6" gutterBottom component="div">
                                    NDR Customer Care History : 
                                  </Typography>
                                  {row.ndr_history.map((item,index) => (
                                    <div key={index} style={{display : "flex", alignItems : "baseline"}}>
                                      <h4>{index + 1}.</h4>
                                      <div style={{paddingLeft:"10px"}}>
                                        {Object.keys(item).map((x,index) => (
                                          <div key={index} style={{display:"flex"}}>
                                            <h4 style={{color:"blue", margin:"0"}}>{x} :</h4>
                                            <h4 style={{paddingLeft:"10px", margin:"0"}}>{Object.values(item[x])}</h4>
                                          </div> 
                                        ))}
                                      </div>
                                    </div>
                                  ))}
                              </Box>   
                            </Collapse>
                          </TableCell>
                          ) : (
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={7}
                            >
                              <Collapse in={open[index]} timeout="auto" unmountOnExit>
                                <Box sx={{ margin: 1 }}>
                                  <Typography variant="h6" gutterBottom component="div" style={{fontSize:"large"}}>
                                    NDR Customer Care History : 
                                  </Typography>
                                  <h4 style={{color:"red"}}>No Customer Care History Yet</h4>
                                </Box>
                              </Collapse>
                            </TableCell>
                          )} */}
                        </TableRow>
                  
                    </React.Fragment>
                    ))}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                </TableBody>
                <TableFooter>
                      <TableRow>
                        <TablePagination
                          style={{ overflow: "inherit" }}
                          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                          colSpan={3}
                          count={data.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: {
                              "aria-label": "rows per page",
                            },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
            </Table>
          </TableContainer>
        </>
        ):(
          
          "No NDR Orders At Present"
        )}
      </div>
    {/* ) : (
      <ProgressBar/>
    )} */}
    </>
  )
}
export default NonDeliveryReportTable
