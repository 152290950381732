import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fulfillBulkInShopify,
  updateWeightOfOrder,
  updateAWBOfOrder,
  revertProcessing,
  bulkDispatched,
  fulfillBulkInShopifyDelhivery,
} from "../../actions/ordersActions";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TableFooter,
  TablePagination,
  Checkbox,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePaginationActions from "../../utils/TablePaginationActions";
import { createPDFforManifest } from "../../utils/pdfUtils";
import ConfirmPopup from "./ConfirmPopup";
import { toast } from "react-toastify";

function OrderActionsSelector({ orders, reset }) {
  const dispatch = useDispatch();
  const [popupVisible, setPopupVisible] = useState(false);

  const deleteProcessing = () => {
    dispatch(revertProcessing(orders[0]));
    setPopupVisible(false);
    reset([]);
  };

  const handleChange = (e) => {
    if (e.target.value === 1) {
      const verified = orders.filter((o) => !o.awb_code).length === 0;
      if (verified) {
        dispatch(fulfillBulkInShopifyDelhivery(orders));
        reset([]);
      } else {
        toast.error("There are orders without awb");
      }
    } else if (e.target.value === 2) {
      dispatch(bulkDispatched(orders));
      reset([]);
    } else {
      setPopupVisible(true);
    }
  };

  return (
    <FormControl variant="outlined" size="small" sx={{ m: 2, minWidth: 120 }}>
      <InputLabel id="demo-simple-select-label">Actions</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={""}
        label="Age"
        onChange={handleChange}
      >
        <MenuItem value={1}>XB Dispatch</MenuItem>
        <MenuItem value={2}>Mark Dispatched</MenuItem>
        <MenuItem value={3}>Cancel Processing</MenuItem>
      </Select>
      <ConfirmPopup
        visible={popupVisible}
        cancel={() => setPopupVisible(false)}
        proceed={deleteProcessing}
      />
    </FormControl>
  );
}

function WeightUpdater({ order }) {
  const dispatch = useDispatch();
  const [weight, setWeight] = useState(order.weight);

  useEffect(() => setWeight(order.weight), [order]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let parsedWeight = parseFloat(weight);
    if (Number.isNaN(parsedWeight)) {
      alert("Weight must be a number");
      setWeight(order.weight);
      return;
    }
    dispatch(updateWeightOfOrder(order.fulfillment_id, parsedWeight));
  };
  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="weight"
        value={weight}
        type="string"
        onChange={(e) => setWeight(e.target.value)}
      />
    </form>
  );
}

function AWBUpdater({ order }) {
  const dispatch = useDispatch();
  const [awb, setAwb] = useState(order.awb_code || "");

  useEffect(() => setAwb(order.awb_code || ""), [order]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateAWBOfOrder(order.fulfillment_id, awb, false));
  };
  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="AWB No."
        value={awb}
        type="string"
        onChange={(e) => setAwb(e.target.value)}
      />
    </form>
  );
}

const PDFDownloader = ({ data }) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => createPDFforManifest(data)}
    >
      Download Manifest
    </Button>
  );
};

export default function XpressBeesDispatchTable({ tableData }) {
  const loading = useSelector((state) => state.loader.loading);
  const [data, setData] = useState(
    tableData
      ?.filter((o) => o.package_status !== "dispatched")
      .sort((a, b) => Number(a.original_order_id) - Number(b.original_order_id))
  );
  const [open, setOpen] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (tableData.length > 0) {
      setOpen(Array(tableData.length).fill(false));
    }
    setData(
      [...tableData.filter((o) => o.package_status !== "dispatched")]
        ?.sort(
          (a, b) => Number(a.original_order_id) - Number(b.original_order_id)
        )
        .reverse()
    );
  }, [tableData]);

  return (
    <>
      {/* {loading && (
                <div className="load-overlay">
                    <h1>Please Wait...</h1>
                </div>
            )}
            <br /> */}
      {selectedOrders.length > 0 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <h4>Selected Action : </h4>
          <OrderActionsSelector
            orders={selectedOrders}
            reset={setSelectedOrders}
          />
        </div>
      ) : (
        <h4>Select only one order to perform actions.</h4>
      )}
      {/* <br /> */}
      {data.length > 0 ? (
        <>
          {/* <PDFDownloader data={selectedOrders} /> */}
          {/* <br /> */}
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {tableData.length} Items
                    <br /> Select
                  </TableCell>
                  <TableCell>Order Number</TableCell>
                  <TableCell>Weight (Kg)</TableCell>
                  <TableCell>AWB no.</TableCell>
                  <TableCell>Shipping</TableCell>
                  <TableCell>SubTotal</TableCell>
                  <TableCell align="right">Total</TableCell>
                  <TableCell align="right">Product</TableCell>
                  <TableCell align="left">List</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? data
                      //   .filter((d) => d.awb_code === "152794980147250")
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                  : data
                ).map((row, index) => (
                  <React.Fragment key={index}>
                    <TableRow
                      key={index}
                      sx={{
                        bgcolor:
                          row.status === "fulfilled"
                            ? "rgba(145,145,145, 0.2)"
                            : index % 2 === 0
                            ? "#afd275"
                            : "#edf5e1",

                        "& > *": {
                          borderBottom: "unset",
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Checkbox
                          color="primary"
                          checked={
                            selectedOrders.filter(
                              (i) => i.fulfillment_id === row.fulfillment_id
                            ).length > 0
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedOrders([...selectedOrders, row]);
                            } else {
                              setSelectedOrders(
                                selectedOrders.filter(
                                  (item) =>
                                    item.fulfillment_id !== row.fulfillment_id
                                )
                              );
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        {row.original_order_id} (#
                        {row.fulfillment_id})
                      </TableCell>
                      <TableCell>
                        <WeightUpdater order={row} />
                      </TableCell>
                      <TableCell>
                        <AWBUpdater order={row} />
                      </TableCell>
                      <TableCell>{row.shipping_charges}</TableCell>
                      <TableCell>{row.sub_total}</TableCell>
                      <TableCell align="right">{row.total}</TableCell>
                      <TableCell align="right">
                        {row.order_items?.length} Items
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => {
                            const temp = open.map((i, openIndex) => {
                              if (index !== openIndex) {
                                return i;
                              } else {
                                return !i;
                              }
                            });
                            setOpen(temp);
                          }}
                        >
                          {open[index] ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                        }}
                        colSpan={6}
                      >
                        <Collapse in={open[index]} timeout="auto" unmountOnExit>
                          <Box sx={{ margin: 1 }}>
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                            >
                              Product Details
                            </Typography>
                            <Table size="small" aria-label="purchases">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Name</TableCell>
                                  <TableCell>SKU</TableCell>
                                  <TableCell>Size</TableCell>
                                  <TableCell>Quantity</TableCell>
                                  <TableCell>MRP</TableCell>
                                  <TableCell>Discount</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {row.order_items?.map((productRow) => (
                                  <TableRow key={productRow.sku}>
                                    <TableCell component="th" scope="row">
                                      {productRow.name}
                                    </TableCell>
                                    <TableCell>{productRow.sku}</TableCell>
                                    <TableCell>
                                      {productRow.variant_title}
                                    </TableCell>
                                    <TableCell>{productRow.units}</TableCell>
                                    <TableCell>
                                      {productRow.selling_price}
                                    </TableCell>
                                    <TableCell>{productRow.discount}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                            <h4>Shipping Address:</h4>
                            <p>
                              Name:{" "}
                              {row.billing_customer_name +
                                " " +
                                row.billing_last_name}
                              <br />
                              Mobile: {row.shipping_phone}
                              <br />
                              Address: {row.shipping_address}
                              <br />
                              {row.shipping_address_2}
                              <br />
                              {row.shipping_city} | {row.shipping_state}
                              <br />
                              {row.shipping_country}
                              <br />
                              {row.shipping_pincode}
                            </p>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    style={{ overflow: "inherit" }}
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    colSpan={3}
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </>
      ) : (
        <h3>No Orders To Fulfill At Present</h3>
      )}
    </>
  );
}
