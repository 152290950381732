import React, { useState } from "react";
import { useEffect } from "react";
import {
  LingerieandAthleisureCategories,
  clothCategories,
  footwearCategories,
  menClothingCategories,
  menShoesCategories,
} from "../../utils/helperArrays";
import CategoryPieChart from "./CategoryPieChart";
import { Stack } from "@mui/material";

const color = [
  "#0079FF",
  "#00DFA2",
  "#F6FA70",
  "#FF0060",
  "#40128B",
  "#9336B4",
  "#D0F5BE",
  "#44D492",
  "#88F7E2",
  "#F5EB67",
  "#9C27B0",
  "#f58d42",
];

const ReverseAnalyticsChart = ({ data }) => {
  const [clothChartData, setClothChartData] = useState([]);
  const [footwearChartData, setFootwearChartData] = useState([]);

  const getClothType = (sku, item) => {
    const combinedClothCategories = clothCategories
      .concat(menClothingCategories)
      .concat(LingerieandAthleisureCategories);
    const found = combinedClothCategories.find(
      (i) => sku.includes(i.value) && i.value
    );
    if (!found) {
      console.log("found error", sku, item);
    }
    return found.label;
  };
  const getFootwearType = (sku, item) => {
    const combinedFootwearCategory =
      footwearCategories.concat(menShoesCategories);
    const found = combinedFootwearCategory.find(
      (i) => sku.includes(i.value) && i.value
    );
    if (!found) {
      console.log("found error", sku, item);
    }
    return found.label;
  };

  useEffect(() => {
    //  filter out delivered status
    const filterDelivered = data.filter((i) => i.package_status === "R_DLVD");
    //  spreaded all products into 1 single array
    const productsArray = filterDelivered.reduce((acc, o) => {
      acc.push(...o.products);
      return acc;
    }, []);
    const clothFiltered = productsArray.filter((i) =>
      isNaN(parseInt(i.originalSize))
    );
    const footwearFiltered = productsArray.filter(
      (i) => !isNaN(parseInt(i.originalSize))
    );
    const clothsData = clothFiltered.reduce((acc, item) => {
      const label = getClothType(item.sku, item);
      const row = acc.find((ac) => ac.item === label);

      if (row) {
        row.count = row.count + 1;
      } else {
        // type : isNaN(p.originalSize) ? "Clothing" : "Footwear"
        const newObj = {
          item: label,
          count: 1,
          color: color[acc.length],
        };

        acc.push(newObj);
      }

      return acc;
    }, []);

    const footWearData = footwearFiltered.reduce((acc, item) => {
      const label = getFootwearType(item.sku, item);
      const row = acc.find((ac) => ac.item === label);

      if (row) {
        row.count = row.count + 1;
      } else {
        // type : isNaN(p.originalSize) ? "Clothing" : "Footwear"
        const newObj = {
          item: label,
          count: 1,
          color: color[acc.length],
        };

        acc.push(newObj);
      }

      return acc;
    }, []);

    setFootwearChartData(footWearData);
    setClothChartData(clothsData);
  }, [data]);

  return (
    <div>
      <h1
        style={{
          width: "100%",
          textAlign: "center",
        }}
      >
        Reverse Analytics
      </h1>
      <Stack
        mt={5}
        sx={{ justifyContent: "center", marginBottom: "50px" }}
        direction={"row"}
        gap={7}
      >
        <CategoryPieChart data={footwearChartData} name={"Footwear"} />

        <CategoryPieChart data={clothChartData} name={"Clothing"} />
      </Stack>
    </div>
  );
};

export default ReverseAnalyticsChart;
