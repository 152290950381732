import { Box, Paper, Stack } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TicketsAssignReport from "./TicketsAssignReport";

const TicketsNav = ({ tickets, users, setOpenFilter }) => {
  const [openReport, setOpenReport] = useState(false);
  const [stats, setStats] = useState({
    totalTickets: 0,
    totalNew: 0,
    totalOpen: 0,
    totalClosed: 0,
    totalReopen: 0,
  });

  //   const [userStats, setUserStats] = useState([]);

  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    const totalTickets = tickets.length;
    const totalNew = tickets.filter((t) => t.package_status === "new").length;
    const totalOpen = tickets.filter((t) => t.package_status === "open").length;
    const totalOpenAndNotReplied = tickets.filter(
      (t) => t.package_status === "open" && !("lastSendedBy" in t)
    ).length;
    const totalOpenAndRepliedByCustomer = tickets.filter(
      (t) => t.package_status === "open" && t.lastSendedBy === "customer"
    ).length;
    const totalOpenAndRepliedByCustomerCare = tickets.filter(
      (t) => t.package_status === "open" && t.lastSendedBy === "customercare"
    ).length;
    const totalClosed = tickets.filter(
      (t) => t.package_status === "closed"
    ).length;
    const totalReopen = tickets.filter(
      (t) => t.package_status === "reopen"
    ).length;

    const userStatistics = ["admin account", ...users].map((u) => {
      const totalRepliedToday = tickets.filter((t) => {
        if (!("customercare_logs" in t) || t.customercare_logs.length === 0)
          return false;
        const index = t.customercare_logs.length - 1;
        const repliedDate = t.customercare_logs[index].date;
        const sendedBy = t.customercare_logs[index].sendedBy;
        return (
          new Date().toLocaleDateString("en-us") === repliedDate &&
          sendedBy === u
        );
      }).length;
      return { name: u, total: totalRepliedToday };
    });
    console.log("user", userStatistics, auth);
    // if (auth.name === "customercare" || auth.name === "cc_supervisor") {
    //   const filtered = userStatistics.filter((u) => u.name === auth.user);
    //   setUserStats(filtered);
    // } else {
    //   setUserStats(userStatistics);
    // }
    setStats({
      totalTickets,
      totalNew,
      totalOpen,
      totalOpenAndNotReplied,
      totalOpenAndRepliedByCustomer,
      totalOpenAndRepliedByCustomerCare,
      totalClosed,
      totalReopen,
    });
  }, [tickets, users, auth]);

  return (
    <div
      style={{
        marginBottom: "20px",
      }}
    >
      <Stack
        component={Paper}
        gap={4}
        p={1}
        mb={2}
        direction={"row"}
        sx={{
          alignItems: "center",
          "& h3": {
            margin: 0,
            padding: 0,
          },
        }}
      >
        {(auth.name === "administrator" || auth.name === "cc_supervisor") && (
          <Box
            sx={{
              alignSelf: "start",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {openReport ? (
              <ArrowDropDownIcon
                onClick={() => setOpenReport(!openReport)}
                sx={{
                  fontSize: "3rem",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              />
            ) : (
              <ArrowRightIcon
                onClick={() => setOpenReport(!openReport)}
                sx={{
                  fontSize: "3rem",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              />
            )}
          </Box>
        )}
        <h3>Total: {stats.totalTickets}</h3>
        <h3>New: {stats.totalNew}</h3>
        <h3>Opened: {stats.totalOpen}</h3>
        <h3>Open & Not replied : {stats.totalOpenAndNotReplied}</h3>
        <h3>Replied By Customer : {stats.totalOpenAndRepliedByCustomer}</h3>
        <h3>
          Replied By Customercare : {stats.totalOpenAndRepliedByCustomerCare}
        </h3>
        <h3>Reopen: {stats.totalReopen}</h3>
        {/* for admin and supervisor */}
        <FilterListIcon
          onClick={() => setOpenFilter((prev) => !prev)}
          sx={{
            marginRight: 0,
            marginLeft: "auto",
            fontSize: "1.5rem",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        />
      </Stack>
      {(auth.name === "administrator" || auth.name === "cc_supervisor") &&
        tickets.length > 0 &&
        openReport && (
          <Paper>
            <TicketsAssignReport tickets={tickets} users={users} />
          </Paper>
        )}
    </div>
  );
};

export default TicketsNav;
