import React,{useEffect} from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getcustomercareusernames } from '../../../actions/ordersActions';
import MultipleBar from './Chart/MultipleBar';
const Last7daysCustomerCare = ({tickets}) => {
    // console.log("last7daysCustomerCare",tickets)
    const dispatch = useDispatch();
    const { customerCareUsers } = useSelector((state) => state.inventoryDetails);
    // console.log(customerCareUsers)
    useEffect(() => {
        if (customerCareUsers.length === 0 )
          dispatch(getcustomercareusernames()); 
      }, []);

    

      const Dates = [];
      const today = new Date();
    
      for (let i = 0; i < 7; i++) {
        const date = new Date();
        date.setDate(today.getDate() - i);
        Dates.push(date.toISOString().split('T')[0]); 
      }
    const data_main = Dates.reduce((acc,date)=>{
        const data = customerCareUsers.reduce((acc,customercare)=>{
            const assignedTickets = tickets.filter(ticket => "executive_id" in ticket)
            const matched = assignedTickets.filter((ticket)=>ticket.executive_id.includes(customercare))
            const EveryCustomerCare = matched.filter(ticket => {
                return !!ticket.messages.find(m => {
                    if(new Date(m.time).toISOString().split("T")[0] === date){
                        return true;
                    }
                    else{
                        return false;
                    }  
                }
                )
            })
            acc.push({
                category: customercare,
                count: EveryCustomerCare.length
            })
            return acc
        },[])
        const all = {
            date: date,
            more: data
        }
        acc.push(all)
        return acc
    },[])
    console.log("Data Main",data_main)
    const graphData = data_main.map((d) => {
        const date = d.date
        const more = d.more.reduce((acc , item) => {
            acc[item.category] = item.count
            return acc
        },{})
        return {
            date,
            ...more
        }
    })

    console.log("graphData",graphData)

  return (
    <>
        <MultipleBar data={graphData} customerCareName = {customerCareUsers}/>
    </>
  )
}

export default Last7daysCustomerCare