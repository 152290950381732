import { Paper } from "@mui/material";
import React from "react";
import FabricAmtForm from "./FabricAmtForm";
import { materialVendors } from "../../utils/helperArrays";

const SendFabrics = () => {
  return (
    <Paper sx={{ p: 4, minHeight: "600px" }}>
      <h2 style={{ textAlign: "center" }}>Send Fabric</h2>
      <FabricAmtForm vendorList={materialVendors} />
    </Paper>
  );
};

export default SendFabrics;
