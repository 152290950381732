import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "../utils/UserPool";
import { toast } from "react-toastify";
import { getCredentials } from "../utils/awsUtils";
import { hideFullScreenLoader } from "./loaderActions";

export const signIn = (Username, Password) => {
  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool,
      });

      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          const token = data.idToken.jwtToken;
          getCredentials(token).then(resolve({ data, verified: true }));

          // toast("Successfully Logged In");
        },
        onFailure: (err) => {
          console.error("Error", err);
          reject(err);
          // toast.error("Incorrect Username or Password", {
          //   theme: "colored",
          // });
        },
        newPasswordRequired: (userAttributes) => {
          console.log("newPasswordRequired: ", userAttributes);
          user.completeNewPasswordChallenge(Password);
          resolve({ userAttributes, verified: false });
        },
      });
    });
  };

  return (dispatch) => {
    authenticate(Username, Password).then(({ data, verified }) => {
      if (verified) {
        dispatch({
          type: "SIGN_IN",
          data,
        });
        dispatch(hideFullScreenLoader());
        dispatch({
          type: "REDIRECT_TO_LOGIN",
          payload: false,
        });
      } else {
        dispatch({
          type: "SIGN_IN_INVALID",
        });
        dispatch(hideFullScreenLoader());
      }
    });
  };
};

export const loadUser = () => {
  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession((err, data) => {
          if (err) {
            reject();
          } else {
            const token = data.idToken.jwtToken;
            // getCredentials(token);
            getCredentials(token).then(resolve(data));
            // resolve(data);
          }
        });
      } else {
        reject();
      }
    });
  };
  return (dispatch) => {
    getSession()
      .then((data) => {
        // console.log("user", data)
        dispatch({
          type: "USER_LOADED",
          data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "REDIRECT_TO_LOGIN",
          payload: true,
        });
      });
  };
};

export const logOut = () => {
  const logout = async () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
      localStorage.clear();
      // toast("User Logged Out");
    }
  };
  return (dispatch) => {
    logout().then(() => {
      dispatch({
        type: "LOG_OUT",
      });
    });
  };
};
