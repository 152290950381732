import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SetProductFormType from "../productsWidgets/SetProductFormType";
import RedirectLogin from "../RedirectLogin";
import { allSKUIds } from "../../actions/productsActions";
import ProgressBar from "../../ProgressBar";

import "./index.css";
import { allInventory } from "../../actions/inventoryActions";

const AddProduct = () => {
  const auth = useSelector((state) => state.auth);
  const SkuIds = useSelector((state) => state.productDetails.SkuIds);
  const { stock } = useSelector((state) => state.inventoryDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!SkuIds.length > 0 && auth) dispatch(allSKUIds());
    if (stock.length === 0) dispatch(allInventory());
  }, [dispatch, auth]);

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>{!SkuIds.length > 0 ? <ProgressBar /> : <SetProductFormType />}</>
      )}
    </>
  );
};

export default AddProduct;
