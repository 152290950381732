import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Paper,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";
import {
  createChallanHistory,
  getVendorMaterialList,
  sendMaterialToVendor,
} from "../../actions/materialActions";
import AmtTable from "./AmtTable";
import { toast } from "react-toastify";
import { createMaterialChallan } from "../../utils/pdfUtils";

function removeDuplicates(arr) {
  return arr.filter(
    (item, index, self) => index === self.findIndex((t) => t.code === item.code)
  );
}
const FabricAmtForm = ({ vendorList }) => {
  const [vendor, setVendor] = useState("");
  const [challanNumber, setChallanNumber] = useState("");
  const [vendorMaterials, setVendorMaterials] = useState([]);
  const [fabrics, setFabrics] = useState([]);
  const [colors, setColors] = useState([]);

  const [selectedFabric, setSelectedFabric] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [rollAmt, setRollAmt] = useState(0);

  const [loading, setLoading] = useState(false);
  const [materialsChosen, setMaterialsChosen] = useState([]);

  const handleClearAll = () => {
    setFabrics([]);
    setColors([]);
  };
  useEffect(() => {
    if (vendor) {
      handleClearAll();
      setLoading(true);
      getVendorMaterialList(vendor)
        .then((data) => {
          if (data.statusCode === 200) {
            const materials = data.body;
            console.log("materials", materials);
            //get all the fabrics
            const vendorFabrics = materials.map((m) => ({
              code: m.fabricId,
              name: m.fabricName,
            }));
            const uniqueFabrics = removeDuplicates(vendorFabrics);
            setFabrics(uniqueFabrics);
            setVendorMaterials(materials);
            setLoading(false);
          }
        })
        .catch((e) => {
          console.log("err", e);
          setLoading(false);
        });
    }
  }, [vendor]);
  useEffect(() => {
    if (selectedFabric) {
      setColors([]);
      const code = selectedFabric;
      const vendorColors = vendorMaterials
        .filter((v) => v.fabricId === code)
        .map((m) => ({
          code: m.colorId,
          name: m.colorName,
        }));
      setColors(vendorColors);
    }
  }, [selectedFabric, vendorMaterials]);
  const handleNumberInput = (e) => {
    if (isNaN(parseInt(e.target.value))) {
      setRollAmt(0);
    } else {
      setRollAmt(parseInt(e.target.value));
    }
  };
  const handleAdd = () => {
    const fabricName = fabrics.find((f) => f.code === selectedFabric).name;
    const colorName = colors.find((c) => c.code === selectedColor).name;
    const isDuplicateId = materialsChosen.find(
      (m) => m.fabricId === selectedFabric && m.colorId === selectedColor
    );
    if (!!isDuplicateId) {
      toast.error("Already Added");
    } else {
      setMaterialsChosen((prev) => [
        ...prev,
        {
          fabricId: selectedFabric,
          colorId: selectedColor,
          amt: rollAmt,
          fabricName,
          colorName,
        },
      ]);
    }
  };
  const handleRemove = (fabricId, colorId) => {
    setMaterialsChosen((prev) =>
      prev.filter((p) => p.fabricId !== fabricId && p.colorId !== colorId)
    );
  };
  const handleCreateChallan = async () => {
    console.log("materials chosen", materialsChosen);
    // {
    //   "barcode": "001",
    //   "unit": "kg",
    //   "fabricName": "Rib",
    //   "amt": 0,
    //   "colorId": "001",
    //   "gs1sk": "MaterialStock",
    //   "gs1pk": "MaterialStock",
    //   "fabricId": "RB",
    //   "colorName": "White",
    //   "company": "MaterialStock#RB",
    //   "rollToKg": 0,
    //   "rollToMetre": 0,
    //   "vendors": [
    //     {
    //       "name": "Inhouse",
    //       "code": "001"
    //     }
    //   ]
    // },
    // {
    //   "barcode": "003-002",
    //   "unit": "kg",
    //   "fabricName": "Poly Terry 300",
    //   "amt": 0,
    //   "colorId": "002",
    //   "gs2sk": "MaterialVendor",
    //   "gs1pk": "MaterialVendor",
    //   "fabricId": "003",
    //   "vendorId": "001",
    //   "vendor": "Inhouse",
    //   "imageUrl": "https://sss-retail-images.s3.amazonaws.com/Material-003002.jpg",
    //   "colorName": "Black",
    //   "company": "MaterialVendor#001",
    //   "rollToKg": 0,
    //   "rollToMetre": 0
    // }
    const vendorName = vendorList.find((v) => v.code === vendor).name;
    createMaterialChallan(
      "Sended To",
      materialsChosen,
      vendorName,
      challanNumber
    );
    const payload = materialsChosen.map((m) => ({
      vendorId: vendor,
      fabricId: m.fabricId,
      colorId: m.colorId,
      amt: m.amt,
    }));
    const data = await sendMaterialToVendor(payload);
    if (data.statusCode === 200) {
      setChallanNumber("");
      setMaterialsChosen([]);
    }
    await createChallanHistory(
      challanNumber,
      vendor,
      vendorName,
      materialsChosen
    );
  };
  return (
    <div>
      <Paper sx={{ p: 4 }}>
        {/* select which vendor */}
        <Stack direction={"row"} gap={2} sx={{ alignItems: "end" }}>
          <TextField
            style={{ width: "250px" }}
            select
            label="Select Vendor"
            value={vendor}
            onChange={(e) => setVendor(e.target.value)}
            variant="standard"
            className="select"
            // fullWidth
          >
            {vendorList.map((option) => (
              <MenuItem key={option.code} value={option.code}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            style={{ width: "300px" }}
            label="Challan Number"
            value={challanNumber}
            onChange={(e) => setChallanNumber(e.target.value)}
            variant="standard"
          />
        </Stack>
        {loading && (
          <Stack spacing={1} my={3}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />

            <Skeleton variant="rectangular" width={500} height={60} />
            <Skeleton variant="rounded" width={500} height={60} />
          </Stack>
        )}
        {!loading && vendor && fabrics.length > 0 && challanNumber && (
          <Box>
            <Divider sx={{ my: 2 }} />
            <Stack direction={"row"} sx={{ justifyContent: "space-evenly" }}>
              <h3>{`Date - ${new Date().toLocaleDateString("en-in")}`}</h3>
              <h3>{`Shipped To - ${
                vendorList.find((v) => v.code === vendor).name
              }`}</h3>
              <h3>{`Challan No. ${challanNumber}`}</h3>
            </Stack>
            <Divider sx={{ my: 2 }} />
            <Stack direction={"row"} gap={2}>
              {/* fabric */}
              <TextField
                style={{ width: "250px" }}
                select
                label="Select Fabric"
                value={selectedFabric}
                onChange={(e) => setSelectedFabric(e.target.value)}
                variant="standard"
                className="select"
                // fullWidth
              >
                {fabrics.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              {/* color */}
              <TextField
                style={{ width: "250px" }}
                disabled={colors.length === 0}
                select
                label="Select Color"
                value={selectedColor}
                onChange={(e) => setSelectedColor(e.target.value)}
                variant="standard"
                className="select"
                // fullWidth
              >
                {colors.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                sx={{ width: "200px" }}
                variant="standard"
                label="Quantity"
                value={rollAmt}
                onChange={handleNumberInput}
              />
              {/* add */}
              <Button variant="contained" sx={{ px: 4 }} onClick={handleAdd}>
                Add
              </Button>
            </Stack>
            {materialsChosen.length > 0 && (
              <div>
                <Divider sx={{ my: 2 }} />
                <AmtTable data={materialsChosen} handleRemove={handleRemove} />
              </div>
            )}
            {/* fabric and color */}
          </Box>
        )}
        {materialsChosen.length > 0 && (
          <Stack direction={"row"} sx={{ justifyContent: "end" }}>
            <Button variant="contained" onClick={handleCreateChallan}>
              Create Challan
            </Button>
          </Stack>
        )}
      </Paper>
    </div>
  );
};

export default FabricAmtForm;
