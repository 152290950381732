import * as React from "react";
import { useState, useEffect } from "react";
import { getSizesByType } from "../../utils/helperArrays";
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TableFooter,
    Paper,
    TextField,
    MenuItem,
    Avatar,
    Chip,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </Box>
    );
}

function Row({ row, sizes }) {
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableCell>
                    <Avatar
                        src={row.src}
                        sx={{ width: 100, height: 100 }}
                        variant="square"
                    />
                </TableCell>
                <TableCell>{row.sku}</TableCell>
                <TableCell>{row.name}</TableCell>
                {sizes.map((i, k) => (
                    <TableCell
                        key={k}
                        style={{
                            background:
                                row.inventoryStock[i] - row.sizes[i] < 0
                                    ? "indianred"
                                    : "white",
                        }}
                    >
                        {Number.isInteger(row.inventoryStock[i] - row.sizes[i])
                            ? row.inventoryStock[i] - row.sizes[i]
                            : 0}
                    </TableCell>
                ))}
                <TableCell>{row.inventoryTotal}</TableCell>
                <TableCell>{row.orderId.length} Order</TableCell>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={17}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <h3>All Orders</h3>
                            {row.orderId.map((item, k) => (
                                <Chip
                                    key={k}
                                    variant="filled"
                                    label={item}
                                    style={{
                                        margin: "10px",
                                        marginLeft: "0",
                                        color: "limegreen",
                                    }}
                                />
                            ))}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function ProductStockTable({
    tableData,
    productType,
    categoryTypes,
}) {
    // console.log(tableData)
    const [data, setData] = useState(
        tableData
            .sort((a, b) => Number(a.inventoryTotal) - Number(b.inventoryTotal))
            .reverse()
    );
    const [desc, setDesc] = useState(true);
    const [sizes, setSizes] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [categoryType, setCategoryType] = useState("");

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        setSizes(getSizesByType(productType));
        setData(
            [...tableData]
                .sort((a, b) => Number(a.total) - Number(b.total))
                .reverse()
        );
        setCategoryType("");
    }, [productType, tableData]);

    useEffect(() => {
        const newData = [...data].reverse();
        setData(newData);
    }, [desc]);

    useEffect(() => {
        if (categoryType && productType) {
            const filterTableData = tableData.filter((p) =>
                p.sku?.includes(categoryType)
            );
            if (filterTableData.length > 0) {
                const newData = [...filterTableData].reverse();
                setData(newData);
            } else {
                setData([]);
            }
        } else {
            setData(
                [...tableData].sort(
                    (a, b) =>
                        Number(a.inventoryTotal) - Number(b.inventoryTotal)
                )
            );
        }
    }, [categoryType, categoryTypes, productType]);

    return (
        <>
            <TextField
                select
                label="Select Product Category"
                value={categoryType}
                onChange={(e) => setCategoryType(e.target.value)}
                variant="outlined"
                className="select"
                fullWidth
            >
                {categoryTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <br />
            <br />
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Image</TableCell>
                            <TableCell>SKU</TableCell>
                            <TableCell>Name</TableCell>
                            {sizes.map((i, k) => (
                                <TableCell key={k}>
                                    Size : {i.toString()}
                                </TableCell>
                            ))}
                            <TableCell onClick={() => setDesc(!desc)}>
                                <div style={{ display: "flex" }}>
                                    Total
                                    <div>
                                        {desc ? (
                                            <ArrowDownward />
                                        ) : (
                                            <ArrowUpward />
                                        )}
                                    </div>
                                </div>
                            </TableCell>
                            {/* <TableCell>Inventory Stock</TableCell> */}
                            <TableCell>Order IDs</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? data.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                              )
                            : data
                        ).map((row, index) => (
                            <Row key={index} row={row} sizes={sizes} />
                        ))}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                style={{ overflow: "inherit" }}
                                rowsPerPageOptions={[
                                    5,
                                    10,
                                    25,
                                    { label: "All", value: -1 },
                                ]}
                                colSpan={3}
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "rows per page",
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
}
