import { Box, Button, CircularProgress, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { cancelDelhiveryShipment } from "../../actions/ordersActions";

const CancelDelhiveryShipment = () => {
  const [awb, setAwb] = useState("");
  const [loading, setLoading] = useState(false);
  const [successRemark, setSuccessRemark] = useState("");
  const [errorRemark, setErrorRemark] = useState("");
  const handleCancel = async () => {
    setSuccessRemark("");
    setErrorRemark("");
    setLoading(true);
    const data = await cancelDelhiveryShipment(awb);
    if (data.status === 200) {
      const delhiveryResponse = data.body;
      console.log("delhivery response", delhiveryResponse);
      if (delhiveryResponse.status) {
        setSuccessRemark(delhiveryResponse.remark);
      } else {
        setErrorRemark(delhiveryResponse.error);
      }
    } else {
      setErrorRemark("Oops, Something Went Wrong. Contact Developer");
    }
    setLoading(false);
  };
  return (
    <div
      style={{
        marginTop: "10px",
      }}
    >
      <Stack direction={"row"} gap={2}>
        <TextField
          size="small"
          placeholder="Enter AWB number"
          value={awb}
          onChange={(e) => {
            setSuccessRemark("");
            setErrorRemark("");
            setAwb(e.target.value);
          }}
        />
        <Button variant="contained" color="error" onClick={handleCancel}>
          {loading ? (
            <CircularProgress
              size={20}
              style={{
                color: "white",
              }}
            />
          ) : (
            "Cancel"
          )}
        </Button>
      </Stack>
      <Box my={1}>
        {successRemark && (
          <p>
            waybill : <span style={{ fontWeight: "bold" }}>{awb}</span>,
            <span style={{ color: "green" }}>Success : {successRemark}</span>
          </p>
        )}
        {errorRemark && (
          <p>
            waybill : <span style={{ fontWeight: "bold" }}>{awb}</span>,{" "}
            <span style={{ color: "red" }}>Failure : {errorRemark}</span>
          </p>
        )}
      </Box>
    </div>
  );
};

export default CancelDelhiveryShipment;
