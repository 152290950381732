import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allInventory } from "../../actions/inventoryActions";
import {
  getWeeklySalesData,
  getThreeMonthsSalesData,
  getThreeUnfulfilledProductsData,
  getReturningProductsData,
} from "../../actions/ordersActions";
import { getPendingPOProductsData } from "../../actions/POActions";
import CreatePurchaseOrder from "../poWidgets/CreatePurchaseOrder";
import { PageSkeleton } from "../dashboard/helperComponents";
import { createPOAnalyticsData } from "../../utils/helpers";
import "./index.css";

const CreatePO = () => {
  const inventory = useSelector((state) => state.inventoryDetails.stock);
  const {
    weeklySalesData,
    threeMonthsSalesData,
    threeMonthsUnfulfilledProductsData,
    returningProductsData,
  } = useSelector((state) => state.orderDetails);
  const { pendingPOProductsData } = useSelector((state) => state.PO);
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  useEffect(() => {
    if (!inventory.length > 0) dispatch(allInventory());
    if (!weeklySalesData) dispatch(getWeeklySalesData());
    if (!threeMonthsSalesData) dispatch(getThreeMonthsSalesData());
    if (!threeMonthsUnfulfilledProductsData)
      dispatch(getThreeUnfulfilledProductsData());
    if (!returningProductsData) dispatch(getReturningProductsData());
    if (!pendingPOProductsData) dispatch(getPendingPOProductsData());
  }, [dispatch]);
  console.log("pendingPOProductsData", pendingPOProductsData);
  console.log("returningproductsdata", returningProductsData);
  useEffect(() => {
    if (
      inventory.length > 0 &&
      weeklySalesData &&
      threeMonthsSalesData &&
      threeMonthsUnfulfilledProductsData &&
      returningProductsData &&
      pendingPOProductsData
    ) {
      const poData = createPOAnalyticsData(
        inventory,
        weeklySalesData.sales_data,
        threeMonthsSalesData.sales_data,
        threeMonthsUnfulfilledProductsData.sales_data,
        returningProductsData.sales_data,
        pendingPOProductsData.sales_data
      );
      setData(poData);
    }
  }, [
    inventory,
    weeklySalesData,
    threeMonthsSalesData,
    threeMonthsUnfulfilledProductsData,
    returningProductsData,
    pendingPOProductsData,
  ]);

  return (
    <>
      {inventory.length === 0 ||
      !weeklySalesData ||
      !threeMonthsSalesData ||
      !threeMonthsUnfulfilledProductsData ||
      !returningProductsData ||
      !pendingPOProductsData ? (
        <PageSkeleton />
      ) : (
        <>{data && <CreatePurchaseOrder tableData={data} />}</>
      )}
    </>
  );
};

export default CreatePO;

// || !products.length > 0
