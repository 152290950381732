import React, { useEffect,useRef } from "react";
import { useDispatch } from "react-redux";
import { read, write, utils, writeFile } from "xlsx";
import { useState } from "react";
import { Box, Button, TextField, Typography, Stack } from "@mui/material";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { toast } from "react-toastify";
import { io } from "socket.io-client";

import { AWBSyncData, deliveredSyncData } from "../../actions/backendSync";
import { createXLforXB } from "../../utils/excelUtils";
import { CSVLink } from "react-csv";

const CSV2XLSXTesting = () => {
  const [excelData, setExcelData] = useState([]);
  const csvLink = useRef();

  // const xbData = createXLforXB(data)
  // setExcelData(xbData)

  // useEffect(() => {
  //     if (excelData.length > 0) {
  //         csvLink.current.link.click();
  //     }
  //   }, [excelData]);

  return (
    <div>
      <CSVLink
          data={excelData}
          ref={csvLink}
          target="_blank"
          filename="XB_DATA"
      />
    </div>
  );
};

export default CSV2XLSXTesting;
