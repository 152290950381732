import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getDeliveredReturnExchangeOrders,
  getDispatchedReturnExchangeOrders,
  getNprReturnExchangeOrders,
  getsyncedDispatchedReturnExchangeOrders,
} from "../../actions/ordersActions";
import DispatchedREOrders1 from "../ordersWidgets/returns/DispatchedREOrders1";
import RedirectLogin from "../RedirectLogin";
import { TextField, Box, MenuItem } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { hideLoading, showLoading } from "../../actions/loaderActions";
import { allProducts } from "../../actions/productsActions";
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const DispatchedRE = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { dispatchedREOrders,
    synceddispatchREOrders, 
    deliveredREOrders
  } = useSelector((state) => state.orderDetails);
  const loading = useSelector((state) => state.loader.loading);
  const [ orders, setOrders] = useState([])
  // console.log(deliveredREOrders)
  const [ searchFilter, setSearchFilter] = useState("")
  const [ orderType, setOrderType] = useState("")
  const [value, setValue] = useState(dayjs(new Date().toLocaleDateString()));
  const [ filteredOrders, setFilteredOrders] = useState([])

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!auth) return
    dispatch(showLoading())
    dispatch(getDispatchedReturnExchangeOrders());
    dispatch(getsyncedDispatchedReturnExchangeOrders())
    dispatch(getDeliveredReturnExchangeOrders())
    dispatch(getNprReturnExchangeOrders())
    dispatch(allProducts())
  }, [auth]);

  useEffect(() => {
    if(dispatchedREOrders.length>0 && synceddispatchREOrders.length>0 && deliveredREOrders.length>0){
      setOrders([...dispatchedREOrders,...synceddispatchREOrders,...deliveredREOrders].filter(x => !!x.orderNumber)) 
      dispatch(hideLoading())
    }
  },[dispatchedREOrders, synceddispatchREOrders, deliveredREOrders])

  useEffect(() => {
    if(searchFilter !== ""){
      setOrderType("")
      const filteredOrder = orders
      .filter(x => String(x.orderNumber).startsWith(searchFilter))
      if(filteredOrder){
        setFilteredOrders(filteredOrder)
      }else return
    }else{
      const filteredOrders = orders.filter(x => x.modified_at === new Date(value.$d).toLocaleDateString('en-in'))
      console.log(filteredOrders.map(x=> x.fulfillment_id))
      setFilteredOrders(filteredOrders)
  }
  },[searchFilter, orders, value])

  useEffect(() => {
    if(orderType !== ""){
      setSearchFilter("")
      if(orderType === "All"){
        setFilteredOrders(orders)
      }else if(orderType === "Already Exchanged Order"){
        const filterByType = orders.filter(x => x.tags?.includes("replacement"))
        if(filterByType.length>0) setFilteredOrders(filterByType)
      }else{
        const filterByType = orders.filter(x => x.type === orderType)
        if(filterByType.length>0) setFilteredOrders(filterByType)
      }
    }
  },[orderType, orders])

  useEffect(() => {
    if(!!value && orders.length>0){
      setSearchFilter("")
      setOrderType("")
      setFilteredOrders(orders.filter(x => x.modified_at === new Date(value.$d).toLocaleDateString("en-in")))
    }
  },[value, orders])

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {loading && (
            <div className="load-overlay">
              <h1>Please Wait...</h1>
            </div>
          )}
          <h1>Approved Return/Exchange Orders</h1>
          <div style={{display:"flex", alignItems:"flex-end"}}>
            <Box sx={{ display: "flex", paddingRight:"30px", alignItems: "flex-end", gap: 1 }}>
                <SearchIcon />
                <TextField
                id="input-with-sx"
                label="Search Order"
                variant="standard"
                value={searchFilter}
                onChange={(e) => setSearchFilter(e.target.value)}
                />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 20, width:"300px" }}>
              <TextField
                select
                label="Order Type"
                value={orderType}
                onChange={(e) => setOrderType(e.target.value)}
                variant="standard"
                className="select"
                fullWidth
              >
                {['All','Exchange','Return','Already Exchanged Order'].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box sx={{paddingLeft:"40px"}}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  variant="standard"
                  label="Approval Date"
                  inputFormat="DD/MM/YYYY"
                  value={value}
                  maxDate={dayjs(new Date().toLocaleDateString())}
                  onChange={handleChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </div>
          <br/>
          <div>
            <p>Total Approved Orders: {dispatchedREOrders.length 
              + synceddispatchREOrders.filter(x => !x.fulfillment_id.includes(".")).length
              + deliveredREOrders.filter(x => !x.fulfillment_id.includes(".")).length
              }</p>
            {filteredOrders.length > 0 ? (
              <DispatchedREOrders1 orders={filteredOrders} setSearchFilter={setSearchFilter}/>
            ) : (
              <h3>No Orders Found</h3>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default DispatchedRE;
