import React, { useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Stack,
   Chip, Checkbox, Backdrop, Modal, Fade, Button, Typography, TextField } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function MaterialModal(props) {
  const {
    name, code, setCode, setName, open, handleClose, handleSubmit, 
    openEdit, setInput, marked, filtered, handleDelChip, handleCheckClicked,
  } = props;
return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Enter the Fabric details
            </Typography>
            <TextField
              required
              disabled={openEdit}
              id="filled-basic"
              label="Code"
              sx={{ m: 0.5 }}
              variant="filled"
              value={code}
              onChange={(e) => {
                setCode(e.target.value.toUpperCase());
              }}
            />
            <TextField
              required
              id="filled-basic"
              label="Name"
              sx={{ m: 0.5 }}
              variant="filled"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <Button onClick={handleSubmit}>Submit</Button>

            <Paper>
              Set Colour
            <TableContainer sx={{ maxHeight: 440, maxWidth: 400 }}>
              <TextField
                id="filled-search"
                label="Search Color"
                variant="filled"
                onChange={(e) => {
                  setInput(e.target.value);
                }}
              />
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Check</TableCell>
                    <TableCell align="right">Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filtered.map((row) => (
                    <TableRow
                      key={row.code}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">
                        <Checkbox
                          checked={marked.some(
                            (item) => item.code === row.code
                          )}
                          onChange={() => handleCheckClicked(row)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </TableCell>
                      <TableCell align="right">{row.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack direction="row" spacing={1}>
              {marked.map((mark) => (
                <Chip label={mark.name} onDelete={() => handleDelChip(mark)} />
              ))}
            </Stack>
          </Paper>
          </Box>

          
        </Fade>
      </Modal>
    </div>
  );
}
