import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableFooter,
  TablePagination,
} from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import TablePaginationActions from "../../utils/TablePaginationActions";

const MaterialAmtTable = ({ materials }) => {
  console.log("materials", materials);
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    setTableData(
      materials.map((m) => ({
        name: `${m.fabricName} ${m.colorName}`,
        amt: m.amt,
        unitValue: m.unitValue,
        vendorNames: m.vendorNames,
      }))
    );
  }, [materials]);
  return (
    <div>
      <TableContainer sx={{ minWidth: 700, my: 4, px: 10 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell align="right">Material</TableCell>
              <TableCell align="right">Unit Value</TableCell>
              <TableCell align="right">Amount</TableCell>
              {tableData.length > 0 && tableData[0].vendorNames && (
                <TableCell align="right">Vendors</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? tableData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : tableData
            ).map((row) => (
              <TableRow
                key={row.code}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  ...
                </TableCell>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">{row.unitValue}</TableCell>
                <TableCell align="right">{row.amt}</TableCell>
                <TableCell align="right">{row.vendorNames}</TableCell>
                {/* <TableCell align="right">
                    <DeleteIcon
                      sx={{ m: 0.3 }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleOpenDel(row.code)}
                    />
                    <EditIcon
                      sx={{ m: 0.3 }}
                      onClick={() => handleOpenEdit(row, row.code, row.name)}
                      style={{ cursor: "pointer" }}
                    />
                  </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{ overflow: "inherit" }}
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MaterialAmtTable;
