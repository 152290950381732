import React, {useState} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MaterialModal from "../../../MaterialModal";
import ConfirmModal from "../../../ConfirmModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MaterialColorListTable = ({ data }) => {
  console.log("Material Colour List",data)
  const [code, setCode] = useState("");
  const [name, setName] = useState("");

  const [fabric, setFabric] = useState(data);

  const [enteredCodeDel, setEnterDel] = useState("");
  const [enteredCodeEdit, setEnterCodeEdit] = useState("");

  const [openDel, setOpenDel] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const showToastMessage = (msg,isError) => {
    if (isError) {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleCloseAdd = () => {
    setOpenAdd(false) 
    setName("")
    setCode("")
  };
  const handleCloseDel = () => {
    setOpenDel(false)
    setName("")
    setCode("")
  };
  const handleCloseEdit = () => {
    setOpenEdit(false)
    setName("")
    setCode("")
  };

  const handleOpenAdd = () => {
    setOpenAdd(true);
    setOpenEdit(false);
    setOpenDel(false);
  };
  const handleOpenEdit = (row, code, name) => {
    console.log("row", code, name);
    setEnterCodeEdit(code);
    setName(name);
    setCode(code);
    setOpenEdit(true);
    setOpenAdd(false);
    setOpenDel(false);
  };
  const handleOpenDel = (code) => {
    setEnterDel(code);
    setOpenDel(true);
    setOpenEdit(false);
    setOpenAdd(false);
  };

  const handleSubmitAdd = (event) => {
    console.log("Clicked add");
    event.preventDefault();
    const newData = {
      code,
      name,
    };
    if (fabric.find((fab) => fab.code === newData.code)) {
      showToastMessage("Already Present !", true);
    } else {
      setFabric((prev) => [...prev, newData]);
      showToastMessage("Succesfully Added !", false);
    }
    setCode("");
    setName("");
    setOpenAdd(false);
  };
  const handleSubmitEdit = (event) => {
    event.preventDefault();
    const newData = {
      code,
      name,
    };
    const updatedFabric = fabric.map((fab) => {
      if (fab.code === enteredCodeEdit) {
        return newData;
      } else {
        return fab;
      }
    });
    setFabric(updatedFabric);
    showToastMessage("Succesfully Edited !", false);
    setCode("");
    setName("");
    setOpenEdit(false);
  };
  const handleSubmitDel = () => {
    const filtered = fabric.filter(
      (material) => material.code != enteredCodeDel
    );
    setFabric(filtered);
    showToastMessage("Succesfully Deleted !", false);
    setOpenDel(false);
  };
  return (
    <div>
      <Paper>
      <TableContainer sx={{ maxHeight: 440, maxWidth:400 }}>
      <Table  stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Code</TableCell>
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">Configuration</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fabric.map((row) => (
            <TableRow
              key={row.code}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.code}
              </TableCell>
              <TableCell align="right">{row.name}</TableCell>
              <TableCell align="right">
                  <DeleteIcon
                    sx={{ m: 0.3 }}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleOpenDel(row.code)}
                  />
                  <EditIcon
                    sx={{ m: 0.3 }}
                    onClick={() => handleOpenEdit(row, row.code, row.name)}
                    style={{ cursor: "pointer" }}
                  />
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      </Paper>
    <Button variant="contained" onClick={handleOpenAdd}>
    Add
  </Button>
  {openEdit && (
        <MaterialModal
          name={name}
          code={code}
          open={openEdit}
          handleClose={handleCloseEdit}
          setName={setName}
          setCode={setCode}
          handleSubmit={handleSubmitEdit}
          openEdit={openEdit}
        />
      )}
      {openAdd && (
        <MaterialModal
          open={openAdd}
          handleClose={handleCloseAdd}
          setName={setName}
          setCode={setCode}
          handleSubmit={handleSubmitAdd}
          name={name}
          code={code}
        />
      )}
      {openDel && (
        <ConfirmModal
          open={openDel}
          handleClose={handleCloseDel}
          handleSubmitDel={handleSubmitDel}
        />
      )}
    </div>
  );
};

export default MaterialColorListTable;
