import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNDROrders } from "../../actions/ordersActions";
import ProgressBar from "../../ProgressBar";
import NDROrdersTable from "../ordersWidgets/NDROrdersTable";
import RedirectLogin from "../RedirectLogin";

const NDROrders = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const orderDetails = useSelector((state) => state.orderDetails);
  console.log(orderDetails.ndrOrders);

  useEffect(() => {
    if (auth) dispatch(getNDROrders());
  }, [dispatch, auth]);

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {!(orderDetails.ndrOrders && orderDetails.ndrOrders.length > 0) ? (
            <ProgressBar />
          ) : (
            <NDROrdersTable tableData={orderDetails.ndrOrders} />
          )}
        </>
      )}
    </>
  );
};

export default NDROrders;
