const initialState = {
  shopify_products: [],
  shopify_image_list: [],
  shopify_product_variants: [],
  shopify_orders_bulk_mail: [],
  shopify_orders_bulk_mail_loading: false,
  shopify_orders_bulk_mail_progress: "",
};

const shopifyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_PRODUCTS":
      return {
        ...state,
        shopify_products: action.data,
      };
    case "SHOPIFY_IMAGE_LIST":
      return {
        ...state,
        shopify_image_list: action.data,
      };
    case "SHOPIFY_PRODUCT_VARIANTS":
      return {
        ...state,
        shopify_product_variants: action.data,
      };
    case "SHOPIFY_ORDER_LIST_FOR_MAIL":
      return {
        ...state,
        shopify_orders_bulk_mail: action.data,
      };
    case "SHOW_BULK_ORDER_MAIL_LOADER":
      return {
        ...state,
        shopify_orders_bulk_mail_loading: true,
      };
    case "HIDE_BULK_ORDER_MAIL_LOADER":
      return {
        ...state,
        shopify_orders_bulk_mail_loading: false,
      };
    case "BULK_ORDER_MAIL_PROGRESS":
      return {
        ...state,
        shopify_orders_bulk_mail_progress: action.data,
      };
    default:
      return state;
  }
};

export default shopifyReducer;
