import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  allShopifyInventory,
  getPerDaySalesData,
  getTridiurnalSalesData,
  getWeeklySalesData,
} from "../../actions/ordersActions";
import SalesDataWithImage from "../salesWidgets/SalesDataWithImage";
import RedirectLogin from "../RedirectLogin";
import { allProducts } from "../../actions/productsActions";
import "./index.css";
import { allInventory } from "../../actions/inventoryActions";
import { PageSkeleton } from "../dashboard/helperComponents";
import SalesDataForPO from "../poWidgets/MaterialPO/SalesDataForPO";

const RawMaterialCalculator = () => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const {
    weeklySalesData,
    tridiurnalSalesData,
    shopifyInventory,
    perDaySalesData,
  } = useSelector((state) => state.orderDetails);
  const products = useSelector((state) => state.productDetails.products);

  const inventory = useSelector((state) => state.inventoryDetails.stock);

  useEffect(() => {
    if (!weeklySalesData && auth) {
      dispatch(getWeeklySalesData());
    }
    if (!tridiurnalSalesData) {
      dispatch(getTridiurnalSalesData());
    }
    if (!perDaySalesData) {
      dispatch(getPerDaySalesData());
    }
    if (!products.length > 0 && auth) dispatch(allProducts());
    if (!shopifyInventory.length > 0 && auth) dispatch(allShopifyInventory());
    if (!inventory.length > 0) dispatch(allInventory());
  }, [dispatch, auth]);

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {!tridiurnalSalesData ||
          !perDaySalesData ||
          !weeklySalesData ||
          !inventory.length > 0 ||
          !products.length > 0 ||
          !shopifyInventory.length > 0 ? (
            <PageSkeleton />
          ) : (
            <SalesDataForPO />
          )}
        </>
      )}
    </>
  );
};

export default RawMaterialCalculator;
