import { useEffect, useState } from "react";
import { createProductsTableData } from "../../utils/helpers";
import {
  productTypes,
  getSizesByType,
} from "../../utils/helperArrays";
import UpdateProductSizeTable from "../inventoryWidgets/UpdateProductSizeTable";
import UpdateProductModal from "../inventoryWidgets/AddStockModal";
import EditCostPriceModal from "../inventoryWidgets/EditCostPriceModal";
import {
  TextField,
  MenuItem,
  Button,
  Box,
  LinearProgress,
  Modal,
} from "@mui/material";
import "../../ProgressBar.css";
import { useSelector } from "react-redux";

const UpdateProductStock = () => {
  const inventoryData = useSelector(state => state.inventoryDetails.stock)
  // const {
  //   inventoryLoading,
  //   inventoryData,
  //   inventoryCostPriceModal,
  //   setInventoryCostPriceModal,
  //   setInventoryStock,
  // } = useContext(InventoryContext);
  const [productType, setProductType] = useState("");
  const [tableData, setTableData] = useState([]);
  const [productSizes, setProductSizes] = useState([]);
  const [searchedSKU, setSearchedSKU] = useState('')

  useEffect(() => {
    if (inventoryData.length > 0 && productType) {
      const temp = getSizesByType(productType);
      setProductSizes(temp);
      const data = createProductsTableData(inventoryData, productType);
      setTableData(data);
    }
  }, [inventoryData, productType]);

  const handleSearchSKU = (e) => {
    e.preventDefault()
    const text = e.target.value
    setSearchedSKU(text)
    if (text.length > 1) {
        const filteredSKU = tableData.filter(p => p.SKU.toString().match(text.toUpperCase()))
        if(filteredSKU){
          setTableData(filteredSKU)
        }else{
          setTableData([])
        }
    } else {
        const data = createProductsTableData(inventoryData, productType);
        setTableData(data)
    }
  };

  return (
    <>
      <br />
        <>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 20 }}>
          <TextField
            select
            label="Select Product Type"
            value={productType}
            onChange={(e) => setProductType(e.target.value)}
            variant="outlined"
            className="select"
            fullWidth
          >
            {productTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <br/>
        {productType && (
          <Box sx={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <TextField
                label="Search SKU"
                type="text"
                variant="outlined"
                size="small"
                value={searchedSKU}
                onChange={(e) => handleSearchSKU(e)}
            />
          </Box>
        )}
        </>
      <br />
      {productType && tableData.length > 0 && productSizes.length > 0 ? (
        <UpdateProductSizeTable data={tableData} productSizes={productSizes} />
      ) : (
        ""
      )}
      
      {/* <Modal
        open={inventoryCostPriceModal}
        onClose={() => {
          setInventoryCostPriceModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <EditCostPriceModal />
      </Modal> */}
    </>
  );
};

export default UpdateProductStock;
