import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import { getCustomerInformation } from "../../actions/customerActions";
import { WinnerTableSkeletonComponent } from "../dashboard/helperComponents";



const Row = ({ row, customerInfoProgress, setCustomerInfoProgress, index }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  useEffect(() => {
    (async () => {
      if (customerInfoProgress !== index) return;
      setLoading(true);
      const customerInfo = await getCustomerInformation(row.id);
      if (customerInfo.statusCode === 200) {
        const firstName = customerInfo.data.first_name || "";
        const lastName = customerInfo.data.last_name || "";
        const name = firstName + " " + lastName;
        setData({
          id: row.id,
          value: row.value,
          name,
        });
      } else {
        setData({
          id: row.id,
          value: row.value,
          name: "500 server error",
        });
      }
      setLoading(false);
      setCustomerInfoProgress(index + 1);      
    })();
  }, [customerInfoProgress, row, index]);

  useEffect (() => {
    console.log("Loading State:", loading)
  },[loading])


  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      {loading ? (
        
          <WinnerTableSkeletonComponent/>
        
      ) : (
        <React.Fragment>
          <TableCell className="cxt-id" sx={{"@media (max-width: 600px)":{width:"15px",maxWidth:"15px",wordWrap:"break-word"}}}>{data.id}</TableCell>
          <TableCell className="cxt-name" sx={{"@media (max-width: 600px)":{width:"10px",maxWidth:"10px", wordWrap:"break-word"}}}>{data.name ? data.name : "no name"}</TableCell>
          <TableCell className="cxt-likes" sx={{"@media (max-width: 600px)":{width:"5px",maxWidth:"5px"}}}>{data.value}</TableCell>
        </React.Fragment>
      )}
    </TableRow>
  );
};

const WinnerTable = ({ data }) => {
  const [winnerList, setWinnerList] = useState([]);
  const [customerInfoProgress, setCustomerInfoProgress] = useState(0);
  const [loading, setLoading] = useState(true);

  function shuffleArray(array) {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  }
  useEffect(() => {
    findWinner();
  }, [data]);
  const findWinner = () => {
    setCustomerInfoProgress(0);
    // data is already sorted from highest to lowest
    const selectedObjects = [];
    let currentIndex = 0;
    //check if selected has reaches 10 and index doesnt exceed our data
    while (selectedObjects.length < 10 && currentIndex < data.length) {
      // take highest value
      const currentValue = data[currentIndex].value;
      // Filter objects with the current value
      const currentGroup = data.filter((item) => item.value === currentValue);
      const shuffled = shuffleArray(currentGroup);
      if (selectedObjects.length + currentGroup.length <= 10) {
        // if it adds up to 10 , randomize and add it
        selectedObjects.push(...shuffled);
      } else {
        // Otherwise, add as much customer as u can
        const remainingCount = 5 - selectedObjects.length;
        selectedObjects.push(...shuffled.slice(0, remainingCount));
      }
      // Move to the next value group
      currentIndex += currentGroup.length;
    }
    setWinnerList(selectedObjects);
    setLoading(false);
  };
  return (
    <div>
      {/* <h3>Winner List</h3> */}
      <Button
        variant="contained"
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          gap: 1,
          background:"purple"
        }}
        onClick={findWinner}
      >
        Lucky Draw{" "}
        <span>
          <ShuffleIcon />
        </span>
      </Button>
      <br></br>
      <TableContainer 
         component={Paper}
         sx={{
         border: "1px solid #e0e0e0",
         borderRadius: "8px", // Rounded corners
         boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
         marginBottom: "1rem",
         maxWidth: "100%",
         overflowX: "auto", // Horizontal scrolling if needed
         "@media (max-width: 600px)": {
          width:"390px"
        }
        }}
      >
        <Table sx={{ minWidth: "380px" ,
                     "@media (max-width: 600px)": {
                      width:"380px"
                    }
      }}>
          <TableHead sx={{ backgroundColor: "#3c4040" }}>
            <TableRow>
              <TableCell sx={{ padding: 1.5,color:"white","@media (max-width: 600px)":{ maxWidth:"15px" }}}>CustomerID</TableCell>
              <TableCell sx={{ padding: 1.5,color:"white" }}>Name</TableCell>
              <TableCell sx={{ padding: 1.5,color:"white" }}>Likes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {winnerList.length === 0 ? (
             <WinnerTableSkeletonComponent/>
            ) : (
            winnerList.map((d, i) => (
              <Row
                key={i}
                row={d}
                customerInfoProgress={customerInfoProgress}
                setCustomerInfoProgress={setCustomerInfoProgress}
                index={i}
              />
            ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default WinnerTable;
