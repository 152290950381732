import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const ChangeView = ({ changeView, view, list }) => {
  return (
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">View</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={view}
        onChange={(e) => changeView(e.target.value)}
      >
        {list.map((l) => (
          <FormControlLabel key={l} value={l} control={<Radio />} label={l} />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default ChangeView;
