import {
    Box,
    Button,
    Collapse,
    Modal,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getCustomerInformation,
    getShopifyCustomerOrders,
} from "../../actions/customerActions";
import TablePaginationActions from "../../utils/TablePaginationActions";
import CustomerOrders from "./CustomerOrders";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    p: 4,
};

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    // customer detail states
    const [openCustomerDetail, setOpenCustomerDetail] = useState(false);
    const [customer, setCustomer] = useState({});

    const handleOpen = async () => {
        const data = await getCustomerInformation(row.id);
        if (data === 500 || data === 403) return;
        setCustomer(data.data);
        setOpenCustomerDetail(true);
    };
    const handleClose = () => setOpenCustomerDetail(false);
    return (
        <React.Fragment>
            <TableRow
                sx={{
                    "& > *": {
                        borderBottom: "unset",
                        backgroundColor:
                            row.id === 6758706741535 ? "lightgreen" : "white",
                    },
                }}
            >
                <TableCell component="th" scope="row">
                    {row.id}
                </TableCell>
                <TableCell
                    onClick={handleOpen}
                    align="left"
                    sx={{
                        cursor: "pointer",
                        "&:hover": {
                            textDecoration: "underline",
                            color: "blue",
                        },
                    }}
                >
                    {row.first_name} {row.last_name}
                </TableCell>
                <TableCell align="left">
                    {new Date(row.created_at).toLocaleString()}
                </TableCell>
                <TableCell align="left">
                    {new Date(row.updated_at).toLocaleString()}
                </TableCell>
                <TableCell align="left">{row.last_order_id}</TableCell>

                <TableCell align="left">{row.orders_count}</TableCell>
                <TableCell align="left">
                    <Button
                        variant="contained"
                        onClick={() => {
                            setOpen(!open);
                        }}
                    >
                        Orders
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <CustomerOrders customer={row} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            {/* customer detail modal */}
            {customer && (
                <Modal
                    open={openCustomerDetail}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <h4>
                            {customer.first_name} {customer.last_name}
                        </h4>
                        <p>customer id : {customer.id}</p>
                        <p>Email : {customer.email || "not given"}</p>
                        <p>Phone : {customer.phone || "not given"}</p>
                        {customer.addresses && (
                            <div>
                                <h4>Addresses :</h4>
                                {customer.addresses.map((c, i) => (
                                    <p key={i}>
                                        {c.address1} , {c.province}-{c.zip}
                                    </p>
                                ))}
                            </div>
                        )}
                    </Box>
                </Modal>
            )}
        </React.Fragment>
    );
}

const CustomerTable = ({ customers, handleButton }) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const dispatch = useDispatch();
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClick = (customer) => {
        dispatch(getShopifyCustomerOrders(customer.id));
    };

    useEffect(() => {
        const sorted = customers.sort(
            (a, b) => b.orders_count - a.orders_count
        );
        setData(sorted);
    }, [customers]);

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Customer Id</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Created At</TableCell>
                            <TableCell align="left">Updated At</TableCell>
                            <TableCell align="left">Last Order Id</TableCell>
                            <TableCell align="left">Order Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? data.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                              )
                            : data
                        ).map((row, i) => (
                            <Row key={i} row={row} handleClick={handleClick} />
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                style={{ overflow: "inherit" }}
                                rowsPerPageOptions={[
                                    5,
                                    10,
                                    25,
                                    { label: "All", value: -1 },
                                ]}
                                colSpan={3}
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "rows per page",
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default CustomerTable;
