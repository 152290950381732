import { lambdaFetch } from "../utils/awsUtils";

export const showLoader = () => {
    return {
      type: "SHOW_LOADER",
    };
};

export const hideLoader = () => {
    return {
      type: "HIDE_LOADER",
    };
};

export const getRtoData = (dataBody) => {
    async function getData() {
      const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/dashboard/rto_analytics";
  
      let fetching = true;
      let orders = [];
      let key = null;
      while (fetching) {
        const response = await lambdaFetch(URL, {
          method: "POST",
          body: JSON.stringify({...dataBody,key}),
        });
        if (response.status === 403) {
          fetching = false;
          return 403;
        }
        const data = await response.json();
        if (data.statusCode === 200) {
          const processingData = data.body;
          orders.push(...processingData);
          key = data.key;
          if (!key) {
            fetching = false;
          }
        } else {
          orders = [];
          fetching = false;
        }
      }
      return orders;
    }
    return (dispatch) => {
      getData()
        .then((data) => {
        //   console.log("rto orders", data);
          if (data === 403) {
            // dispatch(showUnauthorizedModal());
            return;
          }
          if (data.length > 0) {
            dispatch({
              type: "RTO_ORDERS",
              data,
            });
          } else {
            console.log("Error In Getting RTO Orders");
            dispatch(hideLoader());
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch(hideLoader());
        });
    };
};

export const getRtoInTansitData = (dataBody) => {
    async function getData() {
      const URL =
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/dashboard/rto_analytics";
  
      let fetching = true;
      let orders = [];
      let key = null;
      while (fetching) {
        const response = await lambdaFetch(URL, {
          method: "POST",
          body: JSON.stringify({...dataBody,key}),
        });
        if (response.status === 403) {
          fetching = false;
          return 403;
        }
        const data = await response.json();
        if (data.statusCode === 200) {
          const processingData = data.body;
          orders.push(...processingData);
          
          key = data.key;
          if (!key) {
            fetching = false;
          }
        } else {
          orders = [];
          fetching = false;
        }
      }
      return orders;
    }
    return (dispatch) => {
    //   dispatch(showLoading());
      getData()
        .then((data) => {
            // console.log("rto_intransit orders", data);
          if (data === 403) {
            // dispatch(showUnauthorizedModal());
            return;
          }
          if (data.length > 0) {
            dispatch({
              type: "RTO_INTRANSIT_ORDERS",
              data,
            });
          } else {
            console.log("Error In Getting RTO Intransit Orders");
            dispatch(hideLoader());
          }
        })
        .catch((error) => {
            console.log(error);
            dispatch(hideLoader());
        });
    };
  };

export const getRtoDeliveredData = (dataBody) => {
  async function getData() {
    const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/dashboard/rto_analytics";

    let fetching = true;
    let orders = [];
    let key = null;
    while (fetching) {
    const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify({...dataBody,key}),
    });
    if (response.status === 403) {
        fetching = false;
        return 403;
    }
    const data = await response.json();
    if (data.statusCode === 200) {
        const processingData = data.body;
        orders.push(...processingData);
        key = data.key;
        if (!key) {
        fetching = false;
        }
    } else {
        orders = [];
        fetching = false;
    }
    }
    return orders;
}
return (dispatch) => {
    // dispatch(showLoading());
    getData()
    .then((data) => {
        if (data === 403) {
        // console.log("rto delivered orders", data);
        // dispatch(showUnauthorizedModal());
        return;
        }
        if (data.length > 0) {
        dispatch({
            type: "RTO_DELIVERED_ORDERS",
            data,
        });
        } else {
        console.log("Error In Getting RTO Delivered Orders");
        dispatch(hideLoader());
        }
    })
    .catch((error) => {
        console.log(error);
        dispatch(hideLoader());
    });
};
};

export const getForwardDeliveredData = (dataBody) => {
  async function getData() {
      const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/dashboard/monthly_analytics";
  
      let fetching = true;
      let orders = [];
      let key = null;
      while (fetching) {
      const response = await lambdaFetch(URL, {
          method: "POST",
          body: JSON.stringify({...dataBody,key}),
      });
      if (response.status === 403) {
          fetching = false;
          return 403;
      }
      const data = await response.json();
      if (data.statusCode === 200) {
          const processingData = data.body;
          orders.push(...processingData);
          key = data.key;
          if (!key) {
          fetching = false;
          }
      } else {
          orders = [];
          fetching = false;
      }
      }
      return orders;
  }
  return (dispatch) => {
      // dispatch(showLoading());
      getData()
      .then((data) => {
          if (data === 403) {
          // console.log("rto delivered orders", data);
          // dispatch(showUnauthorizedModal());
          return;
          }
          if (data.length > 0) {
          dispatch({
              type: "FORWARD_DELIVERED_ORDERS",
              data,
          });
          } else {
          console.log("Error In Getting Delivered Orders");
          dispatch(hideLoader());
          }
      })
      .catch((error) => {
          console.log(error);
          dispatch(hideLoader());
      });
  };
};

export const getForwardInTransitData = (dataBody) => {
  async function getData() {
      const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/dashboard/monthly_analytics";
  
      let fetching = true;
      let orders = [];
      let key = null;
      while (fetching) {
      const response = await lambdaFetch(URL, {
          method: "POST",
          body: JSON.stringify({...dataBody,key}),
      });
      if (response.status === 403) {
          fetching = false;
          return 403;
      }
      const data = await response.json();
      if (data.statusCode === 200) {
          const processingData = data.body;
          orders.push(...processingData);
          key = data.key;
          if (!key) {
          fetching = false;
          }
      } else {
          orders = [];
          fetching = false;
      }
      }
      return orders;
  }
  return (dispatch) => {
      // dispatch(showLoading());
      getData()
      .then((data) => {
          if (data === 403) {
          // console.log("rto delivered orders", data);
          // dispatch(showUnauthorizedModal());
          return;
          }
          if (data.length > 0) {
          dispatch({
              type: "FORWARD_INTRANSIT_ORDERS",
              data,
          });
          } else {
          console.log("Error In Getting In-Transit Orders");
          dispatch(hideLoader());
          }
      })
      .catch((error) => {
          console.log(error);
          dispatch(hideLoader());
      });
  };
};

export const getForwardNdrData = (dataBody) => {
  async function getData() {
      const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/dashboard/monthly_analytics";
  
      let fetching = true;
      let orders = [];
      let key = null;
      while (fetching) {
      const response = await lambdaFetch(URL, {
          method: "POST",
          body: JSON.stringify({...dataBody,key}),
      });
      if (response.status === 403) {
          fetching = false;
          return 403;
      }
      const data = await response.json();
      if (data.statusCode === 200) {
          const processingData = data.body;
          orders.push(...processingData);
          key = data.key;
          if (!key) {
          fetching = false;
          }
      } else {
          orders = [];
          fetching = false;
      }
      }
      return orders;
  }
  return (dispatch) => {
      // dispatch(showLoading());
      getData()
      .then((data) => {
          if (data === 403) {
          // console.log("rto delivered orders", data);
          // dispatch(showUnauthorizedModal());
          return;
          }
          if (data.length > 0) {
          dispatch({
              type: "FORWARD_NDR_ORDERS",
              data,
          });
          } else {
          console.log("Error In Getting NDR Orders");
          dispatch(hideLoader());
          }
      })
      .catch((error) => {
          console.log(error);
          dispatch(hideLoader());
      });
  };
};

export const getMonthlyRTOData = (dataBody) => {
  async function getData() {
      const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/dashboard/rto_monthly_analytics";
  
      let fetching = true;
      let orders = [];
      let key = null;
      while (fetching) {
      const response = await lambdaFetch(URL, {
          method: "POST",
          body: JSON.stringify({...dataBody,key}),
      });
      if (response.status === 403) {
          fetching = false;
          return 403;
      }
      const data = await response.json();
      if (data.statusCode === 200) {
          const processingData = data.body;
          orders.push(...processingData);
          key = data.key;
          if (!key) {
          fetching = false;
          }
      } else {
          orders = [];
          fetching = false;
      }
      }
      return orders;
  }
  return (dispatch) => {
      // dispatch(showLoading());
      getData()
      .then((data) => {
          if (data === 403) {
          // console.log("rto delivered orders", data);
          // dispatch(showUnauthorizedModal());
          return;
          }
          if (data.length > 0) {
          dispatch({
              type: "MONTHLY_RTO_ORDERS",
              data,
          });
          } else {
          console.log("Error In Getting RTO Orders");
          dispatch(hideLoader());
          }
      })
      .catch((error) => {
          console.log(error);
          dispatch(hideLoader());
      });
  };
};

export const getMonthlyRtoIntransitData = (dataBody) => {
  async function getData() {
      const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/dashboard/rto_monthly_analytics";
  
      let fetching = true;
      let orders = [];
      let key = null;
      while (fetching) {
      const response = await lambdaFetch(URL, {
          method: "POST",
          body: JSON.stringify({...dataBody,key}),
      });
      if (response.status === 403) {
          fetching = false;
          return 403;
      }
      const data = await response.json();
      if (data.statusCode === 200) {
          const processingData = data.body;
          orders.push(...processingData);
          key = data.key;
          if (!key) {
          fetching = false;
          }
      } else {
          orders = [];
          fetching = false;
      }
      }
      return orders;
  }
  return (dispatch) => {
      // dispatch(showLoading());
      getData()
      .then((data) => {
          if (data === 403) {
          // console.log("rto delivered orders", data);
          // dispatch(showUnauthorizedModal());
          return;
          }
          if (data.length > 0) {
          dispatch({
              type: "MONTHLY_RTO_INTRANSIT_ORDERS",
              data,
          });
          } else {
          console.log("Error In Getting RTO In-Transit Orders");
          dispatch(hideLoader());
          }
      })
      .catch((error) => {
          console.log(error);
          dispatch(hideLoader());
      });
  };
};

export const getMonthlyRtoDeliveredData = (dataBody) => {
  async function getData() {
      const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/dashboard/rto_monthly_analytics";
  
      let fetching = true;
      let orders = [];
      let key = null;
      while (fetching) {
      const response = await lambdaFetch(URL, {
          method: "POST",
          body: JSON.stringify({...dataBody,key}),
      });
      if (response.status === 403) {
          fetching = false;
          return 403;
      }
      const data = await response.json();
      if (data.statusCode === 200) {
          const processingData = data.body;
          orders.push(...processingData);
          key = data.key;
          if (!key) {
          fetching = false;
          }
      } else {
          orders = [];
          fetching = false;
      }
      }
      return orders;
  }
  return (dispatch) => {
      // dispatch(showLoading());
      getData()
      .then((data) => {
          if (data === 403) {
          // console.log("rto delivered orders", data);
          // dispatch(showUnauthorizedModal());
          return;
          }
          if (data.length > 0) {
          dispatch({
              type: "MONTHLY_RTO_DELIVERED_ORDERS",
              data,
          });
          } else {
          console.log("Error In Getting RTO Delivered Orders");
          dispatch(hideLoader());
          }
      })
      .catch((error) => {
          console.log(error);
          dispatch(hideLoader());
      });
  };
};