import { Container, MenuItem, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { productTypes } from "../../utils/helperArrays";

import POAnalyticsTable from "./POAnalyticsTable";
import { LocalStorageModal } from "./LocalStorageModal";

const CreatePurchaseOrder = ({ tableData }) => {
  let initial;

  initial = localStorage?.getItem("selectedPOProducts")
    ? JSON.parse(localStorage.getItem("selectedPOProducts"))
    : [];
  const [inventoryChoosen, setInventoryChosen] = useState(null);
  const [productType, setProductType] = useState("");
  const [localStoragePrompt, setLocalStoragePrompt] = useState(false);

  const selectedProducts = useSelector((state) => state.PO.products);
  // console.log(selectedProducts);

  //functions
  const handleProductChange = (type) => {
    if (selectedProducts.length > 0) {
      setLocalStoragePrompt(true);
    } else {
      setProductType(type);
    }
  };

  useEffect(() => {
    if (productType) {
      const datatobeshown = tableData.filter((p) => p.type === productType);
      setInventoryChosen(datatobeshown);
    } else {
      setInventoryChosen(null);
    }
  }, [productType, tableData]); //[productCategoryChosen,vendorChosen]

  return (
    <div
      style={{
        marginTop: 5,
        background: "ghostwhite",
        padding: "24px",
        paddingBottom: "70px",
        borderRadius: "15px",
      }}
    >
      <Stack direction={"row"} gap={2}>
        <TextField
          select
          label="Select Product Type"
          value={productType}
          onChange={(e) => {
            handleProductChange(e.target.value);
          }}
          variant="outlined"
          className="select"
          fullWidth
          style={{ background: "white" }}
          size="small"
        >
          {productTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      {inventoryChoosen && (
        <POAnalyticsTable
          tableData={inventoryChoosen}
          productType={productType}
          selectedPO={initial}
        />
      )}
      {/* <POShop inventory={inventoryChoosen} /> */}
      {localStoragePrompt ? (
        <LocalStorageModal
          open={localStoragePrompt}
          setLocalStoragePrompt={setLocalStoragePrompt}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default CreatePurchaseOrder;
