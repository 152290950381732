import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProcessingOrdersData } from "../../actions/ordersActions";
import XpressBeesDispatchTable from "../ordersWidgets/XpressBeesDispatchTable";
import RedirectLogin from "../RedirectLogin";
import { Backdrop, Box } from "@mui/material";

const XpressBeesDispatch = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { processingOrders } = useSelector((state) => state.orderDetails);

  useEffect(() => {
    if (processingOrders.length === 0 && auth) {
      dispatch(getProcessingOrdersData());
    }
  }, [dispatch, processingOrders, auth]);

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {processingOrders.length === 0 && 
              (
              <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={processingOrders.length === 0}
              >
                  <h1>Loading. Please Wait...</h1>
              </Backdrop>
          )}
          <Box sx={{display:"flex",justifyContent:"center"}}>
            <h1 className="center">Fulfill XpressBees Orders</h1>
          </Box>
          <XpressBeesDispatchTable tableData={processingOrders} />
        </>
      )}
    </>
  );
};

export default XpressBeesDispatch;
