import React from 'react'
import { useState, useEffect } from "react";
import {
    Box,
    Collapse,
    IconButton,
    Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Typography,Paper,TableFooter,TablePagination,
    Button,
    Chip,
    Card,
    CardContent,
    TextField,
    MenuItem
} from "@mui/material";
import { tagsArray } from '../../utils/helperArrays';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
}

function Row({ row, index }) {
    const [open, setOpen] = useState(false);
  
    return (
      <>
        <TableRow
          sx={{
            bgcolor: row.status
              ? "rgb(74, 237, 79)"
              : index % 2 === 0
              ? "rgb(225,220,225)"
              : "rgb(245,245,245)",
  
            "& > *": { borderBottom: "unset" },
          }}
        >
          <TableCell component="th" scope="row">
            {row.order}
          </TableCell>
          <TableCell align="right">{row.date}</TableCell>
          <TableCell align="right">{row.status || "pending"}</TableCell>
          <TableCell align="right">{row.tag ? row.tag : "Shopify"}</TableCell>
          <TableCell align="right">
            <Chip label={row.payment} color="secondary" />
          </TableCell>
          <TableCell align="right">{row.name}</TableCell>
          <TableCell align="right">{row.total}</TableCell>
          <TableCell align="right">{row.products.length} Items</TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Product Details
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>SKU</TableCell>
                      <TableCell align="right">Size</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.products.map((productRow) => (
                      <TableRow key={productRow.sku}>
                        <TableCell component="th" scope="row">
                          {productRow.title}
                        </TableCell>
                        <TableCell>{productRow.sku}</TableCell>
                        <TableCell align="right">
                          {productRow.variant_title}
                        </TableCell>
                        <TableCell align="right">{productRow.quantity}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <h4>Shipping Address:</h4>
                <p>
                  Name: {row.address?.name}
                  <br />
                  Mobile: {row.address?.phone}
                  <br />
                  Address: {row.address?.address1}
                  <br />
                  {row.address?.address2}
                  <br />
                  {row.address?.city}
                  <br />
                  {row.address?.country}
                  <br />
                  {row.address?.zip}
                </p>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
}

const ViewAllOrdersTable = ({viewTableData}) => {
  const [data, setData] = useState(viewTableData)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tag, setTag] = useState("")

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (data.length > 0) {
      if(tag === "None" || tag ===""){
          setData(viewTableData);
      }else if(tag === "Shopify"){
          const filteredTagData = viewTableData.filter((item) => item.tag === "" || item.tag === "adp_order")
          setData(filteredTagData)
      }else if(tag === "Hypd" || tag === "Zaamo"){
          const filteredTagData = viewTableData.filter((item) => item.tag.toLowerCase().includes(tag.toLowerCase()))
          setData(filteredTagData)
      }else if(tag === "Wishlink"){
          const filteredTagData = viewTableData.filter((item) => item.tag.toLowerCase().includes(tag.toLowerCase()))
          setData(filteredTagData)
      }
    }
  },[viewTableData, tag])

  return (
    <>
    <br/>
    <TextField
        select
        label="Select A Tag"
        value={tag}
        onChange={(e) => setTag(e.target.value)}
        variant="outlined"
        className="select"
        style={{width:"500px"}}
    >
        {tagsArray.map((option) => (
            <MenuItem key={option} value={option}>
                {option}
            </MenuItem>
        ))}
    </TextField>
    <br/>
    <br/>
    <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
        <TableHead>
            <TableRow
            >
            <TableCell>Order Number</TableCell>
            <TableCell align="right">Date</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Payment Status</TableCell>
            <TableCell align="right">Order Tag</TableCell>
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right">Product</TableCell>
            <TableCell align="left">List</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {(rowsPerPage > 0
            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : data
            ).map((row, index) => (
            <Row key={index} row={row} index={index} />
            ))}

            {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
            </TableRow>
            )}
        </TableBody>
        <TableFooter>
            <TableRow>
            <TablePagination
                style={{overflow:"inherit"}}
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                inputProps: {
                    'aria-label': 'rows per page',
                },
                native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
            </TableRow>
        </TableFooter>
        </Table>
    </TableContainer>
    </>
  )
}

export default ViewAllOrdersTable