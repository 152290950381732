import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const TicketsAssignReport = ({ tickets, users }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const userStats = users.map((u) => {
      console.log("assign tickets", tickets);
      const newAssigned = tickets.filter(
        (t) =>
          "executive_id" in t &&
          t.executive_id &&
          "package_status" in t &&
          t.executive_id.includes(u) &&
          (t.package_status === "active#new" || t.package_status === "new")
      ).length;
      const totalOpen = tickets.filter(
        (t) =>
          "executive_id" in t &&
          t.executive_id &&
          "package_status" in t &&
          t.executive_id.includes(u) &&
          (t.package_status === "active#open" || t.package_status === "open")
      ).length;
      const totalOpenAndNotReplied = tickets.filter(
        (t) =>
          "executive_id" in t &&
          t.executive_id &&
          "package_status" in t &&
          t.executive_id.includes(u) &&
          (t.package_status === "active#open" || t.package_status === "open") &&
          !("lastSendedBy" in t)
      ).length;

      const totalOpenAndRepliedByCustomer = tickets.filter(
        (t) =>
          "executive_id" in t &&
          t.executive_id &&
          "package_status" in t &&
          t.executive_id.includes(u) &&
          (t.package_status === "active#open" || t.package_status === "open") &&
          t.lastSendedBy === "customer"
      ).length;

      const totalOpenAndRepliedByCustomerCare = tickets.filter(
        (t) =>
          "executive_id" in t &&
          t.executive_id &&
          "package_status" in t &&
          t.executive_id.includes(u) &&
          (t.package_status === "active#open" || t.package_status === "open") &&
          t.lastSendedBy === "customercare"
      ).length;

      const reopen = tickets.filter(
        (t) =>
          "executive_id" in t &&
          t.executive_id &&
          "package_status" in t &&
          t.executive_id.includes(u) &&
          (t.package_status === "active#reopen" ||
            t.package_status === "reopen")
      ).length;

      const totalReOpenAndRepliedByCustomer = tickets.filter(
        (t) =>
          "executive_id" in t &&
          t.executive_id &&
          "package_status" in t &&
          t.executive_id.includes(u) &&
          (t.package_status === "active#reopen" ||
            t.package_status === "reopen") &&
          t.lastSendedBy === "customer"
      ).length;

      const totalReOpenAndRepliedByCustomerCare = tickets.filter(
        (t) =>
          "executive_id" in t &&
          t.executive_id &&
          "package_status" in t &&
          t.executive_id.includes(u) &&
          (t.package_status === "active#reopen" ||
            t.package_status === "reopen") &&
          t.lastSendedBy === "customercare"
      ).length;

      const total = newAssigned + totalOpen + reopen;
      return {
        user: u,
        new: newAssigned,
        totalOpen,
        totalOpenAndNotReplied,
        totalOpenAndRepliedByCustomer,
        totalOpenAndRepliedByCustomerCare,
        totalReOpenAndRepliedByCustomer,
        totalReOpenAndRepliedByCustomerCare,
        reopen,
        total,
      };
    });
    setData(userStats);
  }, [tickets, users]);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          itemsCenter: "center",
          py: 2,
        }}
      >
        <h2>Report</h2>
      </Box>
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>new</TableCell>
            <TableCell>total open</TableCell>
            <TableCell>open & not replied</TableCell>
            <TableCell>replied by customer</TableCell>
            <TableCell>replied by customercare</TableCell>
            <TableCell>Total reopen</TableCell>
            <TableCell>reopen customer</TableCell>
            <TableCell>reopen customercare</TableCell>
            <TableCell>total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((d, key) => (
            <TableRow key={key}>
              <TableCell>{d.user}</TableCell>
              <TableCell>{d.new}</TableCell>
              <TableCell>{d.totalOpen}</TableCell>
              <TableCell>{d.totalOpenAndNotReplied}</TableCell>
              <TableCell>{d.totalOpenAndRepliedByCustomer}</TableCell>
              <TableCell>{d.totalOpenAndRepliedByCustomerCare}</TableCell>
              <TableCell>{d.reopen}</TableCell>
              <TableCell>{d.totalReOpenAndRepliedByCustomer}</TableCell>
              <TableCell>{d.totalReOpenAndRepliedByCustomerCare}</TableCell>
              <TableCell>{d.total}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TicketsAssignReport;
