import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TableFooter,
  TablePagination,
  Box,
  TextField,
  Stack,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MaterialModal from "../../../MaterialModal";
import ConfirmModal from "../../../ConfirmModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TablePaginationActions from "../../../utils/TablePaginationActions";

const ListTable = ({ data }) => {
  const [code, setCode] = useState("");
  const [name, setName] = useState("");

  const [tableData, setTableData] = useState([...data]);

  const [enteredCodeDel, setEnterDel] = useState("");
  const [enteredCodeEdit, setEnterCodeEdit] = useState("");

  const [openDel, setOpenDel] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const showToastMessage = (msg, isError) => {
    if (isError) {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
    setName("");
    setCode("");
  };
  const handleCloseDel = () => {
    setOpenDel(false);
    setName("");
    setCode("");
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
    setName("");
    setCode("");
  };

  const handleOpenAdd = () => {
    setOpenAdd(true);
    setOpenEdit(false);
    setOpenDel(false);
  };
  const handleOpenEdit = (row, code, name) => {
    console.log("row", code, name);
    setEnterCodeEdit(code);
    setName(name);
    setCode(code);
    setOpenEdit(true);
    setOpenAdd(false);
    setOpenDel(false);
  };
  const handleOpenDel = (code) => {
    setEnterDel(code);
    setOpenDel(true);
    setOpenEdit(false);
    setOpenAdd(false);
  };

  const handleSubmitAdd = (event) => {
    console.log("Clicked add");
    event.preventDefault();
    const newData = {
      code,
      name,
    };
    if (tableData.find((fab) => fab.code === newData.code)) {
      showToastMessage("Already Present !", true);
    } else {
      setTableData((prev) => [...prev, newData]);
      showToastMessage("Succesfully Added !", false);
    }
    setCode("");
    setName("");
    setOpenAdd(false);
  };
  const handleSubmitEdit = (event) => {
    event.preventDefault();
    const newData = {
      code,
      name,
    };
    const updatedFabric = tableData.map((fab) => {
      if (fab.code === enteredCodeEdit) {
        return newData;
      } else {
        return fab;
      }
    });
    setTableData(updatedFabric);
    showToastMessage("Succesfully Edited !", false);
    setCode("");
    setName("");
    setOpenEdit(false);
  };
  const handleSubmitDel = () => {
    const filtered = tableData.filter(
      (material) => material.code != enteredCodeDel
    );
    setTableData(filtered);
    showToastMessage("Succesfully Deleted !", false);
    setOpenDel(false);
  };
  return (
    <div>
      <Paper>
        <Stack
          direction={"row"}
          gap={2}
          my={2}
          sx={{ justifyContent: "space-between" }}
        >
          <TextField
            size="small"
            type="text"
            placeholder="search"
            variant="outlined"
          />
          <Button size="small" variant="contained" onClick={handleOpenAdd}>
            Add New
          </Button>
        </Stack>
        <TableContainer sx={{ minWidth: 700 }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Code</TableCell>
                <TableCell align="right">Name</TableCell>
                <TableCell align="right">Configuration</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? tableData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : tableData
              ).map((row) => (
                <TableRow
                  key={row.code}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.code}
                  </TableCell>
                  <TableCell align="right">{row.name}</TableCell>
                  <TableCell align="right">...</TableCell>
                  {/* <TableCell align="right">
                    <DeleteIcon
                      sx={{ m: 0.3 }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleOpenDel(row.code)}
                    />
                    <EditIcon
                      sx={{ m: 0.3 }}
                      onClick={() => handleOpenEdit(row, row.code, row.name)}
                      style={{ cursor: "pointer" }}
                    />
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  style={{ overflow: "inherit" }}
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
      {openEdit && (
        <MaterialModal
          name={name}
          code={code}
          open={openEdit}
          handleClose={handleCloseEdit}
          setName={setName}
          setCode={setCode}
          handleSubmit={handleSubmitEdit}
          openEdit={openEdit}
        />
      )}
      {openAdd && (
        <MaterialModal
          open={openAdd}
          handleClose={handleCloseAdd}
          setName={setName}
          setCode={setCode}
          handleSubmit={handleSubmitAdd}
          name={name}
          code={code}
        />
      )}
      {openDel && (
        <ConfirmModal
          open={openDel}
          handleClose={handleCloseDel}
          handleSubmitDel={handleSubmitDel}
        />
      )}
    </div>
  );
};

export default ListTable;
