import React, { useState } from "react";
import { Container, Stack, Box } from "@mui/material";
import MaterialFabricListTable from "./MaterialFabricListTable";
import MaterialColorListTable from "./MaterialColorListTable";
import FabColCombination from "./FabColCombination";

const MaterialList = ({ fabricList, colorList }) => {
  const [fabCol, setFabCol] = useState([]);
  const dataFabCol = (data) => {
    setFabCol(data);
  };
  console.log("FabCol", fabCol);
  return (
    <Container>
      <br />
      <br />
      <Box sx={{ margin: "auto", width: "max-content" }}>
        <Stack
          direction={"row"}
          gap={2}
          sx={{ justifyContent: "space-between", alignItems: "start" }}
        >
          <MaterialFabricListTable data={fabricList} />
          <MaterialColorListTable data={colorList} />
        </Stack>
      </Box>
      {/* <Box sx={{ margin: "auto", padding: "25px", width: 600 }}>
        <ColorChoose colors={colorList}/>
      </Box> */}
    </Container>
  );
};

export default MaterialList;
