import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getLastXBReverseAWB,
  getNewReturnExchangeOrders,
  getNewReturnExchangeOrdersByExecutiveId,
} from "../../actions/ordersActions";
import RedirectLogin from "../RedirectLogin";
import REOrders from "../ordersWidgets/REOrders";
import { Zoom } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ErrorIcon from "@mui/icons-material/Error";
import { hideFailure, hideSuccess } from "../../actions/loaderActions";
import {
  getInvalidEmailList,
  getUnsuccessfulEmailList,
} from "../../actions/SESActions";

const ApproveReturns = () => {
  const [emailList, setEmailList] = useState();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { returnExchangeOrders, invalidEmail, unsuccessfulEmail } = useSelector(
    (state) => state.orderDetails
  );
  const loading = useSelector((state) => state.loader.loading);
  const success = useSelector((state) => state.loader.success);
  const failure = useSelector((state) => state.loader.failure);
  useEffect(() => {
    if (returnExchangeOrders.length === 0 && auth)
      if (auth.name === "customercare") {
        dispatch(getNewReturnExchangeOrdersByExecutiveId());
      } else {
        dispatch(getNewReturnExchangeOrders());
      }
    if (invalidEmail.length === 0 && auth) dispatch(getInvalidEmailList());
    if (unsuccessfulEmail.length === 0 && auth)
      dispatch(getUnsuccessfulEmailList());
  }, [auth]);

  console.log("RE orders", returnExchangeOrders);
  useEffect(() => {
    if (success) {
      setTimeout(() => {
        dispatch(hideSuccess());
      }, 1000);
    }
    if (failure) {
      setTimeout(() => {
        dispatch(hideFailure());
      }, 1000);
    }
  }, [success, failure, dispatch]);

  useEffect(() => {
    const totalMail = invalidEmail.concat(unsuccessfulEmail);
    setEmailList(totalMail);
  }, [invalidEmail, unsuccessfulEmail]);
  console.log("orders", returnExchangeOrders);
  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {(loading || success || failure) && (
            <div className="load-overlay">
              {!success && !failure ? (
                <h1 style={{ position: "absolute" }}>Please Wait...</h1>
              ) : (
                ""
              )}
              <Zoom in={success}>
                <TaskAltIcon
                  sx={{
                    color: "#87CEEB",
                    fontSize: "400px",
                    position: "absolute",
                  }}
                />
              </Zoom>
              <Zoom in={failure}>
                <ErrorIcon
                  sx={{
                    color: "#f70000",
                    fontSize: "500px",
                    postion: "absolute",
                  }}
                />
              </Zoom>
            </div>
          )}
          <h1>Approve Returns/Exchange Requests</h1>
          <div>
            <p>New Returns/Exchanges: {returnExchangeOrders.length}</p>
            <REOrders orders={returnExchangeOrders} emailList={emailList} />
          </div>
        </>
      )}
    </>
  );
};
export default ApproveReturns;
