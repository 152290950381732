import AWS from "aws-sdk";
import crypto from "crypto-js";

export const getCredentials = async (token) => {
  const params = {
    IdentityPoolId: "ap-south-1:38bf1bbf-2492-4711-ad90-5c8a566a1a70",
    Logins: {
      "cognito-idp.ap-south-1.amazonaws.com/ap-south-1_jWHbH4sMv": token,
    },
  };
  return await new Promise((resolve, reject) => {
    AWS.config.credentials = new AWS.CognitoIdentityCredentials(params, {
      region: "ap-south-1",
    });
    AWS.config.credentials.getPromise().then(
      () => {
        // console.log("credentials", AWS.config.credentials);
        resolve();
      },
      (err) => {
        console.log("err for identity pool", err);
        reject();
      }
    );
  });
};

const getAwsAuthorization = (accessKey, secretKey, token, data, endpoint) => {
  const method = "POST";
  const service = "execute-api";
  // const host = "on1z9dj82d.execute-api.ap-south-1.amazonaws.com";
  const host = endpoint.replace("https://", "").split("/")[0];
  // const host = endpoint.replace("https://", "");
  const region = "ap-south-1";
  const requestParameters = "";

  // functions
  const getSignatureKey = (key, dateStamp, regionName, serviceName) => {
    const kDate = crypto.HmacSHA256(dateStamp, "AWS4" + key);
    const kRegion = crypto.HmacSHA256(regionName, kDate);
    const kService = crypto.HmacSHA256(serviceName, kRegion);
    const kSigning = crypto.HmacSHA256("aws4_request", kService);
    return kSigning;
  };
  const getAmzDate = (dateStr) => {
    const chars = [":", "-"];
    for (let i = 0; i < chars.length; i++) {
      while (dateStr.indexOf(chars[i]) !== -1) {
        dateStr = dateStr.replace(chars[i], "");
      }
    }
    dateStr = dateStr.split(".")[0] + "Z";
    return dateStr;
  };

  //amzdate
  const amzdate = getAmzDate(new Date().toISOString());
  const dateStamp = amzdate.split("T")[0];

  // Create a canonical request.
  //canonical URI
  // const canonicalUri = "/beta/SSSgetinventoryproducts";
  const httpRemoved = endpoint.replace("https://", "");
  const canonicalUri =
    "/" + httpRemoved.substring(httpRemoved.indexOf("/") + 1);
  const canonicalQuerystring = requestParameters;

  let payload = JSON.stringify(data);
  // let payload = JSON.stringify({
  //   storeId: "2",
  // });
  const payloadHash = crypto.SHA256(payload);

  const canonicalHeaders =
    "host:" +
    host +
    "\n" +
    "x-amz-content-sha256:" +
    payloadHash +
    "\n" +
    "x-amz-date:" +
    amzdate +
    "\n";
  const signedHeaders = "host;x-amz-content-sha256;x-amz-date";

  const canonicalRequest =
    method +
    "\n" +
    canonicalUri +
    "\n" +
    canonicalQuerystring +
    "\n" +
    canonicalHeaders +
    "\n" +
    signedHeaders +
    "\n" +
    payloadHash;

  // Use the canonical request and additional metadata to create a string for signing.
  const algorithm = "AWS4-HMAC-SHA256";
  const credentialScope =
    dateStamp + "/" + region + "/" + service + "/" + "aws4_request";
  const string_to_sign =
    algorithm +
    "\n" +
    amzdate +
    "\n" +
    credentialScope +
    "\n" +
    crypto.SHA256(canonicalRequest);

  // Derive a signing key from your AWS secret access key. Then use the signing key, and the string from the previous step, to create a signature.
  const signing_key = getSignatureKey(secretKey, dateStamp, region, service);

  const signature = crypto
    .HmacSHA256(string_to_sign, signing_key)
    .toString(crypto.enc.Hex);
  // Add the resulting signature to the HTTP request in a header or as a query string parameter
  const authorizationHeader =
    algorithm +
    " " +
    "Credential=" +
    accessKey +
    "/" +
    credentialScope +
    ", " +
    "SignedHeaders=" +
    signedHeaders +
    ", " +
    "Signature=" +
    signature;
  const headers = {
    "X-Amz-Date": amzdate,
    Authorization: authorizationHeader,
    "X-Amz-Content-Sha256": payloadHash,
    "X-Amz-Security-Token": token,
  };
  return headers;
};

export const lambdaFetch = async (URL, options = {}) => {
  const { method, headers, body } = options;
  const payload = !!body ? JSON.parse(body) : { company: "LBI" };
  let authorizationHeaders;
  await AWS.config.credentials.getPromise().then(
    () => {
      const accessKeyId = AWS.config.credentials.accessKeyId;
      const secretAccessKey = AWS.config.credentials.secretAccessKey;
      const sessionToken = AWS.config.credentials.sessionToken;
      const headers = getAwsAuthorization(
        accessKeyId,
        secretAccessKey,
        sessionToken,
        payload,
        URL
      );
      authorizationHeaders = headers;
    },
    (err) => {
      console.log("error in authorization", err);
      authorizationHeaders = null;
    }
  );
  if (!authorizationHeaders) return;
  const response = await fetch(URL, {
    method,
    headers: {
      ...authorizationHeaders,
    },
    body: JSON.stringify(payload),
  });
  return response;
};
