import NVD3Chart from "react-nvd3";
import * as d3 from 'd3'
import { useEffect, useMemo, useRef, useState } from "react";
import { Paper } from "@mui/material";

function RtoLineChart({rtoLineChartData}) {
    const [chartData,setChartData] = useState([])

    useEffect(() => {
        setChartData(rtoLineChartData)
    },[rtoLineChartData])

  return (
    <Paper elevation={12} style={{maxWidth:"650px",borderRadius:"30px"}}>
        <div style={{display:"flex",justifyContent:"space-between"}}>
            <h4 style={{margin:"0", padding:"10px 0px 5px 50px"}}>RTO Report</h4>
            <h4 style={{margin:"0", padding:"10px 30px 5px 0",color:"grey",fontSize:"x-small"}}>Last 15 Days</h4>
        </div>
        <div id='chartArea' style={{display:"flex",justifyContent:"center"}}>
            <NVD3Chart
                datum={chartData}
                type="lineChart"
                //   showControls={true}
                showLegend={true}
                useInteractiveGuideline={true}
                duration = "500"
                height = "300"
                width = "500"
                x="rto_declared_on"
                xScale={d3.time.scale()}
                xAxis={{
                    tickFormat: (d) => {
                    return d3.time.format("%Y-%m-%d")(new Date(d));
                    },
                    rotateLabels: -15
                }}
                y="total"
                yDomain={[0, 500]}
                interpolate="cardinal"
            />
        </div>
    </Paper>
  );
}

export default RtoLineChart;
