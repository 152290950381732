import { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import { Paper, Typography, Box } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

const FootwearPC = ({ stock }) => {
  const [infoData, setInfoData] = useState([]);
  const pieChart = useRef();

  useEffect(() => {
    const flats = stock
      .filter((x) => x.category === "Flats")
      .reduce((sum, i) => sum + i.count, 0);
    const mules = stock
      .filter((x) => x.category === "Mules")
      .reduce((sum, i) => sum + i.count, 0);
    const flatform = stock
      .filter((x) => x.category === "Flatform")
      .reduce((sum, i) => sum + i.count, 0);
    const platform = stock
      .filter((x) => x.category === "Platform")
      .reduce((sum, i) => sum + i.count, 0);
    const heels = stock
      .filter((x) => x.category === "Heels")
      .reduce((sum, i) => sum + i.count, 0);
    const boots = stock
      .filter((x) => x.category === "Boots")
      .reduce((sum, i) => sum + i.count, 0);
    const menshoes = stock
      .filter((x) => x.category === "Shoes")
      .reduce((sum, i) => sum + i.count, 0);
    const data = [
      { item: "Flats", count: flats, color: "#88F7E2" },
      { item: "Heels", count: heels, color: "#44D492" },
      { item: "Mules", count: mules, color: "#F5EB67" },
      { item: "Flatform", count: flatform, color: "#FFA15C" },
      { item: "Platform", count: platform, color: "#FA233E" },
      { item: "boots", count: boots, color: "#03A9F4" },
      { item: "men shoes", count: menshoes, color: "#cbbeb5" },
    ];

    setInfoData(data);
    const tweenDuration = 1050;
    const pieData = d3.layout.pie().value((d) => d.count)(data);
    const arc = d3.svg.arc().innerRadius(35).outerRadius(70);
    // const colors = d3.scaleOrdinal(['#88F7E2','#44D492','#F5EB67','#FFA15C','#FA233E'])
    const colors = d3.scale
      .ordinal()
      .range([
        "#88F7E2",
        "#44D492",
        "#F5EB67",
        "#FFA15C",
        "#FA233E",
        "#03A9F4",
        "#cbbeb5",
      ]);
    const svg = d3
      .select(pieChart.current)
      .attr("width", 400)
      .attr("height", 150)
      // .style('background-color','white')
      .append("g")
      .attr("transform", "translate(150,70)");
    let tooldiv = d3
      .select("#chartArea")
      .append("div")
      .style("visibility", "hidden")
      .style("position", "absolute")
      .style("background", "rgba(0, 0, 0, 0.7)")
      .style("color", "white")
      .style("padding", "13px")
      .style("border-radius", "10px");

    svg
      .append("g")
      .selectAll("path")
      .data(pieData)
      .enter()
      .append("path")
      // .join('path')
      //     .attr('d', arc)
      .attr("fill", (d, i) => colors(i))
      .on("mouseover", (e, d) => {
        tooldiv
          .style("visibility", "visible")
          .text(`${e.data.item} : ` + `${e.data.count}`);
      })
      .on("mousemove", (e, d) => {
        tooldiv
          .style("top", d3.event.pageY + "px")
          .style("left", d3.event.pageX + "px");
      })
      .on("mouseout", () => {
        tooldiv.style("visibility", "hidden");
      })
      .transition()
      .duration(function (d, i) {
        return i * 800;
      })
      .attrTween("d", function (d) {
        let i = d3.interpolate(d.startAngle + 0.1, d.endAngle);
        return function (t) {
          d.endAngle = i(t);
          return arc(d);
        };
      });
    // .attr('stroke','white')
  }, [stock]);

  return (
    <Paper
      id="chartArea"
      elevation={12}
      sx={{ borderRadius: "30px", maxWidth: "300px" }}
    >
      <h4
        style={{
          margin: "0",
          paddingTop: "30px",
          display: "flex",
          justifyContent: "center",
          color: "grey",
        }}
      >
        Footwear Inventory
      </h4>
      <svg ref={pieChart} style={{ margin: "15px 0" }}></svg>
      <div
        style={{
          columns: "2",
          transform: "translate(5px)",
          padding: "0 5px 0 5px",
        }}
      >
        {infoData.length > 0 ? (
          <>
            {infoData.map((x, index) => (
              <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
                <CircleIcon sx={{ color: x.color, fontSize: "small" }} />
                <Typography sx={{ fontSize: "medium", marginLeft: "10px" }}>
                  {x.item} ({x.count})
                </Typography>
              </Box>
            ))}
          </>
        ) : (
          ""
        )}
      </div>
      <br />
    </Paper>
  );
};

export default FootwearPC;
