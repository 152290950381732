import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    TableFooter,
    TablePagination,
    MenuItem,
    TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { allInventory } from "../../actions/inventoryActions";
import OpenMoreDetailsRE from "../ordersWidgets/OpenMoreDetailsRE";


function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </Box>
    );
}

function Row({ row, index }) {

    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row">
                    <OpenMoreDetailsRE product={row} />
                </TableCell>
            </TableRow>
        </>
    );
}

export default function ReReportTable({tableData,deliveredOrdersData}) {
    const [orders, setOrders] = useState([]);
    const [productsRE, setProductsRE] = useState([]);
    console.log(productsRE)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [size, setSize] = useState({
        XS: 1,
        S: 2,
        M: 3,
        L: 4,
        XL: 4,
        34: 1,
        35: 2,
        36: 3,
        37: 4,
        38: 5,
        39: 6,
        40: 7,
        41: 8,
      });
    const [categoryFilter,setCategoryFilter] = useState("")
    console.log(categoryFilter)

    

    const { stock } = useSelector((state) => state.inventoryDetails);

    const dispatch = useDispatch();

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - productsRE.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (stock.length > 0) {
          const mappedOrders = tableData.map((o) => {
            const productsWithImages = o.products?.map((p) => {
              const found = stock.find((i) => i.SKU === p.sku);
              const url =
                found?.imageUrl ||
                "https://media.istockphoto.com/vectors/error-page-or-file-not-found-icon-vector-id924949200";
              return { ...p, url };
            });
    
            return {
              ...o,
              products: productsWithImages,
            };
          });
          setOrders(mappedOrders);
        } else {
          dispatch(allInventory());
        }
    }, [stock, tableData]);

    console.log("Delivered Orders:",deliveredOrdersData)

    useEffect(() => {
        if (!orders.length > 0) return;
        const products = orders
          .reduce((acc, o) => {
            o.products?.forEach((p) => {
              const row = acc.find((ac) => ac.sku === p.sku);
    
              if (row) {
                row.value = row.value + 1;
                row.reason = [...row.reason, o.reason];
                row.modified_at = [...row.modified_at, o.modified_at];
                if (o.package_status === "R_DLVD") {
                  row.reverseDelivered = row.reverseDelivered + 1;
                }
                
                if (o.type === "Exchange") {
                  row.exchange = row.exchange + 1;
                } else {
                  row.return = row.return + 1;
                }
                if (o.exchangeReason === "Size-Exchange") {
                  row.sizeExchange = row.sizeExchange + 1;
                } else if (o.exchangeReason === "Damaged Product") {
                  row.damagedProduct = row.damagedProduct + 1;
                } else {
                  row.both = row.both + 1;
                }
                if (size[p.originalSize] > size[p.size]) {
                  row.largeSize = row.largeSize + 1;
                } else if (size[p.originalSize] < size[p.size]) {
                  row.smallSize = row.smallSize + 1;
                }
              } else {
                const newObj = {
                  sku: p.sku,
                  value: 1,
                  reverseDelivered: 0,
                  url: p.url,
                  modified_at: [o.modified_at],
                  reason: [o.reason],
                  exchange: 0,
                  damagedProduct: 0,
                  sizeExchange: 0,
                  both: 0,
                  return: 0,
                  smallSize: 0,
                  largeSize: 0,
                  type : isNaN(p.originalSize) ? "Clothing" : "Footwear"
                };
                if (o.package_status === "R_DLVD") {
                  newObj.reverseDelivered = 1;
                }
                if (o.type === "Exchange") {
                  newObj.exchange = 1;
                } else {
                  newObj.return = 1;
                }
                if (o.exchangeReason === "Size-Exchange") {
                  newObj.sizeExchange = 1;
                } else if (o.exchangeReason === "Damaged Product") {
                  newObj.damagedProduct = 1;
                } else {
                  newObj.both = 1;
                }
                if (size[p.originalSize] > size[p.size]) {
                  newObj.largeSize = 1;
                } else if (size[p.originalSize] < size[p.size]) {
                  newObj.smallSize = 1;
                }
                acc.push(newObj);
              }
            });
            return acc;
          }, [])
          .sort((a, b) => b.value - a.value);

        const deliveredOrdersCount = deliveredOrdersData
          .reduce((acc, o) => {
            o.order_items?.forEach((p) => {
              const row = acc.find((ac) => ac.sku.split(".")[0] === p.sku.split(".")[0]);
              if (row) {
                row.total = row.total + 1;
              } else {
                const newObj = {
                  sku: p.sku.split(".")[0],
                  total: 1,
                };
                acc.push(newObj);
              }
            });
            return acc;
          }, [])

          
        const finalObj = products.map(x=> {
          const findSKU = deliveredOrdersCount.find(p=>p.sku===x.sku)
          if(findSKU) return {...x,totalDelivered : findSKU.total}
          else return x
        })
        categoryFilter==="" ? setProductsRE(finalObj) : setProductsRE(finalObj.filter(x => x.type === categoryFilter))
    }, [orders,deliveredOrdersData,categoryFilter]);
      

    return (
        <>
            <Box sx={{display:"flex",justifyContent:"center"}}>
              <TextField
                select
                label="Select Product Type"
                value={categoryFilter}
                onChange={(e) => setCategoryFilter(e.target.value)}
                variant="outlined"
                className="select"
                sx={{width:"300px",bgcolor:"white"}}
                size="small"
              >
                {[
                    {
                        value: "",
                        label: "None",
                    },
                    {
                        value: "Clothing",
                        label: "Clothing",
                    },
                    {
                        value: "Footwear",
                        label: "Footwear",
                    }
                ].map((option) => (
                  <MenuItem key={option.label} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <br />

            <>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        {/* <TableHead>
                            <TableRow>
                                <TableCell>SKU</TableCell>
                            </TableRow>
                        </TableHead> */}
                        <TableBody>
                            {(rowsPerPage > 0
                                ? productsRE.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                  )
                                : productsRE
                            ).map((row, index) => (
                                <Row key={index} row={row} index={index} />
                            ))}

                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    style={{ overflow: "inherit" }}
                                    rowsPerPageOptions={[
                                        5,
                                        10,
                                        25
                                    ]}
                                    colSpan={3}
                                    count={productsRE.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "rows per page",
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={
                                        handleChangeRowsPerPage
                                    }
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </>
        </>
    );
}
