import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, TextField, Box } from "@mui/material";
import RedoIcon from "@mui/icons-material/Redo";
import CachedIcon from "@mui/icons-material/Cached";
import SearchIcon from "@mui/icons-material/Search";

import { allInventory } from "../../actions/inventoryActions";
import REOrderDetails from "./REOrderDetails";

const REOrders = (props) => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { stock } = useSelector((state) => state.inventoryDetails);
  const [searchOrder, setSearchOrder] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (stock.length > 0) {
      const mappedOrders = props.orders.map((o) => {
        const productsWithImages = o.products.map((p) => {
          const found = stock.find((i) => i.SKU === p.sku);
          const url =
            found?.imageUrl ||
            "https://media.istockphoto.com/vectors/error-page-or-file-not-found-icon-vector-id924949200";
          return { ...p, url };
        });

        return {
          ...o,
          products: productsWithImages,
        };
      });
      const filteredMappedOrders = mappedOrders.filter((o) =>
        String(o.orderNumber).startsWith(searchOrder)
      );
      setOrders(filteredMappedOrders);
    } else {
      dispatch(allInventory());
    }
  }, [stock, props.orders, searchOrder]);

  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "flex-end", gap: 1 }}>
        <SearchIcon sx={{ color: searchOrder ? "#1976D2" : "" }} />
        <TextField
          id="input-with-sx"
          label="Search Order"
          variant="standard"
          onChange={(e) => setSearchOrder(e.target.value)}
        />
      </Box>
      <br />
      <br />
      <div className="flexGrid">
        {orders.map((o) => (
          <Button
            key={o.fulfillment_id}
            variant="contained"
            color={
              "tags" in o && o.tags.includes("replacement")
                ? "error" : !!o.cancelled_awb
                ? "info"
                : o.type === "Return"
                ? "secondary"
                : "success"
            }
            startIcon={
              o.type === "Return" ? (
                <RedoIcon color="warning" />
              ) : (
                <CachedIcon color="action" />
              )
            }
            onClick={() => setSelectedOrder(o)}
          >
            {o.orderNumber}
          </Button>
        ))}
      </div>
      <br />
      {selectedOrder && (
        <div className="orderDetailsBox">
          <div className="flexRow">
            <h1>{selectedOrder.orderNumber}</h1>
            <Button variant="contained" onClick={() => setSelectedOrder(null)}>
              Clear
            </Button>
          </div>
          <REOrderDetails
            order={selectedOrder}
            emailList={props.emailList}
            clear={() => setSelectedOrder(null)}
          />
        </div>
      )}
      <br />
    </div>
  );
};

export default REOrders;
