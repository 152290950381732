import { Button, TextField, Checkbox, MenuItem,
    Table, TableCell, TableBody, TableContainer, TableHead, TableRow, Paper 
} from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { postXBReverseOrder } from '../../actions/ordersActions'

const ReturnOrders = ({orderData}) => {

  const productTypeArray = ["Clothing", "Footwear", "Clothing + Footwear"]

  const [orderNo, setOrderNo] = useState("")
  const [ filteredOrder, setFilteredOrder] = useState("")
  const [ customerName, setCustomerName] = useState("")
  const [ address, setAddress] = useState("")
  const [ address2, setAddress2] = useState("")
  const [ city, setCity] = useState("")
  const [ state, setState] = useState("")
  const [ pincode, setPincode] = useState("")
  const [ phone, setPhone] = useState("")
  const [orderItems, setOrderItems] = useState("")
  const [ selectedItems, setSelectedItems] = useState([])
  const [awb, setAwb] = useState("")
  const [ productType, setProductType] = useState("")

  const dispatch = useDispatch()

  const handleSubmit = () => {
    const requiredOrder = orderData.filter(x => x.order_number === parseInt(orderNo))
    if(requiredOrder.length>0){
        console.log(requiredOrder[0])
        let refundedOrders = []
        if(requiredOrder[0].refunds.length > 0 ){
            requiredOrder[0].refunds.map(item => item.refund_line_items.map(x => refundedOrders.push(x.line_item_id)))
        }
        setFilteredOrder(requiredOrder[0])
        setCustomerName(`${requiredOrder[0].customer.first_name} ${requiredOrder[0].customer.last_name}`)
        setAddress(requiredOrder[0].billing_address.address1)
        setAddress2(requiredOrder[0].billing_address.address2)
        setCity(requiredOrder[0].billing_address.city)
        setState(requiredOrder[0].billing_address.province)
        setPincode(requiredOrder[0].billing_address.zip)
        setPhone(requiredOrder[0].billing_address.phone.split("")
            .filter((i) => i !== " ")
            .filter((i) => i !== "  ")
            .filter((i) => i !== "-")
            .join("")
            .slice(-10))
        if(refundedOrders.length > 0){
            let tempLineItems = []
            requiredOrder[0].line_items.forEach(x => {
                if(refundedOrders.includes(x.id)){
                    return
                }else{
                    tempLineItems.push(x)
                }
            })
            setOrderItems(tempLineItems)
        }else{
            setOrderItems(requiredOrder[0].line_items)
        }

    }else{
        console.log("Order Not Found")
        toast.error("Order Not Found")
    }
  }

  const handlePickup = () => {
    const totalQuantity = selectedItems.reduce((sum,i) => sum + i.quantity,0)
    const totalPrice = selectedItems.reduce((sum,i) => sum + parseInt(i.price) - (i.discount_allocations.length>0 ? parseInt(i.discount_allocations[0].amount) : 0), 0)
    const newObj = {
        AirWayBillNO : awb,
        OrderNo : `R_${orderNo}.1`,
        BusinessAccountName : "Littlebox",
        ProductID : `R_${orderNo}.1`,
        Quantity : totalQuantity,
        ProductName : productType,
        ProductMRP : totalPrice === 0 ? 1 : totalPrice,
        DropDetails: { 
            Addresses: [{ 
                Type: "Primary", 
                Name: "LittleBoxIndia", 
                Address: "MISS CLOG PLOT NUMBER 2 NEAR AGARWAL SWEETS BELOW WELLNESS GYM 100 FEET ROAD GHITORNI India", 
                City: "South West Delhi", 
                State: "Delhi", 
                PinCode: "110030", 
                EmailID: "", 
                Landmark: "" 
            }], 
            ContactDetails: [{ 
                Type: "Primary",
                PhoneNo: "8114118119" 
            }]
        },
        PickupDetails: { 
            Addresses: [{ 
                Type: "Primary", 
                Name: customerName,
                Address: `${address} ${address2}`, 
                City: city, 
                State: state, 
                PinCode: pincode, 
                EmailID: "", 
                Landmark: "" 
            }], 
            ContactDetails: [{ 
                Type: "Primary", 
                VirtualNumber: "", 
                PhoneNo: phone
            }], 
            IsPickupPriority:"1" 
        }
    }
    console.log(newObj)
    dispatch(postXBReverseOrder(newObj))
    setOrderNo("")
    setFilteredOrder("")
    setSelectedItems([])
    setAwb("")
    setProductType("")
  }

  return (
    <div>
        <div style={{display:"flex"}}>
            <TextField
                label="Enter Order Id"
                onChange={(e) => setOrderNo(e.target.value)}
                variant="outlined"
                className="select"
            />
            <Button style={{left:"10px"}} variant='contained' color='secondary' onClick={handleSubmit}>Enter</Button>
        </div>
        <br/>
        <br/>
        {filteredOrder !== "" ? (
            <>
                <div style={{display:"flex"}}>
                    <TextField
                        style={{width:"300px"}}
                        label="Airway Bill Number"
                        value={awb}
                        type="string"
                        onChange={e => setAwb(e.target.value)}
                    />
                    <TextField
                        style={{width:"300px", left:"10px"}}
                        label="Customer Name"
                        value={customerName}
                        type="string"
                        onChange={e => setCustomerName(e.target.value)}
                    />
                </div>
                <br/>
                <TextField
                    style={{width:"1200px"}}
                    label="Billing Address"
                    value={address}
                    type="string"
                    onChange={e => setAddress(e.target.value)}
                    />
                <br/>
                <br/>
                <TextField
                    style={{width:"900px"}}
                    label="Billing Address 2"
                    value={address2}
                    type="string"
                    onChange={e => setAddress2(e.target.value)}
                />
                <br/>
                <br/>
                <div style={{display:"flex"}}>
                    <TextField
                        style={{width:"300px"}}
                        label="City"
                        value={city}
                        type="string"
                        onChange={e => setCity(e.target.value)}
                    />
                    <TextField
                        style={{width:"300px", left:"50px"}}
                        label="State"
                        value={state}
                        type="string"
                        onChange={e => setState(e.target.value)}
                    />
                    <TextField
                        style={{width:"300px", left:"100px"}}
                        label="Pincode"
                        value={pincode}
                        type="string"
                        onChange={e => setPincode(e.target.value)}
                    />
                </div>
                <br/>
                <TextField
                    style={{width:"500px"}}
                    label="Phone"
                    value={phone}
                    type="string"
                    onChange={e => setPhone(e.target.value)}
                />
                <br/>
                <h3>Order Items</h3>
                <TableContainer style={{width:"1000px"}} component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Select</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">SKU</TableCell>
                            <TableCell align="right">Size</TableCell>
                            <TableCell align="right">Quantity</TableCell>
                            <TableCell align="right">New Quantity</TableCell>
                            <TableCell align="right">Price</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {orderItems.map((row, index) => (
                            <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell>
                                <Checkbox
                                    color="primary"
                                    checked={
                                        selectedItems.filter(
                                            (i) =>
                                                i.sku ===
                                                row.sku && i.variant_title === row.variant_title
                                        ).length > 0
                                            ? true
                                            : false
                                    }
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedItems([
                                                ...selectedItems,
                                                row,
                                            ]);
                                        } else {
                                            setSelectedItems(
                                                selectedItems.filter(
                                                    (item) =>
                                                        item.sku !==
                                                        row.sku
                                                )
                                            );
                                        }
                                    }}
                                />
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.title}
                            </TableCell>
                            <TableCell align="right">{row.sku}</TableCell>
                            <TableCell align='right'>{row.variant_title}</TableCell>
                            <TableCell align="right">{row.quantity}</TableCell>
                            <TableCell align="right">
                                <TextField
                                    style={{width:"80px"}}
                                    // value={}
                                    type="number"
                                    onChange={e => {
                                        setSelectedItems([])
                                        const item = orderItems.find(x => x.sku === row.sku)
                                        if(item){
                                            item.quantity = parseInt(e.target.value)
                                        }
                                    }}
                                />
                            </TableCell>
                            <TableCell align="right">{parseInt(row.price) - (row.discount_allocations.length>0 ? parseInt(row.discount_allocations[0].amount) : 0)}</TableCell>
                            {/* parseInt(row.discount_allocations[0].amount ? row.discount_allocations[0].amount : 0 ) */}
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br/>
                <TextField
                    style={{width:"300px"}}
                    select
                    label="Select Product Type"
                    value={productType}
                    onChange={(e) => setProductType(e.target.value)}
                    variant="outlined"
                    className="select"
                >
                    {productTypeArray.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                <br/>
                <br/>
                <Button variant='contained' color='error' onClick={handlePickup}>Generate PickUp</Button>

            </>
        ) : ""}
    </div>
  )
}

export default ReturnOrders