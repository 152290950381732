const initialState = {
  numberOfExchangeDelivered: [],
};

const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "EXCHANGE_DELIVERED":
      return {
        ...state,
        numberOfExchangeDelivered: action.data,
      };
    default:
      return state;
  }
};

export default analyticsReducer;
