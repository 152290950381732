import {
  Box,
  Button,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";

import TablePaginationActions from "../../utils/TablePaginationActions";
import { changeTag } from "../../actions/customerActions";

import { useDispatch, useSelector } from "react-redux";
import {
  updateUnreliableOrdersS3,
  updateUnreliableOrdersTag,
} from "../../actions/ordersActions";

function Row(props) {
  const { row } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { unreliableOrders } = useSelector((state) => state.orderDetails);
  const dispatch = useDispatch();
  console.log("row", row);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleAddTag = async (tag) => {
    const id = row.id;
    const tagData = await updateUnreliableOrdersTag(id, tag);
    if (tagData.statusCode === 200) {
      //update to s3
      const newUnreliableOrders = {
        ...unreliableOrders,
        sales_data: unreliableOrders.sales_data.map((sd) => {
          if (sd.id === row.id) {
            return {
              ...sd,
              tags: tagData.body,
            };
          } else {
            return sd;
          }
        }),
      };
      const uploadData = await updateUnreliableOrdersS3(newUnreliableOrders);
      if (uploadData.statusCode === 200) {
        //dispatch
        dispatch({
          type: "UNRELIABLE_ORDERS_DATA",
          payload: newUnreliableOrders,
        });
        setAnchorEl(null);
      }
    }
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": {
            borderBottom: "unset",
            backgroundColor:
              row.id === 6758706741535 ||
              row.tags.includes("RTO-CR") ||
              row.tags.includes("bad_customer")
                ? "lightgreen"
                : "white",
          },
        }}
      >
        <TableCell component="th" scope="row">
          {new Date(row.created_at).toLocaleString("en-in")}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.order_number}
        </TableCell>
        <TableCell align="left">
          {row.customer.first_name} {row.customer.last_name}
        </TableCell>
        <TableCell align="left">
          {"default_address" in row.customer
            ? row.customer.default_address.phone
            : ""}
        </TableCell>
        {/* <TableCell align="left">{row.customer.email}</TableCell> */}
        <TableCell align="left">
          {new Date(row.rto_order_date).toLocaleDateString("en-in")}
        </TableCell>
        <TableCell align="left">
          {new Date(row.rto_declared_on).toLocaleDateString("en-in")}
        </TableCell>
        <TableCell align="left">{row.financial_status}</TableCell>
        <TableCell align="left">
          {row.line_items.map((li, i) => (
            <p key={i}>
              {li.sku}-{li.variant_title}
            </p>
          ))}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.tags}
        </TableCell>
        <TableCell>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="outlined"
            onClick={handleClick}
          >
            Add Tag
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => handleAddTag("bad_customer")}>
              bad_customer
            </MenuItem>
            <MenuItem onClick={() => handleAddTag("RTO-CR")}>RTO-CR</MenuItem>
            <MenuItem onClick={() => handleAddTag("RTO-C")}>RTO-C</MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          {/* <Collapse in={open} timeout="auto">
            <Box sx={{ margin: 1 }}>
              <CustomerOrders customer={row} />
            </Box>
          </Collapse> */}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const CustomerTable = ({ data }) => {
  const tableData = data.filter((i) => i.financial_status === "pending");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  console.log(
    "no phone",
    tableData.filter((row) => !row.customer.default_address)
  );
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Created At</TableCell>
              <TableCell>OrderId</TableCell>
              <TableCell align="left">Customer name</TableCell>
              <TableCell align="left">Phone</TableCell>
              {/* <TableCell align="left">Email</TableCell> */}
              <TableCell align="left">Last RTO order date</TableCell>
              <TableCell align="left">Last RTO Declared date</TableCell>
              <TableCell align="left">Payment Method</TableCell>
              <TableCell align="left">Product List</TableCell>
              <TableCell>Tags</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? tableData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : tableData
            ).map((row, i) => (
              <Row key={i} row={row} />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{ overflow: "inherit" }}
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CustomerTable;
