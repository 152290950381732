import React, { useEffect, useState } from "react";
import { Paper, Skeleton, Stack } from "@mui/material";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { materialVendors } from "../../utils/helperArrays";
import MaterialAmtTable from "./MaterialAmtTable";
import FabricAvailabilityPieChart from "./FabricAvailabilityPieChart";
import MaterialStock from "./MaterialStock";
import MaterialVendor from "./MaterialVendor";
import { useDispatch, useSelector } from "react-redux";
import { getMaterialFabricList } from "../../actions/materialActions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const MainDashboard = () => {
  const [value, setValue] = useState(0);
  const { materialFabricList, materialColorList } = useSelector(
    (state) => state.inventoryDetails
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (materialFabricList.length === 0) dispatch(getMaterialFabricList());
    // if (materialColorList.length === 0) dispatch(getMaterialColorList());
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  if (materialFabricList.length === 0) {
    return (
      <Stack spacing={1} my={3}>
        {/* For variant="text", adjust the height via font-size */}
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />

        <Skeleton variant="rectangular" width={500} height={60} />
        <Skeleton variant="rounded" width={500} height={60} />
      </Stack>
    );
  } else {
    return (
      <div>
        <Paper sx={{ marginBottom: 2 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
          >
            <Tab key={0} label={"warehouse"} {...a11yProps(0)} />
            {materialVendors.map((m, i) => (
              <Tab key={i + 1} label={m.name} {...a11yProps(i + 1)} />
            ))}
          </Tabs>
        </Paper>
        <Paper>
          <TabPanel value={value} index={0}>
            <MaterialStock />
          </TabPanel>
          {materialVendors.map((m, i) => (
            <TabPanel key={i + 1} value={value} index={i + 1}>
              <MaterialVendor vendorId={m.code} />
            </TabPanel>
          ))}
        </Paper>
      </div>
    );
  }
};

export default MainDashboard;
