import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allInventory } from "../../actions/inventoryActions";
import ProgressBar from "../../ProgressBar";
import UpdateProductStock from "../productsWidgets/UpdateProductStock";
import RedirectLogin from "../RedirectLogin";
import "./index.css";

const UpdateStock = () => {
  const auth = useSelector((state) => state.auth);
  const inventory = useSelector((state) => state.inventoryDetails.stock);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!inventory.length > 0 && auth) dispatch(allInventory());
  }, [dispatch, auth]);

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>{!inventory.length > 0 ? <ProgressBar /> : <UpdateProductStock />}</>
      )}
    </>
  );
};

export default UpdateStock;
