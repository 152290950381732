import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import RedirectLogin from "../RedirectLogin";
import "./index.css";
import {TextField, Box, } from '@mui/material';
import { MenuItem } from '@mui/material';
import { getDeliveredReturnExchangeOrders, getDispatchedReturnExchangeOrders, getsyncedDispatchedReturnExchangeOrders } from '../../actions/ordersActions';
import { hideLoading, showLoading } from '../../actions/loaderActions';
import SearchIcon from "@mui/icons-material/Search";
import { allProducts } from '../../actions/productsActions';
import PendingReturnsCloseTable from '../ordersWidgets/returns/PendingReturnsCloseTable';

const ReturnXBDelivered = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  
  const { dispatchedREOrders,synceddispatchREOrders, deliveredREOrders} = useSelector((state) => state.orderDetails);
  const {productVariants} = useSelector(state => state.productDetails)
  const loading = useSelector(state => state.orderDetails.loading)
  const [ orders, setOrders ] = useState([])
  const [ filteredDateOrders, setFilteredDateOrders] = useState([])
  const [searchFilter, setSearchFilter] = useState("")
  const [ orderType, setOrderType] = useState("")
  const [ syncStatus, setSyncStatus] = useState("")


  useEffect(() => {
    if(!auth) return
    dispatch(showLoading())
    dispatch(getDispatchedReturnExchangeOrders())
    dispatch(getsyncedDispatchedReturnExchangeOrders())
    dispatch(getDeliveredReturnExchangeOrders())
    dispatch(allProducts())
  },[auth])

  useEffect(() => {
    if(dispatchedREOrders.length>0 && synceddispatchREOrders.length>0 && deliveredREOrders.length>0){
        const allOrders = [...dispatchedREOrders,...synceddispatchREOrders,...deliveredREOrders].filter(x => !!x.orderNumber && !!x.reverse_pickup_awb && !x.reverse_inventory_updated)
        setOrders(allOrders)
        dispatch(hideLoading())}
  },[dispatchedREOrders, synceddispatchREOrders, deliveredREOrders])

  useEffect(() => {
    if(searchFilter !== ""){
        setOrderType("")
        setSyncStatus("")
        const searchedOrder = orders.filter(x => String(x.orderNumber).startsWith(searchFilter))
        if(searchedOrder){
            setFilteredDateOrders(searchedOrder)
        }
        else return
    }else{
        setFilteredDateOrders(orders)
    }
  },[orders,searchFilter])

  useEffect(() => {
    if(orderType !== ""){
      setSearchFilter("")
      setSyncStatus("")
      if(orderType === "All"){
        setFilteredDateOrders(orders)
      }else{
        const filterByType = orders.filter(x => x.type === orderType)
        if(filterByType.length>0) setFilteredDateOrders(filterByType)
      }
    }
  },[orderType, orders])

  useEffect(() => {
    if(syncStatus !== ""){
      setSearchFilter("")
      setOrderType("")
      if(syncStatus === "All"){
        setFilteredDateOrders(orders)
      }else if(syncStatus === "Pickup Done"){
        const filterByType = orders.filter(x => x.xb_reverse_last_sync_status !== "WHHandover")
        if(filterByType.length>0) setFilteredDateOrders(filterByType)
      }else{
        const filterByType = orders.filter(x => x.xb_reverse_last_sync_status === "WHHandover")
        if(filterByType.length>0) setFilteredDateOrders(filterByType)
      }
    }
  },[syncStatus, orders])

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
            {(loading || orders.length === 0 || productVariants.length === 0) 
            ? (
                <div className="load-overlay">
                <h1>Please Wait...</h1>
                </div>
            ) : (
                <>
                    <div style={{display:"flex"}}>
                        <Box sx={{ display: "flex", paddingRight:"30px", alignItems: "flex-end", gap: 1 }}>
                            <SearchIcon />
                            <TextField
                            id="input-with-sx"
                            label="Search Order"
                            variant="standard"
                            onChange={(e) => setSearchFilter(e.target.value)}
                            />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 20, width:"300px" }}>
                            <TextField
                                select
                                label="Order Type"
                                value={orderType}
                                onChange={(e) => setOrderType(e.target.value)}
                                variant="standard"
                                className="select"
                                fullWidth
                            >
                                {['All','Exchange','Return'].map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 20, width:"300px", paddingLeft:"30px" }}>
                            <TextField
                                select
                                label="Shipment Status Type"
                                value={syncStatus}
                                onChange={(e) => setSyncStatus(e.target.value)}
                                variant="standard"
                                className="select"
                                fullWidth
                            >
                                {['All','Pickup Done','Delivered'].map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </div>
                    <br/>
                    {/* <h3>Pending Orders on {new Date(date.$d).toLocaleDateString('en-in')} : </h3> */}
                    {filteredDateOrders.length>0 ? (
                        <PendingReturnsCloseTable viewTableData={filteredDateOrders} productVariants={productVariants} setSearchFilter={setSearchFilter}/>
                    ) : "No Orders Found"}
                    </>
            )}
            
        </>
      )}
    </>
  )
}

export default ReturnXBDelivered