import React from 'react'
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Typography,Modal,Box, Card, CardContent, CircularProgress  } from '@mui/material';
import { removeReverseXBShipmentDetails, removeXBShipmentDetails } from '../../../actions/ordersActions';
import XBReverseDetailTable from './XBReverseDetailTable';

export const XbReverseShipmentHistoryModal = ({open, setOrderHistoryModal}) => {
  const dispatch = useDispatch()
  const orderHistory = useSelector(state => state.orderDetails.xbReverseShipmentHistory)
  const [ viewTableData, setViewTableData] = useState([])
  // console.log(viewTableData)
  const [openUp, setOpenUp] = useState(open);
  const handleClose = () => {
    setOpenUp(false)
    setOrderHistoryModal(false)
    dispatch(removeReverseXBShipmentDetails())
  }

    useEffect(() => {
        if (orderHistory.length > 0) {
            const newOrderArray = orderHistory
                .map((item) => {
                    const newOrder = {
                        AWB: item.AWBNo,
                        OrderNo: item.OrderNo,
                        OrderDetails : item.ShipmentSummary
                    };
                    return newOrder;
                })
                setViewTableData(newOrderArray);
        }else{
            setViewTableData("")
        }
    }, [orderHistory]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    background : "whitesmoke",
    textAlign : "center",
    maxHeight : "80vh",
    overflowY : "scroll",
    maxWidth : "90%"
  };

  return (
    <div>
        <Modal
            open={openUp}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
          {(orderHistory && viewTableData.length>0) 
          ? (
            <Box sx={style}>
              <h2>Reverse Order Tracking Details</h2>
              <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          AWB Number : {viewTableData[0].AWB}
                      </Typography>
                      <Typography variant="h5" component="div"></Typography>
                      <Typography variant="body2">
                        Order Id : {viewTableData[0].OrderNo}
                      </Typography>
                  </CardContent>
              </Card>
              <br/>
              <XBReverseDetailTable tableData={viewTableData[0].OrderDetails}/>
            </Box>
          ) 
          : (!orderHistory && viewTableData === "")
          ? (
              <Box sx={style}>
                  <h3>XpressBees Shipment History Not Found.</h3>
              </Box>
          ) 
          : (
              <Box sx={style}>
                  <CircularProgress />
              </Box>
          )}
        </Modal>
    </div>
  )
}
