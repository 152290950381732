import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ProductStockTable from "./ProductStockTable";
import { productTypes, categoryTypes } from "../../utils/helperArrays";
import { TextField, MenuItem } from "@mui/material";

const OrdersWithImages = ({ orderedProducts }) => {
  const productImageList = useSelector(
    (state) => state.productDetails.imageList
  );
  const inventoryStock = useSelector((state) => state.inventoryDetails.stock)
  const [tableData, setTableData] = useState([]);
  const [productType, setProductType] = useState("");
  const [filteredProducts, setFilteredProducts] = useState("");
  const [categoryTypesArray, setCategoryTypesArray] = useState([]);

  useEffect(() => {
    //Create the Tabledata
    if (orderedProducts.length > 0 && productImageList.length > 0 && inventoryStock.length>0) {
      const data = orderedProducts.map((p) => {
        const imageObj = productImageList.find((item) => item.id === p.id);
        const inventoryObj = inventoryStock.filter((item) => item.SKU === p.sku )
        let productStock = {}
        let productStockTotal = null
        if(inventoryObj.length>0){
          const mapped = inventoryObj.map(item => ({
            [item.size]: item.count
          }));
          productStock = Object.assign({}, ...mapped);
          productStockTotal = Object.values(productStock).reduce((x,sum) => x + sum,0)
        }else{
            const mapped = Object.keys(p.sizes).map(item => ({
                [item]: 0
              }));
              productStock = Object.assign({}, ...mapped);
              productStockTotal = 0
        }
        let src = null;
        if (imageObj) {
          src = imageObj.src;
        } else {
          src = "https://sss-retail-images.s3.amazonaws.com/404.jpg?w=256&q=75";
        }
        return {
          ...p,
          src,
          type: imageObj?.type,
          inventoryStock : productStock,
          inventoryTotal : productStockTotal-p.total
        };
      });

      setTableData(data);
    }
  }, [orderedProducts, productImageList]);

  useEffect(() => {
    if (tableData.length > 0) {
      const getCategories = categoryTypes(productType);
      const filtered = tableData.filter((item) => item.type === productType);
      setFilteredProducts(filtered);
      setCategoryTypesArray(getCategories);
    }
  }, [productType, tableData]);

  return (
    <div>
      <br />
      <br />
      <TextField
        select
        label="Select Product Type"
        value={productType}
        onChange={(e) => setProductType(e.target.value)}
        variant="outlined"
        className="select"
        fullWidth
      >
        {productTypes.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <br />
      <br />
      {productType && filteredProducts.length > 0 ? (
        <ProductStockTable
          tableData={filteredProducts}
          categoryTypes={categoryTypesArray}
          productType={productType}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default OrdersWithImages;
