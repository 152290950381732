import { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import { Paper, Typography, Box } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

const MonthlyPC = ({
  forwardDeliveredData,
  forwardInTransitData,
  forwardNdrData,
  rtoMonthlyData,
}) => {
  const [infoData, setInfoData] = useState([]);
  const [infoData1, setInfoData1] = useState([]);
  const pieChart1 = useRef();
  const pieChart2 = useRef();

  useEffect(() => {
    const previous_month_data = [
      {
        item: `Delivered`,
        count: forwardDeliveredData.pastMonthDates,
        color: "#FA233E",
      },
      {
        item: `InTransit`,
        count: forwardInTransitData.pastMonthDates,
        color: "#44D492",
      },
      { item: `NDR`, count: forwardNdrData.pastMonthDates, color: "#F5EB67" },
      { item: `RTO`, count: rtoMonthlyData.pastMonthDates, color: "#FFA15C" },
    ];

    console.log(
      "previous month data",
      forwardDeliveredData.pastMonthDates,
      forwardInTransitData.pastMonthDates,
      forwardNdrData.pastMonthDates,
      rtoMonthlyData.pastMonthDates
    );

    const present_month_data = [
      {
        item: `Delivered`,
        count: forwardDeliveredData.presentMonthDates,
        color: "#FA233E",
      },
      {
        item: `InTransit`,
        count: forwardInTransitData.presentMonthDates,
        color: "#44D492",
      },
      {
        item: `NDR`,
        count: forwardNdrData.presentMonthDates,
        color: "#F5EB67",
      },
      {
        item: `RTO`,
        count: rtoMonthlyData.presentMonthDates,
        color: "#FFA15C",
      },
    ];

    setInfoData(previous_month_data);
    setInfoData1(present_month_data);

    const pieData1 = d3.layout.pie().value((d) => d.count)(previous_month_data);
    const arc1 = d3.svg.arc().innerRadius(35).outerRadius(70);
    const colors1 = d3.scale
      .ordinal()
      .range(["#FA233E", "#44D492", "#F5EB67", "#FFA15C"]);
    const svg1 = d3
      .select(pieChart1.current)
      .attr("width", 400)
      .attr("height", 150)
      // .style('background-color','white')
      .append("g")
      .attr("transform", "translate(130,70)");
    const tooldiv1 = d3
      .select("#chartArea")
      .append("div")
      .style("visibility", "hidden")
      .style("position", "absolute")
      .style("background", "rgba(0, 0, 0, 0.7)")
      .style("color", "white")
      .style("padding", "13px")
      .style("border-radius", "10px");

    svg1
      .append("g")
      .selectAll("path")
      .data(pieData1)
      // .join('path')
      .enter()
      .append("path")
      // .attr('d', arc)
      .attr("fill", (d, i) => colors1(i))
      // .attr('stroke','white')
      .on("mouseover", (e, d) => {
        // console.log(e)
        // console.log(d)
        tooldiv1
          .style("visibility", "visible")
          .text(`${e.data.item} : ` + `${e.data.count}`);
      })
      .on("mousemove", (e, d) => {
        tooldiv1
          .style("top", d3.event.pageY - 50 + "px")
          .style("left", d3.event.pageX - 50 + "px");
      })
      .on("mouseout", () => {
        tooldiv1.style("visibility", "hidden");
      })
      .transition()
      // .delay(function(d, i) {
      .duration(function (d, i) {
        return i * 800;
      })
      .attrTween("d", function (d) {
        var i = d3.interpolate(d.startAngle + 0.1, d.endAngle);
        return function (t) {
          d.endAngle = i(t);
          return arc1(d);
        };
      });

    const pieData2 = d3.layout.pie().value((d) => d.count)(present_month_data);
    const arc2 = d3.svg.arc().innerRadius(35).outerRadius(70);
    const colors2 = d3.scale
      .ordinal()
      .range(["#FA233E", "#44D492", "#F5EB67", "#FFA15C"]);
    const svg2 = d3
      .select(pieChart2.current)
      .attr("width", 400)
      .attr("height", 150)
      // .style('background-color','white')
      .append("g")
      .attr("transform", "translate(140,70)");
    const tooldiv2 = d3
      .select("#chartArea1")
      .append("div")
      .style("visibility", "hidden")
      .style("position", "absolute")
      .style("background", "rgba(0, 0, 0, 0.7)")
      .style("color", "white")
      .style("padding", "13px")
      .style("border-radius", "10px");

    svg2
      .append("g")
      .selectAll("path")
      .data(pieData2)
      // .join('path')
      .enter()
      .append("path")
      // .attr('d', arc)
      .attr("fill", (d, i) => colors2(i))
      // .attr('stroke','white')
      .on("mouseover", (e, d) => {
        // console.log(e)
        // console.log(d)
        tooldiv2
          .style("visibility", "visible")
          .text(`${e.data.item} : ` + `${e.data.count}`);
      })
      .on("mousemove", (e, d) => {
        tooldiv2
          .style("top", d3.event.pageY - 50 + "px")
          .style("left", d3.event.pageX - 50 + "px");
      })
      .on("mouseout", () => {
        tooldiv2.style("visibility", "hidden");
      })
      .transition()
      // .delay(function(d, i) {
      .duration(function (d, i) {
        return i * 800;
      })
      .attrTween("d", function (d) {
        var i = d3.interpolate(d.startAngle + 0.1, d.endAngle);
        return function (t) {
          d.endAngle = i(t);
          return arc2(d);
        };
      });
  }, [
    forwardDeliveredData,
    forwardInTransitData,
    forwardNdrData,
    rtoMonthlyData,
  ]);

  return (
    <Paper
      elevation={12}
      style={{ borderRadius: "30px", maxWidth: "570px", height: "325px" }}
    >
      <h4
        style={{
          margin: "0",
          padding: "10px 30px 0px 0",
          display: "flex",
          justifyContent: "flex-end",
          color: "grey",
          fontSize: "x-small",
        }}
      >
        2 Month Data
      </h4>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Box id="chartArea" style={{ maxWidth: "300px" }}>
          <h4
            style={{
              margin: "0",
              paddingTop: "5px",
              display: "flex",
              justifyContent: "center",
              color: "grey",
            }}
          >
            Previous Month
          </h4>
          <svg ref={pieChart1} style={{ margin: "15px 0" }}></svg>
          <div
            style={{
              columns: "2",
              transform: "translate(5px)",
              paddingBottom: "50px",
            }}
          >
            {infoData.length > 0 ? (
              <>
                {infoData.map((x, index) => (
                  <Box
                    key={index}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <CircleIcon sx={{ color: x.color, fontSize: "small" }} />
                    <Typography sx={{ fontSize: "small", marginLeft: "10px" }}>
                      {x.item} ({x.count})
                    </Typography>
                  </Box>
                ))}
              </>
            ) : (
              ""
            )}
          </div>
          <br />
        </Box>
        <Box id="chartArea1" style={{ maxWidth: "300px" }}>
          <h4
            style={{
              margin: "0",
              paddingTop: "5px",
              display: "flex",
              justifyContent: "center",
              color: "grey",
            }}
          >
            Present Month
          </h4>
          <svg ref={pieChart2} style={{ margin: "15px 0" }}></svg>
          <div
            style={{
              columns: "2",
              transform: "translate(5px)",
              paddingBottom: "50px",
            }}
          >
            {infoData.length > 0 ? (
              <>
                {infoData1.map((x, index) => (
                  <Box
                    key={index}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <CircleIcon sx={{ color: x.color, fontSize: "small" }} />
                    <Typography sx={{ fontSize: "small", marginLeft: "10px" }}>
                      {x.item} ({x.count})
                    </Typography>
                  </Box>
                ))}
              </>
            ) : (
              ""
            )}
          </div>
          <br />
        </Box>
      </div>
    </Paper>
  );
};

export default MonthlyPC;
