import { Avatar, Box, Stack } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import AssessmentIcon from "@mui/icons-material/Assessment";
import React from "react";

const OpenMoreDetailsRE = ({ product }) => {
    return (
        <Box sx={{ marginTop: "20px" }}>
            <Stack direction={"row"} gap={2}>
                <img
                    src={product.url}
                    alt=""
                    style={{
                        width: "250px",
                        height: "100%",
                        objectFit: "contain",
                    }}
                />
                <Stack sx={{ width: "35%", marginRight: "40px" }}>
                    <h2 style={{ margin: 0, marginBottom: "10px" }}>
                        SKU :{" "}
                        <span style={{ color: "red" }}>{product.sku}</span>
                    </h2>
                    {/* #9CC0E7 */}
                    {/* #FAEACB */}
                    <Box
                        sx={{
                            bgcolor: "#F7DBD7",
                            borderRadius: "10px",
                            padding: "8px",
                            marginBottom: "10px",
                        }}
                    >
                        <h3 style={{ margin: 0 }}>
                            Total Return Requests : {product.return}
                        </h3>
                        {/* <h3 style={{ margin: 0 }}>Exchanges : {product.exchange}</h3> */}
                    </Box>
                    <Box
                        sx={{
                            bgcolor: "#c2e7c7",
                            borderRadius: "10px",
                            padding: "8px",
                            marginBottom: "10px",
                        }}
                    >
                        <h3 style={{ margin: 0 }}>
                            Forward Delivered :{" "}
                            {product.totalDelivered
                                ? product.totalDelivered
                                : 0}
                        </h3>
                        <h3 style={{ margin: 0 }}>
                            Reverse Delivered : {product.reverseDelivered}
                        </h3>
                        <h3 style={{ margin: 0 }}>
                            Return Percent(Total Delivered against Total
                            Exchange Request) :{" "}
                            {product.return / product.totalDelivered
                                ? Math.round(
                                      (product.return /
                                          product.totalDelivered) *
                                          100
                                  )
                                : 0}
                            %
                        </h3>
                    </Box>

                    <Box
                        sx={{
                            bgcolor: "#FAEACB",
                            borderRadius: "10px",
                            padding: "8px",
                            marginBottom: "10px",
                        }}
                    >
                        <h3 style={{ margin: 0 }}>
                            Size Exchange : {product.sizeExchange}
                        </h3>
                        <h3 style={{ margin: 0 }}>
                            Damaged Product : {product.damagedProduct}
                        </h3>
                        <h3 style={{ margin: 0 }}>
                            Size Exchange & Damaged : {product.both}
                        </h3>
                    </Box>
                    <Box
                        sx={{
                            bgcolor: "#9CC0E7",
                            borderRadius: "10px",
                            padding: "8px",
                            marginBottom: "10px",
                        }}
                    >
                        <h3 style={{ margin: 0 }}>
                            Customer Found It Small : {product.smallSize}
                        </h3>
                        <h3 style={{ margin: 0 }}>
                            Customer Found It Big : {product.largeSize}
                        </h3>
                    </Box>
                </Stack>
                <Box
                    sx={{
                        width: "50%",
                        height: "350px",
                        overflowY: "scroll",
                        overflowX: "visible",
                    }}
                >
                    <Stack sx={{ padding: "50px All", margin: "50px All" }}>
                        {product.reason.map((r, i) => (
                            <Box
                                key={i}
                                sx={{
                                    border: "1px solid #9CC0E7",
                                    borderRadius: "8px",
                                    position: "relative",
                                    backgroundColor: "#FCFCFC",
                                    margin: "10px 10px",
                                }}
                            >
                                <p style={{ paddingLeft: "20px" }}>{r}</p>
                                <ChatIcon
                                    sx={{
                                        fontSize: "30px",
                                        color: "#9CC0E7",
                                        position: "absolute",
                                        left: 0,
                                        top: 0,
                                        zIndex: 1,
                                        transform: "translate(-40% , -30%)",
                                        backgroundColor: "#EEEEEE",
                                    }}
                                />
                            </Box>
                        ))}
                    </Stack>
                </Box>
            </Stack>
        </Box>
    );
};

export default OpenMoreDetailsRE;
