import { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getShiprocketDispatchableOrders } from "../../actions/ordersActions";
import ShiprocketDispatchTable from "../ordersWidgets/ShiprocketDispatchTable";
import RedirectLogin from "../RedirectLogin";
import { Backdrop, Box, TextField } from "@mui/material";

const PacketReadyOrders = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { inTransitOrders } = useSelector((state) => state.orderDetails);
  const [ searchFilter, setSearchFilter] = useState("")
  const [orders, setOrders] = useState([])

  useEffect(() => {
    if (inTransitOrders.length === 0 && auth) {
      dispatch(getShiprocketDispatchableOrders());
    }
  }, [auth]);

  useEffect(() => {
    if(inTransitOrders.length>0) setOrders(inTransitOrders)
  },[inTransitOrders])

  useEffect(() => {
    if(searchFilter !== ""){
      const filterByType = orders.filter(x => String(x.fulfillment_id).startsWith(searchFilter))
      setOrders(filterByType)
    }else{
      setOrders(inTransitOrders)
    }
  },[searchFilter])

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {inTransitOrders.length === 0 && 
            (
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={inTransitOrders.length === 0}
              >
                <h1>Loading. Please Wait...</h1>
              </Backdrop>
            )}
          <Box sx={{ display: "flex",flexDirection:"column", alignItems:"center", paddingRight:"30px", gap: 1 }}>
            <h1>Place Shiprocket Orders</h1>
            <TextField
            id="input-with-sx"
            label="Search Order"
            variant="outlined"
            size="small"
            sx={{bgcolor:"white",width:"400px"}}
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
            />
          </Box>
          {orders.length>0 ? (
            <ShiprocketDispatchTable
              tableData={orders} setSearchFilter={setSearchFilter}
            />
          ) 
          : 
          <Box sx={{display:"flex",justifyContent:"center"}}>
            <h3>No Orders Found</h3>
          </Box>}
        </>
      )}
    </>
  );
};

export default PacketReadyOrders;
