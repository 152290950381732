const initialState = {
    invalidEmail : [],
    unsuccessfulEmail: [],
  };

const sesReducer = (state=initialState, action) => {
    switch(action.type){
        case "INVALID_EMAIL":
            return {
                ...state,
                invalidEmail : action.payload
            }
        case "UNSUCCESSFUL_EMAIL":
            return {
                ...state,
                unsuccessfulEmail: action.payload,
            };
        default:
            return state;
    }
}

export default sesReducer