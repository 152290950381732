import {
  Box,
  Button,
  Menu,
  MenuItem,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { showFullScreenLoader } from "../../actions/loaderActions";
import {
  getReverseAWBFromDelhivery,
  postDelhiveryReverseOrder,
} from "../../actions/reverseByDelhiveryActions"; //Delhivery Actions
import {
  createDelhiveryReverseOrderPayload,
  createXBReverseOrderPayload,
} from "../../utils/helpers";

const Selector = ({
  row,
  handleOpenCustomCommentBox,
  setComment,
  setSearchValue,
  emailList,
  setDamageSelected,
  setWrongProductSelected,
  submitApprove,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const buttonRef = useRef(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDamageSelect = (e) => {
    setComment("Damage/defective : ");
    setDamageSelected(true);
    setAnchorEl(null);
    handleOpenCustomCommentBox(buttonRef.current);
  };

  const handleWrongProductSelect = (e) => {
    setComment("Wrong product, details : ");
    setWrongProductSelected(true);
    setAnchorEl(null);
    handleOpenCustomCommentBox(buttonRef.current);
  };

  const handleCustomSelect = (e) => {
    setDamageSelected(false);
    setAnchorEl(null);
    handleOpenCustomCommentBox(buttonRef.current);
  };

  const handleAprrove = async (option) => {
    await submitApprove(option);
    handleClose();
  };

  return (
    <>
      <Button
        ref={buttonRef}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="contained"
      >
        Accept
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleAprrove("Size Issue")}>
          Size Issue
        </MenuItem>
        <MenuItem onClick={handleWrongProductSelect}>Wrong Product</MenuItem>
        <MenuItem onClick={() => handleAprrove("Quality Issues")}>
          Quality Issues
        </MenuItem>
        <MenuItem onClick={handleDamageSelect}>Damaged/Defective</MenuItem>
        <MenuItem onClick={handleCustomSelect}>Custom</MenuItem>
      </Menu>
    </>
  );
};

const ExchangeAcceptNew = ({ row, emailList, setSearchValue }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [comment, setComment] = useState("");
  const [damageSelected, setDamageSelected] = useState(false);
  const [wrongProductSelected, setWrongProductSelected] = useState(false);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setComment("");
    setAnchorEl(null);
  };

  const handleClick = (target) => {
    console.log(target);
    setAnchorEl(target);
  };

  const handleApprove = async (option) => {
    dispatch(showFullScreenLoader());
    const email =
      "email" in row && !emailList.includes(row.email) ? row.email : "";
    const payload = createDelhiveryReverseOrderPayload(row);
    console.log("PAYLOAD", payload, comment, option, row);
    if (comment) {
      //   console.log("commented");
      dispatch(
        postDelhiveryReverseOrder(payload, email, row.fulfillment_id, comment)
      );
    } else {
      //   console.log("option");
      dispatch(
        postDelhiveryReverseOrder(payload, email, row.fulfillment_id, option)
      );
    }
    handleClose();
    setSearchValue("");
  };
  console.log("anchor el for main", anchorEl);
  return (
    <>
      {/* select*/}
      <Selector
        row={row}
        emailList={emailList}
        handleOpenCustomCommentBox={handleClick}
        setComment={setComment}
        setWrongProductSelected={setWrongProductSelected}
        setDamageSelected={setDamageSelected}
        setSearchValue={setSearchValue}
        submitApprove={handleApprove}
      />

      {/* custom comment */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
          }}
        >
          <Typography>
            {damageSelected
              ? "Add more details for damage/defective"
              : wrongProductSelected
              ? "Add more details on the product"
              : "Add custom comment"}
          </Typography>
          <br />
          <TextField
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            size="medium"
          />
          <br />
          <Button
            disabled={comment === ""}
            variant="contained"
            color="secondary"
            onClick={handleApprove}
          >
            Accept Exchange
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default ExchangeAcceptNew;
