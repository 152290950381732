import React from 'react'
import { useState } from 'react';

import { Typography,Modal,Box,TextField,Button } from '@mui/material';
import { useDispatch } from 'react-redux';

import { updateNdrOrder, sendDraftOrderInvoice, convertDraftToActiveOrderShopify } from '../../../actions/ordersActions';
import { showLoading } from '../../../actions/loaderActions';

const DraftOrderModal = ({open, setDraftOrderModal ,rowData}) => {
  const [openUp, setOpenUp] = React.useState(open);
  console.log(rowData)

  const dispatch = useDispatch()

  const handleClose = () => {
    setOpenUp(false)
    setDraftOrderModal(false)
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    textAlign : "center"
  };

  const handleInvoiceSend = () => {
    dispatch(showLoading())
    dispatch(sendDraftOrderInvoice({id: rowData.new_draftOrder_id.toString()}))
  }

  const handleActivateOrder = () => {
    dispatch(showLoading())
    dispatch(convertDraftToActiveOrderShopify({id: rowData.new_draftOrder_id.toString()}, rowData.fulfillment_id, rowData.package_status))
  }

  const handleActivateOrderPending = () => {
    dispatch(showLoading())
    dispatch(convertDraftToActiveOrderShopify({id: rowData.new_draftOrder_id.toString(), pending : true}, rowData.fulfillment_id, rowData.package_status))
  }

  return (
    <div>
        <Modal
            open={openUp}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight="Bold">
                Draft Order Details : 
            </Typography>
            <h4>Draft Order Number : {rowData.new_draftOrder}</h4>
            <h4>Draft Order Id : {rowData.new_draftOrder_id}</h4>
            <h4>Email Id : {rowData.email}</h4>
            <Box sx={{display:"flex", justifyContent:"space-around"}}>
                <Button variant='contained' color='error' onClick={handleInvoiceSend}>Send Invoice</Button>
                <Button variant='contained' color='primary' onClick={handleActivateOrder}>Place Paid Order</Button>
                <Button variant='contained' color='primary' onClick={handleActivateOrderPending}>Place Pending Order</Button>
            </Box>
        </Box>
        </Modal>
    </div>
  )
}

export default DraftOrderModal