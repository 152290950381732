import React from "react";
import { useState, useEffect } from "react";
import {
  MenuItem,
  Box,
  Button,
  Paper,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
} from "@mui/material";
import MuiTextField from "@mui/material/TextField";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { Stack } from "@mui/system";
import AssignTicketTable from "./AssignTicketTable";
import AssignTicketBox from "./AssignTicketBox";
//import { Dashboard } from '@mui/icons-material'

const TextField = styled(MuiTextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    paddingLeft: 0,
  },
  "& .MuiInputAdornment-root": {
    backgroundColor: "lightblue",
    padding: "28px 14px",
    borderTopLeftRadius: "3px",
    borderBottomLeftRadius: "3px",
  },
}));

const AssignTicketFilter = ({ tickets, users }) => {
  const CaseStatusArray = ["All", "new", "open", "closed"];
  const CategoryArray = [
    "All",
    "Exchanges",
    "Delivery & Order Status",
    "Order Issues",
    "Payment & Vouchers",
    "Order Cancellation",
    "Order Modification & Issues",
  ];

  const returnExchange = [
    "All",
    "How to initiate the exchange?",
    "I want to exchange but I'm unable to do it",
    "When will my pickup be done?",
    "Where is my Credit voucher/Gift card?",
    "I'm unable to use my Gift card/credit voucher",
    "Why is my pickup not yet done?",
  ];

  const deliveryOrder = [
    "All",
    "How can I track my order?",
    "I want to return my order",
    "Where is my order?",
    "Is COD Available or not?",
  ];

  const orderIssues = [
    "All",
    "How do I cancel my order?",
    "I'm unable to place my order",
    "I have ordered the wrong product?",
  ];

  const paymentVoucher = [
    "All",
    "How to apply voucher/giftcard?",
    "How do I pay for my order?",
    "What is the validity of the giftcard?",
  ];

  const LastSendedByArray = ["All", "None", "customer", "customercare"];
  const TicketAgeArray = ["All", "0-5", "6-10", "11-15", "15+"];
  const PriorityArray = ["All", "Low", "Medium", "High", "None"];
  const PaymnentStatusArray = ["All", "COD", "Paid"];
  const searchByList = ["ticket Id", "order Id", "customer Id"];

  const allQuestions = ["All"].concat(
    returnExchange.slice(1),
    deliveryOrder.slice(1),
    orderIssues.slice(1),
    paymentVoucher.slice(1)
  );

  const [ticketSection, setTicketSection] = useState("All");
  const [data, setData] = useState([]);
  const [resetPage, setResetPage] = useState(0);
  const [searchType, setSearchType] = useState("ticket Id");
  const [searchId, setSearchId] = useState("");
  const [messageStatus, setMessageStatus] = useState("All");
  const [caseStatus, setCaseStatus] = useState("All");
  const [categoryType, setCategoryType] = useState("All");
  const [questions, setQuestions] = useState(allQuestions);
  const [paymentStatus, setPaymentStatus] = useState("All");
  const [questionSelected, setQuestionSelected] = useState("All");
  const [ticketAgeSelected, setTicketAgeSelected] = useState("All");
  const [prioritySelected, setPrioritySelected] = useState("All");
  const [isFilteredEmpty, setIsFilteredEmpty] = useState(false);
  const [createdStartDate, setCreatedStartDate] = useState("");
  const [createdEndDate, setCreatedEndDate] = useState(
    new Date().toLocaleDateString("en-in").split("/").reverse().join("-")
  );
  const [updatedStartDate, setUpdatedStartDate] = useState("");
  const [updatedEndDate, setUpdatedEndDate] = useState(
    new Date().toLocaleDateString("en-in").split("/").reverse().join("-")
  );
  const [sortDirection, setSortDirection] = useState("Descending");

  useEffect(() => {
    let questionFiltered = allQuestions;
    if (categoryType === "Exchanges") {
      questionFiltered = returnExchange;
    } else if (categoryType === "Delivery & Order Status") {
      questionFiltered = deliveryOrder;
    } else if (categoryType === "Order Issues") {
      questionFiltered = orderIssues;
    } else if (categoryType === "Payment & Vouchers") {
      questionFiltered = paymentVoucher;
    }
    setQuestions(questionFiltered);
  }, [categoryType]);

  useEffect(() => {
    if (!tickets.length > 0) return;
    handleFilter();
  }, [tickets, ticketSection]);

  const handleCancelFilter = () => {
    const filtered = tickets.filter(
      (t) =>
        ticketSection === "All" ||
        (ticketSection === "New" && t.package_status === "new") ||
        (ticketSection === "New & Open" &&
          t.package_status === "open" &&
          !("lastSendedBy" in t)) ||
        (ticketSection === "Replied & Open" &&
          t.package_status === "open" &&
          t.lastSendedBy === "customer") ||
        (ticketSection === "Closed" && t.package_status === "closed")
    );
    setData(filtered);

    setIsFilteredEmpty(false);
    setSearchType("ticket Id");
    setSearchId("");
    setMessageStatus("All");
    setCaseStatus("All");
    setCategoryType("All");
    setQuestions(allQuestions);
    setQuestionSelected("All");
    setPrioritySelected("All");
    setPaymentStatus("All");
    setCreatedStartDate("");
    setCreatedEndDate(
      new Date().toLocaleDateString("en-in").split("/").reverse().join("-")
    );
    setUpdatedStartDate("");
    setUpdatedEndDate(
      new Date().toLocaleDateString("en-in").split("/").reverse().join("-")
    );
    setResetPage(Math.random());
  };
  const handleCheck = () => {
    const msInHour = 1000 * 60 * 60;

    const filtered = tickets.filter((t) => {
      return (
        Math.round(Math.abs(new Date() - new Date(t.updated_at)) / msInHour) >
          48 &&
        t.package_status === "open" &&
        (!("lastSendedBy" in t) || t.lastSendedBy === "customer")
      );
    });
    setData(filtered);
  };
  const handleFilter = () => {
    setIsFilteredEmpty(false);
    const filtered = tickets.filter(
      (t) =>
        (ticketSection === "All" && t.package_status !== "closed") ||
        (ticketSection === "New" && t.package_status === "new") ||
        (ticketSection === "New & Open" &&
          t.package_status === "open" &&
          !("lastSendedBy" in t)) ||
        (ticketSection === "Replied & Open" &&
          t.package_status === "open" &&
          t.lastSendedBy === "customer") ||
        (ticketSection === "Closed" && t.package_status === "closed")
    );
    const searchFiltered = filtered.filter(
      (t) =>
        (searchType === "ticket Id" &&
          String(t.original_order_id).startsWith(searchId)) ||
        (searchType === "order Id" &&
          String(t.order_id).startsWith(searchId)) ||
        (searchType === "customer Id" &&
          String(t.customer_id).startsWith(searchId))
    );
    const lastSendedFiltered = searchFiltered.filter(
      (t) =>
        messageStatus === "All" ||
        t.lastSendedBy === messageStatus ||
        (messageStatus === "None" && !("lastSendedBy" in t))
    );

    const questionFiltered = lastSendedFiltered.filter(
      (t) =>
        (categoryType === "All" || t.category === categoryType) &&
        (questionSelected === "All" || t.question === questionSelected) &&
        (caseStatus === "All" || t.package_status === caseStatus)
    );

    const createdFiltered = questionFiltered.filter(
      (t) =>
        (!createdStartDate ||
          new Date(new Date(createdStartDate).toLocaleDateString()) <=
            new Date(new Date(t.created_at).toLocaleDateString())) &&
        new Date(new Date(createdEndDate).toLocaleDateString()) >=
          new Date(new Date(t.created_at).toLocaleDateString())
    );

    const updatedFiltered = createdFiltered.filter(
      (t) =>
        (!updatedStartDate ||
          new Date(new Date(updatedStartDate).toLocaleDateString()) <=
            new Date(new Date(t.updated_at).toLocaleDateString())) &&
        new Date(new Date(updatedEndDate).toLocaleDateString()) >=
          new Date(new Date(t.updated_at).toLocaleDateString())
    );
    const ticketAgeFiltered = updatedFiltered.filter(
      (t) =>
        ticketAgeSelected === "All" ||
        (ticketAgeSelected === "0-5" && t.ticketAge >= 0 && t.ticketAge <= 5) ||
        (ticketAgeSelected === "6-10" &&
          t.ticketAge >= 6 &&
          t.ticketAge <= 10) ||
        (ticketAgeSelected === "11-15" &&
          t.ticketAge >= 11 &&
          t.ticketAge <= 15) ||
        (ticketAgeSelected === "15+" && t.ticketAge > 15)
    );
    const sorted =
      sortDirection === "Descending"
        ? ticketAgeFiltered
        : ticketAgeFiltered.reverse();
    const prioritySorted = sorted.filter(
      (t) =>
        prioritySelected === "All" ||
        t.priority === prioritySelected ||
        (!("priority" in t) && prioritySelected === "None")
    );
    const paymentSorted = prioritySorted.filter(
      (t) => paymentStatus === "All" || t.payment === paymentStatus
    );
    // console.log(
    //   "sorted",
    //   sorted.map((t) => ({
    //     ticket_id: t.fulfillment_id,
    //     date: t.created_at,
    //     order_id: t.order_id,
    //     priority: t.priority,
    //   }))
    // );
    if (paymentSorted.length === 0) {
      setIsFilteredEmpty(true);
    }
    setData(paymentSorted);
  };

  return (
    <div>
      <AssignTicketBox tickets={data} users={users} />
      <br />
      <br />
      <Box mb={2} p={3} component={Paper}>
        <Stack direction="column" gap={2}>
          <Stack direction={"row"}>
            <TextField
              id={"select"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TextField
                      select
                      InputProps={{
                        style: {
                          fontWeight: 800,
                          textAlign: "center",
                          fontSize: "1.2rem",
                          textTransform: "capitalize",
                        },
                        disableUnderline: true,
                      }}
                      value={searchType}
                      onChange={(e) => setSearchType(e.target.value)}
                      variant="standard"
                      style={{ width: "140px" }}
                    >
                      {searchByList.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </InputAdornment>
                ),
              }}
              placeholder="Search"
              value={searchId}
              onChange={(e) => setSearchId(e.target.value)}
              variant="outlined"
              // style={{ width: "200px" }}
            />
          </Stack>
          <Stack direction={"row"} gap={3} sx={{ alignItems: "center" }}>
            <TextField
              select
              value={messageStatus}
              onChange={(e) => setMessageStatus(e.target.value)}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <h3
                      style={{
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      Last Sended By
                    </h3>
                  </InputAdornment>
                ),
              }}
              className="select"
            >
              {LastSendedByArray.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              value={caseStatus}
              onChange={(e) => setCaseStatus(e.target.value)}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <h3
                      style={{
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      Case Status
                    </h3>
                  </InputAdornment>
                ),
              }}
              className="select"
            >
              {CaseStatusArray.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              value={categoryType}
              onChange={(e) => setCategoryType(e.target.value)}
              variant="outlined"
              className="select"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <h3
                      style={{
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      Category
                    </h3>
                  </InputAdornment>
                ),
              }}
            >
              {CategoryArray.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              value={questionSelected}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <h3
                      style={{
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      Questions
                    </h3>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setQuestionSelected(e.target.value)}
              variant="outlined"
              className="select"
            >
              {questions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              value={ticketAgeSelected}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <h3
                      style={{
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      Ticket Age (Days)
                    </h3>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setTicketAgeSelected(e.target.value)}
              variant="outlined"
              className="select"
            >
              {TicketAgeArray.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              value={prioritySelected}
              onChange={(e) => setPrioritySelected(e.target.value)}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <h3
                      style={{
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      Priority
                    </h3>
                  </InputAdornment>
                ),
              }}
              className="select"
            >
              {PriorityArray.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              value={paymentStatus}
              onChange={(e) => setPaymentStatus(e.target.value)}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <h3
                      style={{
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      Payment
                    </h3>
                  </InputAdornment>
                ),
              }}
              className="select"
            >
              {PaymnentStatusArray.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Stack direction={"row"} gap={2}>
            <TextField
              variant="outlined"
              InputProps={{
                inputProps: { max: createdEndDate },
                startAdornment: (
                  <InputAdornment position="start">
                    <h3
                      style={{
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      Created From
                    </h3>
                  </InputAdornment>
                ),
              }}
              value={createdStartDate}
              InputLabelProps={{ shrink: true }}
              type="date"
              onChange={(e) => {
                setCreatedStartDate(e.target.value);
              }}
            />
            <TextField
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <h3
                      style={{
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      Created To
                    </h3>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{ shrink: true }}
              type="date"
              onChange={(e) => {
                setCreatedEndDate(e.target.value);
              }}
              defaultValue={createdEndDate}
            />
            <TextField
              variant="outlined"
              value={updatedStartDate}
              InputProps={{
                inputProps: { max: updatedEndDate },
                startAdornment: (
                  <InputAdornment position="start">
                    <h3
                      style={{
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      Updated From
                    </h3>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{ shrink: true }}
              type="date"
              onChange={(e) => {
                setUpdatedStartDate(e.target.value);
              }}
            />
            <TextField
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <h3
                      style={{
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      Updated To
                    </h3>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{ shrink: true }}
              type="date"
              onChange={(e) => {
                setUpdatedEndDate(e.target.value);
              }}
              defaultValue={updatedEndDate}
            />
          </Stack>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              value={sortDirection}
              onChange={(e) => setSortDirection(e.target.value)}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="Descending"
                control={<Radio />}
                label="Descending"
              />
              <FormControlLabel
                value="Ascending"
                control={<Radio />}
                label="Ascending"
              />
            </RadioGroup>
          </FormControl>
          <Stack
            direction={"row"}
            gap={2}
            sx={{ marginRight: 0, marginLeft: "auto" }}
          >
            <Button onClick={handleCheck}>check for tickets</Button>
            <Button
              variant="contained"
              color={"error"}
              onClick={handleCancelFilter}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleFilter();
                setResetPage(Math.random());
              }}
            >
              Filter
            </Button>
          </Stack>
        </Stack>
      </Box>
      <br />
      <br />
      {/* <AssignTicketTable data={data} /> */}
    </div>
  );
};
export default AssignTicketFilter;
