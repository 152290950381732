import { Box, CircularProgress, Tabs, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRepeatingProductsData } from "../../actions/ordersActions";
import RepeatingProductsTable from "../repeatingProductsWidgets/RepeatingProductsTable";

const dateToday = new Date();
const date1DayAgo = new Date(new Date().setDate(new Date().getDate() - 1));
const date2DayAgo = new Date(new Date().setDate(new Date().getDate() - 2));
const date3DayAgo = new Date(new Date().setDate(new Date().getDate() - 3));
const dateFilters = ["All", dateToday, date1DayAgo, date2DayAgo, date3DayAgo];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RepeatingProducts = () => {
  const [value, setValue] = useState(0);
  const { repeatingProductsData } = useSelector((state) => state.orderDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRepeatingProductsData());
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function findMostRecentDate(dates) {
    return dates.reduce((latest, current) => {
      return new Date(current) > new Date(latest) ? current : latest;
    });
  }
  const createDatesList = (groupedOrders) => {
    return groupedOrders.reduce((acc, groupedOrder) => {
      const list = groupedOrder.map((o) => o.created_at);
      acc = [...acc, ...list];
      return acc;
    }, []);
  };
  const sortByRecentDate = (a, b) => {
    const datesA = createDatesList(a.groupedOrders);
    const datesB = createDatesList(b.groupedOrders);
    const maxA = findMostRecentDate(datesA);
    const maxB = findMostRecentDate(datesB);
    return new Date(maxB) - new Date(maxA);
  };
  const isSameDay = (groupedOrders, date) => {
    const orderDate = findMostRecentDate(createDatesList(groupedOrders));
    return (
      new Date(orderDate).getDate() === new Date(date).getDate() &&
      new Date(orderDate).getFullYear() === new Date(date).getFullYear() &&
      new Date(orderDate).getMonth() === new Date(date).getMonth()
    );
  };

  console.log("fetch", repeatingProductsData);
  return (
    <div>
      <h1
        style={{
          marginBottom: 4,
        }}
      >
        Repeating Products
        <span>
          {!repeatingProductsData ? (
            <CircularProgress size={20} />
          ) : (
            `(Sync at ${repeatingProductsData.updated_at})`
          )}
        </span>
      </h1>
      {repeatingProductsData && (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {dateFilters.map((d, i) => (
                <Tab
                  key={i}
                  label={
                    d === "All" ? d : new Date(d).toLocaleDateString("en-in")
                  }
                  {...a11yProps(i)}
                />
              ))}
            </Tabs>
          </Box>
          {dateFilters.map((d, i) => (
            <TabPanel key={i} value={value} index={i}>
              <RepeatingProductsTable
                data={repeatingProductsData.sales_data
                  .filter(
                    (sd) =>
                      sd.customerId !== 6275588194591 &&
                      sd.customerId !== 6758706741535 &&
                      (d === "All" || isSameDay(sd.groupedOrders, d))
                  )
                  .sort(sortByRecentDate)}
              />
            </TabPanel>
          ))}
        </Box>
      )}
    </div>
  );
};

export default RepeatingProducts;
