import { Stack, Skeleton, TableCell, TableRow } from "@mui/material";

export const PageSkeleton = () => {
    return (
        <>
            <Skeleton
                animation="wave"
                variant="text"
                sx={{ fontSize: "3rem" }}
            />
            <Skeleton
                animation="wave"
                variant="rounded"
                width={300}
                height={50}
                sx={{ marginBottom: "10px" }}
            />
            <Skeleton
                animation="wave"
                variant="rounded"
                width={300}
                height={50}
                sx={{ marginBottom: "10px" }}
            />
            <Skeleton
                animation="wave"
                variant="rounded"
                width={300}
                height={50}
                sx={{ marginBottom: "10px" }}
            />
            <Skeleton
                animation="wave"
                variant="rounded"
                height={500}
                sx={{ marginBottom: "10px" }}
            />
        </>
    );
};

export const SkeletonComponent = () => {
    return (
        <Stack spacing={1}>
            {/* <Skeleton variant="text" sx={{ fontSize: '1rem', width:"300px" }} /> */}
            <Skeleton
                animation="wave"
                variant="rounded"
                width={300}
                height={300}
            />
            {/* {[1,2,3].map(x => (
                <Skeleton key={x} variant="text" sx={{ fontSize: '0.75rem', width:"200px",paddingLeft:"10px" }} />
            ))} */}
        </Stack>
    );
};

export const TableSkeletonComponent = () => {
    return (
        <Stack spacing={1}>
            <Skeleton
                animation="wave"
                variant="rounded"
                width={1000}
                height={50}
            />
            <br />
            {[1, 2, 3, 4].map((x) => (
                <Skeleton
                    key={x}
                    animation="wave"
                    variant="rounded"
                    width={1000}
                    height={100}
                />
            ))}
        </Stack>
    );
};


export const WinnerTableSkeletonComponent = () => {
    return (
        <TableRow>
            <TableCell colSpan={3}>
            <Stack spacing={1}>
              <Skeleton  animation="wave" variant="rounded" width="100%" height={20} />
              <Skeleton  animation="wave" variant="rounded" width="100%" height={20} />
              </Stack>
            </TableCell>
          </TableRow>
    );
};


export const SingleBarSkeletonComponent = () => {
    return (
        <Stack spacing={1}>
            <Skeleton
                animation="wave"
                variant="rounded"
                width={1000}
                height={50}
            />
            <br />
            <Skeleton
                animation="wave"
                variant="rounded"
                width={1000}
                height={80}
            />
        </Stack>
    );
};
