import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { read, utils } from "xlsx";
import { Button, TextField, Stack, MenuItem } from "@mui/material";
import { toast } from "react-toastify";
import { delhiveryGstData, gstData } from "../../actions/reportActions";
import { getXBGstDataForAllOrders } from "../../utils/gstHelpers";
import XBGstTable from "../ordersWidgets/gst/XBGstTable";

const DelhiveryGst = () => {
  const { deliveredOrders } = useSelector((state) => state.reports);
  const [monthIndex, setMonthIndex] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const dispatch = useDispatch();

  const monthIndexArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let selectedFile;

  useEffect(() => {
    dispatch(delhiveryGstData());
  }, []);

  const handler = (e) => {
    selectedFile = e.target.files[0];
  };

  // const handleSyncData = () => {
  //   const excelFileData = excelFile.flat().filter((e) => e.TEMPINSCANDATE1);
  //   console.log("excel", excelFileData, deliveredOrders);
  //   console.log("Intransit");
  //   // get all in transit orders for that month
  //   //can be delivered and rto at the same month show different rows
  //   //can it be shipped and rto without delivery , check for such orders just in case
  //   console.log(
  //     "shipped then rto",
  //     excelFileData.filter((ec) => !ec.DELIVERYDATE && ec.RTODATE)
  //   );
  //   console.log("RTO");
  //   const finalOrders = excelFileData //filter order that has inscan date
  //     .filter(
  //       (ec) => {
  //         if (ec.TEMPINSCANDATE1) {
  //           const dateArr = ec.TEMPINSCANDATE1.split("/");
  //           const date = dateArr[1] + "/" + dateArr[0] + "/" + dateArr[2];
  //           console.log(new Date(date), new Date(date).getMonth());
  //           return new Date(date).getMonth() === monthIndex;
  //         } else {
  //           return false;
  //         }
  //       }
  //       // new Date(ec.MANIFESTATIONDATE).getMonth() === monthIndex ||
  //     )
  //     .filter((x) => x.POID.includes("."))
  //     .reduce((acc, item) => {
  //       const findItemInDB = deliveredOrders.find(
  //         (d) => item.POID === String(d.fulfillment_id)
  //       );
  //       if (findItemInDB) {
  //         // two conditions one for rto and no rto
  //         if (
  //           item.SHIPPING_STATUS === "RTO" ||
  //           item.SHIPPING_STATUS === "RTODispute"
  //         ) {
  //           //check if rto is of february, picked up date has already been filtered for the month so check only rto date
  //           const rtoDateArr = item.RTODATE.split("/");
  //           const rtoDate =
  //             rtoDateArr[1] + "/" + rtoDateArr[0] + "/" + rtoDateArr[2];
  //           //picked up date and rto date same month , check if it has delivery date of same
  //           if (new Date(rtoDate).getMonth() === monthIndex) {
  //             //assume cancelled also as delivered
  //             const newObj1 = {
  //               fulfillment_id: findItemInDB.fulfillment_id,
  //               order_items: findItemInDB.order_items,
  //               shipping_charges: findItemInDB.shipping_charges,
  //               billing_state: findItemInDB.billing_state,
  //               total: findItemInDB.total,
  //               status: item.CLIENTSTATUS,
  //               inscanDate: item.TEMPINSCANDATE1,
  //               delivered_date: item.DELIVERYDATE,
  //               shipping_date: item.SHIPPINGDATE,
  //               shipping_status: item.SHIPPING_STATUS,
  //               customer_name: `${findItemInDB.shipping_customer_name} ${findItemInDB.shipping_last_name}`,
  //               rto_date: item.RTODATE,
  //               awb: item.SHIPPINGID,
  //             };
  //             const newObj2 = {
  //               fulfillment_id: findItemInDB.fulfillment_id,
  //               order_items: findItemInDB.order_items,
  //               shipping_charges: findItemInDB.shipping_charges,
  //               billing_state: findItemInDB.billing_state,
  //               total: findItemInDB.total,
  //               status: "Delivered",
  //               inscanDate: item.TEMPINSCANDATE1,
  //               delivered_date: item.DELIVERYDATE,
  //               shipping_date: item.SHIPPINGDATE,
  //               shipping_status: "Delivered",
  //               customer_name: `${findItemInDB.shipping_customer_name} ${findItemInDB.shipping_last_name}`,
  //               rto_date: "",
  //               awb: item.SHIPPINGID,
  //             };
  //             acc.push(newObj1);
  //             acc.push(newObj2);
  //           }
  //         } else {
  //           const newObj = {
  //             fulfillment_id: findItemInDB.fulfillment_id,
  //             order_items: findItemInDB.order_items,
  //             shipping_charges: findItemInDB.shipping_charges,
  //             billing_state: findItemInDB.billing_state,
  //             total: findItemInDB.total,
  //             status: item.CLIENTSTATUS,
  //             inscanDate: item.TEMPINSCANDATE1,
  //             delivered_date: item.DELIVERYDATE,
  //             shipping_date: item.SHIPPINGDATE,
  //             shipping_status: item.SHIPPING_STATUS,
  //             customer_name: `${findItemInDB.shipping_customer_name} ${findItemInDB.shipping_last_name}`,
  //             rto_date: item.RTODATE,
  //             awb: item.SHIPPINGID,
  //           };
  //           acc.push(newObj);
  //         }
  //       } else {
  //         console.log("not found", item.POID);
  //       }
  //       return acc;
  //     }, []);

  //   const gstCalculatedData = getXBGstDataForAllOrders(finalOrders);
  //   console.log(gstCalculatedData);
  //   setFilteredOrders(gstCalculatedData);
  // };

  const handleSyncData = () => {
    const finalOrders = deliveredOrders
      .filter((d) => {
        return (
          (new Date(d.pickedup_date).getMonth() === monthIndex &&
            new Date(d.pickedup_date).getFullYear() === 2024) ||
          (d.rto_declared_on &&
            new Date(d.rto_declared_on).getMonth() === monthIndex &&
            new Date(d.rto_declared_on).getFullYear() === 2024)
        );
      })
      .reduce((acc, d) => {
        // if rto and same month delivered
        if (
          d.rto_declared_on &&
          new Date(d.rto_declared_on).getMonth() ===
            new Date(d.pickedup_date).getMonth() &&
          new Date(d.rto_declared_on).getFullYear() ===
            new Date(d.pickedup_date).getFullYear()
        ) {
          const newDeliveredObj = {
            fulfillment_id: d.fulfillment_id,
            order_items: d.order_items,
            shipping_charges: d.shipping_charges,
            billing_state: d.billing_state,
            total: d.total,
            status: "Delivered",
            inscanDate: d.pickedup_date,
            delivered_date: "",
            shipping_status: "Delivered",
            customer_name: `${d.shipping_customer_name} ${d.shipping_last_name}`,
            rto_date: "",
            awb: d.awb_code,
          };
          const newRtoObj = {
            fulfillment_id: d.fulfillment_id,
            order_items: d.order_items,
            shipping_charges: d.shipping_charges,
            billing_state: d.billing_state,
            total: d.total,
            status: "RTO",
            inscanDate: d.pickedup_date,
            delivered_date: "",
            shipping_status: "RTO",
            customer_name: `${d.shipping_customer_name} ${d.shipping_last_name}`,
            rto_date: d.rto_declared_on,
            awb: d.awb_code,
          };
          acc.push(newDeliveredObj);
          acc.push(newRtoObj);
        }
        //if rto and not same month
        else if (
          d.rto_declared_on &&
          (new Date(d.rto_declared_on).getMonth() !==
            new Date(d.pickedup_date).getMonth()) &
            (new Date(d.rto_declared_on).getMonth() === monthIndex) &&
          new Date(d.rto_declared_on).getFullYear() === 2024
        ) {
          const newObj = {
            fulfillment_id: d.fulfillment_id,
            order_items: d.order_items,
            shipping_charges: d.shipping_charges,
            billing_state: d.billing_state,
            total: d.total,
            status: "RTO",
            inscanDate: d.pickedup_date,
            delivered_date: "",
            shipping_status: "RTO",
            customer_name: `${d.shipping_customer_name} ${d.shipping_last_name}`,
            rto_date: d.rto_declared_on,
            awb: d.awb_code,
          };
          acc.push(newObj);
        }
        //all intransit and delivered
        else {
          const newObj = {
            fulfillment_id: d.fulfillment_id,
            order_items: d.order_items,
            shipping_charges: d.shipping_charges,
            billing_state: d.billing_state,
            total: d.total,
            status:
              d.package_status === "Delivered" ? "Delivered" : "In Transit",
            inscanDate: d.pickedup_date,
            delivered_date: d.delivery_date,
            shipping_status:
              d.package_status === "Delivered" ? "Delivered" : "In Transit",
            customer_name: `${d.shipping_customer_name} ${d.shipping_last_name}`,
            rto_date: "",
            awb: d.awb_code,
          };
          acc.push(newObj);
        }

        return acc;
      }, []);

    const gstCalculatedData = getXBGstDataForAllOrders(finalOrders);
    console.log(gstCalculatedData);
    setFilteredOrders(gstCalculatedData);
  };

  const handleAWBSubmit = () => {
    // dispatch(AWBSyncData());
  };

  return (
    <div>
      <div>
        <h3>Delhivery GST</h3>
        <div>
          <TextField
            style={{ width: "300px" }}
            select
            label="Select Month"
            value={monthIndex}
            onChange={(e) => {
              setMonthIndex(parseInt(e.target.value));
            }}
            variant="outlined"
            className="select"
          >
            {monthIndexArray.map((option, index) => (
              <MenuItem key={index} value={index}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <br />
        <br />
        <Button variant="contained" color="secondary" onClick={handleSyncData}>
          Sync Data
        </Button>
        <br />
        <XBGstTable gstData={filteredOrders} />
      </div>
      <br />
    </div>
  );
};

export default DelhiveryGst;
