import React from "react";
import { useState, useEffect } from "react";
import ProductViewModal from "./ReverseProductViewModal";
import {
  denyRErequest,
  getLastXBReverseAWB,
  moveToDeleted,
  postXBReverseOrder,
  reinstateAndDeleteAwbRE,
  reinstateRErequest,
} from "../../actions/reverseDeliveryActions";

import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TableFooter,
  TablePagination,
  Button,
  Popover,
  TextField,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useDispatch } from "react-redux";
import { showFullScreenLoader } from "../../actions/loaderActions";
import { getXBReverseShipmentDetails } from "../../actions/ordersActions";

import { XbReverseShipmentHistoryModal } from "../ordersWidgets/returns/XbReverseShipmentHistoryModal";
import {
  changeGiftcardToReplacement,
  changeReplacementToGiftcard,
} from "../../actions/shopifyActions";
import ExchangeAcceptNew from "./ExchangeAcceptNew";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function Row({ row, index, emailList, setSearchValue }) {
  const [productModal, setProductModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteAnchorEl, setDeleteAnchorEl] = useState(null);
  const [reason, setReason] = useState("");
  const [deleteReason, setDeleteReason] = useState("");
  const [openHistory, setOpenHistory] = useState(false);
  const [orderHistoryModal, setOrderHistoryModal] = useState(false);

  const dispatch = useDispatch();

  const handleOpenHistory = (awb) => {
    dispatch(getXBReverseShipmentDetails({ AWB: parseInt(awb) }));
    setOpenHistory(true);
    setOrderHistoryModal(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteReasonBox = (event) => {
    setDeleteAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setReason("");
    setAnchorEl(null);
  };

  const handleDeleteClose = () => {
    console.log("closing");
    setDeleteReason("");
    setDeleteAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const deleteOpen = Boolean(deleteAnchorEl);
  const id = open ? "simple-popover" : undefined;
  const deleteId = deleteOpen ? "simple-popover" : undefined;

  const handleData = () => {
    let tempArr = [];
    tempArr.push(row);
    setModalData(tempArr);
  };

  const handleDeny = () => {
    dispatch(showFullScreenLoader());
    handleClose();
    const payload = {
      id: row.fulfillment_id,
      reason,
    };
    const email =
      "email" in row && !emailList.includes(row.email) ? row.email : "";
    console.log(payload, email);
    dispatch(denyRErequest(payload, email, row.fulfillment_id));
    setSearchValue("");
  };

  const handleReinstate = () => {
    dispatch(showFullScreenLoader());
    dispatch(reinstateRErequest(row.fulfillment_id));
    setSearchValue("");
  };

  const calculateOrderAge = () => {
    const timeDiff = new Date() - new Date(row.created_at);
    const age = timeDiff / (1000 * 60 * 60 * 24);
    return String(Math.round(age));
  };

  const handleReinstateAndDeleteAwb = () => {
    console.log("reinstate and delete awb");
    dispatch(showFullScreenLoader());
    dispatch(reinstateAndDeleteAwbRE(row.fulfillment_id, row.awb_code));
  };

  const handleDelete = () => {
    console.log("delete");
    dispatch(showFullScreenLoader());
    handleDeleteClose();
    dispatch(moveToDeleted(row.fulfillment_id, row.awb_code, deleteReason));
  };

  return (
    <>
      <TableRow
        component={Paper}
        sx={{
          "&:hover": {
            backgroundColor: "rgb(220,220,220)",
          },
        }}
      >
        <TableCell
          component="th"
          scope="row"
          style={{
            color: row.tags?.includes("replacement") ? "red" : "blue",
          }}
        >
          <Typography>{row.fulfillment_id}</Typography>
          {row.tags?.includes("replacement") ? (
            <Typography
              style={{
                fontSize: "x-small",
                borderBottom: "2px dotted",
                width: "max-content",
              }}
            >
              2nd Exchange
            </Typography>
          ) : (
            ""
          )}
        </TableCell>
        <TableCell>
          {new Date(row.sync_date).toLocaleDateString("en-in")}
        </TableCell>
        <TableCell style={{ maxWidth: "200px" }}>
          <Typography style={{ color: "blue" }}>
            {row.address ? row.address.name : ""}
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ fontSize: "small" }}
          >
            {row.address ? row.address.address1 : ""},
            {row.address ? `${row.address.city},${row.address.province}` : ""},
            {row.address ? row.address.zip : ""}
            <br />
            Ph : {row.address ? row.address.phone : ""}
            <br />
            Alt : {row.alternatePhone ? row.alternatePhone : ""}
            <br />
            Email : {row.email ? row.email : ""}
          </Typography>
        </TableCell>
        <TableCell>
          <Box>
            <Typography>{row.awb_code ? row.awb_code : "N/A"}</Typography>
            <Typography
              onClick={() => {
                setProductModal(true);
                handleData();
              }}
              style={{
                fontSize: "x-small",
                color: "blue",
                cursor: "pointer",
                borderBottom: "2px dotted",
                width: "max-content",
              }}
            >
              View Products
            </Typography>
            {productModal ? (
              <ProductViewModal
                productModal={productModal}
                tableData={modalData}
                setProductModal={setProductModal}
              />
            ) : (
              ""
            )}
          </Box>
        </TableCell>
        <TableCell align="left">
          <Box
            onClick={() => handleOpenHistory(row.awb_code)}
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "blue",
                textDecoration: "underline",
              },
            }}
          >
            {String(row.package_status).replace("bsync#", "")}
          </Box>
          {orderHistoryModal ? (
            <XbReverseShipmentHistoryModal
              open={orderHistoryModal}
              setOrderHistoryModal={setOrderHistoryModal}
            />
          ) : (
            ""
          )}
        </TableCell>
        <TableCell align="left">{calculateOrderAge()}</TableCell>
        <TableCell>
          {row.exchangeAction ? row.exchangeAction : "Not Provided"}
        </TableCell>
        <TableCell>
          {row.package_status.includes("new") ? (
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <ExchangeAcceptNew
                row={row}
                emailList={emailList}
                setSearchValue={setSearchValue}
              />
              <br />
              <>
                <Button
                  aria-describedby={id}
                  variant="contained"
                  color="error"
                  onClick={handleClick}
                >
                  Deny
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "20px",
                    }}
                  >
                    <Typography>Mention Rejection Reason</Typography>
                    <br />
                    <TextField
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      size="small"
                    />
                    <br />
                    <Button
                      disabled={reason === ""}
                      variant="contained"
                      color="secondary"
                      onClick={handleDeny}
                    >
                      Submit
                    </Button>
                  </Box>
                </Popover>
              </>
            </Box>
          ) : row.package_status.includes("rejected") ? (
            <Button variant="contained" onClick={handleReinstate}>
              Reinstate
            </Button>
          ) : (
            <Stack direction={"column"} gap={1}>
              {row.exchangeAction === "Giftcard" ? (
                <Button
                  variant="contained"
                  onClick={() =>
                    dispatch(changeGiftcardToReplacement(row.fulfillment_id))
                  }
                >
                  To Replacement
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => {
                    console.log("here");
                    dispatch(changeReplacementToGiftcard(row.fulfillment_id));
                  }}
                >
                  To voucher
                </Button>
              )}
              <Button
                color="secondary"
                variant="contained"
                onClick={handleReinstateAndDeleteAwb}
              >
                Reinstate
              </Button>
              <Button
                // fullWidth
                color="error"
                variant="outlined"
                onClick={handleDeleteReasonBox}
              >
                Delete
              </Button>
              <Popover
                id={deleteId}
                open={deleteOpen}
                anchorEl={deleteAnchorEl}
                onClose={handleDeleteClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px",
                  }}
                >
                  <Typography>Mention Delete Reason</Typography>
                  <br />
                  <TextField
                    value={deleteReason}
                    onChange={(e) => setDeleteReason(e.target.value)}
                    size="small"
                  />
                  <br />
                  <Button
                    disabled={deleteReason === ""}
                    variant="contained"
                    color="secondary"
                    onClick={handleDelete}
                  >
                    Submit
                  </Button>
                </Box>
              </Popover>
            </Stack>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}

const ReverseNewOrdersTable = ({ tableData, emailList, setSearchValue }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  console.log("table data", tableData);
  return (
    <>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow component={Paper}>
              <TableCell align="left">Order Number</TableCell>
              <TableCell align="left">Created On </TableCell>
              <TableCell align="left">Customer Details</TableCell>
              <TableCell align="left">AWB Code</TableCell>
              <TableCell align="left">Order Status</TableCell>
              <TableCell align="left">Order Age(days)</TableCell>
              <TableCell align="left">Requested</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? tableData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : tableData
            ).map((row, index) => (
              <Row
                key={index}
                row={row}
                index={index}
                emailList={emailList}
                setSearchValue={setSearchValue}
              />
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <TablePagination
        style={{
          overflow: "inherit",
          display: "flex",
          justifyContent: "center",
          background: "white",
          borderRadius: "10px",
        }}
        rowsPerPageOptions={[5, 10, 25, 100]}
        colSpan={3}
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </>
  );
};

export default ReverseNewOrdersTable;
