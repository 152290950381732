import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Backdrop,
  Box,
  CircularProgress,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";
import {
  clearReverseDataByStatus,
  getReverseDataByStatus,
  changeFilteredOrdersArray,
  getReverseDataByStatusModified,
} from "../../actions/reverseDeliveryActions";
import {
  getInvalidEmailList,
  getUnsuccessfulEmailList,
} from "../../actions/sesMailActions";
import ReverseNewOrdersTableDelhivery from "./ReverseNewOrdersTableDelhivery";
import { TableSkeletonComponent } from "../dashboard/helperComponents";
import ReverseInTransitFilter from "./ReverseInTransitFilter";
import { allProducts } from "../../actions/shopifyActions";
import ReverseInTransitFilter1 from "./ReverseInTransitFilter1";
import { allInventory } from "../../actions/inventoryActions";
import RPcancelFilter from "./RPcancelFilter";
import DeletedOrders from "./DeletedOrders";
import CanceledDelhivery from "./CanceledDelhivery";
import { addQuantityToReverseOrder } from "../../utils/helpers";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ReverseDelhivery = () => {
  const { reverse_by_status, filteredOrdersReverse } = useSelector(
    (state) => state.reverseDetails
  );
  const { invalidEmail, unsuccessfulEmail } = useSelector(
    (state) => state.sesDetails
  );
  const { shopify_products } = useSelector((state) => state.shopifyDetails);
  const { loading } = useSelector((state) => state.loader);
  const { stock } = useSelector((state) => state.inventoryDetails);

  const [selected, setSelected] = useState(["new"]);
  const [value, setValue] = useState(0);
  const [emailList, setEmailList] = useState();
  const [searchValue, setSearchValue] = useState("");

  const dispatch = useDispatch();

  const todayDate = new Date();
  const changeDateFormat = (date) => {
    const reqDate = (date.getDate() <= 9 ? "0" : "") + date.getDate();
    const reqMonth =
      (date.getMonth() + 1 <= 9 ? "0" : "") + (date.getMonth() + 1);
    const reqFormat = `${[date.getFullYear(), reqMonth, reqDate].join(
      "-"
    )}T00:00:00+05:30`;
    return reqFormat;
  };

  const firstDayLastMonth = changeDateFormat(
    new Date(todayDate.getFullYear(), todayDate.getMonth() - 1, 1)
  );

  useEffect(() => {
    console.log("TAB changed!");
    dispatch(clearReverseDataByStatus());
    dispatch(
      getReverseDataByStatusModified({
        p_status: selected,
        from_date: firstDayLastMonth,
      })
    );
    if (shopify_products.length === 0) dispatch(allProducts());
    if (invalidEmail.length === 0) dispatch(getInvalidEmailList());
    if (unsuccessfulEmail.length === 0) dispatch(getUnsuccessfulEmailList());
    if (stock.length === 0) dispatch(allInventory());
  }, [selected]);

  useEffect(() => {
    const totalMail = invalidEmail.concat(unsuccessfulEmail);
    setEmailList(totalMail);
  }, [invalidEmail, unsuccessfulEmail]);

  useEffect(() => {
    if (searchValue !== "" && !!searchValue) {
      const foundOrders = reverse_by_status.filter(
        (x) => x.fulfillment_id === parseFloat(searchValue.label)
      );
      if (foundOrders.length > 0) {
        dispatch(changeFilteredOrdersArray(foundOrders));
      } else {
        return;
      }
    } else {
      dispatch(changeFilteredOrdersArray(reverse_by_status));
    }
  }, [reverse_by_status, searchValue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  return (
    <>
      {loading && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress size={100} color="inherit" />
        </Backdrop>
      )}
      <h1>Delhivery Reverse Shipment</h1>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Autocomplete
          getOptionLabel={(option) => option.label || ""}
          freeSolo
          disablePortal
          id="combo-box-demo"
          options={reverse_by_status.map((x) => ({
            label: String(x.fulfillment_id),
          }))}
          sx={{ width: 300, bgcolor: "white" }}
          renderInput={(params) => (
            <TextField {...params} label="Search OrderID" />
          )}
          size="small"
          value={searchValue}
          onChange={(event, newValue) => {
            setSearchValue(newValue);
          }}
          selectOnFocus
        />
      </div>
      <br />
      <br />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="New"
            {...a11yProps(0)}
            onClick={() => {
              setSelected(["new"]);
              setSearchValue("");
            }}
          />
          <Tab
            label="Rejected"
            {...a11yProps(1)}
            onClick={() => {
              setSelected(["rejected"]);
              setSearchValue("");
            }}
          />
          <Tab
            label="Pickup Pending"
            {...a11yProps(2)}
            onClick={() => {
              setSelected(["bsync#RPPickupPending", "bsync#RPOutForPickup"]);
              setSearchValue("");
            }}
          />
          <Tab
            label="In Transit"
            {...a11yProps(3)}
            onClick={() => {
              setSelected([
                "R_DLVD",
                "bsync#IT",
                "bsync#RPPickDone",
                "bsync#RAD",
                "bsync#OFD",
                "bsync#PU_Dispatched",
                "bsync#PU_In Transit",
                "bsync#PU_In_Transit",
                "bsync#PU_Pending",
              ]);
              setSearchValue("");
            }}
          />
          <Tab
            label="Approved"
            {...a11yProps(4)}
            onClick={() => {
              setSelected([
                "R_DLVD",
                "bsync#IT",
                "bsync#RPPickDone",
                "bsync#RAD",
                "bsync#OFD",
              ]);
              setSearchValue("");
            }}
          />
          <Tab
            label="Canceled"
            {...a11yProps(5)}
            onClick={() => {
              setSelected(["bsync#CN_Canceled"]);
              setSearchValue("");
            }}
          />
          <Tab
            label="Closed"
            {...a11yProps(6)}
            onClick={() => {
              setSelected(["bsync#RPCancel", "CN_Closed"]);
              setSearchValue("");
            }}
          />
          <Tab
            label="Deleted"
            {...a11yProps(7)}
            onClick={() => {
              setSelected(["deleted"]);
              setSearchValue("");
            }}
          />
        </Tabs>
      </Box>
      {/* new order , added quantity here */}
      <TabPanel value={value} index={0}>
        {filteredOrdersReverse.length > 0 &&
        shopify_products.length > 0 &&
        stock.length > 0 ? (
          <ReverseNewOrdersTableDelhivery
            tableData={addQuantityToReverseOrder(
              filteredOrdersReverse,
              stock,
              shopify_products
            )
              .sort((a, b) => new Date(a.sync_date) - new Date(b.sync_date))
              .reverse()}
            emailList={emailList}
            setSearchValue={setSearchValue}
            searchedValue={searchValue}
          />
        ) : (
          <TableSkeletonComponent />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {filteredOrdersReverse.length > 0 ? (
          <ReverseNewOrdersTableDelhivery
            tableData={filteredOrdersReverse
              .sort((a, b) => new Date(a.sync_date) - new Date(b.sync_date))
              .reverse()}
            setSearchValue={setSearchValue}
            searchedValue={searchValue}
          />
        ) : (
          <TableSkeletonComponent />
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {filteredOrdersReverse.length > 0 ? (
          <ReverseNewOrdersTableDelhivery
            tableData={filteredOrdersReverse
              .sort((a, b) => new Date(a.sync_date) - new Date(b.sync_date))
              .reverse()}
            setSearchValue={setSearchValue}
            searchedValue={searchValue}
          />
        ) : (
          <TableSkeletonComponent />
        )}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {/* Keep same component as Xpressbees */}
        <ReverseInTransitFilter1 setSearchValue={setSearchValue} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        {/* Keep same component as Xpressbees */}
        {filteredOrdersReverse.length > 0 ? (
          <ReverseInTransitFilter tableData={filteredOrdersReverse} />
        ) : (
          <TableSkeletonComponent />
        )}
      </TabPanel>
      <TabPanel value={value} index={5}>
        {/* Keep same component as Xpressbees */}
        {filteredOrdersReverse.length > 0 ? (
          <CanceledDelhivery tableData={filteredOrdersReverse} />
        ) : (
          <TableSkeletonComponent />
        )}
      </TabPanel>
      <TabPanel value={value} index={6}>
        {/* Keep same component as Xpressbees */}
        {filteredOrdersReverse.length > 0 ? (
          <RPcancelFilter tableData={filteredOrdersReverse} />
        ) : (
          <TableSkeletonComponent />
        )}
      </TabPanel>
      <TabPanel value={value} index={7}>
        {/* Keep same component as Xpressbees??? There are actions in this file */}
        {filteredOrdersReverse.length > 0 ? (
          <DeletedOrders
            tableData={filteredOrdersReverse
              .sort((a, b) => new Date(a.sync_date) - new Date(b.sync_date))
              .reverse()}
          />
        ) : (
          <TableSkeletonComponent />
        )}
      </TabPanel>
    </>
  );
};

export default ReverseDelhivery;
