import React, { useEffect, useState } from "react";
import { getVendorMaterialList } from "../../actions/materialActions";
import {
  Box,
  Divider,
  MenuItem,
  Paper,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";
import MaterialAmtTable from "./MaterialAmtTable";
import FabricAvailabilityPieChart from "./FabricAvailabilityPieChart";
function removeDuplicates(arr) {
  return arr.filter(
    (item, index, self) => index === self.findIndex((t) => t.code === item.code)
  );
}
const MaterialVendor = ({ vendorId }) => {
  const [loading, setLoading] = useState(false);
  const [fabrics, setFabrics] = useState([]);
  const [colors, setColors] = useState([]);
  const [vendorMaterials, setVendorMaterials] = useState([]);

  const [selectedFabric, setSelectedFabric] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  useEffect(() => {
    if (vendorId) {
      setLoading(true);
      getVendorMaterialList(vendorId)
        .then((data) => {
          if (data.statusCode === 200) {
            const materials = data.body;
            console.log("materials", materials);
            //get all the fabrics
            const vendorFabrics = materials.map((m) => ({
              code: m.fabricId,
              name: m.fabricName,
            }));
            const uniqueFabrics = removeDuplicates(vendorFabrics);
            setFabrics(uniqueFabrics);
            setSelectedFabric(uniqueFabrics[0].code);
            setVendorMaterials(materials);
            setLoading(false);
          }
        })
        .catch((e) => {
          console.log("err", e);
          setLoading(false);
        });
    }
  }, [vendorId]);

  useEffect(() => {
    if (selectedFabric) {
      setColors([]);
      const vendorColors = vendorMaterials
        .filter((v) => v.fabricId === selectedFabric)
        .map((m) => ({
          code: m.colorId,
          name: m.colorName,
        }));
      vendorColors.unshift({
        code: "All",
        name: "All",
      });
      setColors(vendorColors);
      setSelectedColor(vendorColors[0].code);
    }
  }, [selectedFabric, vendorMaterials]);

  useEffect(() => {
    if (selectedFabric) {
      const materials = vendorMaterials.filter(
        (m) =>
          m.fabricId === selectedFabric &&
          (selectedColor === "All" || m.colorId === selectedColor)
      );
      setFilteredMaterials(materials);
      console.log(materials);
    }
  }, [selectedFabric, selectedColor, vendorMaterials]);
  return (
    <div>
      <Paper sx={{ p: 4 }}>
        {loading && (
          <Stack spacing={1} my={3}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />

            <Skeleton variant="rectangular" width={500} height={60} />
            <Skeleton variant="rounded" width={500} height={60} />
          </Stack>
        )}
        {filteredMaterials && (
          <FabricAvailabilityPieChart materials={filteredMaterials} />
        )}
        {!loading && selectedFabric && (
          <Box>
            <Stack
              direction={"row"}
              gap={2}
              sx={{
                justifyContent: "center",
              }}
            >
              {/* fabric */}
              <TextField
                style={{ width: "250px" }}
                select
                label="Select Fabric"
                value={selectedFabric}
                onChange={(e) => setSelectedFabric(e.target.value)}
                variant="standard"
                className="select"
                // fullWidth
              >
                {fabrics.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              {/* color */}
              <TextField
                style={{ width: "250px" }}
                disabled={colors.length === 0}
                select
                label="Select Color"
                value={selectedColor}
                onChange={(e) => setSelectedColor(e.target.value)}
                variant="standard"
                className="select"
                // fullWidth
              >
                {colors.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </Box>
        )}
        {filteredMaterials.length > 0 && (
          <MaterialAmtTable materials={filteredMaterials} />
        )}
      </Paper>
    </div>
  );
};

export default MaterialVendor;
