import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../actions/authActions";

import { Toolbar, Box, Avatar, Button, Chip } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useNavigate } from "react-router-dom";
const Header = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const handleSubmit = () => {
    dispatch(logOut());
    navigate("/login");
  };

  return (
    <Toolbar
      style={{
        display: "flex",
        justifyContent: "space-between",
        minHeight: "inherit",
      }}
    >
      <Box style={{ width: "160px", height: "40px", paddingTop: "4px" }}>
        <Avatar
          variant="square"
          src="provicali_new_logo_2_1.png"
          sx={{
            width: "133px",
            height: "33px",
            left: "20px",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "40px",
          paddingTop: "4px",
        }}
      >
        <Chip
          sx={{
            color: "black",
            borderColor: "#ecf0f3",
            boxShadow: "inset 3px 3px 3px #CBCED1,inset -3px -3px 3px #FFFFFF",
            alignSelf: "flex-start",
          }}
          avatar={
            <Avatar style={{ backgroundColor: "black", color: "white" }}>
              {auth?.user[0].toUpperCase()}
            </Avatar>
          }
          label={auth?.name}
          variant="outlined"
        />
        <Button
          sx={{ color: "black", paddingBottom: "15px" }}
          onClick={handleSubmit}
        >
          <PowerSettingsNewIcon fontSize="medium" />
        </Button>
      </Box>
    </Toolbar>
  );
};

export default Header;
