import React, { useState, useEffect } from 'react';
import { AgChartsReact } from 'ag-charts-react';

const MultipleBar = ({ data, customerCareName }) => {
    
  let options = {
    autoSize: true,
    data: data,
    
    axes: [
      {
        type: 'category',
        position: 'bottom'
        // title: { text: 'Name' }
      },
      {
        type: 'number',
        position: 'left',
        // title: { text: 'Count' }
      }
    ],
    legend: {
      position: 'bottom',
    },
    tooltip: {
      enabled: true
    }
  };

  const series = customerCareName.map((item)=>{
    return {
        type: 'bar',
        xKey: 'date',
        yKey: `${item}`,
        grouped: true,
        tooltip: {
            renderer: function ({ datum, name, yKey }) {
                return {
                    title: yKey,
                    content: datum[yKey],
                };
            },
        },
    }
    }
);

    options = {...options, series}

  return (
    <div style={{ width: '100%', height: '500px' }}>
      <AgChartsReact options={options} />
    </div>
  );
};

export default MultipleBar;