import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Stack,
  Divider,
  Collapse,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import SortIcon from "@mui/icons-material/Sort";

import { allInventory } from "../../actions/inventoryActions";
import { changeExecutiveId } from "../../actions/ordersActions";

import DispatchedREDetails from "./DispatchedREDetails";
import AssignDropDown from "./AssignDropDown";

const AssignREOrders = (props) => {
  const [orders, setOrders] = useState([]);
  const [userNames, setUserNames] = useState(["All"]);
  const [filteredUsername, setFilteredUsername] = useState("All");
  const [paginatedOrders, setPaginatedOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [type, setType] = useState("All");
  const [searchOrder, setSearchOrder] = useState("");
  const [startAssign, setStartAssign] = useState(false);

  const REtypes = ["All", "Return", "Exchange"];
  const { stock } = useSelector((state) => state.inventoryDetails);
  const dispatch = useDispatch();

  console.log(userNames);
  useEffect(() => {
    if (props.usernames.length > 0) {
      const customercareusers = ["All"];
      customercareusers.push(...props.usernames);
      setUserNames(customercareusers);
    }
  }, [props]);
  useEffect(() => {
    if (stock.length > 0) {
      //   setUserNames(props.usernames);
      const mappedOrders = props.orders.map((o) => {
        const productsWithImages = o.products.map((p) => {
          const found = stock.find((i) => i.SKU === p.sku);
          const url =
            found?.imageUrl ||
            "https://media.istockphoto.com/vectors/error-page-or-file-not-found-icon-vector-id924949200";
          return { ...p, url };
        });

        return {
          ...o,
          products: productsWithImages,
        };
      });
      const filteredMappedOrders =
        type === "All"
          ? mappedOrders.filter((o) =>
              String(o.orderNumber).startsWith(searchOrder)
            )
          : mappedOrders.filter(
              (o) =>
                o.type === type && String(o.orderNumber).startsWith(searchOrder)
            );
      if (filteredUsername !== "All") {
        const userFiltered = filteredMappedOrders.filter(
          (o) => "executive_id" in o && o.executive_id === filteredUsername
        );
        setOrders(userFiltered);
      } else {
        setOrders(filteredMappedOrders);
      }
    } else {
      dispatch(allInventory());
    }
  }, [stock, props.orders, type, filteredUsername, dispatch, searchOrder]);

  useEffect(() => {
    const start = rowsPerPage * page;
    const end = start + rowsPerPage;
    const slicedOrders = orders.slice(start, end);
    setPaginatedOrders(slicedOrders);
  }, [page, rowsPerPage, orders]);

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  return (
    <Box sx={{ margin: "0 20px" }}>
      {selectedOrder && (
        <div className="orderDetailsBox">
          <div className="flexRow">
            <h1>{selectedOrder.orderNumber}</h1>
            <Button variant="contained" onClick={() => setSelectedOrder(null)}>
              Clear
            </Button>
          </div>
          <DispatchedREDetails
            order={selectedOrder}
            clear={() => setSelectedOrder(null)}
          />
        </div>
      )}
      <br />
      <Stack direction={"row"} alignItems={"center"} gap={4}>
        <Box sx={{ display: "flex", alignItems: "flex-end", gap: 1 }}>
          <SearchIcon sx={{ color: searchOrder ? "#1976D2" : "" }} />
          <TextField
            id="input-with-sx"
            label="Search"
            variant="standard"
            onChange={(e) => setSearchOrder(e.target.value)}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end", gap: 1 }}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonSearchIcon
                    sx={{ color: filteredUsername !== "All" ? "#1976D2" : "" }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{ minWidth: "170px" }}
            variant="standard"
            select
            label="UserName"
            value={filteredUsername}
            onChange={(e) => setFilteredUsername(e.target.value)}
          >
            {userNames.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end", gap: 1 }}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SortIcon
                    sx={{ color: filteredUsername !== "All" ? "#1976D2" : "" }}
                  />
                </InputAdornment>
              ),
            }}
            sx={{ minWidth: "170px" }}
            variant="standard"
            select
            label="Type"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            {REtypes.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box sx={{ alignSelf: "end" }}>
          <Button
            sx={{ margin: 0 }}
            onClick={() => setStartAssign((prev) => !prev)}
            variant="outlined"
          >
            Assign
          </Button>
        </Box>
      </Stack>

      <Collapse in={startAssign}>
        <AssignDropDown
          orders={props.orders}
          users={props.usernames}
          RE={props.crm ? true : false}
          ndr={false}
          crm={props.crm}
        />
      </Collapse>

      <br />
      <br />
      <TableContainer>
        <Table
          sx={{
            minWidth: 650,
            backgroundColor: "black",
            color: "white",
            borderRadius: "10px",
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ color: "white" }}>Order No.</TableCell>
              <TableCell align="right" sx={{ color: "white" }}>
                Type
              </TableCell>
              <TableCell align="right" sx={{ color: "white" }}>
                Assigned To
              </TableCell>
              <TableCell align="right" sx={{ color: "white" }}>
                Date
              </TableCell>
              <TableCell align="right" sx={{ color: "white" }}>
                View
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedOrders.map((o) => (
              <TableRow
                key={o.fulfillment_id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:first-child td , &:first-child th": {
                    borderTop: "10px solid #EEEEEE",
                  },
                  backgroundColor: "white",
                  borderBottom: "15px solid #EEEEEE",
                  borderRadius: "40px",
                }}
              >
                <TableCell component="th" scope="row">
                  {o.orderNumber}
                </TableCell>
                <TableCell align="right">{o.type}</TableCell>
                <TableCell align="right">
                  {"executive_id" in o && o.executive_id
                    ? o.executive_id
                    : "None"}
                </TableCell>
                <TableCell align="right">{o.modified_at}</TableCell>
                <TableCell align="right">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setSelectedOrder(o);
                      document.documentElement.scrollTop = 200;
                    }}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      {orders.length > 0 ? (
        <>
          <Box position={"relative"}>
            <Box
              sx={{
                width: "60%",
                borderColor: "black",
                position: "absolute",
                borderTop: "2px solid black",
                right: 0,
                top: 0,
              }}
            />
          </Box>

          <TablePagination
            component="div"
            count={orders.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <Box margin={3}>
          <h4>No orders found</h4>
        </Box>
      )}
    </Box>
  );
};

export default AssignREOrders;
