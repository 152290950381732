import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { allInventory } from "../../actions/inventoryActions";
import { Backdrop, CircularProgress } from "@mui/material";
import EditProductsForm from "./EditProductsForm";

const EditProducts = () => {
  const { stock } = useSelector((state) => state.inventoryDetails);
  const { loading } = useSelector((state) => state.loader);
  const dispatch = useDispatch();

  useEffect(() => {
    if (stock.length === 0) dispatch(allInventory());
  }, []);

  return (
    <>
        <>{(!stock.length > 0 || loading) ? 
            (
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={stock.length === 0 || loading}
                >
                    <CircularProgress size={100} color="inherit" />
                </Backdrop>
            ) : <EditProductsForm />
            }
        </>
    </>
  );
};

export default EditProducts;
