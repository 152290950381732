import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  LinearProgress,
  Paper,
  Typography,
  Stack,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  allCustomers,
  getCustomerCount,
  showFirstOrderOfTheDay,
} from "../../actions/customerActions";
import CustomerTable from "../returningCustomerWidgets/CustomerTable";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CalendarPicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const CustomerReport = () => {
  const [showCalender, setShowCalender] = useState(true);
  const [range, setRange] = useState("Select Date");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data, setData] = useState([]);
  const [orderName, setOrderName] = useState("");
  const { customers, count, customerCount, customerLoading } = useSelector(
    (state) => state.customers
  );
  const { loading } = useSelector((state) => state.loader);

  const dispatch = useDispatch();
  useEffect(() => {
    console.log("order id", orderName);
    if (!orderName || customers.length === 0 || customerLoading) return;

    const filtered = customers.filter(
      (c) => c.last_order_id && parseInt(c.last_order_id) >= parseInt(orderName)
    );
    setData(filtered);
  }, [customers, orderName, customerLoading]);

  const handleFilter = async () => {
    setShowCalender(false);
    const minDate = new Date(startDate);
    console.log("min date", minDate);
    const maxDate = new Date(endDate);
    console.log("max date", maxDate);
    minDate.setUTCHours(24, 0, 0, 0);
    const min = minDate.toISOString();
    // const maxDate = new Date(selected.setDate(selected.getDate() + 1));
    maxDate.setUTCHours(48, 0, 0, 0);
    const max = maxDate.toISOString();
    const orderId = await showFirstOrderOfTheDay(min, max);
    console.log("min", min);
    console.log("max", max);
    console.log("here", orderId);
    setOrderName(orderId.id);
    dispatch(getCustomerCount(min, max));
    dispatch(allCustomers(min, max));
  };

  useEffect(() => {
    if (!startDate && !endDate) return;
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    if (
      new Date(startDate).toLocaleDateString() ===
      new Date(endDate).toLocaleDateString()
    ) {
      setRange(
        `${monthNames[new Date(startDate).getMonth()]} ${new Date(
          startDate
        ).getDate()}`
      );
      return;
    }
    const start = startDate
      ? `${monthNames[new Date(startDate).getMonth()]} ${new Date(
          startDate
        ).getDate()}`
      : "Not Selected";
    const end = endDate
      ? `${monthNames[new Date(endDate).getMonth()]} ${new Date(
          endDate
        ).getDate()}`
      : "Not Selected";
    const dateRange = start + " - " + end;
    setRange(dateRange);
  }, [startDate, endDate]);

  return (
    <Box>
      <Paper sx={{ maxWidth: "100%" }}>
        <Box
          display={"flex"}
          sx={{ alignItems: "center", width: "100%", gap: 3 }}
        >
          <h1 style={{ marginLeft: "10px", minWidth: "200px" }}>{range}</h1>
          {!customerLoading && !loading ? (
            <CalendarMonthIcon
              sx={{
                justifySelf: "flex-end",
                marginRight: "10px",
                marginLeft: "auto",
                fontSize: "2.1rem",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                setShowCalender((prev) => !prev);
              }}
            />
          ) : (
            <CircularProgress
              sx={{
                justifySelf: "flex-end",
                marginRight: "10px",
                marginLeft: "auto",
                fontSize: "2.1rem",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            />
          )}
        </Box>
        {customerLoading ? (
          <Box sx={{ display: "flex", alignItems: "center", margin: "0 20px" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
              <LinearProgress
                variant="determinate"
                value={count === 0 ? 0 : (customerCount / count) * 100}
              />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                count === 0 ? 0 : (customerCount / count) * 100
              )}%`}</Typography>
            </Box>
          </Box>
        ) : (
          ""
        )}
        <br />
        <Collapse in={showCalender}>
          <Stack
            direction={"row"}
            sx={{ alignItems: "center", justifyContent: "space-around" }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack
                direction={"column"}
                sx={{ alignContent: "center", justifyItems: "center" }}
              >
                <h3 style={{ margin: 0, textAlign: "center" }}>Start Date</h3>
                <CalendarPicker
                  date={startDate}
                  maxDate={endDate}
                  onChange={(newDate) => setStartDate(newDate)}
                />
              </Stack>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack direction={"column"} sx={{ alignContent: "flex-start" }}>
                <h3 style={{ margin: 0, textAlign: "center" }}>End Date</h3>
                <CalendarPicker
                  date={endDate}
                  maxDate={dayjs(new Date().toLocaleDateString("en-us"))}
                  minDate={startDate}
                  onChange={(newDate) => setEndDate(newDate)}
                />
              </Stack>
            </LocalizationProvider>
          </Stack>
          <Button
            onClick={handleFilter}
            sx={{ float: "right", margin: "0 20px" }}
            variant="contained"
          >
            Apply Fitler
          </Button>
          <br />
          <br />
        </Collapse>
        <Divider />
      </Paper>
      <CustomerTable customers={data} />
    </Box>
  );
};

export default CustomerReport;
