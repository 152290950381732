import React, { useEffect, useRef, useState } from "react";
import { Container, Box, Avatar, Button } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';

const DragAndDrop = ({sku, setShopifyImages, setDisableSubmit}) => {
  const [drag, setDrag] = useState(false);
  const [preview, setPreview] = useState([])
  const [imageSize, setImageSize] = useState([])
  const [wrongType, setWrongType] = useState(false);
  const [ largeFileError , setLargeFileError] = useState(false)
  // console.log(newUploadFile)
  const onDragEnter = () => setDrag(true);
  const onDragLeave = () => setDrag(false);
  const onDrop = () => setDrag(false);
  
  useEffect(() => {
    if(preview.length>0){
      const temp = preview.map(x => Object.values(x)[0].split("data:image/jpeg;base64,")[1])
      setShopifyImages(temp.map(x => ({attachment : x})))
    }
  },[preview])

  useEffect(() => {
    if(imageSize.length>0){
      const temp = imageSize.map(x => Object.values(x)[0]).reduce((i,sum) => i + sum, 0)/(1024*1024)
      // console.log(temp)
      if(temp > 4.50){
        setLargeFileError(true)
        setDisableSubmit(true)
      }else{
        setLargeFileError(false)
        setDisableSubmit(false)
      }
    }
  },[imageSize])

  const onFileDrop = (e) => {
    if (e.target.files[0].type === "image/jpeg" && e.target.files[0].name.includes(".jpg")) {
      const newFile = e.target.files;
      if (newFile) {
        // setImg(() => [...img, ...newFile]);
        const requiredFile = [...newFile]
        // console.log(requiredFile[0].size)
        const reader = new FileReader()
        reader.readAsDataURL(requiredFile[0])
        reader.onload = () => {
          const result = reader.result.split("data:image/jpeg;base64,")[1]
          setPreview([...preview, { [requiredFile[0].name] : reader.result}])
          setImageSize([...imageSize, { [requiredFile[0].name] : requiredFile[0].size}])
        }
          
      }
    } else {
      // setPreview(null)
      setWrongType(true);
      setTimeout(() => {
        setWrongType(false);
      }, 1000);
    }
  };

  const handleDeleteImage = (image) => {
    const imageName = (Object.keys(image)[0])
    const imageSizeFile = imageSize.filter((item) => Object.keys(item)[0] !== imageName)
    const removePreviewFile = preview.filter((item) => Object.keys(item)[0] !== imageName)
    // console.log(imageFile)
    setImageSize(imageSizeFile)
    setPreview(removePreviewFile)
    }

  return (
    <div>
      <div>
        <Container style={{margin:"0", padding:"0"}}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "250px",
              height: "250px",
              padding: 0,
              margin: 0,
              border: wrongType
                ? "2px dashed rgb(255, 0, 0)"
                : "2px dashed rgb(145, 195, 229)",
              borderRadius: "10px",
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              "&:hover": {
                opacity: 0.6,
              },
              opacity: drag ? 0.6 : 1,
            }}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
          >
            <Avatar
              src="https://e7.pngegg.com/pngimages/637/822/png-clipart-font-awesome-upload-computer-icons-font-computers-blue-text.png"
              sx={{ width: 100, height: 100 }}
            />
            <p style={{ fontWeight: 400, fontSize: 20 }}>Drag And Drop</p>
            <input
              type="file"
              value=""
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                cursor: "pointer",
                opacity: 0,
              }}
              accept=".jpg"
              multiple="multiple"
              onChange={onFileDrop}
            />
          </Box>
        </Container>
        <br/>
        <div style={{display:"flex", flexWrap:"wrap"}}>
          {preview.map((item, i) =>
            <li key={i} style={{display:"flex", flexDirection : "column", listStyle : "none", alignItems:"center", paddingRight: "40px"}}  onClick={() => handleDeleteImage(item)}>
              <Avatar src={Object.values(item)} sx={{ width: 150, height: 150 }} variant="square"/>
              <CancelIcon style={{color:"red"}}/>
            </li>
          )}
        </div>
        {largeFileError ? (<h4 style={{color:"red"}}>Total File Size Of Image Exceeds The Limit. Please Remove Some Images To Proceed</h4>) : ""}
      </div>
    </div>
  );
};

export default DragAndDrop;