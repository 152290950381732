import { Container, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getDesignLikedCustomers } from "../../actions/inventoryActions";
import CustomerTable from "./CustomerTable";
import WinnerTable from "./WinnerTable";

const Customer = ({ designs }) => {
  const COUNT = 10;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    (async () => {
      if (!designs.length > 0) return;
      setLoading(true);
      const customerList = await getDesignLikedCustomers();
      const itemsAddName = designs.map((d) => {
        const items = d.items.map((i) => ({
          ...i,
          name: d.name,
          designId: d.id,
        }));
        return {
          ...d,
          items,
        };
      });
      const products = itemsAddName
        .reduce((arr, row) => {
          const newArr = [...arr, ...row.items];
          return newArr;
        }, [])
        .map((p) => ({
          src: p.src,
          likes: p.likes,
          name: p.name,
          designId: p.designId,
          productId: p.id,
        }))
        .sort((a, b) => b.likes - a.likes);
      const topProducts = products.slice(0, COUNT);

      const topProductIds = topProducts.map((tp) => tp.designId);

      //   filter customer for only top products

      const customerFiltered = customerList
        .map((c) => ({
          customerId: c.fulfillment_id,
          designId: c.company.split("#")[2],
          productId: c.liked_id,
        }))
        .filter((c) => topProductIds.includes(c.designId));

      //   find the number of likes for each customer
      const customerLikes = customerFiltered
        .reduce((acc, row) => {
          const found = acc.find((ac) => ac.id === row.customerId);
          if (found) {
            found.value = found.value + 1;
            found.designIds = [
              ...found.designIds,
              { designId: row.designId, productId: row.productId },
            ];
          } else {
            const newObj = {
              id: row.customerId,
              value: 1,
              designIds: [{ designId: row.designId, productId: row.productId }],
            };
            acc.push(newObj);
          }
          return acc;
        }, [])
        .sort((a, b) => b.value - a.value);

      setData(customerLikes);
      setLoading(false);
    })();
  }, [designs]);
  return (
    <div>
      {/* <Container> */}
      <Stack 
        direction={"row"} 
        gap={10}  
        sx={{
        "@media (max-width: 600px)": {
          flexDirection: "column",
          left:"-5rem",
          gap:"5px",
          width:"320px"
        },
      }}>
        <CustomerTable data={data} />
        <WinnerTable data={data} />
      </Stack>
      {/* </Container> */}
    </div>
  );
};

export default Customer;
