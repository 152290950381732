import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPincodes } from "../../actions/ordersActions";

import { UpdatePincodes } from "../ordersWidgets/UpdatePincodes";
import RedirectLogin from "../RedirectLogin";

const Pincodes = () => {
  const auth = useSelector((state) => state.auth);
  const loading = useSelector((state) => state.loader.loading);
  // const pincodes = useSelector((state) => state.orderDetails.pincodes);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (!auth) return;
  //   dispatch(getPincodes());
  // }, [dispatch, auth]);
  // console.log("pincodes", pincodes);
  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {loading && (
            <div className="load-overlay">
              <h1>Please Wait...</h1>
            </div>
          )}
          <UpdatePincodes />
        </>
      )}
    </>
  );
};

export default Pincodes;
