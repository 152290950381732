import { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import { Paper, Typography, Box } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

const ClothPC = ({ stock }) => {
  const [infoData, setInfoData] = useState([]);
  const pieChart = useRef();

  useEffect(() => {
    console.log("stock", stock);
    const tops = stock
      .filter((x) => x.category === "Tops")
      .reduce((sum, i) => sum + i.count, 0);
    const dresses = stock
      .filter((x) => x.category === "Dress")
      .reduce((sum, i) => sum + i.count, 0);
    const trousers = stock
      .filter((x) => x.category === "Trousers")
      .reduce((sum, i) => sum + i.count, 0);
    const sets = stock
      .filter((x) => x.category === "Sets")
      .reduce((sum, i) => sum + i.count, 0);
    const jeans = stock
      .filter((x) => x.category === "Jeans")
      .reduce((sum, i) => sum + i.count, 0);
    const shirts = stock
      .filter((x) => x.category === "T-Shirts")
      .reduce((sum, i) => sum + i.count, 0);
    const skirts = stock
      .filter((x) => x.category === "Skirts")
      .reduce((sum, i) => sum + i.count, 0);
    const hoodies = stock
      .filter((x) => x.category === "Hoodies")
      .reduce((sum, i) => sum + i.count, 0);
    const womenJacket = stock
      .filter((x) => x.category === "Women's Jacket")
      .reduce((sum, i) => sum + i.count, 0);
    const menJacket = stock
      .filter((x) => x.category === "Men's Jacket")
      .reduce((sum, i) => sum + i.count, 0);
    const menTrousers = stock
      .filter((x) => x.category === "Men Trousers")
      .reduce((sum, i) => sum + i.count, 0);
    const menHoodies = stock
      .filter((x) => x.category === "Men Hoodies")
      .reduce((sum, i) => sum + i.count, 0);
    const lingerie = stock
      .filter((x) => x.category === "Lingerie")
      .reduce((sum, i) => sum + i.count, 0);
    const athleisure = stock
      .filter((x) => x.category === "Athleisure")
      .reduce((sum, i) => sum + i.count, 0);

    const data = [
      { item: "Tops", count: tops, color: "#88F7E2" },
      { item: "T-Shirts", count: shirts, color: "#44D492" },
      { item: "Dresses", count: dresses, color: "#F5EB67" },
      { item: "Trousers", count: trousers, color: "#FFA15C" },
      { item: "Sets", count: sets, color: "#16375B" },
      { item: "Jeans", count: jeans, color: "#FA233E" },
      { item: "Skirts", count: skirts, color: "#F44336" },
      { item: "Hoodies", count: hoodies, color: "#E91E63" },
      { item: "Women's Jacket", count: womenJacket, color: "#9C27B0" },
      { item: "Men's Jacket", count: menJacket, color: "#2b9300" },
      { item: "Men Trousers", count: menTrousers, color: "#3F51B5" },
      { item: "Men Hoodies", count: menHoodies, color: "#2196F3" },
      { item: "Lingerie", count: lingerie, color: "#da1884" },
      { item: "Athleisure", count: athleisure, color: "#cbbeb5" },
    ];

    setInfoData(data);

    const pieData = d3.layout.pie().value((d) => d.count)(data);
    const arc = d3.svg.arc().innerRadius(35).outerRadius(70);
    const colors = d3.scale
      .ordinal()
      .range([
        "#88F7E2",
        "#44D492",
        "#F5EB67",
        "#16375B",
        "#FFA15C",
        "#FA233E",
        "#F44336",
        "#E91E63",
        "#9C27B0",
        "#2b9300",
        "#3F51B5",
        "#2196F3",
        "#da1884",
        "#cbbeb5",
      ]);
    const svg = d3
      .select(pieChart.current)
      .attr("width", 400)
      .attr("height", 150)
      // .style('background-color','white')
      .append("g")
      .attr("transform", "translate(250,70)");
    const tooldiv = d3
      .select("#chartArea")
      .append("div")
      .style("visibility", "hidden")
      .style("position", "absolute")
      .style("background", "rgba(0, 0, 0, 0.7)")
      .style("color", "white")
      .style("padding", "13px")
      .style("border-radius", "10px");

    svg
      .append("g")
      .selectAll("path")
      .data(pieData)
      // .join('path')
      .enter()
      .append("path")
      // .attr('d', arc)
      .attr("fill", (d, i) => colors(i))
      // .attr('stroke','white')
      .on("mouseover", (e, d) => {
        // console.log(e)
        // console.log(d)
        tooldiv
          .style("visibility", "visible")
          .text(`${e.data.item} : ` + `${e.data.count}`);
      })
      .on("mousemove", (e, d) => {
        tooldiv
          .style("top", d3.event.pageY - 50 + "px")
          .style("left", d3.event.pageX - 50 + "px");
      })
      .on("mouseout", () => {
        tooldiv.style("visibility", "hidden");
      })
      .transition()
      // .delay(function(d, i) {
      .duration(function (d, i) {
        return i * 800;
      })
      .attrTween("d", function (d) {
        var i = d3.interpolate(d.startAngle + 0.1, d.endAngle);
        return function (t) {
          d.endAngle = i(t);
          return arc(d);
        };
      });
  }, [stock]);

  return (
    <Paper
      id="chartArea"
      elevation={12}
      sx={{ borderRadius: "30px", maxWidth: "500px" }}
    >
      <h4
        style={{
          margin: "0",
          paddingTop: "30px",
          display: "flex",
          justifyContent: "center",
          color: "grey",
        }}
      >
        Clothing Inventory
      </h4>
      <svg ref={pieChart} style={{ margin: "15px 0" }}></svg>
      <div
        style={{
          columns: "3",
          transform: "translate(5px)",
          padding: "0 5px 0 5px",
        }}
      >
        {infoData.length > 0 ? (
          <>
            {infoData.map((x, index) => (
              <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
                <CircleIcon sx={{ color: x.color, fontSize: "small" }} />
                <Typography sx={{ fontSize: "medium", marginLeft: "10px" }}>
                  {x.item} ({x.count})
                </Typography>
              </Box>
            ))}
          </>
        ) : (
          ""
        )}
      </div>
      <br />
    </Paper>
  );
};

export default ClothPC;
