import * as React from 'react';
import { useState, useEffect } from 'react';
import {Typography, Modal, Box, Avatar, Chip} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height:420,
  width: 650,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const BasicModal = ({data, handleModalClose}) => {
  const [open, setOpen] = useState(false);
  const [modalBody, setModalBody] = useState([])

  
  useEffect(() => {
    setOpen(true)
    const products = data.map((item) => item)
    setModalBody(products)  
  }, [data])

  const handleClose = () => {
    handleModalClose()
    setOpen(false)
  }

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{display:"flex"}}>
            <div style={{color:"limegreen"}}>Product Details</div>
            <CancelIcon onClick={handleClose} fontSize="large" style={{marginLeft:"auto", color:"greenyellow"}}/>
          </Typography>
          <>
          {modalBody.length > 0 
            ? (<>
            <div style={{display:"flex", lineHeight:"0.8", alignItems:"center"}}>
              <Avatar src={modalBody[0].imageUrl} sx={{width: 200 , height: 200}} variant="square"/>
              <div style={{paddingLeft:"40px"}}>
                <h4><b style={{color:"limegreen"}}>Company :</b> "{modalBody[0].company}"</h4>
                <h4><b style={{color:"limegreen"}}>SKU :</b> "{modalBody[0].SKU}"</h4>
                <h4><b style={{color:"limegreen"}}>Type :</b> "{modalBody[0].type}"</h4>
                <h4><b style={{color:"limegreen"}}>Product Category :</b> "{modalBody[0].category}"</h4>
                <h4><b style={{color:"limegreen"}}>Cost Price :</b> "{modalBody[0].costPrice}"</h4>
                <h4><b style={{color:"limegreen"}}>Vendor :</b> "{modalBody[0].vendorType}"</h4>
              </div>
            </div>
                {modalBody.map((i) => (
                  <Chip key={i.barcode} variant="filled" label={i.barcode} style={{margin:"10px", marginLeft:"0", color:"limegreen"}}/>
                ))}
            </>) 
            : ""
          }
          </>
        </Box>
      </Modal>
    </div>
  );
}

export default BasicModal