import React from 'react'
import { useState } from 'react';

import { Typography,Modal,Box,TextField,Button } from '@mui/material';
import { useDispatch } from 'react-redux';

import { updateNdrOrder, sendDraftOrderInvoice, convertDraftToActiveOrderShopify, updateNewReverseOrder } from '../../../actions/ordersActions';
import { showLoading } from '../../../actions/loaderActions';

const CreateGiftCardModal = ({open, setVoucherModal, rowData}) => {
  const [openUp, setOpenUp] = React.useState(open);
  const [ voucherCode, setVoucherCode] = useState("")
//   console.log(rowData)

  const dispatch = useDispatch()

  const handleClose = () => {
    setOpenUp(false)
    setVoucherModal(false)
    setVoucherCode("")
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    textAlign : "center"
  };

  const handleVoucherSubmit = () => {
    dispatch(showLoading())
    const objBody = {
        voucher : true,
        id : rowData.fulfillment_id,
        voucher_code : voucherCode
    }
    // console.log(objBody, rowData.package_status)
    dispatch(updateNewReverseOrder(objBody, rowData.package_status))
    setTimeout(() => {
        setVoucherModal(false)
        setVoucherCode("")
    }, 2000)
  }

  return (
    <div>
        <Modal
            open={openUp}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight="Bold">
                Enter Voucher Details: 
            </Typography>
            <h4>Shopify Customer Id : {rowData.customerId}</h4>
            <h4>Email Id : {rowData.email}</h4>
            <TextField
                    label="Enter Voucher Code"
                    variant="outlined"
                    className="select"
                    value = {voucherCode}                     
                    onChange = {e => setVoucherCode(e.target.value)}
                    style={{width:"400px"}}>
            </TextField>
            <br/>
            <br/>
            <Box sx={{display:"flex", justifyContent:"space-around"}}>
                <Button disabled={voucherCode === ""} variant='contained' color='primary' onClick={handleVoucherSubmit}>Save Voucher</Button>
            </Box>
        </Box>
        </Modal>
    </div>
  )
}

export default CreateGiftCardModal