import {
  Box,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getAutomationStatus,
  getDailyOrderAnalytics,
} from "../../actions/analyticsActions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import SyncIcon from "@mui/icons-material/Sync";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
/*
4weeksSale: 28,
3monthsSale: 90,
3monthsUnfulfilled: 9090
3monthsAll: 9091
3monthsNone: 9092
3monthsZaamo: 9093
3monthsWishLink: 9094
3monthsGoaffpro: 9095
3monthsShopify: 9096
returnedProducts: 100
pendingPO: 101
*/
const orderAgeWithIds = [
  {
    name: "Less than or equal to 3",
    id: "ordersAgeLessThanOrEqual3",
  },
  {
    name: "between 4 and 7",
    id: "ordersAgeBetween4And7",
  },
  {
    name: "between 8 and 15",
    id: "ordersAgeBetween8And15",
  },
  {
    name: "greater than 15",
    id: "ordersAgeGreaterThan15",
  },
];
export const SkeletonComponent = () => {
  return (
    <Stack spacing={1}>
      {/* <Skeleton variant="text" sx={{ fontSize: '1rem', width:"300px" }} /> */}
      <Skeleton
        animation="wave"
        variant="rounded"
        width={"100%"}
        height={100}
      />
      {/* {[1,2,3].map(x => (
                <Skeleton key={x} variant="text" sx={{ fontSize: '0.75rem', width:"200px",paddingLeft:"10px" }} />
            ))} */}
    </Stack>
  );
};

const Row = ({ orderAgeWithId, data }) => {
  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell component="th" scope="row">
        <h3 style={{ fontWeight: 500 }}>{orderAgeWithId.name}</h3>
      </TableCell>
      <TableCell>{data.total}</TableCell>
      <TableCell>{data.COD}</TableCell>
      <TableCell>{data.Prepaid}</TableCell>
      <TableCell>{data.replacement}</TableCell>
      <TableCell>{data.products}</TableCell>
    </TableRow>
  );
};
const TodayOrderAnalytics = () => {
  const [data, setData] = useState({});
  const [isNotUpdated, setIsNotUpdated] = useState(false);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    getDailyOrderAnalytics()
      .then((data) => {
        if (data.statusCode === 200) {
          console.log(data.body);
          setData(data.body);
        } else if (data.statusCode === 404) {
          setIsNotUpdated(true);
        } else {
          setIsError(true);
        }
      })
      .catch(() => {
        setIsError(true);
      });
  }, []);

  return (
    <Box my={2}>
      <Paper
        sx={{
          px: 4,
          py: 2,
        }}
      >
        {!Object.keys(data).length > 0 && !isError && !isNotUpdated && (
          <SkeletonComponent />
        )}
        {Object.keys(data).length > 0 && (
          <div>
            <div
              style={{
                fontSize: 20,
                color: "grey",
                fontWeight: 500,
                marginBottom: 10,
                display: "flex",
                alignItems: "top",
              }}
            >
              <span>
                <AnalyticsIcon sx={{ color: "green" }} />
              </span>
              <span
                style={{
                  paddingRight: 10,
                  borderBottom: "2px solid grey",
                }}
              >
                Daily Order Analytics (In Testing Phase)
              </span>
            </div>
            <div>
              <TableContainer>
                <Table
                  sx={{
                    maxWidth: 800,
                    // [`& .${tableCellClasses.root}`]: {
                    //   borderBottom: "none",
                    // },
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Order Age</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell>COD</TableCell>
                      <TableCell>Prepaid</TableCell>
                      <TableCell>Replacement</TableCell>
                      <TableCell>Products</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderAgeWithIds.map((d, i) => (
                      <Row key={i} orderAgeWithId={d} data={data[d.id]} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        )}
        {isNotUpdated && <h2>No orders processed yet</h2>}
      </Paper>
    </Box>
  );
};

export default TodayOrderAnalytics;
