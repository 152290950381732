import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getNprReturnExchangeOrders } from "../../actions/ordersActions";
import { showLoading, hideLoading } from "../../actions/loaderActions";
import RedirectLogin from "../RedirectLogin";
import NprDataFilter from "../ordersWidgets/returns/NprDataFilter";

const NonPickupReport = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { nprREOrders } = useSelector((state) => state.orderDetails);
  //   const { customerCareUsers } = useSelector((state) => state.inventoryDetails);
  const { loading } = useSelector((state) => state.loader);

  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (!auth) return;
    dispatch(showLoading());
    dispatch(getNprReturnExchangeOrders());
    // dispatch(getcustomercareusernames())
  }, [auth, dispatch]);

  useEffect(() => {
    if (nprREOrders.length > 0) dispatch(hideLoading());
  }, [nprREOrders]);

  useEffect(() => {
    setOrders([
      ...nprREOrders
        .map((o) => {
          const differenceTime =
            new Date().getTime() - new Date(o.sync_date).getTime();
          const numberOfDays = Math.round(differenceTime / (1000 * 3600 * 24));
          return {
            ...o,
            numberOfDays,
          };
        })
        .filter((x) => x.numberOfDays >= 3),
    ]);
  }, [nprREOrders]);

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {loading && (
            <div className="load-overlay">
              <h1>Please Wait...</h1>
            </div>
          )}
          <>
            {orders.length > 0 ? (
              <NprDataFilter tableData={orders} />
            ) : (
              "No Orders Found"
            )}
          </>
        </>
      )}
    </>
  );
};

export default NonPickupReport;
