import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Collapse,
  Divider,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  changeExecutiveId,
  changeExecutiveIdNdr,
} from "../../actions/ordersActions";
import { showFullScreenLoader, showLoading } from "../../actions/loaderActions";
import { useDispatch } from "react-redux";
import { Stack } from "@mui/system";
const AssignDropDown = ({ users, orders, ndr, RE, crm }) => {
  // console.log(orders)

  let assignTypeArray = [];

  ndr
    ? (assignTypeArray = ["Assign"])
    : (assignTypeArray = ["Assign", "ReAssign"]);

  const [assignType, setAssignType] = useState("Assign");
  const [userNames, setUserNames] = useState([]);
  const [NumberOfUnassignOrders, setNumberOfUnassignOrders] = useState(0);
  const [usersToAssign, setUsersToAssign] = useState([]);
  const [openReassignBoxFor, setOpenReassignBoxFor] = useState("");
  const [userToReassign, setUserToReassign] = useState("");
  const [assignOrderUser, setAssignOrderUser] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const assignedOrders = orders.filter((o) => !!o.executive_id);
    const unassignedOrders = orders.filter((o) => !o.executive_id);
    setNumberOfUnassignOrders(unassignedOrders.length);
    if (users.length > 0) {
      setUserNames(users);
      if (assignType === "Assign") {
        const assignUser = users
          .filter((u) => usersToAssign.includes(u))
          .map((u) => {
            const assigned = assignedOrders.filter(
              (c) => c.executive_id === u
            ).length;
            return {
              name: u,
              value: 0,
              assigned,
            };
          });
        setAssignOrderUser(assignUser);
      } else {
        const assignUser = users.map((u) => {
          const assigned = assignedOrders.filter(
            (c) => c.executive_id === u
          ).length;
          return {
            name: u,
            value: 0,
            assigned,
          };
        });
        setAssignOrderUser(assignUser);
      }
    }
  }, [users, usersToAssign, assignType, orders]);

  useEffect(() => {
    if (crm) {
      setUsersToAssign(userNames);
    }
  }, [crm, userNames]);

  const handleAssignChange = (e, name, limit) => {
    const value = parseInt(e.target.value, 10);
    if (value > limit) {
      console.log("Exceeded");
      return;
    }
    if (isNaN(value)) {
      setAssignOrderUser((prev) =>
        prev.map((p) => {
          if (p.name === name) {
            p.value = 0;
          }
          return p;
        })
      );
    } else {
      setAssignOrderUser((prev) =>
        prev.map((p) => {
          if (p.name === name) {
            p.value = value;
          }
          return p;
        })
      );
    }
  };

  const handleSplit = () => {
    let unassignedOrders = [];
    if (ndr) {
      console.log("ndr state", ndr);
      unassignedOrders = orders;
    } else {
      unassignedOrders = orders.filter((o) => !o.executive_id);
    }
    console.log("order", orders);
    console.log("unassign", unassignedOrders);
    let totalNoToAssign = assignOrderUser.length;
    const newAssign = assignOrderUser.map((u) => {
      const Rorders = unassignedOrders.splice(
        0,
        Math.ceil(unassignedOrders.length / totalNoToAssign)
      );
      totalNoToAssign = totalNoToAssign - 1;
      u.value = Rorders.length;
      u.orders = Rorders;
      return u;
    });
    setAssignOrderUser(newAssign);
  };

  const handleAssign = () => {
    const payloadOrders = [];
    assignOrderUser.forEach((user) => {
      const ordersForPayload = user.orders.map((o) => ({
        fulfillment_id: ndr ? o.POID : o.fulfillment_id,
        executive_id: user.name,
      }));
      payloadOrders.push(...ordersForPayload);
    });

    const payload = {
      company: ndr ? "LBI" : "R_LBI",
      orders: payloadOrders,
    };
    // console.log("payload",payload)
    if (ndr) {
      dispatch(showFullScreenLoader())
      dispatch(changeExecutiveIdNdr(payload));
    } else {
      dispatch(changeExecutiveId(payload));
    }
  };

  const handleReassign = (name) => {
    const user = assignOrderUser.filter((u) => u.name === name);
    const value = user[0].value;
    const assignedTo = userToReassign;
    const Rorders = orders
      .filter((o) => !!o.executive_id && o.executive_id === user[0].name)
      .slice(0, value)
      .map((item) => ({
        fulfillment_id: item.fulfillment_id,
        executive_id: assignedTo,
      }));
    const payload = {
      company: "R_LBI",
      orders: Rorders,
    };
    dispatch(changeExecutiveId(payload));
    console.log(payload);
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: ndr ? "center" : "",
      }}
    >
      <h3>Assign Customer Care :</h3>
      <Stack
        style={{ alignItems: ndr ? "center" : "start" }}
        direction={"column"}
        gap={1}
      >
        <Stack direction={"row"} gap={2} sx={{ alignItems: "center" }}>
          <TextField
            sx={{ width: "200px" }}
            variant="standard"
            select
            label="Type"
            value={assignType}
            onChange={(e) => setAssignType(e.target.value)}
          >
            {assignTypeArray.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          {assignType === "Assign" ? (
            <>
              <TextField
                SelectProps={{
                  multiple: true,
                  value: usersToAssign,
                  onChange: (e) => {
                    if (crm) return;
                    setUsersToAssign(e.target.value);
                  },
                }}
                sx={{ width: "170px" }}
                variant="standard"
                select
                label="Select Users"
              >
                {userNames.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <Button
                variant="outlined"
                sx={{ alignSelf: "end" }}
                onClick={handleSplit}
              >
                Split Equally
              </Button>
              <Button
                variant="outlined"
                sx={{ alignSelf: "end" }}
                onClick={handleAssign}
              >
                Assign
              </Button>
              {!ndr ? (
                <h3 style={{ margin: 0, padding: 0 }}>
                  Left To Assign :
                  {NumberOfUnassignOrders -
                    assignOrderUser.reduce((acc, u) => acc + u.value, 0)}
                </h3>
              ) : (
                ""
              )}
            </>
          ) : (
            <></>
          )}
        </Stack>
        {assignType === "Assign" ? (
          <>
            {assignOrderUser.map((u) => (
              <>
                <TextField
                  label={u.name}
                  sx={{ width: "170px" }}
                  value={u.value}
                  variant="filled"
                />
              </>
            ))}
          </>
        ) : (
          <>
            {assignOrderUser.map((u) => (
              <>
                <Stack direction={"row"} gap={2} sx={{ alignItems: "center" }}>
                  <h3 style={{ width: "150px" }}>{u.name}</h3>
                  <h3 style={{}}>Number of orders Assigned : {u.assigned}</h3>
                  <Button
                    variant="outlined"
                    sx={{
                      alignSelf: "center",
                      padding: "1px 5px",
                      marginTop: "4px",
                      display:
                        openReassignBoxFor && openReassignBoxFor === u.name
                          ? "none"
                          : "block",
                    }}
                    onClick={() => {
                      setOpenReassignBoxFor(u.name);
                      setUserToReassign("");
                    }}
                  >
                    ReAssign
                  </Button>
                  {/* {openReassignBoxFor && openReassignBoxFor === u.name ? ( */}
                  <Collapse
                    in={openReassignBoxFor && openReassignBoxFor === u.name}
                    orientation="horizontal"
                  >
                    <Stack
                      direction={"row"}
                      gap={2}
                      sx={{ alignItems: "center", alignSelf: "start" }}
                    >
                      <TextField
                        sx={{ minWidth: "170px" }}
                        variant="standard"
                        select
                        label="Reassign To"
                        value={userToReassign}
                        onChange={(e) => setUserToReassign(e.target.value)}
                      >
                        {userNames
                          .filter((user) => user !== u.name)
                          .map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                      </TextField>
                      <TextField
                        sx={{ width: "100px" }}
                        variant="standard"
                        label="Amount"
                        value={u.value}
                        onChange={(e) =>
                          handleAssignChange(e, u.name, u.assigned)
                        }
                      />

                      <Button
                        color="secondary"
                        variant="outlined"
                        sx={{ padding: "0 5px", alignSelf: "end" }}
                        onClick={() => handleReassign(u.name)}
                      >
                        Assign
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{ padding: "0 5px", alignSelf: "end" }}
                        onClick={() => {
                          setOpenReassignBoxFor("");
                          setUserToReassign("");
                        }}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </Collapse>
                  {/* ) : (
                        ""
                      )} */}
                </Stack>
                <Divider />
              </>
            ))}
          </>
        )}
      </Stack>
    </Box>
  );
};

export default AssignDropDown;
