import { Box, Button, Divider, Stack, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { searchTicket } from "../../actions/ticketActions";
import ReadTicketBox from "../ticketWidgets/ReadTicketBox";

const ReadTicket = () => {
  const [ticketId, setTicketId] = useState("");
  const [loading, setLoading] = useState(false);
  const [ticket, setTicket] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const handleSearch = async () => {
    setErrorMessage("");
    setLoading(false);
    const data = await searchTicket(ticketId);
    console.log("data", data);
    if (data.status === 200) {
      const ticketFound = data.data;
      if (Object.keys(ticketFound).length === 0) {
        setErrorMessage("ticket not found");
      }
      setTicket(ticketFound);
    } else {
      setErrorMessage("error in services");
    }
    setLoading(false);
  };
  console.log("ticket", ticket);
  return (
    <Box>
      <Stack direction={"row"} alignContent="center" gap={2}>
        <TextField
          id="filled-basic"
          label="Ticket Id"
          variant="filled"
          value={ticketId}
          onChange={(e) => setTicketId(e.target.value)}
        />
        <Button variant="outlined" onClick={handleSearch}>
          Search
        </Button>
      </Stack>
      <br />
      <Divider />
      <br />
      <br />
      {errorMessage ? <h3>{errorMessage}</h3> : ""}
      {loading ? (
        <img
          src="https://www.ocpicnicsandmore.com/images/ajax-loader.gif"
          alt=""
          style={{
            objectFit: "contain",
          }}
        />
      ) : (
        ""
      )}
      {Object.keys(ticket).length > 0 ? <ReadTicketBox ticket={ticket} /> : ""}
    </Box>
  );
};

export default ReadTicket;
