// import { getSizesByType } from "./helperArrays";
// import { footwearInitials } from "./weightCalculator";
// import { CSVLink, CSVDownload } from "react-csv";
import ExcelJS from "exceljs";
import { getSizesByType } from "./helperArrays";
import { toast } from "react-toastify";
const getProductInfo = (order) => {
  let text = "";
  order.order_items.forEach((item) => {
    text +=
      item.sku + " -Size: " + item.variant_title + "(" + item.units + ") ";
  });

  return text;
  // const total = order.order_items.reduce((sum, i) => sum + i.units, 0);
  // const footwearNumber = order.order_items.filter((i) => {
  //     const found = footwearInitials.find((f) => i.sku.startsWith(f));
  //     return !!found;
  // }).length;
  // const clothingNumber = total - footwearNumber;
  // return clothingNumber === 0
  //     ? "Footwear - " + footwearNumber
  //     : footwearNumber === 0
  //     ? "Clothing - " + clothingNumber
  //     : "Footwear - " + footwearNumber + " & Clothing - " + clothingNumber;
};

const getTotalQuantity = (order) =>
  order.order_items.reduce((sum, p) => p.units + sum, 0);

export const createXLforXB = (orders) => {
  const excelData = orders.map((o) => ({
    OrderType: o.payment_method,
    NetPayment: o.payment_method === "Prepaid" ? 0 : o.total,
    POID: o.fulfillment_id,
    ShippingID: o.awb_code,
    ShippingReferenceNo: o.awb_code,
    ShipName:
      (o.billing_customer_name || "") + " " + (o.billing_last_name || ""),
    ShipAddress:
      (o.shipping_address || "") + (o.shipping_address_2 || "") === ""
        ? ((o.billing_address || "") + (o.billing_address_2 || "")).trim()
        : ((o.shipping_address || "") + (o.shipping_address_2 || "")).trim(),
    ShipCity: o.shipping_city,
    ShipMobileNo: !!o.shipping_phone ? o.shipping_phone : o.billing_phone,
    MobileNo2: "",
    ShipPinCode: o.shipping_pincode,
    ShipState: o.shipping_state,
    ServiceType: "forward",
    PhysicalWeight: o.weight || "",
    Instructions: "",
    OctroiMRP: o.order_items.reduce(
      (total, i) => i.selling_price * i.units + total,
      0
    ),
    ProductInfo: getProductInfo(o),
    RTOAddress:
      "MISS CLOG PLOT NUMBER 2 NEAR AGARWAL SWEETS BELOW WELLNESS GYM 100 FEET ROAD GHITORNI India",
    RTOPinCode: "110030",
    RTOName: "LittleBoxIndia",
    RTOCity: "South West Delhi",
    InvoiceNumber: "",
    SellerName: "LittleBoxIndia",
    SellerAddress:
      "MISS CLOG PLOT NUMBER 2 NEAR AGARWAL SWEETS BELOW WELLNESS GYM 100 FEET ROAD GHITORNI India",
    IsSellerRegUnderGST: "1",
    SellerGSTRegNumber: "07JUFPS0628A1ZQ",
    SupplyStatePlace: "Delhi",
    EWayBillSrNumber: "0",
    BuyerGSTRegNumber: "0",
    HSNCode: "0",
    TaxableValue: "0",
    CGSTAmount: "0",
    IGSTAmount: "0",
    SellerPincode: "110030",
    Discount: "0",
    GSTTaxRateCGSTN: "0",
    GSTTaxRateSGSTN: "0",
    GSTTAXRateIGSTN: "0",
  }));

  console.log("XB data", excelData);
  return excelData;
};

export const createXLforDelhivery = (orders) => {
  const excelData = orders.map((o) => ({
    Waybill: o.awb_code,
    "Reference No": o.fulfillment_id,
    "Consignee Name":
      (o.billing_customer_name || "") + " " + (o.billing_last_name || ""),
    City: o.shipping_city,
    State: o.shipping_state,
    Country: "India",
    Address:
      (o.shipping_address || "") + (o.shipping_address_2 || "") === ""
        ? ((o.billing_address || "") + (o.billing_address_2 || "")).trim()
        : ((o.shipping_address || "") + (o.shipping_address_2 || "")).trim(),
    Pincode: o.shipping_pincode,
    Phone: "",
    Mobile: !!o.shipping_phone ? o.shipping_phone : o.billing_phone,
    Weight: o.weight || "",
    "Shipment Length": o.length,
    "Shipment Breadth": o.breadth,
    "Shipment Height": o.height,
    "Packaging Type": "",
    "Payment Mode": o.payment_method,
    "Package Amount": o.order_items.reduce(
      (total, i) => i.selling_price * i.units + total,
      0
    ),
    "Cod Amount": o.payment_method === "Prepaid" ? 0 : o.total,
    "Product to be Shipped": getProductInfo(o),
    "Vendor Pickup Location": "LITTLE BOX SURFACE",
    "Return Address":
      "KH.NO-382, F/F, Front Side 100 Phoota Road Village Ghitorni, New Delhi-110030, India",
    "Return Pin": "110030",
    "Shipping Mode": "Surface",
    fragile_shipment: "",
    alternate_phone: "",
    shipment_type: "",
    master_id: "",
    mps_children: "",
    mps_amount: "",
    "Seller Name": "",
    "Seller Address": "",
    "Seller CST No": "",
    "Seller TIN": "",
    "Invoice No": "",
    "Invoice Date": "",
    Quantity: getTotalQuantity(o),
    "Commodity Value": "",
    "Tax Value": "",
    "Category of Goods": "",
    Seller_GST_TIN: "",
    HSN_Code: "",
    "Return Reason": "",
    EWBN: "",
  }));

  return excelData;
};

const toDataURL = async (url) => {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve({ base64Url: reader.result });
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
};

const getRandomLetter = () => {
  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  const randomIndex = Math.floor(Math.random() * alphabet.length);
  return alphabet[randomIndex];
};

const generateRandomLetters = () => {
  let randomLetters = "";
  for (let i = 0; i < 4; i++) {
    randomLetters += getRandomLetter();
  }
  return randomLetters;
};

export const generateExcelForPO = (data, POID) => {
  console.log("data po", data);
  // split type and vendor wise
  const splittedByTypeAndVendor = data.reduce((acc, row) => {
    const found = acc.find(
      (ac) => ac.vendor === row.vendor && ac.type === row.type
    );
    if (found) {
      found.data = [row, ...found.data];
    } else {
      const newObj = {
        vendor: row.vendor,
        type: row.type,
        data: [row],
      };
      acc.push(newObj);
    }
    return acc;
  }, []);
  console.log("split", splittedByTypeAndVendor);
  //split the data value to category wise
  const categoryWise = splittedByTypeAndVendor.map((vt) => ({
    ...vt,
    data: vt.data.reduce((acc, d) => {
      const found = acc.find((ac) => ac.category === d.category);
      if (found) {
        found.products = [...found.products, d];
      } else {
        const newObj = {
          category: d.category,
          products: [d],
        };
        acc.push(newObj);
      }
      return acc;
    }, []),
  }));
  console.log("category wise", categoryWise);
  /////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  //   start building excel workbook for each vendor and type
  categoryWise.forEach((row) => {
    const workbook = new ExcelJS.Workbook();
    row.data.forEach((data) => {
      //add sheet
      const sheet = workbook.addWorksheet(data.category);
      //default row height
      sheet.properties.defaultRowHeight = 100;
      //add columns
      sheet.columns = [
        {
          header: "Date",
          key: "date",
          width: 10,
        },
        {
          header: "Img",
          key: "src",
          width: 20,
        },
        {
          header: "Material",
          key: "material",
          width: 20,
        },
        { header: "SKU", key: "sku", width: 10 },
        ...getSizesByType(row.type).map((s) => ({
          header: s,
          key: s,
          width: 10,
        })),
        {
          header: "Total PO Quantity",
          key: "totalPOQuantity",
          width: 20,
        },
        {
          header: "Total Material Amt",
          key: "material",
          width: 20,
        },
        {
          header: "No. of rolls reqd.",
          key: "rollsReq",
          width: 20,
        },
      ];
      // map the products to their neccessary coloumns
      const promise = Promise.all(
        data?.products?.map(async (product, index) => {
          const rowNumber = index + 1;
          const row = sheet.addRow({
            date: new Date().toLocaleDateString(),
            sku: product?.sku,
            totalPOQuantity: product?.totalPOQuantity,
            ...product?.poQuantity,
          });
          let cell = row.getCell(3);
          // cell.value = "hello\nthere";
          cell.value = {
            richText: product?.material.map((m) => ({
              text: `${m.type} ${m.color}\n`,
            })),
          };
          cell.alignment = { wrapText: true };
          cell = row.getCell(Object.keys(product?.poQuantity).length + 6);
          cell.value = {
            richText: product?.material.map((m) => ({
              text: `${m.totalMaterialAmt}\n`,
            })),
          };
          cell.alignment = { wrapText: true };
          cell = row.getCell(Object.keys(product?.poQuantity).length + 7);
          cell.value = {
            richText: product?.material.map((m) => ({
              text: `${m.numberOfRollsReq}\n`,
            })),
          };
          cell.alignment = { wrapText: true };
          // cell.value = {
          //   richText: [
          //     { text: 'First line', font: { bold: true } },
          //     { text: '\nSecond line', font: { italic: true } }
          //   ]
          // };
          // get base64 format from blob
          const result = await toDataURL(product?.src);
          // get extension
          //   const splitted = product?.src.split(".");
          //   const extName = splitted[splitted.length - 1];
          // add image to workbook
          const imageId2 = workbook.addImage({
            base64: result.base64Url,
            extension: "jpg",
          });
          //  specify what image and where
          sheet.addImage(imageId2, {
            tl: { col: 1, row: rowNumber },
            ext: { width: 80, height: 80 },
          });
          return Promise.resolve();
        })
      );
      //new form data

      promise.then(() => {
        // generate exxcel file
        workbook.xlsx.writeBuffer().then(function (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          // create form data
          const formData = new FormData();
          const POIDSelected = POID.find((p) => p.vendor === row.vendor);
          const randomLetters = generateRandomLetters();
          formData.append(
            "excelFile",
            blob,
            `PO_${row.vendor}_${row.type}_${
              POIDSelected.POID
            }_${new Date().toISOString()}_${randomLetters}.xlsx`
          );

          const uploadPromise = new Promise((resolve, reject) => {
            fetch(
              "https://zj00t5sdze.execute-api.ap-south-1.amazonaws.com/alpha/po/uploadtos3",
              {
                method: "POST",
                headers: {
                  // Set the Content-Type header for form data
                  // Note: You may need to modify the value based on your server-side requirements
                  // "Content-Type": "multipart/form-data",
                },
                body: formData,
              }
            )
              .then((response) => {
                if (response.ok) {
                  // Check the response type
                  const contentType = response.headers.get("content-type");
                  if (contentType && contentType.includes("application/json")) {
                    // If response type is JSON, parse the JSON data
                    return response.json();
                  } else {
                    // If response type is not JSON, resolve with the response object
                    resolve(response);
                  }
                } else {
                  // Handle non-OK response status
                  toast.error("couldnt upload files");
                  reject(
                    new Error(`Request failed with status ${response.status}`)
                  );
                }
              })
              .then((data) => {
                // Handle the result

                resolve(data);
              })
              .catch((error) => {
                // Handle error

                reject(error);
              });
          });

          // Use the promise
          uploadPromise
            .then((result) => {
              // Handle the result
              toast.success("file uploaded successfully");
              console.log("Result", result);
            })
            .catch((error) => {
              // Handle error
              console.error(error);
              toast.error("couldnt upload files");
            });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = `PO_${row.vendor}_${row.type}`;
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
      });
    });
  });
};

export const generateSalesDataExcel = (data) => {
  const workbook = new ExcelJS.Workbook();
  //add sheet
  const sheet = workbook.addWorksheet("sales");
  //default row height
  sheet.properties.defaultRowHeight = 100;
  //add columns
  sheet.columns = [
    {
      header: "Img",
      key: "src",
      width: 20,
    },
    {
      header: "SKU",
      key: "sku",
      width: 10,
    },
    { header: "Name", key: "name", width: 10 },
    {
      header: "Week 1",
      key: "week1Sale",
      width: 10,
    },
    {
      header: "Week 2",
      key: "week2Sale",
      width: 10,
    },
    {
      header: "Week 3",
      key: "week3Sale",
      width: 10,
    },
    {
      header: "Week 4",
      key: "week4Sale",
      width: 10,
    },
    {
      header: "Total",
      key: "total",
      width: 10,
    },
    {
      header: "Material",
      key: "material",
      width: 20,
    },
    {
      header: "Material Amt",
      key: "materialAmt",
      width: 10,
    },
  ];
  // map the products to their neccessary coloumns
  const promise = Promise.all(
    data?.map(async (product, index) => {
      const rowNumber = index + 1;
      const row = sheet.addRow({
        sku: product?.sku,
        total: product?.total,
        name: product?.name,
        week1Sale: product?.week1Sale,
        week2Sale: product?.week2Sale,
        week3Sale: product?.week3Sale,
        week4Sale: product?.week4Sale,
      });
      let cell = row.getCell(9);
      // cell.value = "hello\nthere";
      cell.value = {
        richText: product?.material.map((m) => ({
          text: `${m.type} ${m.color}\n`,
        })),
      };
      cell.alignment = { wrapText: true };
      cell = row.getCell(10);
      cell.value = {
        richText: product?.material.map((m) => ({
          text: `${m.amount}\n`,
        })),
      };
      cell.alignment = { wrapText: true };
      // cell.value = {
      //   richText: [
      //     { text: 'First line', font: { bold: true } },
      //     { text: '\nSecond line', font: { italic: true } }
      //   ]
      // };
      // get base64 format from blob
      const result = await toDataURL(product?.src);
      // get extension
      //   const splitted = product?.src.split(".");
      //   const extName = splitted[splitted.length - 1];
      // add image to workbook
      const imageId2 = workbook.addImage({
        base64: result.base64Url,
        extension: "jpg",
      });
      //  specify what image and where
      sheet.addImage(imageId2, {
        tl: { col: 0, row: rowNumber },
        ext: { width: 80, height: 80 },
      });
      return Promise.resolve();
    })
  );
  //new form data

  promise.then(() => {
    // generate exxcel file
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      // create form data
      const formData = new FormData();
      formData.append("excelFile", blob, `sales.xlsx`);
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = `sales`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  });
};
const getColumnRange = (start, end, row) => {
  const alphabetArray = Array.from(Array(26), (_, i) =>
    String.fromCharCode(65 + i)
  );
  function getColumnLabel(n) {
    let label = "";
    while (n > 0) {
      n -= 1;
      label = alphabetArray[n % 26] + label;
      n = Math.floor(n / 26);
    }
    return label;
  }

  let startLabel = getColumnLabel(start);
  let endLabel = getColumnLabel(end);
  console.log(`${startLabel}${row}:${endLabel}${row}`);
  return `${startLabel}${row}:${endLabel}${row}`;
};
export const generateUnfulfilledPOExcel = (data, sizes) => {
  const ExcelJS = require("exceljs");

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Data");
  const perColumnLength = sizes.length + 1;

  worksheet.columns = new Array(6 * perColumnLength + 1).fill({ width: 15 });

  const firstRow = new Array(6 * perColumnLength + 1).fill("");
  firstRow[0] = "SKU";
  firstRow[1] = "Unfulfilled";
  firstRow[perColumnLength + 2] = "Stock";
  firstRow[perColumnLength * 2 + 3] = "PO";
  firstRow[perColumnLength * 3 + 4] = "Processing";
  console.log(firstRow);
  worksheet.addRow(firstRow);

  const secondRow = new Array(6 * perColumnLength + 1).fill("");
  secondRow[perColumnLength * 3 + 4] = "Cutting";
  secondRow[perColumnLength * 4 + 5] = "Stitching";
  secondRow[perColumnLength * 5 + 6] = "Finishing";
  worksheet.addRow(secondRow);
  const unfulfilledColumnRange = getColumnRange(2, perColumnLength + 1, 1);
  const StockColumnRange = getColumnRange(
    perColumnLength + 3,
    perColumnLength * 2 + 2,
    1
  );
  const POColumnRange = getColumnRange(
    perColumnLength * 2 + 4,
    perColumnLength * 3 + 3,
    1
  );
  const processingColumnRange = getColumnRange(
    perColumnLength * 3 + 5,
    perColumnLength * 6 + 6,
    1
  );
  const cuttingColumnRange = getColumnRange(
    perColumnLength * 3 + 5,
    perColumnLength * 4 + 4,
    2
  );
  const stitchingColumnRange = getColumnRange(
    perColumnLength * 4 + 6,
    perColumnLength * 5 + 5,
    2
  );
  console.log(cuttingColumnRange, stitchingColumnRange);
  const finishingColumnRange = getColumnRange(
    perColumnLength * 5 + 7,
    perColumnLength * 6 + 6,
    2
  );

  worksheet.mergeCells(unfulfilledColumnRange);
  worksheet.mergeCells(StockColumnRange);
  worksheet.mergeCells(POColumnRange);
  worksheet.mergeCells(processingColumnRange);
  worksheet.mergeCells(cuttingColumnRange);
  worksheet.mergeCells(stitchingColumnRange);
  worksheet.mergeCells(finishingColumnRange);

  const thirdRow = new Array(6).fill().reduce((acc, row) => {
    return [...acc, ...sizes, "total", ""];
  }, []);
  thirdRow.unshift("");
  worksheet.addRow(thirdRow);

  worksheet.getRow(1).eachCell((cell) => {
    if (cell.value) {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF0000" },
      };
      cell.font = {
        color: { argb: "FFFFFFFF" },
        bold: true,
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    }
  });

  worksheet.getRow(2).eachCell((cell) => {
    if (cell.value) {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "808080" },
      };
      cell.font = {
        color: { argb: "FFFFFFFF" },
        bold: true,
      };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    }
  });
  //unfulfilled
  //stock
  //po
  //cutting
  //stitching
  //finishing
  data.forEach((d) => {
    const unfulfilled = sizes.reduce((acc, s) => {
      const amt = d.unfulfilled[s] ? d.unfulfilled[s] : "0";
      return [...acc, amt];
    }, []);
    const totalUnfulfilled = unfulfilled.reduce(
      (acc, d) => acc + parseInt(d),
      0
    );
    const stock = sizes.reduce((acc, s) => {
      const amt = d.stock[s] ? d.stock[s] : 0;
      return [...acc, amt];
    }, []);
    const totalstock = stock.reduce((acc, d) => acc + parseInt(d), 0);
    const po = sizes.reduce((acc, s) => {
      const amt = d.PO[s] ? d.PO[s] : 0;
      return [...acc, amt];
    }, []);
    const totalpo = po.reduce((acc, d) => acc + parseInt(d), 0);
    const cutting = sizes.reduce((acc, s) => {
      const amt = d.cutting[s] ? d.cutting[s] : 0;
      return [...acc, amt];
    }, []);
    const totalcutting = cutting.reduce((acc, d) => acc + parseInt(d), 0);
    const stitching = sizes.reduce((acc, s) => {
      const amt = d.stitching[s] ? d.stitching[s] : 0;
      return [...acc, amt];
    }, []);
    const totalstitching = stitching.reduce((acc, d) => acc + parseInt(d), 0);
    const finishing = sizes.reduce((acc, s) => {
      const amt = d.finishing[s] ? d.finishing[s] : 0;
      return [...acc, amt];
    }, []);
    const totalfinishing = finishing.reduce((acc, d) => acc + parseInt(d), 0);
    worksheet.addRow([
      d.sku,
      ...unfulfilled,
      totalUnfulfilled,
      "",
      ...stock,
      totalstock,
      "",
      ...po,
      totalpo,
      "",
      ...cutting,
      totalcutting,
      "",
      ...stitching,
      totalstitching,
      "",
      ...finishing,
      totalfinishing,
    ]);
  });
  // map the products to their neccessary coloumns

  //align
  const defaultAlignment = { vertical: "middle", horizontal: "center" };

  worksheet.eachRow({ includeEmpty: true }, (row) => {
    row.eachCell({ includeEmpty: true }, (cell) => {
      cell.alignment = defaultAlignment;
    });
  });

  //generate excel file
  workbook.xlsx.writeBuffer().then(function (data) {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    // create form data
    const formData = new FormData();
    formData.append("excelFile", blob, `sales.xlsx`);
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = `sales`;
    anchor.click();
    window.URL.revokeObjectURL(url);
  });
};

// const promise = Promise.all(
//   data?.map(async (product, index) => {
//     const rowNumber = index + 1;
//     const row = sheet.addRow({
//       sku: product?.sku,
//       total: product?.total,
//       name: product?.name,
//       week1Sale: product?.week1Sale,
//       week2Sale: product?.week2Sale,
//       week3Sale: product?.week3Sale,
//       week4Sale: product?.week4Sale,
//     });
//     let cell = row.getCell(9);
//     // cell.value = "hello\nthere";
//     cell.value = {
//       richText: product?.material.map((m) => ({
//         text: `${m.type} ${m.color}\n`,
//       })),
//     };
//     cell.alignment = { wrapText: true };
//     cell = row.getCell(10);
//     cell.value = {
//       richText: product?.material.map((m) => ({
//         text: `${m.amount}\n`,
//       })),
//     };
//     cell.alignment = { wrapText: true };
//     // cell.value = {
//     //   richText: [
//     //     { text: 'First line', font: { bold: true } },
//     //     { text: '\nSecond line', font: { italic: true } }
//     //   ]
//     // };
//     // get base64 format from blob
//     const result = await toDataURL(product?.src);
//     // get extension
//     //   const splitted = product?.src.split(".");
//     //   const extName = splitted[splitted.length - 1];
//     // add image to workbook
//     const imageId2 = workbook.addImage({
//       base64: result.base64Url,
//       extension: "jpg",
//     });

//     return Promise.resolve();
//   })
// );
