import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMaterialFabricList } from "../../../actions/materialActions";
import ListTable from "./ListTable";

const FabricList = () => {
  const { materialFabricList, materialColorList } = useSelector(
    (state) => state.inventoryDetails
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (materialFabricList.length === 0) dispatch(getMaterialFabricList());
  }, []);
  return (
    <div>
      <ListTable data={materialFabricList} />
    </div>
  );
};

export default FabricList;
