import * as React from "react";
import { useState, useEffect } from "react";
import {
  getSizesByType,
  materialColorArray,
  materialFabricArray,
  materialSoleFabricArray,
  materialSolePlaceArray,
} from "../../utils/helperArrays";
import { CSVLink } from "react-csv";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  Paper,
  TextField,
  MenuItem,
  Avatar,
  Chip,
  Menu,
  Divider,
  Button,
  CircularProgress,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Stack } from "@mui/system";
import { createPDFForUnfulfilledOrders } from "../../utils/pdfUtils";
import {
  changeAvailableQuantity,
  changeProductsAfterAvailableQntyChange,
  changeProductsAfterStatusChange,
  changeStatusToActive,
  changeStatusToDraft,
} from "../../actions/productsActions";
import { useDispatch, useSelector } from "react-redux";
import ProcessedQntyModal from "../poWidgets/ProcessedQnty/ProcessedQntyModal";
import { getProcessedQntyForSku } from "../../utils/helpers";

const stockFilterArr = ["none", "greater", "smaller", "between"];

const materialFabricList = [
  { code: "All", name: "All" },
  { code: "NaN", name: "No material" },
  ...materialFabricArray.sort((a, b) => a.name.localeCompare(b.name)),
];
const materialSoleFabricList = [
  { code: "All", name: "All" },
  { code: "NaN", name: "No material" },
  ...materialSoleFabricArray.sort((a, b) => a.name.localeCompare(b.name)),
];
const materialColorList = [
  { code: "All", name: "All" },
  { code: "NaN", name: "No material" },
  ...materialColorArray.sort((a, b) => a.name.localeCompare(b.name)),
];

const materialSolePlaceList = [
  { code: "All", name: "All" },
  ...materialSolePlaceArray.sort((a, b) => a.name.localeCompare(b.name)),
];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function Row({ row, sizes }) {
  const [open, setOpen] = React.useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [replaceLoading, setReplaceLoading] = useState(false);
  const { processedQntyList } = useSelector((state) => state.PO);
  const dispatch = useDispatch();

  //processed qnty related functions
  const processedQnty = getProcessedQntyForSku(
    processedQntyList,
    row.sku,
    sizes
  );

  const totalStock = Object.values(row.inventoryStock).reduce(
    (x, sum) => x + sum,
    0
  );

  let shopifyStock = {};
  if (row.stock[0].length > 0) {
    const mapped = row.stock[0].map((item) => ({
      [item.size]: item.stock,
    }));
    shopifyStock = Object.assign({}, ...mapped);
  }
  const totalShopifyInventory = row.stock[0].reduce(
    (sum, item) => item.stock + sum,
    0
  );
  const consumption =
    parseInt(row.total) -
    (parseInt(totalStock) + parseInt(processedQnty.total));
  const material = row.material.map((m) => {
    if (m.materialFormula && m.materialWeight) {
      const materialWeight = parseFloat(m.materialWeight);
      const value = Math.max(consumption, 0);
      const fabricReq =
        m.materialFormula === "IM"
          ? value * materialWeight
          : (value * materialWeight) / 20;
      return {
        ...m,
        fabricReq,
      };
    } else {
      return {
        ...m,
        fabricReq: 0,
      };
    }
  });

  //
  const handleChangeToFinite = async () => {
    setReplaceLoading(true);
    const available = Object.keys(row.itemIds).reduce((acc, s) => {
      const obj = {
        size: s,
        id: row.itemIds[s],
        available:
          row.inventoryStock[s] - (row.sizes[s] ? row.sizes[s] : 0) > 0
            ? row.inventoryStock[s] - (row.sizes[s] ? row.sizes[s] : 0)
            : 0,
      };
      acc.push(obj);
      return acc;
    }, []);
    const data = await changeAvailableQuantity(available);
    if (data.status === 200) {
      dispatch(changeProductsAfterAvailableQntyChange(available));
    }
    setReplaceLoading(false);
  };

  const handleChangeToInfinite = async () => {
    setReplaceLoading(true);
    delete row.itemIds["Free Size (Fits XS to L)"];
    const available = Object.keys(row.itemIds).reduce((acc, s) => {
      const obj = {
        size: s,
        id: row.itemIds[s],
        available: 10000,
      };
      acc.push(obj);
      return acc;
    }, []);
    const data = await changeAvailableQuantity(available);
    if (data.status === 200) {
      dispatch(changeProductsAfterAvailableQntyChange(available));
    }
    setReplaceLoading(false);
  };

  const handleChangeToDraft = async () => {
    setDraftLoading(true);
    const productId = row.productId;
    const data = await changeStatusToDraft(productId);
    if (data.status === 200) {
      dispatch(changeProductsAfterStatusChange(productId, "draft"));
    }
    setDraftLoading(false);
  };
  // const handleChangeToActive = async () => {
  //   console.log("row", row);
  //   const productId = row.productId;
  //   const data = await changeStatusToActive(productId);
  //   if (data.status === 200) {
  //     dispatch(changeProductsAfterStatusChange(productId, "active"));
  //   }
  // };
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <Avatar
            src={row.src}
            sx={{ width: 100, height: 100 }}
            variant="square"
          />
        </TableCell>
        <TableCell>{row.sku}</TableCell>
        <TableCell
          sx={{
            maxWidth: "300px",
          }}
        >
          {row.name}
        </TableCell>
        <TableCell>
          <div>
            {material.map((m, i) => (
              <p key={i}>{`${m.type}${m.place ? `(${m.place})` : ""} ${
                m.color
              } `}</p>
            ))}
          </div>
        </TableCell>
        <TableCell>
          <div>
            {material.map((m, i) => (
              <p key={i}>
                {String(parseFloat(m.fabricReq).toFixed(2))}{" "}
                {m.materialFormula &&
                  (m.materialFormula === "IM" ? "metre" : "roll")}
              </p>
            ))}
          </div>
        </TableCell>
        <TableCell>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p style={{ background: "indianred", padding: "5px" }}>
              Unfulfilled
            </p>
            <p
              style={{
                background: "yellowgreen",
                padding: "5px",
              }}
            >
              Inventory Stock
            </p>
            <p style={{ background: "beige", padding: "5px" }}>
              Shopify Inventory
            </p>
          </div>
        </TableCell>
        {sizes.map((i, k) => (
          <TableCell key={k}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p
                style={{
                  background: "indianred",
                  padding: "10px 5px",
                }}
              >
                {row.sizes[i] ? row.sizes[i] : 0}
              </p>
              <p
                style={{
                  background: "yellowgreen",
                  padding: "10px 5px",
                }}
              >
                {row.inventoryStock[i] ? row.inventoryStock[i] : 0}
              </p>
              <p
                style={{
                  background: "beige",
                  padding: "10px 5px",
                }}
              >
                {shopifyStock[i] ? shopifyStock[i] : 0}
              </p>
            </div>
          </TableCell>
        ))}
        <TableCell>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p
              style={{
                background: "indianred",
                padding: "10px 5px",
              }}
            >
              {row.total}
            </p>

            <p
              style={{
                background: "yellowgreen",
                padding: "10px 5px",
              }}
            >
              {totalStock}
            </p>

            <p
              style={{
                background: "beige",
                padding: "10px 5px",
              }}
            >
              {totalShopifyInventory}
            </p>
          </div>
        </TableCell>
        <TableCell>
          <p>{row.orderId.length} Order</p>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Stack
            direction={"column"}
            sx={{
              gap: 1,
              alignItems: "center",
            }}
          >
            <div>
              {/* change to finite / change to infinite */}
              {totalShopifyInventory >= 600 ? (
                <Button
                  variant="outlined"
                  onClick={handleChangeToFinite}
                  color="success"
                  size="small"
                >
                  {replaceLoading ? <CircularProgress /> : "To Finite"}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={handleChangeToInfinite}
                  size="small"
                >
                  {replaceLoading ? <CircularProgress /> : "To Infinite"}
                </Button>
              )}
            </div>
            {row.status === "active" ? (
              <div>
                {draftLoading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={handleChangeToDraft}
                  >
                    Draft
                  </Button>
                )}
              </div>
            ) : (
              <h3>Drafted</h3>
            )}
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={17}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <h3>All Orders</h3>
              <Stack direction={"row"} sx={{ justifyContent: "space-around" }}>
                {sizes.map((s, i) => (
                  <React.Fragment key={i}>
                    <Stack
                      direction={"column"}
                      sx={{
                        alignItems: "center",
                        width: "100%",
                        margin: "0 8px",
                      }}
                    >
                      <Box
                        sx={{
                          background: "rgba(153,14,199,0.3)",
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        <h3
                          style={{
                            fontSize: "25px",
                            margin: "2px 0",
                          }}
                        >
                          {s}
                        </h3>
                      </Box>
                      <Divider flexItem />
                      <Stack
                        direction={"row"}
                        sx={{
                          flexWrap: "wrap",
                          justifyContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        {(row.orderWithSizes[s] || []).map((o, i) => (
                          <Chip
                            key={i}
                            variant="filled"
                            label={o}
                            style={{
                              // width: "40%",
                              margin: "5px 1px",
                              marginLeft: "0",
                              fontSize: "0.8rem",
                              color: "limegreen",
                            }}
                          />
                        ))}
                      </Stack>
                    </Stack>
                    <Divider orientation="vertical" flexItem />
                  </React.Fragment>
                ))}
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function UnfulfilledOrdersTable({
  tableData,
  productType,
  categoryTypes,
}) {
  const [data, setData] = useState(
    tableData.sort((a, b) => Number(a.total) - Number(b.total))
  );

  const [desc, setDesc] = useState(true);
  const [sizes, setSizes] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [categoryType, setCategoryType] = useState("");
  const [unfulfilledExcelData, setunfulfilledExcelData] = useState([]);
  const [stockExcelData, setStockExcelData] = useState([]);
  // material states
  const [materialColor, setMaterialColor] = useState("All");
  const [materialFabric, setMaterialFabric] = useState("All");
  const [fabricArr, setFabricARR] = useState([]);
  const [materialPlace, setMaterialPlace] = useState("All");
  //
  const [stockFilterOption, setStockFilterOption] = useState("none");
  const [isStockGreaterThan, setIsStockGreaterThan] = useState(0);
  const [isStockLesserThan, setIsStockLesserThan] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [stockSize, setStockSize] = useState("All");
  const [productAge, setProductAge] = useState("All");
  const [replaceAllProgress, setReplaceAllProgress] = useState("");
  const [replaceAllLoading, setReplaceAllLoading] = useState(false);
  const dispatch = useDispatch();
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleDownload = () => {
    createPDFForUnfulfilledOrders(data);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setSizes(getSizesByType(productType));
    setData(
      [...tableData].sort((a, b) => Number(a.total) - Number(b.total)).reverse()
    );
    setCategoryType("");
    setPage(0);
  }, [productType, tableData]);

  useEffect(() => {
    const newData = [...data].reverse();
    setData(newData);
  }, [desc]);

  useEffect(() => {
    if (categoryType && productType) {
      // (p) => p.sku?.substring(0, 2) === categoryType
      const filterTableData = tableData.filter((p) => {
        if (p.sku?.includes(categoryType)) return p;
      });
      if (filterTableData.length > 0) {
        const newData = [...filterTableData].reverse();
        setData(newData);
      } else {
        setData([]);
      }
    } else {
      setData(
        [...tableData]
          .sort((a, b) => Number(a.total) - Number(b.total))
          .reverse()
      );
    }
    setPage(0);
  }, [categoryType, categoryTypes, productType]);

  // set fabric array
  useEffect(() => {
    if (productType === "Shoes" || productType === "Men Shoes") {
      setFabricARR(materialSoleFabricList);
    } else {
      setFabricARR(materialFabricList);
    }
  }, [productType]);

  // excel data
  useEffect(() => {
    // inventory stock - unfulfilled
    const unfulfilledExcelFormatted = data.map((d) => {
      const calculated = getSizesByType(d.type)
        .map((k) => ({
          size: k,
          value: Math.max(
            parseInt(d.sizes[k] ? d.sizes[k] : 0) -
              parseInt(d.inventoryStock[k] ? d.inventoryStock[k] : 0),
            0
          ),
        }))
        .reduce((acc, row) => {
          acc[row.size] = row.value || 0;
          return acc;
        }, {});
      return {
        sku: d.sku,
        ...calculated,
        total: Object.values(calculated).reduce(
          (acc, c) => parseInt(acc) + c,
          0
        ),
      };
    });
    // inventory stock - unfulfilled
    const stockExcelFormatted = data.map((d) => {
      const calculated = getSizesByType(d.type)
        .map((k) => ({
          size: k,
          value: Math.max(
            parseInt(d.inventoryStock[k] ? d.inventoryStock[k] : 0) -
              parseInt(d.sizes[k] ? d.sizes[k] : 0),
            0
          ),
        }))
        .reduce((acc, row) => {
          acc[row.size] = row.value || 0;
          return acc;
        }, {});
      return {
        sku: d.sku,
        ...calculated,
        total: Object.values(calculated).reduce(
          (acc, c) => parseInt(acc) + c,
          0
        ),
      };
    });
    setunfulfilledExcelData(unfulfilledExcelFormatted);
    setStockExcelData(stockExcelFormatted);
  }, [data]);

  // filter
  useEffect(() => {
    // map total shopify stock
    const ordersWithTotalShopifyStock = data.map((d) => ({
      ...d,
      totalShopifyStock: d.stock[0].reduce((acc, d) => acc + d.stock, 0),
    }));
    // finite infinite filter
    const finiteInfiniteFiltered = ordersWithTotalShopifyStock.filter(
      (d) =>
        stockSize === "All" ||
        (stockSize === "Finite" && d.totalShopifyStock < 600) ||
        (stockSize === "Infinite" && d.totalShopifyStock >= 600)
    );
    // product age
    const productAgeFiltered = finiteInfiniteFiltered.filter(
      (d) =>
        productAge === "All" ||
        (productAge === ">30" && d.productAge > 30) ||
        (productAge === "<30" && d.productAge <= 30)
    );
    // shopify stock
    const shopifyStockFiltered = productAgeFiltered.filter((p) => {
      const totalShopifyStock = p.totalShopifyStock;
      return (
        stockFilterOption === "none" ||
        (stockFilterOption === "greater" &&
          totalShopifyStock >= isStockGreaterThan) ||
        (stockFilterOption === "smaller" &&
          totalShopifyStock <= isStockLesserThan) ||
        (stockFilterOption === "between" &&
          totalShopifyStock >= isStockGreaterThan &&
          totalShopifyStock <= isStockLesserThan)
      );
    });
    if (materialColor === "No material" || materialFabric === "No material") {
      const filtered = shopifyStockFiltered.filter(
        (d) => d.material.length === 0
      );
      setFilteredData(filtered);
    } else {
      const filtered = shopifyStockFiltered.filter(
        (d) =>
          (d.material.some((m) => m.color === materialColor) ||
            materialColor === "All") &&
          (d.material.some((m) => m.type === materialFabric) ||
            materialFabric === "All") &&
          (d.material.some((m) => m.place === materialPlace) ||
            materialPlace === "All")
      );
      setFilteredData(filtered);
    }
    // setFilteredData(filtered);
  }, [
    data,
    materialFabric,
    materialColor,
    materialPlace,
    stockFilterOption,
    isStockGreaterThan,
    isStockLesserThan,
    stockSize,
    productAge,
  ]);
  const showStockNumberFilter = () => {
    if (stockFilterOption === "none") {
      return <></>;
    } else if (stockFilterOption === "greater") {
      return (
        <TextField
          type="number"
          variant="filled"
          size="small"
          value={isStockGreaterThan}
          onChange={(e) => setIsStockGreaterThan(e.target.value)}
        />
      );
    } else if (stockFilterOption === "smaller") {
      return (
        <TextField
          type="number"
          variant="filled"
          size="small"
          value={isStockLesserThan}
          onChange={(e) => setIsStockLesserThan(e.target.value)}
        />
      );
    } else {
      return (
        <React.Fragment>
          <TextField
            type="number"
            variant="filled"
            size="small"
            value={isStockGreaterThan}
            onChange={(e) => setIsStockGreaterThan(e.target.value)}
          />
          <h3>-to-</h3>
          <TextField
            type="number"
            variant="filled"
            size="small"
            value={isStockLesserThan}
            onChange={(e) => setIsStockLesserThan(e.target.value)}
          />
        </React.Fragment>
      );
    }
  };
  const handleReplace = async () => {
    setReplaceAllLoading(true);
    for (let i = 0; i < filteredData.length; i++) {
      const row = filteredData[i];
      const available = Object.keys(row.itemIds).reduce((acc, s) => {
        const obj = {
          size: s,
          id: row.itemIds[s],
          available:
            row.inventoryStock[s] - (row.sizes[s] ? row.sizes[s] : 0) > 0
              ? row.inventoryStock[s] - (row.sizes[s] ? row.sizes[s] : 0)
              : 0,
        };
        acc.push(obj);
        return acc;
      }, []);
      const data = await changeAvailableQuantity(available);
      setReplaceAllProgress(`${i + 1}/${filteredData.length}`);
      if (data.status === 200) {
        dispatch(changeProductsAfterAvailableQntyChange(available));
      }
    }
    setReplaceAllLoading(false);
  };
  return (
    <>
      <Paper
        sx={{
          p: 3,
        }}
      >
        {/* category filter */}
        <TextField
          size="small"
          style={{ width: "500px" }}
          select
          label="Select Product Category"
          value={categoryType}
          onChange={(e) => setCategoryType(e.target.value)}
          variant="outlined"
          className="select"
          fullWidth
        >
          {categoryTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <br />
        <br />
        {/* material filter */}
        <Stack direction={"row"} gap={3}>
          {/* fabric */}
          <TextField
            style={{ width: "500px" }}
            size="small"
            select
            label="Select Material Fabric"
            value={materialFabric}
            onChange={(e) => setMaterialFabric(e.target.value)}
            variant="outlined"
            className="select"
            fullWidth
          >
            {fabricArr.map((option) => (
              <MenuItem key={option.code} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          {/* color */}
          <TextField
            size="small"
            style={{ width: "500px" }}
            select
            label="Select Material Color"
            value={materialColor}
            onChange={(e) => setMaterialColor(e.target.value)}
            variant="outlined"
            className="select"
            fullWidth
          >
            {materialColorList.map((option) => (
              <MenuItem key={option.code} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          {/* material place */}
          <TextField
            size="small"
            style={{ width: "250px" }}
            select
            label="Select Place"
            value={materialPlace}
            onChange={(e) => setMaterialPlace(e.target.value)}
            variant="outlined"
            className="select"
          >
            {materialSolePlaceList.map((option) => (
              <MenuItem key={option.code} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <br />
        {/* stock filter */}
        <Stack direction={"row"} gap={2} sx={{ alignItems: "center" }}>
          <TextField
            style={{ width: "200px" }}
            select
            label="Filter"
            value={stockFilterOption}
            onChange={(e) => setStockFilterOption(e.target.value)}
            variant="filled"
            className="select"
            size="small"
          >
            {stockFilterArr.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          {showStockNumberFilter()}
        </Stack>
        <br />
        {/* Replace shopify stock to inventory stock */}
        {/* filter infinite finite */}
        <Stack direction={"row"} gap={2} sx={{ alignItems: "center" }}>
          {/* finite infinte stock filter */}
          <TextField
            style={{ width: "200px" }}
            select
            label="Stock size"
            value={stockSize}
            onChange={(e) => setStockSize(e.target.value)}
            variant="filled"
            className="select"
            size="small"
          >
            {["All", "Finite", "Infinite"].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          {/* product age <30 ,  >30 */}
          <TextField
            style={{ width: "200px" }}
            select
            label="Product Age"
            value={productAge}
            onChange={(e) => setProductAge(e.target.value)}
            variant="filled"
            className="select"
            size="small"
          >
            {["All", ">30", "<30"].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          {/* button to replace all */}
          {stockSize === "Finite" && productAge === ">30" && (
            <Button
              variant="outlined"
              color="success"
              onClick={handleReplace}
              disabled={replaceAllLoading}
            >
              {replaceAllLoading ? (
                <div>{replaceAllProgress}</div>
              ) : (
                "Replace All"
              )}
            </Button>
          )}
        </Stack>
        <br />
        <Button variant="contained" onClick={handleDownload}>
          Download Pdf
        </Button>
        <CSVLink
          data={unfulfilledExcelData}
          filename={`Unfulfilled(${new Date().toLocaleString()}).csv`}
        >
          <Button sx={{ mx: "10px" }} variant="contained">
            Download unfulfilled
          </Button>
        </CSVLink>
        <CSVLink
          data={stockExcelData}
          filename={`Stock(${new Date().toLocaleString()}).csv`}
        >
          <Button sx={{ mx: "10px" }} variant="contained">
            Download stock
          </Button>
        </CSVLink>
      </Paper>
      <br />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>SKU</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Material</TableCell>
              <TableCell>Fabric Req</TableCell>
              <TableCell></TableCell>
              {sizes.map((i, k) => (
                <TableCell key={k}>Size : {i.toString()}</TableCell>
              ))}
              <TableCell onClick={() => setDesc(!desc)}>
                <div style={{ display: "flex" }}>
                  Total
                  <div>{desc ? <ArrowDownward /> : <ArrowUpward />}</div>
                </div>
              </TableCell>
              {/* <TableCell>Inventory Stock</TableCell> */}
              <TableCell>Order IDs</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredData
            ).map((row, index) => (
              <Row key={index} row={row} sizes={sizes} />
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{ overflow: "inherit" }}
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
