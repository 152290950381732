import React, { useEffect, useState } from 'react'
import { getGstData } from '../../utils/gstHelpers'
import GSTReportTable from './GSTReportTable'

export const CalculateGST = ({tableData}) => {
  const [ gstTableData, setGSTTableData ] = useState([])  

  useEffect(() => {
    if(tableData.length>0){
        const gstCalculatedData = getGstData(tableData)
        console.log("not",gstCalculatedData.filter(x => parseFloat(x.clothing_value) + parseFloat(x.footwear_value) + parseFloat(x.shipping_value) !== parseFloat(x.payment)))
        setGSTTableData(gstCalculatedData)
    }
  },[tableData])
  return (
    <GSTReportTable gstData={gstTableData}/>
  )
}
