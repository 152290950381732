import { toast } from "react-toastify";
import { showModal, showUnauthorizedModal } from "./errorModalActions";
import { lambdaFetch } from "../utils/awsUtils";
import { hideLoader, showLoader } from "./loaderActions";

export const getMaterialFabricList = () => {
  async function getData() {
    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/material/getfabriclist";

    const response = await lambdaFetch(URL, {
      method: "POST",

      body: JSON.stringify({}),
    });
    if (response.status === 403) {
      return 403;
    }
    const data = await response.json();
    return data.body;
  }
  return (dispatch) => {
    dispatch(showLoader());
    getData()
      .then((data) => {
        console.log("data", data);
        if (data === 403) {
          dispatch(showUnauthorizedModal());
          return;
        }
        if (data.length > 0) {
          dispatch({
            type: "SHOW_MATERIAL_FABRIC_LIST",
            data,
          });
          dispatch(hideLoader());
        } else {
          toast.error("Error In Loading Fabric List");
          dispatch(hideLoader());
        }
      })
      .catch((error) => {
        toast.error("Something Went Wrong With Connecting to Server", {
          theme: "colored",
        });
        dispatch(hideLoader());
      });
  };
};

export const getMaterialColorList = () => {
  async function getData() {
    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/material/getcolorlist";

    const response = await lambdaFetch(URL, {
      method: "POST",

      body: JSON.stringify({}),
    });
    if (response.status === 403) {
      return 403;
    }
    const data = await response.json();

    return data.body;
  }
  return (dispatch) => {
    dispatch(showLoader());
    getData()
      .then((data) => {
        console.log("data", data);
        if (data === 403) {
          dispatch(showUnauthorizedModal());
          return;
        }
        if (data.length > 0) {
          dispatch({
            type: "SHOW_MATERIAL_COLOR_LIST",
            data,
          });
          dispatch(hideLoader());
        } else {
          toast.error("Error In Loading Color List");
          dispatch(hideLoader());
        }
      })
      .catch((error) => {
        toast.error("Something Went Wrong With Connecting to Server", {
          theme: "colored",
        });
        dispatch(hideLoader());
        throw error;
      });
  };
};

export const addNewMaterial = async (materialData) => {
  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/material/creatematerial";

  //create for material stock
  const materialStockPayload = {
    company: `MaterialStock#${materialData.fabricId}`,
    barcode: materialData.colorId,
    gs1pk: "MaterialStock",
    gs1sk: "MaterialStock",
    amt: 0,
    ...materialData,
  };
  const vendorWisePayloads = materialData.vendors.map((v) => {
    const newObj = {
      company: `MaterialVendor#${v.code}`,
      barcode: `${materialData.fabricId}-${materialData.colorId}`,
      ...materialData,
      gs1pk: "MaterialVendor",
      gs2sk: "MaterialVendor",
      amt: 0,
      vendor: v.name,
      vendorId: v.code,
    };
    delete newObj.vendors;
    return newObj;
  });

  const payload = [materialStockPayload, ...vendorWisePayloads];
  console.log("payload", payload);
  //create for material vendor
  try {
    for (let i = 0; i < payload.length; i++) {
      console.log("each", payload[i]);
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify({ payload: payload[i] }),
      });
      const data = await response.json();
      console.log("data", data);
      if (data.statusCode !== 200) {
        toast.error("Cannot Add Material");
        return { statusCode: 500 };
      }
    }
    toast.success("Material Added successfully");
    return { statusCode: 200 };
  } catch (e) {
    console.log("e", e);
    toast.error("Something Went Wrong With Connecting To Server");
    return { statusCode: 500 };
  }
};

export const getMaterialList = async (fabricId) => {
  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/material/getmaterialsbyfabric";

  const pk = `MaterialStock#${fabricId}`;
  console.log(pk);
  let fetching = true;
  let Materials = [];
  let LastEvaluatedKey = null;
  while (fetching) {
    try {
      const response = await lambdaFetch(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          LastEvaluatedKey,
          pk,
        }),
      });
      if (response.status === 403) {
        fetching = false;
        return 403;
      }
      const data = await response.json();
      if (data.statusCode === 200) {
        console.log(data);
        const processingData = data.body;
        Materials.push(...processingData);
        LastEvaluatedKey = data.LastEvaluatedKey;
        if (!LastEvaluatedKey) {
          fetching = false;
        }
      } else {
        Materials = [];
        fetching = false;
        console.log(data);
        toast.error("Error fetching Materials");
        return {
          statusCode: 500,
        };
      }
    } catch (error) {
      fetching = false;
      console.log(error);
      toast.error("Error fetching Materials", error);
      return {
        statusCode: 500,
      };
    }
  }
  return {
    statusCode: 200,
    body: Materials,
  };
};

export const getVendorMaterialList = async (vendorId) => {
  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/material/getmaterialsbyfabric";

  const pk = `MaterialVendor#${vendorId}`;
  console.log(pk);
  let fetching = true;
  let Materials = [];
  let LastEvaluatedKey = null;
  while (fetching) {
    try {
      const response = await lambdaFetch(URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          LastEvaluatedKey,
          pk,
        }),
      });
      if (response.status === 403) {
        fetching = false;
        return 403;
      }
      const data = await response.json();
      if (data.statusCode === 200) {
        console.log(data);
        const processingData = data.body;
        Materials.push(...processingData);
        LastEvaluatedKey = data.LastEvaluatedKey;
        if (!LastEvaluatedKey) {
          fetching = false;
        }
      } else {
        Materials = [];
        fetching = false;
        console.log(data);
        toast.error("Error fetching Materials");
        return {
          statusCode: 500,
        };
      }
    } catch (error) {
      fetching = false;
      console.log(error);
      toast.error("Error fetching Materials", error);
      return {
        statusCode: 500,
      };
    }
  }
  return {
    statusCode: 200,
    body: Materials,
  };
};

export const uploadImage = async (payload) => {
  const URL =
    "https://on1z9dj82d.execute-api.ap-south-1.amazonaws.com/beta/SSSuploadproductimagetoS3";

  try {
    const response = await lambdaFetch(URL, {
      method: "POST",
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    if (data.statusCode === 200) {
      toast.success("Image Uploaded successfully");
      return { statusCode: 200, url: data.url };
    } else {
      toast.error("Cannot Upload Image");
      return { statusCode: 500 };
    }
  } catch (e) {
    toast.error("Something Went Wrong With Connecting To Server");
    return { statusCode: 500 };
  }
};

export const createChallanHistory = async (
  challanNumber,
  vendor,
  vendorName,
  materials
) => {
  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/material/createchallan";
  //create for material vendor

  const payload = materials.map((m) => ({
    company: `MaterialChallanSend#${challanNumber}`,
    barcode: `${m.fabricId}-${m.colorId}`,
    vendorName: vendorName,
    vendorId: vendor,
    gs1pk: `MateralChallanSend#${new Date().toISOString().split("T")[0]}`,
    gs1sk: challanNumber,
    ...m,
  }));
  try {
    for (let i = 0; i < payload.length; i++) {
      console.log("each", payload[i]);
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify({ payload: payload[i] }),
      });
      const data = await response.json();
      console.log("data", data);
      if (data.statusCode !== 200) {
        toast.error("Error Creating History");
        return { statusCode: 500 };
      }
    }
    toast.success("Challan History Created");
    return { statusCode: 200 };
  } catch (e) {
    console.log("e", e);
    toast.error("Something Went Wrong With Connecting To Server");
    return { statusCode: 500 };
  }
};

export const sendMaterialToVendor = async (payload) => {
  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/material/sendfabric";
  //create for material vendor
  const toastId = toast.info("Receiving Material :", {
    position: "bottom-right",
  });
  try {
    for (let i = 0; i < payload.length; i++) {
      toast.update(toast, {
        render: `Receiving Material In progress : ${parseInt(
          payload.length / i + 1
        )}%`,
      });
      console.log("each", payload[i]);
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify(payload[i]),
      });
      const data = await response.json();
      console.log("data vendor send", data);
      if (data.statusCode !== 200) {
        toast.dismiss(toastId);
        toast.error("Error Sending Material");
        return { statusCode: 500 };
      }
    }
    toast.dismiss(toastId);
    toast.success("Sended successfully");
    return { statusCode: 200 };
  } catch (e) {
    toast.dismiss(toastId);
    console.log("e", e);
    toast.error("Something Went Wrong With Connecting To Server");
    return { statusCode: 500 };
  }
};

export const receiveMaterialFromVendor = async (payload) => {
  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/material/receivefabric";
  //create for material vendor
  const toastId = toast.info("Receiving Material :", {
    position: "bottom-right",
  });
  try {
    for (let i = 0; i < payload.length; i++) {
      toast.update(toast, {
        render: `Receiving Material In progress : ${parseInt(
          payload.length / i + 1
        )}%`,
      });
      console.log("each", payload[i]);
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify(payload[i]),
      });
      const data = await response.json();
      console.log("data", data);
      if (data.statusCode !== 200) {
        toast.dismiss(toastId);
        toast.error("Error Receiving Material");
        return { statusCode: 500 };
      }
    }
    toast.success("Received successfully");
    return { statusCode: 200 };
  } catch (e) {
    toast.dismiss(toastId);
    console.log("e", e);
    toast.error("Something Went Wrong With Connecting To Server");
    return { statusCode: 500 };
  }
};

export const createReceiveChallanHistory = async (
  challanNumber,
  vendor,
  vendorName,
  materials
) => {
  const URL =
    "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/material/createchallan";
  //create for material vendor

  const payload = materials.map((m) => ({
    company: `MaterialChallanReceive#${challanNumber}`,
    barcode: `${m.fabricId}-${m.colorId}`,
    vendorName: vendorName,
    vendorId: vendor,
    gs1pk: `MateralChallanReceive#${new Date().toISOString().split("T")[0]}`,
    gs1sk: challanNumber,
    ...m,
  }));
  try {
    for (let i = 0; i < payload.length; i++) {
      console.log("each", payload[i]);
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: JSON.stringify({ payload: payload[i] }),
      });
      const data = await response.json();
      console.log("data", data);
      if (data.statusCode !== 200) {
        toast.error("Error Creating History");
        return { statusCode: 500 };
      }
    }
    toast.success("Receive Challan History Created");
    return { statusCode: 200 };
  } catch (e) {
    console.log("e", e);
    toast.error("Something Went Wrong With Connecting To Server");
    return { statusCode: 500 };
  }
};
