import { useSelector } from "react-redux";
import RedirectLogin from "../RedirectLogin";


const Accounts = () => {
  const auth = useSelector((state) => state.auth)
  
  return (
    <>
      {!auth 
        ? (<RedirectLogin/>) 
        : (<div>Accounts</div>)
      }
    </>
  )
};

export default Accounts;
