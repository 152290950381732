import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAssignedNdrOrders,
  getDynamoNdrOrdersData,
} from "../../actions/ordersActions";
import NonDeliverReportFilter from "../ordersWidgets/NonDeliverReportFilter";
import RedirectLogin from "../RedirectLogin";
import "./index.css";
import ProgressBar from "../../ProgressBar";
import { showLoading } from "../../actions/loaderActions";
import { Backdrop, CircularProgress } from "@mui/material";

const NonDeliveryReport = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const xbNdrOrders = useSelector((state) => state.orderDetails.xbNdrOrders);
  const { loading } = useSelector((state) => state.loader);

  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (!auth) return;
    dispatch(showLoading());
    dispatch(getAssignedNdrOrders());
  }, [dispatch, auth]);

  const reqDateFormat = (date) => {
    const tempDate = date.split("-");
    const reqDate = [tempDate[1], tempDate[0], tempDate[2]].join("/");
    return reqDate;
  };

  useEffect(() => {
    if (xbNdrOrders.length > 0) {
      console.log("xb ndr orders", xbNdrOrders);
      const formattedOrders = xbNdrOrders
        .filter((x) => !!x.last_ndr_details)
        .filter((x) => !!x.total_ndr_attempts)
        .map((x) => {
          const newObj = {
            ...x.last_ndr_details,
            POID: String(x.fulfillment_id),
            PendingDays:
              (new Date(
                reqDateFormat(x.last_ndr_details.StatusDate)
              ).getTime() -
                new Date(x.sync_date).getTime()) /
              (1000 * 60 * 60 * 24),
            TotalAttempt: x.total_ndr_attempts,
            customer_name:
              x.shipping_customer_name + " " + x.shipping_last_name,
            customer_email: x.billing_email,
            customer_address: x.shipping_address + " " + x.shipping_address_2,
            customer_city: x.shipping_city,
            customer_state: x.shipping_state,
            customer_pincode: x.shipping_pincode,
            customer_phone: x.shipping_phone,
            order_items: x.order_items,
            order_original_id: x.id,
            executive_id:
              x.executive_id === "1" ? "Not Assigned" : x.executive_id,
            total_order_price: x.total?.toString(),
            ordered_date: new Date(x.order_date).toLocaleDateString("en-in"),
            awb: x.awb_code,
            PaymentMode: x.payment_method,
            ShipmentDate: x.sync_date,
          };
          return newObj;
        });
      setOrders(formattedOrders);
    } else {
      setOrders([]);
    }
  }, [xbNdrOrders]);
  console.log("ndr orders ", xbNdrOrders);
  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {(loading || orders.length === 0) && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading || orders.length === 0}
            >
              <CircularProgress size={100} color="inherit" />
            </Backdrop>
          )}
          {orders.length > 0 && <NonDeliverReportFilter tableData={orders} />}
        </>
      )}
    </>
  );
};

export default NonDeliveryReport;
