import React from "react";
import { useState, useEffect } from "react";
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    TableFooter,
    TablePagination,
    Button,
    Chip,
    Card,
    CardContent,
    TextField,
    MenuItem,
    Avatar,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useDispatch, useSelector } from "react-redux";
import {
    getXBReverseShipmentDetails,
    replenishRTOorder,
    updateReverseDeliveredOrders,
    updateRtoDeliveredOrders,
} from "../../../actions/ordersActions";
import { hideLoader, showLoading } from "../../../actions/loaderActions";
import { OrderHistoryDetailModal } from "../ndr/OrderHistoryDetailModal";
import { getXBShipmentDetails } from "../../../actions/ordersActions";
import { SelectItemModal } from "../rto/SelectItemModal";
import { toast } from "react-toastify";
import { allInventory } from "../../../actions/inventoryActions";
import { SelectDamagedProductModal } from "./SelectDamagedProductModal";
import { XbReverseShipmentHistoryModal } from "./XbReverseShipmentHistoryModal";

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };
    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };
    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };
    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === "rtl" ? (
                    <LastPageIcon />
                ) : (
                    <FirstPageIcon />
                )}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                )}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                )}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === "rtl" ? (
                    <FirstPageIcon />
                ) : (
                    <LastPageIcon />
                )}
            </IconButton>
        </Box>
    );
}

function Row({ row, index, productVariants, setSearchFilter }) {
    const [open, setOpen] = useState(false);
    const [orderHistoryModal, setOrderHistoryModal] = useState(false);
    const [selectItemModal, setSelectItemModal] = useState(false);
    const dispatch = useDispatch();

    const handleSubmit = (orderId) => {
        dispatch(showLoading());
        let temp = [];
        const orderItems = row.products.map((x) => {
            const filteredVariant = productVariants.filter(
                (item) =>
                    item.sku === x.sku &&
                    item.title === x.originalSize
            );
            if (filteredVariant.length > 0)
                temp.push({
                    location_id: 65807843485,
                    available_adjustment: 1,
                    inventory_item_id: filteredVariant[0].inventory_item_id,
                });
            else return;
        });
        if (temp.length > 0) {
            console.log(temp, orderId, row.package_status)
            dispatch(updateReverseDeliveredOrders(temp, orderId.toString(), row.package_status))
            // dispatch(replenishRTOorder(orderId.toString()));
            setSearchFilter("");
        } else {
            dispatch(hideLoader());
            toast.error(
                "One or More Products Of This Order Is Not Available In Shopify"
            );
        }
    };
    return (
        <>
            <TableRow
                sx={{
                    bgcolor: "rgb(245,245,245)",
                    "& > *": { borderBottom: "unset" },
                }}
            >
                <TableCell component="th" scope="row">{index + 1}</TableCell>
                <TableCell component="th" scope="row">{row.orderNumber}</TableCell>
                <TableCell
                    align="left"
                    sx={{ color: "red", cursor: "pointer" }}
                >
                    <div
                        onClick={() => {
                            setOrderHistoryModal(true);
                            dispatch(getXBReverseShipmentDetails({AWB : parseInt(row.reverse_pickup_awb ? row.reverse_pickup_awb : row.awb_code)}))
                        }}
                    >
                        {row.reverse_pickup_awb}
                    </div>
                </TableCell>
                {orderHistoryModal ? (
                    <XbReverseShipmentHistoryModal
                        open={orderHistoryModal}
                        setOrderHistoryModal={setOrderHistoryModal}
                    />
                ) : (
                    ""
                )}
                <TableCell
                    align="right"
                    sx={{
                        color:
                            row.package_status === "deliveredReverse"
                                ? "green"
                                : "red",
                    }}
                >
                    {row.package_status === "deliveredReverse"
                        ? "Delivered To WH"
                        : "Reverse In Transit"}
                </TableCell>
                <TableCell align="right">
                    {row.reverse_pickup_date
                        ? row.reverse_pickup_date
                        : "NA"}
                </TableCell>
                <TableCell align="right">
                    {row.reverse_delivery_date
                        ? row.reverse_delivery_date
                        : "NA"}
                </TableCell>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Button
                        variant="contained"
                        onClick={() => handleSubmit(row.fulfillment_id)}
                    >
                        Acknowledge
                    </Button>
                    <Button
                        style={{ left: "20px" }}
                        variant="contained"
                        color="error"
                        onClick={() => setSelectItemModal(true)}
                    >
                        Mark As Damaged
                    </Button>
                    {selectItemModal ? (
                        <SelectDamagedProductModal
                            orderId={row.fulfillment_id}
                            package_status = {row.package_status}
                            open={selectItemModal}
                            setSelectItemModal={setSelectItemModal}
                            orderProducts={row.products}
                            productVariants={productVariants}
                            setSearchFilter={setSearchFilter}
                        />
                    ) : (
                        ""
                    )}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                component="div"
                            >
                                Product Details
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Image</TableCell>
                                        {/* <TableCell>Name</TableCell> */}
                                        <TableCell>SKU</TableCell>
                                        <TableCell align="right">
                                            Size
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.products.map((productRow) => (
                                        <TableRow key={productRow.sku}>
                                            <TableCell>
                                                <Avatar
                                                    src={productRow.url}
                                                    sx={{
                                                        width: 100,
                                                        height: 100,
                                                        objectFit: "ro",
                                                    }}
                                                    variant="square"
                                                />
                                            </TableCell>
                                            {/* <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {productRow.name}
                                            </TableCell> */}
                                            <TableCell>
                                                {productRow.sku}
                                            </TableCell>
                                            <TableCell align="right">
                                                {productRow.originalSize}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}
const PendingReturnsCloseTable = ({
    viewTableData,
    productVariants,
    setSearchFilter,
}) => {
    const dispatch = useDispatch()
    const [data, setData] = useState(
        viewTableData
            .sort((a, b) => Number(a.orderNumber) - Number(b.orderNumber))
            .reverse()
    );

    const { stock } = useSelector((state) => state.inventoryDetails);
    const {products} = useSelector(state => state.productDetails)

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    useEffect(
        () =>
            setData(
                viewTableData
                    .sort(
                        (a, b) =>
                            Number(a.orderNumber) - Number(b.orderNumber)
                    )
                    .reverse()
            ),
        [viewTableData]
    );

    useEffect(() => {
        if (stock.length > 0) {
          const mappedOrders = viewTableData.map((o) => {
            const productsWithImages = o.products.map((p) => {
              const found = stock.find((i) => i.SKU === p.sku);
              const url =
                found?.imageUrl ||
                "https://media.istockphoto.com/vectors/error-page-or-file-not-found-icon-vector-id924949200";
              return { ...p, url };
            });
    
            return {
              ...o,
              products: productsWithImages,
            };
          });
          setData(mappedOrders);
        } else {
          dispatch(allInventory());
        }
      }, [stock, viewTableData]);
    
    return (
        <>
            <br />
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Index</TableCell>
                            <TableCell>Order Number</TableCell>
                            <TableCell align="left">AWB Number</TableCell>
                            <TableCell align="right">Current Status</TableCell>
                            <TableCell align="right">Reverse PickedUp On</TableCell>
                            <TableCell align="right">Delivered On</TableCell>
                            <TableCell align="left">Product Details</TableCell>
                            <TableCell align="left">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? data.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                              )
                            : data
                        ).map((row, index) => (
                            <Row
                                key={index}
                                row={row}
                                index={index}
                                productVariants={productVariants}
                                setSearchFilter={setSearchFilter}
                            />
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                style={{ overflow: "inherit" }}
                                rowsPerPageOptions={[
                                    5,
                                    10,
                                    25,
                                    100
                                ]}
                                colSpan={3}
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "rows per page",
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
};
export default PendingReturnsCloseTable;
