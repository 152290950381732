import React, { useEffect } from "react";
import orders from "../../utils/tempOrders.json";
import { createInvoicePDF } from "../../utils/pdfUtils";

const CreateInvoice = () => {
    useEffect(() => {
        console.log(orders);

        // createInvoicePDF(orders[0]);
        // orders.forEach((order) => {
        //     createInvoicePDF(order);
        // });
    }, []);

    return <div>CreateInvoice</div>;
};

export default CreateInvoice;
