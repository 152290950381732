import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { allOrders } from "../../actions/ordersActions";
import { allProducts } from "../../actions/productsActions";
import { allInventory } from "../../actions/inventoryActions";
import ProgressBar from "../../ProgressBar";
import ProductStockCount from "../poWidgets/ProductStockCount";
import RedirectLogin from "../RedirectLogin";
import "./index.css";

const PoProductList = () => {
  const auth = useSelector((state) => state.auth);
  const orders = useSelector((state) => state.orderDetails.orders);
  const products = useSelector((state) => state.productDetails.products);
  const inventory = useSelector((state) => state.inventoryDetails.stock);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth) return;
    if (!orders.length > 0) dispatch(allOrders());
    if (!products.length > 0) dispatch(allProducts());
    if (!inventory.length > 0) dispatch(allInventory());
  }, [dispatch, auth]);

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {!orders.length > 0 ||
          !products.length > 0 ||
          !inventory.length > 0 ? (
            <ProgressBar />
          ) : (
            <ProductStockCount />
          )}
        </>
      )}
    </>
  );
};

export default PoProductList;
