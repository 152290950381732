import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { allOrders } from "../../actions/ordersActions";
import ProgressBar from "../../ProgressBar";
import RedirectLogin from "../RedirectLogin";
import ReturnOrders from "../ordersWidgets/ReturnOrders";
import "./index.css";

const ExchangeReturn = () => {
  const auth = useSelector((state) => state.auth);
  const orders = useSelector((state) => state.orderDetails.orders);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!orders.length > 0 && auth) dispatch(allOrders());
  }, [dispatch, auth]);

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {!orders.length > 0 ? (
            <ProgressBar />
          ) : (
            <ReturnOrders orderData={orders} />
          )}
        </>
      )}
    </>
  );
};

export default ExchangeReturn;
