import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TableFooter,
  TablePagination,
  Box,
  TextField,
  Stack,
} from "@mui/material";
import TablePaginationActions from "../../../utils/TablePaginationActions";

const MaterialTable = ({ data }) => {
  const [tableData, setTableData] = useState([...data]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Paper p={2}>
        <TableContainer sx={{ minWidth: 700 }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Unit</TableCell>
                <TableCell align="center">Conversion Formula</TableCell>
                <TableCell align="center">Material Stock</TableCell>
                <TableCell align="center">Vendors</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? tableData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : tableData
              ).map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center" component="th" scope="row">
                    <img
                      height={100}
                      style={{
                        objectFit: "contain",
                      }}
                      src={row.imageUrl}
                      alt=""
                    />
                  </TableCell>
                  <TableCell align="center">
                    {`${row.fabricName} ${row.colorName}`}
                  </TableCell>
                  <TableCell align="center">{row.unit}</TableCell>
                  <TableCell align="center">
                    <Stack direction={"column"} gap={1}>
                      <p>{`1 Roll = ${row.rollToKg} kg`}</p>
                      <p>{`1 Roll = ${row.rollToMetre} m`}</p>
                    </Stack>
                  </TableCell>
                  <TableCell align="center">{String(row.amt)}</TableCell>
                  <TableCell align="center">
                    {row.vendors.map((v) => v.name).join(",")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  style={{ overflow: "inherit" }}
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default MaterialTable;
