import React from 'react'
import { useState, useEffect } from 'react';

import CustomerDetailOrderTable from './CustomerDetailOrderTable';

import { Typography,Modal,Box, Card, CardContent, CircularProgress  } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { removeCustomerDetails } from '../../../actions/ordersActions';

export const CustomerDetailsModal = ({open, setCustomerDetailsModal}) => {
  const dispatch = useDispatch()
  const customerDetails = useSelector(state => state.orderDetails.customerDetail)
  const [ viewTableData, setViewTableData] = useState([])
  const [openUp, setOpenUp] = React.useState(open);
  const handleClose = () => {
    setOpenUp(false)
    setCustomerDetailsModal(false)
    dispatch(removeCustomerDetails())
  }

    useEffect(() => {
        if (customerDetails.length > 0) {
            const newOrderArray = customerDetails
                .map((item) => {
                    const newOrder = {
                        order: item.order_number,
                        date: new Date(item.created_at).toLocaleDateString(),
                        name: item.billing_address?.name,
                        total: item.current_total_price,
                        products: item.line_items,
                        payment: item.financial_status,
                        address: item.shipping_address,
                        status: item.fulfillment_status
                    };
                    return newOrder;
                })
                setViewTableData(newOrderArray);
        }else{
            setViewTableData("")
        }
    }, [customerDetails]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    background : "whitesmoke",
    textAlign : "center",
    maxHeight : "80vh",
    overflowY : "scroll",
    maxWidth : "90%"
  };

  return (
    <div>
        <Modal
            open={openUp}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        {(customerDetails && viewTableData.length>0) 
            ? (
                <Box sx={style}>
                    <h2>Customer Details</h2>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                {viewTableData[0].address?.name}
                            </Typography>
                            <Typography variant="h5" component="div"></Typography>
                            <Typography variant="body2">
                            {viewTableData[0].address.address1}
                            <br />
                            {viewTableData[0].address.address2}
                            <br />
                            {viewTableData[0].address.city}
                            <br/>
                            {viewTableData[0].address.province}
                            <br/>
                            {viewTableData[0].address.zip}
                            </Typography>
                        </CardContent>
                    </Card>
                    <br/>
                    <Card sx={{ width: 200, textAlign:"center" }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 20 }} color="text.secondary" gutterBottom>
                                Total Orders
                            </Typography>
                            <Typography variant="h5" component="div"></Typography>
                            <Typography variant="h2" sx={{color:"blue", fontWeight:"bold"}}>
                                {viewTableData.length}
                            </Typography>
                        </CardContent>
                    </Card>
                    <CustomerDetailOrderTable viewTableData={viewTableData}/>
                </Box>
            ) 
            : (!customerDetails && viewTableData === "")
            ? (
                <Box sx={style}>
                    <h3>Customer Details Not Found.Please Refer To Shopify</h3>
                </Box>
            ) 
            : (
                <Box sx={style}>
                    <CircularProgress />
                </Box>
            )}
        </Modal>
    </div>
  )
}
