import { Backdrop, Box, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDynamoNonDeliveredOrdersData } from "../../actions/ordersActions";
import RevertFulfillmentsTable from "../ordersWidgets/RevertFulfillmentsTable";
import RedirectLogin from "../RedirectLogin";
import SearchIcon from "@mui/icons-material/Search";
import { allInventory } from "../../actions/inventoryActions";

const RevertFulfillment = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { nonDeliveredOrders } = useSelector((state) => state.orderDetails);
  const { stock } = useSelector((state) => state.inventoryDetails);
  const [searchFilter, setSearchFilter] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  // console.log(filteredOrders)

  useEffect(() => {
    if (auth) dispatch(getDynamoNonDeliveredOrdersData());
    if (stock.length === 0) dispatch(allInventory());
  }, [dispatch, auth]);

  useEffect(() => {
    if (nonDeliveredOrders.length > 0) setFilteredOrders(nonDeliveredOrders);
  }, [nonDeliveredOrders]);

  useEffect(() => {
    if (searchFilter !== "") {
      const filterByType = nonDeliveredOrders.filter((x) =>
        String(x.fulfillment_id).startsWith(searchFilter)
      );
      setFilteredOrders(filterByType);
    } else {
      setFilteredOrders(nonDeliveredOrders);
    }
  }, [searchFilter]);

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {nonDeliveredOrders.length === 0 && (
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={nonDeliveredOrders.length === 0}
            >
              <h1>Loading. Please Wait...</h1>
            </Backdrop>
          )}
          <Box
            sx={{
              display: "flex",
              paddingRight: "30px",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
            }}
          >
            <TextField
              id="input-with-sx"
              label="Search Order"
              variant="outlined"
              size="small"
              sx={{ bgcolor: "white", width: "400px" }}
              value={searchFilter}
              onChange={(e) => setSearchFilter(e.target.value)}
            />
          </Box>
          {filteredOrders.length > 0 ? (
            <RevertFulfillmentsTable
              tableData={filteredOrders}
              setSearchFilter={setSearchFilter}
            />
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <h3>No Orders Found</h3>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default RevertFulfillment;
