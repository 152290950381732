import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MOTTable from "./MOTTable";
import {
    mapShopifyToShipRocketFormat,
    processingExcludedOrders,
    arrangeByDate,
    filterOutFulfilledLineItems,
} from "../../utils/orderTakeoutHelpers";
import { getProcessingOrdersData } from "../../actions/ordersActions";

const ManualOrderTakeout = ({ ordersData }) => {
    const dispatch = useDispatch();
    const { processingOrders } = useSelector((state) => state.orderDetails);

    const [orders, setOrders] = useState([]);
    const [changedOrders, setChangedOrders] = useState([]);

    useEffect(() => {
        if (ordersData.length > 0) {
            const newOrderArray = ordersData
                .filter((o) => o.financial_status !== "voided")
                .map(mapShopifyToShipRocketFormat);

            const processingExcluded = processingExcludedOrders(
                newOrderArray,
                processingOrders
            );

            const removedFulfilledItems =
                filterOutFulfilledLineItems(processingExcluded);

            const sortedOrders = arrangeByDate(removedFulfilledItems);

            setOrders(sortedOrders);
        } else {
            if (processingOrders.length === 0)
                dispatch(getProcessingOrdersData());
        }
    }, [ordersData, processingOrders]);

    const handleChangeOrders = (order, isProcessed) => {
        if (isProcessed) {
            // GETS the selected order, updates some values
            const excluded = orders.filter(
                (o) => o.original_order_id === order.original_order_id
            )[0];
            const newOrderArrOfTable = orders.map((o) => {
                if (o.original_order_id === order.original_order_id)
                    return order;
                else return o;
            });
            setOrders(newOrderArrOfTable);
            setChangedOrders((prev) => [...prev, excluded]);
        } else {
            const replaced = changedOrders.filter(
                (o) => o.original_order_id === order.original_order_id
            )[0];
            const newOrderArrOfTable = orders.map((o) => {
                if (o.original_order_id === order.original_order_id)
                    return replaced;
                else return o;
            });
            setOrders(newOrderArrOfTable);
            setChangedOrders((prev) =>
                prev.filter(
                    (o) => o.original_order_id !== order.original_order_id
                )
            );
        }
    };

    return (
        <div className="content-container-margin">
            {orders.length > 0 ? (
                <MOTTable tableData={orders} onChange={handleChangeOrders} />
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
};

export default ManualOrderTakeout;
