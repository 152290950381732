import { Box, Button, Divider, Stack, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const ShiprocketReturnForm = ({ inventory, order }) => {
  const [data, setData] = useState({});
  const [length, setLength] = useState("");
  const [breadth, setBreadth] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  console.log("inventory", inventory);
  console.log("order", order);
  // {
  //   mrp: "799.00",
  //   name: "name",
  //   id: 14158368768287,
  //   originalSize: "S",
  //   size: "S",
  //   sku: "SK1113",
  // }
  useEffect(() => {
    const trimmedOrder = {
      alternatePhone: order.alternatePhone,
      orderId: order.orderId,
      email: order.email,
      fulfillment_id: order.fulfillment_id,
      products: order.products,
      address: order.address,
      original_order_id: order.original_order_id,
    };
    trimmedOrder.products = trimmedOrder.products.map((p) => {
      const found = inventory.find((i) => {
        const isPresentSKU = i.variants.find((v) => v.sku);
        return isPresentSKU && isPresentSKU.sku.includes(p.sku);
      });
      return {
        ...p,
        name: found.title,
      };
    });
    trimmedOrder.subtotal = trimmedOrder.products.reduce(
      (acc, p) => parseFloat(p.mrp + acc),
      0
    );
    setData(trimmedOrder);
  }, [order, inventory]);
  console.log("data", data);
  const handleSubmit = () => {
    const payload = {
      ...data,
      length,
      breadth,
      height,
      weight,
    };
    console.log("payload", payload);
  };
  return (
    <div>
      <Stack direction={"column"} gap={2} my={2}>
        <h3>Order Number : {data.orderId}</h3>
        <Divider />
        <p style={{ fontWeight: "bold" }}>
          {data.address.first_name} {data.address.last_name}
        </p>
        <p>{data.alternatePhone}</p>
        <p>{data.email}</p>
        <h4>Products</h4>
        {data.products.map((p) => (
          <Stack direction={"row"} gap={2}>
            <p style={{ fontWeight: "bold" }}>
              {p.sku}-{p.size}
            </p>
          </Stack>
        ))}
      </Stack>
      <Stack direction={"row"} gap={2}>
        <TextField
          variant="outlined"
          placeholder={"length"}
          value={length}
          onChange={(e) => setLength(e.target.value)}
        />
        <TextField
          variant="outlined"
          placeholder={"breadth"}
          value={breadth}
          onChange={(e) => setBreadth(e.target.value)}
        />
        <TextField
          variant="outlined"
          placeholder={"height"}
          value={height}
          onChange={(e) => setHeight(e.target.value)}
        />
        <TextField
          variant="outlined"
          placeholder={"weight"}
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
        />
      </Stack>
      <Button variant="contained" onClick={handleSubmit}>
        Create Reverse Order
      </Button>
    </div>
  );
};

export default ShiprocketReturnForm;
