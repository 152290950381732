import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import RedirectLogin from "../RedirectLogin";
import "./index.css";
import dayjs from 'dayjs';
import {TextField, Box, } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { Paper } from '@mui/material';
import { getDeliveredRTOrders, getDispatchedRTOrders } from '../../actions/ordersActions';
import { hideLoading, showLoading } from '../../actions/loaderActions';
import PendingRtoCloseTable from '../ordersWidgets/rto/PendingRtoCloseTable';
import SearchIcon from "@mui/icons-material/Search";
import { allProducts } from '../../actions/productsActions';

const RtoXB = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const {xbDeliveredRtoOrders} = useSelector(state => state.orderDetails)
  const {xbDispatchedRtoOrders} = useSelector(state => state.orderDetails)
//   console.log(xbDeliveredRtoOrders)
  const {productVariants} = useSelector(state => state.productDetails)
//   console.log(productVariants)
  const loading = useSelector(state => state.orderDetails.loading)
  const [date, setDate] = React.useState(dayjs(new Date().toLocaleDateString()));
  const [ orders, setOrders ] = useState([])
  const [ filteredDateOrders, setFilteredDateOrders] = useState([])
    // console.log(filteredDateOrders)
  const [searchFilter, setSearchFilter] = useState("")


  useEffect(() => {
    if(!auth) return
    dispatch(showLoading())
    dispatch(getDeliveredRTOrders())
    dispatch(getDispatchedRTOrders())
    dispatch(allProducts())
  },[auth])

  useEffect(() => {
    if(xbDeliveredRtoOrders.length>0 && xbDispatchedRtoOrders.length>0){
        const allOrders = [...xbDeliveredRtoOrders,...xbDispatchedRtoOrders]
        const filteredOrders = allOrders.filter(x => !!x.rto_delivery_date)
        setOrders(filteredOrders)
        dispatch(hideLoading())}
  },[xbDeliveredRtoOrders, xbDispatchedRtoOrders])

  useEffect(() => {
    if(orders.length>0 && !!date){
        const filteredOrders = orders.filter(x => new Date(x.rto_delivery_date).toLocaleDateString() === new Date(date.$d).toLocaleDateString())
        setFilteredDateOrders(filteredOrders)
    }
  },[orders,date])

  useEffect(() => {
    if(searchFilter !== ""){
        const searchedOrder = orders.filter(x => x.fulfillment_id.startsWith(searchFilter))
        if(searchedOrder){
            setFilteredDateOrders(searchedOrder)
        }
        else return
    }else{
        const filteredOrders = orders.filter(x => new Date(x.rto_delivery_date).toLocaleDateString() === new Date(date.$d).toLocaleDateString())
        setFilteredDateOrders(filteredOrders)
    }
  },[orders,searchFilter,date])

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
            {(loading || orders.length === 0 || productVariants.length === 0) 
            ? (
                <div className="load-overlay">
                <h1>Please Wait...</h1>
                </div>
            ) : (
                <>
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <Paper style={{maxWidth:"400px"}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <CalendarPicker date={date} maxDate={dayjs(new Date().toLocaleDateString())} onChange={(newDate) => setDate(newDate)} />
                            </LocalizationProvider>
                        </Paper>
                    </div>
                    <br/>
                    <Box sx={{ display: "flex", paddingRight:"30px", alignItems: "flex-end", gap: 1 }}>
                        <SearchIcon />
                        <TextField
                        id="input-with-sx"
                        label="Search Order"
                        variant="standard"
                        onChange={(e) => setSearchFilter(e.target.value)}
                        />
                    </Box>
                    {/* <h3>Pending Orders on {new Date(date.$d).toLocaleDateString('en-in')} : </h3> */}
                    {filteredDateOrders.length>0 ? (
                        <PendingRtoCloseTable viewTableData={filteredDateOrders} productVariants={productVariants} setSearchFilter={setSearchFilter}/>
                    ) : "No Orders On this Date"}
                    </>
            )}
            
        </>
      )}
    </>
  )
}

export default RtoXB