import React, { useEffect, useState } from "react";
import {
  getMaterialList,
  getVendorMaterialList,
} from "../../actions/materialActions";
import {
  Box,
  Divider,
  MenuItem,
  Paper,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";
import MaterialAmtTable from "./MaterialAmtTable";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import FabricAvailabilityPieChart from "./FabricAvailabilityPieChart";
function removeDuplicates(arr) {
  return arr.filter(
    (item, index, self) => index === self.findIndex((t) => t.code === item.code)
  );
}
const MaterialStock = () => {
  const [loading, setLoading] = useState(false);
  const [colors, setColors] = useState([]);

  const [selectedFabric, setSelectedFabric] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [materials, setMaterials] = useState([]);

  const { materialFabricList: fabrics } = useSelector(
    (state) => state.inventoryDetails
  );

  useEffect(() => {
    if (fabrics.length > 0) {
      setSelectedFabric(fabrics[0].code);
    }
  }, [fabrics]);

  useEffect(() => {
    if (selectedFabric) {
      setColors([]);
      getMaterialList(selectedFabric).then((data) => {
        if (data.statusCode === 200) {
          const vendorColors = data.body.map((m) => ({
            code: m.colorId,
            name: m.colorName,
          }));
          if (vendorColors.length === 0) {
            toast.error("No colors found for this fabric");
          }
          vendorColors.unshift({
            code: "All",
            name: "All",
          });
          const vendorListAdd = data.body.map((b) => ({
            ...b,
            vendorNames: b.vendors.map((v) => v.name).join(" , "),
          }));
          setMaterials(vendorListAdd);
          setColors(vendorColors);
          setSelectedColor("All");
        }
      });
    }
  }, [selectedFabric]);

  useEffect(() => {
    if (selectedFabric) {
      const filtered = materials.filter(
        (m) =>
          m.fabricId === selectedFabric &&
          (selectedColor === "All" || m.colorId === selectedColor)
      );
      setFilteredMaterials(filtered);
      console.log(filtered);
    }
  }, [selectedFabric, selectedColor, materials]);
  return (
    <div>
      {filteredMaterials && (
        <FabricAvailabilityPieChart materials={filteredMaterials} />
      )}
      <Paper sx={{ p: 4 }}>
        {loading && (
          <Stack spacing={1} my={3}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />

            <Skeleton variant="rectangular" width={500} height={60} />
            <Skeleton variant="rounded" width={500} height={60} />
          </Stack>
        )}
        {!loading && selectedFabric && (
          <Box>
            <Stack
              direction={"row"}
              gap={2}
              sx={{
                justifyContent: "center",
              }}
            >
              {/* fabric */}
              <TextField
                style={{ width: "250px" }}
                select
                label="Select Fabric"
                value={selectedFabric}
                onChange={(e) => setSelectedFabric(e.target.value)}
                variant="standard"
                className="select"
                // fullWidth
              >
                {fabrics.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              {/* color */}
              <TextField
                style={{ width: "250px" }}
                disabled={colors.length === 0}
                select
                label="Select Color"
                value={selectedColor}
                onChange={(e) => setSelectedColor(e.target.value)}
                variant="standard"
                className="select"
                // fullWidth
              >
                {colors.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </Box>
        )}
        {filteredMaterials.length > 0 && (
          <MaterialAmtTable materials={filteredMaterials} />
        )}
      </Paper>
    </div>
  );
};

export default MaterialStock;
