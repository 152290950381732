import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import MuiTextField from "@mui/material/TextField";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewMaterial,
  getMaterialColorList,
  getMaterialFabricList,
  uploadImage,
} from "../../../actions/materialActions";
import { materialVendors } from "../../../utils/helperArrays";
//custom textfield
const ProcessingTextField = styled(MuiTextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    paddingLeft: 0,
    paddingRight: 0,
  },
  "& .MuiInputAdornment-root": {
    backgroundColor: "lightblue",
    padding: "12px 14px",
    borderTopLeftRadius: "3px",
    borderBottomLeftRadius: "3px",
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const AddMaterial = () => {
  const [fabric, setFabric] = useState();
  const [color, setColor] = useState();
  const [unit, setUnit] = useState("kg");
  const [conversionValueToKg, setConversionValueToKg] = useState(0);
  const [conversionValueToMetre, setConversionValueToMetre] = useState(0);
  const [vendors, setVendors] = useState([]);
  const [url, setUrl] = useState("");

  const [loading, setLoading] = useState(false);

  const { materialFabricList, materialColorList } = useSelector(
    (state) => state.inventoryDetails
  );
  const dispatch = useDispatch();

  const handleNumberInputKg = (e) => {
    // if (isNaN(parseFloat(e.target.value))) {
    //   setConversionValueToKg(0);
    // } else {
    //   setConversionValueToKg(parseFloat(e.target.value));
    // }
    setConversionValueToKg(e.target.value);
  };
  const handleNumberInputMetre = (e) => {
    // if (isNaN(parseFloat(e.target.value))) {
    //   setConversionValueToMetre(0);
    // } else {
    //   setConversionValueToMetre(parseFloat(e.target.value));
    // }
    setConversionValueToMetre(e.target.value);
  };
  const handleChange = (event) => {
    setVendors(event.target.value); // selected options
  };
  const handleAdd = async () => {
    setLoading(true);
    const fabricValue = JSON.parse(fabric);
    const colorValue = JSON.parse(color);
    // upload image
    const imageUploadPayload = {
      productId: `Material-${fabricValue.code}${colorValue.code}`,
      dataUrl: url,
    };
    const imageUploadResponse = await uploadImage(imageUploadPayload);
    if (imageUploadResponse.statusCode === 500) {
      return;
    }
    const payload = {
      fabricName: fabricValue.name,
      fabricId: fabricValue.code,
      colorName: colorValue.name,
      colorId: colorValue.code,
      unit,
      rollToKg: parseFloat(conversionValueToKg),
      rollToMetre: parseFloat(conversionValueToMetre),
      vendors,
      imageUrl: imageUploadResponse.url,
    };
    const res = await addNewMaterial(payload);
    if (res.statusCode === 200) {
      setFabric(undefined);
      setColor(undefined);
      setUrl("");
      setConversionValueToKg(0);
      setConversionValueToMetre(0);
      setVendors([]);
    }
    setLoading(false);
  };
  const handleUploadClick = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      // setImg(() => [...img, ...newFile]);
      // console.log(requiredFile[0].size)
      const reader = new FileReader();
      reader.readAsDataURL(newFile);
      reader.onload = () => {
        setUrl(reader.result);
      };
    }
  };
  return (
    <div>
      <h2
        style={{
          textAlign: "center",
        }}
      >
        Add Material Form
      </h2>
      <Paper sx={{ p: 4 }}>
        {/* fabric and color */}
        <Stack direction={"row"} gap={2} sx={{ marginBottom: 3 }}>
          <TextField
            style={{ width: "300px" }}
            select
            label="Select Fabric"
            value={fabric || ""}
            onChange={(e) => setFabric(e.target.value)}
            defaultValue={""}
            variant="standard"
            className="select"
            // fullWidth
          >
            {materialFabricList.map((option) => (
              <MenuItem key={option.code} value={JSON.stringify(option)}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            style={{ width: "300px" }}
            select
            label="Select Color"
            value={color || ""}
            defaultValue={""}
            onChange={(e) => setColor(e.target.value)}
            variant="standard"
            className="select"
            // fullWidth
          >
            {materialColorList.map((option) => (
              <MenuItem key={option.code} value={JSON.stringify(option)}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        {/* image  */}
        <Box sx={{ marginBottom: 3 }}>
          <div>
            <label htmlFor="contained-button-file">
              <Button variant="outlined" component="span">
                {url ? "Change Image" : "Select Image"}
                <input
                  accept="image/*"
                  id="contained-button-file"
                  style={{
                    display: "none",
                  }}
                  type="file"
                  onChange={handleUploadClick}
                />
              </Button>
            </label>
          </div>
          {url && (
            <img
              height={200}
              style={{
                marginTop: "10px",
                objectFit: "contain",
              }}
              src={url}
              alt=""
            />
          )}
        </Box>
        {/* unit kg or m */}
        <Stack direction={"row"} gap={3} sx={{ marginBottom: 3 }}>
          <TextField
            style={{ width: "100px" }}
            select
            label="Select Unit"
            value={unit}
            onChange={(e) => setUnit(e.target.value)}
            variant="standard"
            className="select"
            // fullWidth
          >
            {["kg", "m"].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          {/* conversion formula to Kg  */}
          <ProcessingTextField
            type="number"
            sx={{ width: "250px" }}
            variant="standard"
            label="Conversion Formula"
            value={conversionValueToKg}
            onChange={handleNumberInputKg}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <h3
                    style={{
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    1 Roll =
                  </h3>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <h3
                    style={{
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    Kg
                  </h3>
                </InputAdornment>
              ),
            }}
          />
          <ProcessingTextField
            type="number"
            sx={{ width: "250px" }}
            variant="standard"
            label="Conversion Formula"
            value={conversionValueToMetre}
            onChange={handleNumberInputMetre}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <h3
                    style={{
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    1 Roll =
                  </h3>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <h3
                    style={{
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    m
                  </h3>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        {/* vendors */}
        <div>
          <FormControl sx={{ width: 300 }}>
            <TextField
              select
              variant="standard"
              label="Vendors"
              SelectProps={{
                multiple: true,
                value: vendors,
                onChange: (e) => handleChange(e),
                renderValue: (selected) =>
                  selected.map((s) => s.name).join(", "),
                style: {
                  width: "250px",
                  maxHeight: `${ITEM_HEIGHT} * 4.5 + ${ITEM_PADDING_TOP}`,
                },
              }}
            >
              {materialVendors.map((option) => (
                <MenuItem key={option.code} value={option}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={vendors
                          .map((v) => v.name)
                          .includes(option.name)}
                      />
                    }
                    label={option.name}
                  />
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </div>
        {/* Create button */}
        <Stack
          my={3}
          direction={"row"}
          sx={{
            justifyContent: "end",
          }}
        >
          <Button disabled={loading} variant="contained" onClick={handleAdd}>
            Add Material
          </Button>
        </Stack>
      </Paper>
    </div>
  );
};

export default AddMaterial;
