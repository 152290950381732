import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, TextField, Box } from "@mui/material";
import { getSingleShipRocketOrderDetails } from "../../actions/ordersActions";

const SyncSRTable = ({ data }) => {
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState(data);
    const [searchedOrder, setSearchedOrder] = useState("");

    useEffect(() => {
        if (searchedOrder !== "") {
            setTableData(
                data.filter((o) => o.fulfillment_id.startsWith(searchedOrder))
            );
        } else {
            setTableData(data);
        }
    }, [searchedOrder, data]);

    const handleSubmit = (order) => {
        // DO something
        dispatch(getSingleShipRocketOrderDetails(order.order_id));
    };

    return (
        <div>
            <h4>Total Undelivered: {data.length}</h4>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 4,
                }}
            >
                Search Specific Order:
                <Box>
                    <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        value={searchedOrder}
                        onChange={(e) => setSearchedOrder(e.target.value)}
                    />
                </Box>{" "}
            </div>
            {tableData
                .sort(
                    (a, b) =>
                        parseFloat(a.fulfillment_id) -
                        parseFloat(b.fulfillment_id)
                )
                .map((o) => (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            maxWidth: "600px",
                            marginBottom: "10px",
                        }}
                        key={o.fulfillment_id}
                    >
                        <p>Shopify: {o.fulfillment_id}</p>
                        <p>AWB: {o.awb_code || "Not generated"}</p>
                        <p>Status:{o.package_status}</p>
                        {o.sr_status ? (
                            <p>{o.sr_status}</p>
                        ) : (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => handleSubmit(o)}
                            >
                                Get SR Status
                            </Button>
                        )}
                    </div>
                ))}
        </div>
    );
};

export default SyncSRTable;
