import { lambdaFetch } from "../utils/awsUtils";
import { showUnauthorizedModal } from "./errorModalActions";
import { toast } from "react-toastify";
import { hideLoading, showLoading } from "./loaderActions";

export const sendPayloadToMail = (payload) => {
  async function sendMail() {
    const Url =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/sendpayloadwithawb";

    const response = await lambdaFetch(Url, {
      method: "POST",

      body: JSON.stringify(payload),
    });
    if (response.status === 403) {
      return 403;
    }
    const data = await response.json();
    return data;
  }
  return (dispatch) => {
    sendMail()
      .then((data) => {
        if (data === 403) {
          dispatch(showUnauthorizedModal());
          return;
        }
        if (data.statusCode === 200) {
          toast.success("Mail sended successfully");
        } else {
          toast.error("Error in sending mail");
        }
      })
      .catch((error) => {
        toast.error("something went wrong with connecting to services", {
          theme: "colored",
        });
      });
  };
};

export const sendREapprovedMessage = (email) => {
  async function sendMail() {
    const Url =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/sendreturnexchangemessage";

    const response = await lambdaFetch(Url, {
      method: "POST",

      body: JSON.stringify({ status: "Approved", email }),
    });
    if (response.status === 403) {
      return 403;
    }
    const data = await response.json();
    return data;
  }
  return (dispatch) => {
    sendMail()
      .then((data) => {
        if (data === 403) {
          dispatch(showUnauthorizedModal());
          return;
        }
        if (data.statusCode === 200) {
          toast.success("Mail sended successfully");
        } else {
          toast.error("Error in sending mail");
        }
      })
      .catch((error) => {
        toast.error("something went wrong with connecting to services", {
          theme: "colored",
        });
      });
  };
};

export const sendRErejectedMessage = (email) => {
  async function sendMail() {
    const Url =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/sendreturnexchangemessage";

    const response = await lambdaFetch(Url, {
      method: "POST",

      body: JSON.stringify({ status: "Rejected", email }),
    });
    if (response.status === 403) {
      return 403;
    }
    const data = await response.json();
    return data;
  }
  return (dispatch) => {
    sendMail()
      .then((data) => {
        if (data === 403) {
          dispatch(showUnauthorizedModal());
          return;
        }
        if (data.statusCode === 200) {
          toast.success("Mail sended successfully");
        } else {
          toast.error("Error in sending mail");
        }
      })
      .catch((error) => {
        toast.error("something went wrong with connecting to services", {
          theme: "colored",
        });
      });
  };
};

export const getInvalidEmailList = () => {
  async function getEmailList() {
    const Url =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/listemailbystatus";

    const response = await lambdaFetch(Url, {
      method: "POST",

      body: JSON.stringify({ status: "permanent_bounce" }),
    });
    if (response.status === 403) {
      return 403;
    }
    const data = await response.json();

    console.log(data);
    return data;
  }
  return (dispatch) => {
    dispatch(showLoading());
    getEmailList()
      .then((data) => {
        if (data === 403) {
          dispatch(showUnauthorizedModal());
          return;
        }
        console.log(data);
        if (data.statusCode === 200) {
          toast.success("Invalid Emails fetched successfully");
          dispatch({
            type: "INVALID_EMAIL",
            payload: data.body.email_list,
          });
        } else {
          toast.error("email list couldn't be fetched");
        }
        dispatch(hideLoading());
      })
      .catch((error) => {
        dispatch(hideLoading());
        toast.error("Something went wrong with connecting to database", {
          theme: "colored",
        });
      });
  };
};

export const getComplaintsEmailList = () => {
  async function getEmailList() {
    const Url =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/listemailbystatus";

    const response = await lambdaFetch(Url, {
      method: "POST",

      body: JSON.stringify({ status: "complaints" }),
    });
    if (response.status === 403) {
      return 403;
    }
    const data = await response.json();

    console.log(data);
    return data;
  }
  return (dispatch) => {
    dispatch(showLoading());
    getEmailList()
      .then((data) => {
        if (data === 403) {
          dispatch(showUnauthorizedModal());
          return;
        }
        console.log(data);
        if (data.statusCode === 200) {
          toast.success("Invalid Emails fetched successfully");
          dispatch({
            type: "COMPLAINT_EMAIL",
            payload: data.body.email_list,
          });
        } else {
          toast.error("email list couldn't be fetched");
        }
        dispatch(hideLoading());
      })
      .catch((error) => {
        dispatch(hideLoading());
        toast.error("Something went wrong with connecting to database", {
          theme: "colored",
        });
      });
  };
};

export const getUnsuccessfulEmailList = () => {
  async function getEmailList() {
    const Url =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/orders/listemailbystatus";

    const response = await lambdaFetch(Url, {
      method: "POST",

      body: JSON.stringify({ status: "soft_bounce" }),
    });
    if (response.status === 403) {
      return 403;
    }
    const data = await response.json();

    console.log(data);
    return data;
  }
  return (dispatch) => {
    dispatch(showLoading());
    getEmailList()
      .then((data) => {
        if (data === 403) {
          dispatch(showUnauthorizedModal());
          return;
        }
        console.log(data);
        if (data.statusCode === 200) {
          toast.success("Invalid Emails fetched successfully");
          dispatch({
            type: "UNSUCCESSFUL_EMAIL",
            payload: data.body.email_list,
          });
        } else {
          toast.error("email list couldn't be fetched");
        }
        dispatch(hideLoading());
      })
      .catch((error) => {
        dispatch(hideLoading());
        toast.error("Something went wrong with connecting to database", {
          theme: "colored",
        });
      });
  };
};

export const sendTicketResponseToMail = (email, ticketId, customerId) => {
  console.log({ status: "ticket_updated", email, ticketId });
  async function sendMail() {
    const Url =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/tickets/sessendticketupdate";

    const response = await lambdaFetch(Url, {
      method: "POST",

      body: JSON.stringify({
        status: "ticket_updated",
        email,
        ticketId,
        customerId,
      }),
    });
    if (response.status === 403) {
      return 403;
    }
    const data = await response.json();
    return data;
  }
  return (dispatch) => {
    sendMail()
      .then((data) => {
        if (data === 403) {
          dispatch(showUnauthorizedModal());
          return;
        }
        if (data.statusCode === 200) {
          toast.success("Mail sended successfully");
        } else {
          toast.error("Error in sending mail");
        }
      })
      .catch((error) => {
        toast.error("something went wrong with connecting to mail services", {
          theme: "colored",
        });
      });
  };
};
