import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import ItemCard from "./ItemCard";
import Chip from "@mui/material/Chip";
import { spacing } from "@mui/system";

const HistoryTable = ({ data }) => {
  console.log("From Table", data);
  console.log("order Date: ", data[0].dbData[0].order_date)
  return (
    <div>
      {data.map((row, index) => (
        <Box key={index} mt={4}>
  <Paper>
  <div>
            <Box
              display="flex"
              flexDirection="row"
              gap={2} 
              justifyContent= "center"
              alignItems= "center"
            >
              <Paper style={{ padding: 16 }}><h6>Order No: </h6>{row.order_number}</Paper>
              <Paper style={{ padding: 16 }}><h6>Financial Status: </h6>{row.financial_status}</Paper>
              <Paper style={{ padding: 16 }}><h6>Fulfillment Status: </h6>{row.fulfillment_status}</Paper>
              <Paper style={{ padding: 16 }}><h6>Created at: </h6>{row.created_at}</Paper>
              <Paper style={{ padding: 16 }}><h6>Total Price: </h6>{row.total_price}</Paper>
            </Box>
            <Box mx={2} my={1}><h6>Address: </h6>
                {row.shipping_address.address1 +
                      row.shipping_address.city +
                      row.shipping_address.zip +
                      row.shipping_address.province
                    }
            </Box>
            <Box mx={2}><Paper><h6>Tags: </h6>{row.tags? row.tags : "No Tags Added Yet"}</Paper></Box>
            <Box mt={2} mx={2}>
              Line Items:{" "}
              {row.line_items.map((item, i) => (
                <Chip key={i} label={item.sku} color="primary" />
              ))}
            </Box>
            </div>
          {
           ( row.dbData.length) > 0 ? 
<TableContainer component={Paper}>
            <Table spacing={3}>
              <TableHead>
                <TableRow>
                  <TableCell>Order Date</TableCell>
                  <TableCell>fulfillment_id</TableCell>
                  <TableCell>Total Price</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Details</TableCell>
                  <TableCell>Ordered Items</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                    row.dbData.map((db , i) => (
                        <TableRow key={row.id}>
                  <TableCell>{db.order_date}</TableCell>
                  <TableCell>{db.fulfillment_id}</TableCell>
                  <TableCell>₹{db.total}</TableCell>
                  <TableCell>{String(db.package_status).replace("sync#","")}</TableCell>
                  <TableCell>
                    <Typography>
                      {<>AWB_Code: {db.awb_code}</>}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    No of Items Ordered: {db.order_items.length}
                    <Stack direction="column" spacing={1}>
                      {db.order_items.map((item, i) => (
                        <Chip key={i} label={item.sku} color="primary" />
                      ))}
                    </Stack>
                  </TableCell>
                </TableRow>
                    ))
                }
              </TableBody>
            </Table>
          </TableContainer>
            : 
            <h3>No orders found in database</h3>
          }
  </Paper>
        </Box>
      ))}
    </div>
  );
};

export default HistoryTable;
