import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStateWiseSalesData } from "../../actions/ordersActions";
import StateWiseSalesTable from "../salesWidgets/StateWiseSalesTable";
const StateWiseSales = () => {
  const { stateWiseSalesData } = useSelector((state) => state.orderDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStateWiseSalesData());
  }, []);
  console.log("statewie", stateWiseSalesData);

  return (
    <div>
      <StateWiseSalesTable sales={stateWiseSalesData} />
    </div>
  );
};

export default StateWiseSales;
