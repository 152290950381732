import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMaterialColorList } from "../../../actions/materialActions";
import ListTable from "./ListTable";

const ColorList = () => {
  const { materialColorList } = useSelector((state) => state.inventoryDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    if (materialColorList.length === 0) dispatch(getMaterialColorList());
  }, []);
  return (
    <div>
      <ListTable data={materialColorList} />
    </div>
  );
};

export default ColorList;
