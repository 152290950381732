const initialState = {
  shiprocket_orders: [],
  loading: false,
  deliveredOrders : [],
  backupOrders : []
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHIPROCKET_ORDERS":
      return {
        ...state,
        shiprocket_orders: action.data,
      };
    case "SHOW_LOADER":
      return {
        ...state,
        loading: true,
      };
    case "HIDE_LOADER":
      return {
        ...state,
        loading: false,
      };
    case "GST_DATA":
      return {
        ...state,
        deliveredOrders : action.payload
      };
    case "BACKUP_DATA":
      return {
        ...state,
        backupOrders : action.payload
      };
    default:
      return state;
  }
};

export default reportReducer;
