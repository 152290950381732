import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    revertProcessing,
    fulfillInShopify,
    markStatusDynamo,
} from "../../actions/ordersActions";
import {
    Box,
    Button,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    TableFooter,
    TablePagination,
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePaginationActions from "../../utils/TablePaginationActions";
import { createPDFforManifest } from "../../utils/pdfUtils";
import ConfirmPopup from "./ConfirmPopup";
import { toast } from "react-toastify";

function OrderActionsSelector({ orders, reset, disableActions }) {
    const dispatch = useDispatch();
    const [popupVisible, setPopupVisible] = useState(false);

    const deleteProcessing = () => {
        dispatch(revertProcessing(orders[0]));
        setPopupVisible(false);
        reset([]);
    };

    const handleChange = (e) => {
        if (e.target.value === 1) {
            // MARK DISPATCHED
            toast.error("Cannot take this action");
            // dispatch(fulfillInShopify(orders[0]));
            // dispatch(markStatusDynamo(orders[0].fulfillment_id, "dispatched"));
            reset([]);
            // Refresh the page
        } else {
            setPopupVisible(true);
        }
    };

    return (
        <FormControl variant="standard" sx={{ m: 2, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={""}
                label="Age"
                onChange={handleChange}
            >
                {!disableActions && (
                    <MenuItem value={1}>Mark Dispatched</MenuItem>
                )}
                <MenuItem value={2}>Cancel Order</MenuItem>
            </Select>
            <ConfirmPopup
                visible={popupVisible}
                cancel={() => setPopupVisible(false)}
                proceed={deleteProcessing}
            />
        </FormControl>
    );
}

const PDFDownloader = ({ data }) => {
    return (
        <Button
            variant="contained"
            color="secondary"
            onClick={() => createPDFforManifest(data)}
        >
            Download Manifest
        </Button>
    );
};

export default function PickupReadyTable({ tableData, disableActions }) {
    const loading = useSelector((state) => state.loader.loading);
    const [data, setData] = useState(
        tableData?.sort(
            (a, b) => Number(a.original_order_id) - Number(b.original_order_id)
        )
    );
    const [open, setOpen] = useState([]);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (tableData.length > 0) {
            setOpen(Array(tableData.length).fill(false));
        }
        setData(
            [...tableData]
                ?.sort(
                    (a, b) =>
                        Number(a.original_order_id) -
                        Number(b.original_order_id)
                )
                .reverse()
        );
    }, [tableData]);

    return (
        <>
            {loading && (
                <div className="load-overlay">
                    <h1>Please Wait...</h1>
                </div>
            )}
            <br />
            {selectedOrders.length === 1 ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "flex-end",
                        gap: "20px",
                    }}
                >
                    <h4>Selected Action: </h4>
                    <OrderActionsSelector
                        orders={selectedOrders}
                        reset={setSelectedOrders}
                        disableActions={disableActions}
                    />
                </div>
            ) : (
                <h4>Select only one order to perform actions.</h4>
            )}
            <br />
            {tableData.length > 0 ? (
                <>
                    <PDFDownloader data={data} />
                    <br />
                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {tableData.length} Items
                                        <br /> Select
                                    </TableCell>
                                    <TableCell align="right">
                                        Order Number
                                    </TableCell>
                                    <TableCell align="right">
                                        AWB Code
                                    </TableCell>
                                    <TableCell align="right">Date</TableCell>
                                    <TableCell align="right">
                                        Package Status
                                    </TableCell>
                                    <TableCell align="right">
                                        Shipping Cost
                                    </TableCell>
                                    <TableCell align="right">
                                        Sub Total
                                    </TableCell>
                                    <TableCell align="right">Total</TableCell>
                                    <TableCell align="right">Product</TableCell>
                                    <TableCell align="left">List</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? data.slice(
                                          page * rowsPerPage,
                                          page * rowsPerPage + rowsPerPage
                                      )
                                    : data
                                ).map((row, index) => (
                                    <React.Fragment key={index}>
                                        <TableRow
                                            key={index}
                                            sx={{
                                                bgcolor:
                                                    row.status === "fulfilled"
                                                        ? "rgba(145,145,145, 0.2)"
                                                        : index % 2 === 0
                                                        ? "#afd275"
                                                        : "#edf5e1",

                                                "& > *": {
                                                    borderBottom: "unset",
                                                },
                                            }}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <Checkbox
                                                    color="primary"
                                                    checked={
                                                        selectedOrders.filter(
                                                            (i) =>
                                                                i.fulfillment_id ===
                                                                row.fulfillment_id
                                                        ).length > 0
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setSelectedOrders([
                                                                ...selectedOrders,
                                                                row,
                                                            ]);
                                                        } else {
                                                            setSelectedOrders(
                                                                selectedOrders.filter(
                                                                    (item) =>
                                                                        item.fulfillment_id !==
                                                                        row.fulfillment_id
                                                                )
                                                            );
                                                        }
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.original_order_id} (#
                                                {row.fulfillment_id})
                                            </TableCell>
                                            <TableCell>
                                                {row.awb_code}
                                            </TableCell>
                                            <TableCell align="right">
                                                {new Date(
                                                    row.order_date
                                                ).toLocaleDateString()}
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    fontWeight: "bold",
                                                    color: "blue",
                                                }}
                                                align="right"
                                            >
                                                {row.package_status}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.shipping_charges}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.sub_total}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.total}
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.order_items.length} Items
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => {
                                                        const temp = open.map(
                                                            (i, openIndex) => {
                                                                if (
                                                                    index !==
                                                                    openIndex
                                                                ) {
                                                                    return i;
                                                                } else {
                                                                    return !i;
                                                                }
                                                            }
                                                        );
                                                        setOpen(temp);
                                                    }}
                                                >
                                                    {open[index] ? (
                                                        <KeyboardArrowUpIcon />
                                                    ) : (
                                                        <KeyboardArrowDownIcon />
                                                    )}
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                style={{
                                                    paddingBottom: 0,
                                                    paddingTop: 0,
                                                }}
                                                colSpan={6}
                                            >
                                                <Collapse
                                                    in={open[index]}
                                                    timeout="auto"
                                                    unmountOnExit
                                                >
                                                    <Box sx={{ margin: 1 }}>
                                                        <Typography
                                                            variant="h6"
                                                            gutterBottom
                                                            component="div"
                                                        >
                                                            Product Details
                                                        </Typography>
                                                        <Table
                                                            size="small"
                                                            aria-label="purchases"
                                                        >
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        Name
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        SKU
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        Size
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        Quantity
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        M.R.P
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        Discount
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {row.order_items.map(
                                                                    (
                                                                        productRow
                                                                    ) => (
                                                                        <TableRow
                                                                            key={
                                                                                productRow.sku
                                                                            }
                                                                        >
                                                                            <TableCell
                                                                                component="th"
                                                                                scope="row"
                                                                            >
                                                                                {
                                                                                    productRow.name
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {
                                                                                    productRow.sku
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell align="right">
                                                                                {
                                                                                    productRow.variant_title
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell align="right">
                                                                                {
                                                                                    productRow.units
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell align="right">
                                                                                {
                                                                                    productRow.selling_price
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell align="right">
                                                                                {
                                                                                    productRow.discount
                                                                                }
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                        <h4>
                                                            Shipping Address:
                                                        </h4>
                                                        <p>
                                                            Name:{" "}
                                                            {row.billing_customer_name +
                                                                " " +
                                                                row.billing_last_name}
                                                            <br />
                                                            Mobile:{" "}
                                                            {row.shipping_phone}
                                                            <br />
                                                            Address:{" "}
                                                            {
                                                                row.shipping_address
                                                            }
                                                            <br />
                                                            {
                                                                row.shipping_address_2
                                                            }
                                                            <br />
                                                            {row.shipping_city}
                                                            <br />
                                                            {
                                                                row.shipping_country
                                                            }
                                                            <br />
                                                            {
                                                                row.shipping_pincode
                                                            }
                                                        </p>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}

                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{ height: 53 * emptyRows }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        style={{ overflow: "inherit" }}
                                        rowsPerPageOptions={[
                                            5,
                                            10,
                                            25,
                                            { label: "All", value: -1 },
                                        ]}
                                        colSpan={3}
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                "aria-label": "rows per page",
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={
                                            handleChangeRowsPerPage
                                        }
                                        ActionsComponent={
                                            TablePaginationActions
                                        }
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </>
            ) : (
                "No Under Process Orders At Present"
            )}
        </>
    );
}
