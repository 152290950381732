import React, { useEffect, useState } from "react";
import ViewAllOrdersFilter from "./ViewAllOrdersFilter";
import { filterTypes } from "../../utils/helperArrays";
import { TextField, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";

const ViewOrders = ({orderData}) => {
    // const orderData = useSelector((state) => state.orderDetails.orders);
    const [displayedOrders, setDisplayedOrders] = useState([]);
    const [filter, setFilter] = useState("")

    useEffect(() => {
        let filteredOrders = [];
        switch (filter) {
            case "fulfilled":
                filteredOrders = orderData.filter(
                    (o) => o.fulfillment_status === "fulfilled"
                );
                break;
            case "unfulfilled":
                filteredOrders = orderData.filter((o) => o.fulfillment_status === null);
                break;
            case "partial":
                filteredOrders = orderData.filter(
                    (o) => o.fulfillment_status === "partial"
                );
                break;
            default:
                filteredOrders = [...orderData];
        }
        
        const filteredFinalOrders = filteredOrders.filter(o => o.financial_status !== "partially_refunded" && o.financial_status !== "voided" && o.financial_status !== "refunded")
        setDisplayedOrders(filteredFinalOrders);

    }, [filter]);
    //     if (orderData.length > 0) {
    //         const newOrderArray = orderData
    //             .map((item) => {
    //                 const newOrder = {
    //                     order: item.order_number,
    //                     date: new Date(item.created_at).toLocaleDateString(),
    //                     name: item.billing_address?.name, //HYPD sent orders without billing address
    //                     total: item.current_total_price,
    //                     products: item.line_items,
    //                     payment: item.financial_status,
    //                     address: item.shipping_address,
    //                     status: item.fulfillment_status, //can be null too
    //                 };
    //                 return newOrder;
    //             })
    //             .filter((o) => o.payment !== "voided");
    //         setAllOrders(newOrderArray);
    //         const displayArray = newOrderArray.filter((o) => o.status === null);
    //         setDisplayedOrders(displayArray);
    //     }
    // }, [orderData]);

    return (
        <div>
            <br />
            <TextField
                select
                label="Select Order Type"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                variant="outlined"
                className="select"
                style={{width:"500px"}}
            >
                {filterTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <ViewAllOrdersFilter tableData={displayedOrders} />
        </div>
    );
};

export default ViewOrders;
