import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { allInventory } from "../../actions/inventoryActions";
import BaseTable from "./BaseTable";
import { dispatchManualHelper } from "../../utils/orderTakeoutHelpers";
import { underProcessOrders } from "../../actions/ordersActions";
import { resetInventoryStock } from "../../actions/inventoryActions";
import { createPDFforManifest } from "../../utils/pdfUtils";

const MOTTable = ({ tableData, onChange }) => {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.loader.loading);
    const initialStock = useSelector((state) => state.inventoryDetails.stock);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [stock, setStock] = useState(initialStock);
    const [changedItems, setChangedItems] = useState([]);

    useEffect(() => {
        if (initialStock.length === 0) dispatch(allInventory());
        setStock(initialStock);
    }, [initialStock]);

    useEffect(() => console.log("CURRENT", selectedOrders), [selectedOrders]);

    const handleSelectionChange = (order, toggle) => {
        if (toggle) {
            //check stock against these products
            // IF stock, decrement stock and update the order to be pushed to database
            const { updatedOrder, updatedStock, changedNow } =
                dispatchManualHelper(order, stock);

            if (updatedOrder.fulfillable) {
                setSelectedOrders((prev) => [...prev, updatedOrder]);
                setStock(updatedStock);
                onChange(updatedOrder, true);
                setChangedItems((prev) => [...prev, ...changedNow]);
            } else {
                // Create an error toast
                toast.error(
                    "Don't have enough stock to fulfill this order. Please choose another.",
                    {
                        theme: "colored",
                    }
                );
            }
        } else {
            const filtered = selectedOrders.filter(
                (o) => o.original_order_id !== order.original_order_id
            );
            setSelectedOrders(filtered);
            // Readjust stock
            const toBeAdjusted = changedItems.filter(
                (i) => i.order_id === order.original_order_id
            );
            // in changedItems array this product will be found with diff field
            const adjustedStock = stock.map((p) => {
                const found = toBeAdjusted.find((i) => i.barcode === p.barcode);
                if (found) {
                    return { ...p, count: p.count + found.diff };
                } else {
                    return p;
                }
            });

            setStock(adjustedStock);
            // remove from changedItems
            setChangedItems((prev) =>
                prev.filter((i) => i.order_id !== order.original_order_id)
            );
            onChange(order, false);
        }
    };

    const handleSubmit = async () => {
        console.log("PROCESSED", selectedOrders);
        const payload = selectedOrders.map((o) => ({
            ...o,
            fulfillment_id: o.fulfillment_id.toString(),
            awb_code : "change"
        }));
        dispatch(underProcessOrders(payload));

        dispatch(resetInventoryStock(stock));

        createPDFforManifest(payload);
    };

    return (
        <>
            {loading && (
                <div className="load-overlay">
                    <h1>Please Wait...</h1>
                </div>
            )}
            {selectedOrders.length > 0 ? (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                    }}
                >
                    <h4>Selected Orders : {selectedOrders.length}</h4>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleSubmit}
                    >
                        Generate Manifest
                    </Button>
                </div>
            ) : (
                <h4>Select orders to manually ship.</h4>
            )}
            <BaseTable
                tableData={tableData}
                selected={selectedOrders}
                setSelected={handleSelectionChange}
            />
        </>
    );
};

export default MOTTable;
