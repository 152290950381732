import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { read, utils } from "xlsx";
import { useState } from "react";
import { Box, Button, TextField, Typography, Stack } from "@mui/material";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { toast } from "react-toastify";
import { io } from "socket.io-client";

import { rtoSyncData } from "../../actions/backendSync";

const RtoMisReportSync = () => {
    const [excelFile, setExcelFile] = useState([]);
    const [syncConnect, setSyncConnect] = useState(false);
    const [syncStatus, setSyncStatus] = useState({});
    const dispatch = useDispatch();
    
    let selectedFile;
    
    useEffect(() => {
      const socket = io("http://localhost:8000");
      socket.on("connect", () => {
        setSyncConnect(true);
      });
      socket.on("sync-status", (status, date, message) => {
        console.log(status, message)
        setSyncStatus((prev) => ({ ...prev, [status]: { message, date } }));
        socket.emit("dummy_run", "ok");
        if(status === 'completed') socket.off("connect")
      });
    }, []);

    useEffect(() => {
      if(excelFile.length>0){
        toast.success("Excel File Uploaded Successfully")
      }else{
        toast.error("No Excel File Uploaded")
        console.log("No Excel File Uploaded")
      }
    }, [excelFile]);

    const handler = (e) => {
      selectedFile = e.target.files[0];
    };

    const handlerSubmit = () => {
      if (selectedFile) {
        let fileReader = new FileReader();
        fileReader.readAsBinaryString(selectedFile);
        fileReader.onload = (e) => {
          let data = e.target.result;
          let workbook = read(data, { type: "binary" });
          workbook.SheetNames.forEach((sheet) => {
            let rowObject = utils.sheet_to_json(workbook.Sheets[sheet], {
                raw: false,
                // dateNF:'yyyy-mm-dd'
            });
            setExcelFile((e) => [...e, rowObject]);
          });
        };
      }
    };

    const handleSyncData = () => {
        const presentDate = new Date().toLocaleDateString()
        const rtoData = excelFile[0].filter(x => x.STATUS === "RTO")

        const syncData = rtoData.map(x => ({
            fulfillment_id: x.POID,
            status: x.SHIPMENTSTATUS === "WHHandover" ? "deliveredRT" : "dispatchedRT",
            rto_declared_on: x.RTODATE ? x.RTODATE : "",
            rto_delivery_date: x.DELIVERYDATE ? x.DELIVERYDATE : presentDate,
        }))

        dispatch(rtoSyncData(syncData))
    }

    return (
      <div>
        <h3>Upload RTO MIS Report</h3>
        <div style={{display:"flex", alignItems:"center"}}>
            <TextField type={"file"} onChange={handler} inputProps={{accept:".xls,.xlsx,.csv,.xlsb"}}/>
            <Button style={{left:"10px"}} variant="contained" color="error" onClick={() => {
                    setExcelFile([])
                }}>Clear File</Button>
        </div>
        <br/>
        <Button variant="contained" color="error" onClick={handlerSubmit}>Upload File</Button>
        <br/>
        <br/>
        {excelFile.length>0 ? (
            <Button variant="contained" color="secondary" onClick={handleSyncData}>Sync Data</Button>
        ) : ""}
        <br/>
        <br/>
        <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
          <Stack gap={0.1}>
            <h2 style={{ margin: 0 }}>Status Logs ...</h2>
            {Object.keys(syncStatus).map((s, index) => (
              <Box>
                <Stack>
                  <Stack direction={"row"} alignItems="center" gap={0.5}>
                    <AccessTimeFilledIcon
                      sx={{
                        color:
                          Object.keys(syncStatus).length - 1 > index
                            ? "green"
                            : "black",
                      }}
                    />
                    <h5 style={{ margin: 0, color: "#808080" }}>
                      {syncStatus[s]["date"]}
                    </h5>
                  </Stack>
                  <Typography
                    variant="h6"
                    component="p"
                    sx={{
                      fontStyle: "italic",
                      borderLeft: "3px solid",
                      borderColor:
                        Object.keys(syncStatus).length - 1 > index
                          ? "green"
                          : "black",
                      marginLeft: "8.5px",
                      paddingLeft: "10px",
                      color:
                        Object.keys(syncStatus).length - 1 > index
                          ? "green"
                          : "black",
                    }}
                  >
                    {syncStatus[s]["message"]}
                  </Typography>
                </Stack>
              </Box>
            ))}
          </Stack>
        </Stack>
        <Box position={"fixed"} bottom={0} left={0} paddingLeft={1}>
          {syncConnect ? (
            <h3 style={{ color: "green" }}>Connected To LBSync</h3>
          ) : (
            <h3 style={{ color: "red" }}>Not Connected</h3>
          )}
        </Box>
      </div>
    );
}

export default RtoMisReportSync