import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography
} from "@mui/material";
import React, { useState, useEffect } from "react";


const DPAnalytics = ({ designs }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    const itemsAddName = designs.map((d) => {
      const items = d.items.map((i) => ({ ...i, name: d.name }));
      return {
        ...d,
        items,
      };
    });
    const products = itemsAddName
      .reduce((arr, row) => {
        const newArr = [...arr, ...row.items];
        return newArr;
      }, [])
      .map((p) => ({ src: p.src, likes: p.likes, name: p.name }))
      .sort((a, b) => b.likes - a.likes);
    setData(products);
  }, [designs]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  return (
    <div className="top-perform-design" style={{paddingLeft:"70px"}}>  
        
      {/* body container */}
      <Paper
            elevation={3}
            sx={{
              backgroundColor: "#f1f1f1",
              borderRadius: "10px",
              height: "auto",
              width: "100%",
              position: "relative",
              padding: "20px",
              "@media (max-width: 768px)": {
                width: "340px", // Adjust styles for screens with a max width of 600px
                left: "-8.2rem",
              },
            }}
          >
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                marginBottom: "1rem",
                fontWeight: "900",
                fontSize: "2rem",
                fontFamily: "Lato",
                "@media (max-width: 768px)": {
                  fontSize: "1.5rem", 
                }
              }}
            >
              Top Performing Designs
            </Typography>
      
        <TableContainer 
          component={Paper}
          style={{
            borderRadius: "10px",
            padding: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            height: "600px",
            width: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <Table sx={{ minWidth: "100%" }}>
            <TableHead sx={{ backgroundColor: "#3c4040", position:"sticky"}}>
              <TableRow sx={{ backgroundColor: "#3c4040", position:"sticky"}}>
                <TableCell sx={{color:"white",borderRadius:"10px 0px 0px 10px"}}>Design</TableCell>
                <TableCell sx={{color:"white"}}>Name</TableCell>
                <TableCell sx={{color:"white",borderRadius:"0px 10px 10px 0px"}}>Likes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((d, i) => (
                  <TableRow
                    key={i}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <img
                        src={d.src}
                        width={100}
                        height={100}
                        alt={`Design ${i}`}
                      />
                    </TableCell>
                    <TableCell>{d.name}</TableCell>
                    <TableCell>{d.likes}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
    </div>
  );
};

export default DPAnalytics;
