import React, { useState, useEffect } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TableFooter,
  TablePagination,
  Button,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePaginationActions from "../../utils/TablePaginationActions";

export default function NDROrdersTable({ tableData }) {
  const [data, setData] = useState(
    tableData?.sort(
      (a, b) => Number(a.channel_order_id) - Number(b.channel_order_id)
    )
  );
  const [open, setOpen] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (tableData.length > 0) {
      setOpen(Array(tableData.length).fill(false));
    }
    setData(
      [...tableData]
        ?.sort(
          (a, b) => Number(a.channel_order_id) - Number(b.channel_order_id)
        )
        .reverse()
    );
  }, [tableData]);

  return (
    <>
      <br />

      <br />
      {tableData.length > 0 ? (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {tableData.length} Items
                  <br /> Select
                </TableCell>
                <TableCell align="right">Order Number</TableCell>
                <TableCell align="right">AWB</TableCell>
                <TableCell align="right">Dispatch Date</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Courier</TableCell>
                <TableCell align="right">Payment Method</TableCell>
                <TableCell align="right">NDR Reason</TableCell>
                <TableCell align="right">Name</TableCell>
                <TableCell align="right">Phone</TableCell>
                <TableCell align="right">Attempts</TableCell>
                {/* <TableCell>Actions</TableCell> */}
                <TableCell align="left">Order Details</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? data.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : data
              ).map((row, index) => (
                <React.Fragment key={index}>
                  <TableRow
                    key={index}
                    sx={{
                      bgcolor: index % 2 === 0 ? "#afd275" : "#edf5e1",

                      "& > *": { borderBottom: "unset" },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="right">{row.channel_order_id}</TableCell>
                    <TableCell align="right">{row.awb_code}</TableCell>
                    <TableCell align="right">
                      {new Date(row.created_at).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="right">
                      {row.status || "pending"}
                    </TableCell>
                    <TableCell align="right">{row.courier}</TableCell>
                    <TableCell align="right">{row.payment_method}</TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold", color: "blue" }}
                      align="right"
                    >
                      {row.reason}
                    </TableCell>
                    <TableCell align="right">{row.customer_name}</TableCell>
                    <TableCell align="right">{row.customer_phone}</TableCell>
                    <TableCell align="right">{row.attempts}</TableCell>
                    {/* <TableCell><Button variant="contained">Re-Attempt</Button></TableCell> */}
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                          const temp = open.map((i, openIndex) => {
                            if (index !== openIndex) {
                              return i;
                            } else {
                              return !i;
                            }
                          });
                          setOpen(temp);
                        }}
                      >
                        {open[index] ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse in={open[index]} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            Product Details
                          </Typography>
                          <Table size="small" aria-label="purchases">
                            <TableHead>
                              <TableRow>
                                <TableCell>SHIPMENT ID</TableCell>
                                <TableCell>Product SKU(s)</TableCell>
                                <TableCell>PRICE</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>{row.shipment_id}</TableCell>
                                <TableCell>{row.channel_sku}</TableCell>
                                <TableCell>{row.product_price}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                          <h4>Shipping Address:</h4>
                          <p>
                            Name: {row.customer_name}
                            <br />
                            Mobile: {row.customer_phone}
                            <br />
                            Email: {row.customer_email}
                            <br />
                            Address: {row.customer_address}
                            <br />
                            {row.customer_address_2}
                            <br />
                            {row.customer_city}
                            <br />
                            {row.customer_state}
                            <br />
                            {row.customer_pincode}
                          </p>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  style={{ overflow: "inherit" }}
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        "No NDR Orders At Present"
      )}
    </>
  );
}
