import { Box, CircularProgress, Divider } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  downloadPOexcelFile,
  getPOexcelFileList,
} from "../../../actions/POActions";

const DownloadPOList = () => {
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [data, setData] = useState([]);
  useEffect(() => {
    (async () => {
      setLoading(true);
      const listData = await getPOexcelFileList();
      console.log("list data", listData);
      if (listData.status === 200) {
        setData(listData.data);
      } else {
        setErrMessage("couldn't find files");
      }
      setLoading(false);
    })();
  }, []);
  const handleDownload = async (key) => {
    console.log("downloading");
    const downloadData = await downloadPOexcelFile(key);
    if (downloadData.status === 200) {
      window.open(downloadData.data);
    }
  };
  return (
    <div>
      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <CircularProgress size={"50px"} />
        </Box>
      ) : (
        <div>
          {data.map((b) => (
            <div>
              <h3
                onClick={() => handleDownload(b)}
                onMouseOver={(e) => (e.target.style.color = "black")}
                onMouseOut={(e) => (e.target.style.color = "gray")}
                style={{
                  margin: "10px 10px",
                  fontWeight: "400",
                  color: "gray",
                  cursor: "pointer",
                }}
              >
                {b.split(".")[0]}
              </h3>
              <Divider />
            </div>
          ))}
        </div>
      )}
      {errMessage && <h3>{errMessage}</h3>}
    </div>
  );
};

export default DownloadPOList;
