import { Box, Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import POCard from "./POCard";
import usePagination from "../../utils/POPagination";
import { useSelector, useDispatch } from "react-redux";
import { selectPOProducts, getLastPOId } from "../../actions/POActions";
import POCartManual from "./POCartManual";
import POCheckout from "./POCheckout";

const POShopManual = ({ inventory }) => {
    let initial;

    initial = localStorage?.getItem("selectedProducts")
        ? JSON.parse(localStorage.getItem("selectedProducts"))
        : [];

    const selectedProducts = useSelector((state) => state.PO.products);
    // const [selectedProducts, setSelectedProducts] = useState(initial);
    const dispatch = useDispatch();

    //pagination
    const [page, setPage] = useState(1);
    const [poId, setPoId] = useState(null);
    const PER_PAGE = 15;
    const paginationCount = Math.ceil(inventory.length / PER_PAGE);
    const _DATA = usePagination(inventory, PER_PAGE);

    const handlepaginationchange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
    };

    const handlePOclick = (product) => {
        const found = selectedProducts?.find((i) => i.SKU === product.SKU);
        if (found) {
            const filtered = selectedProducts.filter(
                (i) => i.SKU !== found.SKU
            );

            // setSelectedProducts(filtered);
            dispatch(selectPOProducts(filtered));
        } else {
            // setSelectedProducts(() => [...selectedProducts, product]);
            dispatch(selectPOProducts([...selectedProducts, product]));
        }
    };

    console.log("po shop selected", selectedProducts);

    const fetchPOId = async () => {
        const data = await getLastPOId();
        if (data?.success) {
            console.log(data.id);
            const numId = Number(data.id);
            if (isNaN(numId)) {
                console.log("Show error", numId);
                toast.error("ID not converted to NUmber");
            } else {
                const newId = numId + 1;
                setPoId(newId.toString());
            }
        } else {
            //Print error
            toast.error(data?.message);
        }
    };

    useEffect(() => {
        fetchPOId();
        dispatch(selectPOProducts(initial));
    }, []);

    useEffect(() => {
        localStorage.setItem(
            "selectedProducts",
            JSON.stringify(selectedProducts)
        );
    }, [selectedProducts]);

    useEffect(() => console.log("PO Id", poId), [poId]);

    return (
        <>
            {/* products section */}
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-evenly",
                    marginTop: 5,
                    gap: 2,
                }}
            >
                {_DATA.currentData().length > 0 ? (
                    _DATA.currentData().map((row) => {
                        let selected;
                        const found = selectedProducts?.find(
                            (i) => i.SKU === row.SKU
                        );
                        found ? (selected = true) : (selected = false);
                        return (
                            <Box
                                onClick={() => {
                                    handlePOclick(row);
                                }}
                            >
                                <POCard data={row} selected={selected} />
                            </Box>
                        );
                    })
                ) : (
                    <></>
                )}
            </Box>
            {/* pagination */}
            <Box
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Pagination
                    sx={{
                        marginTop: 4,
                        marginBottom: 4,
                        transformOrigin: "center",
                    }}
                    page={page}
                    count={paginationCount}
                    size="large"
                    color="primary"
                    onChange={handlepaginationchange}
                />
                <POCartManual poId={poId} />
            </Box>
            {/* checkout buttons */}
        </>
    );
};

export default POShopManual;
