import {
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    changeTag,
    closeOrder,
    fulfillOrder,
    getDBOrders,
    getShopifyCustomerOrdersAsync,
    holdOrderFulfillment,
    openOrder,
    showOrderFulfillment,
} from "../../actions/customerActions";
import { allInventory } from "../../actions/inventoryActions";
import {
    getDelhiveryShipmentDetails,
    getXBShipmentDetails,
} from "../../actions/ordersActions";
import { DelhiveryOrderHistory } from "../delhivery/tracking/DelhiveryOrderHistory";

function Row(props) {
    const {
        row,
        changeCustomerFulfillmentOrders,
        changeCustomerOrders,
        index,
    } = props;
    const [openTagsBox, setOpenTagsBox] = useState(false);
    const [tags, setTags] = useState("");
    const [orderId, setOrderId] = useState(null);
    const [fulfillLoading, setFulfillLoading] = useState(false);
    const [orderHistoryModal, setOrderHistoryModal] = useState(false);
    const dispatch = useDispatch();

    const handleCloseTagsBox = () => {
        setOpenTagsBox(false);
    };

    const handleFulfillOrder = async (orderId, tags) => {
        setFulfillLoading(true);

        const tagData = await changeTag(orderId, tags);
        if (tagData.statusCode !== 200) return;
        const newTag = tagData.data.order.tags;

        const data = await fulfillOrder(orderId);
        if (data !== 500 && data.statusCode !== 500) {
            changeCustomerFulfillmentOrders(orderId, newTag);
        }
        setOpenTagsBox(false);
        setFulfillLoading(false);
    };

    const handleOpenHistory = (awb) => {
        dispatch(getDelhiveryShipmentDetails(awb));
        setOrderHistoryModal(true);
    };

    return (
        <React.Fragment>
            <TableRow
                sx={{
                    backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F5F5F5",
                    "& h4": {
                        margin: 0,
                        padding: 0,
                        fontSize: "0.9rem",
                        fontWeight: 400,
                        fontFamily: "Roboto",
                    },
                }}
            >
                <TableCell>
                    <h4>
                        {new Date(row.created_at)
                            .toLocaleDateString()
                            .toString()}
                    </h4>
                </TableCell>
                <TableCell>{row.financial_status}</TableCell>
                <TableCell>
                    <h4>{row.name}</h4>
                </TableCell>
                {/* {row.order_items.map((o, i) => ( */}
                <React.Fragment>
                    <TableCell>
                        {/* {row.order_items.map((o) => (<React.Fragment></React.Fragment>))} */}
                        {row.order_items.map((o, i) => (
                            <Stack
                                key={i}
                                direction={"row"}
                                sx={{
                                    justifyContent: "space-between",
                                    py: "8px",
                                    borderBottom:
                                        i !== row.order_items.length - 1
                                            ? "1px solid"
                                            : "0px",
                                    borderColor: "rgb(224,224,224)",
                                }}
                            >
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        minWidth: `211px`,
                                    }}
                                >
                                    <h4>
                                        {o.processed_at ===
                                            "not found in database" ||
                                        typeof o.processed_at !== "string" ||
                                        !o.processed_at
                                            ? "-"
                                            : new Date(
                                                  o.processed_at
                                              ).toLocaleDateString()}
                                    </h4>
                                </Box>
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        minWidth: "205px",
                                        cursor: "pointer",
                                        "& :hover": {
                                            textDecoration: "underline",
                                            color: "blue",
                                        },
                                    }}
                                    onClick={() =>
                                        handleOpenHistory(o.awb_code)
                                    }
                                >
                                    <h4>
                                        {o.fulfillment_id ===
                                        "not found/unfulfilled"
                                            ? "-"
                                            : o.fulfillment_id}
                                    </h4>
                                </Box>
                                <Box sx={{ flexGrow: 1 }}>
                                    {o.line_items.map((p, i) => (
                                        <h4 key={i}>
                                            {p.sku} ({p.variant_title})
                                        </h4>
                                    ))}
                                </Box>
                            </Stack>
                        ))}
                    </TableCell>
                    <TableCell>
                        <h4>{row.tags}</h4>
                    </TableCell>
                </React.Fragment>
                <TableCell>
                    {fulfillLoading ? (
                        <CircularProgress />
                    ) : row.fulfillment_status !== "fulfilled" ? (
                        <Button
                            variant="contained"
                            onClick={() => {
                                setOrderId(row.id);
                                setTags(row.tags);
                                setOpenTagsBox(!openTagsBox);
                            }}
                        >
                            Fulfill
                        </Button>
                    ) : (
                        <h3>Fulfilled</h3>
                    )}
                </TableCell>
            </TableRow>
            {/* for fulfill orders */}
            <Dialog open={openTagsBox} onClose={handleCloseTagsBox} fullWidth>
                <DialogTitle>Tags</DialogTitle>
                <DialogContent>
                    <Stack direction={"column"} gap={2}>
                        <TextField
                            variant="outlined"
                            minRows={10}
                            value={tags}
                            onChange={(e) => setTags(e.target.value)}
                        />
                        {fulfillLoading ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                variant="contained"
                                onClick={() => {
                                    // handleHoldFulfillment(holdType, holdReason);
                                    handleFulfillOrder(orderId, tags);
                                }}
                            >
                                Fulfill Order
                            </Button>
                        )}
                    </Stack>
                </DialogContent>
            </Dialog>
            {/* order history */}
            {orderHistoryModal ? (
                <DelhiveryOrderHistory
                    open={orderHistoryModal}
                    setOrderHistoryModal={setOrderHistoryModal}
                />
            ) : (
                ""
            )}
        </React.Fragment>
    );
}

const CustomerOrders = (customer) => {
    const [tableData, setTableData] = useState([]);
    const [orders, setOrders] = useState([]);

    const changeCustomerFulfillmentOrders = (orderId, newTag) => {
        const newTableData = tableData.map((o) => {
            if (o.id === orderId) {
                o.fulfillment_status = "fulfilled";
                o.tags = newTag;
            }
            return o;
        });
        setTableData(newTableData);
    };

    const changeCustomerOrders = (orderId, order) => {
        const newTableData = tableData.map((o) => {
            if (o.id === orderId) {
                o.closed_at = order.closed_at;
                o.tags = order.tags;
            }
            return o;
        });
        setTableData(newTableData);
    };

    useEffect(() => {
        (async () => {
            setTableData([]);
            const data = await getShopifyCustomerOrdersAsync(
                customer.customer.id
            );
            console.log("data", customer.customer.id, data);
            if (data === 500) {
                setOrders([]);
            } else {
                setOrders(data);
            }
        })();
    }, [customer.customer.id]);

    useEffect(() => {
        if (!orders.length > 0) return;
        const list = orders.map((o) => ({
            orderNumber: o.order_number,
            fulfillmentStatus: o.fulfillment_status,
            id: o.id,
        }));
        let customerOrders = [];

        (async () => {
            for (let i = 0; i < list.length; i++) {
                const orderFound = orders.filter(
                    (o) => o.order_number === list[i].orderNumber
                )[0];

                const fulfillment_orders = await showOrderFulfillment(
                    list[i].id
                );
                const selected = { ...orderFound, fulfillment_orders };

                const dbOrders = await getDBOrders(list[i].orderNumber);

                if (dbOrders.statusCode === 200 && dbOrders.data.length > 0) {
                    const dbOrderItems = dbOrders.data.reduce((acc, item) => {
                        const order_items = item.order_items.map((o) => ({
                            ...o,
                            fulfillment_id: item.fulfillment_id,
                            awb_code: item.awb_code,
                            package_status: item.package_status,
                            processed_at: item.processed_at,
                            ndr_processedAt:
                                "ndr_processedAt" in item
                                    ? item.ndr_processedAt
                                    : "not found",
                            rto_declared_on:
                                "rto_declared_on" in item
                                    ? item.rto_declared_on
                                    : "not found",
                            rto_delivery_date:
                                "rto_delivery_date" in item
                                    ? item.rto_delivery_date
                                    : "not found",
                            delivery_date:
                                "delivery_date" in item
                                    ? item.delivery_date
                                    : "not found",
                        }));
                        acc.push(...order_items);
                        return acc;
                    }, []);
                    const formatted = {
                        ...selected,
                        line_items: selected.line_items.map((oi) => {
                            const found = dbOrderItems.find(
                                (i) => i.id === oi.id
                            );
                            if (!!found) {
                                return {
                                    ...oi,
                                    fulfillment_id: found.fulfillment_id,
                                    awb_code: found.awb_code,
                                    package_status: found.package_status,
                                    processed_at: found.processed_at,
                                    ndr_processedAt: found.ndr_processedAt,
                                    rto_declared_on: found.rto_declared_on,
                                    rto_delivery_date: found.rto_delivery_date,
                                    delivery_date: found.delivery_date,
                                };
                            }
                            return {
                                ...oi,
                                fulfillment_id: "not found/unfulfilled",
                                awb_code: "not found",
                                package_status: "not found",
                                processed_at: "not found",
                                ndr_processedAt: "not found",
                                rto_declared_on: "not found",
                                rto_delivery_date: "not found",
                                delivery_date: "not found",
                            };
                        }),
                    };
                    const formattedFulfillmentIdwise = {
                        ...formatted,
                        order_items: formatted.line_items.reduce((acc, p) => {
                            const found = acc.find(
                                (i) => i.fulfillment_id === p.fulfillment_id
                            );
                            if (!!found) {
                                found.line_items = [
                                    ...found.line_items,
                                    {
                                        ...p,
                                    },
                                ];
                            } else {
                                const newFulfillmentId = {
                                    fulfillment_id: p.fulfillment_id,
                                    line_items: [
                                        {
                                            ...p,
                                        },
                                    ],
                                    package_status: p.package_status,
                                    processed_at: p.processed_at,
                                    awb_code: p.awb_code || "Not updated",
                                    ndr_processedAt: p.ndr_processedAt,
                                    rto_declared_on: p.rto_declared_on,
                                    rto_delivery_date: p.rto_delivery_date,
                                    delivery_date: p.delivery_date,
                                };
                                acc.push(newFulfillmentId);
                            }
                            return acc;
                        }, []),
                    };

                    // setOrder((prev) => [...prev, ...formattedFulfillmentIdwise]);
                    customerOrders.push(formattedFulfillmentIdwise);
                } else if (
                    dbOrders.statusCode === 200 &&
                    dbOrders.data.length === 0
                ) {
                    const formatted = {
                        ...selected,
                        order_items: [
                            {
                                fulfillment_id: "not found/unfulfilled",
                                line_items: selected.line_items,
                                package_status: "not found in database",
                                processed_at: "not found in database",
                                ndr_processedAt: "not found in database",
                                rto_declared_on: "not found in database",
                                rto_delivery_date: "not found in database",
                                delivery_date: "not found in database",
                                awb_code: "not found in database",
                            },
                        ],
                    };
                    // setOrder((prev) => [...prev, formatted]);
                    customerOrders.push(formatted);
                } else {
                    const formatted = {
                        ...selected,
                        order_items: [
                            {
                                fulfillment_id: "Database error",
                                awb_code: "database error",
                                line_items: selected.line_items,
                                package_status: "database connection error",
                                processed_at: "database connection error",
                                ndr_processedAt: "database connection error",
                                rto_declared_on: "database connection error",
                                rto_delivery_date: "database connection error",
                                delivery_date: "database connection error",
                            },
                        ],
                    };
                    customerOrders.push(formatted);
                }
            }

            setTableData(customerOrders);
        })();
    }, [orders]);

    return (
        <Box component={Paper}>
            {tableData.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow
                                sx={{ bgcolor: "rgba(0, 181, 204, 0.1)" }}
                            >
                                <TableCell align="left">Order Date</TableCell>
                                <TableCell align="left">
                                    Payment Status
                                </TableCell>
                                <TableCell align="left">Order Id</TableCell>
                                <TableCell align="center">Details</TableCell>
                                <TableCell align="left">Tags</TableCell>
                                <TableCell align="left">Fulfill</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.map((row, index) => (
                                <Row
                                    key={index}
                                    index={index}
                                    row={row}
                                    changeCustomerFulfillmentOrders={
                                        changeCustomerFulfillmentOrders
                                    }
                                    changeCustomerOrders={changeCustomerOrders}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                "Loading..."
            )}
        </Box>
    );
};

export default CustomerOrders;
