import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { ArrowDropUp } from "@mui/icons-material";
import { ArrowDropDown } from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";
import {
  getAmzDate,
  uploadDesign,
  uploadDesignImages,
} from "../../actions/inventoryActions";
import DPAnalytics from "./DPAnalytics";
import Customer from "./Customer";
const REQUIRED_IMAGE_COUNT = 4; // Number of images required




const CustomFileInput = ({ onChange }) => {
  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files.length === REQUIRED_IMAGE_COUNT) {
      onChange(Array.from(files));
    } else {
      alert("Please Select 4 Images");
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems:"flex-start", marginBottom: "1rem" , paddingLeft:"0"}}>
      <Button
        variant="outlined"
        component="label"
        startIcon={<AddPhotoAlternateIcon />}
        sx={{ textTransform: "none" }}
      >
        Select Images
        <input
          type="file"
          accept="image/jpeg, image/png"
          multiple
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
      </Button>
    </Box>
  );
};

//Styles

const designPickerStyles = {
  backgroundColor: "#f1f1f1",
  borderRadius: "10px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  height: "auto",
  width: "500px",
  position: "relative",
  left: "-10rem",
  padding:"20px",
  top:"50px",
  "@media (max-width: 600px)": {
    width: "320px", // Adjust styles for screens with a max width of 600px
    left: "-4rem",
  },
};

const titleStyles = {
  marginBottom: "2rem",
  fontWeight: "700",
  fontSize: "2rem",
  fontFamily: "Lato",
};

const imagePreviewStyles = {
  position: "relative",
  textAlign: "center",
};

const imageStyles = {
  width: "100%",
  maxHeight: "130px",
  borderRadius: "8px",
  marginBottom: "0.6rem",
};

const replaceButtonStyles = {
  position: "absolute",
  bottom: "-1.5rem",
  left: "50%",
  transform: "translateX(-50%)",
  textTransform: "none",
  width: "fit-content",
  fontSize: "0.65rem",
  color: "black",
  backgroundColor: "#CCCCCC",
};

const uploadButtonStyles = {
  textTransform: "none",
  backgroundColor: "#000000",
};

// End of StyLES

const DesignPicker = ({ loading, designs, changeDesign }) => {
  const [designName, setDesignName] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [uploadedDesigns, setUploadedDesigns] = useState([]);
  const [designImgToUpload, setDesignImgToUpload] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

   // Add sorting state and toggle function
  const [sortOrder, setSortOrder] = useState("asc");

  // Function to toggle sorting order
  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const handleReplaceImage = (index) => (e) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/jpeg, image/png";
    fileInput.multiple = false;

    fileInput.addEventListener("change", (e) => {
      const files = e.target.files;
      if (files.length === 1) {
        const newSelectedFiles = [...selectedFiles];
        newSelectedFiles[index] = files[0];
        setSelectedFiles(newSelectedFiles);
      }
    });

    fileInput.click();
  };

  useEffect(() => {
    setDesignImgToUpload([]);
    selectedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      console.log("file", file);
      reader.onload = () => {
        const imageUrlB64 = reader.result;
        setDesignImgToUpload((prev) => [
          ...prev,
          { imgB64: imageUrlB64, type: file.type },
        ]);
      };
    });
  }, [selectedFiles]);

  const handleUpload = async () => {
    setUploadLoading(true);
    if (!designName.trim()) {
      alert("Please enter a Design Name");
      setUploadLoading(false);
      return;
    }

    if (selectedFiles.length !== REQUIRED_IMAGE_COUNT) {
      setUploadLoading(false);
      alert("Please Select 4 Images");
    } else {
      // upload images
      const imgData = await uploadDesignImages(designImgToUpload, designName);
      if (imgData.statusCode === 500) {
        setUploadLoading(false);
        return;
      }
      // after uploading images
      const images = imgData.data;
      const newDesignItems = images.map((img, i) => ({
        id: i + 1,
        likes: 0,
        src: img,
      }));
      const payload = [
        {
          name: designName,
          id: getAmzDate(new Date().toISOString()),
          items: newDesignItems,
        },
        ...designs,
      ];
      const data = await uploadDesign(payload);
      if (data.statusCode === 500) {
        setUploadLoading(false);
        return;
      }
      changeDesign(data.data);
      // after uploading
      const newUploadedDesigns = data.data.map((d) => ({
        name: d.name,
        images: d.items
          .sort((a, b) => {
            if (a.likes < b.likes) {
              return 1;
            } else if (a.likes > b.likes) {
              return -1;
            } else {
              return 0;
            }
          })
          .map((file, index) => ({
            url: file.src,
            votes: file.likes, // Initialize votes to 0 for the uploaded design
          })),
      }));
      setUploadedDesigns(newUploadedDesigns);
      setUploadLoading(false);
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setDesignName("");
    setSelectedFiles([]);
  };

  const handleReset = () => {
    setOpen(false);
    setDesignName("");
    setSelectedFiles([]);
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpenPopup(true);
  };

  // Color classes for different votes
  const colorClasses = ["#66DE73 ", "#A5CFFA", "#FFD54F", "#FFAB91"];

  useEffect(() => {
    const designsFormatted = designs.map((d) => ({
      name: d.name,
      images: d.items
        .sort((a, b) => {
          if (a.likes < b.likes) {
            return 1;
          } else if (a.likes > b.likes) {
            return -1;
          } else {
            return 0;
          }
        })
        .map((file, index) => ({
          url: file.src,
          votes: file.likes, // Initialize votes to 0 for the uploaded design
        })),
    }));

    setUploadedDesigns(designsFormatted);
  }, [designs]);
  console.log("selected files", selectedFiles);

  return (
    <Container maxWidth="lg" sx={{ "@media (max-width: 768px)": {
      width: "100%", // Adjust styles for screens with a max width of 600px
    }, }}>
      <Grid container spacing={2} sx={{ paddingLeft:"50px", paddingTop:"50px"}}>
       
          {/* Top Section page */}
              <Grid item xs={12}> 
               <Grid container spacing={2}>    

                {/* Top Left side (Designs Report) */}
                 <Grid item xs={12} md={6}> 
                  <Paper
                    elevation={3}
                    sx={{
                      backgroundColor: "#f1f1f1",
                      borderRadius: "10px",
                      height: "auto",
                      width: "720px",
                      position: "relative",
                      left: "-10rem",
                      padding: "20px",
                      "@media (max-width: 768px)": {
                        width: "383px", // Adjust styles for screens with a max width of 600px
                        left: "-5.5rem",
                      },
                    }}
                  >
                    <Typography
                      variant="h4"
                      gutterBottom
                      sx={{
                        marginBottom: "1rem",
                        fontWeight: "900",
                        fontSize: "2.5rem",
                        fontFamily: "Lato",
                        "@media (max-width: 768px)": {
                          fontSize: "1.5rem", 
                        }
                      }}
                    >
                      Designs Report
                    </Typography>
                    <TableContainer
                      component={Paper}
                      style={{
                        borderRadius: "10px",
                        padding: "10px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        height: "600px",
                        width: "100%",
                        overflowY: "scroll",
                        overflowX: "hidden",
                        "@media (max-width: 768px)": {
                          maxHeight: "none",
                        }
                      }}
                    >
                      <Table>
                        <TableHead sx={{"@media (max-width: 768px)": {
                                  height: "10px",
                                }}}>
                          <TableRow>
                            <TableCell
                              style={{
                                borderRadius: "10px 0px 0 10px",
                                backgroundColor: "#3c4040",
                                color:"white",
                                fontWeight: "normal",
                                position: "sticky",
                                top: 0,
                                zIndex: 1,
                                height:"10px",
                                "@media (max-width: 768px)": {
                                  height: "10px",
                                }
                              }}
                            >
                              {/* Add sorting functionality to the Design Name column */}
                              
                              <IconButton
                                onClick={toggleSortOrder}
                                sx={{ marginLeft: "4px", color: "white" }}
                              >
                                {sortOrder === "asc" ? (
                                  <ArrowDropUp />
                                ) : (
                                  <ArrowDropDown />
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell
                              style={{
                                backgroundColor: "#3c4040",
                                borderRadius:"0 10px 10px 0",
                                paddingLeft:"10px",
                                color:"white",
                                fontWeight: "bold",
                                position: "sticky",
                                top: 0,
                                zIndex: 1,
                                textAlign: "center",
                                "@media (max-width: 768px)": {
                                  height: "10px",
                                }
                              }}
                            >
                              DESIGNS
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody sx={{paddingTop:"10px"}}>
                          {uploadedDesigns.sort((a, b) => {
                              if (sortOrder === "asc") {
                                return a.name.localeCompare(b.name);
                              } else {
                                return b.name.localeCompare(a.name);
                              }
                            })
                          .map((design) => (
                            <TableRow sx={{"@media (max-width: 768px)": {
                                    paddingTop:"10px"
                            }}} key={design.name}>
                              <TableCell
                                style={{
                                  borderRadius: "0 10px 0 0",
                                  fontWeight: "bold",
                                  "@media (max-width: 768px)": {
                                    
                                  }
                                }}
                              >
                                {design.name}
                              </TableCell>
                              <Table sx={{ paddingTop:"10px",
                                          "@media (max-width: 768px)": {
                                          display:"flex",
                                          flexDirection:"column",
                                          paddingTop:"10px",
                                          left:"2px"
                                        }
                                        }}>
                                <div className="design-div"
                                  style={{
                                    display: "flex", 
                                    flexWrap:"wrap",                              
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flex: "0 0 50%",
                                  }}
                                >
                                  {design.images.map((image, imgIndex) => (
                                    <div
                                      key={imgIndex}
                                      style={{
                                        marginBottom: "8px",
                                        marginRight: "8px",
                                        textAlign: "center",                                     
                                      }}
                                      className="image-preview"
                                      onClick={() => handleImageClick(image.url)}
                                    >
                                      <img
                                        src={image.url}
                                        alt={"img"}
                                        style={{
                                          width: "100px",
                                          height: "100px",
                                          marginBottom: "4px",
                                          borderRadius: "8px",
                                        }}
                                      />
                                      <div
                                        style={{
                                          backgroundColor:
                                            imgIndex < colorClasses.length
                                              ? colorClasses[imgIndex]
                                              : "transparent",
                                          padding: "2px 6px",
                                          borderRadius: "10px",
                                          color: "#333333",
                                          fontWeight: "light",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <ThumbUpAltIcon
                                          sx={{
                                            color: "#000000",
                                            opacity: 1 - imgIndex / colorClasses.length,
                                          }}
                                        />
                                        <span style={{ marginLeft: "4px" }}>
                                          {image.votes}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </Table>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>  



          {/* Top Right side (Top Performing Designs) */}
                        <Grid item xs={12} md={6}>
                          <DPAnalytics designs={designs} />
                        </Grid>
                      </Grid>
              </Grid>
                  

          {/* Horizontal divider */}
              <Grid item xs={12}>
                  <Divider sx={{ height: "100%", marginBottom: "2rem", color:"black"}} />
                </Grid>

          {/* Bottom Section */}

                <Grid item xs={12}>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start"}}>          
                  <Grid container spacing={2}>

              {/* Bottom-Left Section "Design Picker" */}
                <Grid item xs={12} md={6} sx={{paddingTop:"100px"}}>
                  <Paper elevation={3} sx={designPickerStyles}>
                    <Typography variant="h3" gutterBottom sx={titleStyles}>
                      Pick a New Design
                    </Typography>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <TextField
                        fullWidth
                        value={designName}
                        onChange={(e) => setDesignName(e.target.value)}
                        label="Design Name"
                        variant="outlined"
                        size="small"
                      />
                    </Box>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <CustomFileInput onChange={setSelectedFiles} />
                    </Box>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "2rem" }}>
                      {selectedFiles.map((file, index) => (
                        <Box key={index} sx={imagePreviewStyles}>
                          <img
                            src={URL.createObjectURL(file)}
                            alt={`Design ${index + 1}`}
                            style={imageStyles}
                          />
                          <Button
                            variant="outlined"
                            onClick={handleReplaceImage(index)}
                            startIcon={<AddPhotoAlternateIcon />}
                            size="small"
                            sx={replaceButtonStyles}
                          >
                            Replace
                          </Button>
                        </Box>
                      ))}
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      {uploadLoading ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleUpload}
                          sx={uploadButtonStyles}
                        >
                          Upload
                        </Button>
                      )}
                    </Box>
                  </Paper>   
                </Grid>

              {/* Bottom-Right Section "Design Picker" */}

                <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                      <Grid item xs={12} md={12} 
                                 sx={{ paddingTop:"50px",
                                      "@media (max-width: 600px)": {
                                        width: "320px", // Adjust styles for screens with a max width of 600px
                                        left: "-4rem",
                                        position:"relative",
                                        top:"4rem"
                                },
                                     }}> {/* This will span the full width on small screens and align with the right side on larger screens */}
                        <Customer designs={designs}/>
                      </Grid>
                      </Grid>
                </Grid>
            
                {/* Dialog Boxes */}
                
                  <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Design Name is Live</DialogTitle>
                    <DialogContent>
                      Your design with the name "{designName}" is now live. Upload another
                      design.
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleReset} color="primary" autoFocus>
                        Upload another Design
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Dialog
                    open={openPopup}
                    onClose={() => setOpenPopup(false)}
                    maxWidth="sm"
                    fullWidth
                  >
                    <DialogTitle>
                      <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <IconButton
                          edge="end"
                          color="inherit"
                          onClick={() => setOpenPopup(false)}
                          aria-label="close"
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </DialogTitle>
                    <DialogContent>
                      <img
                        src={selectedImage}
                        alt="Selected Design"
                        style={{ width: "100%", height: "auto", borderRadius: "8px" }}
                      />
                    </DialogContent>
                  </Dialog>

                    </Grid>
                    </div>
                </Grid>   
        
      </Grid> 
    </Container>
  );
};

export default DesignPicker;
