import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getcustomercareusernames,
  getNewReturnExchangeOrders,
} from "../../actions/ordersActions";
import AssignREOrders from "../ordersWidgets/AssignREOrders";
import RedirectLogin from "../RedirectLogin";

const AssignRE = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { returnExchangeOrders } = useSelector((state) => state.orderDetails);
  const { customerCareUsers } = useSelector((state) => state.inventoryDetails);
  const loading = useSelector((state) => state.loader.loading);

  useEffect(() => {
    if (returnExchangeOrders.length === 0 && auth)
      dispatch(getNewReturnExchangeOrders());
    if (customerCareUsers.length === 0 && auth)
      dispatch(getcustomercareusernames());
  }, [auth]);

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {loading && (
            <div className="load-overlay">
              <h1>Please Wait...</h1>
            </div>
          )}
          <h1>Assign Orders</h1>

          <div>
            <p>new orders: {returnExchangeOrders.length}</p>
            <AssignREOrders
              orders={returnExchangeOrders}
              usernames={customerCareUsers}
              crm={auth.name === "customercare" ? true : false}
            />
          </div>
        </>
      )}
    </>
  );
};

export default AssignRE;
