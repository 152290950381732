import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField } from "@mui/material";
import {
    getPendingPOProductsData,
    putPendingPOProductsData,
} from "../../actions/POActions";

const AdjustPendingPOProducts = () => {
    const { pendingPOProductsData, loading } = useSelector((state) => state.PO);
    const dispatch = useDispatch();

    const [sku, setSku] = useState(null);
    const [productStock, setProductStock] = useState(null);
    const [sizes, setSizes] = useState(null);

    useEffect(() => {
        if (!pendingPOProductsData) dispatch(getPendingPOProductsData());
    }, [dispatch]);

    const searchSKU = (e) => {
        e.preventDefault();
        const product = pendingPOProductsData.sales_data.filter(
            (p) => p.sku === sku
        );

        if (product.length > 0) {
            setProductStock(product[0]);
            setSizes(Object.keys(product[0].sizes));
        }
    };

    return (
        <div>
            {pendingPOProductsData && !loading ? (
                <div>
                    <form onSubmit={searchSKU}>
                        <TextField
                            placeholder="sku"
                            value={sku}
                            onChange={(e) => {
                                setSku(e.target.value);
                                setSizes(null);
                                setProductStock(null);
                            }}
                        />
                    </form>
                    <br />
                    {sizes?.length > 0 && (
                        <h3>Reset pending quantities by size.</h3>
                    )}
                    <br />
                    <div>
                        {productStock && (
                            <div
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "10px",
                                }}
                            >
                                {sizes?.map((i, k) => (
                                    <div
                                        key={k}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            maxWidth: "90px",
                                        }}
                                    >
                                        {i}:
                                        <TextField
                                            size="small"
                                            value={productStock.sizes[i]}
                                            onChange={(e) =>
                                                setProductStock((prev) => ({
                                                    ...prev,
                                                    sizes: {
                                                        ...prev.sizes,
                                                        [i]: e.target.value,
                                                    },
                                                }))
                                            }
                                            fullWidth
                                        />
                                    </div>
                                ))}
                                <br />
                                <Button
                                    variant="contained"
                                    onClick={() =>
                                        dispatch(
                                            putPendingPOProductsData(
                                                productStock
                                            )
                                        )
                                    }
                                >
                                    Submit
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <h1>Loading...</h1>
            )}
        </div>
    );
};

export default AdjustPendingPOProducts;
