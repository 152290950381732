import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Skeleton,
} from "@mui/material";
import React, { useState } from "react";
import { WinnerTableSkeletonComponent } from "../dashboard/helperComponents";

const CustomerTable = ({ data }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Typography
              variant="h6"
              gutterBottom
              sx={{
                marginBottom: "25px",
                fontWeight: "900",
                fontSize: "1.5rem",
                fontFamily: "Lato",
              }}
            >
              Customer and likes
            </Typography>
      <TableContainer
        component={Paper}
        sx={{
          border: "1px solid #e0e0e0",
          borderRadius: "8px", // Rounded corners
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          marginBottom: "1rem",
          maxWidth: "380px",
          "@media (max-width: 768px)": {
            width:"320px"
          }
         }}
      >
        <Table sx={{ minWidth: "100%",
                    "@media (max-width: 768px)": {
                      maxWidth:"320px"
                    } 
      }}>
          <TableHead sx={{ backgroundColor: "#3c4040" }}>
            <TableRow>
              <TableCell sx={{ padding: 1.5 ,color:"white"}}>Customer Id</TableCell>
              <TableCell sx={{ padding: 1.5 ,color:"white"}}>Number of Likes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ? (
              <WinnerTableSkeletonComponent/>
            ) : (
            data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((d, i) => (
                <TableRow
                  key={i}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "& td": { padding: 1.5 },
                  }}
                >
                  <TableCell>{d.id}</TableCell>
                  <TableCell>{d.value}</TableCell>
                </TableRow>
              )))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export default CustomerTable;
