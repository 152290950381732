import { useEffect, useState } from "react";
import { Box, InputAdornment, Paper, Stack, TextField } from "@mui/material";
import { formatCurrency } from "../../../utils/orderTakeoutHelpers";

const Calculator = ({ data }) => {
  const [totalConsumption, setTotalConsumption] = useState("");
  const [warehouseAmt, setWarehouseAmt] = useState("");
  const [toBeSentAmt, setToBeSentAmt] = useState("");
  const [skPoAmt, setSkPoAmt] = useState("");
  const [totalRequired, setTotalRequired] = useState("");
  const [consumptionMissing, setConsumptionMissing] = useState("");

  useEffect(() => {
    // get data
    let missing = 0;
    const lastMonthConsumption = data.reduce((acc, d) => {
      const material = d.material;
      if (material.length > 0) {
        const singleConsumption = material.reduce((mAcc, m) => {
          if (m.materialFormula === "IR" && m.materialWeight) {
            mAcc =
              mAcc +
              parseFloat(
                (parseFloat(d.total) * parseFloat(m.materialWeight)) / 20
              );
          }
          //in meters
          else if (m.materialFormula === "IM" && m.materialWeight) {
            mAcc =
              mAcc +
              parseFloat(parseFloat(d.total) * parseFloat(m.materialWeight));
          } else {
            missing = missing + 1;
            return mAcc;
          }
          return mAcc;
        }, 0);
        acc = acc + singleConsumption;
      } else {
        missing = missing + 1;
      }
      return acc;
    }, 0);
    const warehouse = isNaN(parseFloat(warehouseAmt)) ? 0 : warehouseAmt;
    const toBeSent = isNaN(parseFloat(toBeSentAmt)) ? 0 : toBeSentAmt;
    const skPo = isNaN(parseFloat(skPoAmt)) ? 0 : skPoAmt;
    const required =
      lastMonthConsumption -
      parseFloat(warehouse) +
      parseFloat(toBeSent) -
      parseFloat(skPo);
    setConsumptionMissing(missing);
    setTotalConsumption(formatCurrency(lastMonthConsumption));
    setTotalRequired(formatCurrency(required));
  }, [data, totalConsumption, warehouseAmt, toBeSentAmt, skPoAmt]);

  const changeAmt = (e) => {
    if (isNaN(parseFloat(e.target.value))) {
      return 0;
    } else {
      return parseFloat(e.target.value);
    }
  };
  return (
    <Paper
      sx={{
        py: 2,
        px: 1,
        mb: 1,
      }}
    >
      <Stack
        direction={"row"}
        gap={1}
        sx={{
          alignItems: "center",
        }}
      >
        <TextField
          style={{ width: "200px" }}
          value={totalConsumption}
          //   onChange={(e) => setTotalConsumption(e.target.value)}
          variant="outlined"
          className="select"
          fullWidth
          size="small"
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">
                <p
                  style={{
                    fontWeight: 800,
                    borderRight: "1px solid black",
                    paddingRight: "4px",
                  }}
                >
                  Last Month
                </p>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          style={{ width: "200px" }}
          value={warehouseAmt}
          onChange={(e) => setWarehouseAmt(changeAmt(e))}
          variant="outlined"
          className="select"
          fullWidth
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <p
                  style={{
                    fontWeight: 800,
                    borderRight: "1px solid black",
                    paddingRight: "4px",
                  }}
                >
                  Warehouse
                </p>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          style={{ width: "200px" }}
          value={toBeSentAmt}
          onChange={(e) => setToBeSentAmt(changeAmt(e))}
          variant="outlined"
          className="select"
          fullWidth
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <p
                  style={{
                    fontWeight: 800,
                    borderRight: "1px solid black",
                    paddingRight: "4px",
                  }}
                >
                  To be sent
                </p>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          style={{ width: "200px" }}
          value={skPoAmt}
          onChange={(e) => setSkPoAmt(changeAmt(e))}
          variant="outlined"
          className="select"
          fullWidth
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <p
                  style={{
                    fontWeight: 800,
                    borderRight: "1px solid black",
                    paddingRight: "4px",
                  }}
                >
                  SK PO
                </p>
              </InputAdornment>
            ),
          }}
        />
        <h3>Total Required : {String(totalRequired)}</h3>
      </Stack>
      {consumptionMissing > 0 && (
        <Box my={1}>
          <h3>Consumption missing : {consumptionMissing}</h3>
        </Box>
      )}
    </Paper>
  );
};

export default Calculator;
