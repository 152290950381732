import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

const RedirectLogin = () => {
  const navigate = useNavigate();

  const goToLogin = () => {
    //clear
    localStorage.clear();
    // Navigate to Login page
    navigate("/login");
  };

  return (
    <div className="redirect-login white-card">
      <h1>Please Login to your account</h1>
      <Button variant="outlined" color="error" onClick={goToLogin}>
        Sign In
      </Button>
      <p>Don't have an account. Contact admin.</p>
    </div>
  );
};

export default RedirectLogin;
