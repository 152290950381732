import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllShipRocketOrderDelivered,
  markDeliveredBulk,
  getShiprocketSyncData,
} from "../../actions/ordersActions";
import RedirectLogin from "../RedirectLogin";
import ProgressBar from "../../ProgressBar";
import SyncSRTable from "../ordersWidgets/SyncSRTable";
import { Button } from "@mui/material";

const SyncShiprocketDelivered = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { nonDeliveredOrders, srDeliveredOrders } = useSelector(
    (state) => state.orderDetails
  );
  console.log("SR delivered", srDeliveredOrders);
  const loading = useSelector((state) => state.loader.loading);

  useEffect(() => {
    if (!nonDeliveredOrders.length > 0 && auth)
      dispatch(getShiprocketSyncData());
  }, [dispatch, auth]);

  useEffect(() => {
    if (srDeliveredOrders.length > 0 && nonDeliveredOrders.length > 0) {
      const payload = nonDeliveredOrders.reduce((filteredOrders, o) => {
        const found = srDeliveredOrders.find(
          (i) => i.channel_order_id === String(o.fulfillment_id)
        );

        if (!!found) {
          filteredOrders.push({
            ...o,
            delivery_charges: found.awb_data?.charges,
          });
        }
        return filteredOrders;
      }, []);
      console.log("PAYLOAD", payload);
      dispatch(markDeliveredBulk(payload));
    }
  }, [srDeliveredOrders]);

  const fetchSRDelivered = () => {
    if (srDeliveredOrders.length === 0)
      dispatch(getAllShipRocketOrderDelivered());
  };

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {nonDeliveredOrders.length === 0 ? (
            <ProgressBar />
          ) : (
            <div>
              {loading && (
                <div className="load-overlay">
                  <h1>Please Wait...</h1>
                </div>
              )}

              <Button
                variant="contained"
                color="secondary"
                onClick={fetchSRDelivered}
              >
                Sync Automatically
              </Button>
              {srDeliveredOrders.length > 0 && (
                <p>Total delivered in SR: {srDeliveredOrders.length}</p>
              )}
              <p>Or you can do manually for each</p>
              <SyncSRTable data={nonDeliveredOrders} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SyncShiprocketDelivered;
