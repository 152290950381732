import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDynamoNonDeliveredOrdersData } from "../../actions/ordersActions";
import AwbNotReadyTable from "../ordersWidgets/AwbNotReadyTable";

import RedirectLogin from "../RedirectLogin";

const AwbNotReady = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const orders = useSelector((state) => state.orderDetails.nonDeliveredOrders);

  useEffect(() => {
    if (auth) dispatch(getDynamoNonDeliveredOrdersData());
  }, [dispatch, auth]);

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          <AwbNotReadyTable
            tableData={orders.filter(
              (o) =>
                o.package_status !== "pickup_ready" &&
                o.package_status !== "dispatched" &&
                o.package_status !== "dispatchedRT"
              // && o.processed_at === "8/18/2022",
            )}
          />
        </>
      )}
    </>
  );
};

export default AwbNotReady;
