import React from 'react'
import { useState } from 'react';

import { Typography,Modal,Box,TextField,Button } from '@mui/material';
import { useDispatch } from 'react-redux';

import { updateNdrOrder,showLoading, hideLoading } from '../../../actions/ordersActions';
import { showFullScreenLoader } from '../../../actions/loaderActions';

const NdrCloseModal = ({open, setCloseModal, rowData}) => {
  const [openUp, setOpenUp] = React.useState(open);
  const [ onCloseReason, setCloseReason] = useState("")

  const dispatch = useDispatch()

  const handleClose = () => {
    setCloseReason("")
    setOpenUp(false)
    setCloseModal(false)
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleSubmit = () => {
    dispatch(showFullScreenLoader())
    const formatDate = new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear()

    const dynamoObj = [{
      fulfillment_id : rowData.POID,
      package_status : "sync#NDR",
      executive_id : "1",
      ndr_history : [
        {
          ActionType : "Closed By CC",
          CloseReason : onCloseReason,
          ClosedDate : formatDate,
          Customer_Care : rowData.executive_id
        }
      ]
    }]
    dispatch(updateNdrOrder(dynamoObj))
    handleClose()
  }

  return (
    <div>
        <Modal
            open={openUp}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight="Bold">
            Type Closing Reason
            </Typography>
            <br/>
            <TextField
                    variant="outlined"
                    className="select"
                    value = {onCloseReason}                     
                    onChange = {e => setCloseReason(e.target.value)}
                    style={{width:"400px"}}>
            </TextField>
            <br/>
            <br/>
            <Button disabled={onCloseReason === ""} variant="contained" onClick={handleSubmit}>Submit</Button>
        </Box>
        </Modal>
    </div>
  )
}

export default NdrCloseModal