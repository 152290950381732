import {
    TextField,
    MenuItem,
    Box,
    // FormGroup,
    // FormControlLabel,
    // Checkbox,
    Grid,
    Button,
} from "@mui/material";
import React, { useState } from "react";

const FilterUnfulfilledOrdersData = ({ data, filteredDateData }) => {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [startDateFocus, setStartDateFocus] = useState(false);
    const [endDateFocus, setEndDateFocus] = useState(false);
    const [hasStartDate, setHasStartDate] = useState(false);
    const [hasEndDate, setHasEndDate] = useState(false);

    const handleSubmit = () => {
        if (data.length > 0) {
            const dateFilter = data.filter(
                (x) =>
                    new Date(x.created_at) >= new Date(startDate) &&
                    new Date(x.created_at) < new Date(endDate)
            );
            filteredDateData(dateFilter);
        }
    };

    const handleClear = () => {
        setStartDate("");
        setStartDateFocus(false);
        setHasStartDate(false);
        setEndDate("");
        setEndDateFocus(false);
        setHasEndDate(false);
        filteredDateData([]);
    };

    return (
        <Grid item md={4}>
            <h3>Search By Date Range</h3>
            <Box sx={{ display: "flex", gap: 2 }}>
                <TextField
                    label="Start Date"
                    onFocus={() => setStartDateFocus(true)}
                    onBlur={() => setStartDateFocus(false)}
                    InputProps={{
                        classes: {
                            input: "CustomTextField",
                        },
                    }}
                    inputProps={{ max: endDate }}
                    type={hasStartDate || startDateFocus ? "date" : "text"}
                    size="small"
                    value={startDate}
                    onChange={(e) => {
                        if (e.target.value) {
                            setStartDate(e.target.value);
                            setHasStartDate(true);
                        } else {
                            setHasStartDate(false);
                        }
                    }}
                />
                <TextField
                    label="End Date"
                    onFocus={() => setEndDateFocus(true)}
                    onBlur={() => setEndDateFocus(false)}
                    inputProps={{ min: startDate }}
                    type={hasEndDate || endDateFocus ? "date" : "text"}
                    size="small"
                    value={endDate}
                    onChange={(e) => {
                        if (e.target.value) {
                            setEndDate(e.target.value);
                            setHasEndDate(true);
                        } else {
                            setHasEndDate(false);
                        }
                    }}
                />
                {hasEndDate && hasStartDate ? (
                    <>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={handleSubmit}
                        >
                            search
                        </Button>
                        <Button
                            style={{ backgroundColor: "red" }}
                            variant="contained"
                            size="small"
                            onClick={handleClear}
                        >
                            clear
                        </Button>
                    </>
                ) : (
                    ""
                )}
            </Box>
        </Grid>
    );
};

export default FilterUnfulfilledOrdersData;
