import React from 'react'
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import {
    Box,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Paper,Avatar, Modal
} from "@mui/material";

function Row({ row,index }) {
  
    return (
      <>
        <TableRow
          sx={{
            bgcolor: "rgb(245,245,245)",
  
            "& > *": { borderBottom: "unset" },
          }}
        >
          <TableCell component="th" scope="row">
            <Avatar
                key={index}
                src={row.url}
                style={{height:"80px",width:"80px"}}
                variant='square'
            />
          </TableCell>
          <TableCell>{row.sku.split(".")[0]}</TableCell>
          <TableCell>{row.name}</TableCell>
          <TableCell>{row.variant_title}</TableCell>
          <TableCell>{row.units}</TableCell>
        </TableRow>
      </>
    );
}

const RtoProductViewModal = ({productModal,tableData,setProductModal}) => {
  const [openUp, setOpenUp] = useState(productModal);
  const [data, setData] = useState([])

  const {stock} = useSelector(state => state.inventoryDetails)

  const handleClose = () => {
    setOpenUp(false)
    setProductModal(false)
  }

  useEffect(() => {
    if (stock.length > 0) {
      const mappedOrders = tableData.map((o) => {
        const productsWithImages = o.order_items.map((p) => {
          const found = stock.find((i) => i.SKU === p.sku.split(".")[0]);
          const url =
            found?.imageUrl ||
            "https://media.istockphoto.com/vectors/error-page-or-file-not-found-icon-vector-id924949200";
          return { ...p, url };
        });

        return {
          ...o,
          products: productsWithImages,
        };
      })
      setData(mappedOrders);
    }
  }, [stock, tableData]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    background : "whitesmoke",
    textAlign : "center",
    maxHeight : "80vh",
    overflowY : "scroll",
    maxWidth : "50%"
  };

  return (
    <Modal
        open={openUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box style={style}>
            {data.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow
                        >
                        <TableCell>Image</TableCell>
                        <TableCell>SKU</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Size</TableCell>
                        <TableCell>Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data[0].products.map((row, index) => (
                        <Row key={index} row={row} index={index} />
                        ))}
                    </TableBody>
                    </Table>
                    <br/>
                </TableContainer>

            ) : "Loading"}
        </Box>
    </Modal>
  )
}

export default RtoProductViewModal