import { Button, TextField, Checkbox, MenuItem,
    Table, TableCell, TableBody, TableContainer, TableHead, TableRow, Paper, Modal, Box
} from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { postXBForwardSingleOrder } from '../../actions/ordersActions'
import { addInventoryStock, reduceInventoryStock } from '../../actions/inventoryActions'

import ModeEditIcon from '@mui/icons-material/ModeEdit';

const SingleOrder = ({orderData, inventoryData}) => {
    // console.log("inventory", inventoryData)

  const [orderNo, setOrderNo] = useState("")
  const [ filteredOrder, setFilteredOrder] = useState("")
  const [ customerName, setCustomerName] = useState("")
  const [ address, setAddress] = useState("")
  const [ address2, setAddress2] = useState("")
  const [ city, setCity] = useState("")
  const [ state, setState] = useState("")
  const [ pincode, setPincode] = useState("")
  const [ phone, setPhone] = useState("")
  const [orderItems, setOrderItems] = useState("")
  const [awb, setAwb] = useState("")
  const [physicalWeight,  setPhysicalWeight ] = useState("")
  const [ newStock, setNewStock ] = useState(0)
  const [ availableStockCount, setAvailableStockCount] = useState([])
  const [editStock, setEditStock] = useState(false)
  const [ editableData ,setEditableData] = useState({})

  const dispatch = useDispatch()

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "20%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleSubmit = () => {
    const requiredOrder = orderData.filter(x => x.order_number === parseInt(orderNo))

    console.log("slected order",requiredOrder)
    if(requiredOrder.length>0){

        let refundedOrders = []
        let fulfillableOrderItems = []
        if(requiredOrder[0].refunds.length > 0 ){
            requiredOrder[0].refunds.map(item => item.refund_line_items.map(x => refundedOrders.push(x.line_item_id)))
        }
        if(refundedOrders.length > 0){
            let tempLineItems = []
            requiredOrder[0].line_items.forEach(x => {
                if(refundedOrders.includes(x.id)){
                    return
                }else{
                    tempLineItems.push(x)
                }
            })

            fulfillableOrderItems = tempLineItems.filter(x => x.fulfillment_status !== "fulfilled")
        }else{
            fulfillableOrderItems = requiredOrder[0].line_items.filter(x => x.fulfillment_status !== "fulfilled")
        }

        let availableStock = []
        fulfillableOrderItems.forEach(item => {
            const checkStock = inventoryData.filter(x => x.SKU === item.sku && x.size === item.variant_title && x.count >= item.fulfillable_quantity)
            if(checkStock.length > 0){
                item["available_stock"] = checkStock[0].count
                availableStock.push({sku : item.sku, size : item.variant_title ,count : checkStock[0].count})
            }else{
                item["available_stock"] = 0
                availableStock.push({sku : item.sku, size : item.variant_title ,count : 0})
            }
        })
        console.log(availableStock)
        setAvailableStockCount(availableStock)
        // console.log(fulfillableOrderItems)
        setFilteredOrder(requiredOrder[0])
        setOrderItems(fulfillableOrderItems)
        setCustomerName(`${requiredOrder[0].customer.first_name} ${requiredOrder[0].customer.last_name}`)
        setAddress(requiredOrder[0].billing_address.address1)
        setAddress2(requiredOrder[0].billing_address.address2)
        setCity(requiredOrder[0].billing_address.city)
        setState(requiredOrder[0].billing_address.province)
        setPincode(requiredOrder[0].billing_address.zip)
        setPhone(requiredOrder[0].billing_address.phone.split("")
            .filter((i) => i !== " ")
            .filter((i) => i !== "  ")
            .filter((i) => i !== "-")
            .join("")
            .slice(-10))
    }else{
        console.log("Order Not Found")
        toast.error("Order Not Found")
    }
  }

  const handlePickup = () => {
    const totalQuantity = orderItems.reduce((sum,i) => sum + i.quantity,0)
    const collectibleAmount = orderItems.reduce((sum,i) => sum + parseInt(i.price) - (i.discount_allocations.length>0 ? parseInt(i.discount_allocations[0].amount) : 0), 0)
    const declaredAmount = orderItems.reduce((sum,i) => sum + parseInt(i.price) , 0)

    const pickupOrderType = orderItems.map(x => `${x.sku}.${x.variant_title}(${x.fulfillable_quantity})`).toString()

    const shippingCharges = filteredOrder.total_shipping_price_set.presentment_money.amount

    const newObj = {
        AirWayBillNO : awb,
        BusinessAccountName : "Littlebox",
        OrderNo : `${orderNo}.${(filteredOrder.fulfillments.length > 0 ? filteredOrder.fulfillments.length : 0) + 1}`,
        SubOrderNo : `${orderNo}.${(filteredOrder.fulfillments.length > 0 ? filteredOrder.fulfillments.length : 0) + 1}`,
        OrderType : filteredOrder.gateway === "Cash on Delivery (COD)" ? "COD" : "Prepaid",
        CollectibleAmount : filteredOrder.gateway === "Cash on Delivery (COD)" ? collectibleAmount + parseInt(shippingCharges) : 0,
        DeclaredValue : declaredAmount,
        PickupType : "Warehouse",
        Quantity : totalQuantity,
        ServiceType : "SD",
        DropDetails: { 
            Addresses: [{ 
                Type: "Primary", 
                Name: customerName,
                Address: `${address} ${address2}`, 
                City: city, 
                State: state, 
                PinCode: pincode,
            }], 
            ContactDetails: [{ 
                Type: "Primary",
                PhoneNo: phone
            }],
        },
        PickupDetails : {
            Addresses: [{ 
                Type: "Primary", 
                Name: "LittleBoxIndia", 
                Address: "MISS CLOG PLOT NUMBER 2 NEAR AGARWAL SWEETS BELOW WELLNESS GYM 100 FEET ROAD GHITORNI India", 
                City: "South West Delhi", 
                State: "Delhi", 
                PinCode: "110030",
            }], 
            ContactDetails: [{ 
                Type: "Primary",
                PhoneNo: "8114118119"
            }],
            PickupVendorCode: "CBDGjh20453NVHyhu",
        },
        RTODetails: {
            Addresses : [{
                Address : "MISS CLOG PLOT NUMBER 2 NEAR AGARWAL SWEETS BELOW WELLNESS GYM 100 FEET ROAD GHITORNI India", 
                City: "South West Delhi", 
                EmailID: "", 
                Name: "LittleBoxIndia", 
                PinCode: "110030", 
                State: "Delhi", 
                Type: "Primary" 
            }], 
            ContactDetails: [{
                PhoneNo : "0000000000",
                Type: "Primary" 
            }] 
        },
        PackageDetails : {
            Weight : { 
                BillableWeight : physicalWeight, 
                PhyWeight : physicalWeight, 
                VolWeight : "" 
            } 
        },
        GSTMultiSellerInfo : [{
            IsSellerRegUnderGST: "Yes", 
            SellerAddress: "MISS CLOG PLOT NUMBER 2 NEAR AGARWAL SWEETS BELOW WELLNESS GYM 100 FEET ROAD GHITORNI India", 
            SellerGSTRegNumber: "07JUFPS0628A1ZQ", 
            SellerName: "LittleBoxIndia", 
            SellerPincode: "110030", 
            SupplySellerStatePlace: "Delhi", 
            HSNDetails: [{  
                ProductDesc: pickupOrderType,
            }]   
        }]
    }
    console.log(newObj)
    dispatch(postXBForwardSingleOrder(newObj))
    dispatch(reduceInventoryStock(orderItems.map(x => {
        const newObj = {
            barcode : `LB${x.sku}${x.variant_title}`,
            count : x.fulfillable_quantity
        }
        console.log("reduced Stock",newObj)
        return newObj
    })))
    setOrderNo("")
    setFilteredOrder("")
    setOrderItems("")
    setAwb("")
    setPhysicalWeight("")

    setCustomerName("")
    setAddress("")
    setAddress2("")
    setCity("")
    setState("")
    setPincode("")
    setPhone("")
    setAvailableStockCount([])

  }

  return (
    <div>
        <div style={{display:"flex"}}>
            <TextField
                label="Enter Order Id"
                onChange={(e) => setOrderNo(e.target.value)}
                variant="outlined"
                className="select"
            />
            <Button style={{left:"10px"}} variant='contained' color='secondary' onClick={handleSubmit}>Enter</Button>
        </div>
        <br/>
        <br/>
        {filteredOrder !== "" ? (
            <>
                <div style={{display:"flex"}}>
                    <TextField
                        style={{width:"300px"}}
                        label="Airway Bill Number"
                        value={awb}
                        type="string"
                        onChange={e => setAwb(e.target.value)}
                    />
                    <TextField
                        style={{width:"300px", left:"10px"}}
                        label="Customer Name"
                        value={customerName}
                        type="string"
                        onChange={e => setCustomerName(e.target.value)}
                    />
                </div>
                <br/>
                <TextField
                    style={{width:"1200px"}}
                    label="Billing Address"
                    value={address}
                    type="string"
                    onChange={e => setAddress(e.target.value)}
                    />
                <br/>
                <br/>
                <TextField
                    style={{width:"900px"}}
                    label="Billing Address 2"
                    value={address2}
                    type="string"
                    onChange={e => setAddress2(e.target.value)}
                />
                <br/>
                <br/>
                <div style={{display:"flex"}}>
                    <TextField
                        style={{width:"300px"}}
                        label="City"
                        value={city}
                        type="string"
                        onChange={e => setCity(e.target.value)}
                    />
                    <TextField
                        style={{width:"300px", left:"50px"}}
                        label="State"
                        value={state}
                        type="string"
                        onChange={e => setState(e.target.value)}
                    />
                    <TextField
                        style={{width:"300px", left:"100px"}}
                        label="Pincode"
                        value={pincode}
                        type="string"
                        onChange={e => setPincode(e.target.value)}
                    />
                </div>
                <br/>
                <TextField
                    style={{width:"500px"}}
                    label="Phone"
                    value={phone}
                    type="string"
                    onChange={e => setPhone(e.target.value)}
                />
                <br/>
                <br/>
                <TextField
                    style={{width:"200px"}}
                    label="Enter Physical Weight"
                    value={physicalWeight}
                    type="string"
                    onChange={e => setPhysicalWeight(e.target.value)}
                />
                <h3>Fulfillable Order Items</h3>
                <TableContainer style={{width:"1000px"}} component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">SKU</TableCell>
                            <TableCell align="right">Size</TableCell>
                            <TableCell align="right">Quantity</TableCell>
                            <TableCell align="right">Available Stock</TableCell>
                            <TableCell align="center">Edit Available Stock</TableCell>
                            <TableCell align="right">Price</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {orderItems.map((row, index) => (
                            <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row">
                                {row.title}
                            </TableCell>
                            <TableCell align="right">{row.sku}</TableCell>
                            <TableCell align='right'>{row.variant_title}</TableCell>
                            <TableCell align="right">{row.fulfillable_quantity}</TableCell>
                            <TableCell align="right">{row.available_stock}</TableCell>
                            <TableCell style={{display:"flex", flexDirection:"column", alignItems:"center"}} align="right">
                                {!editStock ? (
                                        <ModeEditIcon onClick={() => {
                                            setEditStock(true)
                                            setEditableData(row)
                                            console.log(row)
                                        }}/>
                                    ) : (
                                        <Modal
                                            open={editStock}
                                            onClose={() => {
                                                setEditStock(false);
                                                setNewStock("")
                                                setEditableData({})
                                            }}
                                            aria-labelledby="modal-modal-title"
                                            aria-describedby="modal-modal-description"
                                        >
                                            <Box sx={style}>
                                            <h2>Product Name : {editableData.title}</h2>
                                            <h2>SKU : {editableData.sku}</h2>
                                            <h3>Total Quantity : {editableData.fulfillable_quantity} </h3>
                                            <h3>Available Stock : {editableData.available_stock}</h3>
                                            <h3>Add New Stock Amount :</h3>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                            <TextField
                                                style={{width:"105px", paddingBottom:"5px"}}
                                                value={newStock}
                                                type="number"
                                                onChange={e => setNewStock(e.target.value)}
                                            />
                                            <Button style={{left:"10px"}} variant='contained' color='error' onClick={() => {
                                                    dispatch(addInventoryStock([{barcode : `LB${editableData.sku}${editableData.variant_title}`, count : parseInt(newStock)}]))
                                                    let updateStockInOrderItems = orderItems.filter(x => x.sku === editableData.sku && x.variant_title === editableData.variant_title)
                                                    if(updateStockInOrderItems){
                                                        updateStockInOrderItems[0].available_stock = updateStockInOrderItems[0].available_stock + parseInt(newStock)
                                                    }
                                                    //update available stock usestate
                                                    const updateAvailableStockCount = availableStockCount.filter(x => x.sku === editableData.sku && x.size === editableData.variant_title )
                                                    if(updateAvailableStockCount){
                                                        updateAvailableStockCount[0].count = parseInt(newStock)
                                                    }
                                                    setNewStock("")
                                                    setEditStock(false)
                                                    setEditableData({})
                                                    }}>
                                                Add Stock
                                            </Button>
                                            </div>
                                            </Box>
                                        </Modal>
                                    )}
                            </TableCell>
                            <TableCell align="right">{parseInt(row.price) - (row.discount_allocations.length>0 ? parseInt(row.discount_allocations[0].amount) : 0)}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
               
                <br/>
                <Button 
                    variant='contained' 
                    color='error'
                    disabled =  {availableStockCount.map(x => x.count).includes(0)}
                    onClick={handlePickup}
                >Generate PickUp
                </Button>

            </>
        ) : ""}
    </div>
  )
}

export default SingleOrder