import { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { materialVendors } from "../../../utils/helperArrays";
import { getMaterialList } from "../../../actions/materialActions";
import MaterialTable from "./MaterialTable";

const MaterialList = () => {
  const [fabric, setFabric] = useState("");
  const [materialData, setMaterialData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("");
  const { materialFabricList, materialColorList } = useSelector(
    (state) => state.inventoryDetails
  );

  const handleSearch = async () => {
    setLoading(true);
    const data = await getMaterialList(fabric);
    if (data.statusCode === 200) {
      console.log("material", data.body);
      setMaterialData(data.body);
    }
    setLoading(false);
  };

  return (
    <div>
      <h2
        style={{
          textAlign: "center",
        }}
      >
        Material Table
      </h2>
      {/* search */}
      <Stack my={2} direction={"row"} gap={2} sx={{ marginBottom: 3 }}>
        <TextField
          style={{ width: "300px" }}
          select
          label="Select Fabric"
          value={fabric}
          onChange={(e) => setFabric(e.target.value)}
          variant="outlined"
          className="select"
          // fullWidth
        >
          {materialFabricList.map((option) => (
            <MenuItem key={option.code} value={option.code}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button disabled={!fabric} variant="contained" onClick={handleSearch}>
            Search
          </Button>
        )}
      </Stack>
      {/* table data */}
      {materialData.length > 0 && <MaterialTable data={materialData} />}
    </div>
  );
};

export default MaterialList;
