const initialState = {
  loading: false,
  products: [],
  pendingPOProductsData: null,
  POList: [],
  stockOutwardList: [],
  processedQntyList: [],
};

const POReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_PO_LOADER":
      return { ...state, loading: true };
    case "HIDE_PO_LOADER":
      return { ...state, loading: false };
    case "SELECT_PRODUCTS":
      return { ...state, products: action.products };
    case "PENDING_PO_PRODUCTS_DATA":
      return {
        ...state,
        pendingPOProductsData: action.payload,
      };
    case "SHOW_PO":
      return {
        ...state,
        POList: action.payload,
      };
    case "SHOW_STOCK_OUTWARD":
      return {
        ...state,
        stockOutwardList: action.payload,
      };
    case "SHOW_PROCESSED_QNTY":
      return {
        ...state,
        processedQntyList: action.payload,
      };
    case "UPDATE_PROCESSED_QNTY":
      return {
        ...state,
        processedQntyList:
          state.processedQntyList.findIndex(
            (ac) => ac.barcode === action.payload.barcode
          ) !== -1
            ? state.processedQntyList.map((p) => {
                const body = action.payload;
                if (p.barcode === body.barcode) {
                  return body;
                } else {
                  return p;
                }
              })
            : [...state.processedQntyList, action.payload],
      };

    default:
      return state;
  }
};
export default POReducer;
