import { useState } from "react";
import UpdateStockModal from "./UpdateStockModal";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Button,
} from "@mui/material";

export default function UpdateProductSizeTable({ data, productSizes }) {
  const [modalOpen, setModalOpen] = useState(false)
  const [ selectedRowData ,setSelectedRowData] = useState({})

  return (
    <>
      <TableContainer sx={{ width: "100%" }}>
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell> Image </TableCell>
              <TableCell> SKU </TableCell>
              <TableCell> Cost Price </TableCell>
              {productSizes.map((item) => (
                <TableCell key={item}> {item} </TableCell>
              ))}
              <TableCell> Total </TableCell>
              <TableCell>Edit Stock</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow
                key={row.SKU}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Avatar
                    src={row.imageUrl}
                    alt="https://www.elegantthemes.com/blog/wp-content/uploads/2018/02/502-error.png"
                    sx={{
                      width: 100,
                      height: 100,
                      objectFit: "contain",
                    }}
                    variant="square"
                  />
                </TableCell>
                <TableCell>{row.SKU}</TableCell>
                <TableCell>
                  {row.costPrice}{" "}
                  {/* <Button
                    variant="contained"
                    size="small"
                    sx={{ marginLeft: 5 }}
                    onClick={() => {
                      // setSelectedInventoryData(row);
                      // setInventoryCostPriceModal(true);
                    }}
                  >
                    Edit
                  </Button> */}
                </TableCell>
                {productSizes.map((i) => (
                  <TableCell key={i}>{row[i]}</TableCell>
                ))}
                <TableCell>{row.total}</TableCell>
                <TableCell>
                  {
                    <Button
                      onClick={() => {
                        // setUpdateStockModalOpen(true)
                        setModalOpen(true)
                        setSelectedRowData(row);
                        // console.log(row)
                        // // setInventoryDataModal(true);
                        // <UpdateProductModal data={row}/>
                      }}
                      variant="contained"
                      size="small"
                    >
                      Edit Stock
                    </Button>
                  }
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Modal
        open={updateStockModalOpen}
        // onClose={() => {
        //   setUpdateSizeModalOpen(false);
        //   setInventoryStock([]);
        // }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <UpdateProductModal data={data} />
      </Modal> */}
      {modalOpen ? <UpdateStockModal data={selectedRowData} setModalOpen={setModalOpen} setSelectedRowData={setSelectedRowData}/> : ""}
    </>
  );
}
