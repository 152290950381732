const ProductsList = ({products}) => {
  
  return (
    <div>
      <br/>
      <br/>
      <h3>All Products</h3>
      {products.map((p, i) => <p key={i}>{p.id}</p>)}
    </div>
  );
};

export default ProductsList;
