import React from "react";
import PropTypes from "prop-types";
import { TableSkeletonComponent } from "../dashboard/helperComponents";
import ReverseActionTaken from "./ReverseActionTaken";
import { Box, Tab, Tabs } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RPcancelFilter = ({ tableData }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <div>
      {tableData.length > 0 && (
        <ReverseActionTaken
          tableData={tableData
            .sort((a, b) => new Date(a.sync_date) - new Date(b.sync_date))
            .reverse()}
        />
      )}
    </div>
  );
};

export default RPcancelFilter;
