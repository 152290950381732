import React from "react";

const ProgressBarWidget = ({ percent }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "20px",
        outline: "#f0f0f0 solid 4px",
        backgroundColor: "#f0f0f0",
        borderRadius: "5px",
        marginBottom: "20px",
      }}
    >
      <div
        style={{
          width: `${percent}%`,
          height: "20px",
          backgroundColor: "#2196F3",
          borderRadius: "5px",
          transition: "1s ease-in-out",
        }}
      ></div>
    </div>
  );
};

export default ProgressBarWidget;
