import { toast } from "react-toastify";
import { lambdaFetch } from "../utils/awsUtils";
import { ClientId } from "../utils/UserPool";
import { showModal, showUnauthorizedModal } from "./errorModalActions";

const lastAuthUser = localStorage.getItem(
  "CognitoIdentityServiceProvider." + ClientId + ".LastAuthUser"
);
const tokenName =
  "CognitoIdentityServiceProvider." +
  ClientId +
  "." +
  lastAuthUser +
  ".idToken";
const IDtoken = localStorage.getItem(tokenName);

export const showLoading = () => {
  return {
    type: "SHOW_LOADER",
  };
};

export const hideLoading = () => {
  return {
    type: "HIDE_LOADER",
  };
};

export const productUploadSuccess = (data) => {
  return {
    type: "UPLOADED_PRODUCT",
    data,
  };
};

export const shopifyProductUploadSuccess = (data) => {
  return {
    type: "SHOPIFY_PRODUCT",
    data,
  };
};

const productImageList = (data) => {
  return {
    type: "IMAGE_LIST",
    data,
  };
};

const productInventoryList = (data) => {
  return {
    type: "PRODUCT_VARIANTS",
    data,
  };
};

export const allProducts = () => {
  console.log("I am getting called");
  const getAllProducts = async (IDtoken) => {
    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/shopifyproducts/products";
    let fetching = true;
    let products = [];
    let nextUrl = null;
    while (fetching) {
      const response = await lambdaFetch(URL, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${IDtoken}`,
        },
        body: JSON.stringify({ currentUrl: nextUrl, pageLength: 250 }),
      });
      if (response.status === 403) {
        fetching = false;
        return 403;
      }
      const data = await response.json();
      if (data.statusCode === 200) {
        products.push(...data.body);
        nextUrl = data.next;
        if (!nextUrl) {
          fetching = false;
        }
      } else {
        products = [];
        fetching = false;
      }
    }
    return products;
  };
  return (dispatch) => {
    getAllProducts(IDtoken)
      .then((data) => {
        if (data === 403) {
          dispatch(showUnauthorizedModal());
          return;
        }
        if (data.length > 0) {
          // console.log(data)
          dispatch({
            type: "ALL_PRODUCTS",
            data,
          });
          const imagesData = data
            .map((p) => ({ image: p.image, type: p.product_type }))
            .filter((item) => item.image !== null)
            .map((item) => ({
              id: item.image.product_id,
              src: item.image.src,
              type: item.type,
            }));

          const variantArr = data
            .filter((x) => x.status !== "draft")
            .reduce((arr, i) => [...arr, ...i.variants], []);
          console.log("variant  arr", variantArr);
          dispatch(productImageList(imagesData));
          dispatch(productInventoryList(variantArr));
        } else {
          // dispatch(showModal());
          toast.error("Products Not Found");
        }
      })
      .catch((error) => {
        // dispatch(showModal());
        toast.error(
          "Something Went Wrong With Fetching All Products From Shopify",
          {
            theme: "colored",
          }
        );
      });
  };
};

export const postNewProducts = (products) => {
  const addNewProducts = async (IDtoken, products) => {
    const PutProductURL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/shopifyproducts/writetoproducts";

    const response = await lambdaFetch(PutProductURL, {
      method: "POST",

      body: JSON.stringify(products),
    });
    if (response.status === 403) {
      return 403;
    }
    return await response.json();
  };

  return (dispatch) => {
    addNewProducts(IDtoken, products)
      .then((data) => {
        if (data === 403) {
          dispatch(showUnauthorizedModal());
          return;
        }
        if (data.statusCode === 200) {
          dispatch(productUploadSuccess(data));
          toast.success("Products Added Successfully");
        } else {
          dispatch(showModal());
          toast.error("Error in Saving Products");
        }
      })
      .catch((error) => {
        dispatch(showModal());
        toast.error(
          "Something Went Wrong With Saving Products To Shopify or Inventory",
          {
            theme: "colored",
          }
        );
      });
  };
};

export const removeS3Images = () => {
  return (dispatch) => {
    dispatch({
      type: "REMOVE_S3_IMAGES",
    });
  };
};

export const removeResponseBody = () => {
  return (dispatch) => {
    dispatch({
      type: "REMOVE_RESPONSE_BODY",
    });
  };
};

export const allSKUIds = () => {
  const getAllSKU = async (IDtoken) => {
    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/shopifyproducts/getallskuids";

    const response = await lambdaFetch(URL, {
      method: "POST",
    });
    if (response.status === 403) {
      return 403;
    }
    return await response.json();
  };
  return (dispatch) => {
    getAllSKU(IDtoken)
      .then((data) => {
        if (data === 403) {
          dispatch(showUnauthorizedModal());
          return;
        }
        if (data.statusCode === 200) {
          dispatch({
            type: "ALL_SKU",
            data,
          });
        } else {
          dispatch(hideLoading());
          dispatch(showModal());
          toast.error("Error in Loading SKU Ids");
        }
      })
      .catch((error) => {
        dispatch(hideLoading());
        dispatch(showModal());
        toast.error("Something Went Wrong With Connecting To Server", {
          theme: "colored",
        });
      });
  };
};

export const updateLastSkuId = (skuBody) => {
  const addSKU = async () => {
    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/shopifyproducts/changelastskuidshopify";

    const response = await lambdaFetch(URL, {
      method: "POST",

      body: JSON.stringify(skuBody),
    });
    if (response.status === 403) {
      return 403;
    }
    return await response.json();
  };
  return (dispatch) => {
    addSKU()
      .then((data) => {
        if (data === 403) {
          dispatch(showUnauthorizedModal());
          return;
        }
        if (data.statusCode === 200) {
          console.log(data);
          dispatch({
            type: "NEW_SKU_ID",
            data,
          });
          toast.success("SKU Last Id Updated Successfully");
        } else {
          dispatch(hideLoading());
          dispatch(showModal());
          toast.error("Error in Updating New SKU Id");
        }
      })
      .catch((error) => {
        dispatch(hideLoading());
        dispatch(showModal());
        toast.error("Something Went Wrong With Connecting To Server", {
          theme: "colored",
        });
      });
  };
};

export const uploadImagesToS3 = (imageBody) => {
  const updateProductImage = async (IDtoken, imageBody) => {
    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/shopifyproducts/uploadshopifyimagestos3";

    const response = await lambdaFetch(URL, {
      method: "POST",

      body: JSON.stringify(imageBody),
    });
    if (response.status === 403) {
      return 403;
    }
    return await response.json();
  };
  return (dispatch) => {
    updateProductImage(IDtoken, imageBody)
      .then((data) => {
        if (data === 403) {
          dispatch(showUnauthorizedModal());
          return;
        }
        if (data.statusCode === 200) {
          console.log(data);
          dispatch({
            type: "SKU_IMAGES",
            data,
          });
          toast.success("Successfully Saved SKU Images");
        } else {
          dispatch(hideLoading());
          dispatch(showModal());
          toast.error("Error in saving SKU Images To S3");
        }
      })
      .catch((error) => {
        dispatch(hideLoading());
        dispatch(showModal());
        toast.error("Something Went Wrong With Saving Images To S3", {
          theme: "colored",
        });
      });
  };
};

export const addProductToShopify = (product) => {
  const updateProductImage = async () => {
    const URL =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/shopifyproducts/shopifyaddproducts";

    const response = await lambdaFetch(URL, {
      method: "POST",

      body: JSON.stringify(product),
    });
    if (response.status === 403) {
      return 403;
    }
    return await response.json();
  };
  return (dispatch) => {
    updateProductImage()
      .then((data) => {
        if (data === 403) {
          dispatch(showUnauthorizedModal());
          return;
        }
        if (data.statusCode === 200) {
          console.log(data.body.product);
          dispatch(shopifyProductUploadSuccess(data));
          toast.success("Successfully Saved Product To Shopify");
        } else {
          dispatch(showModal());
          toast.error("Error in Saving Product To Shopify");
        }
      })
      .catch((error) => {
        dispatch(showModal());
        toast.error("Something Went Wrong With Saving Product To Shopify", {
          theme: "colored",
        });
      });
  };
};

export const changeStatusToDraft = async (id) => {
  let res;
  try {
    const response = await lambdaFetch(
      `https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/shopifyproducts/changestatus`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          id,
          status: "draft",
        }),
      }
    );
    const data = await response.json();

    if (data.statusCode === 200) {
      toast.success("Status updated successfully");
      res = {
        status: 200,
      };
    } else {
      res = {
        status: 500,
      };
      toast.error("Status not updated");
      console.log("error", data);
    }
  } catch (err) {
    res = { status: 500 };
    toast.error("Status not updated");
    console.log("err", err);
  }

  return res;
};

export const changeStatusToActive = async (id) => {
  let res;
  try {
    const response = await lambdaFetch(
      `https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/shopifyproducts/changestatus`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          id,
          status: "active",
        }),
      }
    );
    console.log({
      id,
      status: "active",
    });
    const data = await response.json();

    if (data.statusCode === 200) {
      toast.success("Status updated successfully");
      res = {
        status: 200,
      };
    } else {
      res = {
        status: 500,
      };
      toast.error("Status not updated");
      console.log("error", data);
    }
  } catch (err) {
    res = { status: 500 };
    toast.error("Status not updated");
    console.log("err", err);
  }

  return res;
};

export const changeAvailableQuantity = async (list) => {
  let res;
  try {
    console.log("payload", {
      list,
    });
    const response = await lambdaFetch(
      `https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/shopifyproducts/changeavailable`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          list,
        }),
      }
    );
    const data = await response.json();

    if (data.statusCode === 200) {
      toast.success("Quantity updated successfully");
      res = {
        status: 200,
      };
    } else {
      res = {
        status: 500,
      };
      toast.error("Quantiry not updated");
      console.log("error", data);
    }
  } catch (err) {
    res = { status: 500 };
    toast.error("Quantity not updated");
    console.log("err", err);
  }

  return res;
};

export const changeProductsAfterStatusChange =
  (id, status) => async (dispatch, getState) => {
    const state = getState();
    const products = state.productDetails.products;
    const productVariants = state.productDetails.productVariants;

    const data = products.map((p) => {
      if (p.id === id) {
        p["status"] = status;
      }
      return p;
    });
    const variantArr = productVariants.filter((pv) => pv.product_id !== id);
    dispatch({
      type: "ALL_PRODUCTS",
      data,
    });
    dispatch(productInventoryList(variantArr));
  };

export const changeProductsAfterAvailableQntyChange =
  (list) => async (dispatch, getState) => {
    const state = getState();
    const shopifyInventory = state.orderDetails.shopifyInventory;
    const data = shopifyInventory.map((row) => {
      const found = list.find((l) => l.id === row.inventory_item_id);
      if (found) {
        row["available"] = found.available;
      }
      return row;
    });
    dispatch({
      type: "ALL_SHOPIFY_INVENTORY",
      data,
    });
  };

export const getProductStockHistory = async (sku) => {
  let res;
  try {
    const response = await lambdaFetch(
      `https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/inventory/stockhistory`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          sku,
        }),
      }
    );
    const data = await response.json();

    if (data.statusCode === 200) {
      res = {
        status: 200,
        body: data.body,
      };
    } else {
      res = {
        status: 500,
      };
    }
  } catch (err) {
    res = { status: 500 };
  }

  return res;
};
