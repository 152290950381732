import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UnfulfilledProductsWithImage from "./UnfulfilledProductsWithImage.js";
import { createUnfulfilledProductsTableData } from "../../utils/helpers";
import { TextField, MenuItem } from "@mui/material";
import { tagsArrayNew } from "../../utils/helperArrays.js";

const UnfulfilledOrdersComponents = () => {
    const { filtered3MProductsData } = useSelector(
        (state) => state.orderDetails
    );
    const [orderedProducts, setOrderedProducts] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [payment, setPayment] = useState("");
    const [tag, setTag] = useState("");
    const [subTitle, setSubTitle] = useState(
        filtered3MProductsData
            ? `Last 90 days analytics of unfulfilled products. Last synced on ${filtered3MProductsData.updated_at}`
            : ""
    );

    useEffect(() => {
        if (filtered3MProductsData) {
            setSubTitle(
                `Last 90 days analytics of unfulfilled products. Last synced on ${filtered3MProductsData.updated_at}`
            );
            const formatted = createUnfulfilledProductsTableData(
                filtered3MProductsData.all
            );
            setOrderedProducts(formatted);
        }
    }, [filtered3MProductsData]);

    useEffect(() => {
        let orders = filtered3MProductsData.all;

        if (tag === "None") {
            orders = filtered3MProductsData.none;
        } else if (tag === "Shopify") {
            orders = filtered3MProductsData.shopify;
        } else if (tag === "Zaamo") {
            orders = filtered3MProductsData.zaamo;
        } else if (tag === "Wishlink") {
            orders = filtered3MProductsData.wishlink;
        } else if (tag === "GoAffPro") {
            orders = filtered3MProductsData.goaffpro;
        } else {
            orders = filtered3MProductsData.all;
        }

        if (payment !== "") {
            if (payment === "C.O.D") {
                // const filtered = orders.filter(
                //     (o) => o.gateway === "Cash on Delivery (COD)"
                // );
                orders = filtered3MProductsData.all;
            }
            if (payment === "Prepaid") {
                // const filtered = orders.filter(
                //     (o) => o.gateway !== "Cash on Delivery (COD)"
                // );
                orders = filtered3MProductsData.all;
            }
        }
        setFilteredOrders(orders);
    }, [filtered3MProductsData, tag, payment]);

    useEffect(() => {
        const formatted = createUnfulfilledProductsTableData(filteredOrders);
        setOrderedProducts(formatted);
    }, [filteredOrders]);

    return (
        <div>
            <h4>{subTitle}</h4>
            <br />
            <TextField
                style={{ width: "500px" }}
                select
                label="Select A Tag"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
                variant="outlined"
                className="select"
            >
                {tagsArrayNew.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
            {/* <TextField
                style={{ width: "500px", marginLeft: "10px" }}
                select
                label="Select Payment Method"
                value={payment}
                onChange={(e) => setPayment(e.target.value)}
                variant="outlined"
                className="select"
            >
                {["None", "C.O.D", "Prepaid"].map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField> */}
            <UnfulfilledProductsWithImage orderedProducts={orderedProducts} />
        </div>
    );
};

export default UnfulfilledOrdersComponents;
