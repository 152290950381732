const initialState = {
  orders: [],
  allUnfulfilled: [],
  unfulfilled: [],
  responseBody: {},
  loading: false,
  fulfillmentLoading: false,
  ndrOrders: [],
  processingOrders: [],
  deliveredOrders: [],
  inTransitOrders: [],
  nonDeliveredOrders: [],
  srDeliveredOrders: [],
  shopifyInventory: [],
  returnExchangeOrders: [],
  rejectedREOrders: [],
  dispatchedREOrders: [],
  synceddispatchREOrders: [],
  deliveredREOrders: [],
  nprREOrders: [],
  allREOrders: [],
  xbNdrOrders: [],
  reverseAwbNo: "",
  xbNdrOnholdOrders: [],
  customerDetail: [],
  xbShipmentHistory: [],
  xbReverseShipmentHistory: [],
  delhiveryShipmentHistory: [],
  pincodes: [],
  pincodesDelhivery: { prepaid: [], cod: [] },
  invalidEmail: [],
  unsuccessfulEmail: [],
  complaintEmail: [],
  xbDeliveredRtoOrders: [],
  xbDispatchedRtoOrders: [],
  processedOrders: [],
  orderTakeout: [],
  perDaySalesData: null,
  weeklySalesData: null,
  tridiurnalSalesData: null,
  threeMonthsSalesData: null,
  threeMonthsUnfulfilledProductsData: null,
  filtered3MProductsData: null,
  returningProductsData: null,
  stateWiseSalesData: null,
  repeatingProductsData: null,
  unreliableOrders: null,
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_ORDERS":
      return {
        ...state,
        orders: action.data,
      };
    case "UNFULFILLED_ORDERS":
      return {
        ...state,
        unfulfilled: action.data,
      };
    case "CLEAR_UNFULFILLED":
      return {
        ...state,
        unfulfilled: [],
      };
    case "ALL_UNFULFILLED_ORDERS":
      return {
        ...state,
        allUnfulfilled: action.data,
      };
    case "REMOVE_UNFULFILLED_ORDERS":
      return {
        ...state,
        allUnfulfilled: [],
      };
    case "ALL_NDR":
      return {
        ...state,
        ndrOrders: action.ndrOrders,
      };
    case "PROCESSING_ORDERS":
      return {
        ...state,
        processingOrders: action.data,
      };
    case "DELIVERED_ORDERS":
      return {
        ...state,
        deliveredOrders: action.data,
      };
    case "INTRANSIT_ORDERS":
      return {
        ...state,
        inTransitOrders: action.data,
      };
    case "NON_DELIVERED_ORDERS":
      return {
        ...state,
        nonDeliveredOrders: action.data.map((o) => ({
          ...o,
          sr_status: null,
        })),
      };
    case "CLEAR_NON_DELIVERED_ORDERS":
      return {
        ...state,
        nonDeliveredOrders: [],
      };
    case "UPDATE_NON_DELIVERED_ORDERS":
      return {
        ...state,
        nonDeliveredOrders: state.nonDeliveredOrders.map((o) => {
          if (o.fulfillment_id === action.payload.channel_order_id) {
            return {
              ...o,
              delivery_charges: action.payload.awb_data.charges,
              sr_status: action.payload.status,
            };
          } else {
            return o;
          }
        }),
      };
    case "SR_DELIVERED_ORDERS":
      return {
        ...state,
        srDeliveredOrders: action.payload,
      };
    case "CLEAR_SR_DELIVERED_ORDERS":
      return {
        ...state,
        srDeliveredOrders: [],
      };
    case "UPDATE_DYNAMO_SINGLE":
      return {
        ...state,
        processingOrders: state.processingOrders.filter(
          (o) => o.fulfillment_id !== action.payload
          // ? action.payload
          // : o
        ),
        orderTakeout: state.orderTakeout.filter(
          (o) => o.fulfillment_id !== action.payload.fulfillment_id
        ),
      };
    case "DELETE_DYNAMO_SINGLE":
      return {
        ...state,
        processingOrders: state.processingOrders.filter(
          (o) => o.fulfillment_id !== action.payload.fulfillment_id
        ),
        orderTakeout: state.orderTakeout.filter(
          (o) => o.fulfillment_id !== action.payload.fulfillment_id
        ),
      };
    case "DELETE_NON_DELIVERED_SINGLE":
      return {
        ...state,
        nonDeliveredOrders: state.nonDeliveredOrders.filter(
          (o) => o.fulfillment_id !== action.payload.fulfillment_id
        ),
        orderTakeout: state.orderTakeout.filter(
          (o) => o.fulfillment_id !== action.payload.fulfillment_id
        ),
      };
    case "SHOW_LOADER":
      return {
        ...state,
        loading: true,
      };
    case "HIDE_LOADER":
      return {
        ...state,
        loading: false,
      };
    case "SHOW_FULLFILLMENT_LOADER":
      return {
        ...state,
        fulfillmentLoading: true,
      };
    case "HIDE_FULLFILLMENT_LOADER":
      return {
        ...state,
        fulfillmentLoading: false,
      };
    case "ALL_SHOPIFY_INVENTORY":
      return {
        ...state,
        shopifyInventory: action.data,
      };
    case "RETURN_EXCHANGE_ORDERS":
      return {
        ...state,
        returnExchangeOrders: action.payload,
      };
    case "REJECTED_RE_ORDERS":
      return {
        ...state,
        rejectedREOrders: action.payload,
      };
    case "DISPATCHED_RE_ORDERS":
      return {
        ...state,
        dispatchedREOrders: action.payload,
      };
    case "SYNCED_DISPATCHED_RE_ORDERS":
      return {
        ...state,
        synceddispatchREOrders: action.payload,
      };
    case "DELIVERED_RE_ORDERS":
      return {
        ...state,
        deliveredREOrders: action.payload,
      };
    case "NPR_RE_ORDERS":
      return {
        ...state,
        nprREOrders: action.payload,
      };
    case "ALL_RE_ORDERS":
      return {
        ...state,
        allREOrders: action.payload,
      };
    case "XB_NDR_ORDERS":
      return {
        ...state,
        xbNdrOrders: action.data,
      };
    case "CLEAR_NDR_ORDERS":
      return {
        ...state,
        xbNdrOrders: [],
      };
    case "REVERSE_AWB_NO":
      return {
        ...state,
        reverseAwbNo: action.payload,
      };
    case "UPDATE_ASSIGN_NDR":
      return {
        ...state,
        xbNdrOrders: state.xbNdrOrders.map((x) => {
          if (x.fulfillment_id === parseFloat(action.payload.fulfillment_id)) {
            return {
              ...x,
              executive_id: action.payload.executive_id,
            };
          } else {
            return x;
          }
        }),
      };
    case "UPDATE_XB_NDR_ORDERS":
      return {
        ...state,
        xbNdrOrders: state.xbNdrOrders.filter(
          (x) => !action.payload.includes(parseFloat(x.fulfillment_id))
        ),
      };
    case "XB_NDR_ONHOLD_ORDERS":
      return {
        ...state,
        xbNdrOnholdOrders: action.data,
      };
    case "SHOPIFY_CUSTOMER_DATA":
      return {
        ...state,
        customerDetail: action.payload,
      };
    case "REMOVE_CUSTOMER_DETAILS":
      return {
        ...state,
        customerDetail: [],
      };
    case "XB_ORDER_HISTORY":
      return {
        ...state,
        xbShipmentHistory: action.payload,
      };
    case "XB_REMOVE_ORDER_HISTORY":
      return {
        ...state,
        xbShipmentHistory: [],
      };
    case "XB_REVERSE_ORDER_HISTORY":
      return {
        ...state,
        xbReverseShipmentHistory: action.payload,
      };
    case "XB_REVERSE_REMOVE_ORDER_HISTORY":
      return {
        ...state,
        xbReverseShipmentHistory: [],
      };
    case "DELHIVERY_ORDER_HISTORY":
      return {
        ...state,
        delhiveryShipmentHistory: action.payload,
      };
    case "DELHIVERY_REMOVE_ORDER_HISTORY":
      return {
        ...state,
        delhiveryShipmentHistory: [],
      };
    case "PINCODES":
      return {
        ...state,
        pincodes: action.payload,
      };
    case "PINCODES_DELHIVERY":
      return {
        ...state,
        pincodesDelhivery: action.payload,
      };
    case "INVALID_EMAIL":
      return {
        ...state,
        invalidEmail: action.payload,
      };
    case "UNSUCCESSFUL_EMAIL":
      return {
        ...state,
        unsuccessfulEmail: action.payload,
      };
    case "COMPLAINT_EMAIL":
      return {
        ...state,
        complaintEmail: action.payload,
      };
    case "UPDATE_DYNAMO_MULTIPLE":
      return {
        ...state,
        xbNdrOnholdOrders: state.xbNdrOnholdOrders.filter(
          (x) => !action.payload.includes(x.fulfillment_id)
        ),
      };
    case "DELIVERED_RTO_ORDERS":
      return {
        ...state,
        xbDeliveredRtoOrders: action.data,
      };
    case "UPDATE_RTO_STOCK":
      return {
        ...state,
        xbDeliveredRtoOrders: state.xbDeliveredRtoOrders.map((x) => {
          if (x.fulfillment_id === action.payload.id) {
            return {
              ...x,
              rto_inventory_updated: true,
            };
          } else {
            return x;
          }
        }),
      };
    case "DISPATCHED_RTO_ORDERS":
      return {
        ...state,
        xbDispatchedRtoOrders: action.data,
      };
    case "UPDATE_DISPATCHED_RTO_STOCK":
      return {
        ...state,
        xbDispatchedRtoOrders: state.xbDispatchedRtoOrders.filter(
          (x) => !action.payload.id.includes(x.fulfillment_id)
        ),
      };
    case "UPDATE_DISPATCHED_REVERSE_STOCK":
      return {
        ...state,
        dispatchedREOrders: state.dispatchedREOrders.filter(
          (x) => !action.payload.includes(x.fulfillment_id)
        ),
      };
    case "UPDATE_SYNC_DISPATCHED_REVERSE_STOCK":
      return {
        ...state,
        synceddispatchREOrders: state.synceddispatchREOrders.filter(
          (x) => !action.payload.includes(x.fulfillment_id)
        ),
      };
    case "UPDATE_DELIVERED_REVERSE_STOCK":
      return {
        ...state,
        deliveredREOrders: state.deliveredREOrders.filter(
          (x) => !action.payload.includes(x.fulfillment_id)
        ),
      };
    case "UPDATE_XB_NPR_ORDERS":
      return {
        ...state,
        nprREOrders: state.nprREOrders.filter(
          (x) => !action.payload.includes(x.fulfillment_id)
        ),
      };
    case "WEEKLY_SALES_DATA":
      return {
        ...state,
        weeklySalesData: action.payload,
      };
    case "TRIDIURNAL_SALES_DATA":
      return {
        ...state,
        tridiurnalSalesData: action.payload,
      };
    case "PER_DAY_SALES_DATA":
      return {
        ...state,
        perDaySalesData: action.payload,
      };
    case "THREE_MONTHS_SALES_DATA":
      return {
        ...state,
        threeMonthsSalesData: action.payload,
      };
    case "STATE_WISE_SALES_DATA":
      return {
        ...state,
        stateWiseSalesData: action.payload,
      };
    case "REPEATING_PRODUCTS_DATA":
      return {
        ...state,
        repeatingProductsData: action.payload,
      };
    case "UNRELIABLE_ORDERS_DATA":
      return {
        ...state,
        unreliableOrders: action.payload,
      };
    case "THREE_MONTHS_UNFULFILLED_PRODUCTS_DATA":
      return {
        ...state,
        threeMonthsUnfulfilledProductsData: action.payload,
      };
    case "FILTERED_3M_UNFULFILLED_PRODUCTS_DATA":
      return {
        ...state,
        filtered3MProductsData: action.payload,
      };
    case "RETURNING_PRODUCTS_DATA":
      return {
        ...state,
        returningProductsData: action.payload,
      };
    case "ORDER_TAKEOUT":
      return {
        ...state,
        orderTakeout: action.payload,
      };
    default:
      return state;
  }
};

export default ordersReducer;
