import { Button } from "@mui/material";

const ChangeVendorProductCount = ({ count, onChange }) => {
    const increment = () => onChange(count + 1);
    const decrement = () => {
        if (count > 0) {
            onChange(count - 1);
        } else {
            return;
        }
    };
    const conatinerStyle = {
        display: "flex",
        gap: "5px",
        alignItems: "center",
    };
    return (
        <div style={conatinerStyle}>
            <Button
                onClick={decrement}
                variant="contained"
                color="success"
                size="small"
                sx={{ minWidth: 10 }}
            >
                -
            </Button>
            <p>{count}</p>
            <Button
                onClick={increment}
                variant="contained"
                size="small"
                sx={{ minWidth: 10 }}
            >
                +
            </Button>
        </div>
    );
};

export default ChangeVendorProductCount;
