import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const ShowEmailReport = ({ allOrders, invalid, complaints, unsuccessful }) => {
  const [serverError, setServerError] = useState([]);
  const [emailType, setEmailType] = useState("Invalid");
  const [emailList, setEmailList] = useState(invalid);
  useEffect(() => {
    const filtered = allOrders.filter((o) => "mailCheckStatus" in o);
    const serverError = filtered.filter((o) => o.mailCheckStatus === 500);
    setServerError(serverError);
  }, [allOrders]);
  useEffect(() => {
    if (emailType === "Invalid") setEmailList(invalid);
    if (emailType === "Complaints") setEmailList(complaints);
    if (emailType === "Unsuccessful") setEmailList(unsuccessful);
    if (emailType === "Server Error") setEmailList(serverError);
  }, [emailType, complaints, invalid, unsuccessful, serverError]);

  console.log("type", emailType);
  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ margin: "0 70px" }}
      >
        <Stack justifyContent={"center"} alignItems={"center"}>
          <Typography component={"p"} variant={"h2"}>
            {invalid.length}
          </Typography>
          {/* <h2>{stats.orders}</h2> */}
          <h4>Invalid Mails</h4>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack justifyContent={"center"} alignItems={"center"}>
          <Typography component={"p"} variant={"h2"}>
            {complaints.length}
          </Typography>
          {/* <h2>{stats.orders}</h2> */}
          <h4>Complaints</h4>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack justifyContent={"center"} alignItems={"center"}>
          <Typography component={"p"} variant={"h2"}>
            {unsuccessful.length}
          </Typography>
          {/* <h2>{stats.return}</h2> */}
          <h4>Unsuccessful</h4>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack justifyContent={"center"} alignItems={"center"}>
          <Typography component={"p"} variant={"h2"}>
            {serverError.length}
          </Typography>
          {/* <h2>{stats.exchange}</h2> */}
          <h4>Server Error</h4>
        </Stack>
      </Stack>
      <br />

      <Stack
        direction={"row"}
        sx={{
          margin: 0,
          padding: 0,
          overflowX: "hidden",
          width: "100%",
        }}
      >
        {["Invalid", "Complaints", "Unsuccessful", "Server Error"].map(
          (types) => (
            <Box
              onClick={(e) => {
                setEmailType(types);
              }}
              sx={{
                float: "left",
                margin: "0 .5em 0 0",
                "& p": {
                  margin: 0,
                  position: "relative",
                  background: emailType === types ? "#fff" : "#ddd",
                  backgroundImage:
                    !emailType === types
                      ? "linear-gradient(to bottom, #fff, #ddd)"
                      : "",
                  padding: ".7em 3.5em",
                  float: "left",
                  textDecoration: "none",
                  color: "#444",
                  textShadow: "0 1px 0 rgba(255,255,255,.8)",
                  borderRadius: "5px 0 0 0",
                  boxShadow: "0 2px 2px rgba(0,0,0,.4)",
                },
                "& p:focus,p:hover,p:hover::after,p:focus,p:focus::after": {
                  background: "#fff",
                  cursor: "pointer",
                },
                "& p:focus": {
                  outline: 0,
                },
                "& p::after": {
                  content: '""',
                  position: "absolute",
                  zIndex: emailType === types ? 4 : 1,
                  top: 0,
                  right: "-.5em",
                  bottom: 0,
                  width: "1em",
                  background: emailType === types ? "#fff" : "#ddd",
                  backgroundImage:
                    !emailType === types
                      ? "linear-gradient(to bottom, #fff, #ddd)"
                      : "",
                  boxShadow: "2px 2px 2px rgba(0,0,0,.4)",
                  transform: "skew(10deg)",
                  borderRadius: "0 5px 0 0",
                },
              }}
            >
              <p style={{ fontWeight: emailType === types ? 800 : 400 }}>
                {types}
              </p>
            </Box>
          )
        )}
      </Stack>

      <Box
        sx={{
          width: "100%",
          height: "100vh",
          background: "#fff",
        }}
      >
        <br />
        <Stack direction={"column"}>
          {emailList.map((e, index) => (
            <Box
              sx={{
                margin: "0 10px",
                "& h4": {
                  marginTop: "4px",
                },
              }}
            >
              <h4>{`${index + 1}. ${e}`}</h4>
              <Divider variant={"inset"} />
            </Box>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default ShowEmailReport;
