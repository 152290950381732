import { useState } from "react";
import AddStockModal from "./AddStockModal";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Button,
  Box,
  IconButton,
  TableFooter,
  TablePagination,
  Stack,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import HistoryIcon from "@mui/icons-material/History";
import { StockHistoryModal } from "./StockHistoryModal";
import { useDispatch } from "react-redux";
import { deleteProductFromShopifyAndDb } from "../../actions/inventoryActions";
import React from "react";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function AddProductSizeTable({ data, productSizes }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [stockHistoryModalOpen, setStockHistoryModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [page, setPage] = useState(0);

  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDeleteProduct = () => {
    const payloadSku = selectedRowData.SKU;
    const payloadId = selectedRowData.id;
    dispatch(deleteProductFromShopifyAndDb(payloadSku, payloadId));
    handleClose();
    setSelectedRowData({});
  };
  return (
    <>
      <TableContainer sx={{ width: "100%" }} component={Paper}>
        <Table sx={{ width: "100%" }} aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell> Image </TableCell>
              <TableCell> SKU </TableCell>
              <TableCell> Cost Price </TableCell>
              {productSizes.map((item) => (
                <TableCell key={item}> {item} </TableCell>
              ))}
              <TableCell> Total </TableCell>
              <TableCell>Material</TableCell>
              <TableCell>Amt</TableCell>
              <TableCell>Edit Stock</TableCell>
              <TableCell>History</TableCell>
              <TableCell> Status </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row) => (
              <TableRow
                key={row.SKU}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <Avatar
                    src={row.imageUrl}
                    alt="https://www.elegantthemes.com/blog/wp-content/uploads/2018/02/502-error.png"
                    sx={{
                      width: 100,
                      height: 100,
                      objectFit: "contain",
                    }}
                    variant="square"
                  />
                </TableCell>
                <TableCell>{row.SKU}</TableCell>
                <TableCell>{row.costPrice} </TableCell>
                {productSizes.map((i) => (
                  <TableCell key={i}>{row[i]}</TableCell>
                ))}
                <TableCell>{row.total}</TableCell>

                {row.material.length > 0 ? (
                  <React.Fragment>
                    <TableCell>
                      <div>
                        {row.material.map((m) => (
                          <p>
                            {m.name.toLowerCase().includes("not required")
                              ? "not required"
                              : `${m.type}${m.place ? `(${m.place})` : ""} ${
                                  m.color
                                } `}
                          </p>
                        ))}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>
                        {row.material.map((m) => (
                          <p>{m.amount}</p>
                        ))}
                      </div>
                    </TableCell>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <TableCell>Not found</TableCell>
                    <TableCell>Not found</TableCell>
                  </React.Fragment>
                )}
                <TableCell>
                  <Stack gap={1}>
                    <Button
                      onClick={() => {
                        setModalOpen(true);
                        setSelectedRowData(row);
                      }}
                      variant="contained"
                      size="small"
                    >
                      Edit Stock
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      color="error"
                      onClick={() => {
                        setSelectedRowData(row);
                        handleClickOpen();
                      }}
                      sx={{
                        ":hover": {
                          bgcolor: "red",
                          color: "white",
                        },
                      }}
                    >
                      Delete
                    </Button>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Box
                    onClick={() => {
                      setStockHistoryModal(!stockHistoryModalOpen);
                      setSelectedRowData(row);
                    }}
                    sx={{
                      ":hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    <HistoryIcon />
                  </Box>
                </TableCell>
                <TableCell>
                  <div
                    style={{
                      background: row.status === "active" ? "green" : "#FF0000",
                      textAlign: "center",
                      borderRadius: "20px",
                      padding: "5px 10px",
                      fontWeight: "500",
                      color: "white",
                    }}
                  >
                    {row.status ? row.status : "N/A"}
                  </div>
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{ overflow: "inherit" }}
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {stockHistoryModalOpen && Object.keys(selectedRowData).length > 0 && (
        <StockHistoryModal
          product={selectedRowData}
          setSelectedRowData={setSelectedRowData}
          open={stockHistoryModalOpen}
          setStockHistoryModal={setStockHistoryModal}
        />
      )}
      {modalOpen ? (
        <AddStockModal
          data={selectedRowData}
          setSelectedRowData={setSelectedRowData}
          setModalOpen={setModalOpen}
        />
      ) : (
        ""
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete product{" "}
            <span style={{ fontWeight: 800, background: "yellow" }}>
              {selectedRowData.SKU}
            </span>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleDeleteProduct}
            variant="contained"
            color="error"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
