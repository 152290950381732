/*
  THIS COMPONENT IS CALLED ONLY BY OLD UNFULFILLED PAGE "/orders"
  It used deprecated `gateway` field on orders endpoint
  Changing on 21March2024
  Need to get rid of this page altogether in future
*/
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import OrderProductsWithImage from "./OrderProductsWithImage.js";
import FilterUnfulfilledOrdersData from "./FilterUnfulfilledOrdersData.js";
import { createUnfulfilledOrdersTableData } from "../../utils/helpers";
import { TextField, MenuItem } from "@mui/material";
import { tagsArray } from "../../utils/helperArrays.js";

const OrdersList = () => {
  const orderData = useSelector((state) => state.orderDetails.allUnfulfilled);
  const productVariants = useSelector(
    (state) => state.productDetails.productVariants
  );
  const inventory = useSelector((state) => state.inventoryDetails.stock);

  const [orderedProducts, setOrderedProducts] = useState([]);
  const [filteredDateData, setFilteredDateData] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [payment, setPayment] = useState("");
  const [tag, setTag] = useState("");
  const [numberOfDays, setNumberOfDays] = useState(0);

  useEffect(() => {
    if (
      orderData.length > 0 &&
      inventory.length > 0 &&
      productVariants.length > 0
    ) {
      const formatted = createUnfulfilledOrdersTableData(filteredOrders);
      const zeroPending = inventory
        .reduce((acc, x) => {
          const found = acc.find((ac) => ac.sku === x.SKU);
          if (!found && x.SKU) {
            acc.push({
              sku: x.SKU,
            });
          }
          return acc;
        }, [])
        .map((item) => {
          const findUnfulfilled = formatted.find((f) => f.sku === item.sku);
          if (findUnfulfilled) {
            return findUnfulfilled;
          } else {
            const productId = productVariants.find(
              (p) => p.sku === item.sku
            )?.product_id;
            const zeroUnfulfilledProduct = {
              sku: item.sku,
              id: productId,
              name: "Two Piece Set: Aesthetics Black Top With Trouser",
              orderId: [],
              orderWithSizes: {},
              total: 0,
              totalLateUnfulfilled: 0,
              sizes: {},
            };
            return zeroUnfulfilledProduct;
          }
        });
      setOrderedProducts(zeroPending);
    }
  }, [orderData, productVariants, inventory]);

  useEffect(() => {
    if (filteredDateData?.length > 0) {
      const filteredOrders = filteredDateData.filter(
        (x) => x.financial_status !== "voided"
      );
      const formatted = createUnfulfilledOrdersTableData(filteredOrders);
      setOrderedProducts(formatted);
    } else {
      const filteredOrders = orderData.filter(
        (x) => x.financial_status !== "voided"
      );
      const formatted = createUnfulfilledOrdersTableData(filteredOrders);
      setOrderedProducts(formatted);
    }
  }, [filteredDateData, orderData]);

  useEffect(() => {
    const filterVoidedOrders = orderData.filter(
      (x) => x.financial_status !== "voided"
    );
    let orders = filterVoidedOrders;
    if (tag !== "") {
      if (tag === "None") {
        // const formatted = createUnfulfilledOrdersTableData(filterVoidedOrders);
        // setOrderedProducts(formatted);
        // setFilteredOrders(filterVoidedOrders);
        orders = filterVoidedOrders;
      } else if (tag === "Shopify") {
        const filteredTagData = filterVoidedOrders.filter(
          (item) => item.tags === "" || item.tags === "adp_order"
        );
        // const formatted = createUnfulfilledOrdersTableData(filteredTagData);
        // setOrderedProducts(formatted);
        // setFilteredOrders(filteredTagData);
        orders = filteredTagData;
      } else if (
        tag === "Hypd" ||
        tag === "Zaamo" ||
        tag === "Wishlink" ||
        tag === "Replacement"
      ) {
        const filteredTagData = filterVoidedOrders.filter((item) =>
          item.tags.toLowerCase().includes(tag.toLowerCase())
        );
        // const formatted = createUnfulfilledOrdersTableData(filteredTagData);
        // setOrderedProducts(formatted);
        // setFilteredOrders(filteredTagData);
        orders = filteredTagData;
      }
    }
    if (payment !== "") {
      if (payment === "C.O.D") {
        const filtered = orders.filter((o) =>
          o.payment_gateway_names.includes("Cash on Delivery (COD)")
        );
        orders = filtered;
      }
      if (payment === "Prepaid") {
        const filtered = orders.filter(
          (o) => !o.payment_gateway_names.includes("Cash on Delivery (COD)")
        );
        orders = filtered;
      }
    }
    const daysFiltered = orders.filter((item) => {
      const differenceTime =
        new Date().getTime() - new Date(item.created_at).getTime();
      const differentDays = Math.round(differenceTime / (1000 * 3600 * 24));

      return parseInt(differentDays) >= parseInt(numberOfDays);
    });
    orders = daysFiltered;
    setFilteredOrders(orders);
  }, [orderData, tag, payment, numberOfDays]);

  useEffect(() => {
    const formatted = createUnfulfilledOrdersTableData(filteredOrders);
    const reduceInv = inventory.reduce((acc, x) => {
      const found = acc.find((ac) => ac.sku === x.SKU);
      if (!found && x.SKU) {
        acc.push({
          sku: x.SKU,
        });
      }
      return acc;
    }, []);
    const zeroPending = reduceInv.map((item) => {
      const findUnfulfilled = formatted.find((f) => f.sku === item.sku);
      if (findUnfulfilled) {
        return findUnfulfilled;
      } else {
        const productId = productVariants.find(
          (p) => p.sku === item.sku
        )?.product_id;
        const zeroUnfulfilledProduct = {
          sku: item.sku,
          id: productId,
          name: "Two Piece Set: Aesthetics Black Top With Trouser",
          orderId: [],
          orderWithSizes: {},
          total: 0,
          totalLateUnfulfilled: 0,
          sizes: {},
        };
        return zeroUnfulfilledProduct;
      }
    });

    setOrderedProducts(zeroPending);
    // setOrderedProducts(formatted);
  }, [filteredOrders, inventory, productVariants]);

  return (
    <div>
      <FilterUnfulfilledOrdersData
        data={orderData}
        filteredDateData={setFilteredDateData}
      />
      <br />
      <TextField
        style={{ width: "500px" }}
        select
        label="Select A Tag"
        value={tag}
        onChange={(e) => setTag(e.target.value)}
        variant="outlined"
        className="select"
      >
        {[...tagsArray, "Replacement"].map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        style={{ width: "100px", marginLeft: "7px" }}
        type="number"
        label="Days"
        aria-valuemin={0}
        value={numberOfDays}
        onChange={(e) => setNumberOfDays(e.target.value)}
        variant="outlined"
        className="select"
      />
      <TextField
        style={{ width: "300px", marginLeft: "10px" }}
        select
        label="Select Payment Method"
        value={payment}
        onChange={(e) => setPayment(e.target.value)}
        variant="outlined"
        className="select"
      >
        {["None", "C.O.D", "Prepaid"].map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      {orderedProducts.length === 0 && numberOfDays > 0 ? (
        <h3>{numberOfDays} days old products not found</h3>
      ) : (
        <OrderProductsWithImage orderedProducts={orderedProducts} />
      )}
    </div>
  );
};

export default OrdersList;
