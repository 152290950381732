import { Box, Button, Chip, Divider, Stack } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerDetails,
  getShopifyCustomerOrders,
} from "../../actions/customerActions";
import ChatModule from "./ChatModule";
import CustomerOrderList from "./CustomerOrderList";
import ReadChat from "./ReadChat";

const ReadTicketBox = ({ ticket }) => {
  const [openOrders, setOpenOrders] = useState(false);
  const [chatboxValue, setChatBoxValue] = useState(2);
  const [customerValue, setCustomerValue] = useState(1);
  const [customerHover, setCustomerHover] = useState(false);
  const { shopifyOrders, customerDetails } = useSelector(
    (state) => state.customers
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(ticket).length > 0) {
      dispatch(getCustomerDetails(ticket.customer_id));
    }
  }, [ticket]);

  const handleCheckOrders = () => {
    dispatch(getShopifyCustomerOrders(ticket.customer_id));
    setOpenOrders(true);
  };

  return (
    <Box>
      <Stack direction="column" gap={2}>
        <Stack
          direction="row"
          gap={4}
          alignItems="center"
          sx={{
            "& h2": {
              margin: 0,
              padding: 0,
            },
            "& h3": {
              margin: 0,
              padding: 0,
            },
          }}
        >
          <h2>
            Ticket ID :{" "}
            <span style={{ color: "red" }}>#{ticket.fulfillment_id} </span>
          </h2>
          <h2>Customer ID :{ticket.customer_id}</h2>
          <h3>
            Case Status :{" "}
            <Chip
              label={
                ticket.package_status.includes("active")
                  ? ticket.package_status.split("#")[1]
                  : ticket.package_status
              }
              color={
                ticket.package_status.includes("new")
                  ? "primary"
                  : ticket.package_status.includes("open")
                  ? "success"
                  : "error"
              }
            />
          </h3>
          <h3>Ticket Age(in Days) : {String(ticket.ticketAge)}</h3>
          <h3>Order Id : {ticket.order_id || "error , reload"}</h3>
        </Stack>
        <Stack
          direction="row"
          gap={4}
          sx={{
            "& h3": {
              margin: 0,
              padding: 0,
            },
          }}
        >
          <h3>Created At : {ticket.created_at}</h3>
          <h3>Updated At : {ticket.updated_at}</h3>
        </Stack>
        <Stack
          direction="row"
          gap={4}
          sx={{
            "& h3": {
              margin: 0,
              padding: 0,
            },
          }}
        >
          <h3>Category : {ticket.category}</h3>
          <h3>Question : {ticket.question}</h3>
        </Stack>
      </Stack>
      <br />
      <Divider />
      <br />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          alignItems: "start",
        }}
      >
        <div
          onClick={() => {
            setCustomerValue(1);
            setChatBoxValue(2);
            setCustomerHover(false);
          }}
          style={{
            flex: chatboxValue,
            transition: "flex 0.1s",
          }}
        >
          <ReadChat
            messages={ticket.messages}
            chover={customerHover}
            ticket={ticket}
          />
        </div>
        <div
          onClick={() => {
            setChatBoxValue(1);
            setCustomerValue(5);
            setCustomerHover(true);
          }}
          style={{
            flex: customerValue,
            backgroundColor: "#f7f7f7",
            borderRadius: "5px",
            boxShadow: "2px -4px #D3D3D3",
            transition: "flex 0.1s",
          }}
        >
          <Stack
            direction="column"
            gap={2}
            sx={{
              padding: "20px",
              marginTop: "10px",
            }}
          >
            <Stack
              direction="column"
              gap={4}
              alignItems="center"
              sx={{
                "& h2": {
                  margin: 0,
                  padding: 0,
                },
                "& h3": {
                  margin: 0,
                  padding: 0,
                },
              }}
            >
              <h2>
                Customer ID :{" "}
                <span style={{ color: "red" }}>#{ticket.customer_id} </span>
              </h2>
            </Stack>
            <Stack direction={"row"}>
              <Stack
                direction="column"
                gap={1}
                alignItems="start"
                sx={{
                  "& h2": {
                    margin: 0,
                    padding: 0,
                  },
                  "& h3": {
                    margin: 0,
                    padding: 0,
                  },
                }}
              >
                <h3>
                  Name : {customerDetails.first_name || ""}{" "}
                  {customerDetails.last_name || ""}
                </h3>
                <h3>
                  Total Orders Given :{" "}
                  {customerDetails.orders_count || "couldn't update"}
                </h3>
                {customerDetails.orders_count > 0 ? (
                  <Button onClick={handleCheckOrders}>Check Orders</Button>
                ) : (
                  ""
                )}
              </Stack>
              <Box
                sx={{
                  marginRight: 0,
                  marginLeft: "auto",
                  "& h2": {
                    margin: 0,
                    padding: 0,
                  },
                  "& h3": {
                    margin: 0,
                    padding: 0,
                  },
                  "& h5": {
                    margin: 0,
                    padding: 0,
                  },
                }}
              >
                <h3>Default Address : </h3>
                <h5>
                  {customerDetails.default_address?.address1 ||
                    "couldn't update"}
                </h5>
                <h5>
                  {`${
                    customerDetails.default_address?.city || "couldn't update"
                  },
              ${customerDetails.default_address?.zip || "couldn't update"}`}
                </h5>
                <h5>
                  {customerDetails.default_address?.province ||
                    "couldn't update"}
                </h5>
                <h5>
                  {customerDetails.default_address?.country ||
                    "couldn't update"}
                </h5>
                <h5>
                  {customerDetails.default_address?.phone || "couldn't update"}
                </h5>
              </Box>
            </Stack>
            {openOrders && customerHover ? (
              <CustomerOrderList orders={shopifyOrders} />
            ) : (
              ""
            )}
          </Stack>
        </div>
      </div>
    </Box>
  );
};

export default ReadTicketBox;
