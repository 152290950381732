import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allInventory } from "../../actions/inventoryActions";
import CreatePurchaseOrderManual from "../poWidgets/CreatePurchaseOrderManual";
import RedirectLogin from "../RedirectLogin";
import "./index.css";

const CreatePOManual = () => {
    const auth = useSelector((state) => state.auth);
    const inventory = useSelector((state) => state.inventoryDetails.stock);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!inventory.length > 0 && auth) dispatch(allInventory());
    }, [dispatch, auth]);

    return (
        <>
            {!auth ? (
                <RedirectLogin />
            ) : (
                <>
                    {inventory.length > 0 ? (
                        <CreatePurchaseOrderManual />
                    ) : (
                        <h1>Loading... </h1>
                    )}
                </>
            )}
        </>
    );
};

export default CreatePOManual;

// || !products.length > 0
