import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createUnfulfilledOrdersTableData } from "../../utils/helpers";
import {
    FootwearRatio,
    ClothingRatio,
    MenShoeRatio,
    clothingSizes,
    shoesSizes,
    getSizesByType,
} from "../../utils/helperArrays";
import POTable from "./POTable";
import { Stack, Typography } from "@mui/material";
import { selectPOProducts } from "../../actions/POActions";
import { ConstructionOutlined } from "@mui/icons-material";

const POCheckout = ({ setOpen, poId }) => {
    const selectedProducts = useSelector((state) => state.PO.products);
    const dispatch = useDispatch();

    const [orderedProducts, setOrderedProducts] = useState([]);
    const [productType, setProductType] = useState("");
    const [productVendor, setProductVendor] = useState("");

    useEffect(() => {
        if (selectedProducts.length > 0) {
            setProductType(selectedProducts[0].type);
            setProductVendor(selectedProducts[0].vendor);
        }
    }, [selectedProducts]);

    useEffect(() => {
        if (selectedProducts.length > 0) {
            console.log("REDUX PO PRODUCTS", selectedProducts);
            const newProductArray = selectedProducts.map((product) => {
                let postock = {};
                const sizes = getSizesByType(product.type);

                sizes.forEach((size) => {
                    postock[size] = 0;
                });

                return {
                    ...product,
                    postock,
                    totalpo: 0,
                };
            });
            dispatch(selectPOProducts(newProductArray));
        }
        // Do nothing
    }, []);

    return (
        <>
            {selectedProducts.length > 0 ? (
                <>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography variant="h4" component="h2">
                            {selectedProducts[0].type}{" "}
                        </Typography>

                        <Typography variant="h4" component="h2">
                            {selectedProducts[0].vendor}{" "}
                        </Typography>
                    </Stack>

                    {selectedProducts.length > 0 ? (
                        <POTable
                            productType={selectedProducts[0].type}
                            setOpen={setOpen}
                            poId={poId}
                        />
                    ) : (
                        ""
                    )}
                </>
            ) : (
                ""
            )}
        </>
    );
};

export default POCheckout;
