import React, { useState } from "react";
import ManualExchangePage from "../reverse/manualexchange/ManualExchange";
import OrderSearch from "../reverse/manualexchange/OrderSearch";
import { manualExchangeSearchOrder } from "../../actions/ordersActions";
const ManualExchange = () => {
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [action, setAction] = useState("by_order_number");
  const [email, setEmail] = useState("");
  const [order, setOrder] = useState(null);
  const [previousExchangeDetails, setPreviousExchangeDetails] = useState([]);
  const [exchangeHistoryDetails, setExchangeHistoryDetails] = useState([]);

  const handleSearch = async () => {
    setEmail("");
    setOrder(null);
    setPreviousExchangeDetails([]);
    setExchangeHistoryDetails([]);
    console.log("working");
    setLoading(true);
    const response = await manualExchangeSearchOrder(orderId, action);
    console.log(response);
    if (response.status === 200) {
      const orderDetails = response.body.orderDetails;
      const customer = "customer" in orderDetails ? orderDetails.customer : "";
      const email =
        !!customer &&
        "email" in customer &&
        customer.email &&
        !customer.email.includes("lbindia.myshopify.com")
          ? customer.email
          : "";
      setEmail(email);
      setOrder(response.body.orderDetails);
      setPreviousExchangeDetails(response.body.previousExchangeDetails);
      setExchangeHistoryDetails(response.body.exchangeHistoryDetails);
    } else {
      // nothing
      setEmail("");
      setOrder(null);
      setPreviousExchangeDetails([]);
      setExchangeHistoryDetails([]);
    }
    setLoading(false);
  };
  console.log("action", action);
  return (
    <div>
      <OrderSearch
        loading={loading}
        orderId={orderId}
        setOrderId={setOrderId}
        handleSearch={handleSearch}
        action={action}
        setAction={setAction}
      />
      {order && (
        <ManualExchangePage
          loading={loading}
          order={order}
          email={email}
          previousExchangeDetails={previousExchangeDetails}
          exchangeHistory={exchangeHistoryDetails}
          setEmail={setEmail}
          setExchangeHistoryDetails={setExchangeHistoryDetails}
          setPreviousExchangeDetails={setPreviousExchangeDetails}
          setOrder={setOrder}
        />
      )}
    </div>
  );
};

export default ManualExchange;
