import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  createProductsTableData,
  createProductsTableDataWithDrafts,
} from "../../utils/helpers";
import { productTypes, getSizesByType } from "../../utils/helperArrays";
import AddProductSizeTable from "../inventoryWidgets/AddProductSizeTable";
import {
  TextField,
  MenuItem,
  Box,
  InputAdornment,
  Paper,
  Divider,
  Button,
} from "@mui/material";

const productStatusList = ["All", "active", "draft", "no status"];
const AddProductStock = () => {
  const inventoryData = useSelector((state) => state.inventoryDetails.stock);
  const { shopify_products } = useSelector((state) => state.shopifyDetails);

  const [productType, setProductType] = useState("");
  const [products, setProducts] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [productSizes, setProductSizes] = useState([]);
  const [searchedSKU, setSearchedSKU] = useState("");
  const [productStatus, setProductStatus] = useState("All");

  const [inventoryErrorList, setInventoryErrorList] = useState([]);
  const [brokenImageList, setBrokenImageList] = useState([]);
  const [checkImageLoading, setCheckImageLoading] = useState(false);
  const [checkImageProgress, setCheckImageProgress] = useState("");

  useEffect(() => console.log("Table Data", tableData), [tableData]);

  useEffect(() => {
    if (
      inventoryData.length > 0 &&
      productType &&
      shopify_products.length > 0
    ) {
      const noSkus = inventoryData.filter((i) => !i.SKU);
      setInventoryErrorList(noSkus);

      const temp = getSizesByType(productType);
      setProductSizes(temp);
      // const data = createProductsTableData(inventoryData, productType);
      const data = createProductsTableDataWithDrafts(
        inventoryData,
        shopify_products,
        productType
      );

      setProducts(data);
    }
  }, [inventoryData, shopify_products, productType]);

  const handleSearchSKU = (e) => {
    e.preventDefault();
    const text = e.target.value;
    setSearchedSKU(text);
    console.log("text", text);
    if (text.length > 1) {
      console.log(
        "no sku",
        products.filter((p) => !p.SKU)
      );
      const filteredSKU = products.filter((p) =>
        p.SKU.toString().match(text.toUpperCase())
      );
      if (filteredSKU) {
        console.log("filtered sku", filteredSKU);
        setTableData(filteredSKU);
      } else {
        setTableData([]);
      }
    } else {
      setTableData(products);
    }
  };

  useEffect(() => {
    if (products.length > 0) {
      console.log(products);
      setTableData(
        products.filter(
          (p) =>
            productStatus === "All" ||
            (p.status && p.status.includes(productStatus)) ||
            (productStatus === "no status" && !p.status)
        )
      );
    }
  }, [products, productStatus]);

  const handleCheckImages = async () => {
    if (inventoryData.length === 0) return;
    setCheckImageLoading(true);
    const skuWithImages = inventoryData
      .filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.SKU === item.SKU)
      )
      .map((p) => ({ SKU: p.SKU, imageUrl: p.imageUrl }));
    let err = [];
    for (let i = 0; i < skuWithImages.length; i++) {
      console.log(skuWithImages[i]);
      setCheckImageProgress(`${i}/${skuWithImages.length}`);
      if (skuWithImages[i].imageUrl) {
        const response = await fetch(skuWithImages[i].imageUrl, {
          method: "HEAD",
        });
        if (!response.ok) {
          err.push(skuWithImages[i].SKU);
        }
      } else {
        err.push(skuWithImages[i].SKU);
      }
    }

    setBrokenImageList(err);
    setCheckImageLoading(false);
  };

  return (
    <>
      <br />
      <>
        {inventoryErrorList.length > 0 && (
          <Box
            sx={{
              background: "gray",
              opacity: 0.5,
              width: "100%",
              textAlign: "center",
              p: "7px",
              marginBottom: "5px",
            }}
          >
            <h4 style={{ color: "white" }}>
              Some products does not have sku : {inventoryErrorList.length}
            </h4>
          </Box>
        )}
        {checkImageLoading && (
          <Box
            sx={{
              background: "gray",
              opacity: 0.5,
              width: "100%",
              textAlign: "center",
              p: "7px",
              marginBottom: "5px",
            }}
          >
            <h4 style={{ color: "white" }}>
              checking for broken images .... <span>{checkImageProgress}</span>
            </h4>
          </Box>
        )}
        {brokenImageList.length > 0 && (
          <Box
            sx={{
              background: "gray",
              opacity: 0.5,
              width: "100%",
              textAlign: "center",
              p: "7px",
              marginBottom: "5px",
            }}
          >
            <h4 style={{ color: "white" }}>
              Number of images not working : {brokenImageList.length}
            </h4>
            <Divider sx={{ color: "white" }} />
            {brokenImageList.map((i) => (
              <p style={{ color: "white" }}>{i}</p>
            ))}
          </Box>
        )}
        <Box sx={{ display: "flex", flexDirection: "row", gap: 20 }}>
          <Paper sx={{ width: "400px" }}>
            <TextField
              select
              // label="Select Product Type"
              value={productType}
              onChange={(e) => setProductType(e.target.value)}
              variant="outlined"
              className="select"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <p
                      style={{
                        fontWeight: 800,
                        borderRight: "1px solid black",
                        paddingRight: "4px",
                      }}
                    >
                      Product Type
                    </p>
                  </InputAdornment>
                ),
              }}
            >
              {productTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Paper>
        </Box>
        <br />
        {productType && (
          <Box sx={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <Paper sx={{ width: "400px" }}>
              <TextField
                // label="Search SKU"
                type="text"
                variant="outlined"
                size="small"
                value={searchedSKU}
                fullWidth
                onChange={(e) => handleSearchSKU(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <p
                        style={{
                          fontWeight: 800,
                          borderRight: "1px solid black",
                          paddingRight: "4px",
                        }}
                      >
                        Search
                      </p>
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
            <Paper>
              <TextField
                style={{ width: "250px" }}
                select
                value={productStatus}
                onChange={(e) => setProductStatus(e.target.value)}
                variant="outlined"
                className="select"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <p
                        style={{
                          fontWeight: 800,
                          borderRight: "1px solid black",
                          paddingRight: "4px",
                        }}
                      >
                        Product Status
                      </p>
                    </InputAdornment>
                  ),
                }}
              >
                {productStatusList.map((option, i) => (
                  <MenuItem key={i} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Paper>
          </Box>
        )}
        <Box my={1}>
          <Button variant="outlined" onClick={handleCheckImages}>
            Check Images
          </Button>
        </Box>
      </>
      <br />
      {productType && tableData.length > 0 && productSizes.length > 0 ? (
        <AddProductSizeTable
          data={tableData}
          productSizes={productSizes}
          setBrokenImageList={setBrokenImageList}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default AddProductStock;
