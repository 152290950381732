const initialState = {
    loading : false,
    rto_orders : [],
    rto_intransit : [],
    rto_delivered : [],
    forward_delivered : [],
    forward_intransit : [],
    forward_ndr : [],
    monthly_rto : [],
  };

const xbReducer = (state=initialState, action) => {
    switch(action.type){
        case "SHOW_LOADER":
            return {
                ...state,
                loading : true
            }
        case "HIDE_LOADER":
            return {
                ...state,
                loading : false
            }
        case "RTO_ORDERS":
            return {
                ...state,
                rto_orders : action.data
            }
        case "RTO_INTRANSIT_ORDERS":
            return {
                ...state,
                rto_intransit : action.data
            }
        case "RTO_DELIVERED_ORDERS":
            return {
                ...state,
                rto_delivered : action.data
            }
        case "FORWARD_DELIVERED_ORDERS":
            return {
                ...state,
                forward_delivered : action.data
            }
        case "FORWARD_INTRANSIT_ORDERS":
            return {
                ...state,
                forward_intransit : action.data
            }
        case "FORWARD_NDR_ORDERS":
            return {
                ...state,
                forward_ndr : action.data
            }
        case "MONTHLY_RTO_ORDERS":
            return {
                ...state,
                monthly_rto : [...state.monthly_rto,...action.data]
            }
        case "MONTHLY_RTO_INTRANSIT_ORDERS":
            return {
                ...state,
                monthly_rto : [...state.monthly_rto,...action.data]
            }
        case "MONTHLY_RTO_DELIVERED_ORDERS":
            return {
                ...state,
                monthly_rto : [...state.monthly_rto,...action.data]
            }
        default:
            return state;
    }
}

export default xbReducer