import { Box, CircularProgress, Tabs, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomerTable from "../unreliableCustomerWidgets/UnreliableCustomerTable";
import { getUnreliableOrdersData } from "../../actions/ordersActions";

const dateToday = new Date();
const date1DayAgo = new Date(new Date().setDate(new Date().getDate() - 1));
const date2DayAgo = new Date(new Date().setDate(new Date().getDate() - 2));
const date3DayAgo = new Date(new Date().setDate(new Date().getDate() - 3));
const dateFilters = ["All", dateToday, date1DayAgo, date2DayAgo, date3DayAgo];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UnreliableOrders = () => {
  const [value, setValue] = useState(0);
  const { unreliableOrders } = useSelector((state) => state.orderDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUnreliableOrdersData());
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isSameDay = (orderDate, date) =>
    new Date(orderDate).getDate() === new Date(date).getDate() &&
    new Date(orderDate).getFullYear() === new Date(date).getFullYear() &&
    new Date(orderDate).getMonth() === new Date(date).getMonth();
  console.log("date filters", dateFilters);
  console.log("unreliable orders", unreliableOrders);
  return (
    <Box>
      <h1
        style={{
          marginBottom: 4,
        }}
      >
        3 days Unreliable Orders
        <span>
          {!unreliableOrders ? (
            <CircularProgress size={20} />
          ) : (
            `(Sync at ${unreliableOrders.updated_at})`
          )}
        </span>
      </h1>
      {unreliableOrders && (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {dateFilters.map((d, i) => (
                <Tab
                  key={i}
                  label={
                    d === "All" ? d : new Date(d).toLocaleDateString("en-in")
                  }
                  {...a11yProps(i)}
                />
              ))}
            </Tabs>
          </Box>
          {dateFilters.map((d, i) => (
            <TabPanel key={i} value={value} index={i}>
              <CustomerTable
                data={unreliableOrders.sales_data.filter(
                  (sale_data) =>
                    d === "All" || isSameDay(sale_data.created_at, d)
                )}
              />
            </TabPanel>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default UnreliableOrders;
