import React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TablePagination,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import ProductViewModal from "./ReverseProductViewModal";
import { useDispatch } from "react-redux";

import { getDelhiveryShipmentDetails } from "../../actions/ordersActions";
import { DelhiveryOrderHistory } from "../delhivery/tracking/DelhiveryOrderHistory";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function Row({ row, index }) {
  const [productModal, setProductModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [orderHistoryModal, setOrderHistoryModal] = useState(false);
  const dispatch = useDispatch();

  const handleData = () => {
    let tempArr = [];
    tempArr.push(row);
    setModalData(tempArr);
  };

  const handleOpenHistory = (awb) => {
    dispatch(getDelhiveryShipmentDetails(awb));
    setOrderHistoryModal(true);
  };
  const calculateOrderAge = () => {
    const timeDiff = new Date() - new Date(row.created_at);
    const age = timeDiff / (1000 * 60 * 60 * 24);
    return String(Math.round(age));
  };
  return (
    <>
      <TableRow
        component={Paper}
        sx={{
          "&:hover": {
            backgroundColor: "rgb(220,220,220)",
          },
        }}
      >
        <TableCell
          component="th"
          scope="row"
          style={{ color: row.tags?.includes("replacement") ? "red" : "blue" }}
        >
          <Typography>{row.fulfillment_id}</Typography>
          {row.tags?.includes("replacement") ? (
            <Typography
              style={{
                fontSize: "x-small",
                borderBottom: "2px dotted",
                width: "max-content",
              }}
            >
              2nd Exchange
            </Typography>
          ) : (
            ""
          )}
        </TableCell>
        <TableCell>
          {new Date(row.sync_date).toLocaleDateString("en-in")}
        </TableCell>
        <TableCell>
          {row.modified_at
            ? new Date(row.modified_at).toLocaleDateString("en-in")
            : "....."}
        </TableCell>
        <TableCell style={{ maxWidth: "200px" }}>
          <Typography style={{ color: "blue" }}>
            {row.address ? row.address.name : ""}
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            style={{ fontSize: "small" }}
          >
            {row.address ? row.address.address1 : ""},
            {row.address ? `${row.address.city},${row.address.province}` : ""},
            {row.address ? row.address.zip : ""}
            <br />
            Ph : {row.address ? row.address.phone : ""}
            <br />
            Alt : {row.alternatePhone ? row.alternatePhone : ""}
            <br />
            Email : {row.email ? row.email : ""}
          </Typography>
        </TableCell>
        <TableCell>
          <Box>
            <Typography>{row.awb_code ? row.awb_code : "N/A"}</Typography>
            <Typography
              onClick={() => {
                setProductModal(true);
                handleData();
              }}
              style={{
                fontSize: "x-small",
                color: "blue",
                cursor: "pointer",
                borderBottom: "2px dotted",
                width: "max-content",
              }}
            >
              View Products
            </Typography>
            {productModal ? (
              <ProductViewModal
                productModal={productModal}
                tableData={modalData}
                setProductModal={setProductModal}
              />
            ) : (
              ""
            )}
          </Box>
        </TableCell>
        <TableCell align="left">
          <Box
            onClick={() => handleOpenHistory(row.awb_code)}
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "blue",
                textDecoration: "underline",
              },
            }}
          >
            {String(row.package_status).replace("bsync#", "")}
          </Box>
          {orderHistoryModal ? (
            <DelhiveryOrderHistory
              open={orderHistoryModal}
              setOrderHistoryModal={setOrderHistoryModal}
            />
          ) : (
            ""
          )}
        </TableCell>
        <TableCell align="left">{calculateOrderAge()}</TableCell>
      </TableRow>
    </>
  );
}

const CanceledTable = ({ tableData }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead component={Paper}>
            <TableRow>
              <TableCell align="left">Order Number</TableCell>
              <TableCell align="left">Created On </TableCell>
              <TableCell align="left">Last Action On</TableCell>
              <TableCell align="left">Customer Details</TableCell>
              <TableCell align="left">AWB Code</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Order Age(days)</TableCell>
            </TableRow>
          </TableHead>
          <br />
          <TableBody>
            {(rowsPerPage > 0
              ? tableData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : tableData
            ).map((row, index) => (
              <Row key={index} row={row} index={index} />
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <TablePagination
        style={{
          overflow: "inherit",
          display: "flex",
          justifyContent: "center",
          background: "white",
          borderRadius: "10px",
        }}
        rowsPerPageOptions={[5, 10, 25, 100]}
        colSpan={3}
        count={tableData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            "aria-label": "rows per page",
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </>
  );
};

export default CanceledTable;
