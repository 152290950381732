import React, { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  changeStockOutwardList,
  getStockOutwardList,
  recievestock,
} from "../../../actions/POActions";
import { getSizesByType } from "../../../utils/helperArrays";
import { set } from "date-fns";
import {
  addInventoryStock,
  allInventory,
} from "../../../actions/inventoryActions";

// switch label
const label = { inputProps: { "aria-label": "Switch demo" } };

//show image switch
const ShowImageSwitch = ({ showImage, setShowImage }) => (
  <div>
    <FormControlLabel
      labelPlacement="start"
      label="Show image"
      control={
        <Switch
          {...label}
          checked={showImage}
          onChange={(e) => setShowImage(e.target.checked)}
        />
      }
    />
    {/* <Switch  /> */}
  </div>
);
// table body row
const Row = ({ product, type, showImage, showPOID }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleRecieve = async () => {
    setLoading(true);
    const inventoryStock = product.stock;
    dispatch(addInventoryStock(inventoryStock));
    const data = await recievestock(product.pk, product.sk);
    if (data.status === 200) {
      console.log("data", data);
      dispatch(
        changeStockOutwardList(
          data.data.pk,
          data.data.sk,
          data.data.package_status,
          data.data.received_at
        )
      );
    }
    setLoading(false);
  };
  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          {new Date(product.created_at).toLocaleDateString()}
        </TableCell>
        <TableCell>{product.POID}</TableCell>
        {showImage && (
          <TableCell>
            <img
              src={product.src}
              alt=""
              style={{
                width: 100,
                height: 100,
                objectFit: "contain",
              }}
            />
          </TableCell>
        )}
        <TableCell>{product.sku}</TableCell>
        {getSizesByType(type).map((s) => (
          <TableCell>{product.quantity[s]}</TableCell>
        ))}
        <TableCell>{product.total}</TableCell>
        <TableCell>
          {product.package_status === "newstock" ? (
            <div>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button variant="outlined" onClick={handleRecieve}>
                  Recieve
                </Button>
              )}
            </div>
          ) : (
            <h3>
              Received stock at{" "}
              {new Date(product.received_at).toLocaleDateString()}
            </h3>
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const HeaderRow = ({ type, showImage }) => {
  return (
    <React.Fragment>
      <TableRow>
        <TableCell>date</TableCell>
        <TableCell>PO ID</TableCell>
        {showImage && <TableCell>Img</TableCell>}
        <TableCell>sku</TableCell>
        {getSizesByType(type).map((s) => (
          <TableCell>{s}</TableCell>
        ))}
        <TableCell>Total</TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const StockOutward = ({ type, category, vendor }) => {
  const [tableData, setTableData] = useState([]);
  const [showImage, setShowImage] = useState(true);
  const { stockOutwardList } = useSelector((state) => state.PO);
  const inventory = useSelector((state) => state.inventoryDetails.stock);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStockOutwardList("stockoutward#active"));
    if (!inventory.length > 0) dispatch(allInventory());
  }, []);

  useEffect(() => {
    if (!inventory.length > 0) return;
    // console.log("list", stockOutwardList, category, type, vendor);
    const filtered = stockOutwardList.filter(
      (p) =>
        p.type === type &&
        p.sku.includes(category) &&
        (p.vendor === vendor || !vendor)
    );
    console.log("filtered", filtered);
    // add barcode from inventory
    const barcodeAdded = filtered.map((d) => ({
      ...d,
      stock: Object.keys(d.quantity).reduce((acc, s) => {
        acc.push({
          barcode: inventory.find(
            (i) => i.SKU === d.sku && String(i.size) === String(s)
          )["barcode"],
          count: d.quantity[s],
        });
        return acc;
      }, []),
    }));
    setTableData(barcodeAdded);
  }, [stockOutwardList, category, type, vendor, inventory]);

  console.log("tabledata", tableData);
  console.log("inventory", inventory);

  return (
    <div>
      {/* switches */}
      <Stack direction={"row"}>
        <ShowImageSwitch showImage={showImage} setShowImage={setShowImage} />
      </Stack>
      <TableContainer component={Paper} sx={{ mb: "5px" }}>
        <Table>
          <TableHead>
            <HeaderRow showImage={showImage} type={type} />
          </TableHead>
          <TableBody>
            {tableData.map((row, i) => (
              <Row product={row} key={i} type={type} showImage={showImage} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default StockOutward;
