import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getMaterialColorList,
  getMaterialFabricList,
} from "../../actions/materialActions";
import { Backdrop, CircularProgress } from "@mui/material";
import MaterialList from "../inventoryWidgets/materialwidgets/MaterialList";

const ProductMaterial = () => {
  const { materialFabricList, materialColorList } = useSelector(
    (state) => state.inventoryDetails
  );
  const { loading } = useSelector((state) => state.loader);
  const dispatch = useDispatch();

  useEffect(() => {
    if (materialFabricList.length === 0) dispatch(getMaterialFabricList());
    if (materialColorList.length === 0) dispatch(getMaterialColorList());
  }, []);
  console.log("list", materialFabricList, materialColorList);
  return (
    <>
      <>
        {!materialFabricList.length > 0 ||
        !materialColorList.length > 0 ||
        loading ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={
              materialFabricList.length === 0 ||
              materialColorList.length === 0 ||
              loading
            }
          >
            <CircularProgress size={100} color="inherit" />
          </Backdrop>
        ) : (
          <MaterialList
            fabricList={materialFabricList}
            colorList={materialColorList}
          />
        )}
      </>
    </>
  );
};

export default ProductMaterial;
