import React from 'react'

import { Button, Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useDispatch } from 'react-redux';
import { reApproveReverseOrder } from '../../../actions/ordersActions';
import { hideLoading, showLoading } from '../../../actions/loaderActions';

const NprReApproveModal = ({open, setReApproveModal, rowData}) => {
  const [openUp, setOpenUp] = React.useState(open);

  const dispatch = useDispatch()

  const handleClose = () => {
    setOpenUp(false)
    setReApproveModal(false)
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const handleSubmit = () => {
    dispatch(showLoading())
    const formatDate = new Date().getDate() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getFullYear()

    const dynamoObj = {
      id : rowData.fulfillment_id,
      cancelled_awb : [rowData.awb_code],
      npr_history : [
        {
          lbi_Remarks : "Reset To Approve Order",
          reApproveDate : formatDate,
        }
      ]
    }

    console.log(dynamoObj)
    dispatch(reApproveReverseOrder(dynamoObj))
    // dispatch(hideLoading())
    handleClose()
  }

  return (
    <div>
        <Dialog
            open={openUp}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"Sure To ReApprove This Order?"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Agreeing to this will reset this order back to Approve R&E.
                Please proceed to the Approve R&E to put a new Pickup request.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button onClick={handleSubmit} autoFocus>
                Agree
            </Button>
            </DialogActions>
        </Dialog>
    </div>
  )
}

export default NprReApproveModal