import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
const actionArray = [
  {
    value: "by_order_number",
    label: "order number",
  },
  {
    value: "by_order_id",
    label: "order id",
  },
];
const OrderSearch = ({
  handleSearch,
  loading,
  setOrderId,
  orderId,
  ordersLength = 1,
  action,
  setAction,
}) => {
  return (
    <div>
      <Stack
        direction={"row"}
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper
          sx={{
            width: "100%",
            height: ordersLength > 0 ? null : "70vh",
            px: "2px",
            py: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 3,
            mb: 2,
          }}
        >
          {ordersLength <= 0 && (
            <h1 style={{ color: "gray" }}>Manual Exchange</h1>
          )}
          <Stack direction={"row"} gap={2}>
            <TextField
              style={{ width: "250px" }}
              select
              // placeholder=""
              value={action}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <p
                      style={{
                        fontWeight: 800,
                        borderRight: "1px solid black",
                        paddingRight: "4px",
                      }}
                    >
                      Status
                    </p>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setAction(e.target.value)}
              variant="outlined"
              className="select"
              size="small"
            >
              {actionArray.map((option) => (
                <MenuItem key={option.label} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              style={{ width: "400px" }}
              placeholder="Search Order"
              value={orderId}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <p
                      style={{
                        fontWeight: 800,
                        borderRight: "1px solid black",
                        paddingRight: "4px",
                      }}
                    >
                      Order ID
                    </p>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setOrderId(e.target.value)}
              variant="outlined"
              className="select"
              size="small"
            />
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                size="small"
                disabled={loading}
                variant="contained"
                onClick={handleSearch}
              >
                Search Order
              </Button>
            )}
          </Stack>
        </Paper>
      </Stack>
    </div>
  );
};

export default OrderSearch;
