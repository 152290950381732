import React, { useState } from "react";
import { Paper, Stack } from "@mui/material";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import MainDashboard from "../materialDashboardWidgets/MainDashboard";
import SendFabrics from "../materialDashboardWidgets/SendFabrics";
import ReceiveFabrics from "../materialDashboardWidgets/ReceiveFabrics";
import ModifyMaterials from "../materialDashboardWidgets/ModifyMaterials";
import FabricRates from "../materialDashboardWidgets/FabricRates";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const MaterialDashboard = () => {
  // create Material Dashboard Tabs
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Stack direction={"row"} sx={{ alignItems: "start" }} gap={3}>
      <Paper sx={{ minWidth: "200px" }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          // sx={{
          //   borderRight: `1px solid red`,
          // }}
        >
          <Tab label="Dashboard" {...a11yProps(0)} />
          <Tab label="Send Fabrics" {...a11yProps(1)} />
          <Tab label="Receive Fabrics" {...a11yProps(2)} />
          <Tab label="Add/Remove Material" {...a11yProps(3)} />
          <Tab label="Fabric Rates" {...a11yProps(4)} />
        </Tabs>
      </Paper>
      <Box width={"100%"}>
        <TabPanel value={value} index={0}>
          <MainDashboard />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SendFabrics />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ReceiveFabrics />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ModifyMaterials />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <FabricRates />
        </TabPanel>
      </Box>
    </Stack>
  );
};

export default MaterialDashboard;
