import React, { useState } from "react";
import POShowList from "../poWidgets/POList/Index.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllPOByStatus } from "../../actions/POActions.js";
import ChangeView from "../poWidgets/POList/ChangeView.js";
import DatewiseTable from "../poWidgets/POList/DatewiseTable.js";
import ProductWiseTable from "../poWidgets/POList/ProductWiseTable.js";
import Filter from "../poWidgets/POList/Filter.js";
import ProcessingTable from "../poWidgets/POList/ProcessingTable.js";
import StockOutward from "../poWidgets/POList/StockOutward.js";
import { Stack } from "@mui/material";
import DownloadPO from "../poWidgets/POList/DownloadPO.js";
const viewArray = ["productwise", "datewise", "processing", "stockoutward"];
const ShowPO = () => {
  const [view, setView] = useState("datewise");
  //filter states
  const [type, setType] = useState("Clothing");
  const [category, setCategory] = useState("");
  const [vendor, setVendor] = useState("All");
  const [data, setData] = useState([]);
  const { POList } = useSelector((state) => state.PO);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!POList.length > 0) dispatch(getAllPOByStatus("active"));
  }, []);

  useEffect(() => {
    const filtered = POList.filter(
      (p) =>
        p.type === type &&
        p.sk.includes(category) &&
        (p.vendor === vendor || vendor === "All")
    );
    setData(filtered);
    console.log("filtered", filtered);
  }, [type, category, vendor, POList]);

  const changeView = (value) => setView(value);

  console.log("po list", POList, type);
  return (
    <div>
      <Stack
        direction={"row"}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ChangeView view={view} changeView={changeView} list={viewArray} />
        <DownloadPO />
      </Stack>
      <Filter
        type={type}
        setType={setType}
        category={category}
        setCategory={setCategory}
        vendor={vendor}
        setVendor={setVendor}
      />
      {view === "datewise" && data.length > 0 && (
        <DatewiseTable data={data} type={data[0].type} />
      )}
      {view === "productwise" && data.length > 0 && (
        <ProductWiseTable data={data} type={data[0].type} />
      )}
      {view === "processing" && data.length > 0 && (
        <ProcessingTable data={data} type={data[0].type} />
      )}
      {view === "stockoutward" && data.length > 0 && (
        <StockOutward
          data={data}
          type={data[0].type}
          category={category}
          vendor={vendor}
        />
      )}
      {!data.length > 0 && <h3>Not found</h3>}
    </div>
  );
};

export default ShowPO;
