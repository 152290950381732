import React from "react";
import OrderHistory from "../ordersWidgets/customerorderhistory/OrderHistory";

const CustomerOrderHistory = () => {
  return (
    <div>
      <OrderHistory />
    </div>
  );
};

export default CustomerOrderHistory;
