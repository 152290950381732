import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { allUnfulfilledOrders } from "../../actions/ordersActions";
import { allInventory } from "../../actions/inventoryActions";
import ManualOrderTakeout from "../ordersWidgets/ManualOrderTakeout";
import RedirectLogin from "../RedirectLogin";
import ProgressBar from "../../ProgressBar";
import "./index.css";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Button, TextField, Box, Backdrop } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { showLoading } from "../../actions/loaderActions";

const ManualDispatch = () => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const unfulfilled_orders = useSelector(
        (state) => state.orderDetails.allUnfulfilled
    );
    // console.log(unfulfilled_orders.map(x => x.order_number))
    const { loading } = useSelector((state) => state.loader);
    const inventory = useSelector((state) => state.inventoryDetails.stock);
    const [startValue, setStartValue] = useState(
        dayjs(new Date().setDate(new Date().getDate() - 7))
    );
    const [endValue, setEndValue] = useState(dayjs(new Date()));
    const [searchFilter, setSearchFilter] = useState("");
    const [orders, setOrders] = useState([]);
    const [seachLoading, setSearchLoading] = useState(false);

    const handleStartChange = (newValue) => {
        setStartValue(newValue);
    };

    const handleLastChange = (newValue) => {
        setEndValue(newValue);
    };

    useEffect(() => {
        if (!auth) return;
        // if (!unfulfilled_orders.length > 0 && auth)
        //   dispatch(allUnfulfilledOrders());
        // if (!inventory.length > 0 && auth) dispatch(allInventory());
    }, [auth]);

    useEffect(() => {
        if (orders.length > 0) setSearchLoading(false);
    }, [orders]);

  useEffect(() => {
      if(searchFilter !== ""){
        const filteredOrder = unfulfilled_orders.filter(x => String(x.order_number).startsWith(searchFilter))
        console.log(filteredOrder)
        if(filteredOrder) setOrders(filteredOrder)
      }
      else{
        setOrders(unfulfilled_orders)
      }
  },[searchFilter, unfulfilled_orders])
    const handleFetchOrders = () => {
        dispatch(showLoading());
        setSearchLoading(true);
        const startDate = new Date(startValue.$d).toISOString().split("T")[0];
        const endDate = new Date(endValue.$d).toISOString().split("T")[0];
        dispatch(allInventory());
        dispatch(allUnfulfilledOrders(startDate, endDate));
    };

    useEffect(() => {
        if (searchFilter !== "") {
            const filteredOrder = unfulfilled_orders.filter((x) =>
                String(x.order_number).startsWith(searchFilter)
            );
            // console.log(filteredOrder)
            if (filteredOrder) setOrders(filteredOrder);
        } else {
            setOrders(unfulfilled_orders);
        }
    }, [searchFilter, unfulfilled_orders]);

    return (
        <>
            {!auth ? (
                <RedirectLogin />
            ) : (
                <>
                    {(loading || seachLoading) && (
                      <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading || seachLoading}
                      >
                        <h1>Loading. Please Wait...</h1>
                      </Backdrop>
                    )}
                    <Box sx={{ display: "flex",flexDirection:"column", alignItems:"center", gap: 1 }}>
                        <h3>
                            Note: By Default Date Range Is Set For A Week From Today
                        </h3>
                        <Box sx={{display:"flex"}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                    label="Start Date"
                                    inputFormat="MM/DD/YYYY"
                                    value={startValue}
                                    onChange={handleStartChange}
                                    renderInput={(params) => <TextField sx={{bgcolor:"white"}} {...params} />}
                                />
                            
                                <DesktopDatePicker
                                    label="End Date"
                                    inputFormat="MM/DD/YYYY"
                                    value={endValue}
                                    maxDate={dayjs(
                                        new Date().setDate(new Date().getDate() + 1)
                                    )}
                                    onChange={handleLastChange}
                                    renderInput={(params) => <TextField sx={{bgcolor:"white",left:"10px"}} {...params} />}
                                />
                            </LocalizationProvider>
                            <Button
                                sx={{left:"20px"}}
                                variant="contained"
                                color="error"
                                onClick={handleFetchOrders}
                            >
                                Fetch Orders
                            </Button>
                        </Box>
                    </Box>
                    <br />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent:"center",
                        }}
                    >
                        <TextField
                            id="input-with-sx"
                            label="Search Order"
                            variant="outlined"
                            sx={{width:"400px",bgcolor:"white"}}
                            size="small"
                            onChange={(e) => setSearchFilter(e.target.value)}
                        />
                    </Box>
                    {orders.length > 0 ? (
                        <ManualOrderTakeout ordersData={orders} />
                    ) : (
                        <Box sx={{display:"flex",justifyContent:"center"}}>
                            <h3>No Orders Found</h3>
                        </Box>
                    )}
                </>
            )}
        </>
    );
};

export default ManualDispatch;
