import React, {useState, useEffect} from 'react'
import { AgChartsReact } from 'ag-charts-react';

  
const Category = ({data}) => {
    const [options, setOptions] = useState({
      data: [],
      // title: {
      //   text: "Categories Percentage",
      // },
      series: [
        {
          type: "pie",
          angleKey: "count",
          legendItemKey: "category",
          calloutLabelKey: "count",
        sectorLabelKey: "count",
        },
      ],
    });

    useEffect(() => {
      setOptions((prevOptions) => ({
        ...prevOptions,
        data: data,
      }));
    }, [data]);
  
    return <AgChartsReact options={options} />;
  };
  

export default Category;