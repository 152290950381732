import { Button, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { read, utils } from "xlsx";
import { updatePincodes } from "../../actions/ordersActions";

export const UpdatePincodes = () => {
  const [excelFile, setExcelFile] = useState([]);
  const dispatch = useDispatch();
  let selectedFile;
  useEffect(() => {
    if (excelFile.length > 0) {
      toast.success("Excel File Uploaded Successfully");
      console.log(excelFile);
    } else {
      toast.error("No Excel File Uploaded");
      console.log("No Excel File Uploaded");
    }
  }, [excelFile]);

  const handler = (e) => {
    selectedFile = e.target.files[0];
  };

  const handlerSubmit = () => {
    if (selectedFile) {
      setExcelFile([]);
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = (e) => {
        let data = e.target.result;
        let workbook = read(data, { type: "binary" });
        workbook.SheetNames.forEach((sheet) => {
          let rowObject = utils.sheet_to_json(workbook.Sheets[sheet], {
            raw: false,
          });
          setExcelFile((e) => [...e, ...rowObject.map((r) => r.Pincode)]);
        });
      };
    }
  };
  const handleUpdate = () => {
    console.log("here");
    dispatch(updatePincodes(excelFile));
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <TextField
          className="file"
          type={"file"}
          onChange={handler}
          inputProps={{ accept: ".xls,.xlsx,.csv,.xlsb" }}
        />
        <Stack direction={"row"} gap={1} sx={{ marginLeft: 1 }}>
          <Button variant="contained" color="error" onClick={handlerSubmit}>
            Upload File
          </Button>
          {/* <Button
            style={{ left: "10px" }}
            variant="contained"
            color="error"
            onClick={() => {
              setExcelFile([]);
            }}
          >
            Clear File
          </Button> */}
        </Stack>
      </div>
      <br />
      {excelFile.length > 0 ? (
        <Button variant={"contained"} onClick={handleUpdate}>
          Update Pincodes
        </Button>
      ) : (
        ""
      )}
    </>
  );
};
