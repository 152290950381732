import React, {useEffect} from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getcustomercareusernames } from '../../../actions/ordersActions';
import Category from './Chart/Pie';
import { Typography } from '@mui/material';

const CustomercareReplied = ({tickets}) => {
    const dispatch = useDispatch();
    const { customerCareUsers } = useSelector((state) => state.inventoryDetails);
    // console.log(customerCareUsers)
    useEffect(() => {
        if (customerCareUsers.length === 0 )
          dispatch(getcustomercareusernames()); 
      }, []);

      const Replies = customerCareUsers.reduce((acc,customerCare)=>{
        // console.log(customerCare)
        // console.log("tickets", tickets)
        const assignedTickets = tickets.filter(ticket => "executive_id" in ticket)
        const matched = assignedTickets.filter((ticket)=>ticket.executive_id.includes(customerCare));
        // console.log("matched", matched)
        const EveryCustomerCare = matched.filter(ticket => {
            return !!ticket.messages.find(m => {
                if(new Date(m.time).toISOString().split("T")[0] === new Date().toISOString().split("T")[0]){
                    return true;
                }
                else{
                    return false;
                }  
            }
            )
        })
        acc.push({
            category: customerCare,
            count: EveryCustomerCare.length
        })
        return acc
      },[])
      console.log("Replies",Replies)
      
  return (
    <>
        <div>Customer Care Replies</div>
        <Category data={Replies}/>
    </>
  )
}

export default CustomercareReplied