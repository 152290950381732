import React from "react";
import ReportTable from "./ReportTable";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  allShopifyInventory,
  allUnfulfilledOrders,
  getThreeUnfulfilledProductsFilteredData,
} from "../../../actions/ordersActions";
import { allProducts } from "../../../actions/productsActions";
import { allInventory } from "../../../actions/inventoryActions";
import { Skeleton } from "@mui/material";
import { Box, MenuItem, TextField } from "@mui/material";
import { REcreateOrdersTableData } from "../../../utils/helpers";
import { KeyboardOptionKeyOutlined } from "@mui/icons-material";

export default function SecondViewReport({ tableData, deliveredOrdersData }) {
  const [orders, setOrders] = useState([]);
  const [productsRE, setProductsRE] = useState([]);
  const [orderAge, setOrderAge] = useState(0);
  const [productAge, setProductAge] = useState(0);
  const [replacementUnfulfilledOrders, setReplacementUnfulfilledOrders] =
    useState([]);
  const [size, setSize] = useState({
    XS: 1,
    S: 2,
    M: 3,
    L: 4,
    XL: 4,
    34: 1,
    35: 2,
    36: 3,
    37: 4,
    38: 5,
    39: 6,
    40: 7,
    41: 8,
  });
  const [stockSize, setStockSize] = useState("All");
  const [categoryFilter, setCategoryFilter] = useState("Clothing");

  const { stock } = useSelector((state) => state.inventoryDetails);

  const auth = useSelector((state) => state.auth);
  const { allUnfulfilled, filtered3MProductsData } = useSelector(
    (state) => state.orderDetails
  );
  const products = useSelector((state) => state.productDetails.products);
  const inventory = useSelector((state) => state.inventoryDetails.stock);
  const shopifyInventory = useSelector(
    (state) => state.orderDetails.shopifyInventory
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (!auth) return;
    if (!allUnfulfilled.length > 0) dispatch(allUnfulfilledOrders());
    if (!filtered3MProductsData)
      dispatch(getThreeUnfulfilledProductsFilteredData());
    if (!products.length > 0) dispatch(allProducts());
    if (!inventory.length > 0) dispatch(allInventory());
    if (!shopifyInventory.length > 0 && auth) dispatch(allShopifyInventory());
  }, [dispatch, auth]);

  useEffect(() => {
    if (stock.length > 0) {
      const mappedOrders = tableData.map((o) => {
        const productsWithImages = o.products?.map((p) => {
          const found = stock.find((i) => i.SKU === p.sku);
          const url =
            found?.imageUrl ||
            "https://media.istockphoto.com/vectors/error-page-or-file-not-found-icon-vector-id924949200";
          return { ...p, url };
        });

        return {
          ...o,
          products: productsWithImages,
        };
      });
      setOrders(mappedOrders);
    } else {
      dispatch(allInventory());
    }
  }, [stock, tableData]);

  useEffect(() => {
    if (!orders.length > 0 || !allUnfulfilled.length > 0) return;
    const products = orders
      .reduce((acc, o) => {
        o.products?.forEach((p) => {
          const row = acc.find((ac) => ac.sku === p.sku);

          if (row) {
            row.value = row.value + 1;
            row.reason = [...row.reason, o.reason];
            row.modified_at = [...row.modified_at, o.modified_at];
            if (o.package_status === "R_DLVD") {
              row.reverseDelivered = row.reverseDelivered + 1;
            }

            if (o.type === "Exchange") {
              row.exchange = row.exchange + 1;
            } else {
              row.return = row.return + 1;
            }
            if (o.exchangeReason === "Size-Exchange") {
              row.sizeExchange = row.sizeExchange + 1;
            } else if (o.exchangeReason === "Damaged Product") {
              row.damagedProduct = row.damagedProduct + 1;
            } else {
              row.both = row.both + 1;
            }
            if (size[p.originalSize] > size[p.size]) {
              row.largeSize = row.largeSize + 1;
            } else if (size[p.originalSize] < size[p.size]) {
              row.smallSize = row.smallSize + 1;
            }
          } else {
            const newObj = {
              sku: p.sku,
              productId: p.id,
              value: 1,
              reverseDelivered: 0,
              url: p.url,
              modified_at: [o.modified_at],
              reason: [o.reason],
              exchange: 0,
              damagedProduct: 0,
              sizeExchange: 0,
              both: 0,
              return: 0,
              smallSize: 0,
              largeSize: 0,
              type: isNaN(p.originalSize) ? "Clothing" : "Footwear",
            };
            if (o.package_status === "R_DLVD") {
              newObj.reverseDelivered = 1;
            }
            if (o.type === "Exchange") {
              newObj.exchange = 1;
            } else {
              newObj.return = 1;
            }
            if (o.exchangeReason === "Size-Exchange") {
              newObj.sizeExchange = 1;
            } else if (o.exchangeReason === "Damaged Product") {
              newObj.damagedProduct = 1;
            } else {
              newObj.both = 1;
            }
            if (size[p.originalSize] > size[p.size]) {
              newObj.largeSize = 1;
            } else if (size[p.originalSize] < size[p.size]) {
              newObj.smallSize = 1;
            }
            acc.push(newObj);
          }
        });
        return acc;
      }, [])
      .sort((a, b) => b.value - a.value);

    const deliveredOrdersCount = deliveredOrdersData.reduce((acc, o) => {
      o.order_items?.forEach((p) => {
        const row = acc.find(
          (ac) => ac.sku.split(".")[0] === p.sku.split(".")[0]
        );
        if (row) {
          row.total = row.total + 1;
        } else {
          const newObj = {
            sku: p.sku.split(".")[0],
            total: 1,
          };
          acc.push(newObj);
        }
      });
      return acc;
    }, []);

    const finalObj = products.map((x) => {
      const findSKU = deliveredOrdersCount.find((p) => p.sku === x.sku);
      if (findSKU) return { ...x, totalDelivered: findSKU.total };
      else return x;
    });
    const replacementUnfulfilled = allUnfulfilled.filter((uo) => {
      const timeDiff = new Date() - new Date(uo.created_at);
      const age = timeDiff / (1000 * 60 * 60 * 24);
      return (
        String(uo.tags).toLowerCase().includes("replacement") && age >= orderAge
      );
    });
    setReplacementUnfulfilledOrders(replacementUnfulfilled);
    const finalObjWithUnfulfilledData = REcreateOrdersTableData(
      finalObj,
      replacementUnfulfilled
    );
    // console.log("final obj", finalObjWithUnfulfilledData);
    categoryFilter === ""
      ? setProductsRE(finalObjWithUnfulfilledData)
      : setProductsRE(
          finalObjWithUnfulfilledData.filter((x) => x.type === categoryFilter)
        );
    // categoryFilter === ""
    //   ? setProductsRE(finalObj)
    //   : setProductsRE(finalObj.filter((x) => x.type === categoryFilter));
  }, [
    orders,
    deliveredOrdersData,
    categoryFilter,
    allUnfulfilled,
    size,
    orderAge,
  ]);

  return (
    <>
      <div>
        {!allUnfulfilled.length > 0 ||
        !productsRE.length > 0 ||
        !products.length > 0 ||
        !inventory.length > 0 ? (
          <div>
            <Skeleton height={100} />
            <Skeleton height={100} />
            <Skeleton height={100} />
          </div>
        ) : (
          <div>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: 2,
                marginBottom: 3,
              }}
            >
              <TextField
                select
                label="Select Product Type"
                value={categoryFilter}
                onChange={(e) => setCategoryFilter(e.target.value)}
                variant="outlined"
                className="select"
                sx={{ width: "300px", bgcolor: "white" }}
                size="small"
              >
                {[
                  {
                    value: "Clothing",
                    label: "Clothing",
                  },
                  {
                    value: "Footwear",
                    label: "Footwear",
                  },
                ].map((option) => (
                  <MenuItem key={option.label} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                type="number"
                label="Product Age"
                value={productAge}
                onChange={(e) => setProductAge(e.target.value)}
                variant="outlined"
                className="select"
                sx={{ width: "300px", bgcolor: "white" }}
                size="small"
              />
              <TextField
                type="number"
                value={orderAge}
                onChange={(e) => setOrderAge(e.target.value)}
                label="Order Age"
                variant="outlined"
                className="select"
                sx={{ width: "300px", bgcolor: "white" }}
                size="small"
              />
              <TextField
                select
                label="Select Stock Size"
                value={stockSize}
                onChange={(e) => setStockSize(e.target.value)}
                variant="outlined"
                className="select"
                sx={{ width: "300px", bgcolor: "white" }}
                size="small"
              >
                {["All", "Finite", "Infinite"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <ReportTable
              productAge={productAge}
              productsRE={productsRE}
              tableData={tableData}
              deliveredOrdersData={deliveredOrdersData}
              stockSize={stockSize}
              productType={categoryFilter}
              replacementUnfulfilledOrders={replacementUnfulfilledOrders}
            />
          </div>
        )}
      </div>
    </>
  );
}
