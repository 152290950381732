import { toast } from "react-toastify";
import { lambdaFetch } from "../utils/awsUtils";
import { hideFullScreenLoader } from "./loaderActions";
import { sendREapprovedMessage } from "./reverseDeliveryActions";

export const hideLoader = () => {
  return {
    type: "HIDE_LOADER",
  };
};

export const sendRErejectedMessage = (email) => {
  // USE SAME AS XPRESSBEES
};

export const getMonthlyReversePendingData = (dataBody) => {
  //TODO
};

export const getMonthlyReverseOFPData = (dataBody) => {
  //TODO
};

export const getMonthlyReverseAttemptData = (dataBody) => {
  //TODO
};

export const getMonthlyReverseCancelledData = (dataBody) => {
  //TODO
};

export const getMonthlyReversePickedupData = (dataBody) => {
  //TODO
};

//TODO
export const getMonthlyReverseIntransitData = (dataBody) => {
  async function getData() {
    const URL = "";

    let fetching = true;
    let orders = [];
    let key = null;
    while (fetching) {
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: {}, //TODO
      });
      if (response.status === 403) {
        fetching = false;
        return 403;
      }
      const data = await response.json();
      if (data.statusCode === 200) {
        const processingData = data.body;
        orders.push(...processingData);
        key = data.key;
        if (!key) {
          fetching = false;
        }
      } else {
        orders = [];
        fetching = false;
      }
    }
    return orders;
  }
  return (dispatch) => {
    // dispatch(showLoading());
    getData()
      .then((data) => {
        if (data === 403) {
          // console.log("rto delivered orders", data);
          // dispatch(showUnauthorizedModal());
          return;
        }
        if (data) {
          dispatch(getMonthlyReverseRadData(dataBody));
          dispatch({
            type: "MONTHLY_REVERSE_PICKED_ORDERS",
            data,
          });
        } else {
          console.log("Error In Getting Reverse Intransit Orders");
          dispatch(hideLoader());
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideLoader());
      });
  };
};

//TODO
export const getMonthlyReverseRadData = (dataBody) => {
  async function getData() {
    const URL = "";

    let fetching = true;
    let orders = [];
    let key = null;
    while (fetching) {
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: {}, //TODO
      });
      if (response.status === 403) {
        fetching = false;
        return 403;
      }
      const data = await response.json();
      if (data.statusCode === 200) {
        const processingData = data.body;
        orders.push(...processingData);
        key = data.key;
        if (!key) {
          fetching = false;
        }
      } else {
        orders = [];
        fetching = false;
      }
    }
    return orders;
  }
  return (dispatch) => {
    // dispatch(showLoading());
    getData()
      .then((data) => {
        if (data === 403) {
          // console.log("rto delivered orders", data);
          // dispatch(showUnauthorizedModal());
          return;
        }
        if (data) {
          dispatch(getMonthlyReverseOfdData(dataBody));
          dispatch({
            type: "MONTHLY_REVERSE_PICKED_ORDERS",
            data,
          });
        } else {
          console.log("Error In Getting Reverse RAD Orders");
          dispatch(hideLoader());
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideLoader());
      });
  };
};

export const getMonthlyReverseOfdData = (dataBody) => {
  //TODO
};

export const getMonthlyReverseDeliveredData = (dataBody) => {
  async function getData() {
    const URL = "";

    let fetching = true;
    let orders = [];
    let key = null;
    while (fetching) {
      const response = await lambdaFetch(URL, {
        method: "POST",
        body: {}, //TODO
      });
      if (response.status === 403) {
        fetching = false;
        return 403;
      }
      const data = await response.json();
      if (data.statusCode === 200) {
        const processingData = data.body;
        orders.push(...processingData);
        key = data.key;
        if (!key) {
          fetching = false;
        }
      } else {
        orders = [];
        fetching = false;
      }
    }
    return orders;
  }
  return (dispatch) => {
    // dispatch(showLoading());
    getData()
      .then((data) => {
        if (data === 403) {
          // console.log("rto delivered orders", data);
          // dispatch(showUnauthorizedModal());
          return;
        }
        if (data) {
          dispatch({
            type: "MONTHLY_REVERSE_DELIVERED_ORDERS",
            data,
          });
        } else {
          console.log("Error In Getting Reverse Delivered Orders");
          dispatch(hideLoader());
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideLoader());
      });
  };
};

export const clearReverseDataByStatus = () => {
  return {
    type: "CLEAR_REVERSE",
  };
};

export const changeFilteredOrdersArray = (payload) => {
  return {
    type: "CHANGE_FILTERED_ORDERS",
    payload: payload,
  };
};

export const getReverseDataByStatus = (orderBody) => {
  //SAME AS EXPRESSBEES??? Check lambda
};

export const clearReverseReportData = () => {
  return {
    type: "CLEAR_REVERSE_REPORT",
  };
};

export const getReverseDataByStatusReport = (orderBody) => {
  //SAME AS EXPRESSBEES??? Check lambda
};

export const clearForwardReportData = () => {
  return {
    type: "CLEAR_FORWARD_REPORT",
  };
};

export const getForwardDataByStatusReport = (orderBody) => {
  //TODO
};

export const getReverseDeliveredDataByStatus = (orderBody) => {
  //TODO
};

export const getReverseAWBFromDelhivery = async () => {
  //TODO
};

export const postDelhiveryReverseOrder =
  (payload, email, orderId, comment) => async (dispatch) => {
    const Url =
      "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/delhivery/createshipment";
    console.log("to send", { payload, comment });
    try {
      const response = await lambdaFetch(Url, {
        method: "POST",
        body: JSON.stringify({ payload, comment }),
      });
      const data = await response.json();
      if (data.statusCode === 200) {
        console.log("success reverse", data.body);
        toast.success("Approved Successfully");
        dispatch({
          type: "UPDATE_REVERSE_BY_STATUS",
          payload: orderId,
        });
        if (email) {
          dispatch(sendREapprovedMessage(email));
        } else {
          alert("Email not provided");
        }

        dispatch(hideFullScreenLoader());
      } else if (data.statusCode === 400) {
        alert("Failed");
        dispatch(hideFullScreenLoader());
      } else {
        console.log("Error", data.body);
        const packages = data.body.packages[0].remarks[0];
        alert(packages);
        dispatch(hideFullScreenLoader());
      }
    } catch (error) {
      console.log("error", error);
      alert("Server Side Error");
      dispatch(hideFullScreenLoader());
    }
  };

export const denyRErequest = (payload, email, orderId) => async (dispatch) => {
  // SAME AS XPRESSBEES
};

export const reinstateRErequest = (id) => async (dispatch) => {
  // SAME AS XPRESSBEES
};

export const updateReverseOrder = (orderBody) => async (dispatch) => {
  //TODO, for reference see xpressbees implementations
};

export const approveReverseDeliveredOrders = (orderBody, id) => {
  // SAME AS XB
};

export const replenishReverseOrderStockInInventory =
  (id) => async (dispatch) => {
    // SAME AS XB
  };

export const updateDamagedReverseOrders = (orderBody, toBeUpdatedBody) => {
  //SAME AS XB
};

export const replenishDamagedReverseOrder = (orderBody) => async (dispatch) => {
  // SAME AS XB
};

export const getSingleReverseOrderDetails = (orderBody) => async (dispatch) => {
  //SAME AS XB
};

export const getSingleReverseOrder = async (id) => {
  //SAME AS XB
};

export const updateMoveToVoucher = (id) => (dispatch) => {
  dispatch({
    type: "UPDATE_REVERSE_BY_STATUS_TO_VOUCHER",
    payload: id,
  });
  dispatch(hideFullScreenLoader());
};

export const updateMoveToReplacement = (id) => (dispatch) => {
  dispatch({
    type: "UPDATE_REVERSE_BY_STATUS_TO_REPLACEMENT",
    payload: id,
  });
  dispatch(hideFullScreenLoader());
};

export const updateMoveToGiftcard = (id) => (dispatch) => {
  dispatch({
    type: "UPDATE_REVERSE_BY_STATUS_TO_GIFTCARD",
    payload: id,
  });
  dispatch(hideFullScreenLoader());
};

export const reinstateAndDeleteAwbRE = (id, awb) => async (dispatch) => {
  // SAME AS XB
};

export const moveToDeleted = (id, awb, reason) => async (dispatch) => {
  // SAME AS XB
};

export const getReverseDataByStatusModified =
  (orderBody) => async (dispatch) => {
    // SAME AS XB
  };
