import { Badge, Button, Modal, Stack, Typography } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { selectPOProducts } from "../../actions/POActions";
import { useDispatch, useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/Cancel";
import POCheckoutManual from "./POCheckoutManual";

const POCartManual = ({ poId }) => {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "100%",
        bgcolor: "#ffffff",
        boxShadow: 24,
        p: 4,
        overflow: "scroll",
    };
    const selectedProducts = useSelector((state) => state.PO.products);
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleCartClick = (product) => {
        const filtered = selectedProducts.filter((i) => i.SKU !== product.SKU);
        console.log("filtered", filtered);
        dispatch(selectPOProducts(filtered));
        localStorage.setItem("selectedProducts", JSON.stringify(filtered));
    };
    return (
        <Box>
            <Stack spacing={4} direction="row">
                <Badge badgeContent={selectedProducts.length} color="secondary">
                    <Button
                        variant="contained"
                        endIcon={<ShoppingCartIcon />}
                        onClick={() => handleOpen()}
                        disabled={selectedProducts.length === 0}
                    >
                        Check Cart
                    </Button>
                </Badge>
                {/* <Button variant="outlined">CheckOut</Button> */}
            </Stack>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {/* {selectedProducts.map((i, index) => (
            <Stack gap={1} direction="row">
              <Typography gutterBottom variant="h6" component="div">
                {`${index + 1}. ${i.SKU}`}
              </Typography>
              <DeleteIcon onClick={() => handleCartClick(i)} />
            </Stack>
          ))} */}
                    <Stack gap={2}>
                        <CancelIcon
                            sx={{
                                float: "right",
                                alignSelf: "end",
                                fontSize: 30,
                                "&:hover": {
                                    color: "red",
                                    cursor: "pointer",
                                },
                            }}
                            onClick={() => setOpen(false)}
                        />
                        <POCheckoutManual setOpen={setOpen} poId={poId} />
                    </Stack>
                </Box>
            </Modal>
        </Box>
    );
};

export default POCartManual;
