const initialState = {
  tickets: [],
  oldTickets: [],
  executiveTickets: [],
  singleTicket: {},
  retryLoading: false,
  openRetryBox: false,
  priorityLoading: false,
};

const ticketReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_TICKETS":
      return {
        ...state,
        tickets: action.data,
      };
    case "OLD_TICKETS":
      return {
        ...state,
        oldTickets: action.data,
      };
    case "EXECUTIVE_TICKETS":
      return {
        ...state,
        executiveTickets: action.data,
      };
    case "SINGLE_TICKET":
      return {
        ...state,
        singleTicket: action.data,
      };
    case "TICKET_RESPONSE_RETRY":
      return {
        ...state,
        retryLoading: action.data,
      };
    case "OPEN_RETRY_BOX":
      return {
        ...state,
        openRetryBox: action.data,
      };
    case "SHOW_PRIORITY_LOADER":
      return {
        ...state,
        priorityLoading: true,
      };
    case "HIDE_PRIORITY_LOADER":
      return {
        ...state,
        priorityLoading: false,
      };
    default:
      return state;
  }
};

export default ticketReducer;
