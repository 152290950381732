import React, { useState } from "react";
import styled from "@emotion/styled";
import MuiTextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
const ProcessingTextField = styled(MuiTextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    paddingLeft: 0,
  },
  "& .MuiInputAdornment-root": {
    backgroundColor: "lightblue",
    padding: "28px 14px",
    borderTopLeftRadius: "3px",
    borderBottomLeftRadius: "3px",
  },
}));
const VendorWiseQntyInput = ({
  size,
  vendorId,
  enteredQnty,
  setEnteredQnty,
}) => {
  const handleChange = (event, size) => {
    console.log("changes", enteredQnty, vendorId);
    const value = parseInt(event.target.value);
    if (isNaN(value)) {
      setEnteredQnty((prev) => ({
        ...prev,
        [vendorId]: {
          ...prev[vendorId],
          [size]: 0,
        },
      }));
      // const oldQnty = { ...enteredQnty };
      // oldQnty[vendorId][size] = 0;
      // setEnteredQnty(oldQnty);
    } else {
      setEnteredQnty((prev) => ({
        ...prev,
        [vendorId]: {
          ...prev[vendorId],
          [size]: value,
        },
      }));
      // const oldQnty = { ...enteredQnty };
      // console.log("old", oldQnty);
      // oldQnty[vendorId][size] = value;
      // console.log("old qnty", oldQnty, vendorId);
      // setEnteredQnty(oldQnty);
    }
  };
  return (
    <div>
      <ProcessingTextField
        size="small"
        sx={{ width: "150px" }}
        variant="outlined"
        value={enteredQnty[vendorId][size]}
        onChange={(e) => handleChange(e, size)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <h3
                style={{
                  textAlign: "center",
                  color: "black",
                }}
              >
                {size}
              </h3>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default VendorWiseQntyInput;
