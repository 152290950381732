import { Box } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState, useEffect } from "react";
import { templates } from "../../utils/ticketTemplate";

const Template = ({ category, question, setMessage }) => {
  const CategoryArray = [
    "All",
    "Exchanges",
    "Delivery & Order Status",
    "Order Issues",
    "Payment & Vouchers",
  ];

  const returnExchange = [
    "All",
    "How to initiate the exchange?",
    "I want to exchange but I'm unable to do it",
    "When will my pickup be done?",
    "Where is my Credit voucher/Gift card?",
    "I'm unable to use my Gift card/credit voucher",
    "Why is my pickup not yet done?",
  ];

  const deliveryOrder = [
    "All",
    "How can I track my order?",
    "I want to return my order",
    "Where is my order?",
    "Is COD Available or not?",
  ];

  const orderIssues = [
    "All",
    "How do I cancel my order?",
    "I'm unable to place my order",
    "I have ordered the wrong product?",
  ];

  const paymentVoucher = [
    "All",
    "How to apply voucher/giftcard?",
    "How do I pay for my order?",
    "What is the validity of the giftcard?",
  ];

  const [messageTemplates, setMessageTemplates] = useState([]);

  useEffect(() => {
    const findTemplate = templates.filter((t) => category === t.category);
    if (findTemplate) {
      setMessageTemplates(findTemplate[0].template);
    }
  }, [category]);

  return (
    <Stack
      direction={"column"}
      gap={2}
      className="scrollbar"
      sx={{
        height: "100%",
        overflowX: "hidden",
        overflowY: "overlay",
        paddingRight: "15px",
      }}
    >
      {messageTemplates.map((m) => (
        <Box
          onClick={() => {
            setMessage(m.message);
          }}
          sx={{
            position: "relative",
            padding: "15px",
            textAlign: "center",
            bgcolor: "lightblue",
            borderRadius: "7px",
            "& h5": {
              padding: 0,
              margin: 0,
            },
            "&:hover": {
              cursor: "pointer",
              transform: "scale(1.02)",
            },
          }}
        >
          <h5>{m.name}</h5>
        </Box>
      ))}
    </Stack>
  );
};

export default Template;
