export const showLoading = () => {
  return (dispatch) => {
    dispatch({
      type: "SHOW_LOADER",
    });
  };
};
export const showLoader = () => {
  return (dispatch) => {
    dispatch({
      type: "SHOW_LOADER",
    });
  };
};
export const hideLoading = () => {
  return (dispatch) => {
    dispatch({
      type: "HIDE_LOADER",
    });
  };
};
export const hideLoader = () => {
  return (dispatch) => {
    dispatch({
      type: "HIDE_LOADER",
    });
  };
};
export const hideSuccess = () => {
  return (dispatch) => {
    dispatch({
      type: "HIDE_SUCCESS",
    });
  };
};
export const showSuccess = () => {
  return (dispatch) => {
    dispatch({
      type: "SHOW_SUCCESS",
    });
  };
};

export const hideFailure = () => {
  return (dispatch) => {
    dispatch({
      type: "HIDE_FAILURE",
    });
  };
};
export const showFailure = () => {
  return (dispatch) => {
    dispatch({
      type: "SHOW_FAILURE",
    });
  };
};

export const showFullScreenLoader = () => {
  return (dispatch) => {
    dispatch({
      type: "SHOW_LOADER",
    });
  };
};

export const hideFullScreenLoader = () => {
  return (dispatch) => {
      dispatch({
      type: "HIDE_LOADER",
      });
  };
};