import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import { getSizesByType } from "../../utils/helperArrays";

const CheckoutTable = ({ data }) => {
    const [tableData, setTableData] = useState([]);
    useEffect(() => {
        // split category wise
        const categoryWise = data.products.reduce((acc, row) => {
            const found = acc.find((ac) => ac.type === row.type);
            if (found) {
                found.products = [...found.products, row];
            } else {
                const newObj = {
                    type: row.type,
                    sizes: getSizesByType(row.type),
                    products: [row],
                };
                acc.push(newObj);
            }
            return acc;
        }, []);
        setTableData(categoryWise);
        // console.log("category wise", categoryWise);
    }, [data]);

    return (
        <div>
            <h3>
                Vendor : <span style={{ color: "red" }}>{data.vendor}</span>
            </h3>
            {tableData.map((td, index) => (
                <div key={index}>
                    <h3>{td.type}</h3>
                    <TableContainer component={Paper} sx={{ mb: "5px" }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>SKU</TableCell>
                                    <TableCell>Name</TableCell>
                                    {td.sizes.map((s, i) => (
                                        <TableCell key={i}>{s}</TableCell>
                                    ))}
                                    <TableCell>Total Quantity</TableCell>
                                    <TableCell>Material</TableCell>
                                    <TableCell>Total Material Amt</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {td.products.map((row) => (
                                    <React.Fragment key={row.id}>
                                        <TableRow>
                                            <TableCell>{row.sku}</TableCell>
                                            <TableCell>{row.name}</TableCell>
                                            {/* size wise po quantity */}
                                            {td.sizes.map((s, i) => (
                                                <TableCell key={i}>
                                                    {row.poQuantity[s]
                                                        ? row.poQuantity[s]
                                                        : "0"}
                                                </TableCell>
                                            ))}
                                            <TableCell>
                                                {row.totalPOQuantity}
                                            </TableCell>
                                            {row.material.length > 0 ? (
                                                <React.Fragment>
                                                    <TableCell>
                                                        <div>
                                                            {row.material.map(
                                                                (m, i) => (
                                                                    <p
                                                                        key={i}
                                                                    >{`${m.type} ${m.color} `}</p>
                                                                )
                                                            )}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell>
                                                        <div>
                                                            {row.material.map(
                                                                (m, i) => (
                                                                    <p key={i}>
                                                                        {
                                                                            m.amount
                                                                        }
                                                                    </p>
                                                                )
                                                            )}
                                                        </div>
                                                    </TableCell>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <TableCell>
                                                        Not found
                                                    </TableCell>
                                                    <TableCell>
                                                        Not found
                                                    </TableCell>
                                                </React.Fragment>
                                            )}
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            ))}
        </div>
    );
};

export default CheckoutTable;
