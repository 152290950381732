import { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";

import { allInventory } from "../../actions/inventoryActions";
import RejectedREDetails from "./RejectedREDetails";

function Row(props) {
  const { order } = props;

  const [selectedOrder, setSelectedOrder] = useState(null);

  return (
    <Fragment>
      <div key={order.fulfillment_id} className="flexRow">
        <h4>Order No. {order.orderNumber}</h4>
        <h4>Type: {order.type}</h4>
        <h4>Rejected On: {order.modified_at}</h4>
        {!selectedOrder ? (
          <Button variant="outlined" onClick={() => setSelectedOrder(order)}>
            View
          </Button>
        ) : (
          <Button
            variant="outlined"
            color="error"
            onClick={() => setSelectedOrder(null)}
          >
            Close
          </Button>
        )}
      </div>
      {selectedOrder && (
        <div className="orderDetailsBox">
          <div className="flexRow">
            <h1>{selectedOrder.orderNumber}</h1>
            <Button variant="contained" onClick={() => setSelectedOrder(null)}>
              Clear
            </Button>
          </div>
          <RejectedREDetails
            order={selectedOrder}
            clear={() => setSelectedOrder(null)}
          />
        </div>
      )}
    </Fragment>
  );
}

const RejectedREOrders = (props) => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { stock } = useSelector((state) => state.inventoryDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    if (stock.length > 0) {
      const mappedOrders = props.orders.map((o) => {
        const productsWithImages = o.products.map((p) => {
          const found = stock.find((i) => i.SKU === p.sku);
          const url =
            found?.imageUrl ||
            "https://media.istockphoto.com/vectors/error-page-or-file-not-found-icon-vector-id924949200";
          return { ...p, url };
        });

        return {
          ...o,
          products: productsWithImages,
        };
      });
      setOrders(mappedOrders);
    } else {
      dispatch(allInventory());
    }
  }, [stock, props.orders]);

  return (
    <div>
      {orders.map((o) => (
        <Row order={o} />
      ))}
    </div>
  );
};

export default RejectedREOrders;
