import * as React from 'react';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import RedirectLogin from "../RedirectLogin";
import RtoOrdersTable from '../reportWidgets/RtoOrdersTable';
import { months } from "../../utils/helperArrays";
import { getAllShipRocketOrders } from "../../actions/reportActions";

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';

import { TextField, MenuItem } from "@mui/material";
import ProgressBar from "../../ProgressBar";
import "./index.css";

const RtoOrders = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const gstOrders = useSelector((state) => state.reports.shiprocket_orders)
  // console.log("gst",gstOrders)
  const loading = useSelector((state) => state.reports.loading)
  const [value, setValue] = useState("");
  const [month, setMonth] = useState("")
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    setTableData([])
    if(month !== ""){
      const date = {
        year : new Date(value._d).getFullYear(),
        month : month
      }
      dispatch(getAllShipRocketOrders(date))
    }
  },[month])

  useEffect(() => {
    if(gstOrders.length > 0){
      const filteredData = gstOrders.filter(item => item.status === "RTO DELIVERED")
      console.log(filteredData)
      setTableData(filteredData)
    }
  },[gstOrders,month,value])

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
      <>
        {loading ? (
          <ProgressBar/>
        ) : (
          <>
            <br/>
            <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              views={['year']}
              label="Enter Year"
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={(params) => <TextField {...params} helperText={null} />}
            />
            </LocalizationProvider>
            <br/>
            <br/>
            <TextField
              style={{width:"300px"}}
              select
              label="Select Month"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
              variant="outlined"
              className="select"
            >
              {months.map((item) => (
                <MenuItem key={item} value={months.indexOf(item)}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
            <br/>
            <br/>
            {tableData.length > 0 && (
              <RtoOrdersTable tableData={tableData}/>
            )}
          </>
        )}
      </>
      )}
    </>
  );
};

export default RtoOrders;
