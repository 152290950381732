import { useDispatch } from "react-redux";
import { Button } from "@mui/material";

const DispatchedREDetails = ({ order, clear }) => {
  const dispatch = useDispatch();

  return (
    <div>
      <div className="flexRow">
        <h4>Order No: {order.orderNumber}</h4>
        <h5>Type: {order.type}</h5>
        <h5>AWB: {order.awb_code}</h5>
        <h5>Approved On: {order.modified_at}</h5>
        <h5>Name: {order.address.name}</h5>
        <h5>
          Contact: {order.address?.phone} | {order.alternatePhone}
        </h5>
      </div>

      <div className="flexRow">
        {order.products.map((p) => (
          <div key={p.id} className="exchangePSpecs">
            <img src={p.url} alt="LBI" width="100" />
            <h5>SKU: {p.sku}</h5>
            {order.type === "Exchange" ? (
              <div>
                <h5>Original Size: {p.originalSize}</h5>
                <h5>Requested Size: {p.size}</h5>
              </div>
            ) : (
              <h5>Size: {p.originalSize}</h5>
            )}
          </div>
        ))}
      </div>
      <div>
        {order.type === "Exchange" ? (
          <>
            <h4>Exchange Type: {order.exchangeReason}</h4>
            <h4>Reason:</h4>
          </>
        ) : (
          <>
            <h4>Return reason</h4>
          </>
        )}
        <p>{order.reason}</p>
      </div>
      <div className="flexRow">
        {order.files?.length > 0 && (
          <div>
            <h4>Customer Pictures:</h4>
            <div className="flexRow">
              {order.files?.map((f) => (
                <div key={f.id}>
                  <a href={f.url} target="_blanck">
                    <img
                      src={`https://drive.google.com/uc?export=view&id=${f.id}`}
                      alt={f.name}
                      width="200"
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DispatchedREDetails;
