import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Button,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    TableFooter,
    TablePagination,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePaginationActions from "../../utils/TablePaginationActions";

const AwbNotReadyTable = ({tableData}) => {
  const loading = useSelector((state) => state.loader.loading);
  const [data, setData] = useState(
    tableData?.sort(
        (a, b) => Number(a.original_order_id) - Number(b.original_order_id)
        )
    );

    const [open, setOpen] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
  
    useEffect(() => {
        if (tableData.length > 0) {
            setOpen(Array(tableData.length).fill(false));
        }
        setData(
            [...tableData]
                ?.sort(
                    (a, b) =>
                        Number(a.original_order_id) -
                        Number(b.original_order_id)
                )
                .reverse()
        );
    }, [tableData]);

  return (
    <>
    <br/>
    <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
            <TableHead>
                <TableRow>
                    <TableCell align="right">
                        Order Number
                    </TableCell>
                    <TableCell align="right">Order Platform</TableCell>
                    <TableCell align="right">Shipping</TableCell>
                    <TableCell align="right">Shipment Id</TableCell>
                    <TableCell align="right">Order Id</TableCell>
                    <TableCell align="right">Package Status</TableCell>
                    <TableCell align="right">Product</TableCell>
                    <TableCell align="left">List</TableCell>
                    <TableCell align="left">Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {(rowsPerPage > 0
                    ? data.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                        )
                    : data
                ).map((row, index) => (
                    <React.Fragment key={index}>
                        <TableRow
                            key={index}
                            sx={{
                                bgcolor:
                                    row.status === "fulfilled"
                                        ? "rgba(145,145,145, 0.2)"
                                        : index % 2 === 0
                                        ? "#afd275"
                                        : "#edf5e1",

                                "& > *": {
                                    borderBottom: "unset",
                                },
                            }}
                        >
                            <TableCell align="right">
                                {row.original_order_id} (#
                                {row.fulfillment_id})
                            </TableCell>
                            <TableCell align="right" sx={{
                                    fontWeight: "bold",
                                    color: "red",
                                }}>{row.tags ? row.tags : "Shopify"}</TableCell>
                            <TableCell align="right">
                                {row.shipment_id}
                            </TableCell>
                            <TableCell align="right">
                                {row.order_id}
                            </TableCell>
                            <TableCell align="right">
                                {row.payment_method}
                            </TableCell>
                            <TableCell
                                sx={{
                                    fontWeight: "bold",
                                    color: "blue",
                                }}
                                align="right"
                            >
                                {row.package_status}
                            </TableCell>
                            <TableCell align="right">
                                {row.order_items.length} Items
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => {
                                        const temp = open.map(
                                            (i, openIndex) => {
                                                if (
                                                    index !==
                                                    openIndex
                                                ) {
                                                    return i;
                                                } else {
                                                    return !i;
                                                }
                                            }
                                        );
                                        setOpen(temp);
                                    }}
                                >
                                    {open[index] ? (
                                        <KeyboardArrowUpIcon />
                                    ) : (
                                        <KeyboardArrowDownIcon />
                                    )}
                                </IconButton>
                            </TableCell>
                            <TableCell align="left">
                                <Button disabled = { !row.shipment_id || !row.order_id } variant="contained">Ship Again</Button>
                                <Button variant="contained" color="error" style={{left: "10px"}}>Cancel Order</Button>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                style={{
                                    paddingBottom: 0,
                                    paddingTop: 0,
                                }}
                                colSpan={6}
                            >
                                <Collapse
                                    in={open[index]}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <Box sx={{ margin: 1 }}>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            component="div"
                                        >
                                            Product Details
                                        </Typography>
                                        <Table
                                            size="small"
                                            aria-label="purchases"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Name
                                                    </TableCell>
                                                    <TableCell>
                                                        SKU
                                                    </TableCell>
                                                    <TableCell>
                                                        Size
                                                    </TableCell>
                                                    <TableCell>
                                                        Quantity
                                                    </TableCell>
                                                    <TableCell>
                                                        MRP
                                                    </TableCell>
                                                    <TableCell>
                                                        Discount
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {row.order_items.map(
                                                    (
                                                        productRow
                                                    ) => (
                                                        <TableRow
                                                            key={
                                                                productRow.sku
                                                            }
                                                        >
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                            >
                                                                {
                                                                    productRow.name
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    productRow.sku
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    productRow.variant_title
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    productRow.units
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    productRow.selling_price
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    productRow.discount
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                        <h4>
                                            Shipping Address:
                                        </h4>
                                        <p>
                                            Name:{" "}
                                            {row.billing_customer_name +
                                                " " +
                                                row.billing_last_name}
                                            <br />
                                            Mobile:{" "}
                                            {row.shipping_phone}
                                            <br />
                                            Address:{" "}
                                            {
                                                row.shipping_address
                                            }
                                            <br />
                                            {
                                                row.shipping_address_2
                                            }
                                            <br />
                                            {row.shipping_city}
                                            <br />
                                            {
                                                row.shipping_country
                                            }
                                            <br />
                                            {
                                                row.shipping_pincode
                                            }
                                        </p>
                                    </Box>
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    </React.Fragment>
                ))}

                {emptyRows > 0 && (
                    <TableRow
                        style={{ height: 53 * emptyRows }}
                    >
                        <TableCell colSpan={6} />
                    </TableRow>
                )}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        style={{ overflow: "inherit" }}
                        rowsPerPageOptions={[
                            5,
                            10,
                            25,
                            { label: "All", value: -1 },
                        ]}
                        colSpan={3}
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: {
                                "aria-label": "rows per page",
                            },
                            native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={
                            handleChangeRowsPerPage
                        }
                        ActionsComponent={
                            TablePaginationActions
                        }
                    />
                </TableRow>
            </TableFooter>
        </Table>
    </TableContainer>
    </>
  )
}

export default AwbNotReadyTable