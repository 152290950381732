import { Container, MenuItem, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    productTypes,
    footwearCategories,
    clothCategories,
    denimCategories,
    menShoesCategories,
    clothingVendor,
    footwearVendor,
    denimVendor,
    menShoesVendor,
} from "../../utils/helperArrays";
import POShopManual from "./POShopManual";
import { LocalStorageModal } from "./LocalStorageModal";

const CreatePurchaseOrderManual = () => {
    let initial;

    initial = localStorage?.getItem("selectedProducts")
        ? JSON.parse(localStorage.getItem("selectedProducts"))
        : [];
    const [inventoryChoosen, setInventoryChosen] = useState([]);
    const [productType, setProductType] = useState("");
    const [productCategory, setProductCategory] = useState([]);
    const [productCategoryChosen, setProductCategoryChosen] = useState("");
    const [vendors, setVendors] = useState([]);
    const [vendorChosen, setVendorChosen] = useState("");
    const [localStoragePrompt, setLocalStoragePrompt] = useState(false);

    const inventory = useSelector((state) => state.inventoryDetails.stock);
    const selectedProducts = useSelector((state) => state.PO.products);
    console.log(selectedProducts);

    const handleProductChange = (type) => {
        if (selectedProducts.length > 0) {
            setLocalStoragePrompt(true);
        } else {
            setProductType(type);
            setProductCategory([]);
            setVendors([]);
            setProductCategoryChosen("");
            setVendorChosen("");

            switch (type) {
                case "Clothing":
                    setProductCategory(clothCategories);
                    setVendors(clothingVendor);
                    break;
                case "Shoes":
                    setProductCategory(footwearCategories);
                    setVendors(footwearVendor);
                    break;
                case "Pants":
                    setProductCategory(denimCategories);
                    setVendors(denimVendor);
                    break;
                case "Men Shoes":
                    setProductCategory(menShoesCategories);
                    setVendors(menShoesVendor);
                    break;
                default:
                    setProductCategory([]);
                    setProductCategoryChosen("");
                    setVendorChosen("");
                    break;
            }
        }
    };

    useEffect(() => {
        if (productType && productCategoryChosen && vendorChosen) {
            const datatobeshown = inventory.filter(
                (i) =>
                    i.category === productCategoryChosen &&
                    i.type === productType &&
                    i.vendor === vendorChosen
            );
            setInventoryChosen(datatobeshown);
        } else {
            setInventoryChosen([]);
        }
    }, [productCategoryChosen, productType, vendorChosen]);

    const handleSelectedItemsPrompt = () => {};

    return (
        <Container
            sx={{
                marginTop: 5,
                background: "ghostwhite",
                padding: "24px",
                borderRadius: "15px",
            }}
        >
            <Stack direction={"row"} gap={2}>
                <TextField
                    select
                    label="Select Product Type"
                    value={productType}
                    onChange={(e) => {
                        handleProductChange(e.target.value);
                    }}
                    variant="outlined"
                    className="select"
                    fullWidth
                    style={{ background: "white" }}
                    size="small"
                >
                    {productTypes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    select
                    label="Select Category"
                    value={productCategoryChosen}
                    onChange={(e) => setProductCategoryChosen(e.target.value)}
                    variant="outlined"
                    className="select"
                    fullWidth
                    style={{ background: "white" }}
                    size="small"
                >
                    {productCategory.map((option) => (
                        <MenuItem key={option.value} value={option.label}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    select
                    label="Select Vendor"
                    value={vendorChosen}
                    onChange={(e) => setVendorChosen(e.target.value)}
                    variant="outlined"
                    className="select"
                    fullWidth
                    style={{ background: "white" }}
                    size="small"
                >
                    {vendors.map((option) => (
                        <MenuItem key={option.value} value={option.label}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Stack>
            {/* temporary */}
            <POShopManual inventory={inventoryChoosen} />
            {localStoragePrompt ? (
                <LocalStorageModal
                    open={localStoragePrompt}
                    setLocalStoragePrompt={setLocalStoragePrompt}
                />
            ) : (
                ""
            )}
        </Container>
    );
};

export default CreatePurchaseOrderManual;
