import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getDynamoNdrOnholdOrdersData } from '../../actions/ordersActions';
import NdrOnHoldFilter from '../ordersWidgets/NdrOnHoldFilter';
import RedirectLogin from "../RedirectLogin";
import "./index.css";
import ProgressBar from "../../ProgressBar";
import NdrOnHoldTable from '../ordersWidgets/NdrOnHoldTable';

const NdrOnHold = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const xbNdrOnholdOrders = useSelector(state => state.orderDetails.xbNdrOnholdOrders)
  const loading = useSelector(state => state.orderDetails.loading)
  
  const [ orders, setOrders] = useState([])
  console.log("order",orders)

  const reqDateFormat = (date) => {
    const tempDate = date.split("-")
    const reqDate = [tempDate[1],tempDate[0],tempDate[2]].join("/")
    return reqDate
  }

  useEffect(() => {
    if (!auth) return;
    if (!xbNdrOnholdOrders.length > 0) dispatch(getDynamoNdrOnholdOrdersData());
  }, [dispatch, auth]);

  useEffect(() => {
    if(xbNdrOnholdOrders.length>0){
      const formattedOrders = xbNdrOnholdOrders
      .filter(x => !!x.last_ndr_details)
      .filter(x => !!x.total_ndr_attempts)
      .map(x => {
        const newObj = {
          ...x.last_ndr_details,
          ndr_history : x.ndr_history ? x.ndr_history : [],
          POID : String(x.fulfillment_id),
          PendingDays : (new Date(reqDateFormat(x.last_ndr_details.StatusDate)).getTime() - new Date(x.sync_date).getTime())/(1000 * 60 * 60 * 24),
          TotalAttempt : x.total_ndr_attempts,
          customer_name : x.shipping_customer_name + " " + x.shipping_last_name,
          customer_email : x.billing_email,
          customer_address : x.shipping_address + " " + x.shipping_address_2,
          customer_city : x.shipping_city,
          customer_state : x.shipping_state,
          customer_pincode : x.shipping_pincode,
          customer_phone : x.shipping_phone,
          order_items : x.order_items,
          order_original_id : x.id,
          executive_id : x.executive_id === "1" ? "Not Assigned" : x.executive_id,
          total_order_price : x.total?.toString(),
          ordered_date : new Date(x.order_date).toLocaleDateString("en-in"),
          awb : x.awb_code,
          PaymentMode : x.payment_method,
          ShipmentDate : x.sync_date
        }
        return newObj
      })
      setOrders(formattedOrders)
    }else{
      setOrders([])
    }
  }, [xbNdrOnholdOrders])

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
            {loading && (
              <ProgressBar/>
            )}
            <NdrOnHoldTable tableData={orders}/>
        </>
      )}
    </>
  )
}

export default NdrOnHold