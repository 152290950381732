import * as React from "react";
import { useState, useEffect } from "react";
import {
  getSizesByType,
  categoryTypes,
  clothingVendor,
  footwearVendor,
  denimVendor,
  menShoesVendor,
} from "../../utils/helperArrays";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableFooter,
  Paper,
  TextField,
  MenuItem,
} from "@mui/material";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import POAnalyticsTableRow from "./POAnalyticsTableRow";
import POAnalyticsSalesTableRow from "./POAnalyticsSalesTableRow";
import { calculatePOQuantity } from "../../utils/helpers";
import Checkout from "./Checkout";
import ChangeTableRowView from "./ChangeTableRowView";

const viewArray = ["individual sales", "total sales"];

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function POAnalyticsTable({
  tableData,
  productType,
  selectedPO,
}) {
  const [productsSelected, setProductsSelected] = useState([]);
  const [data, setData] = useState(
    tableData.sort((a, b) => Number(a.total4weeks) - Number(b.total4weeks))
  );

  const [desc, setDesc] = useState(true);
  const [sizes, setSizes] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [categoryType, setCategoryType] = useState("");
  const [view, setView] = useState("total sales");
  const catTypes = categoryTypes(productType);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setSizes(getSizesByType(productType));
    setData(
      [...tableData]
        .sort((a, b) => Number(a.total4weeks) - Number(b.total4weeks))
        .reverse()
    );
    setCategoryType("");
    setPage(0);
  }, [productType, tableData]);

  useEffect(() => {
    const newData = [...data].reverse();
    setData(newData);
  }, [desc]);

  useEffect(() => {
    if (categoryType && productType) {
      // Filter & Calculate sales % by category
      const filterTableData = tableData.filter((p) =>
        p.sku?.includes(categoryType)
      );
      const sizeWiseSales = sizes.reduce((acc, s) => {
        const total = filterTableData.reduce((sum, i) => {
          const sale = i.sales[s] || 0;
          return sum + sale;
        }, 0);
        return {
          ...acc,
          [s]: total,
        };
      }, {});
      const totalSales = Object.values(sizeWiseSales).reduce(
        (sum, item) => item + sum,
        0
      );
      const percentageSales = sizes.reduce(
        (acc, s) => ({
          ...acc,
          [s]: parseFloat((sizeWiseSales[s] / totalSales) * 100).toFixed(2),
        }),
        {}
      );
      const formattedData = filterTableData
        .map((p) => ({
          ...p,
          percent: percentageSales,
        }))
        .map((p) => {
          const { po, predictedSales, predictedRMS } = calculatePOQuantity(
            p,
            sizes
          );
          return {
            ...p,
            poQuantity: po,
            predictedSales,
            predictedRMS,
          };
        });

      if (formattedData.length > 0) {
        const newData = [...formattedData].reverse();
        setData(newData);
      } else {
        setData([]);
      }
    } else {
      setData(
        [...tableData]
          .sort((a, b) => Number(a.total4weeks) - Number(b.total4weeks))
          .reverse()
      );
    }
    setPage(0);
  }, [categoryType, catTypes, productType]);

  const changePOQuantity = (row) => {
    const newData = data.map((d) => {
      if (d.sku === row.sku) {
        return row;
      } else {
        return d;
      }
    });
    setData(newData);
  };

  useEffect(() => {
    setProductsSelected(selectedPO);
  }, [selectedPO]);

  function roundToNearestHalf(number) {
    // Round the number up
    const roundedNumber = Math.ceil(number);

    // Check if the rounded number is already a multiple of 0.5
    if (roundedNumber % 0.5 === 0) {
      return roundedNumber;
    } else {
      // Calculate the next multiple of 0.5
      const nextHalf = Math.ceil(roundedNumber * 2) / 2;
      return nextHalf;
    }
  }

  const changeProductsSelectedForPO = (row, checked, vendor) => {
    const totalPOQuantity = Object.values(row.poQuantity).reduce(
      (acc, amt) => acc + amt,
      0
    );
    const poProduct = {
      sku: row.sku,
      name: row.name,
      src: row.src,
      poQuantity: row.poQuantity,
      processedQuantity: Object.keys(row.poQuantity).reduce((acc, size) => {
        acc[size] = {
          pending: row.poQuantity[size],
          processed: 0,
          completed: 0,
        };
        return acc;
      }, {}),
      type: row.type,
      totalPOQuantity,
      material: row.material.map((m) => ({
        ...m,
        totalMaterialAmt: m.amount * totalPOQuantity,
        numberOfRollsReq: roundToNearestHalf((m.amount * totalPOQuantity) / 20),
      })),
      category:
        catTypes.find((ct) => ct.value === categoryType)?.label || "not found",
      vendor: vendor,
    };
    if (checked) {
      setProductsSelected((prev) => [...prev, poProduct]);
    } else {
      setProductsSelected((prev) => prev.filter((p) => p.sku !== row.sku));
    }
  };
  // save to local storage
  useEffect(() => {
    localStorage.setItem(
      "selectedPOProducts",
      JSON.stringify(productsSelected)
    );
  }, [productsSelected]);

  const changeView = (v) => setView(v);
  return (
    <>
      <br />
      <TextField
        style={{ width: "500px" }}
        select
        label="Select Product Category"
        value={categoryType}
        onChange={(e) => setCategoryType(e.target.value)}
        variant="outlined"
        className="select"
        fullWidth
      >
        {catTypes.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <br />
      <br />
      <ChangeTableRowView
        view={view}
        changeView={changeView}
        list={viewArray}
      />
      <br />
      <br />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>
                Image
                <br />
                SKU
                <br />
                Name
              </TableCell>
              <TableCell></TableCell>
              {sizes.map((i, k) => (
                <TableCell key={k}>Size : {i.toString()}</TableCell>
              ))}
              <TableCell onClick={() => setDesc(!desc)}>
                <div style={{ display: "flex" }}>
                  Total
                  <div>{desc ? <ArrowDownward /> : <ArrowUpward />}</div>
                </div>
              </TableCell>
              {/* <TableCell>Inventory Stock</TableCell> */}
              <TableCell>Total Sale</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {categoryType !== "" && (
            <TableBody>
              {(rowsPerPage > 0
                ? data.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : data
              ).map((row, index) => (
                <React.Fragment key={index}>
                  {view === "individual sales" ? (
                    <POAnalyticsSalesTableRow
                      row={row}
                      sizes={sizes}
                      changePOQuantity={changePOQuantity}
                      changeProductsSelectedForPO={changeProductsSelectedForPO}
                      productsSelected={productsSelected}
                    />
                  ) : (
                    <POAnalyticsTableRow
                      row={row}
                      sizes={sizes}
                      changePOQuantity={changePOQuantity}
                      changeProductsSelectedForPO={changeProductsSelectedForPO}
                      productsSelected={productsSelected}
                    />
                  )}
                </React.Fragment>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                style={{ overflow: "inherit" }}
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={3}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Checkout
        selected={productsSelected}
        setProductsSelected={setProductsSelected}
      />
    </>
  );
}
