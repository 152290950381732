import { Button } from "@mui/material";
import React, { useState } from "react";
import AddInventoryProductForm from "./AddInventoryProductForm";
import AddProductForm from "./AddProductForm";

const SetProductFormType = () => {
  const [selected, setSelected] = useState("");

  return (
    <>
      {selected === "inventory" ? (
        <AddInventoryProductForm setSelected={setSelected} />
      ) : selected === "shopify" ? (
        <AddProductForm setSelected={setSelected} />
      ) : (
        <>
          <h2 style={{ display: "flex", justifyContent: "center" }}>
            Select Entry Type
          </h2>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setSelected("inventory")}
            >
              Add to Inventory
            </Button>
            <Button
              onClick={() => setSelected("shopify")}
              style={{ left: "50px" }}
              variant="contained"
              color="secondary"
            >
              Add to Shopify & Inventory
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default SetProductFormType;
