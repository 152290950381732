import {
  Box,
  Button,
  Modal,
  Stack,
  Badge,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckoutTable from "./CheckoutTable";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { generateExcelForPO } from "../../utils/excelUtils";
import { createPurchaseOrder } from "../../actions/POActions";
const style = {
  position: "absolute",
  width: "100%",
  height: "100%",
  overflow: "scroll",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "99vw",
  // heigt: "99vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const Checkout = ({ selected, setProductsSelected }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //   divide by vendor
    const vendorWise = selected.reduce((acc, row) => {
      const found = acc.find((ac) => ac.vendor === row.vendor);
      if (found) {
        found.products = [row, ...found.products];
      } else {
        const newObj = {
          vendor: row.vendor,
          products: [row],
        };
        acc.push(newObj);
      }
      return acc;
    }, []);
    // set data
    setData(vendorWise);
  }, [selected]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = async () => {
    setLoading(true);
    console.log("data", data);
    // Call LAMBDA //handle error
    const { success, created, errored, POID } = await createPurchaseOrder(data);

    if (success) {
      generateExcelForPO(selected, POID);
      localStorage.setItem("selectedPOProducts", JSON.stringify([]));
      setProductsSelected([]);
    } else {
      const filteredCreatedForExcel = selected.filter((s) =>
        created.includes(s.vendor)
      );
      const filteredErrorForLocalStorage = selected.filter((s) =>
        errored.includes(s.vendor)
      );

      localStorage.setItem(
        "selectedPOProducts",
        JSON.stringify(filteredErrorForLocalStorage)
      );
      setProductsSelected(filteredErrorForLocalStorage);
      generateExcelForPO(filteredCreatedForExcel, POID);
    }
    // generateExcelForPO(selected);
    setLoading(false);
  };
  console.log("Data", data);
  return (
    <div>
      {/* Button */}

      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        sx={{
          float: "right",
          my: "15px",
          px: "5px",
        }}
      >
        Checkout
        <Badge badgeContent={selected.length} color="success">
          <ShoppingCartIcon />
        </Badge>
      </Button>
      {/* checkout table */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* close button */}
          <Stack direction="row" justifyContent={"end"}>
            <Button
              variant="contained"
              size="small"
              color="error"
              onClick={handleClose}
            >
              Exit
            </Button>
          </Stack>
          {selected.length > 0 ? (
            <div>
              {data.map((d, i) => (
                <Box
                  key={i}
                  sx={{
                    my: "30px",
                    mx: "50px",
                  }}
                >
                  <CheckoutTable data={d} />
                </Box>
              ))}
            </div>
          ) : (
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <img
                src="https://img.freepik.com/premium-vector/shopping-cart-with-cross-mark-wireless-paymant-icon-shopping-bag-failure-paymant-sign-online-shopping-vector_662353-912.jpg"
                alt=""
              />
            </Box>
          )}
          {/* create po button */}
          <div
            style={{
              float: "right",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <div>
                {selected.length > 0 && (
                  <Button
                    variant="contained"
                    sx={{ float: "right" }}
                    onClick={handleClick}
                  >
                    Create Purchase Order
                  </Button>
                )}
              </div>
            )}
          </div>
          {/* download excel */}
          {/* <Button
            variant="contained"
            sx={{ float: "right" }}
            onClick={() => generateExcelForPO(selected)}
          >
            Download Excel
          </Button> */}
        </Box>
      </Modal>
    </div>
  );
};

export default Checkout;
