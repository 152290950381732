import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ViewOrders from "../ordersWidgets/ViewOrders";
import RedirectLogin from "../RedirectLogin";
import { allOrders } from "../../actions/ordersActions";
import ProgressBar from "../../ProgressBar";
import "./index.css";

const ViewOrderList = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const orders = useSelector((state) => state.orderDetails.orders);
  // console.log(orders)
  const products = useSelector((state) => state.productDetails.products);
  const inventory = useSelector((state) => state.inventoryDetails.stock);

  useEffect(() => {
    if (!orders.length > 0 && auth) dispatch(allOrders());
  }, [auth]);

  return (
    <>
      {!auth ? (
        <RedirectLogin />
      ) : (
        <>
          {!orders.length > 0 ? (
            <ProgressBar />
          ) : (
            <ViewOrders orderData={orders} />
          )}
        </>
      )}
    </>
  );
};

export default ViewOrderList;
