import React from 'react'
import Questions from './Chart/Bar'
import { Typography } from '@mui/material'

const QuestionPercentage = ({tickets}) => {
    let questionsCount = tickets.reduce((acc,ticket)=>{
        const found = acc.find(ac=>ac.question === ticket.question)
        if(found){
            found.count +=1;
        }
        else{
            const newObj = {
                question: ticket.question,
                count: 1
            }
            acc.push(newObj)
        }
        return acc
    },[])
    // console.log(questionsCount)
    return (
        <>
            <div>Question Count</div>
            <Questions questionsCount={questionsCount}/>
        </>
    )
}

export default QuestionPercentage