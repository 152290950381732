import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBackupData } from '../../actions/reportActions'
import { io } from "socket.io-client";

import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

const DynamoBackup = () => {
  const {backupOrders} = useSelector(state => state.reports)
  // console.log(backupOrders)

  const [startId, setStartId] = useState('')
  const [endId, setEndId] = useState('')
  const [syncConnect, setSyncConnect] = useState(false);
  const [syncStatus, setSyncStatus] = useState({});

  const dispatch = useDispatch()

  const handleSubmit = () => {
    dispatch(getBackupData({startId,endId}))
  }

  useEffect(() => {
    const socket = io("http://localhost:8000");
    socket.on("connect", () => {
      setSyncConnect(true);
    });
    socket.on("sync-status", (status, date, message) => {
    //   console.log(status, message)
      setSyncStatus((prev) => ({ ...prev, [status]: { message, date } }));
      socket.emit("dummy_run", "ok");
      if(status === 'Deleted Successfully') socket.off("connect")
    });
  }, []);

//   useEffect(() => {dispatch(getBackupData())},[])

  return (
    <>
        <div>
            <TextField
                // style={{width:"400px"}}
                label="Starting Id"
                value={startId}
                onChange={(e) => setStartId(e.target.value)}
                variant="outlined"
                className='select'
            />
            <TextField
                style={{left:"20px"}}
                label="End Id"
                value={endId}
                onChange={(e) => setEndId(e.target.value)}
                variant="outlined"
                className='select'
            />    
        </div>
        <br/>
        <Button 
            disabled={Number(startId)>Number(endId) || startId === '' || endId === ''} 
            variant='contained' 
            color='secondary'
            onClick={handleSubmit}
        >
            Backup Data
        </Button>
        <br/>
        <br/>
        <Stack direction={"row"} justifyContent={"space-between"} width={"100%"}>
          <Stack gap={0.1}>
            <h3 style={{ margin: 0 }}>Status Logs ...</h3>
            {Object.keys(syncStatus).map((s, index) => (
              <Box key={index}>
                <Stack>
                  <Stack direction={"row"} alignItems="center" gap={0.5}>
                    <AccessTimeFilledIcon
                      sx={{
                        color:
                          Object.keys(syncStatus).length - 1 > index
                            ? "green"
                            : "black",
                      }}
                    />
                    <h5 style={{ margin: 0, color: "#808080" }}>
                      {syncStatus[s]["date"]}
                    </h5>
                  </Stack>
                  <Typography
                    variant="h6"
                    component="p"
                    sx={{
                      fontStyle: "italic",
                      borderLeft: "3px solid",
                      borderColor:
                        Object.keys(syncStatus).length - 1 > index
                          ? "green"
                          : "black",
                      marginLeft: "8.5px",
                      paddingLeft: "10px",
                      color:
                        Object.keys(syncStatus).length - 1 > index
                          ? "green"
                          : "black",
                    }}
                  >
                    {syncStatus[s]["message"]}
                  </Typography>
                </Stack>
              </Box>
            ))}
          </Stack>
        </Stack>
        <Box position={"fixed"} bottom={0} left={0} paddingLeft={1}>
          {syncConnect ? (
            <h3 style={{ color: "green" }}>Connected To LBSync</h3>
          ) : (
            <h3 style={{ color: "red" }}>Not Connected</h3>
          )}
        </Box>
    </>
  )
}

export default DynamoBackup