import {
    FormControlLabel,
    Paper,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getSizesByType } from "../../../utils/helperArrays";

// switch label
const label = { inputProps: { "aria-label": "Switch demo" } };

//show image switch
const ShowImageSwitch = ({ showImage, setShowImage }) => (
    <div>
        <FormControlLabel
            labelPlacement="start"
            label="Show image"
            control={
                <Switch
                    {...label}
                    checked={showImage}
                    onChange={(e) => setShowImage(e.target.checked)}
                />
            }
        />
        {/* <Switch  /> */}
    </div>
);
// table body row
const Row = ({ product, type, showImage, showPOID }) => {
    return (
        <React.Fragment>
            <TableRow>
                {showImage && (
                    <TableCell>
                        <img
                            src={product.src}
                            alt=""
                            style={{
                                width: 100,
                                height: 100,
                                objectFit: "contain",
                            }}
                        />
                    </TableCell>
                )}
                <TableCell>{product.sku}</TableCell>
                {getSizesByType(type).map((s) => (
                    <TableCell>{product.pending[s]}</TableCell>
                ))}
                <TableCell>{product.totalPending}</TableCell>
            </TableRow>
        </React.Fragment>
    );
};

const HeaderRow = ({ type, showImage }) => {
    return (
        <React.Fragment>
            <TableRow>
                {showImage && <TableCell>Img</TableCell>}
                <TableCell>SKU</TableCell>
                {getSizesByType(type).map((s) => (
                    <TableCell>{s}</TableCell>
                ))}
                <TableCell>Total Pending</TableCell>
            </TableRow>
        </React.Fragment>
    );
};

const ProductWiseTable = ({ data, type }) => {
    const [tableData, setTableData] = useState([]);
    const [showImage, setShowImage] = useState(true);

    useEffect(() => {
        //filter non pending
        const filteredNonPending = data.filter(
            (d) =>
                Object.values(d.processedQuantity).reduce(
                    (acc, v) => acc + v.pending,
                    0
                ) > 0
        );
        //reduce to product
        const skuWise = filteredNonPending.reduce((acc, row) => {
            const total = Object.values(row.processedQuantity).reduce(
                (totalAcc, v) => totalAcc + v.pending,
                0
            );
            const found = acc.find((ac) => ac.sku === row.sk);
            if (found) {
                found.pending = Object.keys(row.processedQuantity) //[34,35,36,37,38,39]
                    .reduce((pendingAcc, s) => {
                        pendingAcc[s] =
                            found.pending[s] + row.processedQuantity[s].pending;
                        return pendingAcc;
                    }, {});

                found.totalPending = found.totalPending + total;
                //UPDATE the accumulator
                return [...acc.filter((i) => i.sku !== row.sk), found];
            } else {
                const pending = Object.keys(row.processedQuantity).reduce(
                    (pendingAcc, s) => {
                        pendingAcc[s] = row.processedQuantity[s].pending;
                        return pendingAcc;
                    },
                    {}
                ); //{34:2,35:3,36:7}
                const newObj = {
                    sku: row.sk,
                    src: row.src,
                    pending,
                    totalPending: total,
                };
                acc.push(newObj);

                return acc;
            }
        }, []);

        console.log("Data", data);
        console.log("filtered non pending", filteredNonPending);
        console.log("sku wise", skuWise);

        setTableData(skuWise);
    }, [data]);
    // console.log("table data", tableData, getSizesByType(type));
    return (
        <div>
            {/* switches */}
            <Stack direction={"row"}>
                <ShowImageSwitch
                    showImage={showImage}
                    setShowImage={setShowImage}
                />
            </Stack>
            <TableContainer component={Paper} sx={{ mb: "5px" }}>
                <Table>
                    <TableHead>
                        <HeaderRow showImage={showImage} type={type} />
                    </TableHead>
                    <TableBody>
                        {tableData.map((row, i) => (
                            <Row
                                product={row}
                                key={row.sku}
                                type={type}
                                showImage={showImage}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ProductWiseTable;
