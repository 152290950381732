const initialState = {
  stock: [],
  loading: false,
  processingOrders: [],
  updatedRTOstock: [],
  customerCareUsers: [],
  materialFabricList: [],
  materialColorList: [],
  materialList: [],
};

const inventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_INVENTORY_STOCK":
      return {
        ...state,
        stock: action.data,
      };
    case "UPDATED_INVENTORY_STOCK":
      return {
        ...state,
        stock: action.data,
      };
    case "SHOW_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "HIDE_LOADING":
      return {
        ...state,
        loading: false,
      };
    case "PROCESSING_ORDERS":
      return {
        ...state,
        processingOrders: action.processingData,
      };
    case "UPDATE_RTO_STOCK":
      return {
        ...state,
        updatedRTOstock: action.payload,
      };
    case "SHOW_CUSTOMER_CARE_USERS":
      return {
        ...state,
        customerCareUsers: action.payload,
      };
    case "SHOW_MATERIAL_FABRIC_LIST":
      return {
        ...state,
        materialFabricList: action.data,
      };
    case "SHOW_MATERIAL_COLOR_LIST":
      return {
        ...state,
        materialColorList: action.data,
      };
    case "SHOW_MATERIAL_LIST":
      return {
        ...state,
        materialList: action.data,
      };
    default:
      return state;
  }
};

export default inventoryReducer;
