import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";

const AmtTable = ({ data, handleRemove }) => {
  return (
    <div>
      <TableContainer sx={{ minWidth: 700 }}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Fabric</TableCell>
              <TableCell align="center">Color</TableCell>
              <TableCell align="center">Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center" component="th" scope="row">
                  {row.fabricName}
                </TableCell>
                <TableCell align="center">{row.colorName}</TableCell>
                <TableCell align="center">{row.amt}</TableCell>
                <TableCell align="center">
                  <Box
                    sx={{
                      cursor: "pointer",
                      "& :hover": {
                        color: "red",
                      },
                    }}
                    onClick={() => handleRemove(row.fabricId, row.colorId)}
                  >
                    <DeleteOutline />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AmtTable;
