import * as React from "react";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { navBarArr } from "../../utils/links";

export default function NavBar({ open, expanded, setExpanded }) {
  const auth = useSelector((state) => state.auth);
  const [navArr, setNavArr] = useState([]);

  useEffect(() => {
    if (auth.name === "administrator") setNavArr(navBarArr[0]);
    else if (auth.name === "supervisor") setNavArr(navBarArr[1]);
    else if (auth.name === "customercare") setNavArr(navBarArr[2]);
    else if (auth.name === "warehouse") setNavArr(navBarArr[3]);
    else if (auth.name === "cc_supervisor") setNavArr(navBarArr[4]);
    else if (auth.name === "warehouse_assistant") setNavArr(navBarArr[5]);
    else if (auth.name === "marketing") setNavArr(navBarArr[6]);
  }, [auth]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <List>
        {navArr.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                borderRadius: "25px",
              }}
            >
              {!open ? (
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    opacity: open ? 0 : 1,
                  }}
                >
                  {item.icon}
                </ListItemIcon>
              ) : (
                ""
              )}

              <ListItemText
                sx={{
                  opacity: open ? 1 : 0,
                  "&& span>div": {
                    borderRadius: "25px",
                    // boxShadow: "inset 6px 6px 6px #CBCED1,inset -6px -6px 6px #FFFFFF"
                    color: "black",
                  },
                }}
              >
                {!item.submenu ? (
                  <Accordion expanded={true}>
                    <AccordionSummary>
                      <Typography
                        sx={{
                          width: "33%",
                          flexShrink: 0,
                        }}
                      >
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "black",
                          }}
                          to={item.link}
                        >
                          {item.text}
                        </Link>
                      </Typography>
                    </AccordionSummary>
                  </Accordion>
                ) : (
                  <Accordion
                    expanded={expanded === `panel${index + 1}`}
                    onChange={handleChange(`panel${index + 1}`)}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography
                        sx={{
                          width: "33%",
                          flexShrink: 0,
                        }}
                      >
                        {item.text}
                      </Typography>
                    </AccordionSummary>
                    {item.items?.map((x, index) => (
                      <AccordionDetails
                        key={index}
                        sx={{ paddingLeft: "30px" }}
                      >
                        {/* <Button>{x.text}</Button> */}
                        <Typography
                          sx={{
                            width: "33%",
                            flexShrink: 0,
                          }}
                        >
                          <Link
                            style={{
                              textDecoration: "none",
                              color: "black",
                            }}
                            to={x.link}
                          >
                            {x.text}
                          </Link>
                        </Typography>
                      </AccordionDetails>
                    ))}
                  </Accordion>
                )}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
}
