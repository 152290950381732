export const templates = [
  {
    category: "Exchanges",
    template: [
      {
        name: "How to initiate the exchange?",
        message:
          "Hey dear  !\n\nLet's walk you through our exchange process in short.\nWe offer a 10-day size exchange from the date of delivery. You can initiate the Exchange after 24 hours of Delivery. We do not have any Money Refund Policy. In case the next size you want is not in stock, we will provide you a store credit in the form of a Gift Card worth the price of the product(excluding COD charges and GST). For more information kindly visit the FAQ page of our Website.\nYou can initiate the exchange by clicking on the link below\n https://littleboxindia.com/pages/return-exchange\n\nCiao\nSupport Team\nLittlebox",
      },
      {
        name: "I want to exchange but I'm unable to do it",
        message:
          "Hey love !\n\nThis is real easy. Please click on the link below and you will be directed to follow the steps to follow.\nhttps://littleboxindia.com/pages/return-exchange\nOnce the exchange query is submitted, our delivery partner will pick up the product from your address and once delivered in our warehouse we will ship your new order / provide you with the credit voucher.Please note that pick up might take 2-5 days.\nWe hope it clears all your doubts.\n\nCiao\nTeam\nLittlebox",
      },
      {
        name: "When will my exchange item will be picked up?(within 4-5 days of exchange request)",
        message:
          "Hey love\n\n" +
          "A little update on your exchange request.\n" +
          "Once the Exchange Request is approved by the concerned team the pickup gets done in the next 04-05 days.Our courier partner will call you for the same.Please ensure that you pick up the call. \n" +
          "Also once the pick up is done , we will update your status and create your new order or give you the credit voucher as requested by you.\n\n" +
          "Ciao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "When will my exchange item will be picked up?(after 5 days of exchange request)",
        message:
          "Hey love\n\n" +
          "This is unfortunate. We apologise for the delay in picking up your exchange product.\n" +
          "We will follow up with the courier partner super soon and update you on the status of the pick up.Please allow up some time.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "Where is my Credit voucher/Gift card?",
        message:
          "Hey love!\n\n" +
          "Your credit voucher/gift card has been mailed to you on your registered email id.\n" +
          "Please check once for the same.\n" +
          "Your current valid voucher is “ voucher details” and valid for next “ “ days.Once you add the products to your cart there will be a DISCOUNT COUPON/APPLY VOUCHER box where you can apply the voucher code and the voucher amount will get deducted from the total.\n" +
          "Hope this clears your doubt.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "My pickup has already been done long back. When will I receive my exchange/voucher?",
        message:
          "Hey dear\n" +
          "\n" +
          "Thank you for reaching out to us. As per our records, your pickup has been done and is in transit. Once we receive the products from the courier and it passes through a quality check at our warehouse we will proceed with your exchange/voucher.\n" +
          "\n" +
          "Ciao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "I'm unable to use my Gift card/credit voucher",
        message:
          "Hey love\n\n" +
          "We will quickly follow this up internally and update you on this.Your current valid voucher is “ voucher details” and valid for next “ “ days.Once you add the products to your cart there will be a DISCOUNT COUPON/APPLY VOUCHER box where you can apply the voucher code and the voucher amount will get deducted from the total.\n" +
          "Hope this clears your doubt.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "Why is my pickup not yet done?",
        message:
          "Hey love\n\n" +
          "This is unfortunate. We apologise for the delay in picking up your exchange product.\n" +
          "We will follow up with the courier partner super soon and update you on the status of the pick up.Please allow up some time.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "My pickup is not done (Non serviceable zone)",
        message:
          "Hi dear\n\n" +
          "Exchange pickup for the order cannot be done in your area as your area is a Non-Serviceable Zone, So, if you want you can self-courier it to us. After which the courier charges (Rs 300) which will occur while you send us your products will be refunded to you,, so once you mailed us your products please send us the image of the receipt the courier will provide you over this mail so that we can verify the amount you had to pay due when you self posted us the products and we will provide you with the refund.\n" +
          "Here is the self courier address.\n" +
          "\n" +
          "PLOT NUMBER 2 NEAR AGARWAL SWEETS BELOW WELLNESS GYM\n" +
          "100 FEET ROAD GHITORNI\n" +
          "South West Delhi\n" +
          "Delhi India 110030\n" +
          "Contact number - 9999653916\n" +
          "\n" +
          "Support Team\n" +
          "Littlebox India",
      },
      {
        name: "Why is my exchange pick up cancelled?(if pincode is in NSZ)",
        message:
          "Hey dear\n\n" +
          "This is unfortunate.Our courier partner does not provide the service of reverse pick up from your pincode as your area is in Non-Serviceable Zone.\n" +
          "So, we would request you to self-courier it to us. A fixed amount of 250INR will be refunded back to you as your self shipping fee.Please send us the image of the receipt which the courier will provide you over this mail so that we can verify the shipment.\n" +
          "Please pack the parcel in its original format and tape it thoroughly. Please ship the parcel to the following address.\n" +
          "PLOT NUMBER 2 NEAR AGARWAL SWEETS BELOW WELLNESS GYM\n" +
          "100 FEET ROAD GHITORNI\n" +
          "South West Delhi\n" +
          "Delhi India 110030\n" +
          "\n" +
          "Hope this clarifies your query. For any further assistance, please write to us and we will assist you further.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "I want the exchange for the second time for the same product.",
        message:
          "Hey dear\n\n" +
          "This looks like a small bump in the road. But no worries, we hear you.\n" +
          "We do let the parcel be exchanged for a second time but we do charge a nominal amount of fixed shipping fee of 25O INR.\n" +
          "Once the second exchange request is submitted , we will make your new order with the product you requested  with the invoice value of  250 INR. Please note that pick up of the product will take 2-5 days.Please pay the same and collect your parcel at the time of delivery.\n\n" +
          "Ciao\n" +
          "Support Team\n" +
          "LittleBox",
      },
      {
        name: "My exchange period has expired. What can I do?",
        message:
          "Hey love !\n\n" +
          "Thank you for reaching out to us. We would like to inform you that your exchange period has expired as it was valid for 10 days. Hence we are unable to proceed with your exchange pickup.\n" +
          "\n" +
          "Ciao\n" +
          "Team\n" +
          "Littlebox",
      },
      {
        name: "I have already received the product but I am unable to see the exchange option in my account. Please help",
        message:
          "Hey dear\n\n" +
          "Thank you for reaching out to us. The exchange button will not be visible immediately. The exchange button will be visible only after 24  hours and 48 hours after the order is delivered. You can initiate the exchange by going to your Littlebox India account and under the MY ACCOUNT section next to your order number you will find the exchange button. Click on the button and proceed with the steps to submit the exchange request.\n" +
          "\n" +
          "Ciao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "Customer sent us used/wrong products",
        message:
          "Hey love\n\n" +
          "This is unfortunate. We would like to inform you that as per our verification, we have found that you have sent us used products from your end. Hence we are unable to send your exchange order and it has been put on hold.\n" +
          "\n" +
          "Ciao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "My exchange request has been rejected. What to do?",
        message:
          "Hey dear\n" +
          "\n" +
          "Thank you for reaching out to us. Your exchange request has been rejected as the reason for your exchange was invalid. You have not stated your new size/proper reason hence the request has been denied. We hope it cleared your doubts.\n" +
          "\n" +
          "Ciao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
    ],
  },
  {
    category: "Delivery & Order Status",
    template: [
      {
        name: "How can I track my order?",
        message:
          "Hey dear\n\n" +
          "To track your order please visit the My Account  https://littleboxindia.com/account/login page. Once the order is dispatched you can track clicking on the tracking number provided next to your order in My Account.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "I want to return my order",
        message:
          "Hey dear\n\n" +
          "We would like to inform you that we do not have any money return facility.\n" +
          "We only do the exchanges for the products which do not fit you. And if you do not wish to continue with the same product, we will provide you with the credit voucher/gift card of the same amount of the product excluding the cod charges.\n" +
          "We hope this clears your doubt.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "Where is my order? (1st-14th day)",
        message:
          "Hey dear !\n\n" +
          "Thank you for reaching out to us. Your order is in process & we are working on it to be dispatched much sooner.But also please note that our standard dispatch time is between\n" +
          "7 -14 days. Your tracking id will be generated once it gets shipped & wil be mailed to your registered email id. Please keep a check for the same.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "where is my order?(between 14th-25th day)",
        message:
          "Hey dear\n\n" +
          "This is unfortunate.Your order has taken longer than expected time for the dispatch.\n" +
          "We will look into it in super priority now and ensure the dispatch of your order.\n" +
          "In case of any other developments with your order, we will mail you on your registered email id.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "where is my order?(dispatched but in transit)",
        message:
          "Hey love\n\n" +
          "We are thrilled to update you that your order is shipped and it's in transit to the destination. It will be delivered to you soon. Kindly track your order with AWB number  1234  on the Xpressbees website. ( please insert here xb tracking link )\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "where is my order?(Order is marked rto by courier company)",
        message:
          "Hey Dear\n\n" +
          "It is absolutely unfortunate that  your order has been returned to us as the courier partner stated that  you were unable to receive any call tried by the delivery executive  and hence they could not reach your provided address.\n" +
          "If you still want the delivery of your package , please place the order once again on our website and we will ship your order on priority.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "Is Cash On Delivery available?",
        message:
          "Hey love\n\n" +
          "Yes we do have cash on delivery available in most of the pin codes pan India. \n" +
          "In case your pin code is not available for the cash on delivery , you can select the prepaid mode in order to place the order.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
    ],
  },
  {
    category: "Order Issues",
    template: [
      {
        name: "I already made the payment but i did not receive any confirmation yet?",
        message:
          "Hey dear\n\n" +
          "Don't you worry about this. Let us check this for you.\n" +
          "Can you please share the transaction id or any screenshot confirming your money debit.\n" +
          "Also please provide your registered phone number so that we can track the same\n" +
          "We will work on it super soon and get back to you with the details.\n" +
          "Also please check “My account tab” for the latest order number .\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "I want to return my order?",
        message:
          "Hey dear\n\n" +
          "We would like to inform you that we do not have any money return facility.\n" +
          "We only do the exchanges for the products which do not fit you. And if you do not wish to continue with the same product, we will provide you with the credit voucher/gift card of the same amount of the product excluding the cod charges.\n" +
          "We hope this clears your doubt.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "Cancel Prepaid Order",
        message:
          "Hey dear\n\n" +
          "This is sad.But yeah, we will follow as you say.\n" +
          "As per your request we have cancelled your order and a refund for the same has been submitted. It will be credited in the next 07  banking days as per standard banking procedures to your mode of payment.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "Cancel COD Order",
        message:
          "Hey dear\n\n" +
          "This is sad.But yeah, we will follow as you say.\n" +
          "Your order has been cancelled from our end and you have also received a mail for the same on your registered email id.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "Is cash on delivery available in my pin code?",
        message:
          "Hey love\n\n" +
          "Yes we do have cash on delivery available in most of the pin codes pan India. \n" +
          "In case your pin code is not available for the cash on delivery , you can select the prepaid mode in order to place the order.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "I'm unable to place my order",
        message:
          "Hey love.\n\n" +
          "We hear you.Can you please share the screenshot of the error you see while placing the order.We would check it internally and update you on super soon.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },

      {
        name: "change product in order if order is not shipped",
        message:
          "Hey love\n\n" +
          "We hear you. Please send the details of the product you want to remove and also the details like size and colour along with the link of the product which you would want us to add.\n" +
          "We would do the required changes and update your order.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "change product in order if order is shipped",
        message:
          "Hey love\n\n" +
          "We are very sorry but your order has already been shipped and its already on its way to your destination.We won't be able to do any changes in the order now.\n" +
          "We are sure you will love the product once you receive it.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "I already placed the cash on delivery but I have not received any confirmation yet.",
        message:
          "Hey love.\n\n" +
          "We are checking it in super priority.Can you please provide your registered mobile number and your registered email id so that we can check our backend and update you on the same.\n" +
          "Also please check “My account tab” for the latest order number .\n\n" +
          "Ciao\n" +
          "Support Team \n" +
          "Littlebox",
      },
      {
        name: "I want to change my address. How can I do that?",
        message:
          "Hey love\n\n" +
          "Thank you for reaching out to us. Please share your new address with pincode so that we can update it from our end.\n" +
          "\n" +
          "Ciao\n" +
          "Support Team\n" +
          "Littlebox India\n",
      },
      {
        name: "My order has been split. Can you tell me why and when will I be receiving the other part of my order?",
        message:
          "Hey dear\n\n" +
          "Let us check this for you. Your order has been split into 2 parts. One part has already been successfully delivered and the remaining part of your order is in process and will be dispatched to you at the earliest. Once it is shipped you will be notified with an email and the same can be tracked from your my account section.\n" +
          "\n" +
          "We hope we cleared all your doubts.\n" +
          "\n" +
          "Ciao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "I am unable to login to my account as I created two accounts using my phone number and the other my email id. Please help",
        message:
          "Hey dear\n\n" +
          "Thank you for reaching out to us. Let us check this for you and resolve your issue please provide us with your registered email address and phone number so that we can merge your accounts.\n" +
          "\n" +
          "Ciao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
    ],
  },
  {
    category: "Payment & Vouchers",
    template: [
      {
        name: "How to apply voucher/giftcard?",
        message:
          "Hey love\n\n" +
          "You have to apply the voucher code at the time of checkout. You will get an option to enter the voucher code at the checkout window. Please enter the voucher code there and click on APPLY button.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "How do I pay for my order?",
        message:
          "Hey love\n\n" +
          "You can place the order on Prepaid mode or Cash on Delivery (COD). We accept payments through Card, Netbanking and UPI.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "What is the validity of the giftcard?",
        message:
          "Hey love\n\n" +
          "The credit voucher / Gift card issued to you is valid for one year from the date of issuance.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "unable to use giftcard/voucher",
        message:
          "Hey love!\n\n" +
          "We hear you. Can you please send the screenshot of the error you are facing while placing the order using your gift card. We will run it internally and get back to you super soon.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
    ],
  },
  {
    category: "Order Cancellation",
    template: [
      {
        name: "I already made the payment but i did not receive any confirmation yet?",
        message:
          "Hey dear\n\n" +
          "Don't you worry about this. Let us check this for you.\n" +
          "Can you please share the transaction id or any screenshot confirming your money debit.\n" +
          "Also please provide your registered phone number so that we can track the same\n" +
          "We will work on it super soon and get back to you with the details.\n" +
          "Also please check “My account tab” for the latest order number .\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "I want to return my order?",
        message:
          "Hey dear\n\n" +
          "We would like to inform you that we do not have any money return facility.\n" +
          "We only do the exchanges for the products which do not fit you. And if you do not wish to continue with the same product, we will provide you with the credit voucher/gift card of the same amount of the product excluding the cod charges.\n" +
          "We hope this clears your doubt.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "Cancel Prepaid Order",
        message:
          "Hey dear\n\n" +
          "This is sad.But yeah, we will follow as you say.\n" +
          "As per your request we have cancelled your order and a refund for the same has been submitted. It will be credited in the next 07  banking days as per standard banking procedures to your mode of payment.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "Cancel COD Order",
        message:
          "Hey dear\n\n" +
          "This is sad.But yeah, we will follow as you say.\n" +
          "Your order has been cancelled from our end and you have also received a mail for the same on your registered email id.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "Is cash on delivery available in my pin code?",
        message:
          "Hey love\n\n" +
          "Yes we do have cash on delivery available in most of the pin codes pan India. \n" +
          "In case your pin code is not available for the cash on delivery , you can select the prepaid mode in order to place the order.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "I'm unable to place my order",
        message:
          "Hey love.\n\n" +
          "We hear you.Can you please share the screenshot of the error you see while placing the order.We would check it internally and update you on super soon.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },

      {
        name: "change product in order if order is not shipped",
        message:
          "Hey love\n\n" +
          "We hear you. Please send the details of the product you want to remove and also the details like size and colour along with the link of the product which you would want us to add.\n" +
          "We would do the required changes and update your order.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "change product in order if order is shipped",
        message:
          "Hey love\n\n" +
          "We are very sorry but your order has already been shipped and its already on its way to your destination.We won't be able to do any changes in the order now.\n" +
          "We are sure you will love the product once you receive it.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "I already placed the cash on delivery but I have not received any confirmation yet.",
        message:
          "Hey love.\n\n" +
          "We are checking it in super priority.Can you please provide your registered mobile number and your registered email id so that we can check our backend and update you on the same.\n" +
          "Also please check “My account tab” for the latest order number .\n\n" +
          "Ciao\n" +
          "Support Team \n" +
          "Littlebox",
      },
      {
        name: "I want to change my address. How can I do that?",
        message:
          "Hey love\n\n" +
          "Thank you for reaching out to us. Please share your new address with pincode so that we can update it from our end.\n" +
          "\n" +
          "Ciao\n" +
          "Support Team\n" +
          "Littlebox India\n",
      },
      {
        name: "My order has been split. Can you tell me why and when will I be receiving the other part of my order?",
        message:
          "Hey dear\n\n" +
          "Let us check this for you. Your order has been split into 2 parts. One part has already been successfully delivered and the remaining part of your order is in process and will be dispatched to you at the earliest. Once it is shipped you will be notified with an email and the same can be tracked from your my account section.\n" +
          "\n" +
          "We hope we cleared all your doubts.\n" +
          "\n" +
          "Ciao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "I am unable to login to my account as I created two accounts using my phone number and the other my email id. Please help",
        message:
          "Hey dear\n\n" +
          "Thank you for reaching out to us. Let us check this for you and resolve your issue please provide us with your registered email address and phone number so that we can merge your accounts.\n" +
          "\n" +
          "Ciao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
    ],
  },
  {
    category: "Order Modification & Issues",
    template: [
      {
        name: "I already made the payment but i did not receive any confirmation yet?",
        message:
          "Hey dear\n\n" +
          "Don't you worry about this. Let us check this for you.\n" +
          "Can you please share the transaction id or any screenshot confirming your money debit.\n" +
          "Also please provide your registered phone number so that we can track the same\n" +
          "We will work on it super soon and get back to you with the details.\n" +
          "Also please check “My account tab” for the latest order number .\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "I want to return my order?",
        message:
          "Hey dear\n\n" +
          "We would like to inform you that we do not have any money return facility.\n" +
          "We only do the exchanges for the products which do not fit you. And if you do not wish to continue with the same product, we will provide you with the credit voucher/gift card of the same amount of the product excluding the cod charges.\n" +
          "We hope this clears your doubt.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "Cancel Prepaid Order",
        message:
          "Hey dear\n\n" +
          "This is sad.But yeah, we will follow as you say.\n" +
          "As per your request we have cancelled your order and a refund for the same has been submitted. It will be credited in the next 07  banking days as per standard banking procedures to your mode of payment.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "Cancel COD Order",
        message:
          "Hey dear\n\n" +
          "This is sad.But yeah, we will follow as you say.\n" +
          "Your order has been cancelled from our end and you have also received a mail for the same on your registered email id.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "Is cash on delivery available in my pin code?",
        message:
          "Hey love\n\n" +
          "Yes we do have cash on delivery available in most of the pin codes pan India. \n" +
          "In case your pin code is not available for the cash on delivery , you can select the prepaid mode in order to place the order.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "I'm unable to place my order",
        message:
          "Hey love.\n\n" +
          "We hear you.Can you please share the screenshot of the error you see while placing the order.We would check it internally and update you on super soon.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },

      {
        name: "change product in order if order is not shipped",
        message:
          "Hey love\n\n" +
          "We hear you. Please send the details of the product you want to remove and also the details like size and colour along with the link of the product which you would want us to add.\n" +
          "We would do the required changes and update your order.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "change product in order if order is shipped",
        message:
          "Hey love\n\n" +
          "We are very sorry but your order has already been shipped and its already on its way to your destination.We won't be able to do any changes in the order now.\n" +
          "We are sure you will love the product once you receive it.\n" +
          "\nCiao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "I already placed the cash on delivery but I have not received any confirmation yet.",
        message:
          "Hey love.\n\n" +
          "We are checking it in super priority.Can you please provide your registered mobile number and your registered email id so that we can check our backend and update you on the same.\n" +
          "Also please check “My account tab” for the latest order number .\n\n" +
          "Ciao\n" +
          "Support Team \n" +
          "Littlebox",
      },
      {
        name: "I want to change my address. How can I do that?",
        message:
          "Hey love\n\n" +
          "Thank you for reaching out to us. Please share your new address with pincode so that we can update it from our end.\n" +
          "\n" +
          "Ciao\n" +
          "Support Team\n" +
          "Littlebox India\n",
      },
      {
        name: "My order has been split. Can you tell me why and when will I be receiving the other part of my order?",
        message:
          "Hey dear\n\n" +
          "Let us check this for you. Your order has been split into 2 parts. One part has already been successfully delivered and the remaining part of your order is in process and will be dispatched to you at the earliest. Once it is shipped you will be notified with an email and the same can be tracked from your my account section.\n" +
          "\n" +
          "We hope we cleared all your doubts.\n" +
          "\n" +
          "Ciao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
      {
        name: "I am unable to login to my account as I created two accounts using my phone number and the other my email id. Please help",
        message:
          "Hey dear\n\n" +
          "Thank you for reaching out to us. Let us check this for you and resolve your issue please provide us with your registered email address and phone number so that we can merge your accounts.\n" +
          "\n" +
          "Ciao\n" +
          "Support Team \n" +
          "Littlebox\n",
      },
    ],
  },
];
