import React, { useEffect, useState } from "react";
import ProcessOrderTable from "../processOrderWidgets/ProcessOrderTable";
import Folder from "../processOrderWidgets/Folder";
import { useDispatch, useSelector } from "react-redux";
import { getSheetNames, orderBySheetNames } from "../../actions/ordersActions";
import { CircularProgress } from "@mui/material";

const ProcessedOrders = () => {
  const [choseFolder, setChoseFolder] = useState("");
  const [folderNames, setFolderNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const { orderTakeout, loading: orderLoading } = useSelector(
    (state) => state.orderDetails
  );
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("folder name", choseFolder);
    // search orders
    if (choseFolder) {
      dispatch(orderBySheetNames(choseFolder));
    }
  }, [choseFolder]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const sheetData = await getSheetNames();
      if (sheetData.status === 200) {
        setFolderNames(sheetData.data);
      }
      setLoading(false);
    })();
  }, []);
  console.log("order takeout", orderTakeout);
  return (
    <div>
      {loading && <CircularProgress />}
      <Folder data={folderNames} setChoseFolder={setChoseFolder} />
      {orderLoading ? (
        <CircularProgress />
      ) : (
        <div>
          {choseFolder && orderTakeout.length > 0 && (
            <ProcessOrderTable orders={orderTakeout} name={choseFolder} />
          )}
        </div>
      )}
    </div>
  );
};

export default ProcessedOrders;
