import { Box } from "@mui/material";
import React from "react";

const ImageViewer = ({ handleClose, id }) => {
  return (
    <Box>
      <h1>hello</h1>
      <div id="image-viewer">
        <span onClick={handleClose} class="close">
          &times;
        </span>
        <img
          class="modal-content"
          // src={`https://drive.google.com/uc?export=view&id=${id}`}
          src={`https://lh3.google.com/u/0/d/${id}`}
          id="full-image"
          alt=""
        />
      </div>
    </Box>
  );
};

export default ImageViewer;
