import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField, Stack } from "@mui/material";
import { toast } from "react-toastify";
import { read, utils } from "xlsx";

import {
  addInventoryStockFromVendorList,
  allInventory,
} from "../../actions/inventoryActions";

import ProductsFromVendorTable from "../poWidgets/ProductsFromVendorTable";

const AddProductFromVendor = () => {
  // Application state from reducers
  const auth = useSelector((state) => state.auth);
  const inventory = useSelector((state) => state.inventoryDetails.stock);
  const dispatch = useDispatch();

  // Component state
  const [excelFile, setExcelFile] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileNames, setFileNames] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [stockLoading, setStockLoading] = useState(false);

  useEffect(() => {
    if (!inventory.length > 0 && auth) dispatch(allInventory());
  }, [auth]);

  useEffect(() => {
    if (excelFile.length > 0 && inventory.length > 0) {
      // setExcelFile(excelFile.flat())
      toast.success("Excel File Uploaded Successfully");
      console.log(excelFile);
      //create data for table
      const dataForTable = excelFile[0].map((ed) => {
        const findInventoryItem = inventory.find((i) => i.SKU === ed.SKU);
        return {
          ...ed,
          imageUrl: findInventoryItem
            ? findInventoryItem.imageUrl
            : "https://sss-retail-images.s3.amazonaws.com/404.jpg?w=256&q=75",
        };
      });
      setTableData(dataForTable);

      // Create product sizes
      const firstRow = dataForTable[0];
      const keys = Object.keys(firstRow).filter((i) =>
        i.toUpperCase().startsWith("SIZE")
      );
      setSizes(keys);

      setSelectedFile(null);
    } else {
      toast.error("No Excel File Uploaded");
      console.log("No Excel File Uploaded");
    }
  }, [excelFile, inventory]);

  const handler = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handlerSubmit = () => {
    if (selectedFile) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = (e) => {
        let data = e.target.result;
        setFileNames((prev) => [
          ...prev,
          selectedFile?.name.split(".").reverse().splice(1).reverse().join("."),
        ]);
        let workbook = read(data, { type: "binary" });
        setFileNames((prev) => [...prev, workbook.SheetNames[0]]);
        workbook.SheetNames.forEach((sheet) => {
          let rowObject = utils.sheet_to_json(workbook.Sheets[sheet], {
            raw: false,
            // dateNF: "dd/mm/yyyy",
          });
          setExcelFile((prev) => [...prev, rowObject]);
        });
      };
    }
  };

  const clearFile = () => setExcelFile([]);

  const handleDelete = (sku) => {
    const newData = tableData.filter((p) => p.SKU !== sku);
    setTableData(newData);
  };

  const handleItemCountChange = (item) => {
    const newData = tableData.map((p) => (p.SKU === item.SKU ? item : p));
    setTableData(newData);
  };

  const handleItemSubmit = async (sku) => {
    setStockLoading(true);
    // Dispatch targetRow here
    const targetRow = tableData.find((row) => row.SKU === sku);
    const inventoryItems = inventory.filter((i) => i.SKU === targetRow.SKU);
    const sizesToAdd = Object.keys(targetRow)
      .filter((k) => k.startsWith("SIZE"))
      .map((k) => ({
        label: k,
        value: k.replace("SIZE", "").replace(/\s/g, ""),
      }));
    let checkIfAllSizesExists = true;
    sizesToAdd.forEach((s) => {
      if (!inventoryItems.find((i) => i.size === s.value.toUpperCase())) {
        checkIfAllSizesExists = false;
      }
    });
    console.log("Add this to inventory", targetRow, sizesToAdd, inventoryItems);
    if (inventoryItems.length > 0 && checkIfAllSizesExists) {
      const payload = sizesToAdd.map((s) => {
        const barcode = inventoryItems.find(
          (i) => i.size === s.value.toUpperCase()
        )["barcode"];
        return {
          barcode,
          count: parseInt(targetRow[s.label]),
        };
      });
      const data = await addInventoryStockFromVendorList(payload);
      if (data.statusCode === 200) {
        // Delete row from tableData
        const newData = tableData.filter((p) => p.SKU !== sku);
        setTableData(newData);
      } else {
        alert("Cannot add stock");
      }
    } else {
      alert("some/all product sizes does not exists");
    }
    setStockLoading(false);
  };

  return (
    <div>
      <h1>Add Products From Vendor Excel File</h1>
      {/* File Upload */}
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            type={"file"}
            onChange={handler}
            inputProps={{ accept: ".xls,.xlsx,.csv,.xlsb" }}
          />
          <Button
            style={{ left: "10px" }}
            variant="contained"
            color="error"
            onClick={clearFile}
          >
            Clear File
          </Button>
        </div>
        <br />
        <Stack direction={"row"} gap={2}>
          <Button variant="contained" color="error" onClick={handlerSubmit}>
            Upload File
          </Button>
        </Stack>
        <br />
      </div>
      {/* FILE DETAILS */}
      {fileNames.map((name, i) => (
        <p key={i}>{name}</p>
      ))}
      {tableData.length > 0 && (
        <ProductsFromVendorTable
          data={tableData}
          productSizes={sizes}
          onDeleteItem={handleDelete}
          onCountChange={handleItemCountChange}
          onSubmit={handleItemSubmit}
          stockLoading={stockLoading}
        />
      )}
    </div>
  );
};

export default AddProductFromVendor;
